import { Box } from '@mui/material'
import React from 'react'
import RenderCompanyChip from '../RenderChipComponents/RenderCompanyChip'

interface RenderPropertyLinkProps {
  organization: any
}

const RenderPropertyLink = React.forwardRef<HTMLDivElement, RenderPropertyLinkProps>(({ organization }, ref) => {
  if (!organization) return null

  const propertyTypes = ['Housing Property', 'Agency Whitelabel', 'Organization']
  const type = organization.type && organization.type.length > 0 ? organization.type : 'NT'

  if (!propertyTypes.includes(type) && type !== 'NT') return null

  return (
    <Box ref={ref}>
      <RenderCompanyChip option={type} />
    </Box>
  )
})

export default RenderPropertyLink
