import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

const batchUpdateTasks = async (tasks) => {
  const url = `${baseUrl}/aws/tasks/batch`

  // console.log('[batchUpdateTasks] Sending payload:', JSON.stringify({ tasks }, null, 2))

  try {
    const response = await axios.put(url, { tasks })
    // console.log('[batchUpdateTasks] Response:', response.data)
    return response.data
  } catch (error) {
    console.error(
      '[batchUpdateTasks] API Error:',
      error.response?.data || error.message
    )
    throw error
  }
}

export const useBatchUpdateTasks = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: batchUpdateTasks,
    onSuccess: () => {
      queryClient.invalidateQueries(['tasksTable'])
      console.log('Batch update successful')
    },
    onError: (error) => {
      console.error(
        '[useBatchUpdateTasks] API Error:',
        error.response?.data || error.message
      )
    },
  })

  return mutation
}
