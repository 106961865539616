import React from 'react'
import { Box, Select, MenuItem, Typography, useTheme, Chip, FormControl, InputLabel } from '@mui/material'

interface FormTypeSelectProps {
  label: string
  options: { value: string; label: string; color?: string }[]
  value: string
  onChange: (value: string) => void
}

const FormTypeSelect: React.FC<FormTypeSelectProps> = ({ label, value, options, onChange }) => {
  const theme = useTheme()

  return (
    <FormControl fullWidth>
      <InputLabel>{label ? label : 'Type'}</InputLabel>
      <Select value={value || ''} label={label ? label : 'Type'} onChange={e => onChange(e.target.value)} fullWidth>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                size='small'
                sx={{
                  backgroundColor: option?.color || 'transparent', // Fix: Set background color
                  width: 14,
                  height: 14,
                }}
              />
              <Typography>{option.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FormTypeSelect
