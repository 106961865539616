import React from 'react'
import { List, ListItem, Tooltip, ListItemIcon, ListItemButton, useTheme, Typography } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import GridViewIcon from '@mui/icons-material/GridView'
import ReceiptIcon from '@mui/icons-material/Receipt'
import AccountTreeIcon from '@mui/icons-material/AccountTree'

const MainNavigationSection = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  const navItems = [
    {
      label: 'Dashboard',
      path: 'dashboard',
      icon: <GridViewIcon sx={{ height: 22, width: 22, color: 'rgb(5, 190, 113)' }} />,
    },
    {
      label: 'Accounting',
      path: 'accounting',
      icon: <ReceiptIcon sx={{ height: 22, width: 22, color: 'rgba(107, 185, 240, 1)' }} />,
    },
    {
      label: 'Team Tasks',
      path: 'teamtasks',
      icon: <TaskAltIcon sx={{ height: 22, width: 22, color: 'rgb(247, 63, 236)' }} />,
    },
    {
      label: 'Boards',
      path: 'boards',
      icon: <AccountTreeIcon sx={{ height: 22, width: 22, color: 'rgb(247, 63, 134)' }} />,
    },
  ]

  return (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        m: 0, // remove default margins
      }}
    >
      {navItems.map(item => {
        const isSelected = item.path === selectedPath.replace(/^\//, '')

        return (
          <ListItem
            key={item.label}
            disablePadding
            sx={{
              display: 'inline-flex',
              width: 'auto',
            }}
          >
            <ListItemButton
              onClick={() => handleNavigation(item.path)}
              sx={{
                // Give each button consistent dimensions
                minWidth: 50, // or 60, adjust to taste
                minHeight: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isSelected ? bgSelectColor : 'transparent',
                '&:hover': {
                  backgroundColor: bgHoverColor,
                },
              }}
            >
              <Tooltip
                enterDelay={500}
                leaveDelay={0}
                title={<Typography p={1}>{item.label}</Typography>}
                placement='top'
              >
                <ListItemIcon
                  sx={{
                    // Remove margin so icon is truly centered
                    minWidth: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // p: 1,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </Tooltip>
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default MainNavigationSection
