import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { styled, useTheme } from '@mui/material/styles'

// Import the same sections you used in MiniDrawer:
import MainNavigationSection from './MainNavigation/MainNavigationSection'
import BoardsNavigation from './BoardsNavigation/BoardsNavigation'
import DevNavigationSection from './DevNavigation/DevNavigationSection'
import ConnectedUsersNav from './ConnectedUsersNav/ConnectedUsersNav'
import MenuItemsNavSection from './MenuItemsNavSection/MenuItemsNavSection'

import GroboticLogoDark from '../../images/GRObotic Logo-darkfull.png'
import GroboticLogoLight from '../../images/GRObotic Logo-full-lightmode.png'
import { WebsocketProvider } from '../../context/Websocket/WebsocketProvider'
import { Badge, FormControlLabel, IconButton, Switch, Typography } from '@mui/material'
import { CustomCheckbox } from '../DataGrid/style'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import GroboticVersionControl from '../Version'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import CreateMenu from '../CreateMenu/CreateMenu'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer'
import NotificationsNav from './NotificationNav/NotificationNav'
import ToggleThemeNav from './ToggleThemeNav/ToggleThemeNav'

const HorizontalNavSections = ({
  handleOpenCreateOrganization,
  handleOpenCreateDeal,
  handleOpenCreateContact,
  onOpenCreateTask,
  onCloseCreateTask,
  onOpenCreativeIntake,
  onCloseCreativeIntake,
  onOpenMarketingForm,
  onCloseMarketingForm,
  isConnectedUsersOpen,
  handleOpenConnectedUsers,
  handleCloseConnectedUsers,
  selectedPath,
  handleNavigation,
  user,
  devSectionIds,
  open,
  checked,
  onChecked,
}) => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()
  const HorizontalNavContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Step 1: spread out children (left, center, right)
    // backgroundColor: pinned ? theme.palette.background.default : 'transparent',
    backgroundColor: pinned ? theme.palette.background.section : 'transparent',
    // border: `2px solid ${theme.palette.text.primary}`,
    transition: 'filter 0.3s ease',
    width: '100%',
    backdropFilter: pinned ? 'blur(0px)' : 'blur(4px)',
    // padding: theme.spacing(1),
  }))

  return (
    <>
      <HorizontalNavContainer theme={theme}>
        {/* Left Box: Logo on the far left */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pl: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: 115 }}>
            {theme.palette.mode === 'dark' ? (
              <img src={GroboticLogoDark} style={{ width: '100%', height: 'auto' }} alt='logo' />
            ) : (
              <img src={GroboticLogoLight} style={{ width: '100%', height: 'auto' }} alt='logo' />
            )}
          </Box>
          <GroboticVersionControl />
        </Box>

        {/* Middle Box: All the nav items, centered */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'flex 0.3s ease',
            gap: 2,
            flex: 1, // allows this section to grow and center the items
          }}
        >
          <MainNavigationSection
            handleNavigation={handleNavigation}
            open={open}
            selectedPath={selectedPath}
            horizontal
          />
          <Divider orientation='vertical' flexItem />
          <BoardsNavigation handleNavigation={handleNavigation} open={open} selectedPath={selectedPath} horizontal />
          <Divider orientation='vertical' flexItem />

          {/* Possibly your DevNavigationSection if needed */}
          {/* {devSectionIds?.includes(user?.id) && (
          <>
            <DevNavigationSection
              handleNavigation={handleNavigation}
              open={open}
              selectedPath={selectedPath}
              horizontal
            />
            <Divider orientation='vertical' flexItem />
          </>
        )} */}

          {/* Connected Users */}
          <ConnectedUsersNav
            open={open}
            isConnectedUsersOpen={isConnectedUsersOpen}
            handleOpenConnectedUsers={handleOpenConnectedUsers}
            handleCloseConnectedUsers={handleCloseConnectedUsers}
          />
          <NotificationsNav user={user} />
          <ToggleThemeNav />
          <Divider orientation='vertical' flexItem />

          <CreateMenu
            onOpenCreateOrganization={handleOpenCreateOrganization}
            onOpenCreateDeal={handleOpenCreateDeal}
            onOpenCreateContact={handleOpenCreateContact}
            onOpenCreateTask={onOpenCreateTask}
            onCloseCreateTask={onCloseCreateTask}
            onOpenCreativeIntake={onOpenCreativeIntake}
            onCloseCreativeIntake={onCloseCreativeIntake}
            onOpenMarketingForm={onOpenMarketingForm}
            onCloseMarketingForm={onCloseMarketingForm}
          />
        </Box>

        {/* Right Box: Checkbox on the far right */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!checked ? (
            <LockOpenIcon sx={{ height: 18, width: 18, color: theme.palette.text.secondary }} />
          ) : (
            <LockIcon sx={{ height: 18, width: 18, color: theme.palette.text.secondary }} />
          )}
          <CustomCheckbox label='Keep Open' checked={checked} onChange={onChecked} sx={{ mr: 2 }} />
        </Box>
      </HorizontalNavContainer>
    </>
  )
}

export default HorizontalNavSections
