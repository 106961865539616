import React, { ReactNode } from 'react'
import { Box, Popover } from '@mui/material'

interface CustomFiltersModalProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  children: ReactNode
  handleCloseCustomFilters: () => void
}

const CustomFiltersModal: React.FC<CustomFiltersModalProps> = ({
  isOpen,
  anchorEl,
  children,
  handleCloseCustomFilters,
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)} // Ensure it only opens when anchorEl exists
      anchorEl={anchorEl}
      onClose={handleCloseCustomFilters}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2 }}>{children}</Box>
    </Popover>
  )
}

export default CustomFiltersModal
