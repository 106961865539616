import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const DrawerContext = createContext()

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const navigate = useNavigate()
  const [selectedTask, setSelectedTask] = useState(null)

  // const openDrawer = context => {
  //   if (context === 'teamtasks') {
  //     setIsDrawerOpen(true)
  //   } else if (context === 'creative') {
  //     setIsDrawerOpen(true)
  //   }
  // }
  // const closeDrawer = context => {
  //   if (context === 'teamtasks') {
  //     setIsDrawerOpen(false)
  //   } else if (context === 'creative') {
  //     setIsDrawerOpen(false)
  //   }
  // }
  const openDrawer = () => {
    setIsDrawerOpen(true)
  }
  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }

  const drawerState = {
    openDrawer,
    selectedTask,
    setSelectedTask,
    closeDrawer,
    isDrawerOpen,
  }

  return <DrawerContext.Provider value={drawerState}>{children}</DrawerContext.Provider>
}

export const useDrawerContext = () => useContext(DrawerContext)
