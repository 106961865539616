import React, { useEffect, useContext, useState } from 'react'
import { useGetDocuments } from '../../api/PandaDoc/useGetDocuments'
import Loading from '../../components/Loading/Loading'
import { useGetTemplates } from '../../api/PandaDoc/useGetTemplates'
import { Box, TableContainer, Table, useTheme, Container } from '@mui/material'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { documentData } from './documentData'
import DocumentList from './DocumentList'
import { Link as ReactLink } from 'react-router-dom'
import { slugify } from '../../helperFunctions/slugify'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const Documents = () => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()
  const rowBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.6)' : 'rgb(0, 0, 0, 0.1)'
  const csaTemplate = 'wMtKSooN8KnGtPw4pxKVGL'
  // const { data: pandaDocuments, isLoading: pandaDocumentsLoading, isError: pandaDocumentsError } = useGetDocuments()
  // const { products, isProductsLoading, isProductsError } = useProducts(true)

  // const { data: pandaTemplates, isLoading: pandaTemplatesLoading, isError: pandaTemplatesError } = useGetTemplates(
  //   csaTemplate
  // )
  // console.log('pandaTemplates: ', pandaTemplates)
  const {
    data: pandaDocuments,
    isLoading: pandaDocumentsLoading,
    isError: pandaDocumentsError,
    error: errorPandaDocuments,
  } = useGetDocuments()
  // console.log('PANDA TEMPLATE: ', pandaTemplates)
  // const {
  //   data: pandaContacts,
  //   isLoading: pandaContactsLoading,
  //   isError: pandaContactsError,
  // } = useGetPandaContacts()
  //   const {
  //     data: pandaproductss,
  //     isLoading: pandaproductssLoading,
  //     isError: pandaproductssError,
  //   } = useGetproducts()

  const { mutate: createDocument } = useCreateDocument()
  const handleSubmit = e => {
    e.preventDefault()
    console.log('documentData: ', documentData)

    const pandaContract = createDocument(documentData)
    if (pandaContract.status === 200) {
      const currentDocId = pandaContract.id
    }
  }
  const isLoading = pandaDocumentsLoading

  const isError = pandaDocumentsError

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    return <div>PandaDoc Documents Error...</div>
  }
  // if (pandaDocumentsLoading || productsLoading || pandaDocumentsLoading) {
  //   return <Loading />
  // }
  // if (pandaDocumentsError || productsError) {
  //   return <div>error panda documents...</div>
  // }
  const previewUrl = `https://app.pandadoc.com/a/#/documents`

  // console.log('PandaDoc Documents: ', pandaDocuments)
  // console.log('PandaDoc Product Catalog: ', products)
  // console.log('PandaDoc Templates: ', pandaTemplates)
  //   console.log('PandaDoc Contacts: ', pandaContacts)
  return (
    <Container
      sx={{
        height: pinned ? 'calc(100vh - 60px)' : '100vh',
        overflowY: 'auto',
        pt: pinned ? '0px' : 1,
        mt: pinned ? '60px' : 1,
        transition: 'all 0.3s ease',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '85vw',
          marginTop: '5px',
        }}
      >
        {/* <Button onClick={handleSubmit}>Submit Contract</Button> */}
        {pandaDocuments
          ? pandaDocuments.results.map(document => (
              <TableContainer
                container
                key={document.id}
                sx={{
                  width: '100%',
                  margin: '0 auto',
                  marginTop: '1px',
                  '&:hover': {
                    transform: 'scale(1.01)',
                    // margin: '-1px', // Slightly reduce the margin to compensate for the transform
                    zIndex: 1, // Ensure the scaled item is above others if they overlap
                  },
                  transition: 'all 0.3s ease-in-out', // Include margin in the transition for a smooth effect
                }}
              >
                <ReactLink
                  to={`/pandadocs/${slugify(document.name)}`}
                  state={{ document }}
                  style={{ textDecoration: 'none' }}
                >
                  <Table sx={{ minWidth: 650 }}>
                    {/* <TableBody> */}

                    <DocumentList document={document} previewUrl={previewUrl} rowBackgroundColor={rowBackgroundColor} />
                    {/* </TableBody> */}
                  </Table>
                </ReactLink>
              </TableContainer>
            ))
          : null}
      </Box>
    </Container>
  )
}

export default Documents
