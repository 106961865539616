import React from 'react'
import { Grid, Typography, TextField, useTheme } from '@mui/material'
import { fieldStyles } from '../style'
import { useUpdateItems } from '../../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { useQueryClient } from '@tanstack/react-query'

const IntegrationFields = ({ deal, formData, onInputChange }) => {
  const queryClient = useQueryClient()
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()

  const handleBlur = (fieldName, newValue) => {
    if (deal[fieldName] !== newValue) {
      const updates = [
        {
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: deal?.deal_uuid,
          fieldName,
          newValue,
        },
      ]

      updateItems(
        { updates },
        {
          onSuccess: () => {
            showSnackbar(`Update Successful for ${fieldName}`)
            queryClient.invalidateQueries(['deal', deal?.deal_uuid])
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }
  return (
    <>
      {/* Conditional Inputs */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant='subtitle1'>Account Fields</Typography>
        {deal?.isGoogleSearch || deal?.isGoogleDisplay ? (
          <TextField
            label='Google Ads Account Id'
            value={formData?.googleAdsId ?? ''}
            onChange={e => onInputChange('googleAdsId', e.target.value)}
            onBlur={e => handleBlur('googleAdsId', e.target.value)}
            fullWidth
            margin='dense'
            sx={{ ...fieldStyles(theme.palette.background.default) }}
          />
        ) : null}
        {deal?.isMetaAdvertising ? (
          <>
            <TextField
              label='Meta Account Id'
              value={formData?.metaAccountId ?? ''}
              onChange={e => onInputChange('metaAccountId', e.target.value)}
              onBlur={e => handleBlur('metaAccountId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
            <TextField
              label='Meta Pixel Id'
              value={formData?.metaPixelId ?? ''}
              onChange={e => onInputChange('metaPixelId', e.target.value)}
              onBlur={e => handleBlur('metaPixelId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
          </>
        ) : null}
        {deal?.isTikTok ? (
          <>
            <TextField
              label='TikTok Account Id'
              value={formData?.tiktokAccountId ?? ''}
              onChange={e => onInputChange('tiktokAccountId', e.target.value)}
              onBlur={e => handleBlur('tiktokAccountId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
            <TextField
              label='TikTok Pixel Id'
              value={formData?.tiktokPixelId ?? ''}
              onChange={e => onInputChange('tiktokPixelId', e.target.value)}
              onBlur={e => handleBlur('tiktokPixelId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
          </>
        ) : null}
        {deal?.isSimplifi ? (
          <TextField
            label='Simplifi Organization Id'
            value={formData?.simplifiOrgId ?? ''}
            onChange={e => onInputChange('simplifiOrgId', e.target.value)}
            onBlur={e => handleBlur('simplifiOrgId', e.target.value)}
            fullWidth
            margin='dense'
            sx={{ ...fieldStyles(theme.palette.background.default) }}
          />
        ) : null}
        {deal?.isMicrosoftAdvertising ? (
          <>
            <TextField
              label='Bing Account Id'
              value={formData?.bingAccountId ?? ''}
              onChange={e => onInputChange('bingAccountId', e.target.value)}
              onBlur={e => handleBlur('bingAccountId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
            <TextField
              label='Bing UET Id'
              value={formData?.bingUetId ?? ''}
              onChange={e => onInputChange('bingUetId', e.target.value)}
              onBlur={e => handleBlur('bingUetId', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />
          </>
        ) : null}
        {deal?.isWebsiteBuild ? (
          <>
            {/* Field for Website Template URL */}
            <TextField
              label='Website Template URL'
              value={formData?.websiteTemplateUrl ?? ''}
              onChange={e => onInputChange('websiteTemplateUrl', e.target.value)}
              onBlur={e => handleBlur('websiteTemplateUrl', e.target.value)}
              fullWidth
              margin='dense'
              sx={{ ...fieldStyles(theme.palette.background.default) }}
            />

            {/* If the deal indicates additional pages should be added */}
            {deal?.isAddPage && (
              <>
                <TextField
                  label='Number of Website Pages'
                  value={formData?.websitePages ?? ''}
                  onChange={e => onInputChange('websitePages', e.target.value)}
                  onBlur={e => handleBlur('websitePages', e.target.value)}
                  type='number'
                  fullWidth
                  margin='dense'
                  sx={{ ...fieldStyles(theme.palette.background.default) }}
                />
                <TextField
                  label='Additional Website Notes'
                  value={formData?.websiteNotes ?? ''}
                  onChange={e => onInputChange('websiteNotes', e.target.value)}
                  onBlur={e => handleBlur('websiteNotes', e.target.value)}
                  fullWidth
                  margin='dense'
                  multiline
                  rows={3}
                  sx={{ ...fieldStyles(theme.palette.background.default) }}
                />
              </>
            )}
          </>
        ) : null}
      </Grid>

      {/* Notes Section */}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant='subtitle1'>Addt'l Notes Section</Typography>
        <TextField
          label='Website Notes'
          value={formData?.websiteNotes ?? ''}
          onChange={e => onInputChange('websiteNotes', e.target.value)}
          onBlur={e => handleBlur('websiteNotes', e.target.value)}
          fullWidth
          multiline
          rows={3}
          margin='dense'
          sx={{ ...fieldStyles(theme.palette.background.default) }}
        />
        <TextField
          label='Tracked Conversions'
          value={formData?.trackedConversions ?? ''}
          onChange={e => onInputChange('trackedConversions', e.target.value)}
          onBlur={e => handleBlur('trackedConversions', e.target.value)}
          fullWidth
          multiline
          rows={3}
          margin='dense'
          sx={{ ...fieldStyles(theme.palette.background.default) }}
        />
      </Grid>
    </>
  )
}

export default IntegrationFields
