import { useLocation, useNavigate } from 'react-router-dom'

type ChangeViewProps = {
  newView: string
}

export const useChangeView = (): ((props: ChangeViewProps) => void) => {
  const navigate = useNavigate()
  const location = useLocation()
  return ({ newView }: ChangeViewProps): void => {
    console.log('[useChangeView] newView>>>>>>>>>>>>', newView)
    const routes: Record<string, string> = {
      nco: '/nco',
      creative: '/creative',
      seo: '/seo',
      website: '/website',
      mtb: '/mtb',
      deals: '/deals',
      organizations: '/organizations',
      contacts: '/contacts',
      leads: '/leads',
      teamtasks: '/teamtasks',
      campaignchecklists: '/campaignchecklists',
      ads: '/ads',
    }

    if (routes[newView]) {
      localStorage.setItem('boardSelectView', JSON.stringify(newView))
      navigate(routes[newView])
    } else {
      console.warn('Invalid view option:', newView)
    }
  }
}
