import React from 'react'
import { Badge, Box, IconButton, Tooltip, useTheme } from '@mui/material'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'

interface StrategyLinkButtonProps {
  linkUrl: string
  //   onClick: (event: React.MouseEvent<HTMLElement>) => void
}

const StrategyLinkButton: React.FC<StrategyLinkButtonProps> = ({ linkUrl }) => {
  const theme = useTheme()

  const handleClick = () => {
    window.open(linkUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <Tooltip title={'Link to Strategy Template'}>
      <Box>
        <IconButton
          sx={{
            borderRadius: '0px',
          }}
          onClick={handleClick}
          disabled={!linkUrl}
        >
          <TrackChangesIcon
            sx={{
              fontSize: '28px',
              color: linkUrl ? theme.palette.icon.deals : theme.palette.text.secondary,
            }}
          />
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export default StrategyLinkButton
