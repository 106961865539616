import React, { useMemo } from 'react'
import { Grid, Typography, FormControlLabel, Box, useTheme, CircularProgress } from '@mui/material'
import { CustomCheckbox } from '../style.js'
import DrawerLoading from '../../Loading/DrawerLoading.js'
import { useLayoutContext } from '../../../context/LayoutProvider/LayoutProvider.js'

const WebsiteChecklists = ({
  deal,
  formData,
  setFormData,
  handleStatusChange,
  checklists,
  isLoading,
  expanded,
  context,
}) => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()

  // Modified handleChange: no extra "section" parameter
  const handleChange = (checklist, itemId, currentStatus) => {
    console.log(checklist, itemId, currentStatus)
    const newStatus = currentStatus === 'Done' ? 'In Progress' : 'Done'
    // Call parent's function with the required parameters only
    handleStatusChange(checklist, itemId, newStatus)
  }
  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }
  const gridHeight = useMemo(() => {
    const baseOffset = context === 'nco' ? 245 : 195
    const additionalOffset = expanded ? 120 : 0
    return `calc(100vh - ${baseOffset + additionalOffset}px)`
  }, [context, expanded])
  if (isLoading) return <DrawerLoading />

  if (!checklists || checklists.length === 0) {
    return <Typography>No checklists found.</Typography>
  }
  return (
    <Grid container spacing={2} p={2}>
      {/* Left Column: Checklists */}
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            // height: pinned ? 'calc(100vh - 180px)' : 'calc(100vh - 240px)',
            height: gridHeight,
            overflowY: 'auto',
            pr: 1, // optional, add some right padding
            transition: 'height 0.3s ease',
          }}
        >
          <Grid container spacing={2}>
            {checklists?.map(checklist => (
              <Grid item xs={12} key={checklist.SK}>
                <Box sx={{ p: 2, borderRadius: '8px', bgcolor: 'background.section' }}>
                  <Typography variant='h6' sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                    {checklist?.type} Checklist
                  </Typography>
                  {checklist?.sections?.length > 0 ? (
                    checklist.sections.map(section => (
                      <Box key={section.title} sx={{ mt: 2 }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            fontSize: 18,
                            color: theme.palette.text.warning,
                          }}
                          variant='subtitle1'
                        >
                          {section.title}
                        </Typography>
                        {section?.items?.map(item => (
                          <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <FormControlLabel
                              control={
                                <CustomCheckbox
                                  checked={item.status === 'Done'}
                                  onChange={() => handleChange(checklist, item.id, item.status)}
                                />
                              }
                              label={item.title}
                            />
                          </Box>
                        ))}
                      </Box>
                    ))
                  ) : (
                    <Typography>No sections available.</Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WebsiteChecklists
