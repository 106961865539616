import React from 'react'
import { Grid, TextField, Box, FormControl } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const DynamicSection = ({ optimizationDetails, onDateChange, onChangeOpts, onBlurOpts, localOpts, selectedType }) => {
  return (
    <>
      {selectedType === 'seo' ? (
        <>
          <Grid item xs={12}>
            <TextField
              key={`seo-name`}
              label='Name'
              value={optimizationDetails.optimization?.name || ''}
              fullWidth
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              key={`seo-opts`}
              label='# of Opts'
              type='number'
              value={localOpts}
              onChange={onChangeOpts}
              onBlur={onBlurOpts}
              fullWidth
              margin='normal'
              inputProps={{ min: 0, max: 10 }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={9}>
            <TextField
              key={`mtb-name`}
              label='Name'
              value={optimizationDetails.optimization?.name || ''}
              fullWidth
              margin='normal'
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl fullWidth>
                    <DatePicker
                      label='Follow Up Date'
                      // If followDate exists, convert it to a dayjs instance; otherwise, pass null.
                      value={optimizationDetails.followDate ? dayjs(optimizationDetails.followDate) : null}
                      // Wrap the onChange so that it passes a string (or appropriate type) to your handler.
                      onChange={newDate => {
                        // newDate is a Dayjs object or null.
                        const dateString = newDate ? newDate.toISOString() : ''
                        onDateChange({ target: { value: dateString } } as React.ChangeEvent<HTMLInputElement>)
                      }}
                      slotProps={{
                        textField: { fullWidth: true },
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  key='mtb-opts'
                  label='# of Opts'
                  type='number'
                  value={localOpts}
                  onChange={onChangeOpts}
                  onBlur={onBlurOpts}
                  fullWidth
                  margin='normal'
                  inputProps={{ min: 0, max: 10 }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}

export default DynamicSection
