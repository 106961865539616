import React, { useEffect, useRef, useState } from 'react'
import { Grid, Box, useTheme, Button, Typography, Tooltip, Chip, IconButton, CircularProgress } from '@mui/material'
import MassUpdateVertical from '../MassUpdateComponents/MassUpdateVertical'
import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import OrganizationsPopover from './OrganizationsPopover'
import { deleteButtonStyles } from './style'
import useUploadOrganizationPhoto from '../../api/customHooks/useUploadOrganizationPhoto'
import { GridFooterContainer } from '@mui/x-data-grid-pro'
import { EditButton } from '../Deals/style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import MassUpdateContractedBy from '../MassUpdateComponents/MassUpdateContractedBy'
import MassUpdateUserSelect from '../TeamTasks/MassUpdateUserSelect.tsx'

const orgTypeOptions = [
  {
    key: 'PMC-TYPE-OPTION',
    label: 'Property Management Company',
    type: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { key: 'OG-TYPE-OPTION', label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { key: 'HP-TYPE-OPTION', label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
  { key: 'PC-TYPE-OPTION', label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { key: 'AGWL-TYPE-OPTION', label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  {
    key: 'ORG-TYPE-OPTION',
    label: 'Organization',
    type: 'ORG',
    color: 'rgb(242, 140, 40)',
  },
]
const orgStatusOptions = [
  { label: 'Prospect', type: 'Prospect', color: 'rgb(93, 79, 251)' },
  { label: 'Active', type: 'Active', color: 'rgb(152, 214, 129)' },
  { label: 'Onboarding', type: 'Onboarding', color: 'rgb(92, 179, 253)' },
  { label: 'In-Progress', type: 'In-Progress', color: 'rgb(110, 156, 222)' },
  { label: 'Active (SEO)', type: 'Active (SEO)', color: 'rgb(152, 98, 161)' },
  {
    label: 'Active (Creative)',
    type: 'Active (Creative)',
    color: 'rgb(147, 111, 218)',
  },
  {
    label: 'Active (Website)',
    type: 'Active (Website)',
    color: 'rgb(102, 69, 169)',
  },
  {
    label: 'Active (Past Due)',
    type: 'Active (Past Due)',
    color: 'rgb(255, 131, 88)',
  },
  { label: 'Active (LTP)', type: 'Active (LTP)', color: 'rgb(51, 158, 205)' },
  { label: '30 day notice', type: '30 day notice', color: 'rgb(235, 77, 77)' },
  { label: 'Cancelled', type: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    label: 'Inactive / Paused',
    type: 'Inactive / Paused',
    color: 'rgb(255, 145, 145)',
  },
]
interface BoardsGridFooterProps {
  users: object[]
  isLoading: boolean
  massUpdateData: any
  setMassUpdateData: React.Dispatch<React.SetStateAction<object | null>>
  selectionModel: string[]
  rowCount: string
  onChange: (e: any) => void
  onUpdate: (massUpdateData: any, selectionModel: string[]) => void
  onClear: () => void
  deals: string[]
  anchorEl: HTMLElement | null
  selectedData: object
  setSelectedData: React.Dispatch<React.SetStateAction<string | null>>
  setSelectionModel: React.Dispatch<React.SetStateAction<string[] | []>>
  selectedRole: string
  setSelectedRole: React.Dispatch<React.SetStateAction<string | null>>
}
const BoardsGridFooter: React.FC<BoardsGridFooterProps> = ({
  users,
  isLoading,
  massUpdateData,
  setMassUpdateData,
  selectionModel = [],
  setSelectionModel,
  rowCount,
  onChange,
  onUpdate,
  deals = [],
  anchorEl,
  selectedRole,
  setSelectedRole,
}) => {
  const theme = useTheme()

  const orgGridStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    pb: 0.5,
    pl: 1.5,
    pr: 0.5,
    pt: 1,
    mt: 0.5,
  }
  return (
    <GridFooterContainer>
      {' '}
      {/* ✅ Attach the ref here */}
      {selectionModel.length > 0 ? (
        <Grid
          container
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            alignItems: 'center',
            zIndex: 1,
            flexWrap: 'wrap',
            overflow: 'hidden',
          }}
        >
          <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={3} sx={orgGridStyles}>
              <Typography variant='h8'>Update</Typography>
              <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
              <Typography variant='h8'>{selectionModel.length === 1 ? `Deal` : 'Deals'}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={orgGridStyles}>
              <MassUpdateUserSelect
                name='onboardingTeam'
                value={massUpdateData?.onboardingTeam || []}
                options={users}
                displayLabel='Team'
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={2} sm={2.5} md={2.5} lg={2.5} xl={2.5} sx={orgGridStyles} justifyContent={'flex-end'}>
              <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'} placement='top'>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    onUpdate(massUpdateData, selectionModel)
                    setMassUpdateData({})
                  }}
                  disabled={selectionModel.length === 0}
                  sx={{ height: 40 }}
                >
                  {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            padding: 1,
            alignItems: 'center',
            minHeight: 50,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            gap: 1,
          }}
          justifyContent='flex-start'
        >
          <Grid item>
            <Typography variant='body2' mr={2}>
              Total Rows:
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
              {rowCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              /
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              {deals.length}
            </Typography>
          </Grid>
        </Grid>
      )}
    </GridFooterContainer>
  )
}

export default BoardsGridFooter
