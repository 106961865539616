import { Tooltip, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

const formatKeys = (key: string) => {
  switch (key) {
    case 'active':
      return 'Active'
    case 'inProgress':
      return 'In Progress'
    case 'monthToMonth':
      return 'Month To Month'
    case 'dayNotice':
      return '30 Day Notice'
    default:
      return 'No Status'
  }
}
const HoverPath = styled('path')({
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'scale(1.01)',
  },
})

// Helper: convert polar coordinates to Cartesian coordinates
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

// Helper: describe an arc path between startAngle and endAngle
const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
  return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')
}

const MiniPieChart = ({ rowId, data, size = 24, strokeWidth = 4 }) => {
  const theme = useTheme()
  // Calculate the total value
  const total = data.reduce((sum, d) => sum + d.value, 0)
  const textColor =
    total === 1 ? theme.palette.text.highlight : total === 2 ? theme.palette.text.warning : 'rgb(209, 75, 102)'
  // Determine the radius so that the stroke fits within the viewBox
  const radius = (size - strokeWidth) / 2

  // If total is 0, render a placeholder circle
  if (total === 0) {
    return (
      <svg key={rowId} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle cx={size / 2} cy={size / 2} r={radius} fill='none' stroke='lightgray' strokeWidth={strokeWidth} />
      </svg>
    )
  }

  // We'll accumulate the current angle as we render each segment.
  let currentAngle = 0

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ display: 'block', margin: '0 auto' }}>
      {data.map((d, index) => {
        const percentage = d.value / total
        // If this segment covers the entire circle, draw a full circle.
        if (percentage === 1) {
          return (
            // <Tooltip key={`${rowId}-${index}`} title={<Typography>{`${formatKeys(d.label)}: ${d.value}`}</Typography>}>
            <circle
              key={`${rowId}-${index}`}
              cx={size / 2}
              cy={size / 2}
              r={radius}
              fill='none'
              stroke={d.color}
              strokeWidth={strokeWidth}
              strokeLinecap='butt'
            />
            // </Tooltip>
          )
        }
        // If the value is 0, skip drawing this segment.
        if (d.value <= 0) return null

        const angle = percentage * 360
        const startAngle = currentAngle
        const endAngle = currentAngle + angle
        currentAngle += angle
        const dPath = describeArc(size / 2, size / 2, radius, startAngle, endAngle)

        return (
          // <Tooltip key={`${rowId}-${index}`} title={<Typography>{`${formatKeys(d.label)}: ${d.value}`}</Typography>}>
          <path key={index} d={dPath} fill='none' stroke={d.color} strokeWidth={strokeWidth} strokeLinecap='butt' />
          // </Tooltip>
        )
      })}
      {/* Centered text displaying the total count */}
      <text
        x={size / 2}
        y={size / 2}
        textAnchor='middle'
        dominantBaseline='central'
        fontSize={size * 0.4}
        style={{ fill: textColor }}
      >
        {total}
      </text>
    </svg>
  )
}

export default MiniPieChart
