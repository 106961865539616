import React from 'react'
import { Grid, useTheme, Button, Typography, Tooltip, Chip, Box, CircularProgress, IconButton } from '@mui/material'
import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
import { GridFooterContainer } from '@mui/x-data-grid-pro'
import { taskTypeList } from '../DataGrid/filterArrays'
import { taskMasterStatusOptions } from '../DataGrid/filterArrays'
import MassUpdateUserSelect from '../TeamTasks/MassUpdateUserSelect.tsx'
import DeleteIcon from '@mui/icons-material/Delete'
import TasksPopover from './TasksPopover.js'
import './style.css'

export interface TasksGridFooterProps {
  users: object[]
  selectedData: object
  isLoading: boolean
  massUpdateData: any
  selectionModel: string[]
  rowCount: number
  onChange: (e: any) => void
  onUpdate: (massUpdateData: any, selectionModel: string[]) => void
  onClear: () => void
  onClickDelete: () => void
  onDelete: () => void
  isDeleting: boolean
  onClosePopover: () => void
  tasks: any[]
  anchorEl: HTMLElement | null
  context: string
  // ... any additional props you need
}

const TasksGridFooter: React.FC<TasksGridFooterProps> = ({
  users,
  selectedData,
  isLoading,
  massUpdateData,
  selectionModel = [],
  rowCount,
  onChange,
  onUpdate,
  onClear,
  onClickDelete,
  onDelete,
  isDeleting,
  onClosePopover,
  tasks = [],
  anchorEl,
  context,
}) => {
  const theme = useTheme()
  const taskGridStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    pb: 0.5,
    pl: 1.5,
    pr: 1.5,
    pt: 1,
    mt: 0.5,
  }

  const open = Boolean(anchorEl)
  const id = open ? 'tasks-grid-footer-popover' : undefined
  return (
    <GridFooterContainer>
      {selectionModel.length > 0 ? (
        <Grid
          container
          sx={{
            maxHeight: 'auto',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            alignItems: 'center',
            zIndex: 1,
            flexWrap: 'wrap',
            overflow: 'hidden',
          }}
        >
          <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={taskGridStyles}>
              <Typography variant='h8'>{'Update'}</Typography>
              <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
              <Typography variant='h8'>{selectionModel.length === 1 ? 'Task' : 'Tasks'}</Typography>
            </Grid>

            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={taskGridStyles} justifyContent='flex-end'>
              <MassUpdateTypeSelect
                name='type'
                value={massUpdateData.type || ''}
                options={taskTypeList}
                displayLabel='Type'
                onChange={onChange}
                disabled={
                  (massUpdateData.masterStatus?.length || 0) > 0 ||
                  (massUpdateData.AM?.length || 0) > 0 ||
                  (massUpdateData.DMS?.length || 0) > 0 ||
                  (massUpdateData.SEO?.length || 0) > 0
                }
                sx={{ height: 40, minWidth: 150 }}
              />
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} sx={taskGridStyles} justifyContent='flex-end'>
              <MassUpdateTypeSelect
                name='masterStatus'
                value={massUpdateData.masterStatus || ''}
                options={taskMasterStatusOptions}
                displayLabel='Master Status'
                onChange={onChange}
                disabled={
                  (massUpdateData.type?.length || 0) > 0 ||
                  (massUpdateData.AM?.length || 0) > 0 ||
                  (massUpdateData.DMS?.length || 0) > 0 ||
                  (massUpdateData.SEO?.length || 0) > 0
                }
                sx={{ height: 40, minWidth: 150 }}
              />
            </Grid>
            <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5} sx={taskGridStyles} justifyContent='flex-end'>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {/* <MassUpdateUserSelect
                    name='AM'
                    value={massUpdateData.AM || []}
                    options={users}
                    displayLabel='AM'
                    onChange={onChange}
                    disabled={
                      (massUpdateData.masterStatus?.length || 0) > 0 ||
                      (massUpdateData.type?.length || 0) > 0 ||
                      (massUpdateData.DMS?.length || 0) > 0 ||
                      (massUpdateData.SEO?.length || 0) > 0 ||
                      (massUpdateData.MIA?.length || 0) > 0
                    }
                  />
                  <MassUpdateUserSelect
                    name='DMS'
                    value={massUpdateData.DMS || ''}
                    options={users} // Or pass your list of users for DMS
                    displayLabel='DMS'
                    onChange={onChange}
                    // Optionally disable if another field is active:
                    disabled={
                      (massUpdateData.masterStatus?.length || 0) > 0 ||
                      (massUpdateData.type?.length || 0) > 0 ||
                      (massUpdateData.AM?.length || 0) > 0 ||
                      (massUpdateData.SEO?.length || 0) > 0 ||
                      (massUpdateData.MIA?.length || 0) > 0
                    }
                  />
                  <MassUpdateUserSelect
                    name='SEO'
                    value={massUpdateData.SEO || ''}
                    options={users} // Or pass your list of users for SEO
                    displayLabel='SEO'
                    onChange={onChange}
                    // Optionally disable if another field is active:
                    disabled={
                      (massUpdateData.masterStatus?.length || 0) > 0 ||
                      (massUpdateData.type?.length || 0) > 0 ||
                      (massUpdateData.DMS?.length || 0) > 0 ||
                      (massUpdateData.AM?.length || 0) > 0 ||
                      (massUpdateData.MIA?.length || 0) > 0
                    }
                  />
                  <MassUpdateUserSelect
                    name='MIA'
                    value={massUpdateData.MIA || ''}
                    options={users} // Or pass your list of users for SEO
                    displayLabel='MIA'
                    onChange={onChange}
                    // Optionally disable if another field is active:
                    disabled={
                      (massUpdateData.masterStatus?.length || 0) > 0 ||
                      (massUpdateData.type?.length || 0) > 0 ||
                      (massUpdateData.DMS?.length || 0) > 0 ||
                      (massUpdateData.AM?.length || 0) > 0 ||
                      (massUpdateData.SEO?.length || 0) > 0
                    }
                  /> */}
                  <MassUpdateUserSelect
                    name='assignees'
                    value={massUpdateData.assignees || []}
                    options={users} // Or pass your list of users for SEO
                    displayLabel='Assignee(s)'
                    onChange={onChange}
                    // Optionally disable if another field is active:
                    disabled={
                      (massUpdateData.masterStatus?.length || 0) > 0 ||
                      (massUpdateData.type?.length || 0) > 0 ||
                      (massUpdateData.DMS?.length || 0) > 0 ||
                      (massUpdateData.AM?.length || 0) > 0 ||
                      (massUpdateData.SEO?.length || 0) > 0
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={2} sm={2.5} md={2.5} lg={2.5} xl={2.5} sx={taskGridStyles} justifyContent='flex-end'>
              <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'} placement='top'>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    onUpdate(massUpdateData, selectionModel)
                    onClear()
                  }}
                  disabled={selectionModel.length === 0}
                  sx={{ height: 40 }}
                >
                  {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                </Button>
              </Tooltip>
              <Button
                color='secondary'
                variant='contained'
                onClick={onClear}
                disabled={!massUpdateData?.type?.length && !massUpdateData?.masterStatus?.length}
                sx={{ height: 40 }}
              >
                Clear
              </Button>
              {selectionModel?.length === 1 && (
                <Tooltip title={'Delete Task'} placement='top'>
                  <IconButton color='inherit' onClick={onClickDelete}>
                    <DeleteIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            padding: 1,
            alignItems: 'center',
            minHeight: 50,
            bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
            gap: 1,
          }}
          justifyContent='flex-start'
        >
          <Grid item>
            <Typography variant='body2' mr={2}>
              Total Rows:
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
              {rowCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              /
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              {tasks.length}
            </Typography>
          </Grid>
        </Grid>
      )}
      <TasksPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        handleConfirmDelete={onDelete}
        selectedData={selectedData}
        context={context}
      />
    </GridFooterContainer>
  )
}

export default TasksGridFooter
// import React, { useEffect, useRef, useState } from 'react'
// import {
//   Grid,
//   useTheme,
//   Button,
//   Typography,
//   Tooltip,
//   Chip,
//   Box,
//   CircularProgress,
//   IconButton,
//   Popper,
//   Paper,
// } from '@mui/material'
// import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
// import { GridFooterContainer } from '@mui/x-data-grid-pro'
// import { taskTypeList } from '../DataGrid/filterArrays'
// import { masterStatusList } from '../Tasks/CreateTasks/taskObjects/masterStatusList'
// import MassUpdateUserSelect from '../TeamTasks/MassUpdateUserSelect.tsx'
// import DeleteIcon from '@mui/icons-material/Delete'
// import TasksPopover from './TasksPopover.js'
// import './style.css'

// export interface TasksGridFooterProps {
//   users: object[]
//   selectedData: object
//   isLoading: boolean
//   massUpdateData: any
//   selectionModel: string[]
//   setSelectionModel: React.Dispatch<React.SetStateAction<string | null>>
//   rowCount: number
//   onChange: (e: any) => void
//   onUpdate: (massUpdateData: any, selectionModel: string[]) => void
//   onClear: () => void
//   onClickDelete: () => void
//   onDelete: () => void
//   isDeleting: boolean
//   onClosePopover: () => void
//   tasks: any[]
//   anchorEl: HTMLElement | null
//   context: string
//   selectedRole: string
//   setSelectedRole: React.Dispatch<React.SetStateAction<string | null>>
// }

// const TasksGridFooter: React.FC<TasksGridFooterProps> = ({
//   users,
//   selectedData,
//   isLoading,
//   massUpdateData,
//   selectionModel = [],
//   setSelectionModel,
//   rowCount,
//   onChange,
//   onUpdate,
//   onClear,
//   onClickDelete,
//   onDelete,
//   isDeleting,
//   onClosePopover,
//   tasks = [],
//   anchorEl,
//   context,
//   selectedRole,
//   setSelectedRole,
//   selectedCell,
// }) => {
//   const theme = useTheme()
//   const taskGridStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     gap: 1,
//     pb: 0.5,
//     pl: 1.5,
//     pr: 1.5,
//     pt: 1,
//     mt: 0.5,
//   }

//   const open = Boolean(anchorEl)
//   const id = open ? 'tasks-grid-footer-popover' : undefined
//   const [activeRole, setActiveRole] = useState(null)
//   const footerRef = useRef<HTMLDivElement | null>(null) // ✅ Footer ref
//   const roleRefs = {
//     AM: React.useRef<HTMLInputElement>(null),
//     DMS: React.useRef<HTMLInputElement>(null),
//     SEO: React.useRef<HTMLInputElement>(null),
//     MIA: React.useRef<HTMLInputElement>(null),
//     creativeLead: React.useRef<HTMLInputElement>(null),
//   }

//   // ✅ Auto-focus when the selectedRole changes
//   useEffect(() => {
//     if (selectedRole && roleRefs[selectedRole]) {
//       roleRefs[selectedRole].current?.focus()
//       setActiveRole(roleRefs[selectedRole].current)
//     }
//   }, [selectedRole])
//   // useEffect(() => {
//   //   const handleClickOutside = (event: MouseEvent) => {
//   //     if (
//   //       footerRef.current &&
//   //       footerRef.current.contains(event.target as Node) // ✅ Ignore clicks inside footer
//   //     ) {
//   //       return
//   //     }

//   //     if (
//   //       anchorEl?.contains(event.target as Node) || // ✅ Ignore clicks inside the selected cell
//   //       selectedCell?.contains(event.target as Node) // ✅ Ignore clicks inside the popper
//   //     ) {
//   //       return
//   //     }

//   //     // ✅ Only clear selection if clicking outside ALL interactive areas
//   //     setSelectedRole(null)
//   //     if (selectionModel?.length <= 1) {
//   //       setSelectionModel([])
//   //     }
//   //   }

//   //   document.addEventListener('mousedown', handleClickOutside)
//   //   return () => document.removeEventListener('mousedown', handleClickOutside)
//   // }, [anchorEl, selectionModel, selectedCell])

//   return (
//     <GridFooterContainer ref={footerRef}>
//       {selectionModel.length > 0 ? (
//         <Grid
//           container
//           sx={{
//             maxHeight: 'auto',
//             position: 'absolute',
//             bottom: 0,
//             left: 0,
//             right: 0,
//             bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
//             alignItems: 'center',
//             zIndex: 1,
//             flexWrap: 'wrap',
//             overflow: 'hidden',
//           }}
//         >
//           <Grid container>
//             <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={taskGridStyles}>
//               <Typography variant='h8'>{'Update'}</Typography>
//               <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
//               <Typography variant='h8'>{selectionModel.length === 1 ? 'Task' : 'Tasks'}</Typography>
//             </Grid>

//             <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5} sx={taskGridStyles} justifyContent='flex-end'>
//               {isLoading ? (
//                 <CircularProgress />
//               ) : (
//                 <>
//                   {selectedRole && selectionModel?.length <= 1 ? (
//                     <MassUpdateUserSelect
//                       name={selectedRole}
//                       value={massUpdateData?.[selectedRole] || []}
//                       options={users}
//                       displayLabel={selectedRole}
//                       onChange={onChange}
//                       inputRef={roleRefs?.[selectedRole]}
//                       disabled={
//                         (massUpdateData.masterStatus?.length || 0) > 0 ||
//                         (massUpdateData.type?.length || 0) > 0 ||
//                         (massUpdateData.DMS?.length || 0) > 0 ||
//                         (massUpdateData.SEO?.length || 0) > 0 ||
//                         (massUpdateData.MIA?.length || 0) > 0
//                       }
//                     />
//                   ) : (
//                     <>
//                       <Grid
//                         item
//                         xs={1.5}
//                         sm={1.5}
//                         md={1.5}
//                         lg={1.5}
//                         xl={1.5}
//                         sx={taskGridStyles}
//                         justifyContent='flex-end'
//                       >
//                         <MassUpdateTypeSelect
//                           name='type'
//                           value={massUpdateData.type || ''}
//                           options={taskTypeList}
//                           displayLabel='Type'
//                           onChange={onChange}
//                           disabled={
//                             (massUpdateData.masterStatus?.length || 0) > 0 ||
//                             (massUpdateData.AM?.length || 0) > 0 ||
//                             (massUpdateData.DMS?.length || 0) > 0 ||
//                             (massUpdateData.SEO?.length || 0) > 0
//                           }
//                           sx={{ height: 40, minWidth: 150 }}
//                         />
//                       </Grid>
//                       <Grid
//                         item
//                         xs={1.5}
//                         sm={1.5}
//                         md={1.5}
//                         lg={1.5}
//                         xl={1.5}
//                         sx={taskGridStyles}
//                         justifyContent='flex-end'
//                       >
//                         <MassUpdateTypeSelect
//                           name='masterStatus'
//                           value={massUpdateData.masterStatus || ''}
//                           options={masterStatusList}
//                           displayLabel='Master Status'
//                           onChange={onChange}
//                           disabled={
//                             (massUpdateData.type?.length || 0) > 0 ||
//                             (massUpdateData.AM?.length || 0) > 0 ||
//                             (massUpdateData.DMS?.length || 0) > 0 ||
//                             (massUpdateData.SEO?.length || 0) > 0
//                           }
//                           sx={{ height: 40, minWidth: 150 }}
//                         />
//                       </Grid>
//                       <MassUpdateUserSelect
//                         name='AM'
//                         value={massUpdateData.AM || []}
//                         options={users}
//                         displayLabel='AM'
//                         onChange={onChange}
//                         inputRef={roleRefs.AM}
//                         disabled={
//                           (massUpdateData.masterStatus?.length || 0) > 0 ||
//                           (massUpdateData.type?.length || 0) > 0 ||
//                           (massUpdateData.DMS?.length || 0) > 0 ||
//                           (massUpdateData.SEO?.length || 0) > 0 ||
//                           (massUpdateData.MIA?.length || 0) > 0
//                         }
//                       />
//                       <MassUpdateUserSelect
//                         name='DMS'
//                         value={massUpdateData.DMS || ''}
//                         options={users} // Or pass your list of users for DMS
//                         displayLabel='DMS'
//                         onChange={onChange}
//                         inputRef={roleRefs.DMS}
//                         // Optionally disable if another field is active:
//                         disabled={
//                           (massUpdateData.masterStatus?.length || 0) > 0 ||
//                           (massUpdateData.type?.length || 0) > 0 ||
//                           (massUpdateData.AM?.length || 0) > 0 ||
//                           (massUpdateData.SEO?.length || 0) > 0 ||
//                           (massUpdateData.MIA?.length || 0) > 0
//                         }
//                       />
//                       <MassUpdateUserSelect
//                         name='SEO'
//                         value={massUpdateData.SEO || ''}
//                         options={users} // Or pass your list of users for SEO
//                         displayLabel='SEO'
//                         onChange={onChange}
//                         inputRef={roleRefs.SEO}
//                         // Optionally disable if another field is active:
//                         disabled={
//                           (massUpdateData.masterStatus?.length || 0) > 0 ||
//                           (massUpdateData.type?.length || 0) > 0 ||
//                           (massUpdateData.DMS?.length || 0) > 0 ||
//                           (massUpdateData.AM?.length || 0) > 0 ||
//                           (massUpdateData.MIA?.length || 0) > 0
//                         }
//                       />
//                       <MassUpdateUserSelect
//                         name='MIA'
//                         value={massUpdateData.MIA || ''}
//                         options={users} // Or pass your list of users for SEO
//                         displayLabel='MIA'
//                         onChange={onChange}
//                         inputRef={roleRefs.MIA}
//                         // Optionally disable if another field is active:
//                         disabled={
//                           (massUpdateData.masterStatus?.length || 0) > 0 ||
//                           (massUpdateData.type?.length || 0) > 0 ||
//                           (massUpdateData.DMS?.length || 0) > 0 ||
//                           (massUpdateData.AM?.length || 0) > 0 ||
//                           (massUpdateData.SEO?.length || 0) > 0
//                         }
//                       />
//                     </>
//                   )}
//                 </>
//               )}
//             </Grid>
//             <Grid item xs={2} sm={2.5} md={2.5} lg={2.5} xl={2.5} sx={taskGridStyles} justifyContent='flex-end'>
//               <Tooltip title={selectionModel.length === 1 ? 'Apply Update' : 'Apply Mass Update'} placement='top'>
//                 <Button
//                   color='secondary'
//                   variant='contained'
//                   onClick={() => {
//                     onUpdate(massUpdateData, selectionModel)
//                     onClear()
//                   }}
//                   disabled={selectionModel.length === 0}
//                   sx={{ height: 40 }}
//                 >
//                   {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
//                 </Button>
//               </Tooltip>
//               <Button
//                 color='secondary'
//                 variant='contained'
//                 onClick={onClear}
//                 disabled={!massUpdateData?.type?.length && !massUpdateData?.masterStatus?.length}
//                 sx={{ height: 40 }}
//               >
//                 Clear
//               </Button>
//               {selectionModel?.length === 1 && (
//                 <Tooltip title={'Delete Task'} placement='top'>
//                   <IconButton color='inherit' onClick={onClickDelete}>
//                     <DeleteIcon sx={{ fontSize: 30 }} />
//                   </IconButton>
//                 </Tooltip>
//               )}
//             </Grid>
//           </Grid>
//         </Grid>
//       ) : (
//         <Grid
//           container
//           sx={{
//             padding: 1,
//             alignItems: 'center',
//             minHeight: 50,
//             bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
//             gap: 1,
//           }}
//           justifyContent='flex-start'
//         >
//           <Grid item>
//             <Typography variant='body2' mr={2}>
//               Total Rows:
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
//               {rowCount}
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
//               /
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
//               {tasks.length}
//             </Typography>
//           </Grid>
//         </Grid>
//       )}
//       <TasksPopover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={onClosePopover}
//         handleConfirmDelete={onDelete}
//         selectedData={selectedData}
//         context={context}
//       />
//     </GridFooterContainer>
//   )
// }

// export default TasksGridFooter
