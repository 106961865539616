import React, { useMemo, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDealsByIds } from '../../api/aws/useDealByIds'
import { useOrganizationById } from '../../api/aws/useOrganization'
import Loading from '../Loading/Loading'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import { StyledLink } from './style'
import dayjs from 'dayjs'
import { renderColorByString } from '../DataGrid/renderColorByString'

const OrgDealInfo = () => {
  const { orgId } = useParams()
  const { organization, organizationLoading, organizationError, organizationDetailsError } = useOrganizationById(orgId)
  const deals = useMemo(() => {
    if (organization && !organizationLoading) {
      return organization?.deals ?? []
    }
  }, [organization])
  // const dealIds = organization?.dealIds || []
  // const { deals: deals, isLoading: dealsLoading, isError: dealsError, error: dealsErrorDetails } = useDealsByIds(
  //   dealIds
  // )
  console.log('deals: ', deals)

  if (organizationLoading) {
    return <Loading />
  }

  if (organizationError) {
    return <Typography>Error loading organization details: {organizationDetailsError.message}</Typography>
  }

  // Helper function to calculate total amount from deals
  const getTotalContractAmount = products => {
    // Check if products is defined and is an array
    if (!products || !Array.isArray(products)) return '0.00'

    return products
      .reduce((acc, product) => {
        return acc + (parseFloat(product.price) || 0)
      }, 0)
      .toFixed(2) // Format the result to two decimal places
  }
  const formatDateString = date => {
    if (!date) return ''
    return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
  }
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='deals table'>
          <TableHead>
            <TableRow>
              <TableCell>Deal Name</TableCell>
              <TableCell align='right'>Type</TableCell>
              <TableCell align='right'>Deal Status</TableCell>
              <TableCell align='right'>Stage</TableCell>
              <TableCell align='right'>Start Date</TableCell>
              <TableCell align='right'>End Date</TableCell>
              <TableCell align='right'>Total Contract Amount ($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deals?.map(deal => {
              return (
                <TableRow key={deal.deal_uuid}>
                  <TableCell component='th' scope='row'>
                    <StyledLink to={`/deals/${deal.deal_uuid}/details`}>
                      <Box>{deal.name || 'No Name'}</Box>
                    </StyledLink>
                  </TableCell>
                  <TableCell sx={{ color: renderColorByString(deal.type) }} align='right'>
                    {deal.type}
                  </TableCell>
                  <TableCell sx={{ color: renderColorByString(deal.dealStatus) }} align='right'>
                    {deal.dealStatus}
                  </TableCell>
                  <TableCell align='right'>{deal.stage}</TableCell>
                  <TableCell align='right'>{formatDateString(deal.startDate)}</TableCell>
                  <TableCell align='right'>{formatDateString(deal.endDate)}</TableCell>
                  <TableCell align='right'>{`$${getTotalContractAmount(deal.products)}`}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrgDealInfo
