import React, { useState, useEffect, useMemo } from 'react'
import { Drawer, Box, Typography, Tabs, Tab, useTheme, Grid, InputLabel } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useDeal } from '../../api/aws/useDeal'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { mergeTasks, ncoTeamTasksArray } from '../NCO/ncoTaskObjects'
import { integrationFormState } from '../DataGrid/components/checklistRules'
import DisplayChannels from '../DataGrid/DisplayChannels'
import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
import DrawerTasksGrid from '../DataGrid/components/DrawerTasksGrid'
import OptimizationsContainer from './OptimizationsContainer.tsx'
import BoardsGridHeader from '../DataGrid/BoardsGridHeader'
import TasksByOrganization from '../DataGrid/components/TasksByOrganization.tsx'
import FileManagement from '../DataGrid/components/FileManagement.js'
import CommentsInput from '../Comments/CommentsInput.tsx'
import RenderCampaign from '../StrategyCampaigns/RenderCampaign.js'
import ReviewDeal from '../DealApproval/ReviewDeal.js'
import Strategy from '../Strategy/Strategy.js'
import { useOrganizationSubfolders } from '../../api/google/useOrganizationSubfolders.ts'
import { useDealFolders } from '../../api/google/useDealFolders.ts'
import { useGoogleOrganizationFolder } from '../../api/google/useGoogleOrganizationFolder.ts'
import { useOrganizationById } from '../../api/aws/useOrganization.js'
import DetailsLoading from '../Loading/DetailsLoading.js'
import { useValidateGoogleToken } from '../../api/google/useValidateGoogleToken.ts'
import { useBatchUpdateChecklists } from '../../api/aws/checklists/useBatchUpdateChecklists.ts'
import { formatDayjsDateTime } from '../../utility/formatDayjsDateTime.js'
import { useGetChecklists } from '../../api/aws/checklists/useGetChecklists.ts'
import Checklists from '../DataGrid/components/Checklists.tsx'
import { useBatchPostChecklists } from '../../api/aws/checklists/useBatchPostChecklists.ts'
import {
  createGeneralChecklist,
  createIntegrationChecklist,
  createSeoChecklist,
} from '../../api/aws/checklists/lists.ts'
import ProgressBar from '../DataGrid/components/ProgressBar.tsx'
import DrawerLoading from '../Loading/DrawerLoading.js'
import OptimizationsAutocomplete from './OptimizationsAutocomplete.tsx'

const SeoDrawerComponent = ({ open, onClose, context, tasks }) => {
  const { pinned } = useLayoutContext()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { validateToken, isValid } = useValidateGoogleToken()
  const { dealId, orgId, campaignId } = useParams()
  const { mutate: updateDeal } = useUpdateDeal()
  const [activeTab, setActiveTab] = useState(0)
  const [completedCounts, setCompletedCounts] = useState({}) // ✅ Store completed checklists
  // Fetch the deal & organization
  const { deal, isDealLoading } = useDeal(dealId)
  const { organization, isOrganizationLoading } = useOrganizationById(deal?.organizations?.[0])
  // Fetch the Organization's Google Drive Folder ID
  const { data: organizationFolder, isLoading: isOrganizationFolderLoading } = useGoogleOrganizationFolder(
    organization?.org_uuid
  )
  const orgFolderId = organizationFolder?.organizationFolderId ?? null // ✅ Default to null
  // Fetch 'Deals' Folder inside the Organization
  const { data: subfolders, isLoading: isSubfoldersLoading } = useOrganizationSubfolders(orgFolderId || '')
  // Ensure subfolders is always an array
  const safeSubfolders = Array.isArray(subfolders) ? subfolders : []
  // Find the "Deals" folder dynamically
  const dealsFolder = safeSubfolders.find(folder => folder.name === 'Deals')
  const dealsFolderId = dealsFolder ? dealsFolder.id : null
  const creation_timestamp = formatDayjsDateTime(deal?.creation_timestamp)
  // Fetch Specific Deal Folder inside 'Deals' Folder
  // const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(dealsFolderId || '', dealId || '')
  const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(
    dealsFolderId || '',
    creation_timestamp || ''
  )
  // Ensure dealFolders is always an array
  const safeDealFolders = Array.isArray(dealFolders) ? dealFolders : []
  const dealFolderId = safeDealFolders.length > 0 ? safeDealFolders[0]?.id : null
  const { data: fetchedChecklists, isLoading: isChecklistsLoading } = useGetChecklists(deal)
  const [checklists, setChecklists] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [pendingChecklistUpdates, setPendingChecklistUpdates] = useState([]) // Store pending updates
  const [pendingDealUpdates, setPendingDealUpdates] = useState({}) // Store pending updates
  const { mutateAsync: batchUpdateAsync } = useBatchUpdateChecklists()
  const { mutate: createChecklistsBatch } = useBatchPostChecklists()

  useEffect(() => {
    if (!deal?.deal_uuid || isChecklistsLoading) return

    // Determine the desired checklist types
    const checklistTypes = [
      createGeneralChecklist(deal),
      (deal.isSeo1 || deal.isSeo2 || deal.isSeo3 || deal.isSeo4) && createSeoChecklist(deal),
      deal.type === 'MSA' && createIntegrationChecklist(deal),
    ].filter(Boolean)

    // Filter out already existing checklists (by type)
    const missingChecklists = checklistTypes.filter(
      checklist => !fetchedChecklists.some(c => c.type === checklist.type)
    )

    console.log('🔄 Missing Checklists:', missingChecklists)

    if (missingChecklists.length > 0) {
      createChecklistsBatch(missingChecklists, {
        onSuccess: () => {
          // After creation, invalidate or refetch the checklist query
          queryClient.invalidateQueries(['checklists', `DEAL_${deal.deal_uuid}`])
        },
      })
    }
  }, [deal, fetchedChecklists, isChecklistsLoading, createChecklistsBatch, queryClient])

  const handleValidateToken = (token, userId) => {
    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: token, refreshToken: '', userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
    }
  }
  // Set checklists when fetched
  useEffect(() => {
    if (fetchedChecklists) {
      setChecklists(fetchedChecklists)
    }
  }, [fetchedChecklists])

  // Derive general and integration checklists from the updated `checklists` state
  const generalChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'General'), [checklists])
  const integrationChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'Integrations'), [checklists])
  const seoChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'SEO'), [checklists])

  useEffect(() => {
    if (integrationChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedDealField (lowercase)
      const progress = {}

      integrationChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedDealField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedDealField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (seoChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedDealField (lowercase)
      const progress = {}

      seoChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedDealField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedDealField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (generalChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedDealField (lowercase)
      const progress = {}

      generalChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedDealField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedDealField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setCompletedCounts(prev => ({ ...prev, ...progress }))
    }
  }, [integrationChecklist, seoChecklist, generalChecklist])

  useEffect(() => {
    if (!isDealLoading && completedCounts?.onboardingprogress != null) {
      // Ensure both values are numbers
      const dealProgress = Number(deal?.completedCounts?.onboardingprogress ?? 0)
      const newProgress = Number(completedCounts.onboardingprogress)
      if (dealProgress !== newProgress) {
        setPendingDealUpdates(prev => ({
          ...prev,
          completedCounts: { onboardingprogress: newProgress },
        }))
      }
      const seoProgress = Number(deal?.completedCounts?.completeseoform ?? 0)
      const newSeoProgress = Number(completedCounts.completeseoform)
      if (seoProgress !== newSeoProgress) {
        setPendingDealUpdates(prev => ({
          ...prev,
          completedCounts: { completeseoform: newProgress },
        }))
      }
    }
  }, [completedCounts, isDealLoading, deal])

  /** ✅ Handles tab navigation */
  const handleTabChange = (event, newValue) => {
    const paths = [
      'main',
      'strategy',
      'review',
      `notes/${organization?.org_uuid}`,
      `optimizations/${organization?.org_uuid}`,
      'filemgmt',
      `orgtasks/${organization?.org_uuid}`,
    ]
    setActiveTab(newValue)
    navigate(`${dealId}/${paths[newValue]}`)
  }
  useEffect(() => {
    if (location.pathname.includes(`strategy/${campaignId}`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`strategy`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`review`)) {
      setActiveTab(2)
    } else if (location.pathname.includes(`notes/${orgId}`)) {
      setActiveTab(3)
    } else if (location.pathname.includes(`optimizations/${orgId}`)) {
      setActiveTab(4)
    } else if (location.pathname.includes(`filemgmt`)) {
      setActiveTab(5)
    } else if (location.pathname.includes(`orgtasks/${orgId}`)) {
      setActiveTab(6)
    } else {
      setActiveTab(0)
    }
  }, [location, campaignId])

  // Helper: find the section and item indices for a given itemId.
  const findIndices = (checklist, itemId) => {
    for (let sectionIndex = 0; sectionIndex < checklist.sections.length; sectionIndex++) {
      const section = checklist.sections[sectionIndex]
      for (let itemIndex = 0; itemIndex < section.items.length; itemIndex++) {
        if (section.items[itemIndex].id === itemId) {
          return { sectionIndex, itemIndex }
        }
      }
    }
    return null
  }

  const handleStatusChange = (checklist, itemId, newStatus) => {
    console.log('HANDLE STATUS CHANGE', checklist, itemId, newStatus)
    if (!itemId || !newStatus) return

    const indices = findIndices(checklist, itemId)
    if (!indices) {
      console.error('Could not find indices for item:', itemId)
      return
    }

    setPendingChecklistUpdates(prev => [
      ...prev.filter(update => update.itemId !== itemId),
      {
        PK: checklist.PK,
        SK: checklist.SK,
        itemId,
        newStatus,
        sectionIndex: indices.sectionIndex,
        itemIndex: indices.itemIndex,
      },
    ])

    // Optimistic update:
    setChecklists(prevChecklists =>
      prevChecklists.map(chk =>
        chk.PK === checklist.PK && chk.SK === checklist.SK
          ? {
              ...chk,
              sections: chk.sections.map((section, sIndex) => ({
                ...section,
                items: section.items.map((item, iIndex) =>
                  item.id === itemId ? { ...item, status: newStatus } : item
                ),
              })),
            }
          : chk
      )
    )
  }

  const handleClose = async () => {
    if (pendingChecklistUpdates.length > 0) {
      try {
        const response = await batchUpdateAsync(pendingChecklistUpdates)
        if (response?.status === 200) {
          console.log('Invalidating deal....', dealId)
          queryClient.invalidateQueries(['deal', dealId])
        }
        setPendingChecklistUpdates([]) // Clear after successful update
      } catch (error) {
        console.error('Error updating checklists:', error)
        // Optionally, handle the error or show a notification
      }
    }
    if (Object.keys(pendingDealUpdates).length > 0) {
      await handleUpdate()
    }
    if (onClose) {
      onClose()
    }
  }

  const handleUpdate = async () => {
    if (Object.keys(pendingDealUpdates).length === 0) return // No update needed if nothing changed

    const dealId = deal?.deal_uuid

    // ✅ Ensure only updates & new values are passed, keeping existing state
    const updateData = {
      ...deal, // Preserve existing deal state
      ...pendingDealUpdates, // Apply user changes
      completedCounts: { ...completedCounts },
      queryKey: ['deal', dealId],
    }

    try {
      updateDeal(
        { dealId, dealData: updateData },
        {
          onSuccess: () => {
            console.log('✅ Deal successfully updated:', updateData)
            setPendingDealUpdates({}) // Reset updates after success
          },
          onError: error => {
            console.error('❌ Error updating deal:', error)
            setPendingDealUpdates({}) // Prevent stale updates
          },
        }
      )
    } catch (e) {
      console.error('[BoardsDrawer] error updating deal', e)
    }
  }

  const formatDecimal = decimal => Math.round(decimal * 100)

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '85%',
          height: '100vh',
          pt: pinned ? '60px' : 0.8,
          transition: 'all 0.3s ease',
          overflowY: 'hidden',
        },
      }}
    >
      {isDealLoading || isOrganizationLoading ? (
        <DrawerLoading />
      ) : (
        <Box>
          <ExpandCollapseButton
            expanded={expanded}
            setExpanded={setExpanded}
            displayElement={
              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
                    {organization?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Onboarding Progress (SEO)</InputLabel>
                  <ProgressBar progress={formatDecimal(completedCounts?.completeseoform)} />
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex', gap: 0.8, width: '100%', justifyContent: 'flex-end' }}>
                    <DisplayChannels deal={deal} checklistProgress={completedCounts} />
                  </Box>
                </Grid>
              </Grid>
            }
          >
            <BoardsGridHeader newDeal={deal} organization={organization} />
          </ExpandCollapseButton>
          {/* Tabs */}
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
            <Tab label={<Typography>Main</Typography>} />
            <Tab label={<Typography>Strategy</Typography>} disabled={['CSA', 'RCSA'].includes(deal?.type)} />
            <Tab label={<Typography>Review</Typography>} />
            <Tab label={<Typography>Notes</Typography>} />
            <Tab label={<Typography>Onboarding</Typography>} />
            <Tab label={<Typography>File Mgmt</Typography>} />
            <Tab label={<Typography>Tasks By Organization</Typography>} />
          </Tabs>

          {/* Tab Content */}
          <Box sx={{ height: 'calc(100vh - 150px)' }}>
            {/* {activeTab === 0 && <OptimizationsContainer />} */}
            {activeTab === 0 && (
              <OptimizationsContainer organization={organization} isOrganizationLoading={isOrganizationLoading} />
            )}
            {activeTab === 1 && location.pathname.includes(`strategy/${campaignId}`) ? (
              <RenderCampaign />
            ) : activeTab === 1 && location.pathname.includes(`strategy`) ? (
              <Strategy dealId={''} moduleLocation={'boardsDrawer'} expanded={expanded} />
            ) : null}
            {activeTab === 2 && <ReviewDeal moduleLocation={'boardsDrawer'} isHeaderExpanded={expanded} />}
            {activeTab === 3 && <CommentsInput expanded={expanded} context={context} />}
            {activeTab === 4 && !isDealLoading && (
              <Checklists
                deal={deal}
                handleStatusChange={handleStatusChange}
                checklists={seoChecklist}
                isLoading={isChecklistsLoading}
                context={context}
              />
            )}
            {activeTab === 5 && (
              <FileManagement newDeal={deal} organization={organization} dealFolder={safeDealFolders} />
            )}
            {activeTab === 6 && <TasksByOrganization />}
          </Box>
        </Box>
      )}
    </Drawer>
  )
}

export default SeoDrawerComponent
