import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTasksByOrgId } from '../../../api/aws/tasks/useTasksByOrgId.ts'
import { Box, Grid, Card, CardContent, Typography, useTheme, CircularProgress } from '@mui/material'
import { renderColorByString } from '../renderColorByString.js'
import { useOrganizationById } from '../../../api/aws/useOrganization.js'
import { useDealDetailsContext } from '../../../context/DealsDetailsProvider/DealsDetailsProvider.js'
import { useChangeView } from '../../../utility/useChangeView.ts'
import { boardViewMapping } from '../../../utility/boardViewMapping.ts'
import Phases from '../../Phases/Phases.tsx'
import { formatDayJsDateTime } from '../createColumns/renderDealsColumns.js'
import { useLayoutContext } from '../../../context/LayoutProvider/LayoutProvider.js'

// ✅ Define Task type
interface Task {
  PK: string
  SK: string
  name: string
  type: string
  masterStatus: string
  isMarketing: boolean
  createdTime: string
}

const TasksByOrganization: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>()
  const { pinned } = useLayoutContext()
  const navigate = useNavigate()
  const handleChangeView = useChangeView()
  const { handleNcoDrawerClose } = useDealDetailsContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId || '')
  const { data: tasks = [], isLoading, isError } = useTasksByOrgId(orgId || '')
  const theme = useTheme()

  const handleNavigate = (task: Task) => {
    // const route = task?.isMarketing ? '/teamtasks' : '/creative'
    // const url = task.isMarketing
    //   ? `/teamtasks/${task.PK}/${task.SK}/taskdetails`
    //   : `/creative/${task.PK}/${task.SK}/taskdetails`
    const route = '/teamtasks'
    const url = `/teamtasks/${task.PK}/${task.SK}/taskdetails`
    const newView = boardViewMapping[route]
    if (newView) {
      handleChangeView({ newView })
    }
    handleNcoDrawerClose(false)
    navigate(url)
  }

  if (isLoading || isOrganizationLoading)
    return (
      <Box textAlign='center'>
        <CircularProgress />
      </Box>
    )
  if (isError) return <Typography color='error'>Error fetching tasks</Typography>
  if (!tasks.length) return <Typography>No tasks found for this organization</Typography>

  return (
    <Box sx={{ padding: 2, height: pinned ? 'calc(100vh - 160px)' : 'calc(100vh - 120px)', overflowY: 'auto' }}>
      <Typography variant='h5' gutterBottom>
        Tasks for {organization?.name || 'Organization'}
      </Typography>
      <Grid container spacing={2}>
        {tasks.map((task: Task) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={task.SK}>
              {/* ✅ Responsive layout */}
              <Card
                sx={{
                  bgcolor: theme.palette.background.default,
                  boxShadow:
                    theme.palette.mode === 'dark'
                      ? '0px 3px 10px rgba(255,255,255,0.1)'
                      : '0px 3px 10px rgba(0,0,0,0.1)',
                  transition: 'box-shadow 0.2s ease',
                  '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0,0,0,0.3)', // ✅ More dramatic shadow effect
                  },
                }}
              >
                <Box
                  onClick={() => handleNavigate(task)}
                  sx={{ cursor: 'pointer', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                  <CardContent>
                    <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {task.name}
                    </Typography>
                    <Typography sx={{ color: renderColorByString(task?.type) }}>{task?.type}</Typography>
                    <Typography
                      sx={{
                        color: renderColorByString(task?.masterStatus),
                      }}
                    >
                      {task.masterStatus}
                    </Typography>
                    <Typography>{formatDayJsDateTime(task.createdTime)}</Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default TasksByOrganization
