import { Box, Typography, Tooltip } from '@mui/material'

export const createMessageJsx = (messageText, selectedUsers, theme) => {
  console.log('SELECTED USERS', selectedUsers)
  // Base message as simple text
  const baseMessage = <Typography component='span'>{messageText}</Typography>

  // A list of tagged users (with tooltip or styling)
  const taggedUsersJsx = selectedUsers.map(user => (
    <Tooltip
      key={user.id}
      title={
        <Box>
          <Typography>ID: {user.id}</Typography>
          <Typography>
            Name: {user.firstName} {user.lastName}
          </Typography>
          <Typography>Email: {user.email}</Typography>
        </Box>
      }
    >
      <Typography
        component='span'
        sx={{
          ml: 1,
          mr: 1,
          color: theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        @{user.firstName}
        {user.lastName ? ` ${user.lastName}` : ''}
      </Typography>
    </Tooltip>
  ))

  // Combine the base message with the tagged users
  const message = (
    <>
      {baseMessage}
      {selectedUsers.length > 0 && (
        <Box component='span' sx={{ display: 'inline-flex', ml: 1 }}>
          {taggedUsersJsx}
        </Box>
      )}
    </>
  )

  return {
    selectedUsers,
    message, // This is the JSX you can render
  }
}

function convertHtmlToPlainText(htmlString) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  // For each mention, replace the entire <span> with a text node like "@Justin Widmayer"
  doc.querySelectorAll('[data-type="mention"]').forEach(mentionEl => {
    const label = mentionEl.getAttribute('data-label') || ''
    // Replace the node with a text node
    mentionEl.replaceWith(doc.createTextNode(`@${label}`))
  })

  // Now strip any remaining tags or just read .textContent
  return doc.body.textContent || ''
}

export const createTaskSlackPayload = (user, messageText, task) => {
  const plainText = convertHtmlToPlainText(messageText)

  // Construct the task link using the task's PK & SK
  const taskLink = `https://localhost:3000/teamtasks/${task?.PK}/${task?.SK}/messages` // Replace with your actual URL structure
  const taskIconUrl = 'https://cdn-icons-png.flaticon.com/512/1097/1097035.png'
  return {
    channel: user.slackId, // Slack user ID or channel
    text: `Tagged in a message for ${task?.name}`, // Fallback text
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Hi <@${user.slackId}>, you were mentioned in a message!`,
        },
      },

      {
        type: 'divider',
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Message:* ${plainText}`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:bookmark_tabs: ${task?.name}`,
        },
        accessory: {
          type: 'button',
          text: {
            type: 'plain_text',
            text: '📝 Link to Task',
            emoji: true,
          },
          value: `link_to_task${task?.SK}`,
          url: taskLink,
          action_id: 'button-action',
        },
      },
    ],
    username: 'Grobotic',
    icon_emoji: ':bell:',
  }
}
export const createSlackPayload = (user, messageText, url) => {
  const plainText = convertHtmlToPlainText(messageText)
  // If you store Slack user IDs in `user.slackId`, use that here
  return {
    channel: user.slackId, // Slack user ID or channel
    text: `You were mentioned in a message: ${plainText}`, // Fallback text
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Hi <@${user.slackId}>, you were mentioned in a message!`,
        },
      },
      url && {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*<${url}|View in Grobotic>*`,
        },
      },

      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Message:* ${plainText}`,
        },
      },
    ],
    username: 'Grobotic',
    icon_emoji: ':bell:',
  }
}
