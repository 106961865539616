// import React, { useState, useEffect, useRef, useCallback } from 'react'
// import { Box, CircularProgress, IconButton, useTheme, Button } from '@mui/material'
// import SendIcon from '@mui/icons-material/Send'

// // Tiptap & Extensions
// import StarterKit from '@tiptap/starter-kit'
// import BulletList from '@tiptap/extension-bullet-list'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import CharacterCount from '@tiptap/extension-character-count'
// import TextStyle from '@tiptap/extension-text-style'
// import Color from '@tiptap/extension-color'
// import Highlight from '@tiptap/extension-highlight'
// import Link from '@tiptap/extension-link'
// import Image from '@tiptap/extension-image'

// import {
//   MenuButtonBold,
//   MenuButtonItalic,
//   MenuControlsContainer,
//   MenuDivider,
//   MenuSelectHeading,
//   MenuButtonBulletedList,
//   MenuButtonOrderedList,
//   RichTextEditor,
// } from 'mui-tiptap'
// import UsersAutocomplete from '../Users/UsersAutocomplete'
// import { useUsers } from '../../api/aws/useUsers'

// // 1) If you want to show an icon for Docs/Sheets, define them here:
// const GOOGLE_ICONS = {
//   docs: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_document_list_2x.png',
//   sheets: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_spreadsheet_list_2x.png',
//   slides: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_presentation_list_2x.png',
//   forms: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_form_list_2x.png',
//   drive: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_generic_list_2x.png',
// }

// // 2) Check if a link is a recognized Google URL and produce a thumbnail or icon:
// const getGoogleFilePreview = url => {
//   // Drive doc with a fileId
//   const driveRegex = /drive\.google\.com\/(?:file\/d\/|open\?id=)([\w-]+)/
//   const docsRegex = /docs\.google\.com\/document\/d\/([\w-]+)/
//   const sheetsRegex = /docs\.google\.com\/spreadsheets\/d\/([\w-]+)/
//   const slidesRegex = /docs\.google\.com\/presentation\/d\/([\w-]+)/
//   const formsRegex = /docs\.google\.com\/forms\/d\/([\w-]+)/

//   if (driveRegex.test(url)) {
//     const fileId = url.match(driveRegex)[1]
//     return {
//       type: 'drive',
//       id: fileId,
//       thumbnail: `https://drive.google.com/thumbnail?id=${fileId}&sz=w200`,
//       link: `https://drive.google.com/file/d/${fileId}/view`,
//     }
//   } else if (docsRegex.test(url)) {
//     const fileId = url.match(docsRegex)[1]
//     return { type: 'docs', id: fileId, icon: GOOGLE_ICONS.docs, link: url }
//   } else if (sheetsRegex.test(url)) {
//     const fileId = url.match(sheetsRegex)[1]
//     return { type: 'sheets', id: fileId, icon: GOOGLE_ICONS.sheets, link: url }
//   } else if (slidesRegex.test(url)) {
//     const fileId = url.match(slidesRegex)[1]
//     return { type: 'slides', id: fileId, icon: GOOGLE_ICONS.slides, link: url }
//   } else if (formsRegex.test(url)) {
//     const fileId = url.match(formsRegex)[1]
//     return { type: 'forms', id: fileId, icon: GOOGLE_ICONS.forms, link: url }
//   }
//   return null
// }

// // 3) Check if a link is a direct image URL
// const isDirectImageURL = url => {
//   // We only do a naive check for .png, .jpg, etc. at the end
//   // Feel free to expand with a HEAD request or advanced logic if needed
//   return url.startsWith('http') && url.match(/\.(jpg|jpeg|png|gif|webp)$/i)
// }

// // 4) Main component
// const OrganizationRichTextField = ({ content, onContentChange, setIsCreatingNote, isCreatingNote, handleAddNote }) => {
//   ////////////////////////////////////
//   const { users, isUsersLoading, isUsersError } = useUsers(true)
//   ////////////////////////////////////
//   const editorRef = useRef(null)
//   const theme = useTheme()
//   const hoverBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
//   const [characterCount, setCharacterCount] = useState(0)

//   // Keep external content in sync
//   useEffect(() => {
//     onContentChange(content)
//   }, [content, onContentChange])

//   // Reset character count if content is empty
//   useEffect(() => {
//     if (content === '') {
//       setCharacterCount(0)
//     }
//   }, [content])

//   // Clear the editor
//   const handleClear = () => {
//     editorRef.current?.commands.clearContent()
//     setIsCreatingNote(false)
//   }

//   // [A] Insert an image from a button
//   const insertImage = useCallback(() => {
//     if (!editorRef.current) return
//     const url = prompt('Enter image URL:')
//     if (url) {
//       editorRef.current
//         .chain()
//         .focus()
//         .setImage({
//           src: url,
//           alt: 'Custom alt text',
//           title: 'Optional title',
//         })
//         .run()
//     }
//   }, [])

//   // [B] Our handlePaste: checks for direct image or google link
//   const handlePaste = event => {
//     if (!editorRef.current) return
//     event.preventDefault()

//     const pastedText = event.clipboardData.getData('text')

//     // 1) If it’s a direct image link
//     if (isDirectImageURL(pastedText)) {
//       editorRef.current.chain().focus().setImage({ src: pastedText }).run()
//       return
//     }

//     // 2) If it’s a recognized Google link
//     const googleFile = getGoogleFilePreview(pastedText)
//     if (googleFile) {
//       // 2a) If it’s a Drive link with a `thumbnail`:
//       if (googleFile.thumbnail) {
//         editorRef.current
//           .chain()
//           .focus()
//           .insertContent({
//             type: 'link',
//             attrs: { href: googleFile.link, target: '_blank' },
//             content: [
//               {
//                 type: 'image',
//                 attrs: {
//                   src: googleFile.thumbnail,
//                   alt: 'Google Drive File',
//                 },
//               },
//             ],
//           })
//           .run()
//       }
//       // 2b) If it’s docs/sheets/slides => show an icon
//       else if (googleFile.icon) {
//         editorRef.current
//           .chain()
//           .focus()
//           .insertContent({
//             type: 'link',
//             attrs: { href: googleFile.link, target: '_blank' },
//             content: [
//               {
//                 type: 'image',
//                 attrs: {
//                   src: googleFile.icon,
//                   alt: `Google ${googleFile.type}`,
//                 },
//               },
//             ],
//           })
//           .run()
//       }
//       return
//     }

//     // 3) Otherwise, fallback to plain text insertion
//     editorRef.current.chain().focus().insertContent(pastedText).run()
//   }

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         width: '100%',
//         flexDirection: 'column',
//         position: 'relative',
//         '& .MuiCollapse-root': {
//           margin: 0,
//         },
//         '& .MuiMenuBar-root': {
//           margin: 0,
//         },
//       }}
//     >
//       {/* Insert Image Button (optional) */}
//       <Button onClick={insertImage} variant='outlined' sx={{ mb: 1 }}>
//         Insert Image
//       </Button>

//       <RichTextEditor
//         className='rich-text full-width-toolbar'
//         extensions={[
//           StarterKit.configure({ bulletList: false, orderedList: false, listItem: false }),
//           BulletList,
//           ListItem,
//           OrderedList,
//           TextStyle,
//           Color,
//           Highlight,
//           CharacterCount.configure({ limit: 5000 }),
//           Link.configure({ openOnClick: false }),
//           Image.configure({
//             inline: false,
//             allowBase64: false,
//             HTMLAttributes: { class: 'my-custom-class' },
//           }),
//         ]}
//         content={content}
//         onCreate={({ editor }) => {
//           editorRef.current = editor
//           // Add the paste event
//           editor.view.dom.addEventListener('paste', handlePaste)
//         }}
//         onUpdate={({ editor }) => {
//           setCharacterCount(editor.storage.characterCount.characters())
//           onContentChange(editor.getHTML())
//         }}
//         renderControls={() => (
//           <MenuControlsContainer
//             onClick={e => e.stopPropagation()}
//             style={{ width: '100%', justifyContent: 'space-between', height: '100%' }}
//           >
//             <MenuSelectHeading style={{ flexGrow: 1 }} />
//             <MenuDivider />
//             <MenuButtonBold />
//             <MenuButtonItalic />
//             <MenuDivider />
//             <MenuButtonBulletedList />
//             <MenuButtonOrderedList />
//           </MenuControlsContainer>
//         )}
//         editorProps={{
//           attributes: {
//             style: 'height: 50vh; min-height: 76px; overflow-y: auto; text-align: left; width: 100vw;',
//           },
//         }}
//       />

//       <UsersAutocomplete users={users} label={'Tag Users'} defaultOwner={'U062JDP8R5X'} />
//       {/* “Save” Button */}
//       <IconButton
//         color='primary'
//         onClick={() => {
//           handleAddNote()
//           handleClear()
//         }}
//         sx={{
//           position: 'absolute',
//           bottom: '10px',
//           right: '10px',
//           backgroundColor: theme.palette.primary.main,
//           '&:hover': {
//             backgroundColor: hoverBgColor,
//           },
//         }}
//         disabled={isCreatingNote}
//       >
//         {isCreatingNote && <CircularProgress size={24} />}
//         <SendIcon sx={{ height: 25, width: 25, p: 0.1 }} />
//       </IconButton>
//     </Box>
//   )
// }

// export default OrganizationRichTextField
import React, { useState, useEffect, useRef } from 'react'
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

// Tiptap & Extensions
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

// // mui-tiptap UI components
// import {
//   MenuButtonBold,
//   MenuButtonItalic,
//   MenuControlsContainer,
//   MenuDivider,
//   MenuSelectHeading,
//   MenuButtonBulletedList,
//   MenuButtonOrderedList,
//   RichTextEditor,
// } from 'mui-tiptap'
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
  RichTextEditor,
} from 'mui-tiptap'
// Google service icons
const GOOGLE_ICONS = {
  docs: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_document_list_2x.png',
  sheets: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_spreadsheet_list_2x.png',
  slides: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_presentation_list_2x.png',
  forms: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_form_list_2x.png',
  drive: 'https://ssl.gstatic.com/docs/doclist/images/icon_11_generic_list_2x.png',
}

// Detect type of Google link
const getGoogleFilePreview = url => {
  const driveRegex = /drive\.google\.com\/(?:file\/d\/|open\?id=)([\w-]+)/
  const docsRegex = /docs\.google\.com\/document\/d\/([\w-]+)/
  const sheetsRegex = /docs\.google\.com\/spreadsheets\/d\/([\w-]+)/
  const slidesRegex = /docs\.google\.com\/presentation\/d\/([\w-]+)/
  const formsRegex = /docs\.google\.com\/forms\/d\/([\w-]+)/

  if (driveRegex.test(url)) {
    const fileId = url.match(driveRegex)[1]
    return {
      type: 'drive',
      id: fileId,
      thumbnail: `https://drive.google.com/thumbnail?id=${fileId}&sz=w200`,
      link: `https://drive.google.com/file/d/${fileId}/view`,
    }
  }
  if (docsRegex.test(url)) {
    const fileId = url.match(docsRegex)[1]
    return { type: 'docs', id: fileId, icon: GOOGLE_ICONS.docs, link: url }
  }
  if (sheetsRegex.test(url)) {
    const fileId = url.match(sheetsRegex)[1]
    return { type: 'sheets', id: fileId, icon: GOOGLE_ICONS.sheets, link: url }
  }
  if (slidesRegex.test(url)) {
    const fileId = url.match(slidesRegex)[1]
    return { type: 'slides', id: fileId, icon: GOOGLE_ICONS.slides, link: url }
  }
  if (formsRegex.test(url)) {
    const fileId = url.match(formsRegex)[1]
    return { type: 'forms', id: fileId, icon: GOOGLE_ICONS.forms, link: url }
  }
  return null
}

const OrganizationRichTextField = ({ content, onContentChange, setIsCreatingNote, isCreatingNote, handleAddNote }) => {
  const editorRef = useRef(null)
  const theme = useTheme()
  const hoverBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'
  const [characterCount, setCharacterCount] = useState(0)

  // Reflect external changes
  useEffect(() => {
    onContentChange(content)
  }, [content, onContentChange])

  // If content is empty, reset character count
  useEffect(() => {
    if (content === '') {
      setCharacterCount(0)
    }
  }, [content])

  // Clear the editor content
  const handleClear = () => {
    if (editorRef.current) {
      editorRef.current.commands.clearContent()
    }
    setIsCreatingNote(false)
  }

  // Pasting Google Links → Insert as Tiptap nodes
  const handlePaste = event => {
    if (!editorRef.current) return
    event.preventDefault()

    const pastedText = event.clipboardData.getData('text')
    const googleFile = getGoogleFilePreview(pastedText)

    // 1. If it's NOT a recognized Google link, insert as text
    if (!googleFile) {
      editorRef.current.chain().focus().insertContent(pastedText).run()
      return
    }

    // 2. If there's a Drive thumbnail
    if (googleFile.thumbnail) {
      // Insert an image node
      editorRef.current.chain().focus().setImage({ src: googleFile.thumbnail, alt: 'Google Drive File' }).run()

      // Wrap that image node in a link
      editorRef.current
        .chain()
        .focus()
        .extendMarkRange('image') // Ensure the newly inserted image is selected
        .setLink({ href: googleFile.link, target: '_blank' })
        .run()
    }
    // 3. If we have an icon (Docs, Sheets, etc.)
    else if (googleFile.icon) {
      // Insert an image node for the icon
      editorRef.current
        .chain()
        .focus()
        .setImage({ src: googleFile.icon, alt: `Google ${googleFile.type}` })
        .run()

      // Wrap icon in a link
      editorRef.current
        .chain()
        .focus()
        .extendMarkRange('image')
        .setLink({ href: googleFile.link, target: '_blank' })
        .run()

      // 🔸 Alternatively, insert a text link instead:
      //
      // editorRef.current.chain().focus().insertContent([
      //   {
      //     type: 'text',
      //     text: `Open Google ${googleFile.type.charAt(0).toUpperCase() + googleFile.type.slice(1)}`,
      //     marks: [
      //       {
      //         type: 'link',
      //         attrs: { href: googleFile.link, target: '_blank' },
      //       },
      //     ],
      //   },
      // ]).run()
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
        '& .MuiCollapse-root': {
          margin: 0,
          padding: 0,
        },
        '& .MuiMenuBar-root': {
          margin: 0,
          padding: 0,
        },
        '& .MuiTiptap-FieldContainer-root': {
          backgroundColor: theme.palette.background.section,
        },
      }}
    >
      <RichTextEditor
        className='rich-text full-width-toolbar'
        extensions={[
          StarterKit.configure({ bulletList: false, orderedList: false, listItem: false }),
          BulletList,
          ListItem,
          OrderedList,
          TextStyle,
          Color,
          Highlight,
          CharacterCount.configure({ limit: 5000 }),
          Link.configure({ openOnClick: false }), // Link extension
          Image.configure({}), // Image extension
        ]}
        content={content}
        onCreate={({ editor }) => {
          editorRef.current = editor
          editor.view.dom.addEventListener('paste', handlePaste) // Listen for paste events
        }}
        onUpdate={({ editor }) => {
          setCharacterCount(editor.storage.characterCount.characters())
          onContentChange(editor.getHTML())
        }}
        // Customize the top toolbar
        renderControls={() => (
          <MenuControlsContainer
            onClick={e => e.stopPropagation()}
            style={{ width: '100%', justifyContent: 'space-between', height: '100%' }}
          >
            <MenuSelectHeading style={{ flexGrow: 1 }} />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
        editorProps={{
          attributes: {
            style: `height: 48.5vh; min-height: 76px; overflow-y: auto; text-align: left; background-color: ${theme.palette.background.section}; padding: 0;`,
          },
        }}
      />

      <IconButton
        color='primary'
        onClick={() => {
          handleAddNote()
          handleClear()
        }}
        sx={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: hoverBgColor,
          },
        }}
        disabled={isCreatingNote}
      >
        {isCreatingNote && <CircularProgress size={24} />}
        <SendIcon sx={{ height: 25, width: 25, p: 0.1 }} />
      </IconButton>
    </Box>
  )
}

export default OrganizationRichTextField

// import React, { useState, useEffect } from 'react'
// import { Box, CircularProgress, IconButton, useTheme } from '@mui/material'
// import SendIcon from '@mui/icons-material/Send'
// import StarterKit from '@tiptap/starter-kit'
// import BulletList from '@tiptap/extension-bullet-list'
// import ListItem from '@tiptap/extension-list-item'
// import OrderedList from '@tiptap/extension-ordered-list'
// import CharacterCount from '@tiptap/extension-character-count'
// import TextStyle from '@tiptap/extension-text-style'
// import Color from '@tiptap/extension-color'
// import Highlight from '@tiptap/extension-highlight'
// import './style.css'
// import {
//   MenuButtonBold,
//   MenuButtonItalic,
//   MenuControlsContainer,
//   MenuDivider,
//   MenuSelectHeading,
//   MenuButtonBulletedList,
//   MenuButtonOrderedList,
//   RichTextEditor,
// } from 'mui-tiptap'

// const editorExtensions = [
//   StarterKit.configure({
//     bulletList: false,
//     orderedList: false,
//     listItem: false,
//   }),
//   BulletList,
//   ListItem,
//   OrderedList,
//   TextStyle,
//   Color,
//   Highlight,
//   CharacterCount.configure({
//     limit: 5000,
//   }),
// ]

// const OrganizationRichTextField = ({ content, onContentChange, setIsCreatingNote, isCreatingNote, handleAddNote }) => {
//   const [characterCount, setCharacterCount] = useState(0)
//   const [editorInstance, setEditorInstance] = useState(null)
//   const theme = useTheme()
//   const hoverBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)'

//   useEffect(() => {
//     onContentChange(content)
//   }, [content, onContentChange])

//   useEffect(() => {
//     if (content === '') {
//       setCharacterCount(0)
//     }
//   }, [content])

//   const handleClear = () => {
//     if (editorInstance) {
//       editorInstance.commands.clearContent() // Clear editor content when needed
//     }
//     setIsCreatingNote(false)
//   }

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         width: '100%',
//         flexDirection: 'column',
//         position: 'relative',
//         '& .MuiCollapse-root': {
//           margin: 0,
//         },
//         '& .MuiMenuBar-root': {
//           margin: 0,
//         },
//       }}
//     >
//       <RichTextEditor
//         className='rich-text full-width-toolbar'
//         extensions={editorExtensions}
//         content={content}
//         onCreate={({ editor }) => {
//           setEditorInstance(editor) // Save the editor instance
//         }}
//         onUpdate={({ editor }) => {
//           setCharacterCount(editor.storage.characterCount.characters())
//           onContentChange(editor.getHTML())
//         }}
//         renderControls={() => (
//           <MenuControlsContainer
//             onClick={e => e.stopPropagation()}
//             style={{ width: '100%', justifyContent: 'space-between', height: '100%' }} // Ensure full width and spacing
//           >
//             <MenuSelectHeading style={{ flexGrow: 1 }} />
//             <MenuDivider />
//             <MenuButtonBold />
//             <MenuButtonItalic />
//             <MenuDivider />
//             <MenuButtonBulletedList />
//             <MenuButtonOrderedList />
//           </MenuControlsContainer>
//         )}
//         editorProps={{
//           attributes: {
//             style: 'height: 50vh; min-height: 76px; overflow-y: auto; text-align: left;',
//           },
//         }}
//       />
//       <IconButton
//         color='primary'
//         onClick={() => {
//           handleAddNote()
//           handleClear()
//         }}
//         sx={{
//           position: 'absolute',
//           bottom: '10px', // Position relative to the container
//           right: '10px', // Position relative to the container
//           backgroundColor: theme.palette.primary.main,
//           '&:hover': {
//             backgroundColor: hoverBgColor,
//           },
//         }}
//         disabled={isCreatingNote}
//       >
//         {isCreatingNote && <CircularProgress size={24} />}
//         <SendIcon sx={{ height: 25, width: 25, p: 0.1 }} />
//       </IconButton>
//     </Box>
//   )
// }

// export default OrganizationRichTextField
