export const ncoTeamTasks = [
  {
    PM: [
      {
        checklistSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeTasksSent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativesReady: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    MIA: [
      {
        verifyDependencies: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGTMGA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      ///////
      {
        configureDomains: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableKeyEvent: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        enableGoogleSignals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        keyCountingMethod: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      //////////
      {
        createAccountShells: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        createGSCTagForPM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        onboardingChecklist: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    SEO: [
      {
        completeSeoForm: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        onboardingChecklist: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    AM: [
      {
        introEmail: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        tapclicksSetup: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        setReportingCallDate: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
    DMS: [
      {
        campaignCreation: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        launchCampaigns: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],

    QA: [
      {
        campaignQuality: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        adApprovals: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const ncoTeamTasksArray = seoTier => {
  const statuses = [
    { name: 'N/A', color: 'rgb(121, 126, 147)' },
    { name: 'In Progress', color: 'rgb(253, 188, 100)' },
    { name: 'Stuck', color: 'rgb(232, 105, 125)' },
    { name: 'Done', color: 'rgb(51, 211, 145)' },
  ]

  // SEO Tasks (conditionally included)
  const seoTasks =
    seoTier === 'Tier 1'
      ? [{ completeSeoForm: statuses }]
      : ['Tier 2', 'Tier 3', 'Tier 4'].includes(seoTier)
      ? [{ completeSeoForm: statuses }, { loginCredentials: statuses }]
      : []

  // Onboarding tasks grouped by role
  const onboardingTasks = {
    PM: [{ checklistSent: statuses }, { creativeTasksSent: statuses }, { creativesReady: statuses }],
    MIA: [
      { verifyDependencies: statuses },
      { createGTMGA4: statuses },
      { configureDomains: statuses },
      { enableKeyEvent: statuses },
      { enableGoogleSignals: statuses },
      { keyCountingMethod: statuses },
      { createAccountShells: statuses },
      { createGSCTagForPM: statuses },
      { onboardingChecklist: statuses },
    ],
    AM: [{ introEmail: statuses }, { tapclicksSetup: statuses }, { setReportingCallDate: statuses }],
    DMS: [{ campaignCreation: statuses }, { launchCampaigns: statuses }],
    QA: [{ campaignQuality: statuses }, { adApprovals: statuses }],
  }

  // ✅ Convert to expected array format
  const formattedTasks = [{ ...onboardingTasks, SEO: seoTasks.length > 0 ? seoTasks : undefined }]
  console.log('formattedTasks', formattedTasks)
  return formattedTasks
}

export const taskNameMapping = {
  checklistSent: 'Checklist Sent',
  creativeTasksSent: 'Creative Tasks Sent',
  creativesReady: 'Creatives Ready',
  receivedDependencies: 'Dependencies Received',
  verifyDependencies: 'Verify Dependencies',
  createGTMGA4: 'Create GTM/GA4',
  //////
  configureDomains: 'Configure Domains',
  enableKeyEvent: 'Enable Key Event',
  enableGoogleSignals: 'Enable Google Signals',
  keyCountingMethod: 'Update Key Event Counting Method to "once per session"',
  //////
  createAccountShells: 'Create Account Shells',
  createGSCTagForPM: 'Create GSC Tag for PM',
  onboardingChecklist: 'Onboarding Checklist',
  introEmail: 'Intro Email',
  tapclicksSetup: 'Tapclicks Setup',
  setReportingCallDate: 'Set Reporting Call Date',
  campaignCreation: 'Campaign Creation',
  launchCampaigns: 'Launch Campaigns',
  campaignQuality: 'Campaign Quality',
  adApprovals: 'Ad Approvals',
  completeSeoForm: 'Complete SEO Onboarding Form',
}
export const mergeTasks = (existingTasks = {}, newTasksArray = []) => {
  // Ensure `newTasksArray` contains valid data
  const newTasksObject = newTasksArray[0] || {}

  return Object.keys(newTasksObject || {}).reduce(
    (merged, role) => {
      if (!merged[role]) merged[role] = []

      const existingRoleTasks = merged[role].reduce((acc, taskObj) => {
        const taskName = Object.keys(taskObj)[0]
        acc[taskName] = taskObj[taskName]
        return acc
      }, {})

      const updatedRoleTasks = (newTasksObject[role] || []).map(taskObj => {
        const taskName = Object.keys(taskObj)[0]
        return { [taskName]: existingRoleTasks[taskName] || taskObj[taskName] }
      })

      merged[role] = updatedRoleTasks
      return merged
    },
    { ...existingTasks }
  )
}

export const ncoDependenciesList = [
  {
    Dependency: [
      {
        fbPage: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        creativeAssets: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GA4: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        GTM: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        Integration: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        googleBusinessProfile: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
      {
        websiteAccess: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'In Progress', color: 'rgb(253, 188, 100)' },
          { name: 'Stuck', color: 'rgb(232, 105, 125)' },
          { name: 'Done', color: 'rgb(51, 211, 145)' },
        ],
      },
    ],
  },
]
export const dependenciesNameMapping = {
  fbPage: 'FB Page',
  creativeAssets: 'Creative Assets',
  GA4: 'GA4',
  GTM: 'GTM',
  Integration: 'Integration',
  googleBusinessProfile: 'Google Bus. Profile',
  websiteAccess: 'Website Access',
}

export const ncoChannelTasks = [
  {
    Channel: [
      {
        Search: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Social: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
      {
        Display: [
          { name: 'N/A', color: 'rgb(121, 126, 147)' },
          { name: 'Ready To Create', color: 'rgb(51, 158, 205)' },
          { name: 'Ready for QA', color: 'rgb(87, 155, 252)' },
          { name: 'Launched', color: 'rgb(51, 211, 145)' },
          { name: 'Awaiting Client', color: 'rgb(232, 105, 125)' },
        ],
      },
    ],
  },
]

export const ncoMasterStatus = [
  {
    status_name: 'Awaiting Dependencies',
    status_color_hexcode: 'rgb(87, 155, 252)',
  },
  {
    status_name: 'Dependencies Received',
    status_color_hexcode: 'rgb(255, 131, 88)',
  },
  { status_name: 'Handoff Call', status_color_hexcode: 'rgb(120, 75, 209)' },
  { status_name: 'Ready To Create', status_color_hexcode: 'rgb(250, 200, 50)' },
  { status_name: 'Launched', status_color_hexcode: 'rgb(3, 127, 76)' },
  // {
  //   status_name: 'Dependencies Verified',
  //   status_color_hexcode: 'rgb(226, 68, 92)',
  // },
  // { status_name: 'Agreement Signed', status_color_hexcode: 'rgb(0, 200, 117)' },
]
