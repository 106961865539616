import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  useTheme,
  Autocomplete,
  Avatar,
  FormControl,
  InputLabel,
  AvatarGroup,
  Popper,
  Button,
} from '@mui/material'
import { renderTeamOption } from '../Users/renderOptions'

interface MassUpdateUserSelectProps {
  name: string
  value: any // Expect an array of user objects (or an empty array)
  options: any[]
  displayLabel: string
  onChange: (e: React.ChangeEvent<{ name?: string; value: any }>) => void
  inputRef?: React.RefObject<HTMLInputElement>
  disabled?: boolean
  sx?: object
  onConfirm: () => void
}

const MassUpdateUserSelect: React.FC<MassUpdateUserSelectProps> = ({
  name,
  value,
  options,
  displayLabel,
  onChange,
  inputRef,
  disabled,
  sx,
  maxSize,
}) => {
  const theme = useTheme()
  // For multiple selection, ensure value is always an array.
  const [selectedValue, setSelectedValue] = useState<any[]>(value || [])

  useEffect(() => {
    setSelectedValue(value || [])
  }, [value])

  const handleChange = (_event: any, newValue: any[]) => {
    setSelectedValue(newValue)
    // Pass newValue (an array) to onChange.
    onChange({ target: { name, value: newValue } })
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <FormControl fullWidth>
        {/* <InputLabel id={`${name}-label`}>{displayLabel}</InputLabel> */}
        <Autocomplete
          multiple
          disableCloseOnSelect
          popupIcon={null} // Remove dropdown icon if desired
          options={options}
          getOptionLabel={option => `${option.name}`}
          isOptionEqualToValue={(option, value) => option.user_uuid === value.user_uuid}
          value={Array.isArray(selectedValue) ? selectedValue : []}
          onChange={handleChange}
          filterOptions={(options, { inputValue }) => {
            const searchTerm = inputValue.toLowerCase()

            return options?.filter(
              option =>
                option?.name?.toLowerCase().includes(searchTerm) ||
                (option.role && option.role.toLowerCase().includes(searchTerm))
            )
          }}
          renderOption={(props, option, state) => renderTeamOption(props, option, state, theme)}
          renderTags={(tagValue, getTagProps) => (
            <AvatarGroup
              max={4}
              spacing='small'
              sx={{
                display: 'flex',
                alignItems: 'center', // ✅ Ensures avatars align with the input
                '& .MuiAvatar-root': {
                  marginRight: 0.5, // Reduce the gap between avatars
                  height: 36,
                  width: 36,
                },
              }}
            >
              {tagValue.map((option, index) => {
                const { key, onDelete, ...rest } = getTagProps({ index })
                return (
                  <Avatar
                    key={option.user_uuid}
                    alt={option.name}
                    src={option.image_512}
                    sx={{ height: 35, width: 35 }}
                    {...rest}
                  />
                )
              })}
            </AvatarGroup>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={displayLabel}
              variant='outlined'
              placeholder='Search by name or role'
              autoFocus
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: null, // Remove default end adornment
              }}
              sx={{
                maxHeight: '45px',
                '& .MuiAutocomplete-inputRoot': {
                  maxHeight: '45px',
                  height: '45px',
                },
              }}
            />
          )}
          disabled={disabled}
          fullWidth
          sx={{
            '& .MuiInputBase-root': {
              margin: 0,
              p: 0,
              paddingLeft: 1,
            },
          }}
        />
      </FormControl>
    </Box>
  )
}

export default MassUpdateUserSelect
