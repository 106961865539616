import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

const keysForGrid = [
  'org_uuid',
  'name',
  'id',
  'website',
  'status',
  'contractedBy',
  'affiliated',
  'owner',
  'primaryContact',
  'contacts',
  'vertical',
  'deals',
  'type',
  'street',
  'city',
  'state',
  'cmsUsed',
  'social',
  'zip',
  'source',
  'team',
  'creation_timestamp',
]

export const getOrganizationsQuery = async (pk: string | undefined, skFilter: string | string[]) => {
  // Convert skFilter to a comma-separated string if it's an array.
  const skFilterStr = Array.isArray(skFilter) ? skFilter.join(',') : skFilter

  console.log('Calling API with pk:', pk, 'skFilter:', skFilterStr)
  try {
    const url = `${baseUrl}/aws/organizations_table/query`
    const params: any = { table: 'ORGANIZATIONS_TABLE', skFilter: skFilterStr }
    if (pk) {
      params.pk = pk
    }
    const response = await axios.get(url, { params })
    console.log('Response from API:', response.data)

    if (response.status === 204) {
      return { data: {}, status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching organization items:', error)
    throw new Error(error as any)
  }
}

export const useOrganizationsQuery = (pk: string | undefined, skFilter: string | string[]) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['orgItems', pk, skFilter],
    queryFn: () => getOrganizationsQuery(pk, skFilter),
    enabled:
      (typeof skFilter === 'string' ? !!skFilter.trim() : Array.isArray(skFilter) && skFilter.length > 0) && true,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
  })

  return {
    data,
    isLoading,
    isError,
  }
}
