import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ orgId, orgData }) => {
      console.log('orgId', orgId, 'orgData', orgData)
      const url = `${baseUrl}/aws/organizations/update/${orgId}`
      const response = await axios.put(url, orgData)
      return response.data
    },

    onMutate: async ({ orgId, orgData }) => {
      await queryClient.cancelQueries(['organization', orgId])
      const previousDeal = queryClient.getQueryData(['organization', orgId])

      queryClient.setQueryData(['organization', orgId], old => ({
        ...old,
        ...orgData,
      }))

      return { previousDeal }
    },

    onError: (err, { orgId }, context) => {
      queryClient.setQueryData(['organization', orgId], context.previousDeal)
    },

    onSuccess: (_, { orgId }) => {
      // queryClient.invalidateQueries(['/aws/deals', orgId]) // Invalidate all deals to ensure the grid rerenders
      // queryClient.invalidateQueries(['organizations']) // Invalidate organizations if they are related to the deals grid
    },
  })

  return mutation
}
