import React, { useState, useEffect } from 'react'
import { AvatarGroup, Avatar, Box, useTheme } from '@mui/material'
import { useUsers } from '../../api/aws/useUsers'

const RenderAssignees = ({ assignees }) => {
  const theme = useTheme()
  const additionalAvatarBgColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        // padding: densityStyles.padding,
        // gap: densityStyles.gap,
      }}
    >
      <AvatarGroup
        max={3}
        spacing={'medium'}
        slotProps={{
          additionalAvatar: {
            sx: {
              height: 30,
              width: 30,
              backgroundColor: additionalAvatarBgColor,
            },
          },
        }}
      >
        {assignees && Array.isArray(assignees)
          ? assignees.map(user => (
              <Avatar
                key={user.user_uuid || user.id}
                alt={user.real_name}
                src={user.image_512}
                sx={{ height: 30, width: 30 }}
              />
            ))
          : null}
      </AvatarGroup>
    </Box>
  )
}

export default RenderAssignees
