import React, { useEffect, useState } from 'react'
import { getContact, useContact } from '../../../api/aws/useContact'
import { CircularProgress, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export interface Contact {
  linkedinProfile: string
  summary: string
  zip: string
  contacts_uuid: string
  lastName: string
  officePhone: string
  timeCreated: string
  email: string
  name: string
  firstName: string
  photoUrl: string
  state: string
  city: string
  index_name: string
  billingEmail: string
  id: string
  panda_contact_id: string
  phone: string
  middleName: string
  street: string
  title: string
}
interface ContactCellProps {
  contactId: string
}

const ContactCell: React.FC<ContactCellProps> = ({ contactId }) => {
  const { data: contact, isLoading: isContactLoading } = useContact(contactId)
  return isContactLoading ? (
    <CircularProgress />
  ) : (
    <Link
      to={`/contacts/${contactId}/details`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        textDecoration: 'none',
      }}
    >
      <Typography>{contact?.name}</Typography>
    </Link>
  )
}

export default ContactCell
