import React, { useState } from 'react'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DescriptionIcon from '@mui/icons-material/Description'

// Helper function that returns either an image preview or a representative icon.
const getFileThumbnail = (file: File): JSX.Element => {
  if (file.type.startsWith('image/')) {
    return (
      <Box
        component='img'
        src={URL.createObjectURL(file)}
        alt='file preview'
        sx={{
          width: '100%',
          height: 40,
          objectFit: 'cover',
          borderRadius: 1,
        }}
      />
    )
  } else if (file.type === 'application/pdf') {
    return <PictureAsPdfIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
  } else if (file.name.match(/\.(doc|docx)$/i)) {
    return <DescriptionIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
  } else {
    return <DescriptionIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
  }
}

interface NewFileAttachmentsProps {
  selectedFiles: File[]
  onRemoveFile: (index: number) => void
}

export const NewFileAttachments: React.FC<NewFileAttachmentsProps> = ({ selectedFiles, onRemoveFile }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {selectedFiles.map((file, index) => (
        <Box key={index} sx={{ position: 'relative', width: 40, height: 40 }}>
          <Tooltip title={file.name}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                position: 'relative',
                '&:hover .overlay': { opacity: 1 },
                alignItems: 'center',
              }}
            >
              {getFileThumbnail(file)}
              {/* Overlay with delete icon */}
              <Box
                className='overlay'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'rgba(0,0,0,0.4)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
              >
                <IconButton
                  size='small'
                  onClick={e => {
                    e.stopPropagation()
                    onRemoveFile(index)
                  }}
                  sx={{
                    color: 'white',
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}
