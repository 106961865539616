import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

export const useProductsByDealId = (dealId: string | null) => {
  return useQuery({
    queryKey: ['productsByDealId', dealId],
    queryFn: async () => {
      if (!dealId) return []
      const { data } = await axios.get(`${baseUrl}/aws/products_table/${dealId}`)
      return data || []
    },
    enabled: !!dealId, // ✅ Only run if orgId exists
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 2, // Retry twice on failure
  })
}
