// context/LayoutContext.js
import { Box } from '@mui/material'
import React, { createContext, useState, useEffect, useContext } from 'react'

const LayoutContext = createContext({
  pinned: false,
  togglePinned: () => {},
})

/** Access the pinned state from your children */
export const useLayoutContext = () => useContext(LayoutContext)

export const LayoutProvider = ({ children }) => {
  const [pinned, setPinned] = useState(true)
  const [navOpen, setNavOpen] = useState(false)
  // A new function that sets navOpen to a boolean rather than toggling
  const setNavOpenState = newValue => {
    setNavOpen(newValue)
  }
  // Load from localStorage on mount
  useEffect(() => {
    const storedPinned = localStorage.getItem('navPinned')

    if (storedPinned === null) {
      // If no value is stored, set it to true (locked by default)
      localStorage.setItem('navPinned', 'true')
      setPinned(true)
    } else {
      // Use the stored value
      setPinned(storedPinned === 'true')
    }
  }, [])

  // Toggle pinned, store in localStorage
  const togglePinned = () => {
    setPinned(prev => {
      const newPinnedState = !prev
      localStorage.setItem('navPinned', newPinnedState.toString())
      return newPinnedState
    })
  }

  return (
    <LayoutContext.Provider value={{ pinned, togglePinned, navOpen, setNavOpenState }}>
      {children}
    </LayoutContext.Provider>
  )
}
