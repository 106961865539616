import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

const fetchOrgChecklists = async (orgId: string) => {
  const response = await axios.get(`${baseUrl}/aws/checklists/org/${orgId}`)
  console.log('RESPONSE CHECKLISTS: ', response.data)
  return response.data
}

export const useGetOrgChecklists = org => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['checklists', `ORG_${org?.org_uuid}`], // Include the full PK if needed
    queryFn: async () => {
      if (!org?.org_uuid) return null
      const existingChecklists = await fetchOrgChecklists(org.org_uuid)
      console.log('📝 Existing Checklists:', existingChecklists)
      // Optionally, filter to make sure the returned checklists match the expected PK:
      return existingChecklists.filter(chk => chk.PK === `ORG_${org.org_uuid}`)
    },
  })
}
