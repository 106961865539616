import React, { useState, useEffect, useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGridPro, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
import { gridContainerStyles } from '../NCO/style.js'
import MetricsToolbar from './MetricsToolbar.js'
import TotalsFooter from './TotalsFooter.tsx'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { computeOverallGrossTotals } from './computeMonthlyMetrics.ts'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals.js'
import { renderServiceIcon } from './renderServiceIcon.ts'
import { useProductsTable } from '../../api/aws/products/useProductsTable.ts'

interface Product {
  mediaSpend: string
  endDate: string
  selectedProduct: object
  status: string
  org_uuid: string
  qty: number
  deal_uuid: string
  startDate: string
  SK: string
  rowId: string
  margin: number
  total: number
  subcategory: string
  grossProfit: string
  addtlDescriptor: string
  billedPrice: string
  category: string
  payer: string
  instanceId: string
  dateRange: string
  accountUsed: string
  description: string
  price: number
  PK: string
  productName: string
  org_name: string
}

const initialSortModel: GridSortModel = [
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
]

export const computeProductMonthlyMetrics = (
  org: { products: Product[] },
  month: string
): { [service: string]: number } => {
  const [year, mon] = month.split('-').map(Number)
  const monthStart = new Date(year, mon - 1, 1)
  const monthEnd = new Date(year, mon, 0)

  const metrics: { [service: string]: number } = {}
  const validStatuses = ['Active', 'Active (system)', 'Month To Month', '30 Day Notice', 'In Progress']

  org.products.forEach(product => {
    if (!validStatuses.includes(product.status)) return

    const prodStart = new Date(product.startDate)
    const prodEnd = new Date(product.endDate)
    const serviceName = product.productName
    const mediaSpend = parseFloat(product.mediaSpend || '0')
    const qty = product.qty || 1
    const spend = mediaSpend * qty

    const isMonthToMonth = product.status === 'Month To Month'
    const overlapsMonth = prodStart <= monthEnd && prodEnd >= monthStart

    if ((isMonthToMonth || overlapsMonth) && serviceName) {
      const months =
        (prodEnd.getFullYear() - prodStart.getFullYear()) * 12 + (prodEnd.getMonth() - prodStart.getMonth()) + 1
      const monthlySpend = spend / months
      metrics[serviceName] = (metrics[serviceName] || 0) + monthlySpend
    }
  })

  return metrics
}

const ProductsTableMetrics: React.FC = () => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()
  const { showSnackbar } = useSnackbarContext()
  const { data: productsTable, isLoading: isProductsTableLoading } = useProductsTable(true)
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], quickFilterValues: [] })
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }])
  const [rowCount, setRowCount] = useState(0)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('productColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          org_uuid: false,
          affiliated: false,
          source: false,
          street: false,
          city: false,
          state: false,
          zip: false,
          cmsUsed: false,
          social: false,
          dealIds: false,
        }
  })

  const processedOrganizations = useMemo(() => {
    if (!productsTable || isProductsTableLoading) return []

    const orgGroups = new Map<string, any>()
    const validStatuses = ['Active', 'Active (system)', 'Month To Month', '30 Day Notice', 'In Progress']

    productsTable.forEach(product => {
      const { org_uuid, org_name, productName, mediaSpend, qty, status } = product
      if (!org_uuid || !validStatuses.includes(status)) return

      const key = productName
      const numericSpend = parseFloat(mediaSpend || '0')
      const quantity = qty || 1
      const spend = numericSpend * quantity

      if (!orgGroups.has(org_uuid)) {
        orgGroups.set(org_uuid, {
          org_uuid,
          name: org_name,
          products: [],
          deals: [],
          aggregatedMetrics: [],
          monthlyAggregatedMetrics: {},
          metricsMap: {},
        })
      }

      const org = orgGroups.get(org_uuid)
      org.products.push(product)

      if (!selectedMonth) {
        org.metricsMap[key] = (org.metricsMap[key] || 0) + spend
      }
    })

    orgGroups.forEach(org => {
      if (!selectedMonth) {
        org.aggregatedMetrics = Object.entries(org.metricsMap).map(([k, v]) => ({ [k]: v }))
        Object.entries(org.metricsMap).forEach(([k, v]) => {
          org[k] = v
        })
      } else {
        const monthly = computeProductMonthlyMetrics(org, selectedMonth)
        org.monthlyAggregatedMetrics = monthly
        Object.entries(monthly).forEach(([k, v]) => {
          org[k] = v // 🔥 This is what was missing
        })
      }
    })

    return Array.from(orgGroups.values()).filter(org => {
      if (!selectedMonth) return true
      return Object.values(org.monthlyAggregatedMetrics || {}).some(val => val > 0)
    })
  }, [productsTable, isProductsTableLoading, selectedMonth])
  useEffect(() => {
    if (processedOrganizations) {
      setRowCount(processedOrganizations?.length || 0)
    }
  }, [processedOrganizations])
  const grossTotals = useMemo(() => {
    return computeOverallGrossTotals(processedOrganizations, selectedMonth)
  }, [processedOrganizations, selectedMonth])

  const dynamicColumns = useMemo(() => {
    const union = new Set<string>()
    processedOrganizations.forEach(org => {
      const metrics = selectedMonth
        ? org.monthlyAggregatedMetrics
        : org.aggregatedMetrics?.reduce((acc, obj) => ({ ...acc, ...obj }), {}) || {}
      Object.keys(metrics).forEach(key => union.add(key))
    })
    const filteredKeys = Array.from(union).filter(key => (grossTotals[key] || 0) !== 0)
    return filteredKeys.map(key => ({
      field: key,
      headerName: key,
      renderHeader: () => (
        <Box>
          <img src={renderServiceIcon(key)} alt='icon' style={{ height: 28, width: 28 }} />
        </Box>
      ),
      width: 75,
      flex: 1,
      type: 'number',
      valueGetter: params => params || 0,
      renderCell: params => (params?.value > 0 ? formatCurrency(params?.value) : '–'),
    })) as GridColDef[]
  }, [processedOrganizations])

  const baseColumns: GridColDef[] = [
    { field: 'name', headerName: 'Organization Name', width: 150 },
    {
      field: 'products',
      headerName: 'Active Products',
      width: 150,
      valueGetter: params => {
        return params?.length || 0
      },
    },
    { field: 'contractedBy' },
  ]

  const columns: GridColDef[] = [...baseColumns, ...dynamicColumns]
  useEffect(() => {
    setFilterModel(prev => {
      const otherFilters = prev.items.filter(item => item.field !== '__monthFilter')
      return selectedMonth
        ? {
            ...prev,
            items: [
              ...otherFilters,
              {
                id: '__monthFilter',
                field: '__monthFilter',
                operator: 'is',
                value: selectedMonth,
              },
            ],
          }
        : {
            ...prev,
            items: otherFilters,
          }
    })
  }, [selectedMonth])
  useEffect(() => {
    if (filterModel) {
      console.log('filterModel', filterModel)
    }
  }, [filterModel])

  return (
    <Box
      sx={{
        position: 'relative',
        ...gridContainerStyles,
        pt: pinned ? '60px' : 0,
        transition: 'all 0.3s ease-in-out',
        height: '98%',
      }}
    >
      <DataGridPro
        rows={processedOrganizations}
        columns={columns}
        loading={isProductsTableLoading}
        filterModel={filterModel}
        onFilterModelChange={model => setFilterModel(model)}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        slots={{ toolbar: MetricsToolbar, footer: TotalsFooter }}
        slotProps={{
          toolbar: {
            filterModel: filterModel,
            visibilityModel: columnVisibilityModel,
            sortModel,
            setFilterModel,
            onClearSorting: e => {
              e.preventDefault()
              setSortModel([{ field: 'name', sort: 'asc' }])
            },
            onClearVisibility: e => {
              e.preventDefault()
              const init = {
                org_uuid: false,
                affiliated: false,
                source: false,
                street: false,
                city: false,
                state: false,
                zip: false,
                cmsUsed: false,
                social: false,
                dealIds: false,
              }
              setColumnVisibilityModel(init)
              localStorage.setItem('productColumnVisibilityModel', JSON.stringify(init))
            },
            monthFilterValue: selectedMonth,
            setMonthFilter: setSelectedMonth,
          },
          footer: {
            grossTotals,
            dynamicColumns: columns,
            rowCount: rowCount,
          },
        }}
        getRowId={row => row.org_uuid}
        sx={{
          '& .MuiDataGrid-cell': { paddingRight: 1 },
          '& .MuiDataGrid-columnHeaders': { borderBottom: 'none' },
        }}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default ProductsTableMetrics
