import React from 'react'
import { Box, Tooltip } from '@mui/material'

interface ThumbnailProps {
  note: NoteItem
  fallbackIcon: string
  openFrame: (embedUrl: string, webContentLink: string) => void
}

type NoteItem = {
  embedUrl: string
  thumbnailLink: string
  webContentLink: string
}

const PreviewThumbnail: React.FC<ThumbnailProps> = ({ note, openFrame, fallbackIcon }) => {
  return (
    <>
      {note.thumbnailLink && note.embedUrl && (
        <Box
          sx={{
            cursor: 'pointer',
            ml: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onClick={() => openFrame(note.embedUrl, note?.webContentLink)}
        >
          <Tooltip title={note?.name || 'Click to preview'}>
            <Box
              component='img'
              src={note.thumbnailLink || fallbackIcon}
              alt='Note Thumbnail'
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 1,
                paddingRight: 0.6,
                paddingLeft: 0.6,
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'scale(1.15)',
                },
              }}
            />
          </Tooltip>
        </Box>
      )}
    </>
  )
}

export default PreviewThumbnail

// import React from 'react'
// import { Typography, Box } from '@mui/material'
// interface ThumbnailProps {
//   note: NoteItem
//   fallbackIcon: string
//   openFrame: (embedUrl: string) => void
// }

// type NoteItem = {
//   embedUrl: string
//   thumbnailLink: string
// }
// const PreviewThumbnail: React.FC<ThumbnailProps> = ({ note, openFrame, fallbackIcon }) => {
//   return (
//     <>
//       {note.thumbnailLink && note.embedUrl && (
//         <Box
//           sx={{
//             cursor: 'pointer',
//             ml: 1,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//           onClick={() => openFrame(note.embedUrl)}
//         >
//           <img
//             src={note?.thumbnailLink || fallbackIcon}
//             alt='Note Thumbnail'
//             style={{
//               width: 34,
//               height: 'auto',
//               borderRadius: 4,
//               ':hover': {
//                 transform: 'scale(1.05)',
//                 transition: 'transform 0.2s ease',
//               },
//             }}
//           />
//           {/* <Typography variant='caption'>Preview</Typography> */}
//         </Box>
//       )}
//     </>
//   )
// }

// export default PreviewThumbnail
