// computeMonthlyMetrics.ts
export interface Product {
  productName: string
  mediaSpend: string // e.g., "3480.00"
  qty: number
  startDate: string // ISO string, e.g., "2024-06-01T17:00:00.000Z"
  endDate: string // ISO string, e.g., "2025-05-31T17:00:00.000Z"
  // ...other fields if needed
}

export interface Deal {
  products: Product[]
  // ...other fields
}

export interface OrganizationWithDeals {
  org_uuid: string
  name: string
  deals: Deal[]
  // ...other fields
}
export interface AggregatedMetric {
  [product: string]: number
}
export const computeMonthlyMetrics = (org: OrganizationWithDeals, month: string): { [service: string]: number } => {
  // Calculate the start and end date for the selected month.
  const [year, mon] = month.split('-').map(Number)
  const monthStart = new Date(year, mon - 1, 1)
  const monthEnd = new Date(year, mon, 0) // last day of month

  const metrics: { [service: string]: number } = {}
  const monthToMonthMetrics: { [service: string]: number } = {}
  // Loop through each deal and its products.
  org.deals.forEach(deal => {
    if (deal.products && Array.isArray(deal.products)) {
      deal.products.forEach(product => {
        const prodStart = new Date(product.startDate)
        const prodEnd = new Date(product.endDate)
        // Check for overlap: product is active during the month if:
        // prodStart <= monthEnd AND prodEnd >= monthStart.
        if (deal?.dealStatus !== 'Month To Month') {
          if (prodStart <= monthEnd && prodEnd >= monthStart) {
            const mediaSpend = parseFloat(product.mediaSpend) || 0
            const qty = product.qty || 0
            const totalSpend = mediaSpend * qty

            // Calculate the number of months in the product's active period.
            const yearDiff = prodEnd.getFullYear() - prodStart.getFullYear()
            const monthDiff = prodEnd.getMonth() - prodStart.getMonth()
            const totalMonths = yearDiff * 12 + monthDiff + 1

            // Calculate the monthly spend by dividing the total spend by the number of months.
            const monthlySpend = totalSpend / totalMonths
            const serviceName = product.productName
            metrics[serviceName] = (metrics[serviceName] || 0) + monthlySpend
          }
        } else {
          const mediaSpend = parseFloat(product.mediaSpend) || 0
          const qty = product.qty || 0
          const totalSpend = mediaSpend * qty

          // Calculate the number of months in the product's active period.
          const yearDiff = prodEnd.getFullYear() - prodStart.getFullYear()
          const monthDiff = prodEnd.getMonth() - prodStart.getMonth()
          const totalMonths = yearDiff * 12 + monthDiff + 1

          // Calculate the monthly spend by dividing the total spend by the number of months.
          const monthlySpend = totalSpend / totalMonths
          const serviceName = product.productName
          const monthToMonthValues = (monthToMonthMetrics[serviceName] =
            (monthToMonthMetrics[serviceName] || 0) + monthlySpend)
          metrics[serviceName] = (metrics[serviceName] || 0) + monthlySpend
          monthToMonthMetrics[serviceName] = (monthToMonthMetrics[serviceName] || 0) + monthlySpend
        }
      })
    }
  })

  return metrics
  return { metrics: metrics, monthToMonthMetrics: monthToMonthMetrics }
}

// export const computeOverallGrossTotals = (
//   organizations: { aggregatedMetrics?: AggregatedMetric[] }[]
// ): { [product: string]: number } => {
//   const totals: { [product: string]: number } = {}

//   organizations.forEach(org => {
//     if (org.aggregatedMetrics && Array.isArray(org.aggregatedMetrics)) {
//       org.aggregatedMetrics.forEach(metricObj => {
//         const product = Object.keys(metricObj)[0]
//         const value = metricObj[product] || 0
//         totals[product] = (totals[product] || 0) + value
//       })
//     }
//   })

//   return totals
// }
export interface AggregatedMetric {
  [product: string]: number
}

export const computeOverallGrossTotals = (
  organizations: {
    aggregatedMetrics?: AggregatedMetric[]
    monthlyAggregatedMetrics?: AggregatedMetric
  }[],
  month?: string
): { [product: string]: number } => {
  const totals: { [product: string]: number } = {}

  organizations.forEach(org => {
    if (month && org.monthlyAggregatedMetrics) {
      // Use the monthly computed metrics (an object with keys for each product)
      Object.entries(org.monthlyAggregatedMetrics).forEach(([product, value]) => {
        totals[product] = (totals[product] || 0) + value
      })
    } else if (org.aggregatedMetrics && Array.isArray(org.aggregatedMetrics)) {
      // Otherwise, use overall aggregatedMetrics (the backend-provided totals)
      org.aggregatedMetrics.forEach(metricObj => {
        const product = Object.keys(metricObj)[0]
        const value = metricObj[product] || 0
        totals[product] = (totals[product] || 0) + value
      })
    }
  })

  return totals
}

// Optionally, if you prefer an array of single-key objects:
export const computeOverallGrossTotalsArray = (
  organizations: { aggregatedMetrics?: AggregatedMetric[] }[]
): AggregatedMetric[] => {
  const totals = computeOverallGrossTotals(organizations)
  return Object.entries(totals).map(([product, total]) => ({ [product]: total }))
}
