import React from 'react'
import { useGridApiContext, useGridSelector, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { gridFilteredDescendantCountLookupSelector } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { ExpandMore, ChevronRight } from '@mui/icons-material'

export function CustomTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode, formattedValue, row } = props
  const apiRef = useGridApiContext()
  const descendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
  const descendantCount = descendantCountLookup[rowNode.id] ?? 0

  const handleToggle = (event: React.MouseEvent) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  const label = formattedValue?.split('___')[0]

  return (
    <Box
      sx={{
        ml: rowNode.depth * 2,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '.deal-label': {
          color: '#4B9CD3',
          fontStyle: 'italic',
        },
      }}
    >
      {row.type === 'organization' && (
        <IconButton
          size='small'
          onClick={handleToggle}
          aria-label={rowNode.childrenExpanded ? 'Collapse' : 'Expand'}
          disabled={descendantCount === 0}
          sx={{ mr: 1 }}
        >
          {rowNode.childrenExpanded ? (
            <ExpandMore sx={{ opacity: descendantCount === 0 ? 0.2 : 1 }} />
          ) : (
            <ChevronRight sx={{ opacity: descendantCount === 0 ? 0.2 : 1 }} />
          )}
        </IconButton>
      )}

      {row.type === 'organization' ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}
        >
          <Typography fontWeight={600}>{`🏢 ${label}`}</Typography>
          <Chip label={descendantCount} color='primary' size='small' />
        </Box>
      ) : (
        <span className='deal-label'>{`📄 ${label}`}</span>
      )}
    </Box>
  )
}
