import { Mention } from '@tiptap/extension-mention'

export const CustomMention = Mention.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      imageUrl: {
        default: null,
        parseHTML: element => element.getAttribute('data-image-url'),
        renderHTML: attributes => (attributes.imageUrl ? { 'data-image-url': attributes.imageUrl } : {}),
      },
    }
  },
})
// import { Mention } from '@tiptap/extension-mention'
// import { createMentionSuggestionOptions } from './createMentionSuggestionOptions.ts'

// // For "@" mentions:
// export const CustomAtMention = Mention.extend({
//   addAttributes() {
//     return {
//       ...this.parent?.(),
//       imageUrl: {
//         default: null,
//         parseHTML: element => element.getAttribute('data-image-url'),
//         renderHTML: attributes => (attributes.imageUrl ? { 'data-image-url': attributes.imageUrl } : {}),
//       },
//     }
//   },
// }).configure({
//   HTMLAttributes: {
//     class: 'custom-at-mention',
//   },
//   suggestion: {
//     char: '@',
//     ...createMentionSuggestionOptions(), // returns suggestion options for users
//   },
// })

// // For "/" mentions (you could reuse the same suggestion options or provide your own):
// export const CustomSlashMention = Mention.extend({
//   name: 'slashMention', // important: give it a unique name
//   addAttributes() {
//     return {
//       ...this.parent?.(),
//       imageUrl: {
//         default: null,
//         parseHTML: element => element.getAttribute('data-image-url'),
//         renderHTML: attributes => (attributes.imageUrl ? { 'data-image-url': attributes.imageUrl } : {}),
//       },
//     }
//   },
// }).configure({
//   HTMLAttributes: {
//     class: 'custom-slash-mention',
//   },
//   suggestion: {
//     char: '/',
//     ...createMentionSuggestionOptions(), // or create a separate function for "/" if needed
//   },
// })
