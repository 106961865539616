import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

export const useProductsTable = (enabled: boolean) => {
  return useQuery({
    queryKey: ['products_table'],
    queryFn: async () => {
      const { data } = await axios.get(`${baseUrl}/aws/products_table`)
      return data || []
    },
    enabled: enabled ?? false, // ✅ Only run if orgId exists
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 2, // Retry twice on failure
  })
}
