import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getFilteredOrganizations = async (filters = {}) => {
  try {
    const url = `${baseUrl}/aws/organizations/filter`

    if (!filters || Object.keys(filters).length === 0) {
      console.warn('No filters provided for getFilteredOrganizations')
      return []
    }

    const response = await axios.get(url, {
      params: { filters: JSON.stringify(filters) },
    })
    return response.data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return []
  }
}

export const useFilteredOrganizations = (enabled, queryKey = ['filteredOrganizations'], filters) => {
  const validFilters = filters && Object.keys(filters).length > 0 ? filters : {}

  const {
    data: filteredOrganizations,
    isLoading: isFilteredOrganizationsLoading,
    isError: isFilteredOrganizationsError,
  } = useQuery({
    queryKey: [queryKey, validFilters],
    queryFn: () => getFilteredOrganizations(validFilters),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
    enabled: enabled && Object.keys(validFilters).length > 0,
  })

  return {
    filteredOrganizations,
    isFilteredOrganizationsLoading,
    isFilteredOrganizationsError,
  }
}
