import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Grid, useMediaQuery } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import OrganizationDetailsHeader from './OrganizationDetailsHeader'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { getOrganizationById, useOrganizationById } from '../../api/aws/useOrganization'
import { useQueryClient } from '@tanstack/react-query'
import { getQuery } from '../../api/aws/useGetQuery'
import DriveFoldersById from '../Google/DriveFoldersById.tsx'
import { OrganizationDetailsContent } from '../../style/styleElements.js'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { DetailsContent } from '../../style/styleElements.js'

const OrganizationPage = () => {
  const { orgId } = useParams()
  const queryClient = useQueryClient()
  const { pinned } = useLayoutContext()
  const { organization, organizationLoading, organizationError } = useOrganizationById(orgId)
  const theme = useTheme()
  const location = useLocation()
  const [tabIndex, setTabIndex] = useState(0)
  const { activeUser } = useActiveUser()
  const integrationUsers = ['U01FK2W9YNS', 'U06LJAMPWJ1', 'U049PRPH6TF', 'U015KLM51B6', 'U062JDP8R5X', 'UHKAA49CJ']
  const isMacbookPro = useMediaQuery(
    '(min-width: 1512px) and (max-width: 1512px) and (min-height: 857px) and (max-height: 857px)'
  )
  useEffect(() => {
    const path = location.pathname.split('/').pop()
    switch (path) {
      case 'deals':
        setTabIndex(1)
        break
      case 'tasks':
        setTabIndex(2)
        break
      // case 'billing':
      //   setTabIndex(3)
      //   break
      case 'notes':
        setTabIndex(3)
        break
      case 'integration':
        setTabIndex(4)
        break
      case 'folder':
        // setTabIndex(4)
        break
      default:
        setTabIndex(0)
        break
    }
  }, [location.pathname])

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    // <Box sx={{ maxHeight: '100vh' }}>
    <>
      <Box sx={{ display: 'flex', maxWidth: '100vw', overflowY: 'hidden' }}>
        <OrganizationDetailsHeader />
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label='organization tabs'
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
        }}
      >
        <Tab label='Details' component={Link} to={`/organizations/${orgId}/details`} />
        <Tab label='Deals' component={Link} to={`/organizations/${orgId}/deals`} />
        <Tab label='Tasks' component={Link} to={`/organizations/${orgId}/tasks`} />
        {/* <Tab label='Billing' component={Link} to={`/organizations/${orgId}/billing`} /> */}
        <Tab label='Notes' component={Link} to={`/organizations/${orgId}/notes`} />
        {integrationUsers.includes(activeUser?.user?.id) && (
          <Tab label='Integration' component={Link} to={`/organizations/${orgId}/integration`} />
        )}
        <Tab label={<DriveFoldersById folderId={organization?.googleDriveFolderId} />} />
      </Tabs>
      <Grid
        container
        sx={{
          height: isMacbookPro ? 'calc(100vh - 415px)' : 'calc(100vh - 120px)', // Adjust this value based on header height
          overflowY: isMacbookPro ? 'hidden' : 'hidden', // Allow scrolling if content overflows\
          overflowX: 'auto',
          p: 0,
        }}
      >
        <Grid item xs={12}>
          <OrganizationDetailsContent open={pinned}>
            <DetailsContent open={pinned}>
              <Outlet />
            </DetailsContent>
          </OrganizationDetailsContent>
        </Grid>
      </Grid>
    </>
  )
}

export default OrganizationPage
