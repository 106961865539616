import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, useTheme } from '@mui/material'

const DeleteIconButton = ({ hasErrors, onDelete }) => {
  const theme = useTheme()
  return (
    <IconButton
      disabled={hasErrors}
      onClick={onDelete}
      sx={{
        height: 28,
        width: 28,
        backgroundColor: theme.palette.background.default,
        ':hover': {
          backgroundColor: theme.palette.text.error,
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgb(52, 60, 73)', // Custom background color when disabled
        },
      }}
    >
      <DeleteIcon sx={{ color: hasErrors ? 'rgba(231, 109, 137, 0.5)' : '', height: 22, width: 22 }} />
    </IconButton>
  )
}

export default DeleteIconButton
