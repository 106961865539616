import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTasks } from '../api/aws/useTasks'

export interface Task {
  PK: string
  SK: string
  name: string
  type: string
  taskDays: number
  startDate: string | null
  dateDue: string | null
  priority: string
  highPrio: boolean
  AM: any[]
  DMS: any[]
  masterStatus: string
  status: string
  // ... any additional fields such as creativeLead, PM, SEO, MIA, QA
}
interface TasksGridProps {
  filters: object
  route: string
  context: string
  initialVisibilityState: object
  initialSortModel: object
  statusKey: string
  statusLabel: string
  filterOptions: string[]
  boardTheme: string
  // renderFunction: (
  //   theme: any,
  //   users: any[],
  //   densityModel: string,
  //   handleMassUpdate: (data: any, ids: string[]) => void,
  //   rowSelectionModel: any[],
  //   handleTaskClick: (event: any, taskData: any) => void,
  //   handleClickEditStart: (params: any) => void,
  //   handleHighPrioChange: (taskId: string, isChecked: boolean) => void,
  //   handleUserChange: (taskId: string, fieldName: string, newValue: object[]) => void,
  //   handleSelectUserRow: (rowId: string) => void
  // apiRef: React.MutableRefObject<GridApiPro>
  // ) => GridColDef[]
}
interface ParamsPMProps {
  value: object[]
}

const DevDataGrid: React.FC<TasksGridProps> = () => {
  const { tasks, isTasksLoading, isTasksError } = useTasks(true)
  const [selectionModel, setSelectionModel] = useState([])
  const apiRef = useGridApiRef()

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'masterStatus',
      headerName: 'Master Status',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    // {
    //   field: 'PM',
    //   headerName: 'PM',
    //   width: 100,
    //   flex: 0.5,
    //   headerAlign: 'center',
    //   valueGetter: (params: ParamsPMProps) => params,
    //   renderCell: (params: ParamsPMProps) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '100%',
    //         }}
    //       >
    //         {params.value}
    //       </Box>
    //     )
    //   },
    // },
  ]

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        maxWidth: '100vw',
        maxHeight: '100vh',
        // width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'padding 0.3s ease',
        height: '98%',
      }}
    >
      <DataGridPro
        initialState={{}}
        rows={tasks ?? []}
        columns={columns ?? []}
        // getRowId={row => `${row.PK}::${row.SK}`}
        getRowId={row => row?.task_uuid || row?.id}
        onCellEditStart={(params, event) => {
          if (selectionModel.length > 1) {
            event.defaultMuiPrevented = true // Prevent editing
          }
        }}
        checkboxSelection
        disableRowSelectionOnClick
        apiRef={apiRef}
      />
    </Box>
  )
}

export default DevDataGrid
