import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getEntities = async endpoint => {
  try {
    const url = new URL(endpoint, baseUrl) // ✅ Correctly handles URL construction

    const { data } = await axios.get(url.toString())
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // ✅ Return an empty array to prevent crashes
  }
}

export const useEntities = (enabled = false) => {
  return useQuery({
    queryKey: ['entities'], // ✅ Different search terms will trigger different queries
    queryFn: () => getEntities('/aws/entities/all'),
    staleTime: 1000 * 60 * 5, // ✅ Keep cache for 5 minutes
    cacheTime: 1000 * 60 * 30, // ✅ Cache for 30 minutes
    refetchOnWindowFocus: false, // ✅ Avoid refetching on window focus
    refetchOnReconnect: false, // ✅ Avoid refetching on network reconnect
    enabled: !!enabled, // ✅ Only fetch if enabled and search term is not empty
  })
}
// export const getEntities = async (endpoint, searchTerm) => {
//   if (!searchTerm) return []
//   console.log('searchTerm', searchTerm)
//   try {
//     const url = new URL(endpoint, baseUrl)
//     url.searchParams.append('searchTerm', searchTerm.toLowerCase()) // ✅ Convert to lowercase

//     const { data } = await axios.get(url.toString())
//     return data || []
//   } catch (error) {
//     console.error('❌ Error fetching data:', error)
//     return []
//   }
// }

// export const useEntities = (enabled = false, searchTerm = '') => {
//   return useQuery({
//     queryKey: ['entities', searchTerm],
//     queryFn: () => getEntities('/aws/entities/all', searchTerm),
//     staleTime: 1000 * 60 * 5,
//     cacheTime: 1000 * 60 * 30,
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//     enabled: !!enabled && searchTerm.length > 0, // ✅ Only fetch when searchTerm is valid
//   })
// }
