import React, { useState } from 'react'
import { Box, Badge, IconButton, useTheme } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationDrawer from '../../NotificationDrawer/NotificationDrawer'
import { useNotify } from '../../../context/NotificationProvider/NotificationProvider'
import { useUpdateMassItems } from '../../../api/aws/useUpdateMassItems'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
const NotificationsNav = ({ user }) => {
  const [actionsOpen, setActionsOpen] = useState(false)
  const [notifyDrawerOpen, setNotifyDrawerOpen] = useState(false)
  const { mutate: batchUpdateIsRead } = useUpdateMassItems(['gro_notify'])
  const [isUpdating, setIsUpdating] = useState(false)
  const { showSnackbar } = useSnackbarContext()
  const [opacity, setOpacity] = useState(1)
  const { notifications, isNotificationsLoading, unreadCount, markAsRead } = useNotify()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const handleOpenNotifications = event => {
    setAnchorEl(event.currentTarget) // Set the icon button as the anchor
    setNotifyDrawerOpen(prev => !prev) // Toggle popover open/close state
  }
  const updateNotificationsAsRead = async unreadIds => {
    if (!unreadIds.length) return

    const updates = unreadIds.map(notify_uuid => ({
      tableName: 'gro_notify',
      itemId: notify_uuid,
      primaryKey: 'notify_uuid',
      fieldName: 'isRead',
      newValue: true,
    }))

    // Immediately update the local state
    unreadIds.forEach(id => {
      markAsRead(id) // This will update the unreadCount and mark the notifications as read locally
    })

    batchUpdateIsRead(
      { updates },
      {
        onMutate: () => setIsUpdating(true),
        onSuccess: () => {
          setIsUpdating(false)
          // showSnackbar('Notifications updated successfully', 'success')
          setOpacity(1)
        },
        onError: () => {
          setIsUpdating(false)
          showSnackbar('Notifications update failed', 'error')
        },
      }
    )
  }
  const closeDrawer = unreadIds => {
    setNotifyDrawerOpen(false)
    setActionsOpen(false)
    setOpacity(1)
    setAnchorEl(null)

    if (unreadIds.length > 0) {
      updateNotificationsAsRead(unreadIds) // Update notifications as read when closing the drawer
    }
  }
  return (
    <Box>
      {unreadCount > 0 ? ( // Only render the Badge if unreadCount is greater than 0
        <Badge
          badgeContent={unreadCount}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiBadge-badge': {
              top: 18, // Lower this value to move it down/up as needed
              right: 12, // Lower this value to move it left/right as needed
              fontSize: 15,
              fontWeight: 'bold',
              // bgcolor: theme.palette.text.error,
              bgcolor: 'rgb(60, 207, 169)',
              color: 'rgba(0,0,0,1)',
            },
          }}
        >
          <IconButton
            onClick={handleOpenNotifications}
            sx={{
              minWidth: 50, // or 60, adjust to taste
              minHeight: 60,
              borderRadius: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NotificationsIcon sx={{ height: 22, width: 22 }} />
          </IconButton>
        </Badge>
      ) : (
        <Badge>
          <IconButton
            onClick={handleOpenNotifications}
            sx={{
              minWidth: 50, // or 60, adjust to taste
              minHeight: 60,
              borderRadius: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NotificationsNoneIcon
              sx={{
                height: 22,
                width: 22,
              }}
            />
          </IconButton>
        </Badge>
      )}
      {open && (
        <NotificationDrawer
          //  drawerOpen={notifyDrawerOpen}
          userId={user?.users_uuid}
          drawerOpen={open}
          closeDrawer={closeDrawer}
          anchorEl={anchorEl}
        />
      )}
    </Box>
  )
}

export default NotificationsNav
