import React from 'react'
import { Badge, Button, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import ClearIcon from '@mui/icons-material/Clear'

const ResetFiltersButton = ({ filterModel, handleClearFilters, label }) => {
  const theme = useTheme()
  const isFilterActive =
    filterModel &&
    ((filterModel.items && filterModel.items.length > 0) ||
      (filterModel.quickFilterValues && filterModel.quickFilterValues.length > 0))
      ? true
      : false
  return (
    <Tooltip title={isFilterActive ? 'Clear Filters' : ''}>
      <IconButton
        sx={{
          color: theme.palette.text.primary,
          fontWeight: isFilterActive ? 'bold' : '',
          borderRadius: '0px',
        }}
        disabled={
          filterModel &&
          ((filterModel.items && filterModel.items.length > 0) ||
            (filterModel.quickFilterValues && filterModel.quickFilterValues.length > 0))
            ? false
            : true
        }
        onClick={handleClearFilters}
      >
        <Badge
          badgeContent={
            <ClearIcon
              sx={{ fontSize: '18px', color: isFilterActive ? theme.palette.text.error : theme.palette.text.secondary }}
            />
          }
          color=''
        >
          <FilterListIcon
            sx={{
              fontSize: '30px',
              color: isFilterActive ? theme.palette.text.primary : theme.palette.text.secondary, // Active filters color
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default ResetFiltersButton
