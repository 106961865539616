import { Optimization } from '../SEO/types'
export type MtbOptimization = {
  name: string
  type: string
  color: string
  tooltip: string
  description: string
  plan: string
}
export const mtbOptimizations: Optimization[] = [
  {
    name: 'Ad Update',
    key: 'adupdate-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip: 'Improve performance and to ensure quality, compelling copy and combat ad fatigue.',
    description: 'Creative Refresh & copy review to improve relevance and attractiveness.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Campaign Creation',
    key: 'campaigncreation-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip:
      'Target a new offering without disrupting a thriving campaign. Completely rebuild a campaign to improve performance.',
    description: 'Create a new campaign to target a new offering or to completely restructure an old campaign.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Pause Campaign',
    key: 'pausecampaign-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip: 'Client requested, or ineffective campaigns should be paused.',
    description:
      'Client requested or performance based pause to focus spend on better performing campaigns or new client objectives.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Placement Review',
    key: 'placementreview-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip:
      'Ensure that ads are serving on meaningful, relevant placements, and are not serving on suspicious "spammy" sites.',
    description: 'Review "When & Where Ads Showed" in the "Insights and Reports" section of Google Ads.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Audience Update',
    key: 'audienceupdate-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip: 'Improve relevance of the audience ',
    description:
      'Changing which addressable audience we are using with a view to matching the campaign objective e.g. Audience expansion if the frequency is too high to increase reach or audience restriction if the CTR is low to improve relevance.',
    plan: 'Frequency, CPM, CTR, Conversion Rate',
  },
  {
    name: 'Other',
    key: 'other-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Display',
    color: 'rgba(213, 184, 255, 1)',
    tooltip: 'To improve performance. Please describe how this change should impact the campaign.',
    description: 'This label should be used if none of the other labels are applicable. ',
    plan: '',
  },
  {
    name: 'Ad Group',
    key: 'adgroup-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Some entire Ad Groups may be ineffective or out of date. By creating new ad groups or removing ineffective ones, we can improve the effectiveness of ad spend.',
    description: 'Create new ad group. Pause existing ad group. ',
    plan: 'CTR, Conversion Rate, CPA',
  },
  {
    name: 'Ad Update',
    key: 'adupdate-mtb-search',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'Client requested or data driven improvements to ensure quality, compelling copy..',
    description:
      'Create new text ad. Update existing ad. Pause ads. A/B testing ads. Creating responsive search ad. URL in ad updated. "Anything done to an ad" ',
    plan: 'CTR & CPC above all other metrics to measure the impact of this change',
  },
  {
    name: 'Ad Schedule',
    key: 'adschedule-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Ad scheduling helps target users at the most impactful times, and restricts which extensions are served when. It is best to only show a phone number when someone can pick up the phone!.',
    description: 'Adding Ad Schedule. Editing Ad Schedule. Removing Ad Schedule.',
    plan: 'CTR, Conversion Rate, CPA, PTR',
  },
  {
    name: 'Bid Adjustments',
    key: 'bidadjustments-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Spend the budget more effectively e.g. users one mile around the property may be more interested in living at the property than users fifteen miles away. Bid adjustments should be used when considering which users are most likely to convert..',
    description: 'Increase and or Decrease Location, Devices, Ad Schedule bids.',
    plan: 'Conversion Rate, CPA',
  },
  {
    name: 'Campaign Bid Strategy',
    key: 'campaignbidstrategy-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Allow Google to use the data collected from users who have converted to better target more users who are likely to convert, or in some (rare) cases, to dominate Impression Share for a select set of keywords. .',
    description:
      'Changing bid strategy from manual bidding to one of the automated bid strategies: Maximize Clicks, Maximize Conversions, Maximize Conversion Value, Target Impression Share.',
    plan: 'CTR, Conversion Rate, CPA, Impression Share*',
  },
  {
    name: 'Campaign Creation',
    key: 'campaigncreation-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Target a new offering without disrupting a thriving campaign. Completely rebuild a campaign to improve performance..',
    description: 'Create a new campaign to target a new offering or to completely restructure an old campaign.',
    plan: 'Conversion Rate, CPA',
  },
  {
    name: 'Pause Campaign',
    key: 'pausecampaign-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'Client requested, or ineffective campaigns should be paused..',
    description:
      'Client requested or performance based pause to focus spend on better performing campaigns or new client objectives.',
    plan: 'Conversion Rate, CPA',
  },
  {
    name: 'Extensions',
    key: 'extensions-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'Make the ad more appealing, provide more information, take up more real estate on the page..',
    description: 'Added, Removed, Corrected any of the extensions in campaign. ',
    plan: 'CTR, CPC, Conversion Rate',
  },
  {
    name: 'Keywords',
    key: 'keywords-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip:
      'Ensure that the keywords used generate good quality traffic, and to ensure that we are using ALL high intent keywords we can. Please consider match channel and total impact of a keyword. If a keyword has generate 80% of your traffic, but has not generated any leads while others have, the keyword may be eating all of your budget..',
    description: 'Add Keywords. Add Negative Keywords. Change Keyword Match Type. Pause/Remove Keywords.',
    plan: 'CTR, CPC, Conversion Rate, CPA',
  },
  {
    name: 'Location Target Change',
    key: 'locationtargetchange-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'Improve the relevance and/or reach of the audience the ads are showing to..',
    description: 'Adding New Location. Increasing/Decreasing Radius Size',
    plan: 'CPA, Conversion Rate, CTR',
  },
  {
    name: 'Audiences',
    key: 'audiences-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'Improve the relevance and/or reach of the audience the ads are showing to..',
    description:
      'Any changes to audiences or demographics. Household Income is the only demographic that can be changed for housing, credit, and employment campaigns.',
    plan: 'CPA, Conversion Rate, CTR',
  },
  {
    name: 'Other',
    key: 'other-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Search',
    color: 'rgb(119, 255, 173)',
    tooltip: 'To improve performance. Please describe how this change should impact the campaign..',
    description: 'This label should be used if none of the other labels are applicable. ',
    plan: '',
  },
  {
    name: 'Ad Update',
    key: 'adupdate-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Social',
    color: 'rgb(88, 188, 128)',
    tooltip: 'Improve performance and to ensure quality, compelling copy and combat ad fatigue.',
    description: 'Add New Creative. Update Ad Copy. Pause Ads. Change URL. "Anything done to an ad" ',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Ad Set Targeting',
    key: 'adsettargeting-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Social',
    color: 'rgb(88, 188, 128)',
    tooltip: 'Improve the relevance and/or reach of the audience the ads are showing to.',
    description: 'Add/Remove Audience. Increase/Decrease Target Location Radius. Add/Remove Interests. ',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Campaign Creation',
    key: 'campaigncreation-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Social',
    color: 'rgb(88, 188, 128)',
    tooltip:
      'To target a new audience or product with a dedicated budget, or to completely rebuild a campaign for performance reasons.',
    description: 'Create a new campaign to target a new offering or to completely restructure an old campaign.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Pause Campaign',
    key: 'pausecampaign-mtb',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Social',
    color: 'rgb(88, 188, 128)',
    tooltip: 'Client requested, or ineffective campaigns should be paused.',
    description:
      'Client requested or performance based pause to focus spend on better performing campaigns or new client objectives.',
    plan: 'CTR, Conversion Rate, CPM',
  },
  {
    name: 'Other',
    key: 'othe-mtbr',
    module: 'MTB',
    followDate: '',
    type: 'Digital',
    channel: 'Social',
    color: 'rgb(88, 188, 128)',
    tooltip: 'To improve performance. Please describe how this change should impact the campaign.',
    description: 'This label should be used if none of the other labels are applicable. ',
    plan: '',
  },
]
