import React, { useState } from 'react'
import RenderOrgTeam from './RenderOrgTeam'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useQueryClient } from '@tanstack/react-query'
import { Box } from '@mui/material'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'

const TeamMembers = ({ users, organization }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [localOrg, setLocalOrg] = useState(organization) // Maintain optimistic state
  const { mutate: updateItems } = useUpdateItems()
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const { createMessagePayload, addNotification } = useNotify()

  const queryClient = useQueryClient()

  const handleChange = (role, selectedUserIds) => {
    setLocalOrg((prev) => ({
      ...prev,
      [role]: selectedUserIds,
    }))
    setSelectedUserIds(selectedUserIds)
  }
  const handleBlur = (role, selectedUserIds) => {
    // Function to compare arrays for deep equality
    const arraysAreEqual = (arr1, arr2) => {
      if (!arr1 || !arr2) return false
      if (arr1.length !== arr2.length) return false
      return arr1.every((item, index) => arr2[index] === item)
    }

    const currentRoleUsers = organization?.[role] || []
    if (!arraysAreEqual(currentRoleUsers, selectedUserIds)) {
      console.log('Triggering update and notification because values differ')
      handleUpdateOrganization(role, selectedUserIds)
      handleNotify(role, selectedUserIds)
    }
  }

  const handleRemove = (role, selectedUserIds) => {
    setLocalOrg((prev) => ({
      ...prev,
      [role]: selectedUserIds,
    }))
    handleUpdateOrganization(role, selectedUserIds)
  }
  const handleUpdateOrganization = (role, selectedUserIds) => {
    const previousRoleState = localOrg[role] // Save the current state for rollback

    setIsUpdating(true)

    const updates = [
      {
        primaryKey: 'org_uuid',
        tableName: 'organizations',
        itemId: organization.org_uuid,
        fieldName: role,
        newValue: selectedUserIds,
      },
    ]

    updateItems(
      { updates },
      {
        onSuccess: () => {
          console.log('Update successful')
          queryClient.invalidateQueries(['organization', organization.org_uuid]) // Refresh server state
        },
        onError: (err) => {
          console.error('Update failed', err)
          // Rollback to previous state on error
          setLocalOrg((prev) => ({
            ...prev,
            [role]: previousRoleState,
          }))
        },
        onSettled: () => {
          setIsUpdating(false)
        },
      }
    )
  }
  const handleNotify = async (role, userIds) => {
    /*
        userId: id,
        type: type,
        severity: severity,
        title: title,
        message: message,
        board: board || '',
        boardId: itemId,
        url: link || '',
    */

    const payload = await createMessagePayload(
      userIds,
      'team',
      'success',
      'Added Notification',
      `Added to team as ${role} | ${organization?.name}`,
      'organizations',
      organization?.name,
      organization?.org_uuid,
      organization?.photoUrl,
      `organizations/${organization?.org_uuid}/details`
    )
    await addNotification(payload)
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <RenderOrgTeam
        key={localOrg ? localOrg.org_uuid : []}
        users={users}
        organization={localOrg} // Use the local state
        onRemove={handleRemove}
        onChange={handleChange}
        onBlur={handleBlur}
        isUpdating={isUpdating}
      />
    </Box>
  )
}

export default TeamMembers
