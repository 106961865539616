import React, { useState, useEffect } from 'react'
import NetIncomeCalculator from './NetIncomeCalculator'
import {
  Box,
  InputLabel,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { tableCellStyles } from '../DealApproval/style'
// Monthly expenses (negative values)
const monthlyCost = [
  { label: 'Mr. Cooper Loans', value: -1517.87, date: '06-10-2024', account: 'Personal' },
  { label: 'Huntington Bank', value: -491.71, date: '06-11-2024', account: 'Personal' },
  { label: 'Hulu', value: -17.99, date: '06-11-2024', account: 'Personal' },
  { label: 'Lincoln Rx', value: -75.0, date: '06-13-2024', account: 'Personal' },
  { label: '5/3 Mortgage Loan', value: -409.25, date: '06-20-2024', account: 'Personal' },
  { label: 'Google GSuite', value: -7.2, date: '06-03-2024', account: 'Business' },
  { label: 'Google YouTube Music', value: -10.99, date: '06-03-2024', account: 'Business' },
  { label: 'Consumers', value: -45.15, date: '06-03-2024', account: 'Business' },
  { label: 'OpenAI', value: -20.0, date: '06-04-2024', account: 'Business' },
  { label: 'Fitness Your Way', value: -29.0, date: '06-04-2024', account: 'Business' },
  { label: 'Comcast', value: -95.95, date: '06-04-2024', account: 'Business' },
  { label: 'T Mobile', value: -131.91, date: '06-04-2024', account: 'Business' },
  { label: 'DTE', value: -84.17, date: '06-05-2024', account: 'Business' },
  { label: '5/3 Commercial Loan', value: -481.94, date: '06-06-2024', account: 'Business' },
  { label: 'Twitch TV', value: -4.99, date: '06-10-2024', account: 'Business' },
  { label: 'Apple TV', value: -9.99, date: '06-12-2024', account: 'Business' },
  { label: 'Peacock', value: -5.99, date: '06-13-2024', account: 'Business' },
  { label: 'Squarespace', value: -6.0, date: '06-17-2024', account: 'Business' },
  { label: 'Netflix', value: -22.99, date: '06-17-2024', account: 'Business' },
  { label: 'Progressive', value: -297.99, date: '06-25-2024', account: 'Business' },
  { label: 'Disney Plus', value: -13.99, date: '06-26-2024', account: 'Business' },
  {
    label: 'Misc. Spending',
    value: -3000,
    notes: 'Gas, BC, groceries, gifts, Lucy expenses, misc.',
    date: '06-26-2024',
    account: 'Business',
  },
  // Chels
  { label: 'Spotify + Amazon Prime + Apple', value: -30, date: '06-26-2024', account: 'Business' },
  // { label: 'Car Insurance', value: -158, date: '06-26-2024', account: 'Business' },
  { label: 'Equinox Lease', value: -459, date: '06-26-2024', account: 'Business' },
  { label: 'Chase CC', value: -40 },
  { label: 'Personal Loan', value: -302 },
  { label: 'Student Loan 1', value: -89, notes: 'FORBEARANCE UNTIL 11/25/24; INCREASE AMOUNT ONCE OTHER DEBTS PAID' },
  { label: 'Student Loan 2', value: -114 },
  { label: 'Student Loan 3', value: -143 },
  { label: 'TRANSFER TO SAVINGS', value: -125 },
  { label: 'INVESTMENT RETIREMENT', value: -125, notes: '$125? - TBC' },
  { label: 'LIFE INSURANCE - TBC', value: -10, notes: '$10?' },
  { label: 'LIFE INSURANCE - TBC', value: 0, notes: '$10?' },
]
const calculateDebt = mortgageCost => {
  const monthlyCostNoDebt = [
    { label: 'Mr. Cooper Loans', value: -mortgageCost, date: '06-10-2024', account: 'Personal' },
    { label: 'Huntington Bank', value: -491.71, date: '06-11-2024', account: 'Personal' },
    { label: 'Hulu', value: -17.99, date: '06-11-2024', account: 'Personal' },
    { label: 'Lincoln Rx', value: -75.0, date: '06-13-2024', account: 'Personal' },
    { label: 'Google GSuite', value: -7.2, date: '06-03-2024', account: 'Business' },
    { label: 'Google YouTube Music', value: -10.99, date: '06-03-2024', account: 'Business' },
    { label: 'Consumers', value: -45.15, date: '06-03-2024', account: 'Business' },
    { label: 'OpenAI', value: -20.0, date: '06-04-2024', account: 'Business' },
    { label: 'Fitness Your Way', value: -29.0, date: '06-04-2024', account: 'Business' },
    { label: 'Comcast', value: -95.95, date: '06-04-2024', account: 'Business' },
    { label: 'T Mobile', value: -131.91, date: '06-04-2024', account: 'Business' },
    { label: 'DTE', value: -84.17, date: '06-05-2024', account: 'Business' },
    { label: 'Twitch TV', value: -4.99, date: '06-10-2024', account: 'Business' },
    { label: 'Apple TV', value: -9.99, date: '06-12-2024', account: 'Business' },
    { label: 'Peacock', value: -5.99, date: '06-13-2024', account: 'Business' },
    { label: 'Squarespace', value: -6.0, date: '06-17-2024', account: 'Business' },
    { label: 'Netflix', value: -22.99, date: '06-17-2024', account: 'Business' },
    { label: 'Progressive', value: -297.99, date: '06-25-2024', account: 'Business' },
    { label: 'Disney Plus', value: -13.99, date: '06-26-2024', account: 'Business' },
    //Both
    {
      label: 'Misc. Spending',
      value: -3000,
      notes: 'Gas, BC, groceries, gifts, Lucy expenses, misc.',
      date: '06-26-2024',
      account: 'Business',
    },
    // Chels
    { label: 'Spotify + Amazon Prime + Apple', value: -30, date: '06-26-2024', account: 'Business' },
    // { label: 'Car Insurance', value: -158, date: '06-26-2024', account: 'Business' },
    { label: 'Equinox Lease', value: -459, date: '06-26-2024', account: 'Business' },

    { label: 'Barre', value: -169, notes: '$10?', date: '06-26-2024', account: 'Business' },
    { label: 'TRANSFER TO SAVINGS(Colin)', value: -200 },
    { label: 'TRANSFER TO SAVINGS', value: -200 },
    { label: 'INVESTMENT RETIREMENT', value: -125, notes: '$125? - TBC' },
    { label: 'Daycare (Estimate)', value: -900, notes: '$125? - TBC' },

    { label: 'Mortgage/Utilities', value: -600 },
  ]
  return monthlyCostNoDebt
}

const monthlyCostChels = [
  { label: 'Misc. Spending', value: -1400, notes: 'Gas, BC, groceries, gifts, Lucy expenses, misc.' },
  { label: 'Gym Membership', value: -39 },
  { label: 'Car Insurance', value: -158 },
  { label: 'Equinox Lease', value: -459 },
  { label: 'Mortgage/Utilities', value: -600 },
  { label: 'Health Insurance (Medical, Dental, Vision)', value: -286 },
  { label: 'Home Equity Loan', value: -200 },
  { label: 'Spotify + Amazon Prime', value: -27 },
  { label: 'Chase CC', value: -40 },
  { label: 'Personal Loan', value: -302 },
  { label: 'Student Loan 1', value: -89, notes: 'FORBEARANCE UNTIL 11/25/24; INCREASE AMOUNT ONCE OTHER DEBTS PAID' },
  { label: 'Student Loan 2', value: -114 },
  { label: 'Student Loan 3', value: -143 },
  { label: 'TRANSFER TO SAVINGS', value: -125 },
  { label: 'INVESTMENT RETIREMENT', value: -125, notes: '$125? - TBC' },
  { label: 'LIFE INSURANCE - TBC', value: -10, notes: '$10?' },
  { label: 'LIFE INSURANCE - TBC', value: 0, notes: '$10?' },
]
// const calculatePMI = () => {
//   // Assume a 0.7% annual PMI rate (adjust as needed)
//   const PMI_RATE = 0.007

//   // Calculate the percentage of the down payment relative to the total home price
//   const totalHomePrice = 550000
//   const downPaymentPercentage = downPayment / totalHomePrice

//   // Only apply PMI if down payment is less than 20%
//   const monthlyPMI = downPaymentPercentage < 0.2 ? (homeValue * PMI_RATE) / 12 : 0
//   return monthlyPMI
// }
const Budget = () => {
  // TAX_RATE remains available if needed
  const TAX_RATE = 0.22

  // New state variables for down payment and total home value (purchase price)
  const [downPayment, setDownPayment] = useState(50000)
  const [totalHomePrice, setTotalHomePrice] = useState(550000)

  // Mortgage rate remains fixed (or can also be turned into an input)
  const mortgageRate = 0.071

  // Calculate the financed home amount based on inputs
  const homeValue = totalHomePrice - downPayment

  // Mortgage calculation
  const totalMortgage = homeValue * mortgageRate
  const monthlyMortgage = totalMortgage / 12

  // PMI calculation: assume a 0.7% annual rate if down payment is less than 20% of total price
  const PMI_RATE = 0.007
  const downPaymentPercentage = downPayment / totalHomePrice
  const monthlyPMI = downPaymentPercentage < 0.2 ? (homeValue * PMI_RATE) / 12 : 0

  // Combined monthly payment including PMI (if applicable)
  const totalMortgagePayment = monthlyMortgage + monthlyPMI
  console.log('Monthly Mortgage Payment (incl. PMI): ', totalMortgagePayment)

  // Income-related state values and expenses (unchanged from your original code)
  const [yearlySalary, setYearlySalary] = useState('')
  const [netMonthlyIncome, setNetMonthlyIncome] = useState(null)
  const [netBiWeeklyIncome, setNetBiWeeklyIncome] = useState(null)
  const [startingIncome, setStartingIncome] = useState(0)
  const [startingIncomeBiWeekly, setStartingIncomeBiWeekly] = useState(0)
  const [startingIncome85, setStartingIncome85] = useState(4937.72)
  const [startingIncome100, setStartingIncome100] = useState(5395.83)
  const [startingIncome125, setStartingIncome125] = useState(6854.17)
  const [remainingIncome, setRemainingIncome] = useState(startingIncome)
  const [remainingIncome85, setRemainingIncome85] = useState(startingIncome85)
  const [remainingIncome100, setRemainingIncome100] = useState(startingIncome100)
  const [remainingIncome125, setRemainingIncome125] = useState(startingIncome125)
  const [remainingIncomeBiWeekly, setRemainingIncomeBiWeekly] = useState(0)
  const monthlyCost = calculateDebt(totalMortgagePayment)
  const totalMonthlyCost = monthlyCost.reduce((acc, cost) => acc + cost.value, 0)

  // Update starting income when netMonthlyIncome changes
  useEffect(() => {
    if (netMonthlyIncome !== null) {
      setStartingIncome(parseFloat(netMonthlyIncome))
    }
  }, [netMonthlyIncome])

  // Update starting bi-weekly income when netBiWeeklyIncome changes
  useEffect(() => {
    if (netBiWeeklyIncome !== null) {
      setStartingIncomeBiWeekly(parseFloat(netBiWeeklyIncome) * 2)
    }
  }, [netBiWeeklyIncome])

  // Calculate remaining income using the net income directly without additional tax subtraction.
  useEffect(() => {
    const remaining = monthlyCost.reduce((acc, cost) => acc + cost.value, startingIncome)
    setRemainingIncome(remaining)
  }, [startingIncome, monthlyCost])

  useEffect(() => {
    const remaining = monthlyCost.reduce((acc, cost) => acc + cost.value, startingIncomeBiWeekly)
    setRemainingIncomeBiWeekly(remaining)
  }, [startingIncomeBiWeekly, monthlyCost])

  // Calculate remaining income for 85k, 100k, and 125k scenarios
  useEffect(() => {
    const remaining = monthlyCost.reduce((acc, cost) => acc + cost.value, startingIncome85)
    setRemainingIncome85(remaining)
  }, [startingIncome85, monthlyCost])

  useEffect(() => {
    const remaining = monthlyCost.reduce((acc, cost) => acc + cost.value, startingIncome100)
    setRemainingIncome100(remaining)
  }, [startingIncome100, monthlyCost])

  useEffect(() => {
    const remaining = monthlyCost.reduce((acc, cost) => acc + cost.value, startingIncome125)
    setRemainingIncome125(remaining)
  }, [startingIncome125, monthlyCost])

  // Handlers for income inputs
  const handleIncomeChange = e => {
    const input = e.target.value
    const income = input === '' ? '' : parseFloat(input)
    setStartingIncome(income)
  }
  const handleBiWeeklyIncomeChange = e => {
    const input = e.target.value
    const income = input === '' ? '' : parseFloat(input)
    setStartingIncomeBiWeekly(income)
  }

  // Handlers for the new down payment and total home value inputs
  const handleDownPaymentChange = e => {
    const input = e.target.value
    const value = input === '' ? 0 : parseFloat(input)
    setDownPayment(value)
  }

  const handleHomePriceChange = e => {
    const input = e.target.value
    const value = input === '' ? 0 : parseFloat(input)
    setTotalHomePrice(value)
  }

  return (
    <Box sx={{ maxWidth: 1800, p: 2, mt: 10, height: 'calc(100vh - 90px)', maxHeight: '100vh', overflowY: 'auto' }}>
      <Box>
        <NetIncomeCalculator
          setNetMonthlyIncome={setNetMonthlyIncome}
          setNetBiWeeklyIncome={setNetBiWeeklyIncome}
          yearlySalary={yearlySalary}
          setYearlySalary={setYearlySalary}
          taxRate={TAX_RATE}
        />
      </Box>

      <Typography variant='h2'>Budget Analysis</Typography>

      {/* Inputs for down payment and total home value */}
      <Box sx={{ my: 2 }}>
        <InputLabel>Down Payment: </InputLabel>
        <TextField
          type='number'
          value={downPayment}
          onChange={handleDownPaymentChange}
          placeholder='Enter down payment'
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel>Total Home Value: </InputLabel>
        <TextField
          type='number'
          value={totalHomePrice}
          onChange={handleHomePriceChange}
          placeholder='Enter total home value'
        />
      </Box>

      {/* Income inputs */}
      <Box sx={{ my: 2 }}>
        <InputLabel>Starting Income (Monthly, Net): </InputLabel>
        <TextField type='number' value={startingIncome === '' ? '' : startingIncome} onChange={handleIncomeChange} />
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel>Starting Income (Bi-Weekly, Net): </InputLabel>
        <TextField
          type='number'
          value={startingIncomeBiWeekly === '' ? '' : startingIncomeBiWeekly}
          onChange={handleBiWeeklyIncomeChange}
        />
      </Box>

      <Box>
        <Table sx={{ borderCollapse: 'separate', maxWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{ border: '1px solid rgb(103, 107, 115)' }}>
                <Typography>Remaining Income After Expenses</Typography>
                <Typography>Monthly Cost Total:</Typography>
                <Typography sx={{ color: 'crimson' }}>${totalMonthlyCost.toFixed(2)}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={tableCellStyles}>Estimated Taxes ({TAX_RATE * 100}%)</TableCell>
              <TableCell sx={tableCellStyles}>{yearlySalary ? (yearlySalary * TAX_RATE).toFixed(2) : '0.00'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableCellStyles}>Custom Salary (Monthly, Net)</TableCell>
              <TableCell sx={tableCellStyles}>
                {typeof remainingIncome === 'number' ? remainingIncome.toFixed(2) : '0.00'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableCellStyles}>Custom Salary (Bi-Weekly, Net)</TableCell>
              <TableCell sx={tableCellStyles}>
                {typeof remainingIncomeBiWeekly === 'number' ? remainingIncomeBiWeekly.toFixed(2) : '0.00'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableCellStyles}>85,000 (Net)</TableCell>
              <TableCell sx={tableCellStyles}>
                {typeof remainingIncome85 === 'number' ? remainingIncome85.toFixed(2) : '0.00'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableCellStyles}>100,000 (Net)</TableCell>
              <TableCell sx={tableCellStyles}>
                {typeof remainingIncome100 === 'number' ? remainingIncome100.toFixed(2) : '0.00'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableCellStyles}>125,000 (Net)</TableCell>
              <TableCell sx={tableCellStyles}>
                {typeof remainingIncome125 === 'number' ? remainingIncome125.toFixed(2) : '0.00'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <List>
          {monthlyCost.map((cost, index) => (
            <ListItem key={index}>
              {cost.date}: {cost.label} - ${cost.value.toFixed(2)} {cost.account && `(${cost.account})`}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default Budget

// const Budget = () => {
//   // TAX_RATE no longer needs to be applied to the income in budget,
//   // but can be kept if you want to display tax info based on gross salary.
//   const TAX_RATE = 0.22
//   const downPayment = 50000
//   const homeValue = 550000 - downPayment
//   const mortgageRate = 0.071
//   const totalMortgage = homeValue * mortgageRate
//   const monthlyMortgage = totalMortgage / 12
//   console.log('Monthly Mortgage: ', monthlyMortgage)
//   const [yearlySalary, setYearlySalary] = useState('')

//   const [netMonthlyIncome, setNetMonthlyIncome] = useState(null)
//   const [netBiWeeklyIncome, setNetBiWeeklyIncome] = useState(null)
//   // These values are assumed to be net incomes (after taxes)
//   const [startingIncome, setStartingIncome] = useState(0)
//   const [startingIncomeBiWeekly, setStartingIncomeBiWeekly] = useState(0)
//   const [startingIncome85, setStartingIncome85] = useState(4937.72)
//   const [startingIncome100, setStartingIncome100] = useState(5395.83)
//   const [startingIncome125, setStartingIncome125] = useState(6854.17)

//   // Remaining income now is based on net income (already after-tax) minus expenses.
//   const [remainingIncome, setRemainingIncome] = useState(startingIncome)
//   const [remainingIncome85, setRemainingIncome85] = useState(startingIncome85)
//   const [remainingIncome100, setRemainingIncome100] = useState(startingIncome100)
//   const [remainingIncome125, setRemainingIncome125] = useState(startingIncome125)
//   const [remainingIncomeBiWeekly, setRemainingIncomeBiWeekly] = useState(0)

//   const totalMonthlyCost = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, 0)

//   // Update starting income when netMonthlyIncome changes
//   useEffect(() => {
//     if (netMonthlyIncome !== null) {
//       // startingIncome is already net income (after taxes)
//       setStartingIncome(parseFloat(netMonthlyIncome))
//     }
//   }, [netMonthlyIncome])

//   // Update starting bi-weekly income when netBiWeeklyIncome changes
//   useEffect(() => {
//     if (netBiWeeklyIncome !== null) {
//       // Multiply by 2 to approximate monthly income
//       setStartingIncomeBiWeekly(parseFloat(netBiWeeklyIncome) * 2)
//     }
//   }, [netBiWeeklyIncome])

//   // Calculate remaining income using the net income directly without additional tax subtraction.
//   useEffect(() => {
//     const remaining = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, startingIncome)
//     setRemainingIncome(remaining)
//   }, [startingIncome, monthlyCostNoDebt])

//   // Bi-weekly remaining income calculation (same logic)
//   useEffect(() => {
//     const remaining = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, startingIncomeBiWeekly)
//     setRemainingIncomeBiWeekly(remaining)
//   }, [startingIncomeBiWeekly, monthlyCostNoDebt])

//   // Calculate remaining income for 85k, 100k, and 125k scenarios
//   useEffect(() => {
//     const remaining = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, startingIncome85)
//     setRemainingIncome85(remaining)
//   }, [startingIncome85, monthlyCostNoDebt])

//   useEffect(() => {
//     const remaining = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, startingIncome100)
//     setRemainingIncome100(remaining)
//   }, [startingIncome100, monthlyCostNoDebt])

//   useEffect(() => {
//     const remaining = monthlyCostNoDebt.reduce((acc, cost) => acc + cost.value, startingIncome125)
//     setRemainingIncome125(remaining)
//   }, [startingIncome125, monthlyCostNoDebt])

//   const handleIncomeChange = e => {
//     const input = e.target.value
//     const income = input === '' ? '' : parseFloat(input)
//     setStartingIncome(income)
//   }

//   const handleBiWeeklyIncomeChange = e => {
//     const input = e.target.value
//     const income = input === '' ? '' : parseFloat(input)
//     setStartingIncomeBiWeekly(income)
//   }

//   return (
//     <Box sx={{ maxWidth: 800, p: 2, mt: 10, height: 'calc(100vh - 90px)', maxHeight: '100vh', overflowY: 'auto' }}>
//       <Box>
//         <NetIncomeCalculator
//           setNetMonthlyIncome={setNetMonthlyIncome}
//           setNetBiWeeklyIncome={setNetBiWeeklyIncome}
//           yearlySalary={yearlySalary}
//           setYearlySalary={setYearlySalary}
//           taxRate={TAX_RATE}
//         />
//       </Box>
//       <Typography variant='h2'>Budget Analysis</Typography>
//       <Box>
//         <InputLabel>Starting Income (Monthly, Net): </InputLabel>
//         <TextField type='number' value={startingIncome === '' ? '' : startingIncome} onChange={handleIncomeChange} />
//       </Box>
//       <Box>
//         <InputLabel>Starting Income (Bi-Weekly, Net): </InputLabel>
//         <TextField
//           type='number'
//           value={startingIncomeBiWeekly === '' ? '' : startingIncomeBiWeekly}
//           onChange={handleBiWeeklyIncomeChange}
//         />
//       </Box>
//       <Box>
//         <Table sx={{ borderCollapse: 'separate', maxWidth: 800 }}>
//           <TableHead>
//             <TableRow>
//               <TableCell colSpan={2} sx={{ border: '1px solid rgb(103, 107, 115)' }}>
//                 <Typography>Remaining Income After Expenses</Typography>
//                 <Typography>Monthly Cost Total:</Typography>
//                 <Typography sx={{ color: 'crimson' }}>${totalMonthlyCost.toFixed(2)}</Typography>
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {/* Optionally show estimated taxes based on gross salary */}
//             <TableRow>
//               <TableCell sx={tableCellStyles}>Estimated Taxes ({TAX_RATE * 100}%)</TableCell>
//               <TableCell sx={tableCellStyles}>{yearlySalary ? (yearlySalary * TAX_RATE).toFixed(2) : '0.00'}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={tableCellStyles}>Custom Salary (Monthly, Net)</TableCell>
//               <TableCell sx={tableCellStyles}>
//                 {typeof remainingIncome === 'number' ? remainingIncome.toFixed(2) : '0.00'}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={tableCellStyles}>Custom Salary (Bi-Weekly, Net)</TableCell>
//               <TableCell sx={tableCellStyles}>
//                 {typeof remainingIncomeBiWeekly === 'number' ? remainingIncomeBiWeekly.toFixed(2) : '0.00'}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={tableCellStyles}>85,000 (Net)</TableCell>
//               <TableCell sx={tableCellStyles}>
//                 {typeof remainingIncome85 === 'number' ? remainingIncome85.toFixed(2) : '0.00'}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={tableCellStyles}>100,000 (Net)</TableCell>
//               <TableCell sx={tableCellStyles}>
//                 {typeof remainingIncome100 === 'number' ? remainingIncome100.toFixed(2) : '0.00'}
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell sx={tableCellStyles}>125,000 (Net)</TableCell>
//               <TableCell sx={tableCellStyles}>
//                 {typeof remainingIncome125 === 'number' ? remainingIncome125.toFixed(2) : '0.00'}
//               </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//         <List>
//           {monthlyCostNoDebt.map((cost, index) => (
//             <ListItem key={index}>
//               {cost.date}: {cost.label} - ${cost.value.toFixed(2)} ({cost.account})
//             </ListItem>
//           ))}
//         </List>
//       </Box>
//     </Box>
//   )
// }

// export default Budget
