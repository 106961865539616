import { useQueryClient } from '@tanstack/react-query'
import { getContact } from '../useContact'

export interface Contact {
  linkedinProfile: string
  summary: string
  zip: string
  contacts_uuid: string
  lastName: string
  officePhone: string
  timeCreated: string
  email: string
  name: string
  firstName: string
  photoUrl: string
  state: string
  city: string
  index_name: string
  billingEmail: string
  id: string
  panda_contact_id: string
  phone: string
  middleName: string
  street: string
  title: string
}

export const useContactsHook = () => {
  const queryClient = useQueryClient()

  const handleFetchContact = async (contactId: string): Promise<Contact> => {
    // Use fetchQuery with a single configuration object
    return await queryClient.fetchQuery<Contact>({
      queryKey: ['contact', contactId],
      queryFn: () => getContact('/aws/contacts', 'contacts', contactId),
      staleTime: Infinity, // This is a number, so it's valid.
    })
  }

  return { handleFetchContact }
}
