import React, { useEffect, useState } from 'react'
import { Box, Autocomplete, TextField, Typography, CircularProgress } from '@mui/material'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

const DealSearch = () => {
  const queryClient = useQueryClient()
  const { dealId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const deals = queryClient.getQueryData(['deals', 'dealPage'])
  const [selectedDeal, setSelectedDeal] = useState(null)

  // Once deals are loaded, set the currently selectedDeal from the URL
  useEffect(() => {
    if (deals?.length && dealId) {
      const newSelected = deals.find(d => d?.deal_uuid === dealId)
      setSelectedDeal(newSelected || null)
    }
  }, [dealId, deals])

  const handleChangeDeal = (_, newDeal) => {
    if (!newDeal?.deal_uuid) return
    // 1) Split out the path: e.g. [ '', 'deals', oldDealId, 'reviewdeal' ]
    const segments = location.pathname.split('/')
    // 2) Replace the old deal ID with the new one
    //    segments[0] is '', segments[1] is 'deals', segments[2] is oldDealId
    segments[2] = newDeal.deal_uuid
    // 3) Reassemble the path
    const newPath = segments.join('/')
    navigate(newPath)
  }

  return (
    <Autocomplete
      multiple={false}
      value={selectedDeal || {}}
      id='deals-autocomplete'
      options={deals}
      getOptionLabel={option => option.name || ''}
      onChange={handleChangeDeal}
      // filterOptions={filterOptions}
      // renderOption={renderOption}
      renderOption={(props, option) => {
        const { key, id, ...rest } = props
        return (
          <Box
            key={`${key}-${id}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
            {...rest}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Typography>{option?.name}</Typography>
            </Box>
          </Box>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          // InputProps={{
          //   ...params.InputProps,
          //   endAdornment: (
          //     <>
          //       {isLoading ? <CircularProgress color='inherit' size={18} /> : null}
          //       {params.InputProps.endAdornment}
          //     </>
          //   ),
          // }}
          sx={{
            '& .MuiInputBase-input': {
              color: selectedDeal ? 'lightblue' : 'inherit',
            },
          }}
        />
      )}
      // renderInput={params => <TextField {...params} label={label} />}
      fullWidth
      // loading={isLoading}
      freeSolo
    />
  )
}

export default DealSearch
