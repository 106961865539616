import { Box, Avatar, AvatarGroup, Autocomplete, TextField } from '@mui/material'

export const renderMarketingTeamColumns = (
  roleArray,
  users,
  rowSelectionModel,
  handleUserChange,
  densityStyles,
  handleClickEditStart,
  handleSelectUserRow
) => {
  return (roleArray ?? ['AM', 'PM', 'DMS', 'creativeLead', 'MIA', 'QA', 'SEO']).map(role => {
    return {
      field: role,
      headerName: role,
      flex: 0.2,
      minWidth: 55,
      // editable: rowSelectionModel.length <= 1,
      editable: false,
      isCellEditable: () => rowSelectionModel.length <= 1,
      // For rendering, ensure the value is an array.
      valueGetter: params => {
        const usersArray = Array.isArray(params) ? params : []
        return usersArray.map(user => user.name).join(', ') // Returns names as a string
      },
      renderCell: params => {
        const values = Array.isArray(params?.row?.[role]) ? params?.row?.[role] : []
        return (
          <Box
            key={`nco-${role}`}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexWrap: 'wrap',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            // onClick={() => handleClickEditStart(params)}
            onClick={() => handleSelectUserRow(params?.rowNode?.id, role)}
          >
            <AvatarGroup max={4} spacing={'small'}>
              {values.length > 0 ? (
                values.map(user => (
                  <Avatar key={user.user_uuid || user.id} src={user.image_512} sx={{ height: 36, width: 36 }} />
                ))
              ) : (
                <Avatar sx={{ opacity: 0.1 }} />
              )}
            </AvatarGroup>
          </Box>
        )
      },
      // The edit cell uses Autocomplete in multiple selection mode.
      // renderEditCell: params => {
      //   const handleChange = (_event, newValue) => {
      //     console.log('newValue', newValue)
      //     // Stop cell editing.
      //     params.api.stopCellEditMode({ id: params.id, field: params.field })
      //     // newValue will be an array of user objects.
      //     handleUserChange(params.row.deal_uuid, role, newValue)
      //   }

      //   return (
      //     <Autocomplete
      //       multiple
      //       options={users}
      //       getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`.trim()}
      //       isOptionEqualToValue={(option, value) => option.id === value?.id}
      //       // Always provide an array to the value.
      //       value={Array.isArray(params.value) ? params.value : []}
      //       onChange={handleChange}
      //       renderOption={(props, option) => {
      //         const { key, ...rest } = props
      //         return (
      //           <Box
      //             key={option.user_uuid || option.id}
      //             component='li'
      //             {...rest}
      //             sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
      //           >
      //             <Avatar
      //               src={option.image_512}
      //               sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
      //             />
      //             {option.firstName} {option.lastName}
      //           </Box>
      //         )
      //       }}
      //       renderTags={(tagValue, getTagProps) => (
      //         <Box
      //           sx={{ display: 'flex', alignItems: 'center', padding: densityStyles.padding, gap: densityStyles.gap }}
      //         >
      //           <AvatarGroup max={4} spacing={'small'}>
      //             {tagValue.map((option, index) => {
      //               const { key, onDelete, ...rest } = getTagProps({ index })
      //               return (
      //                 // <Box key={option.user_uuid} {...rest} sx={{ display: 'flex', alignItems: 'center', height: 30 }}>
      //                 <Avatar
      //                   key={option.user_uuid}
      //                   {...rest}
      //                   alt={option.name}
      //                   src={option.image_512}
      //                   sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
      //                 />
      //                 // </Box>
      //               )
      //             })}
      //           </AvatarGroup>
      //         </Box>
      //       )}
      //       renderInput={params => (
      //         <TextField
      //           {...params}
      //           variant='outlined'
      //           autoFocus
      //           InputProps={{
      //             ...params.InputProps,
      //             endAdornment: null, // Remove the default dropdown icon
      //           }}
      //           sx={{ maxHeight: '40px', p: 0 }}
      //         />
      //       )}
      //       fullWidth
      //       sx={{ maxHeight: '45px' }}
      //     />
      //   )
      // },
    }
  })
}
