import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { ncoStatusFilters } from '../DataGrid/filterArrays.js'
import { useTheme } from '@mui/material'
import renderMtbColumns from '../DataGrid/createColumns/renderMtbColumns.js'
import { mtbInitialVisibilityState } from '../DataGrid/createColumns/gridViewStates.ts'
import MonthlyTaskDataGrid from '../DataGrid/MonthlyTaskDataGrid.js'

const initialSortModel = [{ field: 'name', sort: 'asc' }]

const MtbPage = () => {
  const theme = useTheme()
  const { dealId, orgId } = useParams()

  const filters = {
    status: [
      'Active',
      'Active (system)',
      'In Progress',
      'Month To Month',
      '30 Day Notice',
      'Active (system)',
      'Active (SEO)',
      'Active (Creative)',
      'Active (Website)',
      'Active (LTP)',
    ],
  }
  return (
    <>
      <MonthlyTaskDataGrid
        dealId={dealId}
        orgId={orgId}
        filters={filters}
        queryKey={['mtbPage', 'deals']}
        route={'mtb'}
        context={'mtb'}
        initialVisibilityState={mtbInitialVisibilityState}
        initialSortModel={initialSortModel}
        statusKey={'onboardingStatus'}
        statusLabel={'Onboarding Status'}
        filterOptions={ncoStatusFilters}
        boardTheme={theme.palette.icon.mtb}
        renderFunction={renderMtbColumns}
      />
    </>
  )
}
export default MtbPage
