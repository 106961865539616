import GroGoogleIcon from '../../images/icons/Google Ads.png'
import GroGoogleDisplayIcon from '../../images/icons/Google Display.png'
import GroMetaIcon from '../../images/icons/meta.png'
import GroTikTokIcon from '../../images/icons/tik-tok.png'
import GroWebsiteHostingIcon from '../../images/icons/Website Hosting.png'
import GroSeoIcon from '../../images/icons/seo.png'
import GroSeoTier1Icon from '../../images/icons/SEO - Tier 1.png'
import GroSeoTier2Icon from '../../images/icons/SEO - Tier 2.png'
import GroSeoTier3Icon from '../../images/icons/SEO - Tier 3.png'
import GroSeoTierCustomIcon from '../../images/icons/SEO - Custom.png'
import GroSpotifyIcon from '../../images/icons/spotify.png'
import GroMicrosoftIcon from '../../images/icons/bing.png'
import GroYouTubeIcon from '../../images/icons/youtube.png'
import GroCreativeIcon from '../../images/icons/Creative.png'
import GroWebsiteDevelopmentIcon from '../../images/icons/Website Development.png'
import GroVideographyIcon from '../../images/icons/Videography.png'
import GroPhotographyIcon from '../../images/icons/Photography.png'
import GroConsultingIcon from '../../images/icons/Consulting.png'
import MetaLightSvg from '../../images/icons/meta (1).svg'
import GoogleDisplaySvg from '../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../images/icons/Google Ads.png'
import LinkedInSvg from '../../images/icons/linkedin.svg'
import MicrosoftSvg from '../../images/icons/bing.png'
import SimplifiSvg from '../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../images/icons/snapchat.svg'
import SpotifySvg from '../../images/icons/spotify.svg'
import TikTokSvg from '../../images/icons/tik-tok.png'
import YoutubeSvg from '../../images/icons/youtube.svg'
import Seo1Svg from '../../images/icons/seotier1.svg'
import Seo2Svg from '../../images/icons/seotier2.svg'
import Seo3Svg from '../../images/icons/seotier3.svg'
import Seo4Svg from '../../images/icons/seo.png'
import DarkBudgetSvg from '../../images/icons/darkBudget.svg'
import BudgetSvg from '../../images/icons/budget.svg'
import GoogleSearchIcon from '../../images/icons/google-search-icon.svg'
import RedditSvg from '../../images/icons/reddit-logo-2436.svg'

export const renderServiceIcon = (productName: string) => {
  switch (productName) {
    case 'Paid Search Advertising':
      return GoogleSearchSvg
    case 'Google Display Advertising':
      return GoogleDisplaySvg
    case 'Meta Advertising':
      return MetaLightSvg
    case 'YouTube Advertising':
      return GroYouTubeIcon
    case 'TikTok Advertising':
      return GroTikTokIcon
    case 'Microsoft Advertising':
      return MicrosoftSvg
    case 'Website Hosting':
      return GroWebsiteHostingIcon
    case 'Website Hosting + 30 Minutes of Maintenance':
      return GroWebsiteHostingIcon
    case 'Website Hosting + 60 Minutes of Maintenance':
      return GroWebsiteHostingIcon
    case 'SEO - Tier 1':
      return GoogleSearchIcon
    case 'SEO - Tier 2':
      return GoogleSearchIcon
    case 'SEO - Tier 3':
      return GoogleSearchIcon
    case 'SEO - Tier 4':
      return GoogleSearchIcon
    case 'Simplifi Advertising':
      return SimplifiSvg
    case 'SnapChat Advertising':
      return SnapchatSvg
    case 'Spotify Advertising':
      return SpotifySvg
    case 'TikTok Advertising':
      return TikTokSvg
    case 'Linkedin Advertising':
      return LinkedInSvg
    case 'Youtube Advertising':
      return YoutubeSvg
      return GroSeoTierCustomIcon
    case 'Programmatic Display Advertising':
      return SimplifiSvg
    case 'Programmatic Video Advertising':
      return SimplifiSvg
    case 'Programmatic Audio Advertising':
      return SimplifiSvg
    case 'Graphic Design - General':
      return GroCreativeIcon
    case 'Graphic Design - Logo Creation':
      return GroCreativeIcon
    case 'Graphic Design - Additional Logo Revisions':
      return GroCreativeIcon
    case 'Graphic Design - Revisions':
      return GroCreativeIcon
    case 'Video Shoot':
      return GroVideographyIcon
    case 'Video Editing':
      return GroVideographyIcon
    case 'Photo Shoot':
      return GroPhotographyIcon
    case 'Photo Editing':
      return GroPhotographyIcon
    case 'Consulting':
      return GroConsultingIcon
    case 'Paid Search - Media Spend':
      return BudgetSvg
    case 'Projected Budget':
      return DarkBudgetSvg
    case 'Reddit Advertising':
      return RedditSvg
    default:
      return productName
  }
}
