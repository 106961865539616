import { useState } from 'react'
import { useBatchUpdateTasks } from './useBatchUpdateTasks'

// Define the shape of a single update item
export interface UpdateItem {
  primaryKey: string
  sortKey?: string
  tableName: string
  itemId: string
  itemSortKey?: string // NEW: include the sort key value
  fieldName: string
  newValue: string | string[]
}

// This type lets you pass any key/value pair where the value is either a string or array of strings.
export interface DynamicMassUpdateData {
  [field: string]: string | string[] | undefined
}

type SnackbarVariant = 'success' | 'error'

// Replace these with your actual implementations.
const handleClosePopover = (): void => {
  // Your popover close logic here...
}

const showSnackbar = (message: string, variant: SnackbarVariant): void => {
  // Your snackbar display logic here...
}

const useMassUpdateTasks = () => {
  const { mutate: updateMassItems } = useBatchUpdateTasks()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const handleMassUpdate = (massUpdateData: DynamicMassUpdateData, selectionModel: string[]): void => {
    setIsUpdating(true)
    const updates: UpdateItem[] = []

    // Iterate over each field/value pair in massUpdateData
    Object.entries(massUpdateData).forEach(([field, value]) => {
      // Only add an update if value is defined and non-empty
      if (
        value !== undefined &&
        (typeof value === 'string' ? value.trim().length > 0 : Array.isArray(value) && value.length > 0)
      ) {
        selectionModel.forEach(compositeId => {
          // Parse the composite id into PK and SK.
          const [PK, SK] = compositeId.split('::')
          // If the value is an array of one element, take the single value
          // const newValue = Array.isArray(value) && value.length === 1 ? value[0] : value
          const newValue =
            field === 'assignees' && Array.isArray(value)
              ? value
              : Array.isArray(value) && value.length === 1
              ? value[0]
              : value

          updates.push({
            primaryKey: 'PK',
            sortKey: 'SK', // This indicates that your table has a sort key.
            tableName: 'TASKS_TABLE',
            itemId: PK, // Use the parsed partition key.
            itemSortKey: SK, // Use the parsed sort key.
            fieldName: field,
            newValue,
          })
        })
      }
    })

    // Use the same update function regardless of how many rows are selected.
    updateMassItems(updates, {
      onSuccess: () => {
        setIsUpdating(false)
        handleClosePopover()
        showSnackbar(
          selectionModel.length === 1
            ? 'Task updated successfully'
            : `${selectionModel.length} Tasks updated successfully`,
          'success'
        )
      },
      onError: () => {
        setIsUpdating(false)
        showSnackbar(selectionModel.length === 1 ? 'Task update failed' : 'Tasks update failed', 'error')
      },
    })
  }

  return { handleMassUpdate, isUpdating }
}

export default useMassUpdateTasks
