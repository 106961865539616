import type { MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import tippy, { type Instance as TippyInstance } from "tippy.js";
import SuggestionList, { type SuggestionListRef } from "./SuggestionList.tsx";

export type UsersArray = User[];
export type User = {
  id: string;
  firstName: string;
  lastName: string;
  image_512: string;
  role: string;
  // other properties if needed
};

export type MentionSuggestion = {
    id: string;
    name: string;
    imageUrl: string;
  };
  
  const DOM_RECT_FALLBACK: DOMRect = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
    toJSON() {
      return {};
    },
  };

// In createMentionSuggestionOptions
export const createMentionSuggestionOptions = (users: User[]) => ({
  items: async ({ query }): Promise<MentionSuggestion[]> =>
    Promise.resolve(
      users
        .map(user => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          imageUrl: user.image_512, // extra metadata
        }))
        .filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
        .slice(0, 5)
    ),
  render: () => {
    let component: ReactRenderer<SuggestionListRef> | undefined;
    let popup: TippyInstance | undefined;

    return {
      onStart: props => {
        component = new ReactRenderer(SuggestionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })[0];
      },

      onUpdate(props) {
        component?.updateProps(props);
        popup?.setProps({
          getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.hide();
          return true;
        }
        if (!component?.ref) {
          return false;
        }
        return component.ref.onKeyDown(props);
      },

      onExit() {
        popup?.destroy();
        component?.destroy();
        popup = undefined;
        component = undefined;
      },

      // IMPORTANT: in your command callback, pass the imageUrl along
      onSelect({ editor, range, props: suggestionProps }) {
        // suggestionProps is an individual suggestion item from our items list
        const { id, name, imageUrl } = suggestionProps;
        const mentionItem = {
          id,
          label: name,
          imageUrl, // Pass the image URL here
        };

        editor.commands.command(({ tr }) => {
          tr.replaceRangeWith(range.from, range.to, editor.schema.nodes.mention.create(mentionItem));
          return true;
        });
      },
    };
  },
});

// // mentionSuggestionOptions.ts
// import type { MentionOptions } from "@tiptap/extension-mention";
// import { ReactRenderer } from "@tiptap/react";
// import tippy, { type Instance as TippyInstance } from "tippy.js";
// import SuggestionList, { type SuggestionListRef } from "./SuggestionList.tsx";

// export type UsersArray = User[];
// export type User = {
//   id: string;
//   firstName: string;
//   lastName: string;
//   image_512: string;
//   role: string;
//   // other properties if needed
// };

// export type MentionSuggestion = {
//     id: string;
//     name: string;
//     imageUrl: string;
//   };
  
//   const DOM_RECT_FALLBACK: DOMRect = {
//     bottom: 0,
//     height: 0,
//     left: 0,
//     right: 0,
//     top: 0,
//     width: 0,
//     x: 0,
//     y: 0,
//     toJSON() {
//       return {};
//     },
//   };
// export const createMentionSuggestionOptions = (users: User[]) => ({
//   items: async ({ query }): Promise<MentionSuggestion[]> =>
//     Promise.resolve(
//       users
//         .map(user => ({
//           id: user.id,
//           name: `${user.firstName} ${user.lastName}`,
//           imageUrl: user.image_512, 
//         }))
//         .filter(item => item.name.toLowerCase().includes(query.toLowerCase()))
//         .slice(0, 5)
//     ),

//   render: () => {
//     let component: ReactRenderer<SuggestionListRef> | undefined
//     let popup: TippyInstance | undefined

//     return {
//       onStart: props => {
//         component = new ReactRenderer(SuggestionList, {
//           props,
//           editor: props.editor,
//         })

//         popup = tippy('body', {
//           getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
//           appendTo: () => document.body,
//           content: component.element,
//           showOnCreate: true,
//           interactive: true,
//           trigger: 'manual',
//           placement: 'bottom-start',
//         })[0]
//       },

//       onUpdate(props) {
//         component?.updateProps(props)

//         popup?.setProps({
//           getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
//         })
//       },

//       onKeyDown(props) {
//         if (props.event.key === 'Escape') {
//           popup?.hide()
//           return true
//         }

//         if (!component?.ref) {
//           return false
//         }

//         return component.ref.onKeyDown(props)
//       },

//       onExit() {
//         popup?.destroy()
//         component?.destroy()
//         popup = undefined
//         component = undefined
//       },
//     }
//   },
// })
