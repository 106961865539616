import React, { useState, useRef, useEffect } from 'react'
import { Modal, Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Default styles for the modal container
const defaultModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
}

interface PreviewModalProps {
  previewUrl: string
  onClose: () => void
}

const PreviewModal: React.FC<PreviewModalProps> = ({ previewUrl, onClose }) => {
  const contentRef = useRef<HTMLIFrameElement | HTMLImageElement>(null)
  const [dimensions, setDimensions] = useState<{ width: string; height: string }>({
    width: '85vw',
    height: '65vh',
  })
  const minWidth = '50vw'
  const minHeight = '50vh'
  // Check file extension to decide whether it's an image or a document.
  const isImage = /\.(jpg|jpeg|png|gif)$/i.test(previewUrl)
  const isPDF = /\.pdf$/i.test(previewUrl)
  const isDoc = /\.(doc|docx)$/i.test(previewUrl)

  // For images, update modal size based on natural dimensions.
  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget
    console.log('naturalWidth', naturalWidth, 'naturalHeight', naturalHeight)
    // You can adjust these values or add constraints here.
    setDimensions({
      width: Math.min(naturalWidth, minWidth),
      height: Math.min(naturalHeight, minHeight),
    })
  }

  // For PDFs or DOCs, you might choose a default size or use a viewer.
  // For example, using Google Docs Viewer for DOCX:
  const viewerUrl = isPDF
    ? previewUrl
    : isDoc
    ? `https://docs.google.com/gview?url=${encodeURIComponent(previewUrl)}&embedded=true`
    : previewUrl

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          ...defaultModalStyle,
          width: dimensions.width,
          height: dimensions.height,
          minHeight: isPDF ? '75vh' : '',
          minWidth: '30vw',
          maxHeight: '85vh',
        }}
      >
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: 'grey.500' }}>
          <CloseIcon />
        </IconButton>
        {isImage ? (
          <img
            src={previewUrl}
            alt='Preview'
            ref={contentRef as React.RefObject<HTMLImageElement>}
            onLoad={handleImageLoad}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        ) : (
          <iframe
            src={previewUrl}
            title='Preview'
            ref={contentRef as React.RefObject<HTMLIFrameElement>}
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        )}
      </Box>
    </Modal>
  )
}

export default PreviewModal
