import { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../../queryMutations/mutationFunctions'
import axios from 'axios'

export const getTaskById = async (pk: string, sk: string) => {
  try {
    const url = `${baseUrl}/aws/tasks/${encodeURIComponent(pk)}/${encodeURIComponent(sk)}` // ✅ Encode special characters

    const response = await axios.get(url, {
      params: { table: 'TASKS_TABLE' }, // ✅ Pass only table param, PK/SK are in URL
    })

    if (response.status === 204) {
      return { data: {}, status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching task:', error)
    return []
  }
}

export const useTask = (pk: string, sk: string) => {
  const { data: task, isLoading: isTaskLoading, isError: isTaskError } = useQuery({
    queryKey: ['task', pk, sk], // ✅ Ensure query key includes both PK and SK
    queryFn: () => getTaskById(pk!, sk!), // ✅ Ensure both PK and SK are passed
    enabled: !!pk && !!sk, // ✅ Only fetch if PK & SK exist
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
  })

  return {
    task,
    isTaskLoading,
    isTaskError,
  }
}
