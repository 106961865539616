import { Select, MenuItem, Chip, Typography, Box } from '@mui/material'
import { taskMasterStatusOptions } from '../../DataGrid/filterArrays'
import { StyledSelect } from '../../../style/styleElements'

// Adjust imports and other parts of the component as necessary...

const SelectMasterStatus = ({ selectedMasterStatus, setSelectedMasterStatus }) => {
  const handleMasterStatusChange = event => {
    const newValue = event.target.value
    // Find the full task type object based on the selected value
    const selectedMasterStatusObject = taskMasterStatusOptions.find(type => type.value === newValue)
    setSelectedMasterStatus(selectedMasterStatusObject) // Store the full task type object
  }

  return (
    <StyledSelect
      value={selectedMasterStatus ? selectedMasterStatus.value : ''}
      onChange={handleMasterStatusChange}
      displayEmpty
      fullWidth
      renderValue={selectedValue => (
        <Box display='flex' alignItems='center' gap={1}>
          {selectedMasterStatus && (
            <Chip
              size='small'
              style={{
                backgroundColor: selectedMasterStatus.color,
                width: 14,
                height: 14,
                borderRadius: '50%',
              }}
            />
          )}
          <Typography variant='subtitle1'>
            {selectedMasterStatus ? selectedMasterStatus.label : 'Select Master Status'}
          </Typography>
        </Box>
      )}
    >
      {taskMasterStatusOptions.map(type => (
        <MenuItem key={type.value} value={type.value}>
          <Chip
            size='small'
            style={{
              backgroundColor: type.color,
              width: 14,
              height: 14,
              borderRadius: '50%',
              margin: '5px',
            }}
          />
          <Typography variant='subtitle1'>{type.label}</Typography>
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default SelectMasterStatus
