import React from 'react'
import { Badge, IconButton, Tooltip, useTheme } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'
import ClearIcon from '@mui/icons-material/Clear'

const ResetSortingButton = ({ isSortModelChanged, onClearSorting }) => {
  const theme = useTheme()
  return (
    <Tooltip title={isSortModelChanged ? 'Clear Sorting' : ''}>
      <IconButton
        sx={{
          height: 40,
          color: theme.palette.text.primary, // Active sorting color
          fontWeight: isSortModelChanged ? 'bold' : '',
          borderRadius: '0px',
        }}
        disabled={isSortModelChanged ? false : true}
        onClick={onClearSorting}
      >
        <Badge
          badgeContent={
            <ClearIcon
              sx={{
                fontSize: '18px',
                color: isSortModelChanged ? theme.palette.text.error : theme.palette.text.secondary,
              }}
            />
          }
          color=''
        >
          <SortIcon
            sx={{
              fontSize: '30px',
              color: isSortModelChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Active filters color
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
export default ResetSortingButton
