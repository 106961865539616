import React, { useState, useEffect, useRef } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Switch, FormControlLabel } from '@mui/material'
import HorizontalNavSections from './HorizontalNavSections'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import BoardNavButton from './BoardsNavigation/BoardNavButton.tsx'

const NAV_HEIGHT = 50

// Adjust the transform logic based on pinned
const FloatingNavBarContainer = styled('nav')(({ theme, pinned, visible }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 65, // Ensures it stops short of BoardOptionsNavContainer
  height: `${NAV_HEIGHT}px`, // MATCHES BoardOptionsNavContainer height
  bgcolor: theme.palette.background.section,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[5],
  borderBottom: `1px solid ${theme.palette.divider}`, // Matches BoardOptionsNavContainer
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 0.3s ease',
  opacity: 1,
  transform: pinned ? 'translateY(0)' : visible ? 'translateY(0)' : 'translateY(-100%)',
  zIndex: 1300,
}))
const BoardOptionsNavContainer = styled('nav')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0, // Ensures it stays in the top-right corner
  height: `${NAV_HEIGHT + 5}px`, // MATCHES FloatingNavBarContainer height
  width: '60px', // Small width for just the icon
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.section,
  color: theme.palette.text.primary,
  // boxShadow: theme.shadows[5], // Matches FloatingNavBarContainer
  boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
  zIndex: 1400,
}))

const FloatingNavBar = props => {
  const {
    handleOpenCreateOrganization,
    handleOpenCreateDeal,
    handleOpenCreateContact,
    onOpenCreateTask,
    onCloseCreateTask,
    onOpenCreativeIntake,
    onCloseCreativeIntake,
    onOpenMarketingForm,
    onCloseMarketingForm,
    isConnectedUsersOpen,
    handleOpenConnectedUsers,
    handleCloseConnectedUsers,
    isNotifyDrawerOpen,
    onOpenNotify,
    onCloseNotify,
    selectedPath,
    handleNavigation,
    user,
    devSectionIds,
    imageUrl,
    // New pinned props
    pinned,
    onTogglePinned,
  } = props

  // We keep old "hover to show" logic in place, but only if pinned = false
  const [visible, setVisible] = useState(false)
  const hideTimeoutRef = useRef(null)
  const { setNavOpenState } = useLayoutContext()
  const handleMouseEnter = () => {
    if (!pinned) {
      clearTimeout(hideTimeoutRef.current)
      setVisible(true)
    }
  }

  const handleMouseLeave = () => {
    if (!pinned) {
      hideTimeoutRef.current = setTimeout(() => {
        setVisible(false)
      }, 500)
    }
  }

  // Show nav if user’s cursor is near the bottom
  useEffect(() => {
    if (pinned) {
      // If pinned, always visible. Just set it once.
      setVisible(true)
      return
    }

    const handleMouseMove = e => {
      const threshold = 20
      if (e.clientY <= threshold) {
        setVisible(true)
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      clearTimeout(hideTimeoutRef.current)
    }
  }, [pinned])
  useEffect(() => {
    // Now we set navOpen to the same as `visible`,
    // instead of toggling it
    setNavOpenState(visible)
  }, [visible, setNavOpenState])
  return (
    <>
      <FloatingNavBarContainer
        pinned={pinned}
        visible={visible}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Left side nav sections */}
        <HorizontalNavSections
          handleOpenCreateOrganization={handleOpenCreateOrganization}
          handleOpenCreateDeal={handleOpenCreateDeal}
          handleOpenCreateContact={handleOpenCreateContact}
          onOpenCreateTask={onOpenCreateTask}
          onCloseCreateTask={onCloseCreateTask}
          onOpenCreativeIntake={onOpenCreativeIntake}
          onCloseCreativeIntake={onCloseCreativeIntake}
          onOpenMarketingForm={onOpenMarketingForm}
          onCloseMarketingForm={onCloseMarketingForm}
          isConnectedUsersOpen={isConnectedUsersOpen}
          handleOpenConnectedUsers={handleOpenConnectedUsers}
          handleCloseConnectedUsers={handleCloseConnectedUsers}
          isNotifyDrawerOpen={isNotifyDrawerOpen}
          onOpenNotify={onOpenNotify}
          onCloseNotify={onCloseNotify}
          selectedPath={selectedPath}
          handleNavigation={handleNavigation}
          user={user}
          devSectionIds={devSectionIds}
          imageUrl={imageUrl}
          open // if you need
          checked={pinned}
          onChecked={onTogglePinned}
        />
        {/* Right side: "Keep open" switch or checkbox */}
        {/* <Box>
        <FormControlLabel
        label='Keep Open'
        control={<Switch checked={pinned} onChange={onTogglePinned} />}
        sx={{ mr: 2 }}
        />
        </Box> */}
      </FloatingNavBarContainer>
      <BoardOptionsNavContainer>
        <BoardNavButton />
      </BoardOptionsNavContainer>
    </>
  )
}

export default FloatingNavBar
