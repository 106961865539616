import { Route, Routes } from 'react-router-dom'
import AppLayout from './AppLayout'
import { SnackbarProvider } from './context/SnackbarProvider/SnackbarProvider'
import OrganizationsPage from './components/Organizations/OrganizationsPage'
import OrganizationPage from './components/Organizations/OrganizationPage'
import Leads from './components/Leads/Leads'
import Documents from './components/PandaDoc/Documents'
import DocumentDetails from './components/PandaDoc/DocumentDetails'
import DealsPage from './components/Deals/DealsPage'
import ContactDetails from './components/Contacts/ContactDetails'
import SlackUserList from './components/SlackUserList/SlackUserList'
import { HeaderProvider } from './context/HeaderProvider/HeaderProvider'
import DashboardPage from './components/Dashboard/DashboardPage'
import DealsDetails from './components/Deals/DealsDetails'
import OrganizationDetails from './components/Organizations/OrganizationDetails'
import OrgDealInfo from './components/Organizations/OrgDealInfo'
import OrgBillingInfo from './components/Organizations/OrgBillingInfo'
import OrgTaskInfo from './components/Organizations/OrgTaskInfo'
import OrganizationNotes from './components/Organizations/OrganizationNotes'
import DealsKanban from './components/DealsKanban/DealsKanban'
import DealTaskInfo from './components/Deals/DealTaskInfo'
import DealServicesInfo from './components/Deals/DealServicesInfo'
import DealPage from './components/Deals/DealPage'
import ContactsPage from './components/Contacts/ContactsPage'
import { ValidationProvider } from './context/ValidationProvider/ValidationProvider'
import PaymentSchedule from './components/PaymentSchedule/PaymentSchedule'
import { LeadsProvider } from './context/LeadsProvider/LeadsProvider.js'
import Strategy from './components/Strategy/Strategy.js'
import ReviewDeal from './components/DealApproval/ReviewDeal.js'
import ContactPage from './components/Contacts/ContactPage.js'
import AccountingSchedule from './components/PaymentSchedule/AccountingSchedule.js'
import ApprovalsPage from './components/Approvals/ApprovalsPage.js'
import SprintsPage from './components/Sprints/SprintsPage.js'
import NCOPage from './components/NCO/NCOPage.js'
import TimelinePage from './components/Timeline/TimelinePage.js'
import RenderCampaign from './components/StrategyCampaigns/RenderCampaign.js'
import AccountingPage from './components/Accounting/AccountingPage.js'
import IntegrationNotes from './components/Integration/IntegrationNotes.js'
import MentionsPage from './components/Mentions/MentionsPage.js'
import TeamTasksPage from './components/TeamTasks/TeamTasksPage.tsx'
import TaskProvider from './context/TaskProvider/TaskProvider.js'
import NewContract from './components/Deals/NewContract.js'
import BreakpointMarker from './components/ui/matter-ui-breakpoint-marker.tsx'
import GooglePickerAPI from './components/Google/GooglePickerAPI.tsx'
import Boards from './components/Boards/Boards.js'
import CreativePage from './components/Creative/CreativePage.tsx'
import WebsitePage from './components/Website/WebsitePage.js'
import BoardsDrawerComponent from './components/DataGrid/BoardsDrawerComponent.js'
import SeoPage from './components/SEO/SeoPage.js'
import TaskDrawer from './components/TaskDrawer/TaskDrawer.js'
import NcoDrawer from './components/NCO/NcoDrawer.js'
import AccountingDrawer from './components/Accounting/AccountingDrawer.js'
import AccountingDetails from './components/Accounting/AccountingDetails.tsx'
import TaskDetails from './components/TaskDrawer/TaskDetails.tsx'
import FileManagement from './components/DataGrid/components/FileManagement.js'
import DriveFoldersById from './components/Google/DriveFoldersById.tsx'
import { DrawerProvider } from './context/DrawerProvider/DrawerProvider.js'
import TaskMessages from './components/Comments/TaskMessages.tsx'
import DevDataGrid from './Development/DataGrid.tsx'
import SeoDrawerComponent from './components/SEO/SeoDrawerComponent.js'
import ProductsGrid from './components/ProductRows/ProductsGrid.js'
import CreativeDrawerComponent from './components/DataGrid/CreativeDrawerComponent.js'
import CreateCustomChecklist from './components/DataGrid/components/CreateCustomChecklist.jsx'
import MtbPage from './components/MTB/MonthlyTaskBoardPage.js'
import Budget from './components/UpdateComponents/Budget.js'
import MtbDrawerComponent from './components/MTB/MtbDrawerComponent.js'
import WebsiteDrawerComponent from './components/DataGrid/WebsiteDrawerComponent.js'
import MetricsDataGrid from './components/MetricAnalytics/MetricDataGrid.tsx'
import PivotTable from './components/MetricAnalytics/PivotTable.tsx'
import ProductsTableMetrics from './components/MetricAnalytics/ProductsTableMetrics.tsx'
import CampaignChecklistDrawer from './components/CampaignChecklist/CampaignChecklistDrawer.js'
import CampaignChecklistPage from './components/CampaignChecklist/CampaignChecklistPage.tsx'

const ProtectedApp = () => {
  return (
    <>
      {/* <BreakpointMarker breakpoint='xs' label='Extra Small (xs)' color='rgb(255, 82, 0)' />
      <BreakpointMarker breakpoint='sm' label='Small (sm)' color='rgb(54, 209, 93)' />
      <BreakpointMarker breakpoint='md' label='Medium (md)' color='rgb(0, 180, 255)' />
      <BreakpointMarker breakpoint='lg' label='Large (lg)' color='rgb(126, 0, 255)' />
      <BreakpointMarker breakpoint='xl' label='Extra Large (xl)' color='rgb(255, 0, 166)' /> */}
      <ValidationProvider>
        <LeadsProvider>
          <SnackbarProvider>
            <DrawerProvider>
              <AppLayout>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                <Routes>
                  <Route path='/dashboard' element={<DashboardPage />} />
                  <Route path='/dev' element={<DevDataGrid />} />
                  <Route path='/boards' element={<Boards />} />
                  <Route path='/metrics' element={<MetricsDataGrid />} />
                  <Route path='/productmetrics' element={<ProductsTableMetrics />} />
                  <Route path='/pivottable' element={<PivotTable />} />
                  <Route path='/budget' element={<Budget />} />
                  <Route path='/seo' element={<SeoPage />}>
                    <Route path=':dealId/*' element={<SeoDrawerComponent />}>
                      <Route path='main' element={null} />
                      <Route path='strategy' element={null} />
                      <Route path='strategy/:campaignId' element={null} />
                      <Route path='review' element={null} />
                      <Route path='notes/:orgId' element={null} />
                      <Route path='optimizations/:orgId' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks/:orgId' element={null} />
                    </Route>
                  </Route>

                  <Route path='/products' element={<ProductsGrid />} />
                  <Route path='/website' element={<WebsitePage />}>
                    <Route path=':dealId/*' element={<WebsiteDrawerComponent />}>
                      <Route path='main' element={null} />
                      <Route path='projectinfo' element={null} />
                      <Route path='review' element={null} />
                      <Route path='notes/:orgId' element={null} />
                      <Route path='checklists/:orgId' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks/:orgId' element={null} />
                    </Route>
                  </Route>
                  {/* NCO Routes */}
                  <Route path='/nco' element={<NCOPage />}>
                    <Route path=':dealId/*' element={<BoardsDrawerComponent />}>
                      <Route path='main' element={null} />
                      <Route path='strategy' element={null} />
                      <Route path='strategy/:campaignId' element={null} />
                      <Route path='review' element={null} />
                      <Route path='notes/:orgId' element={null} />
                      <Route path='kickoffcall' element={null} />
                      <Route path='integration/:orgId' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks/:orgId' element={null} />
                    </Route>
                  </Route>
                  <Route path='/mtb' element={<MtbPage />}>
                    <Route path=':orgId/*' element={<MtbDrawerComponent />}>
                      <Route path='main' element={null} />
                      <Route path='SEO' element={null} />
                      <Route path='AM' element={null} />
                      <Route path='DMS' element={null} />
                      <Route path='notes' element={null} />
                      <Route path='kickoffcall' element={null} />
                      <Route path='onboarding' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks' element={null} />
                      <Route path='deals' element={null} />
                    </Route>
                  </Route>
                  <Route path='/campaignchecklists' element={<CampaignChecklistPage />}>
                    <Route path=':orgId/*' element={<CampaignChecklistDrawer />}>
                      <Route path='main' element={null} />
                      <Route path='DMS' element={null} />
                      <Route path='QA' element={null} />
                      <Route path='AM' element={null} />
                      <Route path='notes' element={null} />
                      <Route path='kickoffcall' element={null} />
                      <Route path='onboarding' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks' element={null} />
                      <Route path='deals' element={null} />
                    </Route>
                  </Route>
                  <Route path='/creative' element={<CreativePage />}>
                    <Route path=':dealId/*' element={<CreativeDrawerComponent />}>
                      <Route path='main' element={null} />
                      <Route path='projectinfo' element={null} />
                      <Route path='review' element={null} />
                      <Route path='notes/:orgId' element={null} />
                      <Route path='checklists/:orgId' element={null} />
                      <Route path='filemgmt' element={null} />
                      <Route path='orgtasks/:orgId' element={null} />
                    </Route>
                  </Route>
                  {/* Team Tasks Routes */}
                  {/* <Route path='/teamtasks' element={<TeamTasksPage />}>
                    <Route path=':PK/:SK/*' element={<TaskDrawer />}>
                      <Route path='taskdetails' element={null} />
                      <Route path='messages' element={null} />
                      <Route path='filemgmt' element={null} />
                    </Route>
                  </Route>
                  <Route path='/creative' element={<CreativePage />}>
                    <Route path=':PK/:SK/*' element={<TaskDrawer />}>
                      <Route path='taskdetails' element={null} />
                      <Route path='messages' element={null} />
                      <Route path='filemgmt' element={null} />
                    </Route>
                  </Route> */}
                  <Route path='/teamtasks' element={<TeamTasksPage />}>
                    <Route path=':PK/:SK/*' element={<TaskDrawer />}>
                      <Route path='main' element={<TaskDetails />} />
                      <Route path='messages' element={<TaskMessages />} />
                      <Route path='filemgmt' element={<DriveFoldersById />} />
                    </Route>
                  </Route>

                  {/* <Route path='/creative' element={<CreativePage />}>
                    <Route path=':PK/:SK/*' element={<TaskDrawer />}>
                      <Route path='main' element={<TaskDetails />} />
                      <Route path='messages' element={<TaskMessages />} />
                      <Route path='filemgmt' element={<DriveFoldersById />} />
                    </Route>
                  </Route> */}

                  <Route path='/accounting' element={<AccountingPage />}>
                    <Route path=':dealId/*' element={<AccountingDrawer />}>
                      <Route path='details' element={<AccountingDetails />} />
                    </Route>
                  </Route>
                  <Route path='/leads' element={<Leads />} />
                  <Route path='/approvals' element={<ApprovalsPage />} />
                  <Route path='/notifytags' element={<MentionsPage />} />
                  <Route path='/deals' element={<DealsPage />} />
                  {/* <Route path='/deals' element={<PaginateDeals />} /> */}
                  <Route path='/deals/pipeline' element={<DealsKanban />} />
                  <Route path='/deals/:dealId/*' element={<DealPage />}>
                    <Route path='details' element={<DealsDetails />} />
                    <Route path='agreement' element={<NewContract />} />
                    <Route path='tasks' element={<DealTaskInfo />} />
                    <Route path='services' element={<DealServicesInfo />} />
                    <Route path='strategy' element={<Strategy />} />
                    <Route path='strategy/:campaignId' element={<RenderCampaign />} />
                    <Route path='reviewdeal' element={<ReviewDeal />} />
                    <Route path='paymentschedule' element={<PaymentSchedule />} />
                    <Route path='invoicing' element={<AccountingSchedule />} />
                    <Route path='timeline' element={<TimelinePage />} />
                  </Route>
                  <Route path='/contacts' element={<ContactsPage />} />
                  <Route path='/contacts/:contactId/*' element={<ContactPage />}>
                    <Route path='details' element={<ContactDetails />} />
                    <Route path='additionalinfo' element={<div>additionalInfo</div>} />
                  </Route>
                  <Route path='/organizations' element={<OrganizationsPage />} />
                  <Route path='/organizations/:orgId/*' element={<OrganizationPage />}>
                    <Route path='details' element={<OrganizationDetails />} />
                    <Route path='deals' element={<OrgDealInfo />} />
                    <Route path='tasks' element={<OrgTaskInfo />} />
                    <Route path='billing' element={<OrgBillingInfo />} />
                    <Route path='notes' element={<OrganizationNotes />} />
                    <Route path='integration' element={<IntegrationNotes />} />
                  </Route>
                  {/* <Route path='/propertymgmt/:propertyMgmtId' element={<PropertyMgmtCompany />} /> */}
                  {/* <Route path='/ownershipGroup/:ownershipGroupId' element={<OwnershipGroup />} /> */}
                  <Route path='/sprints' element={<SprintsPage />} />
                  {/* <Route path='/updatecomponents' element={<UpdateComponents />} /> */}
                  <Route path='/pandadocs' element={<Documents />} />
                  <Route path='/pandadocs/:name' element={<DocumentDetails />} />
                  {/* <Route path='/slackuserlist' element={<SlackUserList />} /> */}
                  <Route path='/googledrives' element={<GooglePickerAPI />} />
                  {/* <Route path='/timeline' element={<WebhookData />} /> */}
                </Routes>
              </AppLayout>
            </DrawerProvider>
          </SnackbarProvider>
        </LeadsProvider>
      </ValidationProvider>
    </>
  )
}

export default ProtectedApp
