import { Box, InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'

const NetIncomeCalculator = ({ setNetMonthlyIncome, setNetBiWeeklyIncome, yearlySalary, setYearlySalary, taxRate }) => {
  /*

Taxable Income	Rate
$0 - $23,220	10%
$23,220 - $94,300	12%
$94,300 - $201,050	22%
$201,050 - $383,900	24%
$383,900 - $487,450	32%
$487,450 - $731,200	35%
$731,200+	37%

*/

  // const healthInsurancePerMonth = 636.26
  // const healthInsurancePerMonth = 336.26
  const healthInsurancePerMonth = 0

  const calculateNetMonthlyIncome = salary => {
    const monthlyGrossIncome = salary / 12
    const monthlyIncomeAfterHealthInsurance = monthlyGrossIncome - healthInsurancePerMonth
    const netIncome = monthlyIncomeAfterHealthInsurance * (1 - taxRate)
    return netIncome
  }

  const calculateNetBiWeeklyIncome = salary => {
    const biWeeklyGrossIncome = salary / 26
    const biWeeklyHealthInsurance = healthInsurancePerMonth / 2
    const incomeAfterHealthInsurance = biWeeklyGrossIncome - biWeeklyHealthInsurance
    const netIncomeBiWeekly = incomeAfterHealthInsurance * (1 - taxRate)
    return netIncomeBiWeekly
  }

  const handleSalaryChange = e => {
    const salary = parseFloat(e.target.value)
    setYearlySalary(salary)

    if (!isNaN(salary)) {
      const netIncome = calculateNetMonthlyIncome(salary)
      setNetMonthlyIncome(netIncome.toFixed(2))

      const netBiWeeklyIncome = calculateNetBiWeeklyIncome(salary)
      setNetBiWeeklyIncome(netBiWeeklyIncome.toFixed(2))
    } else {
      setNetMonthlyIncome(null)
      setNetBiWeeklyIncome(null)
    }
  }

  return (
    <Box>
      <Typography variant='h2'>Net Income Calculator</Typography>
      <Box>
        <InputLabel>Yearly Salary: </InputLabel>
        <TextField
          type='number'
          value={yearlySalary === '' ? '' : yearlySalary}
          onChange={handleSalaryChange}
          placeholder='Enter your yearly salary'
        />
      </Box>
    </Box>
  )
}

export default NetIncomeCalculator
