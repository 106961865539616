import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../../queryMutations/mutationFunctions'
import axios from 'axios'

// ✅ Improved type definition for filters
type FilteredTaskProps = Record<string, boolean | string | number>

export const getFilteredTasks = async (filters: FilteredTaskProps) => {
  try {
    const url = `${baseUrl}/aws/tasks/filter`
    // Instead of returning early, call the endpoint even if filters is empty.
    const params = Object.keys(filters).length > 0 ? { filters: JSON.stringify(filters) } : {}
    const { data } = await axios.get(url, { params })
    console.log('Fetched tasks:', data)
    return data || []
  } catch (error) {
    console.error('Error fetching tasks:', error)
    return []
  }
}

export const useFilteredTasks = (enabled: boolean, queryKey: string = 'filteredTasks', filters: FilteredTaskProps) => {
  // Always use the filters (even if empty) so the queryKey changes when filters change.
  const validFilters: FilteredTaskProps = filters || {}

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKey, validFilters],
    queryFn: () => getFilteredTasks(validFilters),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
    enabled, // Remove the check for non-empty filters so it always fetches.
  })

  return {
    filteredTasks: data,
    isFilteredTasksLoading: isLoading,
    isFilteredTasksError: isError,
  }
}

// ✅ Function to fetch filtered tasks
// export const getFilteredTasks = async (filters: FilteredTaskProps) => {
//   try {
//     const url = `${baseUrl}/aws/tasks/filter`

//     if (!filters || Object.keys(filters).length === 0) {
//       console.warn('No filters provided for getFilteredTasks')
//       return []
//     }

//     const { data } = await axios.get(url, {
//       params: { filters: JSON.stringify(filters) }, // ✅ Sending filters directly
//     })

//     console.log('Fetched filtered tasks:', data)
//     return data || []
//   } catch (error) {
//     console.error('Error fetching tasks:', error)
//     return []
//   }
// }

// // ✅ Improved `useFilteredTasks` Hook
// export const useFilteredTasks = (enabled: boolean, queryKey: string = 'filteredTasks', filters: FilteredTaskProps) => {
//   const validFilters: FilteredTaskProps = filters && Object.keys(filters).length > 0 ? filters : {}

//   const { data, isLoading, isError } = useQuery({
//     queryKey: [queryKey, validFilters],
//     queryFn: () => getFilteredTasks(validFilters),
//     staleTime: Infinity,
//     cacheTime: 1000 * 60 * 10,
//     enabled: enabled && Object.keys(validFilters).length > 0,
//   })

//   return {
//     filteredTasks: data,
//     isFilteredTasksLoading: isLoading,
//     isFilteredTasksError: isError, // ✅ Fixed duplicate key issue
//   }
// }
