import React from 'react'
import { Box, Grid, Tooltip } from '@mui/material'
import { Note, SelectedFile } from './types'

interface ThumbnailProps {
  note: NoteItem
  fallbackIcon: string
  openFrame: (embedUrl: string, webContentLink: string) => void
}

type NoteItem = {
  embedUrl: string
  thumbnailLink: string
  attachments: SelectedFile[]
}

const PreviewAttachments: React.FC<ThumbnailProps> = ({ note, openFrame, fallbackIcon }) => {
  return (
    <>
      <Grid container sx={{ mt: 1, alignItems: 'center' }}>
        {note.attachments.map((file: any) => (
          <Grid item key={file.id} xs={1.5}>
            <Tooltip title={file?.name}>
              <Box onClick={() => openFrame(file.embedUrl, file.webContentLink)} sx={{ cursor: 'pointer' }}>
                <Box
                  component='img'
                  src={file.thumbnailLink || fallbackIcon}
                  alt={file.name}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    paddingRight: 0.6,
                    paddingLeft: 0.6,
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.15)',
                    },
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default PreviewAttachments
