import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Stack,
  useTheme,
  InputLabel,
  CircularProgress,
} from '@mui/material'
import RenderOrganizationLinkName from '../Reusable/RenderOrganizationLinkName'
import {
  InfoBox,
  InfoRow,
  InfoLabel,
  InfoValueWrapper,
  InfoValue,
  StyledLink,
  CityStateZipRow,
  PhoneRow,
} from './style'
import EditOrganizationInfo from './EditOrganizationInfo'
import UserImageComponent from '../Users/UserImageComponent'
import { useUsersById } from '../../api/customHooks/useUsersById'
import EditIconButton from '../ui/edit-icon-button'
import LinkIcon from '@mui/icons-material/Link'
import { FlexBoxRow } from '../ui/flex-box-row'
import { formatWebsiteUrl } from '../DataGrid/createColumns/renderOrganizationColumns'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import SvgIconComponent from '../../images/SvgIconComponent'
import RenderField from './RenderField'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import GroboticDark from '../../images/Grobotic png.png'
import GroMetaIcon from '../../images/icons/meta (1).svg'
import { useQueryClient } from '@tanstack/react-query'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const OrganizationInfoCard = ({
  users,
  organization,
  primaryContact,
  billingContact,
  addtlContacts,
  orgId,
  onChange,
  onSave,
  onUpdateWebsite,
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { pinned } = useLayoutContext()
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['organizationDetails', 'organizations'])
  const userIds =
    organization && Array.isArray(organization.owner)
      ? organization.owner.map((id) => id)
      : []
  const { users: owners, isUsersByIdLoading: isOwnersLoading } = useUsersById(
    userIds,
    'owner'
  )
  const { isValidWebsite, errors, setErrors } = useValidationContext()
  const [details, setDetails] = useState({
    ...organization,
    billingPhone: billingContact
      ? billingContact.phone
      : organization
      ? organization.billingPhone
      : '...',
    email: billingContact
      ? billingContact.email
      : organization
      ? organization.email
      : '...',
  })
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedPrimaryContact, setSelectedPrimaryContact] =
    useState(primaryContact)
  const [selectedContacts, setSelectedContacts] = useState([])
  const contactIds = organization?.contacts || []
  const handleSave = () => {
    setIsEditMode(false)
  }
  const handleEdit = () => {
    setIsEditMode(true)
  }
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'website') {
      if (!isValidWebsite(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Invalid website URL',
        }))
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }))
      }
    } else if (name === 'owner') {
      console.log('owner changed')
    }
    // Update state and validate website in real-time
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }))
    // await queryClient.setQueryData(['organization', orgId], old => ({
    //   ...old,
    //   [fieldName]: newValue,
    // }))
  }

  const handleBlur = (event) => {
    const { name, value } = event.target

    if (organization[name] !== value) {
      onSave(name, value)
    }
  }
  const handleWebsiteBlur = (event) => {
    const { name, value } = event.target

    // Prevent infinite loop by only validating and updating if there are no errors
    if (name === 'website' && !isValidWebsite(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Invalid website URL',
      }))
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
      }))

      if (organization[name] !== value) {
        onUpdateWebsite(name, value)
      }
    }
  }
  const handleCompanyChange = (context, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [context]: value,
    }))
    if (JSON.stringify(organization[context]) !== JSON.stringify(value)) {
      onChange(context, value)
    }
  }

  const handleObjectBlur = (context, newValue) => {
    if (JSON.stringify(organization[context]) !== JSON.stringify(newValue)) {
      onSave(context, newValue)
    }
  }

  useEffect(() => {
    setDetails(organization)
  }, [organization, orgId])
  return (
    <>
      {isEditMode === false ? (
        <InfoBox
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '' : 'rgba(255,255,255)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <EditIconButton
              color={'primary'}
              variant="contained"
              onEdit={handleEdit}
            >
              Edit
            </EditIconButton>
          </Box>
          <Typography variant="h6" gutterBottom>
            Information
          </Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <InfoRow>
                <InfoLabel>Owner</InfoLabel>
                {isOwnersLoading ? (
                  <CircularProgress />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {owners && Array.isArray(owners) ? (
                      owners.map((user) => (
                        <Box
                          key={user.id}
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          <UserImageComponent
                            imageUrl={user.image_512}
                            width={40}
                            height={40}
                            name={user.name}
                          />
                          <Typography>{user.name}</Typography>
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <img
                          height={40}
                          width={40}
                          src={
                            theme.palette.mode === 'dark'
                              ? GroboticDark
                              : GroboticLight
                          }
                        />
                        <Typography>No Owner Assigned</Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </InfoRow>
              <InfoRow>
                <RenderField
                  label="Name"
                  fieldName="name"
                  value={organization.name}
                />
              </InfoRow>
              <InfoRow>
                <RenderField
                  label="Legal Entity Name"
                  fieldName="dba"
                  value={organization.dba}
                />
              </InfoRow>
              <InfoRow>
                <InfoLabel>Website</InfoLabel>
                <InfoValueWrapper>
                  {organization.website ? (
                    <a
                      href={
                        organization.website.startsWith('http')
                          ? organization.website
                          : `https://${organization.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <FlexBoxRow>
                        <InfoValue>
                          {formatWebsiteUrl(organization.website)}
                        </InfoValue>
                        <LinkIcon />
                      </FlexBoxRow>
                    </a>
                  ) : (
                    <InfoValue>No Website Assigned</InfoValue>
                  )}
                </InfoValueWrapper>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Primary Contact</InfoLabel>
                <InfoValueWrapper>
                  {primaryContact && (
                    <Stack key={primaryContact.contacts_uuid} gap={4}>
                      <StyledLink
                        key={primaryContact.contacts_uuid}
                        to={`/contacts/${primaryContact.contacts_uuid}/details`}
                      >
                        {primaryContact.name}
                      </StyledLink>
                    </Stack>
                  )}
                </InfoValueWrapper>
              </InfoRow>

              <InfoRow>
                <InfoLabel>Billing Contact</InfoLabel>
                <InfoValueWrapper>
                  {billingContact && (
                    <Stack key={billingContact.contacts_uuid} gap={4}>
                      <StyledLink
                        key={billingContact.contacts_uuid}
                        to={`/contacts/${billingContact.contacts_uuid}/details`}
                      >
                        {billingContact.name}
                      </StyledLink>
                    </Stack>
                  )}
                </InfoValueWrapper>
              </InfoRow>
              <InfoRow>
                <InfoLabel>Contacts</InfoLabel>
                <InfoValueWrapper>
                  {addtlContacts && addtlContacts.length > 0 ? (
                    addtlContacts
                      .filter(
                        (contact) =>
                          contact.contacts_uuid !==
                          selectedPrimaryContact?.contacts_uuid
                      )
                      .map((contact) => (
                        <Stack key={contact.contacts_uuid} gap={4}>
                          <StyledLink
                            key={contact.contacts_uuid}
                            to={`/contacts/${contact.contacts_uuid}/details`}
                          >
                            {contact.name}
                          </StyledLink>
                        </Stack>
                      ))
                  ) : (
                    <Typography sx={{ color: 'rgba(211,211,211, 0.7)' }}>
                      {'No additional contacts assigned'}
                    </Typography>
                  )}
                </InfoValueWrapper>
              </InfoRow>
              {/* {organization && subOrganizationTypes.includes(organization.type) && ( */}
              <>
                <InfoRow>
                  <InfoLabel>Contracted By</InfoLabel>
                  <InfoValueWrapper>
                    {organization &&
                    Array.isArray(organization.contractedBy) &&
                    organization.contractedBy.length > 0 ? (
                      organization.contractedBy.map((contractedById) => {
                        return (
                          <StyledLink
                            key={contractedById}
                            to={`/organizations/${contractedById}/details`}
                          >
                            <RenderOrganizationLinkName
                              organizationId={contractedById}
                            />
                          </StyledLink>
                        )
                      })
                    ) : (
                      <Typography sx={{ color: 'rgba(211,211,211, 0.7)' }}>
                        {'No organization assigned'}
                      </Typography>
                    )}
                  </InfoValueWrapper>
                </InfoRow>
                <InfoRow>
                  <InfoLabel>Affiliate</InfoLabel>
                  <InfoValueWrapper>
                    {organization &&
                    Array.isArray(organization.affiliated) &&
                    organization.affiliated.length > 0 ? (
                      organization.affiliated.map((affiliatedId) => (
                        <StyledLink
                          key={affiliatedId}
                          to={`/organizations/${affiliatedId}/details`}
                        >
                          <RenderOrganizationLinkName
                            organizationId={affiliatedId}
                          />
                        </StyledLink>
                      ))
                    ) : (
                      <Typography sx={{ color: 'rgba(211,211,211, 0.7)' }}>
                        {'No organization assigned'}
                      </Typography>
                    )}
                  </InfoValueWrapper>
                </InfoRow>
              </>
              {/* )} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneRow>
                <Box sx={{ flex: 2 }}>
                  <RenderField
                    label="Business Phone"
                    fieldName="phone"
                    value={organization.businessPhone}
                  />
                </Box>
                <Box sx={{ flex: 1, mx: 2 }}>
                  <RenderField
                    label="Billing Phone"
                    fieldName="phone"
                    value={
                      billingContact
                        ? billingContact.phone
                        : organization
                        ? organization.billingPhone
                        : '...'
                    }
                  />
                </Box>
              </PhoneRow>

              <InfoRow>
                <RenderField
                  label="Billing Email"
                  fieldName="email"
                  value={
                    billingContact
                      ? billingContact.email
                      : organization
                      ? organization.email
                      : '...'
                  }
                />
              </InfoRow>

              {!organization.street &&
              !organization.city &&
              !organization.state &&
              !organization.zip ? (
                <InfoRow>
                  <InfoValueWrapper>
                    <InfoValue>No Address Info Available</InfoValue>
                  </InfoValueWrapper>
                </InfoRow>
              ) : (
                <>
                  <InfoRow>
                    <RenderField
                      label="Street"
                      fieldName="address"
                      value={organization.street}
                    />
                  </InfoRow>

                  <CityStateZipRow>
                    <Box sx={{ flex: 2 }}>
                      <RenderField
                        label="City"
                        fieldName="address"
                        value={organization.city}
                      />
                    </Box>
                    <Box sx={{ flex: 1, mx: 2 }}>
                      <RenderField
                        label="State"
                        fieldName="address"
                        value={organization.state}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <RenderField
                        label="Zip"
                        fieldName="address"
                        value={organization.zip}
                      />
                    </Box>
                  </CityStateZipRow>
                </>
              )}
              <InfoRow>
                <RenderField
                  label="CMS being used"
                  fieldName="cms"
                  value={organization.cmsUsed}
                />
              </InfoRow>
              <InfoRow>
                <InfoLabel>Active Social Profiles</InfoLabel>
                <InfoValueWrapper>
                  {organization &&
                  Array.isArray(organization.social) &&
                  organization.social.length > 0 ? (
                    <SvgIconComponent
                      socialProfiles={organization ? organization.social : []}
                    />
                  ) : (
                    <InfoValue sx={{ color: 'rgba(211,211,211, 0.7)' }}>
                      {'...'}
                    </InfoValue>
                  )}
                </InfoValueWrapper>
              </InfoRow>

              <InfoRow>
                <RenderField
                  label="Marketing Objective"
                  fieldName="objective"
                  value={organization.marketingObjective}
                />
              </InfoRow>
              <InfoRow>
                <RenderField
                  label="Geographic Targeting"
                  fieldName="geoTargeting"
                  value={organization.geoTargeting}
                />
              </InfoRow>
              <InfoRow>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <img
                    key={`facebookAccess-key`}
                    src={GroMetaIcon}
                    alt={'facebookAccess-alt'}
                    style={{ width: 24, height: 24 }}
                  />
                  <InputLabel sx={{ fontSize: 14 }}>Facebook Access</InputLabel>
                </Box>
                <RenderField
                  label=""
                  fieldName="facebookAccess"
                  value={organization.facebookAccess}
                />
              </InfoRow>
            </Grid>
          </Grid>
        </InfoBox>
      ) : (
        <>
          {isOrganizationsLoading ? (
            <DetailsLoading />
          ) : (
            <EditOrganizationInfo
              users={users}
              organization={organization}
              organizations={organizations}
              owners={owners}
              isOwnersLoading={isOwnersLoading}
              billingContact={billingContact}
              addtlContacts={addtlContacts}
              details={details}
              setDetails={setDetails}
              handleSave={handleSave}
              onUpdateWebsite={onUpdateWebsite}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleCompanyChange={handleCompanyChange}
              handleObjectBlur={handleObjectBlur}
              onWebsiteBlur={handleWebsiteBlur}
            />
          )}
        </>
      )}
    </>
  )
}

export default OrganizationInfoCard
