import { Typography, Box, Button, Popover, useTheme, Backdrop, CircularProgress } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DescriptionIcon from '@mui/icons-material/Description'

import {
  popoverStyles,
  boxContainerStyles,
  boxStyles,
  deleteButtonStyles,
  textStyles,
  buttonContainerStyles,
} from './style'
import { useState } from 'react'
const getThumbnail = url => {
  if (/\.(jpe?g|png|gif)$/i.test(url)) {
    return (
      <Box
        component='img'
        src={url}
        alt='task-file'
        sx={{
          width: '100%',
          height: 45,
          borderRadius: 1,
          objectFit: 'cover',
          transition: 'transform 0.2s ease',
          //   '&:hover': {
          //     transform: 'scale(1.15)',
          //   },
        }}
      />
    )
  } else if (/\.pdf$/i.test(url)) {
    return <PictureAsPdfIcon sx={{ fontSize: 45, color: 'text.secondary' }} />
  } else if (/\.(doc|docx)$/i.test(url)) {
    return <DescriptionIcon sx={{ fontSize: 45, color: 'text.secondary' }} />
  } else {
    return <DescriptionIcon sx={{ fontSize: 45, color: 'text.secondary' }} />
  }
}
const TasksPopover = ({ id, open, anchorEl, onClose, handleConfirmDelete, selectedData, context }) => {
  const theme = useTheme()
  const [isDeleting, setIsDeleting] = useState(false)
  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await handleConfirmDelete()
      await onClose()
      setIsDeleting(false)
    } catch (error) {
      setIsDeleting(false)

      console.log('Error deleting deal: ', error)
    }
  }
  return (
    // <Backdrop open={open} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
        sx={popoverStyles(theme)}
      >
        <Box sx={boxContainerStyles(theme)}>
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <WarningIcon sx={{ color: 'crimson' }} />
              <Typography sx={{ fontWeight: 'bold', color: 'crimson' }}>Confirm Deletion</Typography>
            </Box>
            <Typography style={textStyles(theme)} sx={{ mb: 2 }}>
              Are you sure you want to permanently delete the following item(s) from Grobotic?
            </Typography>
            <Box sx={boxStyles(theme)}>
              {context === 'teamTasks' ? (
                <TaskAltIcon
                  sx={{
                    // color: 'rgb(247, 63, 236)',
                    color: 'rgb(52, 211, 145)',
                    fontSize: 35,
                  }}
                />
              ) : (
                <EmojiObjectsIcon
                  sx={{
                    color: 'rgb(111, 31, 223)',
                    fontSize: 35,
                  }}
                />
              )}

              <Typography style={textStyles(theme)}>{selectedData ? selectedData.name : ''}</Typography>
            </Box>
            {selectedData?.imageUrls && selectedData?.imageUrls?.length > 0 && (
              <Box sx={{ ...boxStyles(theme), gap: 1, justifyContent: 'center' }}>
                {selectedData?.imageUrls?.map(url => (
                  <Box key={url}>{getThumbnail(url)}</Box>
                ))}
              </Box>
            )}
          </>

          <Box sx={buttonContainerStyles(theme)}>
            {isDeleting && <CircularProgress />}
            <Button
              sx={deleteButtonStyles(theme)}
              variant='outlined'
              onClick={handleDelete}
              color='secondary'
              disabled={isDeleting}
            >
              Confirm
            </Button>
            <Button variant='contained' onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}
export default TasksPopover
