import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

const updateTaskItems = async ({ updates }) => {
  console.log('[UpdateItems] called with updates: ', updates)

  const url = `${baseUrl}/aws/tasks/updateitems`

  try {
    const response = await axios.put(url, { updates })

    if (response.status !== 200) throw new Error('Network response was not ok')

    return response.data
  } catch (error) {
    console.error('Failed to update items:', error)
    throw error
  }
}

export const useUpdateTaskItems = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: updateTaskItems,
    onSuccess: (data, variables) => {
      console.log('[useUpdateItems] Updated items data:', data, 'VARIABLES: ', variables)
      variables.updates.forEach(update => {
        // Invalidate queries for the affected items
        queryClient.invalidateQueries([update.tableName, update.pkValue, update.skValue])
      })
    },
    onError: error => {
      console.error('[useUpdateItems] Error updating items:', error)
    },
  })

  return mutation
}
