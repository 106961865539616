import React, { ReactNode } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material'

interface ExpandCollapseProps {
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  children: ReactNode
  displayElement: string | ReactNode
}

const ExpandCollapseButton: React.FC<ExpandCollapseProps> = ({ expanded, setExpanded, children, displayElement }) => {
  return (
    <>
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          p: 1,
          cursor: 'pointer',
          // ':hover': { transform: 'scale(1.01)', transition: 'transform 0.3s ease' },
        }}
      >
        {typeof displayElement === 'string' ? (
          <>
            <IconButton onClick={() => setExpanded(!expanded)} size='small'>
              {expanded ? <ExpandLessIcon sx={{ fontSize: 34 }} /> : <ExpandMoreIcon sx={{ fontSize: 34 }} />}
            </IconButton>
            <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
              {displayElement}
            </Typography>
          </>
        ) : (
          <>
            <IconButton onClick={() => setExpanded(!expanded)} size='small'>
              {expanded ? <ExpandLessIcon sx={{ fontSize: 34 }} /> : <ExpandMoreIcon sx={{ fontSize: 34 }} />}
            </IconButton>
            {displayElement}
          </>
        )}
      </Box>
      <Collapse in={expanded}>{children}</Collapse>
    </>
  )
}

export default ExpandCollapseButton
