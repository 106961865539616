import React, { useState, useEffect, useContext } from 'react'
import { Box, useTheme } from '@mui/material'
// import Loading from '../Loading/Loading'
import { renderLeadsColumns } from '../DataGrid/createColumns/renderLeadsColumns'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { gridContainerStyles } from './style'
import CustomGridToolbar from '../DataGrid/CustomGridToolbar'
import { LeadsGridFooter } from './LeadsGridFooter'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import LeadDrawer from './LeadDrawer'
import { useLeads } from '../../api/aws/useLeads'
import { useLeadsById } from '../../api/customHooks/useLeadsById'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const Leads = () => {
  const { pinned } = useLayoutContext()
  const [selectedLeads, setSelectedLeads] = useState(null)
  const [selectedLead, setSelectedLead] = useState(null)
  const [leadId, setLeadId] = useState(null)
  const [leadIds, setLeadIds] = useState([])
  const { leads, isLeadsLoading, isLeadsError } = useLeads(true)
  const { data: fetchedLeads, isLoading: leadsByIdLoading } = useLeadsById(leadIds)
  const { data: fetchedLead, isLoading: leadByIdLoading } = useLeadsById(leadId ? [leadId] : [])
  const { mutate: deleteLead } = useDeleteMutation()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const { mutate: deleteItem, isLoading } = useDeleteMutation()
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(leads ? leads.length : 0)
  const [rowCount, setRowCount] = useState(leads ? leads.length : 0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  useEffect(() => {
    if (leads && selectionModel.length === 1) {
      const newLead = leads.find(lead => lead.lead_uuid === selectionModel[0])
      setSelectedLeads(newLead)
      // setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedLeads(null)
    }
  }, [selectionModel, leads])

  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }
  const handleLeadClick = lead => {
    console.log('Lead Clicked! lead: ', lead)
    setLeadId(lead.lead_uuid)
    setSelectedLead(lead)
    setDrawerOpen(true)
  }
  const columns = renderLeadsColumns(handleLeadClick)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleSelectionModelChange = newSelectionModel => {
    console.log('New Selection Model: ', newSelectionModel)
    if (newSelectionModel.length !== selectionModel.length) {
      setSelectionModel(newSelectionModel)
      if (newSelectionModel.length === 0 && handleRowSelected) {
        handleRowSelected(null)
      } else {
        const selectedRowData = leads.find(row => row.id === newSelectionModel[0])
        if (handleRowSelected) {
          handleRowSelected(selectedRowData)
        }
      }
    }
  }
  const handleRowSelected = lead => {
    if (selectionModel.length === 1) {
      setSelectedLead(lead)
    }
    setSelectedLeads(lead)
    console.log('SELECTED LEAD: ', selectedLead)
  }

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    console.log('handleConfirmDelete selectedLeads: ', selectedLeads)
    if (selectedLeads) {
      const params = {
        endpoint: `/aws/delete/leads/${selectedLeads.lead_uuid}`,
        table: 'leads',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedLeads(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedLeads(null)
    }
  }

  useEffect(() => {
    const handleFilterModelChange = () => {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
      setVisibleRowCount(filteredRowsCount)
    }

    const api = apiRef.current
    const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    return () => {
      unsubscribe()
    }
  }, [apiRef])

  const totalRowCount = leads ? leads.length : 0

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ...gridContainerStyles,
          pt: pinned ? '60px' : 0.8,
          transition: 'all 0.3s ease', // or .5s, etc.
          height: '98%',
          // height: pinned ? 'calc(100vh - 80px)' : 'calc(100vh - 20px)',
        }}
      >
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [{ field: 'submittedDate', sort: 'desc' }],
              sortModel: [{ field: 'submittedTime', sort: 'desc' }],
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: {
                keyTerm: false,
                ggLid: false,
                userId: false,
                sessionId: false,
                pageURL: false,
              },
            },
          }}
          rows={leads || []}
          columns={columns}
          rowHeight={52}
          getRowId={row => row.lead_uuid || row.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          // onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{
            toolbar: CustomGridToolbar,
            footer: LeadsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              setFilterModel: setFilterModel,
              context: 'leads',
            },
            footer: {
              selectionModel: selectionModel,
              totalRowCount: totalRowCount,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              leads: leads,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              selectedData: selectedLeads,
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          loading={isLeadsLoading}
        />
        <LeadDrawer lead={selectedLead} open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      </Box>
    </>
  )
}

export default Leads

// import React, { useState, useEffect, useContext } from 'react'
// import { Box, useTheme } from '@mui/material'
// // import Loading from '../Loading/Loading'
// import { renderLeadsColumns } from '../DataGrid/createColumns/renderLeadsColumns'
// import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
// import { gridContainerStyles } from './style'
// import CustomGridToolbar from '../DataGrid/CustomGridToolbar'
// import { LeadsGridFooter } from './LeadsGridFooter'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
// import { useLeadsContext } from '../../context/LeadsProvider/LeadsProvider'
// import LeadDrawer from './LeadDrawer'
// import DetailsLoading from '../Loading/DetailsLoading'

// const Leads = () => {
//   const {
//     leads,
//     selectedLeads,
//     leadsLoading,
//     setSelectedLeads,
//     selectedLead,
//     setSelectedLead,
//     setLeadId,
//     handleDeleteLead,
//   } = useLeadsContext()
//   const { showSnackbar } = useSnackbarContext()
//   const theme = useTheme()
//   const [isDeleting, setIsDeleting] = useState(false)
//   const [selectionModel, setSelectionModel] = useState([])
//   const [filterModel, setFilterModel] = useState({ items: [] })
//   const { mutate: deleteItem, isLoading } = useDeleteMutation()
//   const apiRef = useGridApiRef()
//   const [visibleRowCount, setVisibleRowCount] = useState(leads ? leads.length : 0)
//   const [rowCount, setRowCount] = useState(leads ? leads.length : 0)
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [drawerOpen, setDrawerOpen] = useState(false)
//   useEffect(() => {
//     if (leads && selectionModel.length === 1) {
//       const newLead = leads.find(lead => lead.lead_uuid === selectionModel[0])
//       setSelectedLeads(newLead)
//       // setMassUpdateData({ vertical: [], type: '' })
//     } else {
//       setSelectedLeads(null)
//     }
//   }, [selectionModel, leads])

//   const handleDeleteClick = event => {
//     setAnchorEl(event.currentTarget)
//   }

//   const handleColumnResizeStop = () => {
//     if (apiRef.current) {
//       apiRef.current.updateColumns()
//     }
//   }
//   const handleLeadClick = lead => {
//     console.log('Lead Clicked! lead: ', lead)
//     setLeadId(lead.lead_uuid)
//     setSelectedLead(lead)
//     setDrawerOpen(true)
//   }
//   const columns = renderLeadsColumns(handleLeadClick)

//   const open = Boolean(anchorEl)
//   const id = open ? 'simple-popover' : undefined
//   const handleSelectionModelChange = newSelectionModel => {
//     console.log('New Selection Model: ', newSelectionModel)
//     if (newSelectionModel.length !== selectionModel.length) {
//       setSelectionModel(newSelectionModel)
//       if (newSelectionModel.length === 0 && handleRowSelected) {
//         handleRowSelected(null)
//       } else {
//         const selectedRowData = leads.find(row => row.id === newSelectionModel[0])
//         if (handleRowSelected) {
//           handleRowSelected(selectedRowData)
//         }
//       }
//     }
//   }
//   const handleRowSelected = lead => {
//     if (selectionModel.length === 1) {
//       setSelectedLead(lead)
//     }
//     setSelectedLeads(lead)
//     console.log('SELECTED LEAD: ', selectedLead)
//   }

//   const handleConfirmDelete = async () => {
//     setIsDeleting(true)
//     console.log('handleConfirmDelete selectedLeads: ', selectedLeads)
//     if (selectedLeads) {
//       const params = {
//         endpoint: `/aws/delete/leads/${selectedLeads.lead_uuid}`,
//         table: 'leads',
//       }
//       deleteItem(params, {
//         onSuccess: message => {
//           setSelectedLeads(null)
//           showSnackbar(message, 'success')
//           setIsDeleting(false)
//         },
//         onError: error => {
//           showSnackbar(error.message, 'error')
//           setIsDeleting(false)
//         },
//       })
//     }
//   }
//   const handleClosePopover = () => {
//     setAnchorEl(null)
//     if (selectionModel.length > 0) {
//       selectionModel.forEach(id => {
//         apiRef.current.selectRow(id, false)
//       })
//       setSelectionModel([])
//       setSelectedLeads(null)
//     }
//   }

//   useEffect(() => {
//     const handleFilterModelChange = () => {
//       const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
//       const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
//       setVisibleRowCount(filteredRowsCount)
//     }

//     const api = apiRef.current
//     const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

//     // Initialize the visible row count when the component mounts
//     handleFilterModelChange()

//     return () => {
//       unsubscribe()
//     }
//   }, [apiRef])

//   const totalRowCount = leads ? leads.length : 0

//   return (
//     <>
//       <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
//         <DataGridPro
//           initialState={{
//             sorting: {
//               sortModel: [{ field: 'submittedDate', sort: 'desc' }],
//               sortModel: [{ field: 'submittedTime', sort: 'desc' }],
//               sortModel: [{ field: 'name', sort: 'asc' }],
//             },
//             columns: {
//               columnVisibilityModel: {
//                 keyTerm: false,
//                 ggLid: false,
//                 userId: false,
//                 sessionId: false,
//                 pageURL: false,
//               },
//             },
//           }}
//           rows={leads || []}
//           columns={columns}
//           rowHeight={52}
//           getRowId={row => row.lead_uuid || row.id}
//           checkboxSelection
//           disableRowSelectionOnClick
//           selectionModel={selectionModel}
//           onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
//           // onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
//           onRowCountChange={count => setRowCount(count)}
//           filterModel={filterModel}
//           onFilterModelChange={model => setFilterModel(model)}
//           apiRef={apiRef}
//           slots={{
//             toolbar: CustomGridToolbar,
//             footer: LeadsGridFooter,
//           }}
//           slotProps={{
//             toolbar: {
//               filterModel: filterModel,
//               setFilterModel: setFilterModel,
//               context: 'leads',
//             },
//             footer: {
//               selectionModel: selectionModel,
//               totalRowCount: totalRowCount,
//               visibleRowCount: visibleRowCount,
//               rowCount: rowCount,
//               leads: leads,
//               anchorEl: anchorEl,
//               handleDeleteClick: handleDeleteClick,
//               handleConfirmDelete: handleConfirmDelete,
//               handleClosePopover: handleClosePopover,
//               selectedData: selectedLeads,
//             },
//           }}
//           onColumnResizeStop={handleColumnResizeStop}
//           disableExtendRowFullWidth
//           loading={leadsLoading}
//         />
//         <LeadDrawer lead={selectedLead} open={drawerOpen} onClose={() => setDrawerOpen(false)} />
//       </Box>
//     </>
//   )
// }

// export default Leads
