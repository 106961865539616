import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Grid, useTheme, Box } from '@mui/material'
import {
  contractMap,
  generalInfoMap,
  totalsMap,
  ioTotalsMap,
  ioContractMap,
  csaTotalsMap,
  billingInfoMap,
} from './dataMaps'
import DetailsLoading from '../Loading/DetailsLoading'
import GenerateTable from './GenerateTable'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'
import { useActiveUser } from '../../api/slack/useActiveUser'
import RenderApprover from '../Approvals/RenderApprover'
import { useApproveDeal } from '../../api/approvals/useApproveDeal'
import { tableSectionStyles } from './style'
import GenerateInfoTables from './GenerateInfoTables'
import { useRejectDeal } from '../../api/approvals/useRejectDeal'
import { useResolveDeal } from '../../api/approvals/useResolveDeal'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import { updateDealTimelineData } from '../Timeline/createTimelineData'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { useContact } from '../../api/aws/useContact'
import { useUser } from '../../api/aws/useUser'
import { useApproversById } from '../../api/customHooks/useApproversById'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
import DrawerLoading from '../Loading/DrawerLoading.js'

const ReviewDeal = ({ moduleLocation, isHeaderExpanded }) => {
  const { dealId } = useParams()
  const { pinned } = useLayoutContext()
  const location = useLocation()
  const { newDeal, setNewDeal, productRows, timelineData } = useDealDetailsContext()
  const { mutate: approveDeal } = useApproveDeal()
  const { mutate: rejectDeal } = useRejectDeal()
  const { mutate: resolveDeal } = useResolveDeal()
  const [expanded, setExpanded] = useState(moduleLocation === 'boardsDrawer' ? true : false)
  const {
    activeUser: { user: activeUser },
  } = useActiveUser()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const [isApproving, setIsApproving] = useState(false)
  const [rejectDialogs, setRejectDialogs] = useState({})
  const [resolveDialogs, setResolveDialogs] = useState({})
  const [rejectText, setRejectText] = useState('')
  const [resolveText, setResolveText] = useState('')
  const [isRejecting, setIsRejecting] = useState(false)
  const [isResolving, setIsResolving] = useState(false)
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : ''
  const { organization, isOrganizationLoading } = useOrganizationById(orgId)
  const { data: billingContact, isLoading: isBillingContactLoading } = useContact(organization?.billingContact?.[0], [
    'billingContact',
    organization?.billingContact?.[0],
  ])
  const { organization: contractedBy, isOrganizationLoading: isContractedByLoading } = useOrganizationById(
    organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : []
  )
  const { user: createdBy, isLoading: isCreatedByLoading } = useUser(newDeal?.createdBy, 'createdBy')
  const approverIds = newDeal?.approvers?.map(approver => approver?.id)
  const { approversById, isApproversByIdLoading } = useApproversById(approverIds)
  const theme = useTheme()
  const adminIds = ['U062JDP8R5X', 'UHKAA49CJ']
  const { allSame } =
    newDeal && Array.isArray(newDeal.products) ? calculateTotalMonthsForProducts(newDeal.products) : { totalMonths: 1 }

  useEffect(() => {
    if (timelineData) {
      console.log('timelineData', timelineData)
    }
  }, [timelineData])

  useEffect(() => {
    if (organization) {
      console.log('[Review Deal] organization', organization)
    }
  }, [organization])

  const handleApprove = approverId => {
    if (!dealId || !activeUser) {
      console.error('Missing dealId or activeUser for approval')
      return
    }

    const timelineData = updateDealTimelineData(newDeal?.deal_uuid, activeUser, 'Approve', 'Approval', 'Deal approved')
    try {
      approveDeal(
        {
          dealId,
          approverId,
          requestor: { ...newDeal?.requestor },
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => ({
              ...prevDeal,
              approvers: prevDeal.approvers.map(approver =>
                approver.id === approverId
                  ? {
                      ...approver,
                      hasApproved: true,
                      hasRejected: false,
                      timeApproved: new Date().toISOString(),
                    }
                  : approver
              ),
            }))
            createTimelineEntry(timelineData)
          },
          onError: e => {
            console.error(e)
          },
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleRejectTextOpen = approverId => {
    setRejectDialogs(prev => ({ ...prev, [approverId]: true }))
  }
  const handleRejectTextClose = approverId => {
    setRejectDialogs(prev => ({ ...prev, [approverId]: false }))
  }
  const handleResolveTextOpen = approverId => {
    setResolveDialogs(prev => ({ ...prev, [approverId]: true }))
  }
  const handleResolveTextClose = approverId => {
    setResolveDialogs(prev => ({ ...prev, [approverId]: false }))
  }
  const handleReject = approverId => {
    if (!dealId || !activeUser) return
    const timelineData = updateDealTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Reject',
      'Approval',
      'Deal rejected',
      rejectText
    )
    try {
      rejectDeal(
        {
          dealId,
          requestor: { ...newDeal?.requestor },
          approverId,
          reason: rejectText,
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => {
              const updatedApprovers = prevDeal.approvers.map(approver =>
                approver.id === approverId || adminIds.includes(activeUser.id)
                  ? {
                      ...approver,
                      hasRejected: true,
                      reason: rejectText,
                      timeRejected: new Date().toISOString(),
                    }
                  : approver
              )
              return {
                ...prevDeal,
                status: 'Rejected',
                approvers: updatedApprovers,
              }
            })
            createTimelineEntry(timelineData)
          },
          onError: e => console.log(e),
        }
      )
      handleRejectTextClose(approverId)
    } catch (error) {
      console.error(error)
    }
  }
  const handleResolve = (requestorId, approverId) => {
    if (!dealId || !activeUser) return
    const timelineData = updateDealTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Resolve',
      'Approval',
      'Deal resolved',
      resolveText
    )
    try {
      resolveDeal(
        {
          dealId,
          requestor: { ...newDeal?.requestor, resolution: resolveText },
          approverId,
          resolution: resolveText,
        },
        {
          onSuccess: data => {
            setNewDeal(prevDeal => {
              const updatedRequestor = {
                ...prevDeal?.requestor,
                hasResolved: true,
                resolution: resolveText,
              }
              const updatedApprovers = prevDeal.approvers.map(approver =>
                approver.id === approverId || adminIds.includes(approverId)
                  ? {
                      ...approver,
                      hasResolved: true,
                      hasRejected: false,
                      resolution: resolveText,
                      timeResolved: new Date().toISOString(),
                    }
                  : approver
              )
              return {
                ...prevDeal,
                status: 'Resolved',
                approvers: updatedApprovers,
                requestor: updatedRequestor,
                isResolved: true,
              }
            })
            createTimelineEntry(timelineData)
          },
          onError: e => console.log(e),
        }
      )
      handleResolveTextClose()
    } catch (error) {
      console.error(error)
    }
  }

  // Use your moduleHeight (or similar) to control overall container height.
  const moduleHeight =
    pinned && !isHeaderExpanded
      ? `calc(80vh - 55px)`
      : !pinned && isHeaderExpanded
      ? `calc(80vh - 135px)`
      : pinned && isHeaderExpanded
      ? `calc(80vh - 185px)`
      : '100vh'
  const detailsHeight = expanded ? 'calc(100vh - 25%)' : 'calc(100vh - 25%)'
  if (isOrganizationLoading || isContractedByLoading || isBillingContactLoading || isCreatedByLoading) {
    return moduleLocation === 'boardsDrawer' ? <DrawerLoading /> : <DetailsLoading />
  }

  return (
    <>
      <Grid
        container
        sx={{
          height: moduleLocation === 'boardsDrawer' ? moduleHeight : detailsHeight,
          overflow: 'auto',
          transition: 'height 0.3s ease',
        }}
      >
        {/* Additional Information Row */}
        <Grid item xs={12}>
          <ExpandCollapseButton expanded={expanded} setExpanded={setExpanded} displayElement='Additional Information'>
            {expanded && (
              <Box sx={{ height: 350, overflowY: 'auto' }}>
                <Grid container spacing={2}>
                  {/* Approvers */}
                  <Grid item xs={12} sm={6} md={3}>
                    {newDeal?.approvers && newDeal?.approvers.length > 0 ? (
                      newDeal.approvers.map(approver => (
                        <RenderApprover
                          key={approver.id}
                          activeUser={activeUser}
                          approver={approver}
                          requestor={newDeal?.requestor}
                          isApproving={isApproving}
                          onApprove={() => handleApprove(approver.id)}
                          rejectText={rejectText}
                          setRejectText={setRejectText}
                          isRejecting={isRejecting}
                          handleRejectTextOpen={() => handleRejectTextOpen(approver.id)}
                          handleRejectTextClose={() => handleRejectTextClose(approver.id)}
                          isRejectTextOpen={!!rejectDialogs[approver.id]}
                          handleReject={() => handleReject(approver.id)}
                          resolveText={resolveText}
                          setResolveText={setResolveText}
                          isResolving={isResolving}
                          isResolveTextOpen={!!resolveDialogs[approver.id]}
                          handleResolveTextOpen={() => handleResolveTextOpen(approver.id)}
                          handleResolveTextClose={() => handleResolveTextClose(approver.id)}
                          handleResolve={handleResolve}
                          requestedTime={newDeal.requestedTime || ''}
                          isResolved={newDeal?.isResolved || false}
                          timeResolved={newDeal?.timeResolved}
                          resolution={newDeal?.resolution || null}
                        />
                      ))
                    ) : (
                      <Box
                        sx={{
                          flexGrow: 1,
                          border:
                            theme.palette.mode === 'dark'
                              ? '1px solid rgba(224, 224, 224, 0.4)'
                              : '1px solid rgba(0,0,0,0.4)',
                          borderRadius: '25px',
                          backgroundImage: theme.palette.background.image,
                          backgroundSize: '10px 10px',
                          minHeight: '330px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    )}
                  </Grid>
                  {/* General Info */}
                  <Grid item xs={12} sm={6} md={3}>
                    <GenerateInfoTables options={generalInfoMap(organization)} />
                  </Grid>
                  {/* Totals Info */}
                  <Grid item xs={12} sm={6} md={3}>
                    <GenerateInfoTables
                      options={
                        newDeal && newDeal.type === 'IO'
                          ? ioTotalsMap(newDeal, totals, allSame)
                          : ['CSA', 'RCSA'].includes(newDeal.type)
                          ? csaTotalsMap(newDeal, totals)
                          : totalsMap(newDeal, totals, allSame)
                      }
                    />
                  </Grid>
                  {/* Billing Info */}
                  <Grid item xs={12} sm={6} md={3}>
                    <GenerateInfoTables options={billingInfoMap(newDeal, contractedBy, createdBy, billingContact)} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </ExpandCollapseButton>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <GenerateTable
              options={
                newDeal && newDeal.type === 'IO' ? ioContractMap(productRows, theme) : contractMap(productRows, theme)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Products Table Row */}
    </>
  )
}

export default ReviewDeal
