import React from 'react'
import { Autocomplete, Box, Chip, Grid, List, ListItem, TextField, Typography, useTheme } from '@mui/material'

const TaskSelector = ({ checklistItems, onTaskSelected, selectedTask }) => {
  const theme = useTheme()
  const processedChecklistItems = checklistItems?.map(item => {
    return item?.SK?.startsWith('OPTIMIZATION')
      ? { ...item, identifier: 'Optimization', idColor: 'rgb(65, 190, 169)' }
      : item?.role === 'AM'
      ? { ...item, identifier: 'AM Task', idColor: 'rgb(251, 193, 30)' }
      : item?.role === 'DMS'
      ? { ...item, identifier: 'DMS Task', idColor: 'rgb(251, 193, 30)' }
      : { ...item, identifier: 'Task', idColor: 'rgb(231, 102, 223)' }
  })
  return (
    <Autocomplete
      value={selectedTask}
      options={processedChecklistItems}
      getOptionLabel={option => option.name || option.title || ''}
      onChange={(event, newValue) => {
        onTaskSelected(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          label='Select Task'
          variant='outlined'
          sx={{
            '& .MuiInputBase-input': { color: theme.palette.text.primary },
            '& .MuiInputLabel-root': { color: theme.palette.text.primary },
            '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.text.primary },
          }}
        />
      )}
      renderOption={(props, option, { index }) => (
        <List
          {...props}
          key={`${option?.title}-${index}-${option?.identifier}` || `${option.title}-${index}` || option.id}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Grid item xs={7}>
              {option.name || option.title}
            </Grid>
            {option?.identifier && (
              <Grid item xs={4} sx={{ color: option?.idColor, fontSize: 14 }}>
                {option?.identifier}
              </Grid>
            )}
            {option?.comments?.length > 0 ? (
              <Grid item xs={1}>
                <Chip label={option.comments.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
              </Grid>
            ) : (
              <Grid item xs={1} />
            )}
          </Grid>
        </List>
      )}
    />
  )
}

export default TaskSelector
