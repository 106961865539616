import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Token from './authentication/Token'
import AuthCallback from './authentication/AuthCallback'
import ProtectedRoute from './authentication/ProtectedRoute'
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import ProtectedApp from './ProtectedApp'
import AuthProvider from './authentication/AuthContext'
import { LayoutProvider } from './context/LayoutProvider/LayoutProvider'
import { Grid } from '@mui/material'
// import QueryDataDebugger from './api/queryFunctions/QueryDataDebugger'

const Main = () => {
  console.log('Main component mounted')
  return (
    <AuthProvider>
      <Router>
        {/* <QueryDataDebugger /> */}
        <Routes>
          <Route path='/' element={<Token />} />
          <Route path='/callback' element={<AuthCallback />} />
          <Route
            path='/*'
            element={
              <ProtectedRoute>
                <LayoutProvider>
                  <ProtectedApp />
                </LayoutProvider>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default Main
