import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { seoTasks } from '../DataGrid/taskTemplates.js'
import { seoStatusOptions } from '../DataGrid/filterArrays.js'
import { useTheme } from '@mui/material'
import renderSeoColumns from '../DataGrid/createColumns/renderSeoColumns.js'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  strategy: false,
  id: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  dependenciesDueDate: false,
  dateLaunched: false,
  DMS: false,
  MIA: false,
  QA: false,
  contractedBy: false,
}

const SeoPage = () => {
  const { dealId } = useParams()
  const theme = useTheme()

  const filters = {
    status: ['Completed', 'Completed Internally'],
    type: 'MSA',
    productCategories: ['SEO'],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  return (
    <GroboticDataGrid
      dealId={dealId}
      filters={filters}
      queryKey={['seoPage', 'deals']}
      route={'seo'}
      tasks={seoTasks}
      context={'seo'}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      filterOptions={seoStatusOptions}
      boardTheme={theme.palette.icon.seo}
      renderFunction={renderSeoColumns}
    />
  )
}
export default SeoPage
