import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Box,
  FormControl,
  Tooltip,
  useTheme,
  Modal,
  Button,
  Autocomplete,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { optimizations, Optimization } from './types.ts'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { formatTooltipContent } from './formatTooltipContent.tsx'
import DynamicSection from './DynamicSection.tsx'
import FormTypeSelect from '../Forms/FormTypeSelect.tsx'
import { mtbOptimizations } from '../MTB/types.ts'

interface OptimizationDetails {
  optimization: Optimization | null
  addtlNotes: string
  opts: number
  followDate: string | null
  selectedType: string
}
interface OptimizationsModalProps {
  open: boolean
  onSave: (data: OptimizationDetails) => void
  onClose: () => void
  organizationName: string
  selectedType: string
  context: string
}

const OptimizationsModal: React.FC<OptimizationsModalProps> = ({
  open,
  onSave,
  onClose,
  organizationName,
  context,
}) => {
  const theme = useTheme()
  const [optimizationDetails, setOptimizationDetails] = useState<OptimizationDetails>({
    optimization: null,
    addtlNotes: '',
    opts: 0,
    followDate: null,
    selectedType: '',
  })
  const [localOpts, setLocalOpts] = useState(String(optimizationDetails.opts))
  // Local state for selectedType in the form
  const [localSelectedType, setLocalSelectedType] = useState('')

  // Sync localOpts with optimizationDetails
  useEffect(() => {
    setLocalOpts(String(optimizationDetails.opts))
  }, [optimizationDetails.opts])

  // When modal closes, reset the form.
  useEffect(() => {
    if (!open) {
      setOptimizationDetails({
        optimization: null,
        addtlNotes: '',
        opts: 0,
        followDate: null,
        selectedType: '',
      })
      setLocalSelectedType('')
    }
  }, [open])

  // Sync local selected type with optimizationDetails.selectedType
  useEffect(() => {
    setLocalSelectedType(optimizationDetails.selectedType)
  }, [optimizationDetails.selectedType])

  // When an optimization is selected from the autocomplete
  const handleSelect = (_: any, value: Optimization | null) => {
    setOptimizationDetails({
      optimization: value,
      addtlNotes: '',
      opts: 0,
      followDate: null,
      selectedType: localSelectedType, // keep the current selected type
    })
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setOptimizationDetails(prev => ({ ...prev, followDate: value }))
  }

  // Handle change for the "Notes" field
  const handleChangeNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setOptimizationDetails(prev => ({ ...prev, addtlNotes: value }))
  }

  // When the type changes, refresh the form by resetting the optimization.
  const handleTypeChange = (value: string) => {
    if (value !== optimizationDetails.selectedType) {
      setLocalSelectedType(value)
      // Reset the optimization to null and clear the selected type value
      setOptimizationDetails(prev => ({ ...prev, optimization: null, selectedType: '' }))
    }
  }

  const handleChangeOpts = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (value === '') {
      setLocalOpts('')
      return
    }
    if (!/^\d+$/.test(value)) return
    let numValue = parseInt(value, 10)
    if (numValue < 0) numValue = 0
    if (numValue > 10) numValue = 10
    setLocalOpts(String(numValue))
  }

  const handleBlurOpts = () => {
    const numValue = localOpts === '' ? 0 : parseInt(localOpts, 10)
    setLocalOpts(String(numValue))
    setOptimizationDetails(prev => ({
      ...prev,
      opts: numValue,
      optimization: prev.optimization ? { ...prev.optimization, opts: numValue } : null,
    }))
  }

  const handleSave = () => {
    console.log('optimizationDetails', optimizationDetails)
    onSave(optimizationDetails)
    setOptimizationDetails({
      optimization: null,
      addtlNotes: '',
      opts: 0,
      followDate: null,
      selectedType: '',
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
            padding: 4,
            width: '70%',
            maxWidth: 700,
            margin: 'auto',
            marginLeft: '10%',
            marginTop: '5%',
            borderRadius: 1,
            boxShadow: 24,
            spacing: 1,
          }}
        >
          <Grid item xs={12}>
            <TextField
              label='Organization'
              value={organizationName ?? ''}
              fullWidth
              margin='normal'
              InputProps={{ readOnly: true }}
              sx={{
                '& .MuiInputBase-input': {
                  color: theme.palette.text.highlight,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTypeSelect
              key={`seo-select-type`}
              options={[
                { label: 'SEO', value: 'seo' },
                { label: 'Digital', value: 'digital' },
              ]}
              value={localSelectedType}
              onChange={handleTypeChange}
            />
          </Grid>
          {localSelectedType === '' ? null : (
            <Grid item xs={12} pt={1}>
              <Autocomplete
                options={localSelectedType === 'seo' ? optimizations : mtbOptimizations}
                getOptionLabel={option => option.name}
                onChange={handleSelect}
                sx={{
                  width: '100%',
                }}
                renderOption={(props, option) => {
                  const { key, ...rest } = props
                  return (
                    <li key={`${option.name}-${option.key}-${option.channel}-${option.module}`} {...rest}>
                      <Box sx={{ display: 'flex', width: '100%' }}>
                        <Typography variant='body1' sx={{ flex: 1, textAlign: 'left' }}>
                          {option.name}
                        </Typography>
                        <Typography variant='body2' sx={{ flex: 1, textAlign: 'center', color: option.color }}>
                          {option.type}
                        </Typography>
                        {option.tooltip && (
                          <Box sx={{ flex: 1, textAlign: 'right' }}>
                            <Tooltip
                              leaveDelay={200}
                              title={
                                <Box
                                  sx={{
                                    width: '600px',
                                    maxWidth: '600px',
                                    minWidth: '500px',
                                    textAlign: 'left',
                                    whiteSpace: 'normal',
                                    padding: '12px',
                                    backgroundColor: 'rgba(20, 20, 20, 0.95)',
                                    color: '#ffffff',
                                    borderRadius: '6px',
                                    boxShadow: '0px 4px 12px rgba(0,0,0,0.3)',
                                  }}
                                >
                                  {formatTooltipContent(option.tooltip)}
                                </Box>
                              }
                              placement='right'
                              arrow
                            >
                              <QuestionMarkIcon />
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </li>
                  )
                }}
                renderInput={params => (
                  <TextField {...params} label='Select Optimization Task' variant='outlined' fullWidth />
                )}
              />
            </Grid>
          )}

          {optimizationDetails.optimization && (
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>Selected Task Details</Typography>
              </Grid>
              <DynamicSection
                key={'dynamic-section'}
                optimizationDetails={optimizationDetails}
                onDateChange={handleDateChange}
                onChangeOpts={handleChangeOpts}
                onBlurOpts={handleBlurOpts}
                localOpts={localOpts}
                selectedType={localSelectedType}
              />
              <Grid item xs={12}>
                <TextField
                  label='Description'
                  value={optimizationDetails.optimization?.description || ''}
                  fullWidth
                  margin='normal'
                  multiline
                  rows={4}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='Notes'
                  value={optimizationDetails.addtlNotes}
                  onChange={handleChangeNotes}
                  fullWidth
                  margin='normal'
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          )}

          <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end', width: '100%', pt: 1 }}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Grid>
      </div>
    </Modal>
  )
}

export default OptimizationsModal
