import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Avatar, Box, AvatarGroup, useTheme, Tooltip, Badge, Typography } from '@mui/material'
import { renderTeamOption, renderUserOption } from '../../Users/renderOptions'
import { User } from '../../Forms/types'

interface OnboardTeamAutocompleteProps {
  users: User[]
  role: string
  label: string
  onChange: (role: string, selectedUsers: User[]) => void
  defaultValue?: User[]
  isUpdating?: boolean
  error: boolean
  disabled: boolean
  isLoading: boolean
  maxSize: number
}

const OnboardTeamAutocomplete: React.FC<OnboardTeamAutocompleteProps> = ({
  users,
  role,
  label,
  onChange,
  defaultValue = [],
  isUpdating,
  error,
  disabled,
  isLoading,
  maxSize,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>(defaultValue || [])
  const theme = useTheme()

  useEffect(() => {
    setSelectedUsers(defaultValue)
  }, [defaultValue])

  const handleSelectUser = (_event: React.ChangeEvent<{}>, newValue: User[]) => {
    setSelectedUsers(newValue)
    onChange(role, newValue)
  }

  return (
    <Autocomplete
      multiple
      options={users || []}
      getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`}
      isOptionEqualToValue={(option, value) => (option?.user_uuid || option?.id) === value.user_uuid} // Ensure proper value matching
      // value={selectedUsers}
      value={selectedUsers?.filter(user => users?.some(u => u.user_uuid === user.user_uuid))} // ✅ Remove invalid users
      onChange={handleSelectUser}
      filterOptions={(options, { inputValue }) => {
        const searchTerm = inputValue.toLowerCase()

        return options?.filter(
          option =>
            option?.name?.toLowerCase().includes(searchTerm) ||
            (option.role && option.role.toLowerCase().includes(searchTerm))
        )
      }}
      renderOption={(props, option, state) => renderTeamOption(props, option, state, theme)}
      renderTags={(tagValue, getTagProps) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AvatarGroup max={maxSize ?? 4} spacing={2}>
            {tagValue.map((option, index) => {
              const { key, onDelete, ...rest } = getTagProps({ index })
              return (
                <Box key={`${option.user_uuid}-${index}`} {...rest} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Badge
                    badgeContent={
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: 'rgba(63, 195, 128, 1)',
                          fontWeight: 'bold',
                        }}
                      >
                        {option?.role}
                      </Typography>
                    }
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Avatar alt={option.name} src={option.image_512} sx={{ height: 36, width: 36 }} />
                  </Badge>
                </Box>
              )
            })}
          </AvatarGroup>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          sx={{
            maxHeight: '60px',
            '& .MuiAutocomplete-inputRoot': {
              maxHeight: '60px',
              height: '60px',
            },
            '& .MuiInputBase-root': {
              padding: '8px 12px',
              bgcolor: disabled ? theme.palette.background.disabled : '',
            },
            '& .MuiAutocomplete-endAdornment': {
              display: disabled ? 'none' : '',
            },
          }}
          error={error}
          disabled={disabled}
        />
      )}
      fullWidth
      sx={{ minHeight: '60px' }}
      disabled={disabled}
      loading={isLoading || false}
    />
  )
}

export default OnboardTeamAutocomplete
