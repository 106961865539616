import { Tooltip } from '@mui/material'
import GoogleDriveSvg from '../../images/googleDriveIcon.svg'

const ChannelIcon = ({ isContracted, title, src, opacity }) => {
  return (
    <Tooltip title={title ?? ''}>
      <img
        key={`channel-icon-key-${title ?? ''}`}
        src={src ?? GoogleDriveSvg}
        alt={'channel-icon-alt'}
        style={{
          width: 26,
          height: 26,
          opacity: isContracted && opacity ? opacity : 0.1, // Dynamic opacity based on checklist progress
          transition: 'opacity 0.3s ease-in-out', // Smooth transition effect
        }}
      />
    </Tooltip>
  )
}

export default ChannelIcon
