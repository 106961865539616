export const mtbInitialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  products: false,
  contractedBy: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  seoTier: false,
  creativeLead: false,
  dealStatus: false,
  AM: false,
  DMS: false,
  SEO: false,
  MIA: false,
  QA: false,
  PM: false,
  lastModified: false,
  totalQualifiedOpts: false,
  dependenciesDueDate: false,
  opts: false,
  dateLaunched: false,
  estLaunchDate: false,
  seoFormCounts: false,
  completedCounts: false,
  optsPercentage: false,
  seoGmb: false,
  seoStatus: false,
}
export const seoViewState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  products: false,
  contractedBy: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  seoTier: false,
  creativeLead: false,
  dealStatus: false,
  AM: false,
  DMS: false,
  SEO: false,
  MIA: false,
  QA: false,
  PM: false,
  lastModified: false,
  totalQualifiedOpts: false,
  dependenciesDueDate: false,
  qualifyingTier: false,
  dateLaunched: false,
  estLaunchDate: false,
  amProgress: false,
  dmsProgress: false,
}
