import React, { useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  Link as MuiLink,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material'
import { InfoRow, InfoLabel, InfoValueWrapper, InfoValue, StyledLink } from './style'
import UserImageComponent from '../Users/UserImageComponent'
import { useUsersById } from '../../api/customHooks/useUsersById'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import GroboticDark from '../../images/Grobotic png.png'
import { formatNumber } from '../DealsKanban/aggregateStageTotals'
import RenderDealInfoLink from './RenderDealInfoLink'
import PandaDocLinkIcon from '../Icons/PandaDockLinkIcon'
import { DisabledIconButton } from '../DataGrid/style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { calculateDayjsTotalMonths } from '../../utility/calculateTotalMonths'
import useTotals from '../../api/customHooks/useTotals'
import { renderColorByString } from '../DataGrid/renderColorByString'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import { useQueryClient } from '@tanstack/react-query'
import DealStatusSelect from '../DealStatusSelect/DealStatusSelect'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import DealStatusModal from './DealStatusModal'
import { calculateProgress } from '../Approvals/calculateProgress'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import SelectInvoiceOption from '../CreateDeal/SelectInvoiceOption'
import SelectAgreementOption from '../CreateDeal/SelectAgreementOption'
import RenderContactChip from './RenderContactChip'
import CustomOptionSelect from '../Reusable/CustomOptionSelect'
import { dealStageOptions } from '../DataGrid/filterArrays'
import { handleCopy } from './utils/handleCopy'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import { updateDealTimelineData } from '../Timeline/createTimelineData'
import { useActiveUser } from '../../api/slack/useActiveUser'

const DealInfoCard = ({ onSave, onEdit, newContacts }) => {
  const {
    newDeal,
    setNewDeal,
    productRows,
    timelineData = {},
    isDealStatusChange,
    setIsDealStatusChange,
    handleChange,
  } = useDealDetailsContext()
  const [validationErrors, setValidationErrors] = useState({
    organization: false,
    type: false,
    startDate: false,
    endDate: false,
  })
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()
  const { activeUser } = useActiveUser()
  const { mutate: updateItem } = useUpdateItem()
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const { mutate: saveDeal } = useSaveDeal()
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal.type)
  const orgId = newDeal && newDeal.organizations && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const userIds =
    newDeal && Array.isArray(newDeal.owner)
      ? newDeal.owner.map(id => id)
      : !Array.isArray(newDeal?.owner)
      ? [newDeal?.owner]
      : []
  const { users, isUsersByIdLoading, isUsersByIdError } = useUsersById(userIds)
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { organization: contractedBy } = useOrganizationById(organization?.contractedBy?.[0], [
    'deal',
    'contractedBy',
    organization?.contractedBy?.[0],
  ])
  const [showModal, setShowModal] = useState(false) // To control the modal visibility
  const [isModalSave, setIsModalSave] = useState(false)
  const [tempDealStatus, setTempDealStatus] = useState('')
  const { createMessagePayload, addNotification } = useNotify()
  const [isError, setIsError] = useState(false)
  const [invoiceOption, setInvoiceOption] = useState(newDeal?.invoiceOption ?? '')
  const [agreementOption, setAgreementOption] = useState(newDeal?.agreementOption ?? '')
  const [pipelineStage, setPipelineStage] = useState(newDeal?.stage ?? '')
  const [probability, setProbability] = useState(newDeal?.probability ?? 0)
  const [strategyLink, setStrategyLink] = useState(newDeal?.strategyLink ?? '')
  const [isContactCopied, setIsContactCopied] = useState(false)
  const [isAuthorizedCopied, setIsAuthorizedCopied] = useState(false)

  const onChange = (fieldName, value) => {
    handleChange(fieldName, value, organization)
  }
  const handleUpdateModal = (churnNote, lastDate, reasonChurn, newStatus) => {
    console.log('Last Date of Service:', lastDate)
    console.log('Churned Reason:', reasonChurn)
    console.log('Churned Notes:', churnNote)
    console.log('newStatus:', newStatus)
    if (!reasonChurn || !lastDate || !churnNote) {
      showSnackbar('All fields are required.', 'error')
      return
    }
    try {
      // Update deal data with formattedLastDate and newReason
      const updatedDeal = {
        ...newDeal,
        lastDateOfService: lastDate,
        reasonChurn: reasonChurn,
        churnNote: churnNote,
        dealStatus: newStatus,
      }
      setNewDeal(prev => ({
        ...prev,
        lastDateOfService: lastDate,
        reasonChurn: reasonChurn,
        churnNote: churnNote,
        dealStatus: newStatus,
      }))
      handleSave(updatedDeal, newStatus)
      // setShowModal(false)
    } catch (e) {
      console.error(e)
    }
  }
  const handleUpdate = async (fieldName, newValue) => {
    console.log('newValue: ', newValue)
    const timelineData = updateDealTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Update',
      'deals',
      `Updated`,
      `${fieldName} update to ${newValue}`
    )

    if (newDeal[fieldName] !== newValue) {
      updateItem(
        {
          primaryKey: 'deal_uuid',
          tableName: 'deals',
          itemId: newDeal?.deal_uuid,
          fieldName,
          newValue,
        },
        {
          onSuccess: () => {
            showSnackbar(`Deal ${fieldName} updated successfully`, 'success')
            console.log('Update successful')
            createTimelineEntry(timelineData)
            queryClient.invalidateQueries(['deal', newDeal?.deal_uuid])
          },
          onError: err => {
            console.error('Update failed', err)
          },
        }
      )
    }
  }

  const handleSave = (deal, newStatus) => {
    console.log('Product rows before saving:', productRows)

    const { progressPercentage, individualProgress } = calculateProgress(deal.approvers)
    console.log('has status changed????', isDealStatusChange)
    const dealData = isModalSave
      ? {
          ...deal,
          dealStatus: newStatus || deal?.dealStatus,
          lastModified: new Date(),
        }
      : {
          ...deal,
          dealStatus: newStatus || deal?.dealStatus,
          status: !deal.hasProducts ? 'Draft' : deal.hasProducts && deal.status === 'Draft' ? 'Saved' : deal.status,
          stage: !deal.hasProducts
            ? 'Discovery'
            : deal.hasProducts && deal.stage === 'Discovery'
            ? 'Proposal'
            : deal.stage,
          products: productRows, // Make sure this reflects the correct state
          // lastDateOfService: deal.lastDateOfService || lastDateOfService,
          // churnReason: deal.churnReason || churnReason,
          lastModified: new Date(),
          progress: individualProgress,
          progressPercentage,
        }
    console.log('dealData', dealData)
    if (isDealStatusChange) {
      try {
        const payload = createMessagePayload(
          Array.isArray(dealData?.owner) ? dealData?.owner : [dealData?.owner],
          'status',
          'success',
          'Deal status changed',
          `${dealData.name} changed to ${dealData?.dealStatus}`,
          'deals',
          dealData?.name,
          dealData?.deal_uuid,
          dealData?.image_url,
          `/deals/${dealData?.deal_uuid}/details`
        )
        console.log('DEAL STATUS CHANGED: ', payload)
        addNotification(payload)
      } catch (error) {
        console.error('error creating notification for status', error)
      }
    }
    saveDeal(
      {
        dealId: deal.deal_uuid,
        dealData: dealData,
      },
      {
        onSuccess: () => {
          if (timelineData) {
            createTimelineEntry(timelineData)
          }
          setNewDeal({ ...dealData })
          setIsDealStatusChange(false)
          setShowModal(false)
        },
        onError: () => {
          setIsDealStatusChange(false)
          setShowModal(false)
          setIsError(true)
        },
      }
    )
  }
  const handleObjectBlur = (context, newValue) => {
    if (JSON.stringify(newDeal[context]) !== JSON.stringify(newValue)) {
      handleUpdate(context, newValue)
      setNewDeal(prev => ({
        ...prev,
        [context]: newValue,
      }))
    }
  }
  const handleCancelModal = () => {
    setShowModal(false)
    setTempDealStatus('')
    setNewDeal(prev => ({
      ...prev,
      dealStatus: prev.dealStatus, // Revert to old status
    }))
  }
  const handleInvoiceOptionChange = async event => {
    const selectedInvoiceOption = event.target.value
    setInvoiceOption(selectedInvoiceOption)

    await handleUpdate('invoiceOption', selectedInvoiceOption)

    setNewDeal(prev => ({
      ...prev,
      invoiceOption: selectedInvoiceOption, // Correctly updating the state
    }))
  }

  const handleAgreementOptionChange = async event => {
    const selectedAgreementOption = event.target.value
    setAgreementOption(selectedAgreementOption)

    await handleUpdate('agreementOption', selectedAgreementOption)

    setNewDeal(prev => ({
      ...prev,
      agreementOption: selectedAgreementOption, // Correctly updating the state
    }))
  }
  const handlePipelineStageChange = async newValue => {
    setPipelineStage(newValue)
    await handleUpdate('stage', newValue)

    setNewDeal(prev => ({
      ...prev,
      stage: newValue, // Correctly updating the state
    }))
  }
  const handleFieldChange = (fieldName, newValue) => {
    console.log('[handleFieldChange] fieldName', fieldName, 'newValue', newValue)
    if (fieldName === 'probability') {
      setProbability(newValue)
    } else {
      setStrategyLink(newValue)
    }
  }
  // const handleFieldChange = (fieldName, newValue) => {
  //   console.log('[handleFieldChange] fieldName', fieldName, 'newValue', newValue)

  //   // Merge new values while keeping other state intact
  //   setNewDeal(prev => ({
  //     ...prev,
  //     [fieldName]: newValue,
  //   }))
  // }

  const handleDealStatusChange = async (fieldName, newValue) => {
    console.log('[handleFieldChange] fieldName', fieldName, 'newValue', newValue)
    await handleUpdate('dealStatus', newValue)
    setNewDeal(prev => ({
      ...prev,
      dealStatus: newValue, // Correctly updating the state
    }))
  }

  const handleBlur = event => {
    const { name, value } = event.target
    console.log('PREV VALUE: ', newDeal?.probability)
    console.log('[handleBlur] name:', name, 'value', value)
    if (newDeal[name] !== value) {
      console.log('(NEW VALUE DETECTED)updating after blur')
      setNewDeal(prev => ({
        ...prev,
        [name]: value,
      }))
      handleUpdate(name, value)
    }
  }
  const { allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const csaTypes = ['CSA', 'RCSA']
  return (
    <>
      {isOrganizationLoading && <DetailsLoading />}
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Typography variant='h5' sx={{ color: theme.palette.text.primary, width: '33.33%' }}>
            Information
          </Typography>
          <Typography variant='h5' sx={{ color: theme.palette.text.primary, width: '33.33%' }}>
            Communication
          </Typography>
          <Typography variant='h5' sx={{ color: theme.palette.text.primary, width: '33.33%' }}>
            Edit Details
          </Typography>
        </Box>
      </Grid>
      {/* <Grid container spacing={4}> */}

      <Grid
        item
        xs={3.9}
        sm={3.9}
        md={3.9}
        lg={3.9}
        xl={3.9}
        p={4}
        mb={1}
        sx={{
          bgcolor: theme.palette.background.section,
          borderRadius: '25px',
        }}
      >
        <InfoRow>
          <InfoLabel>Owner</InfoLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {users && Array.isArray(users) ? (
              users.map(user => (
                <Box key={user.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <UserImageComponent imageUrl={user.image_512} width={40} height={40} name={user.name} />
                  <Typography>{user.name}</Typography>
                </Box>
              ))
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img height={40} width={40} src={theme.palette.mode === 'dark' ? GroboticDark : GroboticLight} />
                <Typography>No Owner Assigned</Typography>
              </Box>
            )}
          </Box>
        </InfoRow>
        <InfoRow>
          <InfoLabel>Name</InfoLabel>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InfoValue>{newDeal.name}</InfoValue>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {newDeal.contractId ? (
                <MuiLink
                  href={`https://app.pandadoc.com/a/#/documents/${newDeal.contractId}`}
                  onClick={e => e.stopPropagation()}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconButton sx={{ padding: 0 }}>
                    <PandaDocLinkIcon size={36} />
                  </IconButton>
                </MuiLink>
              ) : (
                <DisabledIconButton sx={{ padding: 0 }} disabled>
                  <PandaDocLinkIcon size={36} />
                </DisabledIconButton>
              )}
            </Box>
          </Box>
        </InfoRow>

        <InfoRow>
          <InfoValueWrapper>
            <InfoLabel>Type</InfoLabel>
            {newDeal && newDeal.type ? (
              <InfoValue sx={{ color: renderColorByString(newDeal.type, theme) }}>{newDeal.type}</InfoValue>
            ) : (
              <InfoValue>No Type Assigned</InfoValue>
            )}
          </InfoValueWrapper>
        </InfoRow>
        <InfoRow>
          <InfoLabel sx={{ mb: 2 }}>Organization</InfoLabel>
          <InfoValueWrapper>
            <StyledLink key={orgId} to={`/organizations/${orgId}/details`}>
              <RenderDealInfoLink organization={organization} />
            </StyledLink>
          </InfoValueWrapper>
        </InfoRow>
        <InfoRow>
          <InfoLabel sx={{ mb: 2 }}>Contracted By</InfoLabel>
          {contractedBy ? (
            <StyledLink key={contractedBy.org_uuid} to={`/organizations/${contractedBy.org_uuid}/details`}>
              <RenderDealInfoLink organization={contractedBy} />
            </StyledLink>
          ) : (
            <Typography sx={{ color: 'rgba(211,211,211, 0.7)' }}>No Organization Assigned</Typography>
          )}
        </InfoRow>
      </Grid>
      <Grid
        item
        xs={3.9}
        sm={3.9}
        md={3.9}
        lg={3.9}
        xl={3.9}
        p={4}
        mb={1}
        sx={{
          bgcolor: theme.palette.background.section,
          borderRadius: '25px',
        }}
      >
        <Stack>
          <InfoLabel>Contacts</InfoLabel>
          {newContacts &&
            newContacts?.map(contact => {
              return (
                <Box key={contact?.contacts_uuid || contact?.id} py={0.5}>
                  <RenderContactChip
                    key={contact?.contacts_uuid || contact?.id}
                    contacts={[contact]}
                    justify={'flex-start'}
                    marginLeft={-1}
                  />
                </Box>
              )
            })}
        </Stack>

        <InfoRow>
          <InfoLabel>Authorized Recipient</InfoLabel>
          <InfoValueWrapper>
            {newDeal?.recipients?.authorizedRecipient?.map(ar => (
              <StyledLink key={ar.id} to={`/users/${ar.id}`}>
                {ar.email}
              </StyledLink>
            ))}
          </InfoValueWrapper>
        </InfoRow>

        <InfoRow>
          <InfoLabel>Authorized Link</InfoLabel>
          <Stack direction='row' alignItems='center' justifyContent={'space-between'} spacing={1}>
            {newDeal?.authorizedDocumentLink && newDeal?.linksGenerated ? (
              <>
                <StyledLink to={newDeal.authorizedDocumentLink} target='_blank' rel='noopener noreferrer'>
                  {isAuthorizedCopied ? 'Copied to clipboard' : 'Authorized Link'}
                </StyledLink>
                {isAuthorizedCopied ? (
                  <IconButton>
                    <CheckIcon sx={{ color: theme.palette.text.success }} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleCopy(newDeal?.authorizedDocumentLink, setIsAuthorizedCopied)}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    <ContentCopyIcon fontSize='medium' />
                  </IconButton>
                )}
              </>
            ) : (
              <InfoValue>—</InfoValue>
            )}
          </Stack>
        </InfoRow>

        <InfoRow>
          <InfoLabel>Contact Recipient</InfoLabel>
          <InfoValueWrapper>
            {newDeal?.recipients?.contactRecipient?.map(cr => (
              <StyledLink key={cr.id} to={`/contacts/${cr.id}`}>
                {cr.email}
              </StyledLink>
            ))}
          </InfoValueWrapper>
        </InfoRow>

        <InfoRow>
          <InfoLabel>Contact Link</InfoLabel>
          <Stack direction='row' alignItems='center' justifyContent={'space-between'} spacing={1}>
            {newDeal?.contactDocumentLink && newDeal?.linksGenerated ? (
              <>
                <StyledLink to={newDeal.contactDocumentLink} target='_blank' rel='noopener noreferrer'>
                  {isContactCopied ? 'Copied to clipboard' : 'Contact Link'}
                </StyledLink>
                {isContactCopied ? (
                  <IconButton>
                    <CheckIcon sx={{ color: theme.palette.text.success }} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleCopy(newDeal?.contactDocumentLink, setIsContactCopied)}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    <ContentCopyIcon fontSize='medium' />
                  </IconButton>
                )}
              </>
            ) : (
              <InfoValue>{'—'}</InfoValue>
            )}
          </Stack>
        </InfoRow>
      </Grid>

      <Grid
        item
        xs={3.9}
        sm={3.9}
        md={3.9}
        lg={3.9}
        xl={3.9}
        p={4}
        mb={1}
        sx={{
          bgcolor: theme.palette.background.section,
          borderRadius: '25px',
        }}
      >
        <InfoRow>
          <AssignOwnerAutocomplete
            userId={newDeal?.owner || []}
            label='Owner'
            onChange={onChange}
            context='owner'
            onBlur={handleObjectBlur}
            multiple={false} // Single select mode
          />
        </InfoRow>
        <InfoRow>
          {/* <InfoLabel>Deal Status</InfoLabel> */}
          <DealStatusSelect
            newDeal={newDeal}
            setNewDeal={setNewDeal}
            setIsDealStatusChange={setIsDealStatusChange}
            onCancel={handleCancelModal}
            onSaveModal={handleUpdateModal}
            onChange={handleDealStatusChange}
            setShowModal={setShowModal}
            setTempDealStatus={setTempDealStatus}
            label={true}
            setIsModalSave={setIsModalSave}
          />
        </InfoRow>
        <InfoRow>
          {/* <InfoLabel>Invoice Option</InfoLabel> */}
          <SelectInvoiceOption
            invoiceOption={invoiceOption}
            onChange={handleInvoiceOptionChange}
            validationErrors={validationErrors}
            // label={false}
          />
        </InfoRow>
        <InfoRow>
          {/* <InfoLabel>Agreement Option</InfoLabel> */}
          <SelectAgreementOption
            agreementOption={agreementOption}
            onChange={handleAgreementOptionChange}
            validationErrors={validationErrors}
            // label={false}
          />
        </InfoRow>
        <Box sx={{ width: '100%' }}>
          <CustomOptionSelect
            stateKey={'stage'}
            value={newDeal?.stage}
            options={dealStageOptions}
            displayLabel={'Pipeline Stage'}
            setterFn={handlePipelineStageChange}
            minWidth={'100%'}
          />
        </Box>
        <InfoRow>
          <TextField
            label='Probability'
            name='probability'
            value={probability ?? 0}
            onChange={e => handleFieldChange('probability', e.target.value)}
            onBlur={event => handleBlur(event)}
            fullWidth
            type='number'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
            inputProps={{ min: 0, max: 100 }}
          />
        </InfoRow>
        <InfoRow>
          <TextField
            label='Strategy Link'
            placeholder='Link to Strategy'
            name='strategyLink'
            value={strategyLink ?? ''}
            onChange={e => handleFieldChange('strategyLink', e.target.value)}
            onBlur={event => handleBlur(event)}
            fullWidth
            type='text'
          />
        </InfoRow>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        gap={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 3,
          borderTop: '1px solid rgba(255,255,255,0.1)',
        }}
      >
        {[
          { label: 'Gross Profit', value: totals.grossProfit },
          { label: 'Monthly Total', value: totals.monthlyTotal },
          { label: 'Total Gross Profit', value: totals.totalGrossProfit },
          { label: 'Total', value: totals.total },
        ].map((item, index) => (
          <Stack
            key={index}
            sx={{
              width: '100%',
              alignItems: 'flex-start', // Align text to left
              textAlign: 'left', // Ensure text is aligned inside the cell
              paddingLeft: 2, // Add left padding for better spacing
            }}
          >
            <InfoLabel sx={{ fontSize: 16 }}>{item.label}</InfoLabel>
            <InfoValueWrapper>
              <InfoValue sx={{ fontSize: 20 }}>{`$ ${newDeal?.totals ? formatNumber(item.value) : '0.00'}`}</InfoValue>
            </InfoValueWrapper>
          </Stack>
        ))}
      </Grid>

      {/* </Grid> */}
      {showModal && (
        <DealStatusModal
          isOpen={showModal}
          onClose={handleCancelModal}
          onSave={handleUpdateModal}
          tempDealStatus={tempDealStatus}
        />
      )}
    </>
  )
}

export default DealInfoCard
