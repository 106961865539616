import { Grid, Typography, Box, Button, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateOptimizationButton from '../ui/create-optimization-button.tsx'
import CreateOptimizationsModal from './CreateOptimizationsModal.tsx'
import { Insight, Observation, Optimization } from './types.ts'
import SavedOptimization from './SavedOptimization.tsx'
import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization.js'
import SaveIconButton from '../ui/save-icon-button.js'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { usePostOptimization } from '../../api/aws/optimizations/usePostOptimization.tsx'
import { useOptsByOrgId } from '../../api/aws/optimizations/useOptsByOrgId.ts'
import { useUpdateOptimization } from '../../api/aws/optimizations/useUpdateOptimization.ts'

interface OptimizationEntry extends Optimization {
  PK: string
  opt_uuid: string
  orgId: string
  addtlNotes: string
  opts: number
  followDate: string
  id: string
  createdAt: string
  type: string
}
interface InsightEntry extends Insight {
  createdAt: string
}
interface ObservationEntry extends Observation {
  createdAt: string
}

const OptimizationsContainer = ({ organization, isOrganizationLoading, context }) => {
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const [open, setOptOpen] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState(false)
  // const [optimizations, setOptimizations] = useState<OptimizationEntry[]>(organization?.optimizations || [])
  const { data: optimizations, isLoading: isOptimizationsLoading } = useOptsByOrgId(organization?.org_uuid)
  const [localOptimizations, setLocalOptimizations] = useState<OptimizationEntry[]>([])
  const [insights, setInsights] = useState<InsightEntry[]>(organization?.insights || [])
  const [onservations, setObservations] = useState<ObservationEntry[]>(organization?.observations || [])
  const { mutate: updateOrganization } = useUpdateOrganization()
  const { mutate: updateOptimization } = useUpdateOptimization()
  const { mutate: createOptimization } = usePostOptimization()
  // useEffect(() => {
  //   console.log('Updated Optimizations:', optimizations)
  // }, [optimizations])
  useEffect(() => {
    if (optimizations) {
      setLocalOptimizations(optimizations ?? [])
    }
  }, [optimizations])
  const handlePostOptimization = (data: {
    optimization: Optimization | null
    addtlNotes: string
    opts: number
    followDate: string
    type: string
  }) => {
    if (!data.optimization) return
    const newUuid = crypto.randomUUID()
    const newEntry: OptimizationEntry = {
      ...data.optimization,
      PK: `ORG_${organization?.org_uuid}`,
      orgId: organization?.org_uuid,
      addtlNotes: data.addtlNotes,
      opts: data.opts,
      followDate: data.followDate,
      id: newUuid,
      opt_uuid: newUuid,
      createdAt: new Date().toISOString(),
      type: data.type,
    }

    // Optionally, if you're relying on your fetch hook to get optimizations,
    // you might not need to update local state manually.
    // Instead, call the mutation to post the new optimization:
    createOptimization(newEntry, {
      onSuccess: () => {
        console.log('✅ Successfully created optimization')
        setIsCreating(false)
        setOptOpen(false)
        queryClient.invalidateQueries(['optsByOrgId', organization?.org_uuid])
      },
      onError: error => {
        console.error('❌ Error creating optimization:', error)
        setIsCreating(false)
      },
    })
  }

  // Update optimization fields (addtlNotes, opts) in state
  const handleUpdateOptimization = (index: number, field: keyof OptimizationEntry, value: string | number) => {
    setLocalOptimizations(prevOptimizations =>
      prevOptimizations.map((opt, i) => (i === index ? { ...opt, [field]: value } : opt))
    )
  }

  // Save updated optimizations to the organization
  // const handleSaveChanges = () => {
  //   updateOrganization(
  //     {
  //       orgData: { optimizations },
  //       orgId: organization?.org_uuid,
  //     } as any,
  //     {
  //       onSuccess: () => {
  //         console.log('Successfully updated organization')
  //         queryClient.invalidateQueries(['deal', dealId])
  //       },
  //       onError: e => console.error('Error updating organization', e),
  //     }
  //   )
  // }
  const handleSaveChanges = async (optIdentifiers: Optimization[], updatedData: Partial<Optimization>) => {
    console.log('optIdentifiers', optIdentifiers, 'updatedData', updatedData)
    if (!optIdentifiers.length) return

    // Assume updatedData includes PK and SK. Extract them:
    const { PK, SK, ...fieldsToUpdate } = updatedData

    // Validate that PK and SK exist, if not, fall back to optIdentifiers
    const finalPK = PK || optIdentifiers[0].PK
    const finalSK = SK || optIdentifiers[0].SK

    if (!finalPK || !finalSK) {
      console.warn('[handleSaveChanges] No valid keys provided.')
      return
    }

    const updatePayload = { optPK: finalPK, optSK: finalSK, updatedData: fieldsToUpdate }
    console.log('Data used for update (single update): ', updatePayload)

    updateOptimization(updatePayload, {
      onSuccess: () => {
        // Update local state AND react-query cache for immediate UI update
        setLocalOptimizations(prev =>
          prev.map(opt => (opt.PK === finalPK && opt.SK === finalSK ? { ...opt, ...fieldsToUpdate } : opt))
        )
        queryClient.setQueryData(['optsByOrgId', organization?.org_uuid], oldData => {
          if (!oldData) return oldData
          return oldData.map(opt => (opt.PK === finalPK && opt.SK === finalSK ? { ...opt, ...fieldsToUpdate } : opt))
        })

        // queryClient.invalidateQueries(['optsByOrgId', organization?.org_uuid])
      },
      onError: error => console.error('[handleSaveChanges] Single update failed', error),
    })
  }

  const handleClose = () => {
    setOptOpen(false)
    setLocalOptimizations(localOptimizations || [])
  }

  return (
    <Box sx={{ py: 2 }}>
      {isOrganizationLoading || isOptimizationsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs={6}>
            <CreateOptimizationButton onClick={() => setOptOpen(true)} isCreating={isCreating} disabled={false} />
          </Grid>
          <Grid
            container
            spacing={2}
            p={2}
            sx={{ maxHeight: '100vh', height: 'calc(100vh - 140px)', overflowY: 'auto' }}
          >
            {/* Save Changes Button */}

            <Grid item xs={12}>
              {localOptimizations?.length > 0 ? (
                localOptimizations.map((item, index) => (
                  <SavedOptimization
                    key={index}
                    optimization={item}
                    addtlNotes={item.addtlNotes}
                    onUpdate={(field, value) => handleUpdateOptimization(index, field, value)}
                    onSave={handleSaveChanges}
                    context={context}
                  />
                ))
              ) : (
                <Typography variant='body2'>No optimizations saved yet.</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <CreateOptimizationsModal
                open={open}
                onClose={handleClose}
                onSave={handlePostOptimization}
                organizationName={organization?.name || ''}
                context={context}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default OptimizationsContainer

/*
import { Grid, Typography, Box, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateOptimizationButton from '../ui/create-optimization-button.tsx'
import CreateOptimizationsModal from './CreateOptimizationsModal.tsx'
import { Optimization } from './types.ts'
import SavedOptimization from './SavedOptimization.tsx'
import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization.js'
import SaveIconButton from '../ui/save-icon-button.js'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

interface OptimizationEntry extends Optimization {
  addtlNotes: string
  opts: number
  followDate: string
  id: string
  createdAt: string
  type: string
}

const OptimizationsContainer = ({ organization, isOrganizationLoading, context }) => {
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const [open, setOptOpen] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState(false)
  const [optimizations, setOptimizations] = useState<OptimizationEntry[]>(organization?.optimizations || [])
  const { mutate: updateOrganization } = useUpdateOrganization()

  // useEffect(() => {
  //   console.log('Updated Optimizations:', optimizations)
  // }, [optimizations])

  // Save new optimization
  const handleSaveOptimization = (data: {
    optimization: Optimization | null
    addtlNotes: string
    opts: number
    followDate: string
    id: string
    createdAt: string
    type: string
  }) => {
    if (!data.optimization) return

    const newEntry: OptimizationEntry = {
      ...data.optimization,
      addtlNotes: data.addtlNotes,
      opts: data.opts,
      followDate: data.followDate,
      id: crypto.randomUUID(),
      createdAt: new Date().toISOString(),
      type: data.type,
    }

    const updatedOptimizations = [...optimizations, newEntry]

    setOptimizations(updatedOptimizations)

    updateOrganization(
      {
        orgData: { optimizations: updatedOptimizations },
        orgId: organization?.org_uuid,
      } as any,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['deal', dealId])
        },
        onError: e => {
          console.error('Error updating organization', e)
        },
      }
    )

    setOptOpen(false)
  }
  // Update optimization fields (addtlNotes, opts) in state
  const handleUpdateOptimization = (index: number, field: keyof OptimizationEntry, value: string | number) => {
    setOptimizations(prevOptimizations =>
      prevOptimizations.map((opt, i) => (i === index ? { ...opt, [field]: value } : opt))
    )
  }

  // Save updated optimizations to the organization
  const handleSaveChanges = () => {
    updateOrganization(
      {
        orgData: { optimizations },
        orgId: organization?.org_uuid,
      } as any,
      {
        onSuccess: () => {
          console.log('Successfully updated organization')
          queryClient.invalidateQueries(['deal', dealId])
        },
        onError: e => console.error('Error updating organization', e),
      }
    )
  }

  const handleClose = () => {
    setOptOpen(false)
    setOptimizations(organization?.optimizations || [])
  }

  const handleDeleteOptimization = (id: string) => {
    const updatedOptimizations = optimizations.filter(opt => opt.id !== id)

    setOptimizations(updatedOptimizations)

    updateOrganization(
      {
        orgData: { optimizations: updatedOptimizations },
        orgId: organization?.org_uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['deal', dealId])
        },
        onError: e => {
          console.error('Error updating organization', e)
        },
      }
    )
  }

  return (
    <Box sx={{ py: 2 }}>
      <Grid item xs={6}>
        <CreateOptimizationButton onClick={() => setOptOpen(true)} isCreating={isCreating} disabled={false} />
      </Grid>
      <Grid container spacing={2} p={2} sx={{ maxHeight: '100vh', height: 'calc(100vh - 140px)', overflowY: 'auto' }}>

        <Grid item xs={12}>
          {optimizations.length > 0 ? (
            optimizations.map((item, index) => (
              <SavedOptimization
                key={index}
                optimization={item}
                addtlNotes={item.addtlNotes}
                onUpdate={(field, value) => handleUpdateOptimization(index, field, value)}
                onSave={handleSaveChanges}
                onDelete={handleDeleteOptimization}
                context={context}
              />
            ))
          ) : (
            <Typography variant='body2'>No optimizations saved yet.</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <CreateOptimizationsModal
            open={open}
            onClose={handleClose}
            onSave={handleSaveOptimization}
            organizationName={organization?.name || ''}
            context={context}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default OptimizationsContainer






*/
