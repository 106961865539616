import React, { useEffect } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Grid, useMediaQuery, Tooltip } from '@mui/material'
import { useParams, Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import DealsDetailsHeader from './DealsDetailsHeader'
import { DealDetailsProvider } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import { useTimeline } from '../../api/aws/useTimeline'
import { useStrategy } from '../../api/aws/useStrategy'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import RenderErrorTooltip from '../Strategy/RenderErrorTooltip'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { DetailsContent } from '../../style/styleElements'
import { useDeals } from '../../api/aws/useDeals'
import { useOrganizations } from '../../api/aws/useOrganizations'

const DealPage = () => {
  const { dealId } = useParams()
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations(true, [
    'organizations',
    'dealPage',
  ])

  const { navOpen, pinned } = useLayoutContext()
  const { deal, isLoading, isError } = useDeal(dealId, ['dealPage', dealId])
  const { strategy, isLoading: isStrategyLoading, isError: isStrategyError } = useStrategy(dealId)
  const { strategyErrors, errorKeys, handleValidateStrategy, isStrategyValid } = useValidationContext()
  const { timeline, isLoading: timelineLoading, isError: timelineError } = useTimeline(dealId)
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = React.useState(0)
  const isMacbookPro = useMediaQuery(
    '(min-width: 1512px) and (max-width: 1512px) and (min-height: 857px) and (max-height: 857px)'
  )
  // const isMacbookPro = useMediaQuery(
  //   '(min-width: 1512px) and (max-width: 1650px) and (min-height: 850px) and (max-height: 860px)'
  // )
  useEffect(() => {
    if (deal && strategy && !isStrategyLoading) {
      handleValidateStrategy(strategy, deal)
    }
  }, [deal, strategy])

  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/agreement')) {
      setTabValue(1)
    } else if (location.pathname.includes('/tasks')) {
      setTabValue(2)
    } else if (location.pathname.includes('/services')) {
      setTabValue(3)
    } else if (location.pathname.includes('/strategy')) {
      setTabValue(4)
    } else if (location.pathname.includes('/paymentschedule')) {
      setTabValue(5)
    } else if (location.pathname.includes('/reviewdeal')) {
      setTabValue(6)
    } else if (location.pathname.includes('/invoicing')) {
      setTabValue(7)
    } else if (location.pathname.includes('/timeline')) {
      setTabValue(8)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (isError) {
    return <Typography>Error loading deal details</Typography>
  }
  return (
    <>
      {/* <Box sx={{ position: 'sticky', top: 0, zIndex: 1100 }}> */}
      <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
        <DealsDetailsHeader deal={deal} />
      </Box>
      {/* Render the search right below the header */}

      <Grid container>
        <Box
          sx={{
            width: '100%',
            maxWidth: '100vw', // Prevent overflow beyond viewport
            overflowX: 'auto', // Enable horizontal scrolling
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Tabs
              value={tabValue}
              variant='scrollable'
              scrollButtons={'auto'}
              allowScrollButtonsMobile
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
                width: '100%', // Ensure it stretches to the parent's width
                '& .MuiTabs-scrollButtons': {
                  border: `1px solid ${theme.palette.text.primary}`,
                  '&.Mui-disabled': {
                    opacity: 0.3,
                  },
                },
              }}
            >
              <Tab
                label={
                  <span>
                    <Typography noWrap>Details</Typography>
                  </span>
                }
                onClick={() => navigate(`/deals/${dealId}/details`)}
                component={null}
                to={`/deals/${dealId}/details`}
              />
              <Tab
                sx={{ pointerEvents: 'auto' }}
                label={<Typography noWrap>Agreement</Typography>}
                onClick={() => navigate(`/deals/${dealId}/agreement`)}
                component={null}
                to={`/deals/${dealId}/agreement`}
              />
              <Tab
                // label={<Typography noWrap>Tasks</Typography>}
                label={<Typography noWrap>Tasks</Typography>}
                onClick={() => navigate(`/deals/${dealId}/tasks`)}
                component={null}
                to={`/deals/${dealId}/tasks`}
                disabled
              />
              <Tab
                label={<Typography noWrap>Services</Typography>}
                onClick={() => navigate(`/deals/${dealId}/services`)}
                component={null}
                to={`/deals/${dealId}/services`}
                disabled
              />
              <Tab
                label={
                  errorKeys &&
                  errorKeys?.length > 0 &&
                  !deal?.isCompleted &&
                  deal?.status !== 'Completed Internally' ? (
                    <RenderErrorTooltip strategy={strategy} isStrategyValid={isStrategyValid} errorKeys={errorKeys} />
                  ) : (
                    <Typography noWrap>Strategy</Typography>
                  )
                }
                disabled={deal?.status === 'Completed Internally'}
                onClick={() => navigate(`/deals/${dealId}/strategy`)}
                component={null}
                to={`/deals/${dealId}/strategy`}
              />
              <Tab
                label={<Typography>Payment Schedule</Typography>}
                onClick={() => navigate(`/deals/${dealId}/paymentschedule`)}
                component={null}
                to={`/deals/${dealId}/paymentschedule`}
              />
              <Tab
                label={<Typography>Review Deal</Typography>}
                onClick={() => navigate(`/deals/${dealId}/reviewdeal`)}
                component={null}
                to={`/deals/${dealId}/reviewdeal`}
              />
              <Tab
                label={<Typography>Invoicing</Typography>}
                onClick={() => navigate(`/deals/${dealId}/invoicing`)}
                component={null}
                to={`/deals/${dealId}/invoicing`}
              />
              <Tab
                onClick={() => navigate(`/deals/${dealId}/timeline`)}
                label={<Typography sx={{ whiteSpace: 'nowrap' }}>Timeline</Typography>}
                component={null}
                to={`/deals/${dealId}/timeline`}
                disabled={!timeline || timeline?.length === 0}
              />
            </Tabs>
          </Grid>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          height: isMacbookPro ? 'calc(100vh - 115px)' : 'calc(100vh - 120px)', // Adjust this value based on header height
          // overflowY: isMacbookPro ? 'auto' : 'auto', // Allow scrolling if content overflows\
          overflowY: 'hidden',
          overflowX: 'auto',
          p: 0,
        }}
      >
        {isLoading || isStrategyLoading ? (
          <DetailsLoading />
        ) : (
          <Grid item xs={12}>
            <DealDetailsProvider dealData={deal}>
              {/* <DetailsContent open={navOpen} sx={{ marginTop: pinned ? '65px' : 0 }}> */}
              <DetailsContent open={navOpen}>
                <Outlet />
              </DetailsContent>
            </DealDetailsProvider>
          </Grid>
        )}
      </Grid>
      {/* </Box> */}
    </>
  )
}

export default DealPage
