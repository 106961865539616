import React from 'react'
import { Box, Grid, IconButton, Tooltip, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import GetAppIcon from '@mui/icons-material/GetApp'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DescriptionIcon from '@mui/icons-material/Description'

interface ThumbnailProps {
  imageUrls: string[]
  openFrame: (embedUrl: string) => void
  onDeleteFile: (url: string) => void
  handleDownload: (url: string) => void
}

// Helper function that returns either an <img> or an icon component based on the file type.
const getThumbnail = (url: string) => {
  if (/\.(jpe?g|png|gif)$/i.test(url)) {
    return (
      <Box
        component='img'
        src={url}
        alt='task-file'
        sx={{
          width: '100%',
          height: 75,
          borderRadius: 1,
          objectFit: 'cover',
          transition: 'transform 0.2s ease',
          //   '&:hover': {
          //     transform: 'scale(1.15)',
          //   },
        }}
      />
    )
  } else if (/\.pdf$/i.test(url)) {
    return <PictureAsPdfIcon sx={{ fontSize: 75, color: 'text.secondary' }} />
  } else if (/\.(doc|docx)$/i.test(url)) {
    return <DescriptionIcon sx={{ fontSize: 75, color: 'text.secondary' }} />
  } else {
    return <DescriptionIcon sx={{ fontSize: 75, color: 'text.secondary' }} />
  }
}

const PreviewTaskAttachments: React.FC<ThumbnailProps> = ({ imageUrls, openFrame, onDeleteFile, handleDownload }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        mt: 1,
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {imageUrls.map((url: string) => (
        <Box key={url} sx={{ flexGrow: 1, maxWidth: 100, position: 'relative' }}>
          <Tooltip title={url}>
            <Box
              onClick={() => openFrame(url)}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                '&:hover .overlay': { opacity: 0.7 },
                bgcolor: theme.palette.background.default,
              }}
            >
              {getThumbnail(url)}
              {/* Overlay that shows two icons in a row */}
              <Box
                className='overlay'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  opacity: 0,
                  transition: 'opacity 0.2s transform 0.2s',

                  backgroundColor: theme.palette.background.default,

                  //   borderTopLeftRadius: 4,
                  //   borderTopRightRadius: 4,
                }}
              >
                <IconButton
                  size='small'
                  onClick={e => {
                    e.stopPropagation()
                    onDeleteFile(url)
                  }}
                  sx={{
                    p: 0,
                    color: theme.palette.text.primary,
                    '&:hover': {
                      bgcolor: 'red',
                    },
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
                <IconButton
                  size='small'
                  onClick={e => {
                    e.stopPropagation()
                    handleDownload(url)
                  }}
                  sx={{
                    p: 0,
                    color: 'white',
                  }}
                >
                  <GetAppIcon fontSize='small' />
                </IconButton>
              </Box>
            </Box>
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}

export default PreviewTaskAttachments
