// ThemeProviderWrapper.js
import React, { useContext } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { lightTheme } from './lightTheme/lightTheme'
import { darkTheme } from './darkTheme/darkTheme'
import { ThemeContext } from './ThemeContext'
import { CssBaseline, GlobalStyles } from '@mui/material'

const ThemeProviderWrapper = ({ children }) => {
  const { theme } = useContext(ThemeContext)
  const currentTheme = theme === 'light' ? lightTheme : darkTheme
  const globalStyles = {
    // '*::-webkit-scrollbar': {
    //   width: '6px',
    //   height: '6px',
    // },
    // '*::-webkit-scrollbar-track': {
    //   background: currentTheme.palette.mode === 'dark' ? '#2c2f33' : '#f0f0f0',
    // },
    // '*::-webkit-scrollbar-thumb': {
    //   backgroundColor: currentTheme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
    //   borderRadius: '3px',
    // },
    // '*': {
    //   scrollbarWidth: 'thin',
    //   scrollbarColor: `${currentTheme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'} ${
    //     currentTheme.palette.mode === 'dark' ? '#2c2f33' : '#f0f0f0'
    //   }`,
    // },

    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow:
        currentTheme.palette.mode === 'dark'
          ? 'inset 0 0 6px rgba(0,0,0,0.3)'
          : 'inset 0 0 6px rgba(255,255,255,255.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: currentTheme.palette.mode === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
      borderRadius: '6px',
    },
  }
  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles styles={globalStyles} />
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeProviderWrapper
