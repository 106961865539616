import React from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import format from 'date-fns/format'
import { renderColorByString } from '../DataGrid/renderColorByString'
import { determineCreativeType } from '../Creative/determineCreativeType.tsx'
import RenderAssignees from './RenderAssignees.js'
import { handleFormatTypesString } from '../Forms/types.ts'

function formatDate(timestamp) {
  if (!timestamp) return
  return format(new Date(timestamp), 'MM/dd/yyyy')
}
const TaskDrawerHeader = ({ task, organization }) => {
  const theme = useTheme()
  const taskType = task?.type ? handleFormatTypesString(task?.type ?? '—') : '—'
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.section,
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 0.5, px: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Master Status
            </Typography>
            <Typography sx={{ textAlign: 'left', color: renderColorByString(task?.masterStatus) }}>
              {task?.masterStatus}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Task Days
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(task?.taskDays)}
            </Typography>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Start Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(task?.startDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              End Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(task?.endDate)}
            </Typography>
          </Box>
        </Grid>
        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />

        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 1, px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Request Type
            </Typography>
            <Typography sx={{ color: renderColorByString(task?.requestType) }}>{task?.requestType}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Requested By
            </Typography>
            <Typography sx={{ color: renderColorByString(task?.requestedBy) }}>{task?.requestedBy}</Typography>
          </Box>
          {task?.isCreative && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
                Creative Status
              </Typography>
              <Typography sx={{ color: renderColorByString(task?.creativeStatus) }}>
                {task?.creativeStatus || 'no status'}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Assignees
            </Typography>
            <RenderAssignees assignees={task?.assignees} />
          </Box>
        </Grid>

        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 1, px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Organization Status
            </Typography>
            <Typography sx={{ color: renderColorByString(organization?.status) }}>{organization?.status}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Website
            </Typography>
            <Typography sx={{ height: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {organization?.website ?? '—'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Task Type
            </Typography>
            <Typography sx={{ color: renderColorByString(taskType) }}>{taskType}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TaskDrawerHeader
