import React from 'react'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { renderColorByString } from '../DataGrid/renderColorByString'

interface OptionType {
  id: string
  name?: string // For organizations
  dba?: string // DBA field if needed
  firstName?: string // For users
  lastName?: string // For users
  image_512?: string // For avatars
  org_uuid: string
  onboardingStatus: string
  status: string
}

interface FormAutocompleteProps {
  data: OptionType[]
  isLoading: boolean
  label?: string
  error?: boolean
  onChange: (selected: OptionType | null) => void
  value: OptionType | null
  disabled: boolean
}

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  data,
  isLoading,
  label,
  error,
  onChange,
  value,
  disabled,
}) => {
  const theme = useTheme()
  return (
    <Autocomplete
      options={data || []}
      getOptionLabel={option => option.name || option.dba || `${option.firstName || ''} ${option.lastName || ''}`}
      isOptionEqualToValue={(option, value) => option.id === value?.id} // Ensure correct matching
      value={value}
      onChange={(_event, newValue) => onChange(newValue)}
      loading={isLoading}
      renderOption={(props, option) => {
        const { key, ...rest } = props
        return (
          <Box
            key={option?.id}
            {...rest}
            component='li'
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, width: '100%' }}
          >
            {option.image_512 && (
              <Avatar alt={option.name || option.firstName} src={option.image_512} sx={{ height: 36, width: 36 }} />
            )}
            <Box sx={{ display: 'flex', width: '100%' }}>
              {option.name || option.dba || `${option.firstName || ''} ${option.lastName || ''}`}
            </Box>
            {option?.onboardingStatus && (
              <Box
                sx={{
                  display: 'flex',
                  width: '30%',
                  color: renderColorByString(option?.onboardingStatus),
                  fontSize: 12,
                }}
              >
                {option.onboardingStatus}
              </Box>
            )}
            {option?.status && (
              <Box sx={{ display: 'flex', width: '30%', color: renderColorByString(option?.status), fontSize: 12 }}>
                {option.status}
              </Box>
            )}
          </Box>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label || 'Select'}
          variant='outlined'
          error={error}
          sx={{
            '& .MuiInputBase-root': {
              padding: '8px 12px',
              bgcolor: disabled ? theme.palette.background.disabled : '',
            },
          }}
        />
      )}
      fullWidth
      disabled={disabled}
    />
  )
}

export default FormAutocomplete
