import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

export interface Organization {
  org_uuid: string
  name: string
  deals: { products: any }[]
  aggregatedMetrics?: Array<{ [key: string]: number }>
  // Optionally, you can include raw deals or additional data fields
}

export const getEnrichedOrganizations = async (): Promise<Organization[]> => {
  try {
    const url = `${baseUrl}/aws/organizations/metrics/deals`
    const { data } = await axios.get(url)
    return data || []
  } catch (error) {
    console.error('Error fetching organizations:', error)
    return []
  }
}

export const useEnrichedOrganizations = (enabled: boolean = true) => {
  const { data, isLoading, isError } = useQuery<Organization[]>({
    queryKey: ['organizationMetrics'],
    queryFn: getEnrichedOrganizations,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
    enabled,
  })

  return {
    enrichedOrganizations: data || [],
    isEnrichedOrganizationsLoading: isLoading,
    isEnrichedOrganizationsError: isError,
  }
}
