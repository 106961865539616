import { useState, useEffect } from 'react'
import { useGoogleFilesByFolderId } from './useGoogleFilesByFolderId.ts'
import { useValidateGoogleToken } from './useValidateGoogleToken.ts'
import { useAuth } from '../../authentication/AuthContext.js'
import axios from 'axios' // ✅ Use axios to fetch parent folder
import { useDriveMetadata } from './useDriveMetaData.ts'
import { useMultipleFilesMetadata } from './useMultipleFilesMetadata.ts'

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
const DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY!
const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID!
declare global {
  interface Window {
    google?: any
    gapi?: any
    googlePickerInstance?: any
  }
}
const useGooglePickerById = (refreshToken: string, userId: string) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [storedAccessToken, setStoredAccessToken] = useState<string | null>(null)
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<{
    id: string
    name: string
    mimeType: string
    url: string
    embedUrl: string
    thumbnailLink?: string
    iconLink?: string
    webContentLink?: string
  } | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<object[]>([])
  const [lastOpenedFolderId, setLastOpenedFolderId] = useState<string>('root')
  const { redirectToSSOAuth } = useAuth()

  // ✅ Fetch Google files when folder changes
  const { data: filesInFolder, isLoading: isFilesLoading } = useGoogleFilesByFolderId(selectedFolder)

  // ✅ Get access token from local storage
  useEffect(() => {
    const localStorageToken = localStorage.getItem('google_access_token')
    if (localStorageToken) {
      setStoredAccessToken(localStorageToken)
    }
  }, [])

  // ✅ Validate access token using `useValidateGoogleToken`
  const { validateToken, isValid, accessToken, refreshed } = useValidateGoogleToken()
  const { data: driveMetadata, isLoading: isDriveMetadataLoading } = useDriveMetadata(
    selectedFile?.id,
    storedAccessToken!
  )
  const { mutate: fetchMultipleDriveMetadata } = useMultipleFilesMetadata()
  useEffect(() => {
    const checkPickerReady = setInterval(() => {
      if (window.gapi && window.google) {
        clearInterval(checkPickerReady)
        setIsLoaded(true)
      }
    }, 100)
    return () => clearInterval(checkPickerReady)
  }, [])

  useEffect(() => {
    const idsMissingMeta = selectedFiles.filter((file: any) => !file.thumbnailLink).map((file: any) => file.id)

    if (idsMissingMeta.length > 0 && storedAccessToken) {
      fetchMultipleDriveMetadata(
        { fileIds: idsMissingMeta, accessToken: storedAccessToken },
        {
          onSuccess: results => {
            setSelectedFiles(prevFiles =>
              prevFiles.map((file: any) => {
                const meta = results.find((r: any) => r.fileId === file.id)
                return meta && !meta.error
                  ? {
                      ...file,
                      thumbnailLink: meta.thumbnailLink,
                      iconLink: meta.iconLink,
                      webContentLink: meta.webContentLink,
                    }
                  : file
              })
            )
          },
          onError: err => {
            console.error('Error fetching multiple metadata:', err)
          },
        }
      )
    }
  }, [selectedFiles, storedAccessToken, fetchMultipleDriveMetadata])

  const showPicker = async (parentFolderId: string = 'root') => {
    if (!isLoaded) {
      console.error('Google Picker API is not fully loaded yet.')
      return
    }

    const token = localStorage.getItem('google_access_token')

    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: storedAccessToken, refreshToken, userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
              openPicker(data.accessToken!, parentFolderId)
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
      openPicker(token, parentFolderId)
    }
  }

  useEffect(() => {
    if (driveMetadata && selectedFile) {
      setSelectedFile(prevFile => ({
        ...prevFile!,
        thumbnailLink: driveMetadata.thumbnailLink,
        iconLink: driveMetadata.iconLink,
        webContentLink: driveMetadata.webContentLink,
      }))
    }
  }, [driveMetadata])

  // ✅ Open Picker with folder navigation and file handling
  const openPicker = (accessToken: string, parentFolderId: string) => {
    // ✅ Destroy previous picker if it exists
    if (window.googlePickerInstance) {
      window.googlePickerInstance.setVisible(false)
      window.googlePickerInstance = null // Reset the instance
    }

    const uploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true).setParent(parentFolderId)

    const docsView = new window.google.picker.DocsView()
      .setEnableDrives(true)
      .setIncludeFolders(true)
      .setParent(parentFolderId)
      .setMode(window.google.picker.DocsViewMode.LIST)

    const picker = new window.google.picker.PickerBuilder()
      .addView(docsView)
      .addView(uploadView)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setOAuthToken(accessToken)
      .setDeveloperKey(DEVELOPER_KEY)
      .setCallback(async (data: any) => {
        console.log('Picker response:', data)

        if (data.action === window.google.picker.Action.PICKED) {
          const selectedItem = data.docs[0]
          console.log('selectedItem', selectedItem)
          if (selectedItem.mimeType === 'application/vnd.google-apps.folder') {
            setLastOpenedFolderId(parentFolderId)
            setSelectedFolder(selectedItem.id)
          } else if (Array.isArray(data.viewToken) && data.viewToken.includes('upload')) {
            console.log('📂 File uploaded. Navigating to last opened folder...')
            // setSelectedFile(selectedItem)
            setLastOpenedFolderId(parentFolderId)
            setSelectedFolder(selectedItem.id)
            // ✅ Reopen the picker in the last opened folder
            showPicker(parentFolderId)
          } else {
            // Check if a single file was double-clicked for preview
            if (data.docs.length === 1) {
              // Set the preview file state
              setSelectedFile({
                id: selectedItem.id,
                name: selectedItem.name,
                mimeType: selectedItem.mimeType,
                url: selectedItem.url,
                embedUrl: selectedItem.embedUrl,
                // Optionally, add thumbnailLink or iconLink later when metadata is fetched
              })
            } else {
              // For multiple files, update the multiple file state as before
              const files = data.docs.map((doc: any) => ({
                id: doc.id,
                name: doc.name,
                mimeType: doc.mimeType,
                url: doc.url,
                embedUrl: doc.embedUrl,
              }))
              console.log('FILES', data.docs)
              setSelectedFiles(prev => [...prev, ...files])
            }
            setLastOpenedFolderId(selectedItem.parentId)
            // try {
            //   const files = data.docs.map((doc: any) => ({
            //     id: doc.id,
            //     name: doc.name,
            //     mimeType: doc.mimeType,
            //     url: doc.url,
            //     embedUrl: doc.embedUrl,
            //     // You can fetch extra fields later if needed.
            //   }))
            //   setSelectedFiles(prev => [...prev, ...files])
            // } catch (err) {
            //   console.error('Error fetching thumbnail:', err)
            // }
            // setLastOpenedFolderId(selectedItem.parentId)
          }
        } else if (data.action === window.google.picker.Action.CANCEL) {
          console.log('❌ Picker cancelled, destroying instance.')
          window.googlePickerInstance = null
        }
      })
      .setAppId(APP_ID)
      .build()

    window.googlePickerInstance = picker
    picker.setVisible(true)
  }

  return {
    isLoaded,
    showPicker,
    selectedFolder,
    filesInFolder,
    isFilesLoading,
    selectedFile,
    setSelectedFile,
    selectedFiles,
    setSelectedFiles,
    lastOpenedFolderId,
  }
}

export default useGooglePickerById

// import { useState, useEffect } from 'react'
// import { useGoogleFilesByFolderId } from './useGoogleFilesByFolderId.ts'
// import { useValidateGoogleToken } from './useValidateGoogleToken.ts'
// import { useAuth } from '../../authentication/AuthContext.js'
// import axios from 'axios' // ✅ Use axios to fetch parent folder
// import { useDriveMetadata } from './useDriveMetaData.ts'

// const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!
// const DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY!
// const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID!
// declare global {
//   interface Window {
//     google?: any
//     gapi?: any
//     googlePickerInstance?: any
//   }
// }
// const useGooglePickerById = (refreshToken: string, userId: string) => {
//   const [isLoaded, setIsLoaded] = useState(false)
//   const [storedAccessToken, setStoredAccessToken] = useState<string | null>(null)
//   const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
//   const [selectedFile, setSelectedFile] = useState<{
//     id: string
//     name: string
//     mimeType: string
//     url: string
//     embedUrl: string
//     thumbnailLink?: string
//     iconLink?: string
//   } | null>(null)
//   const [lastOpenedFolderId, setLastOpenedFolderId] = useState<string>('root')
//   const { redirectToSSOAuth } = useAuth()

//   // ✅ Fetch Google files when folder changes
//   const { data: filesInFolder, isLoading: isFilesLoading } = useGoogleFilesByFolderId(selectedFolder)

//   // ✅ Get access token from local storage
//   useEffect(() => {
//     const localStorageToken = localStorage.getItem('google_access_token')
//     if (localStorageToken) {
//       setStoredAccessToken(localStorageToken)
//     }
//   }, [])

//   // ✅ Validate access token using `useValidateGoogleToken`
//   const { validateToken, isValid, accessToken, refreshed } = useValidateGoogleToken()
//   const { data: driveMetadata, isLoading: isDriveMetadataLoading } = useDriveMetadata(
//     selectedFile?.id,
//     storedAccessToken!
//   )
//   useEffect(() => {
//     const checkPickerReady = setInterval(() => {
//       if (window.gapi && window.google) {
//         clearInterval(checkPickerReady)
//         setIsLoaded(true)
//       }
//     }, 100)
//     return () => clearInterval(checkPickerReady)
//   }, [])

//   const showPicker = async (parentFolderId: string = 'root') => {
//     if (!isLoaded) {
//       console.error('Google Picker API is not fully loaded yet.')
//       return
//     }

//     const token = localStorage.getItem('google_access_token')

//     if (!token || !isValid) {
//       console.log('🔄 Token missing or invalid, validating...')

//       validateToken(
//         { accessToken: storedAccessToken, refreshToken, userId },
//         {
//           onSuccess: data => {
//             if (data.isValid) {
//               localStorage.setItem('google_access_token', data.accessToken || '')
//               openPicker(data.accessToken!, parentFolderId)
//             } else {
//               console.warn('❌ Token validation failed, please reauthenticate.')
//             }
//           },
//         }
//       )
//     } else {
//       console.log('✅ Using valid access token from cache.')
//       openPicker(token, parentFolderId)
//     }
//   }

//   useEffect(() => {
//     if (driveMetadata && selectedFile) {
//       setSelectedFile(prevFile => ({
//         ...prevFile!,
//         thumbnailLink: driveMetadata.thumbnailLink,
//         iconLink: driveMetadata.iconLink,
//       }))
//     }
//   }, [driveMetadata])
//   // ✅ Open Picker with folder navigation and file handling
//   const openPicker = (accessToken: string, parentFolderId: string) => {
//     // ✅ Destroy previous picker if it exists
//     if (window.googlePickerInstance) {
//       window.googlePickerInstance.setVisible(false)
//       window.googlePickerInstance = null // Reset the instance
//     }

//     const uploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true).setParent(parentFolderId)

//     const docsView = new window.google.picker.DocsView()
//       .setEnableDrives(true)
//       .setIncludeFolders(true)
//       .setParent(parentFolderId)
//       .setMode(window.google.picker.DocsViewMode.LIST)

//     const picker = new window.google.picker.PickerBuilder()
//       .addView(docsView)
//       .addView(uploadView)
//       .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
//       .setOAuthToken(accessToken)
//       .setDeveloperKey(DEVELOPER_KEY)
//       .setCallback(async (data: any) => {
//         console.log('Picker response:', data)

//         if (data.action === window.google.picker.Action.PICKED) {
//           const selectedItem = data.docs[0]
//           console.log('selectedItem', selectedItem)
//           if (selectedItem.mimeType === 'application/vnd.google-apps.folder') {
//             setLastOpenedFolderId(parentFolderId)
//             setSelectedFolder(selectedItem.id)
//           } else if (Array.isArray(data.viewToken) && data.viewToken.includes('upload')) {
//             console.log('📂 File uploaded. Navigating to last opened folder...')
//             // setSelectedFile(selectedItem)
//             setLastOpenedFolderId(parentFolderId)
//             setSelectedFolder(selectedItem.id)
//             // ✅ Reopen the picker in the last opened folder
//             showPicker(parentFolderId)
//           } else {
//             // const fileUrl = `https://drive.google.com/file/d/${selectedItem.id}/view`
//             try {
//               // const extraFields = await fetchDriveFileMetadata(accessToken, selectedItem.id)
//               // e.g., { thumbnailLink, iconLink, ... }
//               setSelectedFile({
//                 id: selectedItem.id,
//                 name: selectedItem.name,
//                 mimeType: selectedItem.mimeType,
//                 url: selectedItem.url,
//                 embedUrl: selectedItem.embedUrl,
//                 // thumbnailLink: extraFields.thumbnailLink,
//                 // iconLink: extraFields.iconLink,
//               })
//             } catch (err) {
//               console.error('Error fetching thumbnail:', err)
//             }
//             setLastOpenedFolderId(selectedItem.parentId)
//           }
//         } else if (data.action === window.google.picker.Action.CANCEL) {
//           console.log('❌ Picker cancelled, destroying instance.')
//           window.googlePickerInstance = null
//         }
//       })
//       .setAppId(APP_ID)
//       .build()

//     window.googlePickerInstance = picker
//     picker.setVisible(true)
//   }

//   return {
//     isLoaded,
//     showPicker,
//     selectedFolder,
//     filesInFolder,
//     isFilesLoading,
//     selectedFile,
//     setSelectedFile,
//     lastOpenedFolderId,
//   }
// }

// export default useGooglePickerById
