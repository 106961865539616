import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../../queryMutations/mutationFunctions'

interface RawUploadTaskImagesVars {
  formData: FormData
}

interface RawUploadTaskImagesResponse {
  urls: string[]
}

const rawUploadFiles = async ({ formData }: RawUploadTaskImagesVars): Promise<RawUploadTaskImagesResponse> => {
  const url = `${baseUrl}/aws/s3/raw-upload`
  console.log('Uploading images, formData:', formData)
  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response.data // Expected: { urls: string[] }
}

const useRawUpload = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  return useMutation<RawUploadTaskImagesResponse, unknown, RawUploadTaskImagesVars>({
    mutationFn: rawUploadFiles,
    onError: (err, variables, context) => {
      console.error('Upload Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: data => {
      console.log('Images uploaded successfully:', data)
      showSnackbar('Images uploaded successfully', 'success')
    },
  })
}

export default useRawUpload
