import React from 'react'
import MetaLightSvg from '../../images/icons/meta (1).svg'
import GoogleDisplaySvg from '../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../images/icons/google-search-icon.svg'
import LinkedInSvg from '../../images/icons/linkedin.svg'
import MicrosoftSvg from '../../images/icons/bing.png'
import SimplifiSvg from '../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../images/icons/snapchat.svg'
import SpotifySvg from '../../images/icons/spotify.svg'
import TikTokSvg from '../../images/icons/tiktok.svg'
import YoutubeSvg from '../../images/icons/youtube.svg'
import ChannelIcon from '../Icons/ChannelIcon'
import Ga4Svg from '../../images/icons/google-analytics.svg'
import GtmSvg from '../../images/icons/google-tag-manager.svg'
import Seo1Svg from '../../images/icons/seotier1.svg'
import Seo2Svg from '../../images/icons/seotier2.svg'
import Seo3Svg from '../../images/icons/seotier3.svg'
import Seo4Svg from '../../images/icons/seo.png'
import GoogleAdsSvg from '../../images/icons/google-ads-icon.svg'
import { Divider } from '@mui/material'
import SeoDisplayIcon from '../ui/seo-display-icon'

const DisplayChannels = ({ deal, checklistProgress }) => {
  // Modified getOpacity: expects checklistProgress[channelKey] as a fraction (0 to 1)
  const getOpacity = channelKey => {
    // If no progress is provided, default to a low opacity (e.g., 0.2)
    const fraction = checklistProgress?.[channelKey.toLowerCase()] || 0
    // For a smooth scaling, you might directly return the fraction or scale it
    return Math.min(1, Math.max(0.2, fraction)) // Ensure between 0.2 and 1
  }

  const gtmGa4Channels =
    deal?.isYoutube ||
    deal?.isGoogleSearch ||
    deal?.isMetaAdvertising ||
    deal?.isLinkedIn ||
    deal?.isMicrosoftAdvertising ||
    deal?.isSimplifi ||
    deal?.isSnapchat ||
    deal?.isSpotify ||
    deal?.isTikTok

  return (
    <>
      {deal?.isMetaAdvertising && (
        <ChannelIcon
          isContracted={deal?.isMetaAdvertising}
          title={'Meta Advertising'}
          src={MetaLightSvg}
          opacity={getOpacity('fbpage')}
        />
      )}
      {deal?.isGoogleDisplay && (
        <ChannelIcon
          isContracted={deal?.isGoogleDisplay}
          title={'Google Display'}
          src={GoogleDisplaySvg}
          opacity={getOpacity('googleads')}
        />
      )}
      {deal?.isGoogleSearch && (
        <ChannelIcon
          isContracted={deal?.isGoogleSearch}
          title={'Google Search'}
          src={GoogleSearchSvg}
          opacity={getOpacity('GTM')}
        />
      )}
      {deal?.isLinkedIn && (
        <ChannelIcon
          isContracted={deal?.isLinkedIn}
          title={'LinkedIn'}
          src={LinkedInSvg}
          opacity={getOpacity('linkedin')}
        />
      )}
      {deal?.isMicrosoftAdvertising && (
        <ChannelIcon
          isContracted={deal?.isMicrosoftAdvertising}
          title={'Microsoft'}
          src={MicrosoftSvg}
          opacity={getOpacity('bing')}
        />
      )}
      {deal?.isSimplifi && (
        <ChannelIcon
          isContracted={deal?.isSimplifi}
          title={'Simplifi'}
          src={SimplifiSvg}
          opacity={getOpacity('simplifi')}
        />
      )}
      {deal?.isSnapchat && (
        <ChannelIcon
          isContracted={deal?.isSnapchat}
          title={'Snapchat'}
          src={SnapchatSvg}
          opacity={getOpacity('snapchat')}
        />
      )}
      {deal?.isSpotify && (
        <ChannelIcon
          isContracted={deal?.isSpotify}
          title={'Spotify'}
          src={SpotifySvg}
          opacity={getOpacity('spotify')}
        />
      )}
      {deal?.isTikTok && (
        <ChannelIcon isContracted={deal?.isTikTok} title={'TikTok'} src={TikTokSvg} opacity={getOpacity('tiktok')} />
      )}
      {deal?.isYoutube && (
        <ChannelIcon
          isContracted={deal?.isYoutube}
          title={'Youtube'}
          src={YoutubeSvg}
          opacity={getOpacity('youtube')}
        />
      )}
      {deal?.isSeo1 && (
        <SeoDisplayIcon
          isContracted={deal?.isSeo1}
          title={'SEO - Tier 1'}
          tier={1}
          src={Seo1Svg}
          opacity={getOpacity('completeSeoForm')}
        />
      )}
      {deal?.isSeo2 && (
        <SeoDisplayIcon
          isContracted={deal?.isSeo2}
          title={'SEO - Tier 2'}
          tier={2}
          src={Seo2Svg}
          opacity={getOpacity('completeSeoForm')}
        />
      )}
      {deal?.isSeo3 && (
        <SeoDisplayIcon
          isContracted={deal?.isSeo3}
          title={'SEO - Tier 3'}
          tier={3}
          src={Seo3Svg}
          opacity={getOpacity('completeSeoForm')}
        />
      )}
      {deal?.isSeo4 && (
        <SeoDisplayIcon
          isContracted={deal?.isSeo4}
          title={'SEO - Tier 4'}
          tier={4}
          src={Seo4Svg}
          opacity={getOpacity('completeSeoForm')}
        />
      )}
      <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
      {gtmGa4Channels && (
        <ChannelIcon isContracted={gtmGa4Channels} title={'GA4'} src={Ga4Svg} opacity={getOpacity('ga4')} />
      )}
      {gtmGa4Channels && (
        <ChannelIcon
          isContracted={gtmGa4Channels}
          title={'Google Tag Manager'}
          src={GtmSvg}
          opacity={getOpacity('gtm')}
        />
      )}
    </>
  )
}

export default DisplayChannels

// const DisplayChannels = ({ deal, checklistProgress }) => {
//   // Function to calculate opacity based on progress
//   const getOpacity = section => {
//     if (!checklistProgress || !checklistProgress[section]) return 0.2 // Default to lowest opacity
//     const progress = checklistProgress[section] // Completed tasks count
//     const totalTasks = Object.keys(checklistProgress).length // Get total tasks

//     return section === 'googleAds' ? Math.max(0.1, progress / 2) : Math.max(0.1, progress / totalTasks) // Scale between 0.1 and 1
//   }
//   const gtmGa4Channels =
//     deal?.isYoutube ||
//     deal?.isGoogleSearch ||
//     deal?.isMetaAdvertising ||
//     deal?.isLinkedIn ||
//     deal?.isMicrosoftAdvertising ||
//     deal?.isSimplifi ||
//     deal?.isSnapchat ||
//     deal?.isSpotify ||
//     deal?.isTikTok
//   return (
//     <>
//       {deal?.isMetaAdvertising && (
//         <ChannelIcon
//           isContracted={deal?.isMetaAdvertising}
//           title={'Meta Advertising'}
//           src={MetaLightSvg}
//           opacity={getOpacity('meta')}
//         />
//       )}
//       {deal?.isGoogleDisplay && (
//         <ChannelIcon
//           isContracted={deal?.isGoogleDisplay}
//           title={'Google Display'}
//           src={GoogleDisplaySvg}
//           opacity={getOpacity('googleAds')}
//         />
//       )}
//       {deal?.isGoogleSearch && (
//         <ChannelIcon
//           isContracted={deal?.isGoogleSearch}
//           title={'Google Search'}
//           src={GoogleSearchSvg}
//           opacity={getOpacity('googleAds')}
//         />
//       )}
//       {deal?.isLinkedIn && (
//         <ChannelIcon
//           isContracted={deal?.isLinkedIn}
//           title={'LinkedIn'}
//           src={LinkedInSvg}
//           opacity={getOpacity('linkedin')}
//         />
//       )}
//       {deal?.isMicrosoftAdvertising && (
//         <ChannelIcon
//           isContracted={deal?.isMicrosoftAdvertising}
//           title={'Microsoft'}
//           src={MicrosoftSvg}
//           opacity={getOpacity('bing')}
//         />
//       )}
//       {deal?.isSimplifi && (
//         <ChannelIcon
//           isContracted={deal?.isSimplifi}
//           title={'Simplifi'}
//           src={SimplifiSvg}
//           opacity={getOpacity('Simplifi')}
//         />
//       )}
//       {deal?.isSnapchat && (
//         <ChannelIcon
//           isContracted={deal?.isSnapchat}
//           title={'Snapchat'}
//           src={SnapchatSvg}
//           opacity={getOpacity('snapchat')}
//         />
//       )}
//       {deal?.isSpotify && (
//         <ChannelIcon
//           isContracted={deal?.isSpotify}
//           title={'Spotify'}
//           src={SpotifySvg}
//           opacity={getOpacity('Spotify')}
//         />
//       )}
//       {deal?.isTikTok && (
//         <ChannelIcon isContracted={deal?.isTikTok} title={'TikTok'} src={TikTokSvg} opacity={getOpacity('tiktok')} />
//       )}
//       {deal?.isYoutube && (
//         <ChannelIcon
//           isContracted={deal?.isYoutube}
//           title={'Youtube'}
//           src={YoutubeSvg}
//           opacity={getOpacity('Youtube')}
//         />
//       )}
//       {deal?.isSeo1 && (
//         <SeoDisplayIcon
//           isContracted={deal?.isSeo1}
//           title={'SEO - Tier 1'}
//           tier={1}
//           src={Seo1Svg}
//           opacity={getOpacity('googleSearchConsole')}
//         />
//       )}
//       {deal?.isSeo2 && (
//         <SeoDisplayIcon
//           isContracted={deal?.isSeo2}
//           title={'SEO - Tier 2'}
//           tier={2}
//           src={Seo2Svg}
//           opacity={getOpacity('googleSearchConsole')}
//         />
//       )}
//       {deal?.isSeo3 && (
//         <SeoDisplayIcon
//           isContracted={deal?.isSeo3}
//           title={'SEO - Tier 3'}
//           tier={3}
//           src={Seo3Svg}
//           opacity={getOpacity('googleSearchConsole')}
//         />
//       )}
//       {deal?.isSeo4 && (
//         <SeoDisplayIcon
//           isContracted={deal?.isSeo4}
//           title={'SEO - Tier 4'}
//           tier={4}
//           src={Seo4Svg}
//           opacity={getOpacity('googleSearchConsole')}
//         />
//       )}
//       <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
//       {/* {deal?.isGoogleDisplay ||
//         (deal?.isGoogleSearch && (
//           <ChannelIcon
//             isContracted={deal?.isGoogleDisplay || deal?.isGoogleSearch}
//             title={'Google Ads'}
//             src={GoogleAdsSvg}
//             opacity={getOpacity('googleAds')}
//           />
//         ))} */}
//       {gtmGa4Channels && (
//         <ChannelIcon isContracted={gtmGa4Channels} title={'GA4'} src={Ga4Svg} opacity={getOpacity('ga4')} />
//       )}
//       {gtmGa4Channels && (
//         <ChannelIcon
//           isContracted={gtmGa4Channels}
//           title={'Google Tag Manager'}
//           src={GtmSvg}
//           opacity={getOpacity('gtm')}
//         />
//       )}
//     </>
//   )
// }

// export default DisplayChannels
