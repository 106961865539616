export const dialogStyles = theme => ({
  width: '100%',
  maxWidth: '1300px',
  // backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '50vh',
  maxHeight: '95vh',
  padding: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    // padding: '30px',
  },
  '& .MuiTextField-root': {
    // margin: '10px 0',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(79, 84, 94)' : 'white',
    minWidth: '100%', // Ensure the input base width fits within the dialog
  },
  '& .MuiFormControl-root': {
    width: '100%', // Ensure the form control takes full width
  },
  '& .MuiDateRangePicker-root': {
    width: '100%', // Ensure the DateRangePicker takes full width
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,51)' : 'rgba(0,0,0,0.5)',
    borderRadius: '6px',
  },
})
