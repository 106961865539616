import { Grid, Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeals } from '../../api/aws/useDeals'
import DetailsLoading from '../Loading/DetailsLoading'
import { PieChart } from '@mui/x-charts/PieChart'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import { renderColorByString } from '../DataGrid/renderColorByString'
import { useFilteredDeals } from '../../api/aws/useFilteredDeals'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { useChangeView } from '../../utility/useChangeView.ts'

const Boards = () => {
  const navigate = useNavigate()
  const { pinned } = useLayoutContext()
  const handleChangeView = useChangeView()
  const { deals, isDealsLoading } = useDeals(true, ['boards', 'deals'])
  const filters = {
    status: ['Completed', 'Completed Internally'],
    stage: 'Deal Won',
    type: 'MSA',
    // productCategories: ['Digital'],
    // productSubcategories: [
    //   'Videography',
    //   'Graphic Design', // Filters deals with Search category in products
    // ],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  const { filteredDeals, isFilteredDealsLoading, isFilteredDealsError } = useFilteredDeals(
    true,
    ['ncoPage', 'deals'],
    filters
  )
  // 🔹 Aggregate `ncoStatus` counts efficiently
  const ncoStatusCounts = useMemo(() => {
    if (!filteredDeals) return {} // Ensure deals is defined
    return filteredDeals.reduce((acc, deal) => {
      if (deal.onboardingStatus) {
        acc[deal.onboardingStatus] = (acc[deal.onboardingStatus] || 0) + 1
      }
      return acc
    }, {})
  }, [filteredDeals])

  const totalNcoDeals = useMemo(() => Object.values(ncoStatusCounts || {}).reduce((sum, count) => sum + count, 0), [
    ncoStatusCounts,
  ])

  // 🔹 Convert ncoStatusCounts into an array format for MUI Pie Chart
  const pieChartData = useMemo(
    () =>
      Object.entries(ncoStatusCounts || {}).map(([status, count]) => ({
        id: status,
        value: count,
        label: status, // Removes labels from chart
        color: renderColorByString(status),
      })),
    [ncoStatusCounts]
  )

  const boards = [
    {
      title: 'New Client Onboards',
      route: '/nco',
      count: totalNcoDeals,
      icon: <AddRoadIcon sx={{ color: 'rgba(159, 90, 253, 1)' }} />,
    },
    { title: 'Website', route: '/website' },
    { title: 'SEO', route: '/seo' },
    { title: 'Creative Tasks', route: '/creative' },
    { title: 'Team Tasks', route: '/teamtasks' },
    { title: 'Monthly Tasks', route: '/mtb' },
  ]
  const handleNavigate = route => {
    const boardViewMapping = {
      '/nco': 'nco',
      '/creative': 'creative',
      '/seo': 'seo',
      '/website': 'website',
      '/deals': 'deals',
      '/organizations': 'organizations',
      '/contacts': 'contacts',
      '/leads': 'leads',
      '/teamtasks': 'teamtasks',
      '/mtb': 'mtb',
    }

    const newView = boardViewMapping[route] || null

    if (newView) {
      handleChangeView({ newView })
    }

    navigate(route)
  }

  if (isDealsLoading || isFilteredDealsLoading) {
    return <DetailsLoading />
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2, pt: pinned ? '60px' : 0, transition: 'padding 0.3s ease' }}>
      <Grid container spacing={2}>
        {boards.map((board, index) => (
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
            <Card sx={{ height: '100%', overflow: 'visible' }}>
              <CardActionArea onClick={() => handleNavigate(board.route)} sx={{ height: '100%' }}>
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  {/* Board Title & Icon */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box>{board.icon}</Box>
                    <Typography variant='h5'>{board.title}</Typography>
                  </Box>

                  {/* Show deal count if available */}
                  {board.count !== undefined && (
                    <Typography variant='h6' sx={{ marginTop: 1 }}>
                      {board.count} {board.count === 1 ? 'Deal' : 'Deals'}
                    </Typography>
                  )}

                  {/* MUI Pie Chart for NCO statuses */}
                  {board.title === 'New Client Onboards' && pieChartData.length > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between', // Keep Key on Left & Chart on Right
                        alignItems: 'center',
                        width: '100%',
                        marginTop: 2,
                      }}
                    >
                      {/* 🔹 Legend (Left Side) */}
                      <Box sx={{ flex: 1, textAlign: 'left', minWidth: 150, paddingRight: '20px' }}>
                        {pieChartData.map(({ id, value }) => (
                          <Typography key={id} sx={{ color: renderColorByString(id), fontSize: 14 }}>
                            {id}: {value}
                          </Typography>
                        ))}
                      </Box>

                      {/* 🔹 Pie Chart (Right Side) */}
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <PieChart
                          series={[
                            {
                              data: pieChartData,
                              highlightScope: { fade: 'global', highlight: 'item' },
                              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                              outerRadius: 90,
                              innerRadius: 40,
                            },
                          ]}
                          slotProps={{
                            legend: {
                              hidden: true,
                            },
                          }}
                          width={250}
                          height={250}
                          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                        />
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Boards
