import React, { useState } from 'react'
import { Box, Typography, CircularProgress, Grid, InputLabel, IconButton, useTheme, Button, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TaskRichText from '../Tasks/TaskRichText'
import SaveIconButton from '../ui/save-icon-button'
import { handleFormatTypesString } from '../Forms/types.ts'
import { renderColorByString } from '../DataGrid/renderColorByString'
import { useDownloadImage } from '../../api/aws/s3/useDownloadImage.ts'
import TaskImageUploader from './TaskImageUploader.tsx'
import { baseUrl } from '../../api/queryMutations/mutationFunctions.js'
import { useQueryClient } from '@tanstack/react-query'
import PreviewTaskAttachments from './PreviewTaskAttachments.tsx'
import PreviewModal from './PreviewModal.tsx'

// Assume these helper functions exist or implement them:
// uploadTaskImage(file: File): Promise<string>
// updateTaskImages(newImageUrls: string[]): Promise<void>
// You may implement updateTaskImages using your updateTaskItem mutation or a dedicated endpoint.
interface Task {
  PK: string
  SK: string
  task_uuid: string
  imageUrls?: string[]
  type: string
}
interface TaskDetailsProps {
  task: Task
  isTaskLoading: boolean
  onNavigate: (route: string) => void
  onChange: (field: string, newValue: any) => void
  onSave: () => void
  addtlNotes: string
  expanded: boolean
  updateTaskImages: (newImageUrls: string[]) => void
}

const GROUPS = ['search', 'social', 'display', 'video', 'integration', 'seo']
const FIELD_ORDER: Record<string, string[]> = {
  search: [
    'searchPlatform',
    'searchBudget',
    'searchUpdate',
    'searchReview',
    'searchCreate',
    'searchNco',
    'searchCampaignPlatform',
    'searchAdApprovals',
    'searchPause',
    'searchEnable',
  ],
  social: [
    'socialPlatform',
    'socialBudget',
    'socialUpdate',
    'socialReview',
    'socialCreate',
    'socialNco',
    'socialCampaignPlatform',
    'socialAdApprovals',
    'socialPause',
    'socialEnable',
  ],
  display: [
    'displayPlatform',
    'displayBudget',
    'displayUpdate',
    'displayReview',
    'displayCreate',
    'displayNco',
    'displayCampaignPlatform',
    'displayAdApprovals',
    'displayPause',
    'displayEnable',
  ],
  video: [
    'videoPlatform',
    'videoBudget',
    'videoUpdate',
    'videoReview',
    'videoCreate',
    'videoNco',
    'videoCampaignPlatform',
    'videoAdApprovals',
    'videoPause',
    'videoEnable',
  ],
  integration: [
    'integrationOptions',
    'integrationBudget',
    'integrationUpdate',
    'integrationReview',
    'integrationCreate',
    'integrationNco',
    'integrationCampaignChecklist',
    'integrationAdApprovals',
    'integrationPause',
    'integrationEnable',
  ],
  seo: [
    'seoSpecifics',
    'seoBudget',
    'seoUpdate',
    'seoReview',
    'seoCreate',
    'seoNco',
    'seoCampaignChecklist',
    'seoAdApprovals',
    'seoPause',
    'seoEnable',
  ],
}
const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}
const prettifyKey = (key: string) => {
  const parts = key.match(/[A-Z][a-z]+|[a-z]+/g)
  return parts?.slice(1).join(' ') || key
}

const TaskDetails: React.FC<TaskDetailsProps> = ({
  task,
  isTaskLoading,
  onNavigate,
  onChange,
  onSave,
  addtlNotes,
  expanded,
  updateTaskImages,
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isDeleting, setIsDeleting] = useState<boolean | false>(false)
  const [isDownloading, setIsDownloading] = useState<boolean | false>(false)
  const { mutate: downloadImage } = useDownloadImage()

  const handleAddtlNotesChange = (newValue: string) => {
    onChange('addtlNotes', newValue)
  }
  const openFrame = (url: string) => {
    setPreviewUrl(url)
  }

  const closePreview = () => {
    setPreviewUrl(null)
  }
  // --- Download handler (with forced download via anchor) ---
  const handleDownload = (s3Url: string) => {
    setIsDownloading(true)
    const key = s3Url.split('.amazonaws.com/')[1]
    downloadImage(key, {
      onSuccess: (url: string) => {
        const link = document.createElement('a')
        link.href = url
        link.download = key.split('/').pop() || 'downloaded_image'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setIsDownloading(false)
      },
      onError: (error: any) => {
        setIsDownloading(false)

        console.error('Error fetching presigned URL:', error)
      },
    })
  }

  // --- Upload new images ---
  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files)
      try {
        // Assume uploadTaskImage returns the URL after uploading to S3.
        const uploadedUrls = await Promise.all(files.map(file => uploadTaskImage(file)))
        const updatedImages = [...(task.imageUrls || []), ...uploadedUrls]
        updateTaskImages(updatedImages)
      } catch (error) {
        console.error('Error uploading images:', error)
      }
    }
  }

  const handleDeleteImage = async (url: string) => {
    setIsDeleting(true)
    try {
      // Extract the S3 key from the URL.
      const key = url.split('.amazonaws.com/')[1]
      // Set the additional parameters.
      const tableName = 'TASKS_TABLE'
      const { PK, SK } = task
      const fieldName = 'imageUrls'

      const response = await fetch(
        `${baseUrl}/aws/s3/delete?key=${encodeURIComponent(
          key
        )}&tableName=${tableName}&PK=${PK}&SK=${SK}&fieldName=${fieldName}`,
        {
          method: 'DELETE',
        }
      )
      if (response.ok) {
        queryClient.invalidateQueries(['task', task.PK, task.SK])
      }
      if (!response.ok) {
        throw new Error('Deletion failed')
      }
      // Update the task's imageUrls using the new array returned from the endpoint.
      setIsDeleting(false)
    } catch (error) {
      setIsDeleting(false)

      console.error('Error deleting image:', error)
    }
  }

  return (
    <>
      {isTaskLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container p={1} spacing={0} sx={{ bgcolor: theme.palette.background.section }}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: renderColorByString(handleFormatTypesString(task?.type)),
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}
              >
                {handleFormatTypesString(task?.type)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveIconButton hasErrors={false} onSave={onSave} />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              padding: 2,
              height: { xs: 'auto', md: 'auto' },
              maxHeight: '100%',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {/* Scrollable Grouped Fields */}
            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                pr: 2,
                borderRight: { md: `1px solid ${theme.palette.divider}` },
                maxHeight: { xs: 'auto', md: expanded ? 'calc(100vh - 425px)' : 'calc(100vh - 295px)' },
              }}
            >
              {GROUPS.map(group => {
                const existingKeys = FIELD_ORDER[group].filter(key => task?.[key] !== undefined && task?.[key] !== null)
                if (existingKeys.length === 0) return null

                return (
                  <Box
                    key={group}
                    sx={{
                      mb: 3,
                      width: '100%',
                      position: 'relative',
                      borderBottom: `1px solid ${theme.palette.text.primary}`,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: theme.palette.background.section,
                        py: 0.5,
                      }}
                    >
                      <Typography
                        sx={{ color: theme.palette.text.warning, fontWeight: 'bold', textAlign: 'left', fontSize: 18 }}
                      >
                        {group.charAt(0).toUpperCase() + group.slice(1)}
                      </Typography>
                    </Box>

                    {existingKeys.map(key => {
                      const value = task[key]
                      return (
                        <Box
                          key={key}
                          sx={{
                            mb: 1,
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: key === 'seoSpecifics' ? 'left' : 'center',
                            flexDirection: key === 'seoSpecifics' ? 'column' : 'row',
                          }}
                        >
                          <Typography sx={{ fontSize: 16, color: theme.palette.text.secondary }} fontWeight={600}>
                            {['Platform', 'Options', 'Specifics'].some(substr => key.includes(substr))
                              ? prettifyKey(key)
                              : `Options`}
                          </Typography>
                          {Array.isArray(value) ? (
                            value.map((item, idx) => (
                              <Typography key={idx} sx={{ ml: 1 }}>
                                {item}
                              </Typography>
                            ))
                          ) : (
                            <Typography variant='body2' sx={{ ml: 1 }}>
                              {value}
                            </Typography>
                          )}
                        </Box>
                      )
                    })}
                  </Box>
                )
              })}
            </Box>

            {/* Rich Text Notes */}
            <Box
              sx={{
                flex: 1,
                pl: { md: 2 },
                maxHeight: { xs: 'auto', md: '100%' },
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputLabel sx={{ mb: 1 }}>Description</InputLabel>
              <Box sx={{ flexGrow: 1 }}>
                <TaskRichText content={addtlNotes} onContentChange={handleAddtlNotesChange} />
              </Box>
            </Box>
          </Box>

          {/* Image Attachments Section */}
          <Grid container sx={{ display: 'flex', mt: 1, width: '100%' }}>
            {task?.imageUrls && task?.imageUrls?.length > 0 && (
              <PreviewTaskAttachments
                imageUrls={task?.imageUrls}
                openFrame={openFrame}
                onDeleteFile={handleDeleteImage}
                handleDownload={handleDownload}
                isLoading={isDeleting || isDownloading}
              />
            )}
          </Grid>
          <TaskImageUploader task={task} />
          {previewUrl && <PreviewModal previewUrl={previewUrl} onClose={closePreview} />}
        </>
      )}
    </>
  )
}

export default TaskDetails
