import React from 'react'
import { Badge, Box, Typography, Tooltip, useTheme } from '@mui/material'
import Seo4Svg from '../../images/icons/seo.png'
import SearchIcon from '@mui/icons-material/Search'

const SeoDisplayIcon = ({ title, tier, opacity }) => {
  const theme = useTheme()

  return (
    <Tooltip title={title ?? 'SEO'}>
      <Box
        sx={{
          p: 0,
          m: 0,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
          color: theme.palette.text.primary,
        }}
      >
        <Badge
          badgeContent={<Typography sx={{ fontSize: '9px', fontWeight: 'bold', color: 'white' }}>{tier}</Typography>}
          sx={{
            opacity: opacity ?? 1,
            '& .MuiBadge-badge': {
              minWidth: 16,
              height: 16,
              borderRadius: '50%',
              fontSize: '9px',
              fontWeight: 'bold',
              padding: '0 4px',
              right: 6, // Move badge slightly inward
              top: 6, // Adjust to prevent cutoff
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <SearchIcon sx={{ fontSize: 28, opacity: opacity ?? 1 }} />
        </Badge>
      </Box>
    </Tooltip>
  )
}

export default SeoDisplayIcon
