import React from 'react'
import { Box, DialogTitle, useTheme } from '@mui/material'
import { DialogIcon, boxStyles, dialogTitleStyles } from './style'
import GroboticPng from '../../images/Grobotic png.png'
import GroboticLight from '../../images/Grobotic png-lightmode.png'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'

const CreateTaskHeader = ({ context }) => {
  const theme = useTheme()
  return (
    <Box sx={boxStyles}>
      <DialogIcon src={theme.palette.mode === 'dark' ? GroboticPng : GroboticLight} />
      <Box sx={boxStyles}>
        <DialogTitle sx={dialogTitleStyles(theme)}>{context}</DialogTitle>
        {context === 'Creative Intake Form' ? (
          <EmojiObjectsIcon
            sx={{
              color: 'rgb(111, 31, 223)',
              width: 35,
              height: 35,
            }}
          />
        ) : context === 'Digital Task Form' ? (
          <TaskAltIcon
            sx={{
              color: 'rgb(52, 211, 145)',
              width: 35,
              height: 35,
            }}
          />
        ) : (
          <TaskAltIcon
            sx={{
              color: 'rgb(255, 68, 161)',
              width: 35,
              height: 35,
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default CreateTaskHeader
