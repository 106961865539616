// utils.ts
export function getDiff<T extends Record<string, any>>(original: T, updated: T): Partial<T> {
  const diff: Partial<T> = {}
  Object.keys(updated).forEach(key => {
    // Use JSON.stringify for a deep comparison of values.
    if (JSON.stringify(original[key]) !== JSON.stringify(updated[key])) {
      diff[key] = updated[key]
    }
  })
  return diff
}
