export const generalTasksList = [
  {
    key: 'checklistSent',
    name: 'Checklist Sent (Dependency checklist email)',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'receivedDependencies',
    name: 'Received Dependencies',
    role: 'PM',
    dependencies: ['checklistSent'],
    id: 2,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'verifyDependencies',
    name: 'Verify Dependencies',
    role: 'PM',
    dependencies: ['checklistSent', 'receivedDependencies'],
    id: 3,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'createAccountShells',
    name: 'Create Account Shells',
    role: 'MIA',
    dependencies: ['verifyDependencies'],
    id: 4,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'creativeTasksSent',
    name: 'Creative Tasks Sent',
    role: 'PM',
    dependencies: ['verifyDependencies'],
    id: 5,
    relatedDealField: 'onboardingProgress',
    subTask: true,
  },
  {
    key: 'integrationsStep',
    name: 'Integrations',
    role: 'MIA',
    dependencies: ['verifyDependencies'],
    id: 6,
    relatedDealField: 'onboardingProgress',
    subTask: true,
  },
  {
    key: 'searchCampaignCreation',
    name: 'Search Campaign Creation',
    role: 'DMS',
    dependencies: ['integrationsStep'],
    id: 7,
    relatedDealField: 'onboardingProgress',
    subTask: true,
  },
  {
    key: 'createSocialDisplayCampaigns',
    name: 'Create Social/Display Campaigns',
    role: 'DMS',
    dependencies: ['integrationsStep'],
    id: 8,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'campaignQuality',
    name: 'Campaign Quality',
    role: 'DMS',
    dependencies: ['createSocialDisplayCampaigns'],
    id: 9,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'launchCampaigns',
    name: 'Launch Campaigns',
    role: 'DMS',
    dependencies: ['campaignQuality'],
    id: 10,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'handoffCall',
    name: 'Handoff Call',
    role: 'PM',
    dependencies: ['launchCampaigns'],
    id: 11,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'completeSeoForm',
    name: 'Complete Seo Form',
    role: 'SEO',
    dependencies: ['launchCampaigns'],
    id: 12,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'loginCredentials',
    name: 'SEO Login Credentials',
    role: 'SEO',
    dependencies: ['launchCampaigns'],
    id: 13,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'firstReportingCall',
    name: 'First Reporting Call',
    role: 'AM',
    dependencies: ['handoffCall'],
    id: 14,
    relatedDealField: 'onboardingProgress',
  },
  {
    key: 'tapclicksSetup',
    name: 'Tapclicks Setup',
    role: 'AM',
    dependencies: ['firstReportingCall'],
    id: 15,
    relatedDealField: 'onboardingProgress',
  },
]
export const premiumWebsiteList = [
  {
    key: 'domainAcquisition',
    name: 'Domain Acquisition',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'contentVerification',
    name: 'Content Verification',
    role: 'PM',
    dependencies: ['domainAcquisition'],
    id: 2,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'setupDevSite',
    name: 'Setup Dev Site',
    role: 'PM',
    dependencies: ['contentVerification'],
    id: 3,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'homepageBuild',
    name: 'Homepage Build',
    role: 'PM',
    dependencies: ['setupDevSite'],
    id: 4,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'homepageRevisions',
    name: 'Homepage Revisions',
    role: 'PM',
    dependencies: ['homepageBuild'],
    id: 5,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'subpagesBuild',
    name: 'Subpages Build',
    role: 'PM',
    dependencies: ['homepageRevisions'],
    id: 6,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'subpageRevisions',
    name: 'Subpage Revisions',
    role: 'PM',
    dependencies: ['subpagesBuild'],
    id: 7,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'qualityAssuranceChecks',
    name: 'Quality Assurance Checks',
    role: 'PM',
    dependencies: ['subpageRevisions'],
    id: 8,
    relatedDealField: 'websiteProgress',
  },
  {
    key: 'cutoverToLiveDomain',
    name: 'Cutover to live domain',
    role: 'PM',
    dependencies: ['qualityAssuranceChecks'],
    id: 9,
    relatedDealField: 'websiteProgress',
  },
]
export const newWebsiteList = [
  {
    id: 'NEW-WEBSITE-1',
    title: 'Domain Acquisition',
    status: 'In Progress',
    relatedDealField: 'Domain Acquisition',
  },
  {
    id: 'NEW-WEBSITE-2',
    title: 'Content Verification',
    status: 'In Progress',
    relatedDealField: 'Content Verification',
  },
  {
    id: 'NEW-WEBSITE-3',
    title: 'Setup Live Domain for Development',
    status: 'In Progress',
    relatedDealField: 'Setup Live Domain for Development',
  },
  {
    id: 'NEW-WEBSITE-4',
    title: 'Homepage + Coming Soon page Build',
    status: 'In Progress',
    relatedDealField: 'Homepage + Coming Soon page Build',
  },
  {
    id: 'NEW-WEBSITE-5',
    title: 'Subpage Revisions',
    status: 'In Progress',
    relatedDealField: 'Subpage Revisions',
  },
  {
    id: 'NEW-WEBSITE-6',
    title: 'Subpages Build',
    status: 'In Progress',
    relatedDealField: 'Subpages Build',
  },
  {
    id: 'NEW-WEBSITE-7',
    title: 'Subpage Revisions',
    status: 'In Progress',
    relatedDealField: 'Subpage Revisions',
  },
  {
    id: 'NEW-WEBSITE-8',
    title: 'Quality Assurance Checks',
    status: 'In Progress',
    relatedDealField: 'Quality Assurance Checks',
  },
  {
    id: 'NEW-WEBSITE-3',
    title: 'Cutover to live domain',
    status: 'In Progress',
    relatedDealField: 'Cutover to live domain',
  },
]
export const logoTaskList = [
  {
    key: 'namingBrainstorming',
    name: 'Naming/Brainstorming',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'firstRoundLogoCreationMultipleDesigns',
    name: 'First Round Logo Creation (Multiple Designs)',
    role: 'PM',
    dependencies: ['namingBrainstorming'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'creativePanelReview',
    name: 'Creative Panel Review',
    role: 'PM',
    dependencies: ['firstRoundLogoCreationMultipleDesigns'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'reviewSelections',
    name: 'Review Selections',
    role: 'PM',
    dependencies: ['creativePanelReview'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'brandPDFCreation',
    name: 'Brand PDF Creation',
    role: 'PM',
    dependencies: ['reviewSelections'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientReview',
    name: 'Client Review',
    role: 'PM',
    dependencies: ['brandPDFCreation'],
    id: 6,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientFeedbackRevisions',
    name: 'Client Feedback/Revisions',
    role: 'PM',
    dependencies: ['clientReview'],
    id: 7,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'reviseBrandPDF',
    name: 'Revise Brand PDF',
    role: 'PM',
    dependencies: ['clientFeedbackRevisions'],
    id: 8,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientReview',
    name: 'Client Review',
    role: 'PM',
    dependencies: ['reviseBrandPDF'],
    id: 9,
    relatedDealField: 'creativeProgress',
  },
  // {
  //   key: 'finalSelectionLogo',
  //   name: 'Final Selection (Logo)',
  //   role: 'PM',
  //   dependencies: ['clientReview'],
  //   id: 10,
  //   relatedDealField: 'creativeProgress',
  // },
]
export const brandingGuideTaskList = [
  {
    key: 'assembleWorkIntoBrandTemplate',
    name: 'Assemble work into Brand Template',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'brandMessaging',
    name: 'Brand Messaging',
    role: 'PM',
    dependencies: ['assembleWorkIntoBrandTemplate'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'brandPatterns',
    name: 'Brand Patterns',
    role: 'PM',
    dependencies: ['brandMessaging'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'brandPhotography',
    name: 'Brand Photography',
    role: 'PM',
    dependencies: ['brandPatterns'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
]

export const fullBrandList = [
  {
    key: 'namingBrainstorming',
    name: 'Naming/Brainstorming',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'firstRoundLogoCreationMultipleDesigns',
    name: 'First Round Logo Creation (Multiple Designs)',
    role: 'PM',
    dependencies: ['namingBrainstorming'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'creativePanelReview',
    name: 'Creative Panel Review',
    role: 'PM',
    dependencies: ['firstRoundLogoCreationMultipleDesigns'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'reviewSelections',
    name: 'Review Selections',
    role: 'PM',
    dependencies: ['creativePanelReview'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'brandPDFCreation',
    name: 'Brand PDF Creation',
    role: 'PM',
    dependencies: ['reviewSelections'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientReview',
    name: 'Client Review',
    role: 'PM',
    dependencies: ['brandPDFCreation'],
    id: 6,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientFeedbackRevisions',
    name: 'Client Feedback/Revisions',
    role: 'PM',
    dependencies: ['clientReview'],
    id: 7,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'reviseBrandPDF',
    name: 'Revise Brand PDF',
    role: 'PM',
    dependencies: ['clientFeedbackRevisions'],
    id: 8,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientReviewFinal',
    name: 'Client Review (Final)',
    role: 'PM',
    dependencies: ['reviseBrandPDF'],
    id: 9,
    relatedDealField: 'creativeProgress',
  },
  // {
  //   key: 'finalSelectionFullBrand',
  //   name: 'Final Selection (Full Brand Build)',
  //   role: 'PM',
  //   dependencies: ['clientReviewFinal'],
  //   id: 10,
  //   relatedDealField: 'creativeProgress',
  // },
]
export const photoVideoTravelTaskList = [
  {
    key: 'scheduleShoot',
    name: 'Schedule Shoot',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'travelAcommodationsIfApplicable',
    name: 'Travel/Acommodations (if applicable)',
    role: 'PM',
    dependencies: ['scheduleShoot'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'bookModels',
    name: 'Book Models\t',
    role: 'PM',
    dependencies: ['travelAcommodationsIfApplicable'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'shootPrep',
    name: 'Shoot Prep\t',
    role: 'PM',
    dependencies: ['bookModels'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'showUpSetUp',
    name: 'Show up/Set up',
    role: 'PM',
    dependencies: ['shootPrep'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'shoot',
    name: 'Shoot\t',
    role: 'PM',
    dependencies: ['showUpSetUp'],
    id: 6,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'transferFootage',
    name: 'Transfer Footage',
    role: 'PM',
    dependencies: ['shoot'],
    id: 7,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'cullingPhotos',
    name: 'Culling Photos',
    role: 'PM',
    dependencies: ['transferFootage'],
    id: 8,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'edit',
    name: 'Edit',
    role: 'PM',
    dependencies: ['cullingPhotos'],
    id: 9,
    relatedDealField: 'creativeProgress',
  },
  // {
  //   key: 'finalSelectionPhotoVideo',
  //   name: 'Final Selection {Photo & Video)',
  //   role: 'PM',
  //   dependencies: ['edit'],
  //   id: 10,
  //   relatedDealField: 'creativeProgress',
  // },
  {
    key: 'sendToAMPMBDE',
    name: 'Send to AM/PM/BDE',
    role: 'PM',
    dependencies: ['edit'],
    id: 11,
    relatedDealField: 'creativeProgress',
  },
]

export const photoVideoLocalTaskList = [
  {
    key: 'scheduleShoot',
    name: 'Schedule Shoot',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'showUpSetUp',
    name: 'Show up/Set up',
    role: 'PM',
    dependencies: ['scheduleShoot'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'shootPrepShoot',
    name: 'Shoot Prep\tShoot',
    role: 'PM',
    dependencies: ['showUpSetUp'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'transferFootageEdit',
    name: 'Transfer Footage/Edit',
    role: 'PM',
    dependencies: ['shootPrepShoot'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'sendToAMPMBDE',
    name: 'Send to AM/PM/BDE',
    role: 'PM',
    dependencies: ['transferFootageEdit'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
]

export const shootEquipmentChecklist = [
  {
    key: 'bothCameras',
    title: 'Both Cameras',
    status: 'In Progress',
    id: 'EQUIP-2',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'cameraBags',
    title: 'Camera Bags',
    status: 'In Progress',
    id: 'EQUIP-3',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'memoryCardsWiped',
    title: 'Memory Cards wiped',
    status: 'In Progress',
    id: 'EQUIP-4',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'allBatteriesCharged',
    title: 'All Batteries Charged',
    status: 'In Progress',
    id: 'EQUIP-5',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'flash',
    title: 'Flash',
    status: 'In Progress',
    id: 'EQUIP-6',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'extraBatteriesForFlash',
    title: 'Extra Batteries for Flash',
    status: 'In Progress',
    id: 'EQUIP-7',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'shotListLocationIdeas',
    title: 'Shot List/Location/Ideas',
    status: 'In Progress',
    id: 'EQUIP-8',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'tripodsIfNecessary',
    title: 'Tripods if necessary',
    status: 'In Progress',
    id: 'EQUIP-9',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'lightequipmentIfNecessary',
    title: 'Light/equipment if necessary',
    status: 'In Progress',
    id: 'EQUIP-10',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'drone',
    title: 'Drone',
    status: 'In Progress',
    id: 'EQUIP-11',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'droneBatteriesCharged',
    title: 'Drone batteries Charged',
    status: 'In Progress',
    id: 'EQUIP-12',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'droneUpdated',
    title: 'Drone updated',
    status: 'In Progress',
    id: 'EQUIP-13',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'controllerCharged',
    title: 'Controller Charged',
    status: 'In Progress',
    id: 'EQUIP-14',
    relatedDealField: 'equipmentProgress',
  },
  {
    key: 'memoryCardWiped',
    title: 'Memory Card Wiped',
    status: 'In Progress',
    id: 'EQUIP-15',
    relatedDealField: 'equipmentProgress',
  },
]

export const createCampaignTaskList = [
  {
    key: 'namingBrainstorming',
    name: 'Naming/Brainstorming',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'ideationIfApplicable',
    name: 'Ideation (If applicable)',
    role: 'PM',
    dependencies: ['namingBrainstorming'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'creativeExampleFontsPatternsColorsEtc',
    name: 'Creative Example (Fonts, Patterns, Colors, etc.',
    role: 'PM',
    dependencies: ['ideationIfApplicable'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'clientReview',
    name: 'Client Review',
    role: 'PM',
    dependencies: ['creativeExampleFontsPatternsColorsEtc'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'designCollateral',
    name: 'Design Collateral',
    role: 'PM',
    dependencies: ['clientReview'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'feebackRevisions',
    name: 'Feeback/Revisions',
    role: 'PM',
    dependencies: ['designCollateral'],
    id: 6,
    relatedDealField: 'creativeProgress',
  },
]

export const collateralTaskList = [
  {
    key: 'mockupEnvelopes',
    name: 'Mock-up: Envelopes',
    role: 'PM',
    dependencies: [],
    id: 1,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'mockupBusinessCards',
    name: 'Mock-up: Business Cards',
    role: 'PM',
    dependencies: ['mockupEnvelopes'],
    id: 2,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'mockupLetterhead',
    name: 'Mock-up: Letterhead',
    role: 'PM',
    dependencies: ['mockupBusinessCards'],
    id: 3,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'mockupSocialCoverPhotoFBXLinkedin',
    name: 'Mock-up: Social Cover Photo (FB, X, Linkedin)',
    role: 'PM',
    dependencies: ['mockupLetterhead'],
    id: 4,
    relatedDealField: 'creativeProgress',
  },
  {
    key: 'mockupBusinessProfilePictureForSocial',
    name: 'Mock-up: Business Profile Picture for Social',
    role: 'PM',
    dependencies: ['mockupSocialCoverPhotoFBXLinkedin'],
    id: 5,
    relatedDealField: 'creativeProgress',
  },
]
export const amTaskList = [
  {
    key: 'checkInEmail',
    name: 'Bi-Weekly check in email',
    role: 'AM',
    dependencies: [],
    id: 1,
    relatedDealField: 'amProgress',
    relatedOrgField: 'amProgress',
  },
  {
    key: 'reportingCall',
    name: 'Reporting Call',
    role: 'AM',
    dependencies: [],
    id: 2,
    relatedDealField: 'amProgress',
    relatedOrgField: 'amProgress',
  },
  {
    key: 'budgetAudits',
    name: 'Budget Audits',
    role: 'AM',
    dependencies: [],
    id: 3,
    relatedDealField: 'amProgress',
    relatedOrgField: 'amProgress',
  },
]
// DMS : have you left an optimization or observation and have you done the budget audit to be 100%
export const dmsTaskList = [
  {
    key: 'optOrObserveCreated',
    name: 'Observation/Optimization Made',
    role: 'DMS',
    dependencies: [],
    id: 1,
    relatedDealField: 'dmsProgress',
    relatedOrgField: 'dmsProgress',
  },
  {
    key: 'budgetAudits',
    name: 'Budget Audits',
    role: 'DMS',
    dependencies: [],
    id: 2,
    relatedDealField: 'dmsProgress',
    relatedOrgField: 'dmsProgress',
  },
  {
    key: 'insightsReportingCall',
    name: 'Insights for AM Reporting Call',
    role: 'DMS',
    dependencies: [],
    id: 3,
    relatedDealField: 'dmsProgress',
    relatedOrgField: 'dmsProgress',
  },
]
