import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ncoStatusFilters } from '../DataGrid/filterArrays.js'
import { useTheme } from '@mui/material'
import renderMtbColumns from '../DataGrid/createColumns/renderMtbColumns.js'
import { mtbInitialVisibilityState } from '../DataGrid/createColumns/gridViewStates.ts'
import CampaignChecklistDataGrid from '../DataGrid/CampaignChecklistDataGrid.js'
import { useOrganizationsQuery } from '../../api/aws/organizations/useOrganizationsQuery.ts'
import { useMasterApi } from '../../api/aws/master/useMasterApi.ts'

const initialSortModel = [{ field: 'name', sort: 'asc' }]

const CampaignChecklistPage = () => {
  const { data: queriedOrganizations, isLoading: isQueriedOrganizationsLoading } = useOrganizationsQuery(undefined, [
    'META',
  ])
  const { data: masterData, isLoading: isMasterDataLoading } = useMasterApi(true)
  useEffect(() => {
    if (!isMasterDataLoading && masterData) {
      console.log('masterData', masterData)
    }
  }, [masterData])
  useEffect(() => {
    if (!isQueriedOrganizationsLoading && queriedOrganizations) {
      console.log('queriedOrganizations', queriedOrganizations)
    }
  }, [queriedOrganizations])

  const theme = useTheme()
  const { orgId } = useParams()

  const filters = {
    status: [
      'Active',
      'Active (system)',
      'In Progress',
      'Month To Month',
      '30 Day Notice',
      'Active (system)',
      'Active (SEO)',
      'Active (Creative)',
      'Active (Website)',
      'Active (LTP)',
    ],
  }
  return (
    <>
      <CampaignChecklistDataGrid
        orgId={orgId}
        filters={filters}
        queryKey={['campaignChecklistPage', 'deals']}
        route={'campaignChecklists'}
        context={'campaignChecklists'}
        initialVisibilityState={mtbInitialVisibilityState}
        initialSortModel={initialSortModel}
        statusKey={'onboardingStatus'}
        statusLabel={'Onboarding Status'}
        filterOptions={ncoStatusFilters}
        boardTheme={theme.palette.icon.campaignChecklist}
      />
    </>
  )
}
export default CampaignChecklistPage
