import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { ncoStatusFilters } from '../DataGrid/filterArrays.js'
import { websiteTasks } from '../DataGrid/taskTemplates.js'
import renderWebsiteColumns from '../DataGrid/createColumns/renderWebsiteColumns.js'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  slaDays: false,
  completedCounts: false,
  estLaunchDate: false,
  dateLaunched: false,
}

const WebsitePage = () => {
  const { dealId } = useParams()
  const filters = {
    status: 'Completed',
    type: ['CSA', 'MSA', 'MSA - Housing'],
    productCategories: ['Website'],
    // productSubcategories: [
    //   'Videography',
    //   'Graphic Design', // Filters deals with Search category in products
    // ],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  return (
    <GroboticDataGrid
      dealId={dealId}
      filters={filters}
      queryKey={['website', 'deals']}
      route={'website'}
      tasks={websiteTasks}
      context={'website'}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'status'}
      statusLabel={'Website Status'}
      filterOptions={ncoStatusFilters}
      renderFunction={renderWebsiteColumns}
    />
  )
}
export default WebsitePage
