import Checkbox from 'antd/es/checkbox/Checkbox'
import { styled } from '@mui/material/styles'
import { theme } from 'antd'

export const dataGridStyles = theme => ({
  border: 0,
  maxHeight: '100vh',
  maxWidth: '95vw',
  boxShadow: theme.shadows,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    backgroundColor: theme.palette.mode === 'light' ? '#f9f8ff' : 'rgba(30, 39, 53, 0.65)',
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
})
export const gridContainerStyles = {
  display: 'flex',
  maxHeight: '100vh',
  // width: '100%',
  justifyContent: 'center',
  // alignItems: 'center',
}
export const gridToolbarContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}
export const fieldStyles = fieldBgColor => ({
  width: '100%',
  // height: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: fieldBgColor,
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-input': {
    // backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on hover
  },
})
export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    padding: '4px', // Reduce padding
  },
  '& .MuiSvgIcon-root': {
    width: 18,
    height: 18,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
    borderRadius: 2,
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    backgroundColor: '#1890ff',
    borderColor: '#1890ff',
  },
  '&.Mui-checked .MuiSvgIcon-root path': {
    display: 'none',
  },
  '&.Mui-checked:after': {
    content: '""',
    position: 'absolute',
    width: 5.7,
    height: 9.1,
    border: '2px solid #fff',
    borderTop: 0,
    borderLeft: 0,
    transform: 'rotate(45deg) translate(-50%, -50%)',
    top: '50%',
    left: '39%',
  },
  '&.MuiCheckbox-indeterminate .MuiSvgIcon-root': {
    backgroundColor: '#1890ff',
  },
  // '& .MuiCheckbox-root svg': {
  //   width: 16,
  //   height: 16,
  //   backgroundColor: 'transparent',
  //   border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
  //   borderRadius: 2,
  // },
  // '& .MuiCheckbox-root svg path': {
  //   display: 'none',
  // },
  // '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
  //   backgroundColor: '#1890ff',
  //   borderColor: '#1890ff',
  // },
  // '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
  //   position: 'absolute',
  //   display: 'table',
  //   border: '2px solid #fff',
  //   borderTop: 0,
  //   borderLeft: 0,
  //   transform: 'rotate(45deg) translate(-50%,-50%)',
  //   opacity: 1,
  //   transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
  //   content: '""',
  //   top: '50%',
  //   left: '39%',
  //   width: 5.71428571,
  //   height: 9.14285714,
  // },
  // '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
  //   width: 8,
  //   height: 8,
  //   backgroundColor: '#1890ff',
  //   transform: 'none',
  //   top: '39%',
  //   border: 0,
  // },
}))
