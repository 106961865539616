import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import UserImageComponent from '../../Users/UserImageComponent'
import { userImageStyles, getDensityStyles } from '../style'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import RenderCompanyChip from '../../RenderChipComponents/RenderCompanyChip'
import { formatPhoneNumber } from '../../Contacts/formatPhoneNumber'
import { getGridStringOperators } from '@mui/x-data-grid-pro'

export const formatDayjsDate = dateStr => {
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}

export const renderContactsColumns = (organizations = [], rows = [], users = [], density = 'standard') => {
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        return null
      }
      return cellValue => !containsFn(cellValue)
    },
  }
  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    if (!name || typeof name !== 'string') {
      // Handle cases where name is undefined, null, or not a string
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            N/A
          </Typography>
        ),
      }
    }

    const nameParts = name.split(' ')

    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }

    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }

  return [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.3,
      minWidth: 150,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
            padding: densityStyles.padding,
            gap: densityStyles.gap,
          }}
        >
          <Link
            to={`/contacts/${params.row.contacts_uuid}/details`}
            style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold', fontSize: '1rem' }}
          >
            {params.row.photoUrl ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              >
                <Avatar
                  src={params.row.photoUrl}
                  sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                  alt={params.value}
                />

                <Typography>{params.value}</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              >
                <Avatar
                  {...stringAvatar(params.value)}
                  sx={{ backgroundColor: '#ffbcdc', width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                  alt={params.value}
                />

                <Typography>{params.value}</Typography>
              </Box>
            )}
          </Link>
        </Box>
      ),
    },
    {
      field: 'organizations',
      headerName: 'Organization',
      flex: 0.2,
      minWidth: 150,
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg = organizations.find(org => org.id === orgId)
        // return { orgId: orgId, arrayOrg: arrayOrg }
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params &&
          params.row &&
          params.row.organizations &&
          Array.isArray(params.row.organizations) &&
          params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const currentOrg = organizations.find(org => org.org_uuid === orgId)
        const orgName = params && params.value ? params.value : ''

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>
              {currentOrg && orgId ? (
                <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {/* <Box>{orgName}</Box> */}
                  <RenderCompanyChip option={currentOrg} />
                </Link>
              ) : null}
              {/* {orgId ? (
              <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box>{orgName}</Box>
              </Link>
            ) : (
              <Box>No Organization Assigned</Box>
            )} */}
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'billingContact',
      headerName: 'Billing Contact',
      flex: 0.1,
      minWidth: 100,
      valueGetter: params => {
        return params ?? params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const isBillingContact =
          params && params.value ? params.value : params?.row?.isBillingContact ? params?.row?.isBillingContact : false

        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {isBillingContact ? (
              <CheckIcon sx={{ color: 'rgb(5, 190, 113)' }} />
            ) : (
              <CloseIcon sx={{ color: 'crimson' }} />
            )}
          </Box>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.2,
      minWidth: 100,
    },
    // {
    //   field: 'firstName',
    //   headerName: 'First Name',
    //   flex: 0.2,
    //   minWidth: 100,
    // },
    // {
    //   field: 'lastName',
    //   headerName: 'Last Name',
    //   flex: 0.2,
    //   minWidth: 100,
    // },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 0.2,
      minWidth: 100,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <>
            {params && params.value && params.value !== 'No Phone' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box>{formatPhoneNumber(params.value)}</Box>
              </Box>
            ) : (
              <Box sx={{ color: 'rgba(255,255,255,0.5)' }}>No Phone Number</Box>
            )}
          </>
        )
      },
    },
    {
      field: 'timeCreated',
      headerName: 'Created Time',
      flex: 0.2,
      minWidth: 100,
      hidden: true,
      renderCell: params => {
        return <Box>{formatDayjsDate(params.value)}</Box>
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 0.2,
      minWidth: 100,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const userId = params && params[0] ? params[0] : null
        const user = users.find(u => u.id === userId)
        return user && user.name ? user.name : 'No User Assigned'
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const userId = params && params.row && params.row.owner ? params.row.owner[0] : null
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box>No User Assigned</Box>
            )}
          </Box>
        )
      },
    },
  ]
}

export default renderContactsColumns
