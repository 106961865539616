import React from 'react'
import { Typography } from '@mui/material'

export const formatTooltipContent = (description: string) => {
  const lines = description
    .split('\n')
    .map(line => line.trim())
    .filter(line => line !== '')
  const elements: React.ReactNode[] = []
  let currentList: string[] = []

  const flushList = (keyBase: string) => {
    if (currentList.length) {
      elements.push(
        <ul key={keyBase}>
          {currentList.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      )
      currentList = []
    }
  }

  lines.forEach((line, index) => {
    if (line.startsWith('- ')) {
      currentList.push(line.substring(2))
    } else {
      flushList(`list-${index}`)
      if (line.endsWith(':')) {
        elements.push(
          <Typography key={index} style={{ color: 'rgb(249, 167, 49)', fontWeight: 'bold' }}>
            {line}
          </Typography>
        )
      } else {
        elements.push(<Typography key={index}>{line}</Typography>)
      }
    }
  })

  flushList('final-list')
  return <div>{elements}</div>
}
