// useDeleteOptimization.ts
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'

interface DeleteOptimizationVars {
  optPK: string
  optSK: string
  orgId: string
}

export const useDeleteOptimization = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()
  const mutation = useMutation<void, Error, DeleteOptimizationVars, { previousOpt: any; previousList: any }>({
    mutationFn: async ({ optPK, optSK }) => {
      const encodedPK = encodeURIComponent(optPK)
      const encodedSK = encodeURIComponent(optSK)
      const url = `${baseUrl}/aws/optimizations/${encodedPK}/${encodedSK}`
      console.log('Deleting optimization with:', { optPK, optSK })
      const response = await axios.delete(url)
      return response.data
    },

    // Optimistic update: remove the optimization from both individual and list caches.
    onMutate: async ({ optPK, optSK, orgId }) => {
      // Build cache keys
      const optKey = ['optimization', optPK, optSK]
      const listKey = ['optsByOrgId', orgId]

      // Cancel any outgoing refetches for these keys.
      await queryClient.cancelQueries({ queryKey: optKey })
      await queryClient.cancelQueries({ queryKey: listKey })

      // Snapshot the previous data for rollback.
      const previousOpt = queryClient.getQueryData(optKey)
      const previousList = queryClient.getQueryData(listKey)

      // Remove the individual optimization from its cache.
      queryClient.setQueryData(optKey, undefined)

      // Remove the optimization from the list.
      queryClient.setQueryData(listKey, (oldData: any[] | undefined) => {
        if (!oldData) return oldData
        return oldData.filter(opt => !(opt.PK === optPK && opt.SK === optSK))
      })

      return { previousOpt, previousList }
    },
    onError: (err, { optPK, optSK, orgId }, context) => {
      // Roll back to previous state if deletion fails.
      const optKey = ['optimization', optPK, optSK]
      const listKey = ['optsByOrgId', orgId]
      if (context?.previousOpt) {
        queryClient.setQueryData(optKey, context.previousOpt)
      }
      if (context?.previousList) {
        queryClient.setQueryData(listKey, context.previousList)
      }
    },
    onSuccess: (_, { optPK, optSK, orgId }) => {
      showSnackbar('Optimization deleted successfully', 'success')
      // Invalidate queries so that data is refetched if needed.
      const optKey = ['optimization', optPK, optSK]
      const listKey = ['optsByOrgId', orgId]
      // queryClient.invalidateQueries({ queryKey: optKey })
      // queryClient.invalidateQueries({ queryKey: listKey })
    },
  })
  return mutation
}
