import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'

interface MonthYearFilterProps {
  value: string // Expected in "YYYY-MM" format (empty string means no filter)
  onChange: (value: string) => void
  availableYears?: number[]
}

const MonthYearFilter: React.FC<MonthYearFilterProps> = ({ value, onChange, availableYears }) => {
  const currentYear = new Date().getFullYear()
  // By default, include the current year and several past years.
  const years = availableYears || [
    currentYear - 5,
    currentYear - 4,
    currentYear - 3,
    currentYear - 2,
    currentYear - 1,
    currentYear,
    currentYear + 1,
  ]
  // Sort ascending, if desired.
  const sortedYears = [...years].sort((a, b) => a - b)

  const months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ]

  // Parse the current value into year and month.
  // If value is empty, default to current year and an empty month.
  const [selectedYear, selectedMonth] = value ? value.split('-') : [currentYear.toString(), '']

  const handleYearChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newYear = e.target.value as string
    // Combine with currently selected month (if any)
    onChange(newYear + (selectedMonth ? '-' + selectedMonth : ''))
  }

  const handleMonthChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newMonth = e.target.value as string
    onChange(selectedYear + '-' + newMonth)
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <FormControl variant='outlined' size='small' sx={{ width: 100 }}>
        <InputLabel id='year-filter-label'>Year</InputLabel>
        <Select
          labelId='year-filter-label'
          id='year-filter'
          label='Year'
          value={selectedYear || currentYear.toString()}
          onChange={handleYearChange}
        >
          {sortedYears.map(year => (
            <MenuItem key={year} value={year.toString()}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant='outlined' size='small' sx={{ width: 150 }}>
        <InputLabel id='month-filter-label'>Month</InputLabel>
        <Select
          labelId='month-filter-label'
          id='month-filter'
          label='Month'
          value={selectedMonth || ''}
          onChange={handleMonthChange}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {months.map(month => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MonthYearFilter
