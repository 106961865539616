import React, { useEffect, useState, useRef, useMemo } from 'react'
import { GridToolbarContainer, GridToolbarDensitySelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { Badge, Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles, StyledGridToolbarQuickFilter } from './style'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { ncoStatusFilters } from '../DataGrid/filterArrays'
import { useNavigate } from 'react-router-dom'
import ResetFiltersButton from '../ui/reset-filters-button'
import ResetSortingButton from '../ui/reset-sorting-button'
import ResetColumnsButton from '../ui/reset-columns-button'
import OpenColumnsMenu from '../ui/open-columns-menu'
import OpenFiltersMenu from '../ui/open-filters-menu'
import ExportCsvButton from '../ui/export-csv-button'
import ChangeBoardView from './ChangeBoardView'
import CustomFiltersModal from './components/CustomFiltersModal.tsx'
import CustomFiltersMenu from '../ui/custom-filters-menu-button.tsx'
import { slotProps } from './style'
import { useChangeView } from '../../utility/useChangeView.ts'
import FilterMyDataButton from '../ui/filter-my-data-button.tsx'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import UserImageComponent from '../Users/UserImageComponent.js'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { seoViewState } from './createColumns/gridViewStates.ts'

const projectManagerViewState = {
  // isGoogleDisplay: true,
  // isGoogleSearch: true,
  // isLinkedIn: true,
  // isMetaAdvertising: true,
  // isMicrosoftAdvertising: true,
  // isSimplifi: true,
  // isSnapchat: true,
  // isSpotify: true,
  // isTikTok: true,
  // isYoutube: true,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  // organizations: true,
  // onboardingStatus: true,
  type: false,
  timeCompleted: false,
  dependenciesDueDate: false,
  slaDays: false,
  estLaunchDate: false,
  dateLaunched: false,
  startDate: false,
  endDate: false,
  // contractId: true,
  contractedBy: false,
  strategy: false,
  status: false,
  id: false,
  stage: false,
  lastModified: false,
  products: false,
  owner: false,
  implementationFee: false,
  total: false,
  // needs to be added
  vertical: false,
  creatives: false,
  // seoStatus: true,
  creativeLead: false,
  // AM: true,
  // DMS: true,
  // SEO: true,
  // MIA: true,
  // QA: true,
  // PM: true,
}
const isSortModelEqual = (sortModel1, sortModel2) => {
  if (sortModel1.length !== sortModel2.length) return false
  return sortModel1.every((sortItem, index) => {
    const initialSortItem = sortModel2[index]
    return sortItem.field === initialSortItem.field && sortItem.sort === initialSortItem.sort
  })
}
const BoardsGridToolbar = ({
  filterModel,
  boardIcon,
  selectionModel,
  // isTaskView,
  // onChangeBoardView,
  initialVisibilityState,
  visibilityModel,
  initialSortModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  onChangeVisibility,
  statusKey,
  statusLabel,
  filterOptions,
  context,
  boardTheme,
}) => {
  const theme = useTheme()
  const handleChangeView = useChangeView()
  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
  const gridFilterBgColor = theme.palette.mode === 'dark' ? theme.palette.primary.main : 'rgba(0,0,0,0.1)'
  const slotPropsColor = theme.palette.text.primary
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [ncoFilterStatus, setNcoFilterStatus] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [viewsAnchorEl, setViewsAnchorEl] = useState(null)
  const [isCustomFiltersOpen, setCustomFiltersOpen] = useState(false)
  const [isCustomViewsOpen, setCustomViewsOpen] = useState(false)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { profile } = activeUser?.user
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  const isSortModelChanged = useMemo(() => {
    return !isSortModelEqual(sortModel, initialSortModel)
  }, [sortModel, initialSortModel])
  // const [selectedView, setSelectedView] = useState(() => {
  //   const savedView = localStorage.getItem(`${context}SelectedView`)
  //   return savedView ? JSON.parse(savedView) : `${context}`
  // })
  const [selectedView, setSelectedView] = useState(() => {
    const savedView = localStorage.getItem(`boardSelectView`)
    return savedView ? JSON.parse(savedView) : `${context}`
  })
  const apiRef = useGridApiContext() // Get DataGrid API

  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }
  ///////////////////////////////////////////////////////////////////////////\
  // Utility function to check if two sort models are deeply equal

  // Handle keydown event to focus the quick filter on Ctrl+Shift+F or Cmd+Shift+F
  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])
  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }
  const handleChangeVisibility = newModel => {
    onChangeVisibility(newModel)
  }
  const handleSetStatusFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }

      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }
  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({ items: [], quickFilterValues: [] })
    setNcoFilterStatus('')
    setFilterStage('')
  }
  const handleOpenCustomFilters = event => {
    setAnchorEl(event.currentTarget) // Attach the popover to the button
    setCustomFiltersOpen(true)
  }
  const handleOpenCustomViews = event => {
    setViewsAnchorEl(event.currentTarget) // Attach the popover to the button
    setCustomViewsOpen(true)
  }
  const handleCloseCustomFilters = () => {
    setAnchorEl(null) // Clear the anchor when closing
    setCustomFiltersOpen(false)
  }
  const handleCloseCustomViews = () => {
    setViewsAnchorEl(null) // Clear the anchor when closing
    setCustomViewsOpen(false)
  }
  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            ml: 2,
          }}
        >
          <ChangeBoardView
            context={context}
            onChangeView={newView => handleChangeView({ newView })}
            selectedView={selectedView ?? `${context}SelectedView`}
            setSelectedView={setSelectedView}
          />
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
          <OpenFiltersMenu onOpenFilters={handleOpenFilterPanel} filterModel={filterModel} badgeColor={boardTheme} />
          <ExportCsvButton onExport={handleExport} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CustomFiltersMenu
            icon={<VisibilityIcon sx={{ fontSize: '30px' }} />}
            onOpenCustomFilters={handleOpenCustomViews}
            count={filterModel?.items?.length ?? 0}
            badgeColor={boardTheme}
          />
          <CustomFiltersModal
            isOpen={isCustomViewsOpen}
            anchorEl={viewsAnchorEl}
            handleCloseCustomFilters={handleCloseCustomViews}
          >
            <Stack gap={1}>
              <Button onClick={() => handleChangeVisibility(projectManagerViewState)} variant='outlined'>
                PM VIEW
              </Button>
              <Button onClick={() => handleChangeVisibility(seoViewState)} variant='outlined'>
                SEO VIEW
              </Button>
            </Stack>
          </CustomFiltersModal>
          <CustomFiltersMenu
            onOpenCustomFilters={handleOpenCustomFilters}
            count={filterModel?.items?.length ?? 0}
            badgeColor={boardTheme}
          />

          {/* Custom Filters Popover */}
          <CustomFiltersModal
            isOpen={isCustomFiltersOpen}
            anchorEl={anchorEl}
            handleCloseCustomFilters={handleCloseCustomFilters}
          >
            <CustomStatusFilter
              options={filterOptions ?? ncoStatusFilters}
              setterFn={setStatusFilter ?? setNcoFilterStatus}
              filterModel={filterModel}
              value={statusFilter ?? ncoFilterStatus}
              onChange={handleSetStatusFilter}
              // context={statusKey}
              context={'onboardingStatus'}
              label={statusLabel ?? 'No Label'}
            />
          </CustomFiltersModal>

          <FilterMyDataButton
            icon={
              <UserImageComponent
                imageUrl={profile?.image_512}
                // width={width}
                // height={height}
                // opacity={opacity}
                isLoading={isActiveUserLoading}
              />
            }
            filterModel={filterModel}
            onClick={handleFilterMyData}
            label={'My Items'}
          />
          <ResetColumnsButton
            visibilityModel={visibilityModel}
            onClearVisibility={onClearVisibility}
            initialVisibilityState={initialVisibilityState}
          />
          <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
          <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
          <Badge
            badgeContent={
              <>
                {filterModel &&
                filterModel.quickFilterValues &&
                Array.isArray(filterModel.quickFilterValues) &&
                filterModel.quickFilterValues.length > 0 ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                      bgcolor: boardTheme,
                      p: 0.5,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      display: 'flex', // Flexbox centering
                      alignItems: 'center', // Vertically center
                      justifyContent: 'center', // Horizontally center
                      color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                      fontWeight: 'bold',
                    }}
                  >
                    {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                      ? filterModel.quickFilterValues.length
                      : null}
                  </Typography>
                ) : null}
              </>
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
          </Badge>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default BoardsGridToolbar
/*
Meta: creative assets, fb page,
Google Display(social or display categories): creative assets,
ALL: GA4, GTM, Integration
SEO : google bus prof, website access

*/
