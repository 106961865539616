import { Checkbox, Avatar, AvatarGroup, Typography, Box } from '@mui/material'
import UserImageComponent from './UserImageComponent'

export const renderTeamOption = (props, option, { selected } = {}, theme) => {
  const { key, id, ...rest } = props
  return (
    <Box
      key={`${key}-${id}`}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}
      {...rest}
    >
      {option.img_link || option.image_48 ? (
        <UserImageComponent imageUrl={option.img_link || option.image_48} style={{ marginRight: 8 }} />
      ) : (
        <Avatar style={{ marginRight: 8 }} />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          {option.name || option.Name || `${option?.first_name} ${option?.last_name}`}
        </Typography>
        {option?.role && (
          <Typography
            sx={{
              color: theme.palette.text.success,
              fontSize: '13px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              textAlign: 'left',
              width: '100%',
            }}
          >{`${option?.role}`}</Typography>
        )}
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '11px',
            fontStyle: 'italic',
            textAlign: 'left',
            width: '100%',
          }}
        >
          {option.email || option.Email || ''}
        </Typography>

        {option?.title && (
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: '11px',
              fontStyle: 'italic',
              textAlign: 'left',
              width: '100%',
            }}
          >{`${option?.title}`}</Typography>
        )}
      </Box>
    </Box>
  )
}

export const renderUserOption = (props, option, { selected } = {}, theme) => {
  const { key, id, ...rest } = props
  return (
    <Box
      key={`${key}-${id}`}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}
      {...rest}
    >
      {option.img_link || option.image_48 ? (
        <UserImageComponent imageUrl={option.img_link || option.image_48} style={{ marginRight: 8 }} />
      ) : (
        <Avatar style={{ marginRight: 8 }} />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          {option.name || option.Name || `${option?.first_name} ${option?.last_name}`}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '11px',
            fontStyle: 'italic',
            textAlign: 'left',
            width: '100%',
          }}
        >
          {option.email || option.Email || ''}
        </Typography>
        {option?.panda_contact_id && (
          <Typography
            sx={{
              color: theme.palette.text.success,
              fontSize: '11px',
              fontStyle: 'italic',
              textAlign: 'left',
              width: '100%',
            }}
          >{`${option?.panda_contact_id}`}</Typography>
        )}
        {option?.id && (
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: '11px',
              fontStyle: 'italic',
              textAlign: 'left',
              width: '100%',
            }}
          >{`${option?.id}`}</Typography>
        )}
      </Box>
    </Box>
  )
}

export const renderUserTags = value => {
  console.log('renderUserTags: value: ', value)
  return (
    <AvatarGroup max={2} spacing='small'>
      {value.map(
        option =>
          option &&
          (option.img_link || option.image_48 ? (
            <UserImageComponent
              imageUrl={option.img_link || option.image_48}
              key={option.gusers_uuid || option.id || option.user_uuid}
            />
          ) : (
            <Avatar key={option.gusers_uuid || option.id || option.user_uuid} />
          ))
      )}
    </AvatarGroup>
  )
}
