import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, useTheme, CircularProgress } from '@mui/material'
import MyDeals from './MyDeals'
import MyOrganizations from './MyOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { getDeals, useDeals } from '../../api/aws/useDeals'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useQuery } from '@tanstack/react-query'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { useMasterTable } from '../../api/aws/useMasterTable'
import { useOrganizationsQuery } from '../../api/aws/organizations/useOrganizationsQuery.ts'
import { useOrganizationItems } from '../../api/aws/organizations/useOrganizationItems.ts'
import OrganizationsList from './OrganizationsPaginated.tsx'
import { usePaginateOrganizations } from '../../api/aws/organizations/usePaginateOrganizations.ts'
import { useContactsTable } from '../../api/aws/contacts/useContactsTable.ts'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const DashboardPage = () => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user: { profile: { real_name: username } = {}, id: userId } = {} } = activeUser || {}
  const [selectedUserId, setSelectedUserId] = useState(userId)
  const [selectedMonth, setSelectedMonth] = useState('')
  // const { deals, isDealsLoading, isDealsError } = useDeals(true, ['dashboard', 'deals'])
  const { data: deals, isLoading: isDealsLoading, isError: isDealsError } = useQuery({
    queryKey: ['deals'],
    queryFn: () => getDeals('/aws/deals', 'deals'),
  })
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError,
  } = useOrganizations(true, ['dashboard', 'organizations'])
  const orgId = 'c7982d02-753c-4deb-94fc-8726729402eb'
  // const { data: organizationItems, isLoading: isOrganizationItemsLoading } = useOrganizationItems(`ORG#${orgId}`)

  // const { data: queriedOrganizations, isLoading: isQueriedOrganizationsLoading } = useOrganizationsQuery(undefined, [
  //   'META',
  //   'OWNER',
  //   'AFFILIATED',
  //   'PRIMARY_CONTACT',
  //   'CONTRACTED_BY',
  //   'ADDITIONAL_CONTACT',
  //   'DEAL',
  // ])
  // useEffect(() => {
  //   if (!isOrganizationItemsLoading && organizationItems) {
  //     console.log('organizationItems', organizationItems)
  //   }
  // }, [organizationItems])
  // useEffect(() => {
  //   if (!isQueriedOrganizationsLoading && queriedOrganizations) {
  //     console.log('queriedOrganizations', queriedOrganizations)
  //   }
  // }, [queriedOrganizations])
  // const {
  //   data: paginatedOrgs,
  //   isLoading: isPaginatedOrgsLoading,
  //   isError: isPaginatedOrgsError,
  //   fetchNextPage,
  //   hasNextPage,
  // } = usePaginateOrganizations(undefined, [
  //   'META',
  //   'OWNER',
  //   'AFFILIATED',
  //   'PRIMARY_CONTACT',
  //   'CONTRACTED_BY',
  //   'ADDITIONAL_CONTACT',
  //   'DEAL',
  // ])
  // useEffect(() => {
  //   if (!isPaginatedOrgsLoading && paginatedOrgs) {
  //     console.log('paginatedOrgs', paginatedOrgs)
  //     console.log('fetchNextPage', fetchNextPage)
  //     console.log('hasNextPage', hasNextPage)
  //   }
  // }, [paginatedOrgs])
  const { data: contactsTable, isLoading: isContactsTableLoading } = useContactsTable(true)
  useEffect(() => {
    if (!isContactsTableLoading && contactsTable) {
      console.log('contactsTable', contactsTable)
    }
  }, [contactsTable])
  const isLoading = isDealsLoading || isOrganizationsLoading
  const isError = isDealsError || isOrganizationsError
  const gridBorderColors = theme.palette.mode === 'dark' ? 'white' : 'black'
  const myOrgs =
    organizations && Array.isArray(organizations)
      ? organizations.filter(org => {
          // Exclude organizations with missing essential data
          if (!org || !org.name || !org.status) return false

          // Check if the current user is in the owner array
          return org.owner && Array.isArray(org.owner) && org.owner.includes(activeUser?.user?.id)
        })
      : []
  if (isLoading) {
    return <DetailsLoading />
  }

  const handleUserChange = event => {
    setSelectedUserId(event.target.value)
  }

  const handleMonthChange = event => {
    setSelectedMonth(event.target.value)
  }
  const dealsByOwner =
    deals && Array.isArray(deals)
      ? deals.filter(deal => {
          // Exclude deals with missing essential data
          if (!deal || !deal.name || !deal.status) return false

          // Check if the current user is in the owner array
          return deal.owner && Array.isArray(deal.owner) && deal.owner.includes(activeUser?.user?.id)
        })
      : []
  const dealsByTeamMember =
    deals && Array.isArray(deals)
      ? deals.filter(deal => {
          // Exclude deals with missing essential data
          if (!deal || !deal.name || !deal.status) return false

          const isPM = deal.PM && Array.isArray(deal.PM) && deal.PM.includes(activeUser?.user?.id)
          const isAM = deal.AM && Array.isArray(deal.AM) && deal.AM.includes(activeUser?.user?.id)
          const isDMS = deal.DMS && Array.isArray(deal.DMS) && deal.DMS.includes(activeUser?.user?.id)
          const isSEO = deal.SEO && Array.isArray(deal.SEO) && deal.SEO.includes(activeUser?.user?.id)
          const isBD = deal.BD && Array.isArray(deal.BD) && deal.BD.includes(activeUser?.user?.id)

          // Return true if the user is associated with any of these roles
          return isPM || isAM || isDMS || isSEO || isBD
        })
      : []

  // Combine and deduplicate the deals
  const uniqueDeals = new Set([...dealsByOwner, ...dealsByTeamMember])
  const myDeals = [...uniqueDeals]
  const filteredDeals =
    deals && Array.isArray(deals)
      ? selectedUserId
        ? deals.filter(deal => deal.owner && deal.owner.includes(selectedUserId))
        : deals
      : []

  const dealsForSelectedMonth = selectedMonth
    ? filteredDeals.filter(deal => {
        const dealMonth = new Date(deal.startDate).getMonth()
        return dealMonth === months.indexOf(selectedMonth)
      })
    : filteredDeals

  const activeDealsCount = dealsForSelectedMonth.filter(deal => deal.status === 'Active').length
  const contractsSentCount = dealsForSelectedMonth.filter(deal => deal.stage === 'Contract Sent').length
  const dealsWonData = dealsForSelectedMonth.filter(deal => deal.stage === 'Deal Won').length
  const dealsInDraft = dealsForSelectedMonth.filter(deal => deal.status === 'draft').length

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack content vertically
        justifyContent: 'space-between',
        alignItems: 'center',
        // height: '100vh', // Full viewport height
        pt: pinned ? '60px' : 0.8,
        transition: 'all 0.3s ease', // or .5s, etc.
        height: 'calc(100vh - 22px)',
        overflowY: 'auto',
        marginTop: 1,
      }}
    >
      {isLoading && <CircularProgress />}
      <Grid container spacing={0} sx={{ flexGrow: 1, width: '98%' }}>
        {/* <OrganizationsList skFilter={['META']} /> */}
        {/* Scrollable MyDeals Section */}
        <Grid
          item
          xs={6}
          sx={{
            overflowY: 'auto',
            border: '1px solid',
            borderColor: gridBorderColors,
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: theme.palette.background.paper,
              padding: '10px',
              width: '100%',
            }}
          >
            <Typography variant='h6' align='center'>
              {`Deals [${myDeals.length}]`}
            </Typography>
          </Box>

          {myDeals && myDeals.length > 0 ? (
            myDeals.map(deal => (
              <Box key={deal.id} sx={{ marginBottom: 2, pl: 1.5, pr: 1.5 }}>
                <MyDeals deal={deal} />
              </Box>
            ))
          ) : (
            <Typography variant='body1'>No deals available.</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            overflowY: 'auto',
            border: '1px solid',
            borderColor: gridBorderColors,
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              backgroundColor: theme.palette.background.paper,
              padding: '10px',
              width: '100%',
            }}
          >
            <Typography variant='h6' align='center'>
              {`Organizations [${myOrgs.length}]`}
            </Typography>
          </Box>
          {myOrgs && myOrgs.length > 0 ? (
            myOrgs.map(org => (
              <Box key={org.org_uuid} sx={{ marginBottom: 2, pl: 1.5, pr: 1.5 }}>
                <MyOrganizations org={org} />
              </Box>
            ))
          ) : (
            <Typography variant='body1'>No Organizations available.</Typography>
          )}
        </Grid>

        {/* Stats Grid */}
        <Grid container sx={{ height: '20vh', marginTop: '10px' }} spacing={0}>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid',
              borderColor: gridBorderColors,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6'>Active Deals: {activeDealsCount}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid',
              borderColor: gridBorderColors,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6'>Contracts Sent: {contractsSentCount}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid',
              borderColor: gridBorderColors,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6'>Deals Won: {dealsWonData}</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid',
              borderColor: gridBorderColors,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='h6'>Deals in Draft: {dealsInDraft}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashboardPage
