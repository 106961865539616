import React, { useRef, useState, useEffect } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import DealInfoCard from './DealInfoCard'
import { gridStyles } from './style'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { useNewContactsById } from '../../api/customHooks/useNewContactsById'
import { useDeal } from '../../api/aws/useDeal'
import { styled } from '@mui/material/styles'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { useProductsByDealId } from '../../api/aws/products/useProductsByDealId.ts'

const DetailsContent = styled(Box)(({ open, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: 2,
  transition: 'height 0.3s ease',
  height: '98%',
  flex: 1,
}))

const DealsDetails = () => {
  const { dealId } = useParams()
  const { navOpen } = useLayoutContext()
  const theme = useTheme()
  const { newDeal, setNewDeal } = useDealDetailsContext()
  const { deal, isDealLoading } = useDeal(dealId, ['dealPage', dealId])
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { showSnackbar } = useSnackbarContext()
  // Retrieve contacts associated with the deal
  const newContactIds = newDeal && newDeal.contacts && Array.isArray(newDeal.contacts) ? newDeal.contacts : []
  const { newContacts } = useNewContactsById(newContactIds, 'dealDetails-contacts')

  ///////////////////////////////////////////////////////////////////////////
  // const {
  //   data: productsByDealId,
  //   isLoading: isProductsByDealIdLoading,
  //   isError: isProductsByDealIdError,
  // } = useProductsByDealId(dealId)
  // useEffect(() => {
  //   if (!isProductsByDealIdLoading && productsByDealId) {
  //     console.log('productsByDealId', productsByDealId)
  //   }
  // }, [productsByDealId])
  ///////////////////////////////////////////////////////////////////////////

  const handleNavigate = () => {
    if (newDeal?.id) {
      navigate(`/deals/${newDeal.id}/contract`)
    } else {
      console.error('No deal ID found')
    }
  }
  const handleEdit = () => {
    // setIsEditMode(true)
    // setIsOpen(true)
    handleNavigate()
  }
  const handleCancel = () => {
    setNewDeal(prev => ({ ...prev, ...deal }))
  }

  return (
    <Grid container spacing={0} justifyContent='center' sx={gridStyles(theme)}>
      <Grid
        container
        sx={{
          overflowY: 'auto',
          // bgcolor: theme.palette.background.section,
          justifyContent: 'center',
          p: 1,
          gap: 0.5,
        }}
      >
        <DealInfoCard key={dealId} deal={newDeal} onEdit={handleEdit} newContacts={newContacts} />
        {/* <Outlet /> */}
      </Grid>
    </Grid>
  )
}

export default DealsDetails
