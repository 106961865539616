import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

interface MultipleFilesMetadataVars {
  fileIds: string[]
  accessToken: string
}

const fetchMultipleDriveMetadata = async (fileIds: string[], accessToken: string): Promise<any> => {
  const response = await axios.post(
    `${baseUrl}/aws/googleapi/files/metadata`,
    { fileIds },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  )
  return response.data
}

export const useMultipleFilesMetadata = () => {
  return useMutation<any, Error, MultipleFilesMetadataVars, unknown>({
    mutationFn: ({ fileIds, accessToken }) => fetchMultipleDriveMetadata(fileIds, accessToken),
    onSuccess: () => console.log('Successfully fetched file metadata'),
    onError: error => console.error('[useMultipleFilesMetadata] Error:', error),
  })
}
