import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import React, { ReactNode } from 'react'

interface RenderBoardLinkProps {
  title: string
  route: string
  onNavigate: (route: string) => void
  boardTheme: string
  icon: ReactNode
  disabled: boolean
}

const RenderBoardLink: React.FC<RenderBoardLinkProps> = ({ title, route, onNavigate, boardTheme, icon, disabled }) => {
  const theme = useTheme()
  return (
    <ListItem
      key={title}
      button
      onClick={() => onNavigate(route)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 12px',
        borderRadius: '6px',
        filter: 'grayscale(100%)', // Default gray effect
        transition: 'background-color 0.3s border-color 0.3s ease',
        border: `1px solid transparent`,
        '&:hover': {
          filter: 'none', // Remove grayscale on hover
          transition: 'filter 0.2s ease-in-out',
          backgroundColor: 'transparent',
          border: `1px solid ${boardTheme}`,
          '& .MuiSvgIcon-root': {
            color: boardTheme,
          },
          '& .seo-icon': {
            filter: 'none', // Remove grayscale on hover
          },
        },
      }}
      disabled={disabled}
    >
      <ListItemIcon
        sx={{
          minWidth: 40,
          color: 'inherit', // Default color when not hovered
          fontSize: '1.8rem',
          transition: 'color 0.3s ease',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} sx={{ textAlign: 'left', color: theme.palette.text.primary }} />
    </ListItem>
  )
}

export default RenderBoardLink
