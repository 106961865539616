import { AvatarGroup, Avatar, Modal, Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import UserImageComponent from '../../Users/UserImageComponent'
import RoleCard from '../../NCO/RoleCard'
import OnboardTeamAutocomplete from './OnboardTeamAutocomplete.tsx'
import { useUsers } from '../../../api/aws/useUsers'

const RenderOnboardTeam = ({ deal, onRoleChange }) => {
  const [teamOpen, setTeamOpen] = useState(false)
  const { users, isUsersLoading } = useUsers(true, ['onboardTeam', 'users'])
  // const roles = rolesArray ?? ['AM', 'PM', 'DMS', 'SEO', 'QA', 'BD', 'MIA'] // Add all roles present in the deal
  // ✅ Extract and Flatten All Users From Role Groups
  // const onboardUsers = roles
  //   .flatMap(role => (Array.isArray(deal?.[role]) ? deal[role] : [])) // Ensures only arrays are mapped
  //   .filter(user => user?.image_512 || user?.name) // Ensures valid user objects
  const onboardUsers = deal?.onboardingTeam || []
  return (
    <>
      {/* Avatar Group to Show Team Members */}
      <AvatarGroup max={8} onClick={() => setTeamOpen(true)} sx={{ cursor: 'pointer' }} spacing={'medium'}>
        {onboardUsers.map((user, index) =>
          user.image_512 ? (
            <Avatar key={`${user.user_uuid}-${index}`} src={user.image_512} />
          ) : (
            <Avatar key={`${user.user_uuid}-${index}`}>{user.name?.charAt(0) || '?'}</Avatar>
          )
        )}
      </AvatarGroup>

      {/* Modal to Change Team Members */}
      <Modal open={teamOpen} onClose={() => setTeamOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90vw',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant='h6' sx={{ mb: 2 }}>
            Assign Roles
          </Typography>
          {/* {roles.map((role, index) => (
            <RoleCard
              key={`${role}-${index}`}
              initialRoleUsers={deal?.[role]}
              handleChange={onRoleChange}
              roleName={role}
              max={8}
            />
          ))} */}
          <OnboardTeamAutocomplete
            users={users}
            role='onboardingTeam'
            label='Onboarding Team'
            onChange={onRoleChange}
            defaultValue={onboardUsers || []}
            disabled={false}
            isLoading={isUsersLoading}
            maxSize={8}
            // error={marketingFormErrors?.assignee?.value}
          />
        </Box>
      </Modal>
    </>
  )
}

export default RenderOnboardTeam
