import React, { useState, useEffect } from 'react'
import { Box, FormControl, Select, MenuItem, Chip, Typography, InputLabel, useTheme } from '@mui/material'

const MassUpdateTypeSelect = ({ name, value, options, displayLabel, onChange, disabled, sx }) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState(value[0] ?? '')

  useEffect(() => {
    setSelectedValue(value[0] ?? '')
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    console.log('NEW VALUE: ', newValue)
    setSelectedValue(newValue)
    // If newValue is empty, pass an empty array; otherwise, wrap in an array.
    onChange({ target: { name, value: newValue === '' ? [] : [newValue] } })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel htmlFor={`${name}-select`}>{displayLabel}</InputLabel>
        <Select
          label={displayLabel}
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={selectedValue}
          onChange={handleChange}
          disabled={disabled ?? false}
          MenuProps={{ style: { zIndex: 2000 } }}
          sx={{ maxHeight: 45 }}
        >
          {/* Add a Clear option */}
          {options.map(option => {
            const typeStyle = {
              height: '100%',
              width: '100%',
              borderColor: option.color,
              borderWidth: '1px',
              borderStyle: 'solid',
              color: theme.palette.text.primary,
              borderRadius: '4px',
              marginRight: '8px',
            }
            const statusStyle = {
              alignItems: 'left',
              height: 15,
              width: 15,
              backgroundColor: option.color,
              marginRight: '8px',
            }
            return (
              <MenuItem
                key={option.label}
                value={option.label}
                sx={{
                  overflowY: 'auto',
                  height: 'auto',
                  minHeight: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: name === 'type' ? 'center' : '',
                  }}
                >
                  <Chip
                    size='small'
                    label={name === 'type' ? option.type || option?.label : ''}
                    style={name === 'type' ? typeStyle : statusStyle}
                  />
                  <Typography variant='subtitle1'>{name !== 'type' ? option.label : ''}</Typography>
                </Box>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MassUpdateTypeSelect
