import React from 'react'
import { Box, Avatar, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
const RenderContractedByColumn = ({ orgName, orgId, densityStyles }) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        padding: densityStyles.padding,
        cursor: 'pointer',
        gap: densityStyles.gap,
      }}
      onClick={() => navigate(orgId ? `/organizations/${orgId}/details` : '#')}
    >
      {/* <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'left',
          height: '100%',
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {orgName ? orgName : ''}
          </Typography>
        </Box>
      </Box>
      {/* </Link> */}
    </Box>
  )
}

export default RenderContractedByColumn
