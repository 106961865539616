import React from 'react'
import { Button, Box, Typography } from '@mui/material'
import useUploadTaskImages from '../../api/aws/tasks/useUploadTaskImages.ts'
interface Task {
  task_uuid: string
  PK: string
  SK: string
  // ... other task fields
}

interface TaskImageUploaderProps {
  task: Task
}

const TaskImageUploader: React.FC<TaskImageUploaderProps> = ({ task }) => {
  const { mutate: uploadImages } = useUploadTaskImages()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData()
      // Append each file with the same key, e.g. 'files'
      Array.from(e.target.files).forEach(file => {
        formData.append('files', file)
      })
      // Append additional required fields so the backend can update the item.
      formData.append('tableName', 'TASKS_TABLE')
      formData.append('PK', task.PK)
      formData.append('SK', task.SK)
      formData.append('fieldName', 'imageUrls')
      console.log('FORM DATA', formData)
      uploadImages(
        { taskId: task.task_uuid, PK: task.PK, SK: task.SK, formData },
        {
          onSuccess: data => {
            console.log('Successfully added file')
          },
          onError: error => {
            console.error('Error adding file', error)
          },
        }
      )
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Button variant='outlined' component='label'>
        Add Image
        <input type='file' accept='image/*' multiple hidden onChange={handleFileChange} />
      </Button>
    </Box>
  )
}

export default TaskImageUploader
