import React, { useState, useEffect } from 'react'
import { Typography, Chip, FormControl, Select, MenuItem, useTheme, InputLabel } from '@mui/material'

export const dealStatusOptions = [
  {
    label: 'Month To Month',
    color: 'rgb(22, 138, 239)',
  },
  {
    label: 'Active',
    color: 'rgb(80, 200, 120)',
  },
  {
    label: 'Active (system)',
    color: 'rgb(40, 249, 144)',
  },
  {
    label: 'In Progress',
    color: 'rgb(253, 188, 100)',
  },
  {
    label: '30 Day Notice',
    color: 'rgb(255, 123, 208)',
  },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    label: 'Ended',
    color: 'rgba(220, 20, 60, 0.8)',
  },
  // {
  //   label: 'Expired',
  //   color: 'rgba(220, 20, 60, 0.8)',
  // },
  // {
  //   label: 'Expired (system)',
  //   color: 'rgba(229, 40, 200, 0.9)',
  // },
]

const DealStatusSelect = ({
  newDeal,
  setNewDeal,
  setIsDealStatusChange,
  onSaveModal,
  onChange,
  setShowModal,
  setTempDealStatus,
  label = false,
  setIsModalSave,
}) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState('')
  const [lastDateOfService, setLastDateOfService] = useState('')
  const [reason, setReason] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (newDeal.dealStatus) {
      setSelectedValue(newDeal.dealStatus || 'Select Status')
    }
  }, [newDeal.dealStatus])

  const handleChange = event => {
    const newValue = event.target.value

    const requiresModal = ['30 Day Notice', 'Cancelled', 'Ended'].includes(newValue)

    if (requiresModal) {
      setShowModal(true) // Open the modal
      setTempDealStatus(newValue) // Temporarily hold the status
      setIsDealStatusChange(true)
      setIsModalSave(true)
    } else {
      setSelectedValue(newValue)
      onChange('dealStatus', newValue)
      setNewDeal(prev => ({
        ...prev,
        dealStatus: newValue,
      }))
    }
  }

  const handleSave = () => {
    if (!lastDateOfService || !reason) {
      setError(true) // Show an error if fields are empty
      return
    }

    setError(false)
    onSaveModal(lastDateOfService, reason) // Pass data to parent
  }

  return (
    <FormControl fullWidth>
      {label ? <InputLabel id='status-select-label'>Deal Status</InputLabel> : null}
      <Select
        id='status-select'
        label={label ? 'Deal Status' : ''}
        value={selectedValue || ''}
        onChange={handleChange}
        name='status'
        required
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 54,
          display: 'flex',
        }}
      >
        {dealStatusOptions.map(option => (
          <MenuItem
            key={option.label}
            value={option.label}
            disabled={['Expired (system)', 'Active (system)'].includes(option.label)} // Disable "Expired (system)" option
          >
            <Chip
              size='small'
              style={{
                backgroundColor: option.color,
                height: 14,
                width: 14,
                marginRight: 8,
                borderRadius: '50%',
              }}
            />
            <Typography variant='subtitle1'>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DealStatusSelect
