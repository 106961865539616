export const data1 = [
  {
    data: {
      boards: [
        {
          id: '565443568',
          name: 'Creative Requests',
          views: [
            {
              id: '31743351',
              name: 'Main View',
              type: 'TableBoardView',
              settings_str: '{}',
            },
          ],
          items_page: {
            cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsMjUsfDk2MTg3MzI5',
            items: [
              {
                subitems: [
                  {
                    name: 'John Randall - Final Video',
                  },
                  {
                    name: 'Donna Petty - Final Video',
                  },
                  {
                    name: 'Alice Maxwell - Final Video',
                  },
                  {
                    name: 'Tommy Ford - Final Video',
                  },
                  {
                    name: 'Wes York - Final Video',
                  },
                  {
                    name: 'Sales Team - Favorite Amenity Feature - Final Video',
                  },
                  {
                    name: 'Sales Team - Favorite Design Feature - Final Video',
                  },
                  {
                    name: 'Sales Team - Love for Lake Ttown - Final Video',
                  },
                ],
              },
              {
                subitems: [
                  {
                    name: "April Chef's Table",
                  },
                  {
                    name: 'Masters Specials',
                  },
                  {
                    name: 'Easter Egg Decorating & Hunt Breakfast',
                  },
                  {
                    name: 'Easter Brunch',
                  },
                ],
              },
              {
                subitems: [
                  {
                    name: 'Domain / Content Acquisition',
                  },
                  {
                    name: 'Setup Live Domain for Development',
                  },
                  {
                    name: 'Homepage + Coming Soon page Build',
                  },
                  {
                    name: 'Subpages Build',
                  },
                  {
                    name: 'Quality Assurance Checks',
                  },
                  {
                    name: 'Cutover to live domain',
                  },
                ],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [
                  {
                    name: 'HTML Email',
                  },
                  {
                    name: 'Poster',
                  },
                  {
                    name: 'Flyer',
                  },
                  {
                    name: 'Instagram Story',
                  },
                ],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [
                  {
                    name: 'Hillhouse Construction Update - Video Edit',
                  },
                ],
              },
              {
                subitems: [
                  {
                    name: 'Captains Clube Wine Video Edit 3/5',
                  },
                  {
                    name: 'Captains Clube Wine Video Edit 3/12',
                  },
                  {
                    name: 'Captains Clube Wine Video Edit 3/19',
                  },
                  {
                    name: 'Captains Clube Wine Video Edit 3/26',
                  },
                ],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
              {
                subitems: [],
              },
            ],
          },
        },
      ],
    },
  },
]
export const data2 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsNzUsfDU3NzAwNjUw',
        items: [
          {
            id: '7612591666',
            name: 'CLV - MCM - Booklet Rebrand',
            subitems: [
              {
                name: 'Varnado Hall (NSU)',
              },
              {
                name: 'University Oaks (UTSA)',
              },
              {
                name: 'Centennial Court (UTA)',
              },
              {
                name: 'University Forrest (UHCL)',
              },
              {
                name: 'University Pines (UTT)',
              },
              {
                name: 'Village Oaks (TSTC)',
              },
              {
                name: 'Privateer Place (UNO)',
              },
              {
                name: 'University Meadows (UMSL)',
              },
              {
                name: 'Cambridge Oaks (UHMC)',
              },
              {
                name: 'University Columns (NSU)',
              },
              {
                name: 'MCM Cover',
              },
            ],
          },
          {
            id: '8213275220',
            name: 'HillHouse - Organic Social Graphics',
            subitems: [
              {
                name: 'First Organic Social Post',
              },
              {
                name: 'FAQ Social Post',
              },
            ],
          },
          {
            id: '6927228743',
            name: "Captain's Club - Instagram Posts",
            subitems: [
              {
                name: '4th of July Graphic',
              },
              {
                name: 'Surf School Graphic',
              },
              {
                name: 'Family Paddle Board Race Graphic',
              },
              {
                name: 'Carefree Boat Club Social',
              },
              {
                name: 'Labor Day',
              },
              {
                name: '25% off Bushwacker’s!',
              },
              {
                name: 'Georgia Tailgate',
              },
              {
                name: 'Group/Private Events Graphic',
              },
              {
                name: 'Black Friday Promo',
              },
              {
                name: 'Trudon Giveaway Graphic',
              },
              {
                name: 'Mardi Gras Graphic',
              },
            ],
          },
          {
            id: '8266663658',
            name: 'Indian Hills CC - February Instagram Posts',
            subitems: [
              {
                name: "Valentine's Dinner",
              },
              {
                name: 'Father/Daughter Dinner & Dance',
              },
              {
                name: 'Wine Tasting',
              },
            ],
          },
          {
            id: '8203060434',
            name: 'Asset - Gilbane - Octave - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8539211855',
            name: 'Aptitude - Asset - Marshall Valley | February Website Updates',
            subitems: [],
          },
          {
            id: '8484963344',
            name: 'Jacobson Equities  - Alsander GNV - February Website Edits',
            subitems: [],
          },
          {
            id: '8386590660',
            name: 'Captains Club Wine - Video Shoot',
            subitems: [
              {
                name: 'Wine Video 1',
              },
              {
                name: 'Wine Video 2',
              },
              {
                name: 'Wine Video 3',
              },
              {
                name: 'Sunset Cruise Promo',
              },
            ],
          },
          {
            id: '8578441403',
            name: 'Vercanta Pleasanton - Website Edit',
            subitems: [],
          },
          {
            id: '8203291759',
            name: 'Asset - Gilbane - Tempo - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8540612210',
            name: 'The Builders Group of West Alabama - Social Hype Reveal Video',
            subitems: [],
          },
          {
            id: '8336416454',
            name: 'Article Student Living - Uncommon Columbus - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8523028362',
            name: 'Dial Senior Living - Salt Creek - Display Ads',
            subitems: [],
          },
          {
            id: '8522664193',
            name: 'Dial Senior Living - The Belleview - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8596581536',
            name: 'Aptitude - Asset - The Marshall in The Valley - Website Edits - March',
            subitems: [],
          },
          {
            id: '7847325695',
            name: 'Tuscaloosa County Commission - Website Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7789250221',
            name: "Captain's Club - Social Membership Rollout - Instagram Graphics",
            subitems: [
              {
                name: 'Graphic #1: Social Membership (Post and Story)',
              },
              {
                name: 'Graphic #2: Carefree Boat Club Membership (Post and Story)',
              },
              {
                name: 'Graphic #3: Boat Slip Membership (Post and Story)',
              },
              {
                name: 'Graphic #4: Expand Your Membership Options (Post and Story)',
              },
            ],
          },
          {
            id: '7789229070',
            name: "Captain's Club - Social Membership Rollout - Brochures",
            subitems: [
              {
                name: 'PDF Brochure',
              },
              {
                name: 'Tri-Fold Brochure',
              },
            ],
          },
          {
            id: '8034699833',
            name: 'Carrie Fitts Real Estate - Pensacola - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'IDX Integration',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7789222953',
            name: "Captain's Club - Social Membership Rollout - Website Revisions",
            subitems: [],
          },
          {
            id: '8213869345',
            name: "Captain's Club - Social & Display Ads",
            subitems: [],
          },
          {
            id: '8409643153',
            name: 'Touchstone Living - Solaris - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8409738188',
            name: 'Touchstone Living - Watercolor - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8336199130',
            name: 'Ivory University House - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8409817796',
            name: 'Touchstone Living - Independence - Social & Display Ads',
            subitems: [],
          },
        ],
      },
    },
  },
]

export const data3 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsNzUsfDU3NzAwNjUw',
        items: [
          {
            id: '7612591666',
            name: 'CLV - MCM - Booklet Rebrand',
            subitems: [
              {
                name: 'Varnado Hall (NSU)',
              },
              {
                name: 'University Oaks (UTSA)',
              },
              {
                name: 'Centennial Court (UTA)',
              },
              {
                name: 'University Forrest (UHCL)',
              },
              {
                name: 'University Pines (UTT)',
              },
              {
                name: 'Village Oaks (TSTC)',
              },
              {
                name: 'Privateer Place (UNO)',
              },
              {
                name: 'University Meadows (UMSL)',
              },
              {
                name: 'Cambridge Oaks (UHMC)',
              },
              {
                name: 'University Columns (NSU)',
              },
              {
                name: 'MCM Cover',
              },
            ],
          },
          {
            id: '8213275220',
            name: 'HillHouse - Organic Social Graphics',
            subitems: [
              {
                name: 'First Organic Social Post',
              },
              {
                name: 'FAQ Social Post',
              },
            ],
          },
          {
            id: '6927228743',
            name: "Captain's Club - Instagram Posts",
            subitems: [
              {
                name: '4th of July Graphic',
              },
              {
                name: 'Surf School Graphic',
              },
              {
                name: 'Family Paddle Board Race Graphic',
              },
              {
                name: 'Carefree Boat Club Social',
              },
              {
                name: 'Labor Day',
              },
              {
                name: '25% off Bushwacker’s!',
              },
              {
                name: 'Georgia Tailgate',
              },
              {
                name: 'Group/Private Events Graphic',
              },
              {
                name: 'Black Friday Promo',
              },
              {
                name: 'Trudon Giveaway Graphic',
              },
              {
                name: 'Mardi Gras Graphic',
              },
            ],
          },
          {
            id: '8266663658',
            name: 'Indian Hills CC - February Instagram Posts',
            subitems: [
              {
                name: "Valentine's Dinner",
              },
              {
                name: 'Father/Daughter Dinner & Dance',
              },
              {
                name: 'Wine Tasting',
              },
            ],
          },
          {
            id: '8203060434',
            name: 'Asset - Gilbane - Octave - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8539211855',
            name: 'Aptitude - Asset - Marshall Valley | February Website Updates',
            subitems: [],
          },
          {
            id: '8484963344',
            name: 'Jacobson Equities  - Alsander GNV - February Website Edits',
            subitems: [],
          },
          {
            id: '8386590660',
            name: 'Captains Club Wine - Video Shoot',
            subitems: [
              {
                name: 'Wine Video 1',
              },
              {
                name: 'Wine Video 2',
              },
              {
                name: 'Wine Video 3',
              },
              {
                name: 'Sunset Cruise Promo',
              },
            ],
          },
          {
            id: '8578441403',
            name: 'Vercanta Pleasanton - Website Edit',
            subitems: [],
          },
          {
            id: '8203291759',
            name: 'Asset - Gilbane - Tempo - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8540612210',
            name: 'The Builders Group of West Alabama - Social Hype Reveal Video',
            subitems: [],
          },
          {
            id: '8336416454',
            name: 'Article Student Living - Uncommon Columbus - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8523028362',
            name: 'Dial Senior Living - Salt Creek - Display Ads',
            subitems: [],
          },
          {
            id: '8522664193',
            name: 'Dial Senior Living - The Belleview - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8596581536',
            name: 'Aptitude - Asset - The Marshall in The Valley - Website Edits - March',
            subitems: [],
          },
          {
            id: '7847325695',
            name: 'Tuscaloosa County Commission - Website Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7789250221',
            name: "Captain's Club - Social Membership Rollout - Instagram Graphics",
            subitems: [
              {
                name: 'Graphic #1: Social Membership (Post and Story)',
              },
              {
                name: 'Graphic #2: Carefree Boat Club Membership (Post and Story)',
              },
              {
                name: 'Graphic #3: Boat Slip Membership (Post and Story)',
              },
              {
                name: 'Graphic #4: Expand Your Membership Options (Post and Story)',
              },
            ],
          },
          {
            id: '7789229070',
            name: "Captain's Club - Social Membership Rollout - Brochures",
            subitems: [
              {
                name: 'PDF Brochure',
              },
              {
                name: 'Tri-Fold Brochure',
              },
            ],
          },
          {
            id: '8034699833',
            name: 'Carrie Fitts Real Estate - Pensacola - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'IDX Integration',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7789222953',
            name: "Captain's Club - Social Membership Rollout - Website Revisions",
            subitems: [],
          },
          {
            id: '8213869345',
            name: "Captain's Club - Social & Display Ads",
            subitems: [],
          },
          {
            id: '8409643153',
            name: 'Touchstone Living - Solaris - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8409738188',
            name: 'Touchstone Living - Watercolor - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8336199130',
            name: 'Ivory University House - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8409817796',
            name: 'Touchstone Living - Independence - Social & Display Ads',
            subitems: [],
          },
        ],
      },
    },
  },
]

export const data4 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsMTAwLHw0MjczMzM5MDQz',
        items: [
          {
            id: '7834652240',
            name: '908 Group - Asset - 2026 New Development "The Hall" - Site Maps',
            subitems: [
              {
                name: 'Property Map - Parcel 2',
              },
              {
                name: 'Property Map - Parcel 3',
              },
              {
                name: 'Location Map',
              },
            ],
          },
          {
            id: '8372368913',
            name: 'Dinerstein | Atlas Alvarado - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8458133664',
            name: 'Alden Street - Wildwood Baton Rouge - TikTok Ads',
            subitems: [],
          },
          {
            id: '7797754477',
            name: 'Aptitude - Asset - The Pier Conway - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7797746965',
            name: 'Aptitude - Asset - The Marshall Louisville - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7797686198',
            name: 'Aptitude - Asset - The Marshall Arkansas - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '8487516151',
            name: 'TBAL (Greystar) - Emerson (floor plan carousel) - Social Ad',
            subitems: [],
          },
          {
            id: '8506871185',
            name: 'L3 - The Wynwood - Social & Display Ads',
            subitems: [],
          },
          {
            id: '7933074845',
            name: 'Ready to Hire - Goodwill and HCA Healthcare - Dallas - Social & Display Ad',
            subitems: [],
          },
          {
            id: '8185459110',
            name: 'Alum Tuscaloosa - Website Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '8519111961',
            name: 'RISE - Eagle Landing - Social Ad',
            subitems: [],
          },
          {
            id: '8498411626',
            name: 'Elmington - Reserve at Mountain Pass - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8204434047',
            name: 'Alden Street - Carmin  - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8399176318',
            name: 'TBAL (willow bridge) - Peregrine (fb videos) - Social Ad',
            subitems: [],
          },
          {
            id: '8542051229',
            name: 'Aptitude - Cardinal Group - The Marshall Rochester - Website Edits - February',
            subitems: [],
          },
          {
            id: '8204345943',
            name: 'Alden Street - Dakota Ranch - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8410852396',
            name: 'Aptitude - Asset - The Marshall St. Louis - Website Edits - February',
            subitems: [],
          },
          {
            id: '7737805268',
            name: 'Cardinal Group - Hoover Square - Site Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '8466664363',
            name: 'Capstone Management Partners - Nexus on Holmes - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8227206070',
            name: 'Ready to Hire - TriStar Health - Nashville - Sterile Processing Technicians - Social Ad',
            subitems: [],
          },
          {
            id: '7798157161',
            name: 'The Builders Group of West Alabama - Rebrand',
            subitems: [
              {
                name: 'Full Logo Package',
              },
              {
                name: 'Branding Guide',
              },
              {
                name: 'Business Systems Kit',
              },
              {
                name: 'A-Frame Signage',
              },
            ],
          },
          {
            id: '8443698814',
            name: 'L3 Campus - Asset - Onshore Daytona - Website Edits - February',
            subitems: [],
          },
          {
            id: '8573377678',
            name: 'Aptitude - Asset - The Marshall St. Louis and Valley - Website Edits',
            subitems: [],
          },
          {
            id: '8386748824',
            name: 'Scannell Properties - Asset - Replay Iowa City - Website Edits - February',
            subitems: [],
          },
          {
            id: '8204301899',
            name: 'Alden Street - Cottages - Social Ad',
            subitems: [],
          },
        ],
      },
    },
  },
]
export const data5 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsMTI1LHwyMTUyMTI3MTEy',
        items: [
          {
            id: '8578596747',
            name: 'Gilbane - Asset - Tempo - Website Edits - February',
            subitems: [],
          },
          {
            id: '8373142522',
            name: 'D.R. Horton - Memphis | Jonesboro General - Display Ads',
            subitems: [],
          },
          {
            id: '8106897763',
            name: 'Fairin Realty - Huntley Knoll - Social Ad',
            subitems: [],
          },
          {
            id: '8478173003',
            name: 'TBAL (Greystar) - Vermeer - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8158609326',
            name: 'TBAL (Willowbridge) - Whitlow - Social & Display Ads',
            subitems: [],
          },
          {
            id: '7877304974',
            name: 'TBAL (Greystar) - Navona - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8375239571',
            name: 'Greystar - Shiloh Green - Website Edits - February',
            subitems: [],
          },
          {
            id: '8186521567',
            name: 'TBAL - Ferro (YouTube) - Social Ad',
            subitems: [],
          },
          {
            id: '8447878213',
            name: 'Ready to Hire - Grede Manufacturing Social Ads (Set B) - Social Ad',
            subitems: [],
          },
          {
            id: '8521626530',
            name: 'CLV - UTA - Shorthorn Ad',
            subitems: [],
          },
          {
            id: '8466887581',
            name: 'Capstone Management Partners - Meridian on Main - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8522459524',
            name: 'Cengage - Ready to Hire - Goshen Health - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8412776352',
            name: 'Muinzer - Close to Campus - Social & Display Ads',
            subitems: [],
          },
          {
            id: '8519065726',
            name: 'Asset - The Renegade - Website Updates - February',
            subitems: [],
          },
          {
            id: '8533540509',
            name: 'Latigo, LLC - Pitch Deck',
            subitems: [],
          },
          {
            id: '7402739535',
            name: 'Template - Large Website or Site Rebuild - Do Not Edit. Make a copy.',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Homepage Build',
              },
              {
                name: 'Homepage Revisions',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7409022609',
            name: 'Template - New Site Builds (No pre-existing site) - Do Not Edit. Make a copy.',
            subitems: [
              {
                name: 'Domain  Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Live Domain for Development',
              },
              {
                name: 'Homepage + Coming Soon page Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Subpages Build',
              },
              {
                name: 'Subpage Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '7513785137',
            name: 'Harwood Real Estate | Booklet',
            subitems: [],
          },
          {
            id: '7513824932',
            name: 'Harwood Real Estate | One-Sheeters',
            subitems: [
              {
                name: 'The Agents',
              },
              {
                name: 'Residential Division',
              },
              {
                name: 'Commercial Division',
              },
            ],
          },
          {
            id: '7513853144',
            name: 'Harwood Real Estate | Tri-fold Brochure',
            subitems: [],
          },
          {
            id: '7973929306',
            name: 'Jacobson Equities - Vercanta Riverfront - Website Rebuild',
            subitems: [
              {
                name: 'Domain Acquisition',
              },
              {
                name: 'Content Verification',
              },
              {
                name: 'Setup Dev Site',
              },
              {
                name: 'Full Site Build',
              },
              {
                name: 'Full Site Revisions',
              },
              {
                name: 'Quality Assurance Checks',
              },
              {
                name: 'Cutover to live domain',
              },
            ],
          },
          {
            id: '704024825',
            name: 'ACH - Gather Illinois  - Social & Display Ads',
            subitems: [],
          },
          {
            id: '711633613',
            name: 'DR Horton - The Parks - White House, TN - Display/Social Ads',
            subitems: [],
          },
          {
            id: '847654007',
            name: 'AirPros - Print/Flyer Design',
            subitems: [],
          },
          {
            id: '863640722',
            name: 'ACH - SH - Torre ATX - Social & Display Ads',
            subitems: [],
          },
        ],
      },
    },
  },
]
export const data6 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsMTUwLHw0MTY5MDIxNTEy',
        items: [
          {
            id: '865387404',
            name: 'Gourmade Kitchen - Website Updates',
            subitems: [],
          },
          {
            id: '866529996',
            name: 'ACH - SH - Marshall Fayetteville (AR) - Turn off URL LLA Targeting',
            subitems: [],
          },
          {
            id: '875309811',
            name: 'Team AMC - Crovetti Ortho - Geofence/Factual/CTV',
            subitems: [],
          },
          {
            id: '875319417',
            name: 'Covert Recruiting - New Website Build',
            subitems: [],
          },
          {
            id: '895299535',
            name: 'Analyze Updated Audiences',
            subitems: [],
          },
          {
            id: '902102079',
            name: 'BFS Logistics - Logo Design',
            subitems: [],
          },
          {
            id: '904756093',
            name: 'The Walk - Website Change',
            subitems: [],
          },
          {
            id: '913442949',
            name: 'King Simmons / Ford & Spree - Turn off ad campaigns. Client Churned.',
            subitems: [],
          },
          {
            id: '925511776',
            name: 'HomePros - New Flyer - Urgent',
            subitems: [],
          },
          {
            id: '930051352',
            name: 'Asset - Windsor Hall | Set Up Creative Tasks (NCO)',
            subitems: [],
          },
          {
            id: '932331889',
            name: '  D.R. Horton - Memphis | Time to Set Up Tapclicks',
            subitems: [],
          },
          {
            id: '933388166',
            name:
              'Insertion order is ending soon. Please renew or ensure tasks are set for  ACH - SH - One Ten - December 2020',
            subitems: [],
          },
          {
            id: '934358983',
            name: 'Windsor Hall - Social & Display Ads',
            subitems: [],
          },
          {
            id: '935753612',
            name: 'Covert Recruiting - One Pager - Urgent',
            subitems: [],
          },
          {
            id: '938595913',
            name: 'Send Bridges Geofence Results (by location) (Dec 31, 2020)',
            subitems: [],
          },
          {
            id: '939524268',
            name: 'Get previous month Pedi Pocket centro spends and send to Matt for invoicing.  (Jan 1, 2021)',
            subitems: [],
          },
          {
            id: '943294737',
            name: 'Ranger Cattle - Social Ad',
            subitems: [],
          },
          {
            id: '947352221',
            name: 'NTX Denton - Phone Number for SEO',
            subitems: [],
          },
          {
            id: '948136269',
            name: 'StadiumHouse Logo - Gainesville',
            subitems: [],
          },
          {
            id: '948150128',
            name: 'The Griffin - Logo Design',
            subitems: [],
          },
          {
            id: '948157101',
            name: 'The Griffin - Logo Design',
            subitems: [],
          },
          {
            id: '948157393',
            name: 'The Griffin - Logo Design',
            subitems: [],
          },
          {
            id: '950968565',
            name: 'Statehouse Tallahassee - Chat overlay Code',
            subitems: [],
          },
          {
            id: '951284883',
            name: 'Spaces - Blue Haus - Social Ad',
            subitems: [],
          },
          {
            id: '951425204',
            name: 'Aptitude - Marshall Louisville - Search Insertion Order',
            subitems: [],
          },
        ],
      },
    },
  },
]
export const data7 = [
  {
    data: {
      next_items_page: {
        cursor: 'MSw1NjU0NDM1Njgsd0hDakp2LXRydFh0RWZGbm8zTE1ZLDM1NTAsMTc1LHwyMjU3MzkzNzYz',
        items: [
          {
            id: '954062774',
            name: 'DR Horton - Updated January Spends',
            subitems: [],
          },
          {
            id: '954379273',
            name: 'Polk Place - Set Up Display Campaigns',
            subitems: [],
          },
          {
            id: '954387339',
            name: 'Polk Place - Set Up Search Campaigns',
            subitems: [],
          },
          {
            id: '954396210',
            name: 'DR Horton - Woodbridge Glen - Social & Display Ads',
            subitems: [],
          },
          {
            id: '954399113',
            name: 'Woodbridge Glen - Set Up Social Campaigns',
            subitems: [],
          },
          {
            id: '954399834',
            name: 'Woodbridge Glen - Set Up Display Campaigns',
            subitems: [],
          },
          {
            id: '954486571',
            name: 'ACH - SH - Aero on 24th - Social & Display Refresh - Social & Display Ads',
            subitems: [],
          },
          {
            id: '954566418',
            name: 'Aero on 24th - Increase bids and budget for branded',
            subitems: [],
          },
          {
            id: '954581707',
            name: 'Nine Columbia - Website Updates',
            subitems: [],
          },
          {
            id: '954616500',
            name: 'DR Horton - General Branding - Social & Display Ads',
            subitems: [],
          },
          {
            id: '956132661',
            name: 'Aptitude - Pier Conway CCU - Refresh - Social & Display Ads',
            subitems: [],
          },
          {
            id: '956133041',
            name: 'Aptitude - The Marshall 4th Street Louisville - Refresh - Social & Display Ads',
            subitems: [],
          },
          {
            id: '956133233',
            name: 'Aptitude - Marshall 727 Syracuse - Refresh - Social Ad',
            subitems: [],
          },
          {
            id: '956564873',
            name: 'Team AMC - LVMR',
            subitems: [],
          },
          {
            id: '956575239',
            name: 'Team AMC - LVMR - Paid Search Advertising',
            subitems: [],
          },
          {
            id: '956626551',
            name: 'Team AMC - Meadows Bank - Revised Allocations',
            subitems: [],
          },
          {
            id: '961465189',
            name: 'ACH - SH - 908 Group - The Nine Columbia - Social & Display Ads',
            subitems: [],
          },
          {
            id: '964745227',
            name: 'ACH - SH - Denton At NTX - Video Design',
            subitems: [],
          },
          {
            id: '965557353',
            name: 'ACH - SH - Verve New Brunswick - Social & Display Ads',
            subitems: [],
          },
          {
            id: '967883182',
            name: '3rd Group - University Park - Display Spend & Branded',
            subitems: [],
          },
          {
            id: '968674952',
            name: 'Click to Call Audit',
            subitems: [],
          },
          {
            id: '968709328',
            name: 'CLS - Catalyst | Set Up Creative Tasks (NCO)',
            subitems: [],
          },
          {
            id: '968713323',
            name: 'CLS - The Edge Pensacola | Set Up Creative Tasks (NCO)',
            subitems: [],
          },
          {
            id: '968724510',
            name: 'CLS - Overlook at Nacogdoches | Set Up Creative Tasks (NCO)',
            subitems: [],
          },
          {
            id: '968819046',
            name: 'CLS - SH - The Catalyst - Social & Display Ads',
            subitems: [],
          },
        ],
      },
    },
  },
]
function consolidateData(datasets) {
  return datasets.flatMap(
    dataset =>
      dataset?.data?.next_items_page?.items?.map(item => ({
        id: item.id,
        name: item.name,
        subitems: item.subitems?.map(sub => sub.name) || [], // Extract subitem names, fallback to empty array
      })) || []
  )
}

export function countSubitemsFrequency(consolidatedData) {
  const subitemCounts = {}
  const itemCounts = {}

  consolidatedData.forEach(item => {
    item.subitems.forEach(subitem => {
      subitemCounts[subitem] = (subitemCounts[subitem] || 0) + 1
    })
  })

  return subitemCounts
}

// Example Usage:
// Assuming you have multiple datasets like data1, data2, ..., data7
export const combineArrays = (...arrays) => {
  return consolidateData(arrays.flat())
}
