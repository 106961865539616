import React, { useMemo } from 'react'
import { Droppable } from '@hello-pangea/dnd'
import DraggableListItem from './DraggableListItem'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { Box, Chip, Typography } from '@mui/material'
import { setStageStyle } from './setStageStyle'
import { formatCurrencyString } from './aggregateStageTotals'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const FlexPaper = styled(Paper)(({ theme }) => ({
  flex: '0 0 auto',
  minWidth: '15.5%',
  maxWidth: '15.5%',
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0)',
  margin: '0 8px',
}))

const Header = styled('h3')(({ label }) => ({
  margin: 0,
  textAlign: 'center',
  color: 'black',
  padding: '8px 0',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  width: '100%',
  backgroundColor: setStageStyle(label),
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
}))

const KanbanColumn = ({ columnId, items, label, total, grossProfit }) => {
  const validDroppableId = columnId ? String(columnId) : 'default-droppable'
  const { pinned } = useLayoutContext()
  const memoizedItems = useMemo(
    () => items.map((item, index) => <DraggableListItem key={item.id} item={item} index={index} />),
    [items]
  )

  return (
    <FlexPaper>
      <Header label={label}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',

            px: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: 18 }}>{label}</Typography>
            <Chip label={items.length} />
          </Box>
          <Typography sx={{ textAlign: 'left' }}>{`Total: ${formatCurrencyString(total) || '0.00'}`}</Typography>
          <Typography sx={{ textAlign: 'left' }}>{`Gross Profit: ${
            formatCurrencyString(grossProfit) || '0.00'
          }`}</Typography>
        </Box>
      </Header>
      <Droppable droppableId={validDroppableId}>
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              flex: 1,
              minHeight: '50px',
              overflowY: 'auto', // ✅ Ensure only this has scrolling
              backgroundColor: snapshot.isDraggingOver ? 'rgba(0,0,0,0.1)' : 'transparent',
            }}
          >
            {memoizedItems.length > 0 ? (
              memoizedItems
            ) : (
              <Typography sx={{ textAlign: 'center', padding: 2 }}>No deals</Typography>
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </FlexPaper>
  )
}

export default React.memo(KanbanColumn)

// import React, { useMemo } from 'react'
// import { Droppable } from '@hello-pangea/dnd'

// import DraggableListItem from './DraggableListItem'
// import { styled } from '@mui/material/styles'
// import Paper from '@mui/material/Paper'
// import { Box, Chip, Typography, useTheme } from '@mui/material'
// import { setStageStyle } from './setStageStyle'
// import { formatCurrencyString, formatWholeNumber } from './aggregateStageTotals'

// const FlexPaper = styled(Paper)(({ theme }) => ({
//   flex: '0 0 auto', // Prevent columns from stretching to fill
//   minWidth: '15.5%', // Minimum column width
//   maxWidth: '15.5%', // Optional: maximum width for columns
//   display: 'flex',
//   flexDirection: 'column',
//   border: '2px solid',
//   borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0)',
//   // overflowY: 'auto', // Enable vertical scrolling within the column
//   margin: '0 8px', // Add spacing between columns
// }))

// const Header = styled('h3')(({ label, theme }) => ({
//   margin: 0, // Remove any margin
//   textAlign: 'center',
//   color: 'black',
//   padding: '8px 0',
//   position: 'sticky',
//   top: 0, // Stick the header to the top
//   zIndex: 1,
//   width: '100%',
//   backgroundColor: setStageStyle(label), // Background color to match the column
//   borderBottom: '1px solid rgba(0, 0, 0, 0.1)', // Optional: Add bottom border for separation
// }))
// const KanbanColumn = ({ columnId, items, label, total, grossProfit }) => {
//   const theme = useTheme()
//   const kanbanBgColor = theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(232, 246, 252)'
//   return (
//     <FlexPaper>
//       <Header label={label}>
// <Box
//   sx={{
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'left',
//     justifyContent: 'center',
//     px: 2,
//   }}
// >
//   <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
//     <Typography sx={{ fontWeight: 'bold', textAlign: 'left', fontSize: 18 }}>{label}</Typography>
//     <Chip label={items.length} />
//   </Box>
//   <Typography sx={{ textAlign: 'left' }}>{`Total: ${formatCurrencyString(total) || '0.00'}`}</Typography>
//   <Typography sx={{ textAlign: 'left' }}>{`Gross Profit: ${
//     formatCurrencyString(grossProfit) || '0.00'
//   }`}</Typography>
// </Box>
//       </Header>
//       <Box
//         sx={{
//           overflowY: 'auto',
//           flex: 1,
//           display: 'flex',
//           flexDirection: 'column',
//           backgroundColor: kanbanBgColor,
//         }}
//       >
//         <Droppable droppableId={columnId}>
//           {(provided, snapshot) => {
//             return (
//               <Box
//                 ref={provided.innerRef}
//                 {...provided.droppableProps}
//                 sx={{
//                   minHeight: '50px',
//                   backgroundColor: snapshot.isDraggingOver ? 'rgba(0,0,0,0.3)' : 'transparent',
//                   flex: 1,
//                   display: 'flex',
//                   flexDirection: 'column',
//                 }}
//               >
//                 {items && items.length > 0 ? (
//                   items.map((item, index) => <DraggableListItem key={item.id} item={item} index={index} />)
//                 ) : (
//                   <Box sx={{ padding: '10px', textAlign: 'center' }}>No items</Box>
//                 )}
//                 {provided.placeholder}
//               </Box>
//             )
//           }}
//         </Droppable>
//       </Box>
//     </FlexPaper>
//   )
// }

// export default KanbanColumn
