import React, { useState, useEffect, useMemo } from 'react'
import { Drawer, Box, Typography, Tabs, Tab, useTheme, Grid, InputLabel, Avatar } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useDeal } from '../../api/aws/useDeal'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { mergeTasks, ncoTeamTasksArray } from '../NCO/ncoTaskObjects'
import { integrationFormState } from '../DataGrid/components/checklistRules'
import DisplayChannels from '../DataGrid/DisplayChannels'
import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
import DrawerTasksGrid from '../DataGrid/components/DrawerTasksGrid'
import OptimizationsContainer from '../SEO/OptimizationsContainer.tsx'
import BoardsGridHeader from '../DataGrid/BoardsGridHeader'
import TasksByOrganization from '../DataGrid/components/TasksByOrganization.tsx'
import FileManagement from '../DataGrid/components/FileManagement.js'
import CommentsInput from '../Comments/CommentsInput.tsx'
import RenderCampaign from '../StrategyCampaigns/RenderCampaign.js'
import ReviewDeal from '../DealApproval/ReviewDeal.js'
import Strategy from '../Strategy/Strategy.js'
import { useOrganizationSubfolders } from '../../api/google/useOrganizationSubfolders.ts'
import { useDealFolders } from '../../api/google/useDealFolders.ts'
import { useGoogleOrganizationFolder } from '../../api/google/useGoogleOrganizationFolder.ts'
import { useOrganizationById } from '../../api/aws/useOrganization.js'
import DetailsLoading from '../Loading/DetailsLoading.js'
import { useValidateGoogleToken } from '../../api/google/useValidateGoogleToken.ts'
import { useBatchUpdateChecklists } from '../../api/aws/checklists/useBatchUpdateChecklists.ts'
import { formatDayjsDateTime } from '../../utility/formatDayjsDateTime.js'
import { useGetChecklists } from '../../api/aws/checklists/useGetChecklists.ts'
import Checklists from '../DataGrid/components/Checklists.tsx'
import { useBatchPostChecklists } from '../../api/aws/checklists/useBatchPostChecklists.ts'
import {
  createAMTaskList,
  CreateDMSTaskList,
  createGeneralChecklist,
  createIntegrationChecklist,
  createOrganizationAMTaskList,
  createOrganizationDMSTaskList,
  createSeoChecklist,
} from '../../api/aws/checklists/lists.ts'
import ProgressBar from '../DataGrid/components/ProgressBar.tsx'
import DrawerLoading from '../Loading/DrawerLoading.js'
import { getDiff } from './getDiff.ts'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
import { useGetOrgChecklists } from '../../api/aws/checklists/useGetOrgChecklists.ts'
import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization.js'
import { useOptsByOrgId } from '../../api/aws/optimizations/useOptsByOrgId.ts'
import { useBatchUpdateOrgChecklists } from '../../api/aws/checklists/useBatchUpdateOrgChecklists.ts'
import OrgDealInfo from '../Organizations/OrgDealInfo.js'
import MtbTasksGrid from './MtbTasksGrid.js'

const MtbDrawerComponent = ({ open, onClose, context, tasks }) => {
  const { pinned } = useLayoutContext()
  const { showSnackbar } = useSnackbarContext()

  const theme = useTheme()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { validateToken, isValid } = useValidateGoogleToken()
  const { dealId, orgId, campaignId } = useParams()
  const { mutate: updateOrganization } = useUpdateOrganization()
  const [activeTab, setActiveTab] = useState(0)
  const [mtbCompletedCounts, setMtbCompletedCounts] = useState({}) // ✅ Store completed checklists
  // Fetch the deal & organization
  const { deal, isDealLoading } = useDeal(dealId)
  const { organization, isOrganizationLoading } = useOrganizationById(orgId)
  // Fetch the Organization's Google Drive Folder ID
  const { data: organizationFolder, isLoading: isOrganizationFolderLoading } = useGoogleOrganizationFolder(
    organization?.org_uuid
  )
  const orgFolderId = organizationFolder?.organizationFolderId ?? null // ✅ Default to null
  // Fetch 'Deals' Folder inside the Organization
  const { data: subfolders, isLoading: isSubfoldersLoading } = useOrganizationSubfolders(orgFolderId || '')
  // Ensure subfolders is always an array
  const safeSubfolders = Array.isArray(subfolders) ? subfolders : []
  // Find the "Deals" folder dynamically
  const dealsFolder = safeSubfolders.find(folder => folder.name === 'Deals')
  const dealsFolderId = dealsFolder ? dealsFolder.id : null
  const creation_timestamp = formatDayjsDateTime(deal?.creation_timestamp)
  // Fetch Specific Deal Folder inside 'Deals' Folder
  // const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(dealsFolderId || '', dealId || '')
  const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(
    dealsFolderId || '',
    creation_timestamp || ''
  )
  // Ensure dealFolders is always an array
  const safeDealFolders = Array.isArray(dealFolders) ? dealFolders : []
  const dealFolderId = safeDealFolders.length > 0 ? safeDealFolders[0]?.id : null
  // const { data: fetchedChecklists, isLoading: isChecklistsLoading } = useGetChecklists(deal)
  const { data: fetchedChecklists, isLoading: isChecklistsLoading } = useGetOrgChecklists(organization)
  const [checklists, setChecklists] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [pendingChecklistUpdates, setPendingChecklistUpdates] = useState([]) // Store pending updates
  const [pendingOrgUpdates, setPendingOrgUpdates] = useState({}) // Store pending updates
  const { mutateAsync: batchUpdateAsync } = useBatchUpdateOrgChecklists()
  const { mutate: createChecklistsBatch } = useBatchPostChecklists()
  const { data: optimizations, isLoading: isOptimizationsLoading } = useOptsByOrgId(organization?.org_uuid)

  useEffect(() => {
    if (organization) {
      console.log('organization', organization)
    }
  }, [organization])
  useEffect(() => {
    // if (!deal?.deal_uuid || isChecklistsLoading) return
    if (!organization?.org_uuid || isChecklistsLoading) return

    // Determine the desired checklist types
    const checklistTypes = [
      // createGeneralChecklist(deal), ******
      // (deal.isSeo1 || deal.isSeo2 || deal.isSeo3 || deal.isSeo4) && createSeoChecklist(deal), ******
      context === 'mtb' && createOrganizationAMTaskList(organization),
      context === 'mtb' && createOrganizationDMSTaskList(organization),
    ].filter(Boolean)

    // Filter out already existing checklists (by type)
    const missingChecklists = checklistTypes.filter(
      checklist => !fetchedChecklists.some(c => c.type === checklist.type)
    )

    console.log('🔄 Missing Checklists:', missingChecklists)

    if (missingChecklists.length > 0) {
      createChecklistsBatch(missingChecklists, {
        onSuccess: () => {
          // After creation, invalidate or refetch the checklist query
          queryClient.invalidateQueries(['checklists', `ORG_${organization.org_uuid}`])
        },
      })
    }
  }, [organization, fetchedChecklists, isChecklistsLoading, createChecklistsBatch, queryClient])

  const handleValidateToken = (token, userId) => {
    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: token, refreshToken: '', userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
    }
  }
  // Set checklists when fetched
  useEffect(() => {
    if (fetchedChecklists) {
      console.log('fetchedChecklists', fetchedChecklists)
      setChecklists(fetchedChecklists)
    }
  }, [fetchedChecklists])
  useEffect(() => {
    if (pendingOrgUpdates) {
      console.log('pendingOrgUpdates', pendingOrgUpdates)
    }
  }, [pendingOrgUpdates])

  // Derive general and integration checklists from the updated `checklists` state
  const generalChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'General'), [checklists])
  const amTaskList = useMemo(() => checklists.filter(chk => chk?.type === 'AM'), [checklists])
  const dmsTaskList = useMemo(() => checklists.filter(chk => chk?.type === 'DMS'), [checklists])
  const seoChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'SEO'), [checklists])
  const combinedTasksAndOptimizations = useMemo(
    () =>
      !isOptimizationsLoading && !isChecklistsLoading
        ? [
            ...(Array.isArray(optimizations) ? optimizations : []),
            ...(amTaskList?.[0]?.sections?.[0]?.items || []),
            ...(dmsTaskList?.[0]?.sections?.[0]?.items || []),
          ]
        : [],
    [optimizations, isOptimizationsLoading, isChecklistsLoading, amTaskList, dmsTaskList]
  )

  // const mergedTaskLists = [
  //   ...generalChecklist?.[0]?.sections?.[0]?.items,
  //   ...amTaskList?.[0]?.sections?.[0]?.items,
  //   ...dmsTaskList?.[0]?.sections?.[0]?.items,
  // ]
  // console.log('mergedTaskLists', mergedTaskLists)
  useEffect(() => {
    if (generalChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedOrgField (lowercase)
      const progress = {}

      generalChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedOrgField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedOrgField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setMtbCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (amTaskList.length > 0) {
      // We'll build a progress object keyed by the relatedOrgField (lowercase)
      const progress = {}

      amTaskList.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedOrgField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedOrgField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setMtbCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (dmsTaskList.length > 0) {
      // We'll build a progress object keyed by the relatedOrgField (lowercase)
      const progress = {}

      dmsTaskList.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedOrgField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedOrgField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setMtbCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (seoChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedOrgField (lowercase)
      const progress = {}

      seoChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedOrgField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedOrgField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setMtbCompletedCounts(prev => ({ ...prev, ...progress }))
    }
  }, [generalChecklist, amTaskList, dmsTaskList, seoChecklist])

  useEffect(() => {
    if (!isOrganizationLoading) {
      const seoProgress = Number(organization?.mtbCompletedCounts?.completeseoform ?? 0)
      const newSeoProgress = Number(mtbCompletedCounts.completeseoform)
      if (mtbCompletedCounts?.completeseoform != null && seoProgress !== newSeoProgress) {
        setPendingOrgUpdates(prev => ({
          ...prev,
          mtbCompletedCounts: { completeseoform: newSeoProgress },
        }))
      }
      const amProgress = Number(organization?.mtbCompletedCounts?.amprogress ?? 0)
      const newAmProgress = Number(mtbCompletedCounts.amprogress)
      if (mtbCompletedCounts?.amprogress != null && amProgress !== newAmProgress) {
        setPendingOrgUpdates(prev => ({
          ...prev,
          mtbCompletedCounts: { amprogress: newAmProgress },
        }))
      }
      const dmsProgress = Number(organization?.mtbCompletedCounts?.dmsprogress ?? 0)
      const newDmsProgress = Number(mtbCompletedCounts.dmsprogress)
      if (mtbCompletedCounts?.dmsprogress != null && dmsProgress !== newDmsProgress) {
        setPendingOrgUpdates(prev => ({
          ...prev,
          mtbCompletedCounts: { dmsprogress: newDmsProgress },
        }))
      }
    }
  }, [mtbCompletedCounts, isOrganizationLoading, organization])
  /** ✅ Handles tab navigation */
  const handleTabChange = (event, newValue) => {
    const paths = ['main', 'SEO', 'AM', 'DMS', `notes`, `onboarding`, 'filemgmt', `orgtasks`, 'deals']
    setActiveTab(newValue)
    navigate(`${orgId}/${paths[newValue]}`)
  }
  useEffect(() => {
    if (location.pathname.includes(`SEO`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`AM`)) {
      setActiveTab(2)
    } else if (location.pathname.includes(`DMS`)) {
      setActiveTab(3)
    } else if (location.pathname.includes(`notes`)) {
      setActiveTab(4)
    } else if (location.pathname.includes(`onboarding`)) {
      setActiveTab(5)
    } else if (location.pathname.includes(`filemgmt`)) {
      setActiveTab(6)
    } else if (location.pathname.includes(`orgtasks`)) {
      setActiveTab(7)
    } else if (location.pathname.includes(`deals`)) {
      setActiveTab(8)
    } else {
      setActiveTab(0)
    }
  }, [location, campaignId])

  // Helper: find the section and item indices for a given itemId.
  const findIndices = (checklist, itemId) => {
    console.log('checklist: ', checklist)
    for (let sectionIndex = 0; sectionIndex < checklist.sections.length; sectionIndex++) {
      const section = checklist.sections[sectionIndex]
      for (let itemIndex = 0; itemIndex < section.items.length; itemIndex++) {
        if (section.items[itemIndex].id === itemId) {
          return { sectionIndex, itemIndex }
        }
      }
    }
    return null
  }

  const handleStatusChange = (checklist, itemId, newStatus) => {
    console.log('HANDLE STATUS CHANGE', checklist, itemId, newStatus)
    if (!itemId || !newStatus) return

    const indices = findIndices(checklist, itemId)
    if (!indices) {
      console.error('Could not find indices for item:', itemId)
      return
    }

    setPendingChecklistUpdates(prev => [
      ...prev.filter(update => update.itemId !== itemId),
      {
        PK: checklist.PK,
        SK: checklist.SK,
        itemId,
        newStatus,
        fieldName: 'status',
        sectionIndex: indices.sectionIndex,
        itemIndex: indices.itemIndex,
      },
    ])

    // Optimistic update:
    setChecklists(prevChecklists =>
      prevChecklists.map(chk =>
        chk.PK === checklist.PK && chk.SK === checklist.SK
          ? {
              ...chk,
              sections: chk.sections.map((section, sIndex) => ({
                ...section,
                items: section.items.map((item, iIndex) =>
                  item.id === itemId ? { ...item, status: newStatus } : item
                ),
              })),
            }
          : chk
      )
    )
  }

  const handleClose = async () => {
    if (pendingChecklistUpdates.length > 0) {
      try {
        const response = await batchUpdateAsync(pendingChecklistUpdates)
        if (response?.status === 200) {
          console.log('Invalidating org....', organization?.org_uuid)
          queryClient.invalidateQueries(['organization', organization?.org_uuid])
        }
        setPendingChecklistUpdates([]) // Clear after successful update
      } catch (error) {
        console.error('Error updating checklists:', error)
        // Optionally, handle the error or show a notification
      }
    }
    if (Object.keys(pendingOrgUpdates).length > 0) {
      await handleUpdate()
    }
    if (onClose) {
      onClose()
    }
  }

  const handleUpdate = async () => {
    // No update needed if nothing has changed.
    if (Object.keys(pendingOrgUpdates).length === 0) return

    const orgId = organization?.org_uuid

    // Build the new organization object by merging the original organization, pending changes,
    // and any additional values like mtbCompletedCounts.
    const newOrganization = {
      ...organization,
      ...pendingOrgUpdates,
      mtbCompletedCounts: { ...mtbCompletedCounts },
      queryKey: ['organization', orgId],
    }

    // Compute the diff between the original organization and the new organization object.
    const diff = getDiff(organization, newOrganization)

    console.log('Computed diff for update:', diff)

    // If diff is empty (nothing changed), exit early.
    if (Object.keys(diff).length === 0) return

    try {
      updateOrganization(
        { orgId, orgData: diff },
        {
          onSuccess: () => {
            console.log('✅ Deal successfully updated with diff:', diff)
            setPendingOrgUpdates({}) // Reset updates after success
          },
          onError: error => {
            console.error('❌ Error updating deal:', error)
            setPendingOrgUpdates({}) // Prevent stale updates
          },
        }
      )
    } catch (e) {
      console.error('[BoardsDrawer] error updating deal', e)
    }
  }

  const formatDecimal = decimal => Math.round(decimal * 100)
  // const handleAddTaskComment = async (selectedTask, newNoteObj) => {
  //   if (!selectedTask || !newNoteObj) return

  //   // Append the new note object to the existing comments array
  //   const updatedComments = [{ ...newNoteObj, context: selectedTask?.title }, ...(selectedTask.comments || [])]

  //   // Use your findIndices helper to get the correct section and item indices.
  //   const indices = findIndices(organization?.optimizations?.[0], selectedTask.id)
  //   if (!indices) {
  //     console.error('Could not determine indices for item', selectedTask.id)
  //     return
  //   }

  // const updatePayload = {
  //   PK: organization?.optimizations?.[0].PK,
  //   SK: generalChecklist[0].SK,
  //   itemId: selectedTask.id,
  //   fieldName: 'comments', // specifying a comment update
  //   newValue: updatedComments,
  //   sectionIndex: indices.sectionIndex,
  //   itemIndex: indices.itemIndex,
  // }

  //   console.log('Update payload for comments:', updatePayload)

  //   batchUpdateAsync([updatePayload], {
  //     onSuccess: () => {
  //       showSnackbar('Task comment added successfully', 'success')
  //     },
  //     onError: err => {
  //       console.error('Failed to add task comment:', err)
  //       showSnackbar('Failed to add task comment', 'error')
  //     },
  //   })
  // }
  const handleAddTaskComment = async (selectedTask, newNoteObj) => {
    if (!selectedTask || !newNoteObj) return

    // Append the new note object to the existing comments array.
    const updatedComments = [
      { ...newNoteObj, context: selectedTask?.title || selectedTask?.name },
      ...(selectedTask.comments || []),
    ]

    let updatePayload

    // If the selected task is an optimization (i.e. it does NOT have sections)
    if (selectedTask?.SK && selectedTask.SK.startsWith('OPTIMIZATION')) {
      // Use selectedTask's own PK and SK
      updatePayload = {
        PK: selectedTask.PK,
        SK: selectedTask.SK,
        itemId: selectedTask.id,
        fieldName: 'comments',
        newValue: updatedComments,
        // No section or item indices needed for optimizations.
      }
    } else {
      // Otherwise, assume it's a checklist task with sections.
      // Use your findIndices helper to get the correct indices.
      const indices = findIndices(organization?.optimizations?.[0], selectedTask.id)
      if (!indices) {
        console.error('Could not determine indices for item', selectedTask.id)
        return
      }
      updatePayload = {
        PK: organization?.optimizations?.[0].PK, // or however you get the checklist's PK
        SK: generalChecklist[0].SK, // make sure this is correct for checklist tasks
        itemId: selectedTask.id,
        fieldName: 'comments',
        newValue: updatedComments,
        sectionIndex: indices.sectionIndex,
        itemIndex: indices.itemIndex,
      }
    }

    console.log('Update payload for comments:', updatePayload)

    batchUpdateAsync([updatePayload], {
      onSuccess: () => {
        showSnackbar('Task comment added successfully', 'success')
      },
      onError: err => {
        console.error('Failed to add task comment:', err)
        showSnackbar('Failed to add task comment', 'error')
      },
    })
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '85%',
          height: '100vh',
          pt: pinned ? '60px' : 0.8,
          transition: 'all 0.3s ease',
          overflowY: 'hidden',
        },
      }}
    >
      {isDealLoading || isOrganizationLoading ? (
        <DrawerLoading />
      ) : (
        <Box>
          <ExpandCollapseButton
            expanded={expanded}
            setExpanded={setExpanded}
            displayElement={
              <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={4}>
                  <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
                    {organization?.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {location?.pathname.includes('AM') ? (
                    <>
                      <InputLabel>AM Progress</InputLabel>
                      <ProgressBar progress={formatDecimal(mtbCompletedCounts?.amprogress)} />
                    </>
                  ) : location?.pathname.includes('DMS') ? (
                    <>
                      <InputLabel>DMS Progress</InputLabel>
                      <ProgressBar progress={formatDecimal(mtbCompletedCounts?.dmsprogress)} />
                    </>
                  ) : location?.pathname.includes('SEO') ? (
                    <>
                      <InputLabel>SEO Progress</InputLabel>
                      <ProgressBar progress={formatDecimal(mtbCompletedCounts?.seoprogress)} />
                    </>
                  ) : (
                    <>
                      <InputLabel>MTB Progress</InputLabel>
                      <ProgressBar progress={formatDecimal(mtbCompletedCounts?.completeseoform)} />
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.8,
                      width: '100%',
                      justifyContent: 'flex-end',
                      pr: 2,
                    }}
                  >
                    <Avatar
                      src={organization.photoUrl}
                      sx={{
                        width: 48,
                        height: 48,
                        objectFit: 'cover',
                        objectPosition: 'center',
                        ':hover': { opacity: '0.5' },
                      }}
                      alt={organization?.name}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(`/organizations/${organization?.org_uuid}/details`)
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            }
          >
            <BoardsGridHeader newDeal={deal} organization={organization} />
          </ExpandCollapseButton>
          {/* Tabs */}
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
            <Tab label={<Typography>Main</Typography>} />
            {/* <Tab label={<Typography>SEO</Typography>} disabled={!mtbCompletedCounts?.completeseoform != null} /> */}
            <Tab label={<Typography sx={{ borderBottom: `1px solid ${theme.palette.icon.seo}` }}>SEO</Typography>} />
            <Tab label={<Typography sx={{ borderBottom: `1px solid ${theme.palette.icon.deals}` }}>AM</Typography>} />
            <Tab label={<Typography sx={{ borderBottom: `1px solid ${theme.palette.icon.deals}` }}>DMS</Typography>} />
            <Tab label={<Typography>Notes</Typography>} />
            <Tab label={<Typography>Checklists</Typography>} />
            <Tab label={<Typography>File Mgmt</Typography>} />
            <Tab label={<Typography>Tasks By Organization</Typography>} />
            <Tab label={<Typography>Deals</Typography>} />
          </Tabs>

          {/* Tab Content */}
          <Box sx={{ height: 'calc(100vh - 150px)' }}>
            {/* {activeTab === 0 && <OptimizationsContainer />} */}
            {activeTab === 0 && (
              <OptimizationsContainer organization={organization} isOrganizationLoading={isOrganizationLoading} />
            )}
            {activeTab === 1 && (
              <MtbTasksGrid
                deal={deal}
                handleStatusChange={handleStatusChange}
                updates={pendingOrgUpdates}
                setUpdates={setPendingOrgUpdates}
                checklists={checklists}
                checklistProgress={mtbCompletedCounts}
                checklistType={'SEO'}
                context={'mtb'}
              />
            )}
            {activeTab === 2 && (
              <MtbTasksGrid
                deal={organization}
                handleStatusChange={handleStatusChange}
                updates={pendingOrgUpdates}
                setUpdates={setPendingOrgUpdates}
                checklists={checklists}
                checklistProgress={mtbCompletedCounts}
                checklistType={'AM'}
                context={'mtb'}
              />
            )}
            {activeTab === 3 && (
              <MtbTasksGrid
                deal={organization}
                handleStatusChange={handleStatusChange}
                updates={pendingOrgUpdates}
                setUpdates={setPendingOrgUpdates}
                checklists={checklists}
                checklistProgress={mtbCompletedCounts}
                checklistType={'DMS'}
                context={'mtb'}
              />
            )}
            {activeTab === 4 && (
              <CommentsInput
                expanded={expanded}
                context={context}
                // checklistItems={generalChecklist?.[0]?.sections?.[0]?.items || []}
                // checklistItems={[...optimizations, ...checklists?.[0]?.sections?.[0]?.items]}
                checklistItems={combinedTasksAndOptimizations}
                // checklistItems={[
                //   ...optimizations,
                //   ...amTaskList?.[0]?.sections?.[0]?.items,
                //   ...dmsTaskList?.[0]?.sections?.[0]?.items,
                // ]}
                onAddTaskComment={handleAddTaskComment}
              />
            )}
            {activeTab === 5 && !isDealLoading && (
              <Checklists
                deal={deal}
                handleStatusChange={handleStatusChange}
                checklists={seoChecklist}
                isLoading={isChecklistsLoading}
                context={context}
              />
            )}
            {activeTab === 6 && (
              <FileManagement newDeal={deal} organization={organization} dealFolder={safeDealFolders} />
            )}
            {activeTab === 7 && <TasksByOrganization />}
            {activeTab === 8 && <OrgDealInfo />}
          </Box>
        </Box>
      )}
    </Drawer>
  )
}

export default MtbDrawerComponent
