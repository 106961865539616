import React, { useState } from 'react'
import { Box, List, ListItem, Typography, Avatar, IconButton, useTheme, Grid, Tooltip, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { User } from './createMentionSuggestionOptions'
import { Note } from './types.ts'
import FallbackIcon from '../../images/Grobotic png.png'
import PreviewThumbnail from './PreviewThumbnail.tsx'
import PreviewAttachments from './PreviewAttachments.tsx'

interface CommentListProps {
  notes: Note[]
  isLoading: boolean
  users: User[]
  openFrame: (embedUrl: string) => void
  onDeleteNote: (note_id: string) => void
}

const CommentList: React.FC<CommentListProps> = ({ notes, isLoading, users, openFrame, onDeleteNote }) => {
  const [filteredNotes, setFilteredNotes] = useState(notes ?? [])
  const handleSetIntegrationFilter = () => {
    const miaNotes = notes?.filter(n => n?.context === 'integration')
    setFilteredNotes(miaNotes)
  }
  const handleSetNcoFilter = () => {
    const ncoNotes = notes?.filter(n => n?.context === 'nco')
    setFilteredNotes(ncoNotes)
  }
  const handleSetSeoFilter = () => {
    const seoNotes = notes?.filter(n => n?.context === 'seo')
    setFilteredNotes(seoNotes)
  }
  const handleSetCreativeNotes = () => {
    const creativeNotes = notes?.filter(n => n?.context === 'creative')
    setFilteredNotes(creativeNotes)
  }
  const handleClearFilters = () => {
    setFilteredNotes(notes)
  }
  const theme = useTheme()
  const attachmentsLength = notes?.attachments?.length
  const attachmentsGridSize = attachmentsLength <= 2 ? 3 : attachmentsLength >= 3 ? 12 / attachmentsLength : 3
  return (
    <>
      {/* <Button onClick={handleSetIntegrationFilter}>Integration</Button>
      <Button onClick={handleSetNcoFilter}>NCO</Button>
      <Button onClick={handleSetSeoFilter}>SEO</Button>
      <Button onClick={handleSetCreativeNotes}>Creative</Button>
      <Button onClick={handleClearFilters}>Clear</Button> */}
      <List>
        {notes.map(note => {
          const noteOwner = users.find((user: any) => user.id === note.owner?.[0])
          return (
            <ListItem
              key={note.note_id}
              sx={{
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                bgcolor: theme.palette.background.mentions,
                '--color': theme.palette.text.link,
                borderRadius: '10px',
                p: 1,
              }}
            >
              {/* Row 1: Header with Avatar, Name/Date, Context Label, and Delete Button */}
              <Grid container alignItems='center'>
                {/* Owner Avatar */}
                <Grid item>
                  {noteOwner && (
                    <Avatar
                      alt={noteOwner.name}
                      src={noteOwner.image_512 || noteOwner.imageUrl}
                      sx={{ width: 48, height: 48 }}
                    />
                  )}
                </Grid>
                {/* Owner Name and Date */}
                <Grid item xs>
                  <Box sx={{ ml: 2 }}>
                    <Typography sx={{ color: theme.palette.text.primary }} variant='subtitle2' fontWeight='bold'>
                      {noteOwner?.name}
                    </Typography>
                    <Typography variant='caption'>{new Date(note.createdAt).toLocaleString()}</Typography>
                  </Box>
                </Grid>
                {/* Context Label */}
                <Grid item>
                  {note?.context === 'seo' ? (
                    <Typography sx={{ fontSize: 12, color: 'rgb(238, 111, 141)' }}>SEO</Typography>
                  ) : note?.context === 'nco' ? (
                    <Typography sx={{ fontSize: 12, color: 'rgb(52, 211, 145)' }}>NCO</Typography>
                  ) : note?.context === 'creative' ? (
                    <Typography sx={{ fontSize: 12, color: 'rgb(150, 88, 238)' }}>Creative</Typography>
                  ) : note?.context === 'website' ? (
                    <Typography sx={{ fontSize: 12, color: 'rgb(112, 84, 240)' }}>Website</Typography>
                  ) : note?.context === 'mtb' ? (
                    <Typography sx={{ fontSize: 12, color: 'rgb(20, 119, 248)' }}>MTB</Typography>
                  ) : (
                    <Typography sx={{ fontSize: 12, color: theme.palette.text.highlight }}>{note?.context}</Typography>
                  )}
                </Grid>
                {/* Delete Button */}
                <Grid item>
                  <IconButton edge='end' aria-label='delete' onClick={() => onDeleteNote(note.note_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {/* Row 2: Note Contents */}
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ color: theme.palette.text.primary }}
                  variant='body2'
                  dangerouslySetInnerHTML={{ __html: note.contents }}
                />
              </Box>
              {/* Row 3: Attachments (if any) */}
              {note.attachments && note.attachments.length > 0 && (
                <PreviewAttachments note={note} openFrame={openFrame} fallbackIcon={FallbackIcon} />
              )}
              {/* Row 3: Combined Row for Thumbnail & Tagged Users */}
              {((note.thumbnailLink && note.embedUrl) || (note.taggedUsers && note.taggedUsers.length > 0)) && (
                <Grid container sx={{ mt: 1, alignItems: 'center' }}>
                  {/* Thumbnail on left */}
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {note.thumbnailLink && note.embedUrl && (
                      <PreviewThumbnail note={note} openFrame={openFrame} fallbackIcon={FallbackIcon} />
                    )}
                  </Grid>
                  {/* Tagged Users on right */}
                  <Grid item xs={12}>
                    {note.taggedUsers && note.taggedUsers.length > 0 && (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {note.taggedUsers.map((user: any) => {
                          return (
                            <Tooltip key={user?.id} title={user?.name}>
                              <Avatar
                                key={user.id}
                                alt={user.name}
                                src={user.imageUrl}
                                sx={{ width: 24, height: 24, ml: 0.5 }}
                              />
                            </Tooltip>
                          )
                        })}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

export default CommentList

// import React from 'react'
// import { Box, List, ListItem, ListItemText, Typography, Avatar, IconButton, useTheme, Grid } from '@mui/material'
// import DeleteIcon from '@mui/icons-material/Delete'
// import { User } from './createMentionSuggestionOptions'
// import { Note, MentionUser, Organization, CommentsInputProps } from './types.ts'
// import FallbackIcon from '../../images/Grobotic png.png'
// import { baseUrl } from '../../api/queryMutations/mutationFunctions.js'
// import PreviewThumbnail from './PreviewThumbnail.tsx'

// interface CommentListProps {
//   notes: Note[]
//   users: User[]
//   openFrame: (embedUrl: string) => void
//   onDeleteNote: (note_id: string) => void
// }

// const CommentList: React.FC<CommentListProps> = ({ notes, users, openFrame, onDeleteNote }) => {
//   const theme = useTheme()
//   return (
//     <List>
//       <Grid container>
//         {notes.map(note => {
//           const noteOwner = users.find((user: any) => user.id === note.owner?.[0])
//           return (
//             <React.Fragment key={note.note_id}>
//               <ListItem
//                 sx={{
//                   mb: 2,
//                   display: 'flex',
//                   alignItems: 'flex-start',
//                   bgcolor: theme.palette.background.mentions,
//                   '--color': theme.palette.text.link,
//                   borderRadius: '10px',
//                 }}
//               >
//                 {noteOwner && (
//                   <Avatar
//                     alt={noteOwner.name}
//                     src={noteOwner.image_512 || noteOwner.imageUrl}
//                     sx={{ width: 48, height: 48, mr: 2 }}
//                   />
//                 )}
//                 <ListItemText
//                   sx={{ color: theme.palette.text.primary }}
//                   primary={
//                     <Box
//                       sx={{
//                         display: 'flex',
//                         flexDirection: 'row',
//                         alignItems: 'center',
//                         justifyContent: 'space-between',
//                       }}
//                     >
//                       {noteOwner && (
//                         <>
//                           <Typography variant='subtitle2' fontWeight='bold'>
//                             {noteOwner.name} - {new Date(note.createdAt).toLocaleString()}
//                           </Typography>

//                           {note?.context === 'seo' && <Typography sx={{ color: 'rgb(238, 111, 141)' }}>SEO</Typography>}
//                           {note?.context === 'nco' && <Typography sx={{ color: 'rgb(52, 211, 145)' }}>NCO</Typography>}
//                           {note?.context === 'creative' && (
//                             <Typography sx={{ color: 'rgb(111, 31, 223)' }}>Creative</Typography>
//                           )}
//                         </>
//                       )}
//                     </Box>
//                   }
//                   secondary={
//                     <>
//                       <Grid item xs={12}>
//                         <Typography variant='body2' dangerouslySetInnerHTML={{ __html: note.contents }} />
//                       </Grid>
//                     </>
//                   }
//                 />
//                 {/* Render clickable thumbnail if available */}
//                 {note.thumbnailLink && note.embedUrl && (
//                   <Grid item xs={12}>
//                     <PreviewThumbnail note={note} openFrame={openFrame} fallbackIcon={FallbackIcon} />
//                   </Grid>
//                 )}
//                 <IconButton edge='end' aria-label='delete' onClick={() => onDeleteNote(note.note_id)}>
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             </React.Fragment>
//           )
//         })}
//       </Grid>
//     </List>
//   )
// }

// export default CommentList
