import React, { useState, useEffect } from 'react'
import { useCreateTask } from '../../api/aws/useCreateTask'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  useTheme,
} from '@mui/material'
import SelectTaskType from './CreateTasks/SelectTaskType'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactQuill from 'react-quill' // Assuming you're using React Quill for rich text
import 'react-quill/dist/quill.snow.css' // Import quill styles
import ThemedImage from '../../images/ThemedImage/ThemedImage'
import GroLogoBlack from '../../images/GRObotic Logo-darkfull.png'
import GroLogoWhite from '../../images/GRO-logo-white-01.png'
import SelectMasterStatus from './CreateTasks/SelectMasterStatus'
import { CreateProjectTextField } from '../../style/styleElements'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { newTaskState, tasksInitialState } from '../../api/initialState/initialState'
import DealsAutocomplete from '../TeamTasks/DealsAutocomplete'
import { dialogStyles, StyledTaskTextField } from './style'
import CreateTaskHeader from './CreateTaskHeader'
import TaskRichText from './TaskRichText'
import { useDeals } from '../../api/aws/useDeals'
import dayjs from 'dayjs'
import { calculateTaskDays, workday } from './taskUtil'

const CreateTask = ({ open, onClose }) => {
  const { deals, isDealsLoading, isDealsError } = useDeals(true, ['deals'])
  const { mutate: createTask } = useCreateTask()
  const theme = useTheme()
  const [newTask, setNewTask] = useState(newTaskState)
  const [selectedDeal, setSelectedDeal] = useState(null) // or useState({});
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedMasterStatus, setSelectedMasterStatus] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const { mutate: sendNotification } = useNotifyMessage()
  // console.log('Projects in CreateTask: ', projects)
  const handleChange = e => {
    const { name, value } = e.target
    setNewTask({ ...newTask, [name]: value })
  }
  const handleDateChange = (field, value) => {
    setNewTask(prev => ({
      ...prev,
      [field]: value,
    }))
  }
  ///////////////////////// Batch Creation of Tasks for Testing ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const handleBatchCreate = async () => {
    setIsCreating(true)

    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0]

    // Generate 50 tasks and create them
    const tasksToCreate = []
    for (let i = 0; i < 2; i++) {
      const taskData = {
        taskName: `Batch Task #${i + 1}`, // Example task name
        project: {},
        masterStatus: 'Select Status',
        taskType: 'New Client Onboard',
        startDate: today, // Set startDate to today's date
        dueDate: null,
        doneDate: null,
        AM: [],
        DMS: [],
        description: '',
        // Populate other task data as needed
      }
      tasksToCreate.push(taskData)
    }

    // Create tasks asynchronously
    const createTasksPromises = tasksToCreate.map(taskData => createTask(taskData))
    try {
      await Promise.all(createTasksPromises)
      setIsCreating(false)
    } catch (error) {
      console.error('Error creating tasks:', error)
      setIsError(true)
      setIsCreating(false)
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSubmit = async e => {
    e.preventDefault()
    setIsCreating(true)

    if (
      newTask.taskName &&
      selectedDeal && // Now an object, so this check ensures it's not empty
      selectedTaskType &&
      newTask.startDate &&
      newTask.dueDate &&
      newTask.description &&
      selectedMasterStatus
    ) {
      const taskDays = selectedTaskType?.label ? calculateTaskDays(selectedTaskType?.label) : 0
      const newDateDue = newTask?.startDate && taskDays ? workday(newTask?.startDate, taskDays) : null
      console.log('taskDays', taskDays)
      console.log('newDateDue', newDateDue)
      const updatedTask = {
        ...newTask,
        deal: selectedDeal?.deal_uuid, // Include the entire project object
        taskType: selectedTaskType.label,
        taskTypeColor: selectedTaskType.color,
        taskDays: taskDays,
        dateDue: newDateDue,
        masterStatus: selectedMasterStatus.label,
        masterStatusColor: selectedMasterStatus.color,
        // Add any other fields required for task creation
      }
      try {
        createTask(updatedTask, {
          onSuccess: data => {
            setIsCreating(false)
            onClose()
          },
          onError: error => {
            setIsCreating(false)
            setIsError(true)
          },
        })

        onClose() // Close dialog on successful task creation
      } catch (error) {
        console.error(`Error Creating task or tread: ${error}`)
      }
    } else {
      console.error('Task details are incomplete')
      setIsError(true) // Display error message to the user
    }

    setIsCreating(false)
  }

  useEffect(() => {
    if (open) {
      setNewTask(newTaskState)
      setSelectedDeal(null)
      setSelectedTaskType(null)
      setSelectedMasterStatus(null)
      setIsError(false)
    }
  }, [open])

  const handleDealSelect = selectedDeal => {
    // Directly use the selected project object
    if (selectedDeal) {
      // Update selectedDeal state with the entire project object
      setSelectedDeal(selectedDeal)

      // Update the newTask state if necessary, with details from the selected project
      // Note: Adjust this part based on the specific details you need from the project for the new task
      setNewTask(prev => ({
        ...prev,
        // Assuming you need to update these fields based on the selected project;
        // otherwise, you might not need to update the task state here
        AM: selectedDeal.AM || [],
        DMS: selectedDeal.DMS || [],
        PM: selectedDeal.PM || [],
        SEO: selectedDeal.SEO || [],
        QA: selectedDeal.QA || [],
        MIA: selectedDeal.MIA || [],
      }))
    }
  }

  const handleDescriptionChange = newValue => {
    console.log('newValue', newValue)
    setNewTask(prev => ({ ...prev, description: newValue }))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
    >
      <CreateTaskHeader context={'Create Task'} />
      <DialogContent>
        <Box component='form' onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <StyledTaskTextField
                label='Task Name'
                name='taskName'
                value={newTask.taskName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {isDealsLoading ? (
                <CircularProgress />
              ) : (
                <DealsAutocomplete
                  multiple={false}
                  deals={deals}
                  label={'Deals'}
                  value={selectedDeal}
                  onAddDeal={handleDealSelect}
                />
              )}
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SelectTaskType selectedTaskType={selectedTaskType} setSelectedTaskType={setSelectedTaskType} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <SelectMasterStatus
                selectedMasterStatus={selectedMasterStatus}
                setSelectedMasterStatus={setSelectedMasterStatus}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <DatePicker
                  label='Start Date'
                  value={newTask.startDate}
                  onChange={newValue => handleDateChange('startDate', newValue)}
                  component={{
                    TextField: props => <TextField {...props} />,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <DatePicker
                  label='Due Date'
                  value={newTask.dueDate}
                  onChange={newValue => handleDateChange('dueDate', newValue)}
                  component={{
                    TextField: props => <TextField {...props} />,
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TaskRichText content={newTask?.description} onContentChange={handleDescriptionChange} />
            </Grid>
            <Button type='submit' variant='contained' disabled={isCreating}>
              Save
            </Button>
          </Grid>
        </Box>
        {/* <Button variant='contained' onClick={handleBatchCreate}>
          Create 50 Tasks
        </Button> */}
        {isCreating && <CircularProgress />}
        {isError && <Typography color='error'>Error creating task</Typography>}
      </DialogContent>
    </Dialog>
  )
}

export default CreateTask
