import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useUpdateMassItems } from './useUpdateMassItems'

// Define the structure of each update item
export interface UpdateItem {
  primaryKey: string // 'deal_uuid'
  tableName: string // 'deals'
  itemId: string // The unique ID of the deal
  fieldName: string // The field being updated
  newValue: string | string[] | boolean // New value for the field
}

// Type for mass update data
export interface DynamicMassUpdateData {
  [field: string]: string | string[] | boolean | undefined
}

type SnackbarVariant = 'success' | 'error'

// Placeholder functions (should be replaced with actual implementations)
const handleClosePopover = (): void => {
  // Your logic for closing popover
}

const showSnackbar = (message: string, variant: SnackbarVariant): void => {
  // Your logic for displaying messages
}

const useMassUpdateDeals = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  // Use a mutation to handle mass updates
  const { mutate: updateMassItems } = useUpdateMassItems()

  // Function to process and trigger the mass update
  const handleMassUpdateDeals = (massUpdateData: DynamicMassUpdateData, selectionModel: string[]): void => {
    if (!selectionModel.length) {
      showSnackbar('No deals selected for update', 'error')
      return
    }

    setIsUpdating(true)
    const updates: UpdateItem[] = []

    // Iterate over each field/value pair in massUpdateData
    Object.entries(massUpdateData).forEach(([field, value]) => {
      if (value !== undefined && (typeof value === 'string' ? value.trim().length > 0 : value.length > 0)) {
        selectionModel.forEach(id => {
          updates.push({
            primaryKey: 'deal_uuid',
            tableName: 'deals',
            itemId: id, // Use deal UUID
            fieldName: field,
            newValue: value, // Support strings, arrays, and booleans
          })
        })
      }
    })

    if (updates.length === 0) {
      showSnackbar('No valid updates to apply', 'error')
      setIsUpdating(false)
      return
    }
    console.log('UPDATES', updates)
    // Use the same update function regardless of how many rows are selected.
    updateMassItems(
      { updates },
      {
        onSuccess: () => {
          setIsUpdating(false)
          handleClosePopover()
          showSnackbar(
            selectionModel.length === 1
              ? 'Task updated successfully'
              : `${selectionModel.length} Tasks updated successfully`,
            'success'
          )
        },
        onError: () => {
          setIsUpdating(false)
          showSnackbar(selectionModel.length === 1 ? 'Task update failed' : 'Tasks update failed', 'error')
        },
      }
    )
  }

  return { handleMassUpdateDeals, isUpdating }
}

export default useMassUpdateDeals
