import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../../queryMutations/mutationFunctions'

// Define the task data structure
interface TaskData {
  PK?: string
  SK?: string
  orgId: string
  title: string
  description: string
  status?: string
  createdTime?: string
  dueDate?: string
  assignee?: string
  priority?: string
  [key: string]: any // Allow additional properties
}

// API call function
const postTask = async (taskData: TaskData): Promise<TaskData> => {
  const url = `${baseUrl}/aws/tasks_table`
  console.log('[usePostTask] Submitting Task Data:', taskData)

  const response = await axios.post(url, taskData)
  return response.data // Return the created task object
}

// Mutation hook
export const usePostTask = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  return useMutation<TaskData, Error, TaskData>({
    mutationFn: postTask,

    onMutate: async newTask => {
      await queryClient.cancelQueries({ queryKey: ['tasks'] })

      // Snapshot previous tasks
      const previousTasks = queryClient.getQueryData<TaskData[]>(['tasks'])

      // Optimistically update cache
      queryClient.setQueryData<TaskData[]>(['tasks'], old =>
        old ? [...old, { ...newTask, task_uuid: 'temp-id' }] : [{ ...newTask, task_uuid: 'temp-id' }]
      )

      return { previousTasks }
    },

    onError: (error, _newTask, context) => {
      if (context?.previousTasks) {
        queryClient.setQueryData(['tasks'], context.previousTasks)
      }
      console.error('[usePostTask] Mutation Error:', error)
      showSnackbar(error.response?.data?.message || error.message, 'error')
    },

    onSuccess: data => {
      console.log('[usePostTask] Task Created Successfully:', data)

      // Update the cache with the actual task UUID
      queryClient.setQueryData<TaskData[]>(['tasks'], oldTasks =>
        oldTasks ? oldTasks.map(task => (task.task_uuid === 'temp-id' ? { ...data } : task)) : [data]
      )

      showSnackbar('Task created successfully', 'success')
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] })
    },
  })
}
