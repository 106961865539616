import { Box, Grid } from '@mui/material'
import React from 'react'
import DriveFoldersById from '../../Google/DriveFoldersById.tsx'
import DriveSubfoldersById from '../../Google/DriveSubfoldersById.tsx'
import { useGoogleFilesByFolderId } from '../../../api/google/useGoogleFilesByFolderId.ts'
import { useGoogleSubfoldersById } from '../../../api/google/useGetSubfoldersById.ts'
import GoogleDriveSection from '../../../api/google/GoogleDriveSection.tsx'
import DriveFoldersTree from '../../Google/DriveFoldersTree.tsx'
import { formatDayJsDateTime } from '../createColumns/renderDealsColumns.js'
import { useLayoutContext } from '../../../context/LayoutProvider/LayoutProvider.js'

const FileManagement = ({ newDeal, organization, dealFolders }) => {
  const { data: orgFolders, isLoading: isOrgFoldersLoading } = useGoogleFilesByFolderId(
    organization?.googleDriveFolderId
  )
  const { pinned } = useLayoutContext()
  const creation_timestamp = formatDayJsDateTime(newDeal?.creation_timestamp)

  return (
    <Grid
      container
      sx={{
        height: pinned ? 'calc(100vh - 160px)' : 'calc(100vh - 100px)',
        overflowY: 'auto',
        p: 3,
        transition: 'height 0.3s ease',
      }}
    >
      {/* <GoogleDriveSection
        organization={organization}
        parentFolderId={organization?.googleDriveFolderId}
        orgFolders={orgFolders ?? []}
        //   handleCreateGoogleFolder={handleCreateGoogleFolder}
        //   handleCreateGoogleFolders={handleCreateGoogleFolders}
      />{' '} */}
      {<DriveFoldersById folderId={organization?.googleDriveFolderId} isParentFolder={true} />}
      {orgFolders?.map(sf => {
        return (
          <Grid key={`orgFolders-${sf?.id}`} item xs={12} sm={12} md={12} lg={12} xl={12}>
            {sf?.mimeType.includes('application/vnd.google-apps.folder') && (
              <Box key={sf.id}>
                <DriveFoldersTree
                  key={sf.id}
                  creation_timestamp={creation_timestamp}
                  folderId={sf?.id}
                  folderName={sf.name}
                  level={0}
                />
              </Box>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FileManagement
