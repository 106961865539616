import React, { useRef, useState } from 'react'
import {
  IconButton,
  Popover,
  Box,
  Tooltip,
  useTheme,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import EventIcon from '@mui/icons-material/Event'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import GridViewIcon from '@mui/icons-material/GridView'
import ReceiptIcon from '@mui/icons-material/Receipt'
import GoogleSearchIcon from '../../../images/icons/google-search-icon.svg'
import CampaignIcon from '@mui/icons-material/Campaign'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useNavigate } from 'react-router-dom'
import './boardsNav.css'
import RenderBoardLink from './RenderBoardLink.tsx'
import { useChangeView } from '../../../utility/useChangeView.ts'
import InsightsIcon from '@mui/icons-material/Insights'

const BoardNavButton = () => {
  const navigate = useNavigate()
  const handleChangeView = useChangeView()
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const iconRefs = useRef([])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const boards = [
    {
      title: 'Approvals',
      route: '/approvals',
      color: 'rgba(205, 254, 194, 1)',
      icon: <PublishedWithChangesIcon />,
      disabled: false,
    },
    {
      title: 'Accounting',
      route: '/accounting',
      color: 'rgba(107, 185, 240, 1)',
      icon: <ReceiptIcon />,
      disabled: false,
    },
    {
      title: 'Contacts',
      route: '/contacts',
      color: 'rgb(255, 123, 187)',
      icon: <PersonIcon />,
      disabled: false,
    },
    {
      title: 'Creative',
      route: '/creative',
      color: 'rgb(150, 88, 238)',
      icon: <EmojiObjectsIcon />,
      disabled: false,
    },
    {
      title: 'Dashboard',
      route: '/dashboard',
      color: 'rgb(5, 190, 113)',
      icon: <GridViewIcon />,
      disabled: false,
    },
    {
      title: 'Deals',
      route: '/deals',
      color: 'rgb(251, 193, 30)',
      icon: <AttachMoneyIcon />,
      disabled: false,
    },
    {
      title: 'Leads',
      route: '/leads',
      color: 'rgb(94, 123, 167)',
      icon: <OnlinePredictionIcon />,
      disabled: false,
    },
    {
      title: 'Monthly Task Board',
      route: '/mtb',
      color: 'rgb(20, 119, 248)',
      icon: <CalendarMonthIcon />,
      disabled: false,
    },
    {
      title: 'New Client Onboards',
      route: '/nco',
      color: 'rgb(52, 211, 145)',
      icon: <AddRoadIcon />,
      disabled: false,
    },
    {
      title: 'Organizations',
      route: '/organizations',
      color: 'rgb(114, 229, 218)',
      icon: <CorporateFareIcon />,
      disabled: false,
    },
    {
      title: 'SEO',
      route: '/seo',
      color: 'rgb(238, 111, 141)',
      icon: (
        <img
          src={GoogleSearchIcon}
          style={{
            height: 28,
            width: 28,
            // filter: 'grayscale(100%)', // Default gray effect
            transition: 'filter 0.3s ease-in-out',
          }}
          className='seo-icon'
        />
      ),
      disabled: false,
    },
    {
      title: 'Team Tasks',
      route: '/teamtasks',
      color: 'rgb(247, 63, 236)',
      icon: <TaskAltIcon />,
      disabled: false,
    },
    {
      title: 'Website',
      route: '/website',
      color: 'rgb(112, 84, 240)',
      icon: <WebAssetIcon />,
      disabled: false,
    },
    {
      title: 'Campaign Checklists',
      route: '/campaignchecklists',
      color: 'rgb(255, 131, 88)',
      icon: <CampaignIcon />,
      disabled: false,
    },
    {
      title: 'Metrics',
      route: '/metrics',
      color: 'rgb(84, 107, 240)',
      icon: <InsightsIcon />,
      disabled: false,
    },
  ]

  const handleNavigate = (route: string) => {
    const boardViewMapping: Record<string, string> = {
      '/nco': 'nco',
      '/creative': 'creative',
      '/seo': 'seo',
      '/website': 'website',
      '/mtb': 'mtb',
      '/deals': 'deals',
      '/organizations': 'organizations',
      '/contacts': 'contacts',
      '/leads': 'leads',
      '/teamtasks': 'teamtasks',
      '/campaignchecklists': 'campaignchecklists',
      '/metrics': 'metrics',
    }

    const newView = boardViewMapping[route] || null

    if (newView) {
      handleChangeView({ newView })
    }

    navigate(route)
    handleClose()
  }

  return (
    <>
      <Tooltip title='Boards'>
        <IconButton onClick={handleClick} sx={{ color: 'white' }}>
          <ViewModuleIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          zIndex: 1401,
          '& .MuiPopover-paper': {
            width: '100%', // Popover width is 25% of the screen width
            maxWidth: '20vw',
            maxHeight: '70vh', // Ensures scrolling if many items
            overflowY: 'auto',
            padding: 1,
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.15)',
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List sx={{ padding: 0 }}>
            {boards.map(board => (
              <RenderBoardLink
                key={board?.title}
                title={board.title}
                route={board.route}
                onNavigate={handleNavigate}
                boardTheme={board.color}
                icon={board.icon}
                disabled={board.disabled}
              />
            ))}
          </List>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

export default BoardNavButton
