import React from 'react'
import { Badge, Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
interface CustomFilterMenuButtonProps {
  onOpenCustomFilters: (event: React.MouseEvent<HTMLElement>) => void
  count: number
  badgeColor: string | ''
  icon: React.ReactNode
}

const CustomFiltersMenu: React.FC<CustomFilterMenuButtonProps> = ({ icon, onOpenCustomFilters, count, badgeColor }) => {
  const theme = useTheme()
  return (
    <Box>
      <Tooltip title={<Typography>Custom Filters</Typography>} className='custom-filter-tooltip'>
        <Badge
          badgeContent={
            count > 0 ? (
              <Typography
                sx={{
                  fontSize: 14,
                  color: badgeColor,
                  p: 0.5,
                  borderRadius: '50%',
                  display: 'flex', // Flexbox centering
                  alignItems: 'center', // Vertically center
                  justifyContent: 'center', // Horizontally center
                  fontWeight: 'bold',
                }}
              >
                {count}
              </Typography>
            ) : (
              ''
            )
          }
          sx={{
            '.MuiBadge-badge': {
              minWidth: 16,
              height: 16,
              fontSize: '10px',
              padding: 0,
              transform: 'translate(2px, -2px)', // Moves the badge closer to the icon
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <IconButton onClick={onOpenCustomFilters} sx={{ borderRadius: '0px' }}>
            {icon ? (
              icon
            ) : (
              <>
                <TuneIcon sx={{ fontSize: '30px' }} />
              </>
            )}
          </IconButton>
        </Badge>
      </Tooltip>
    </Box>
  )
}

export default CustomFiltersMenu
