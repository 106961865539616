import { IconButton, Tooltip, Box } from '@mui/material'
import GoogleDriveSvg from '../../images/googleDriveIcon.svg'
import FolderIcon from '@mui/icons-material/Folder'

const GoogleDriveIcon = ({ title, width = 24, height = 24, isParentFolder }) => {
  return (
    <Tooltip title={title || ''}>
      <Box color='inherit' sx={{ p: 0, m: 0, position: 'relative' }}>
        {/* Folder Icon (Background) */}
        <FolderIcon sx={{ fontSize: isParentFolder ? width * 3 : width * 1.3 }} />
        {/* Google Drive Overlay Icon */}
        <Box
          component='img'
          src={GoogleDriveSvg}
          alt='google-drive-alt'
          sx={{
            width: isParentFolder ? 36 : width,
            height: isParentFolder ? 36 : height,
            position: 'absolute',
            p: 0.6,
            bottom: 6.5, // Adjust for placement inside the folder
            right: 3.5, // Adjust to position it within the FolderIcon
          }}
        />
      </Box>
    </Tooltip>
  )
}

export default GoogleDriveIcon

// import { IconButton, Badge, Drawer, Tooltip } from '@mui/material'
// import GoogleDriveSvg from '../../images/googleDriveIcon.svg'
// import FolderIcon from '@mui/icons-material/Folder'

// const GoogleDriveIcon = ({ title, width, height }) => {
//   return (
//     <Tooltip title={title || ''}>
//       <IconButton color='inherit' sx={{ p: 0, m: 0 }}>
//         <FolderIcon />
//         <img
//           key={`google-drive-key`}
//           src={GoogleDriveSvg}
//           alt={'google-drive-alt'}
//           style={{ width: width ?? 24, height: height ?? 24 }}
//         />
//       </IconButton>
//     </Tooltip>
//   )
// }

// export default GoogleDriveIcon
