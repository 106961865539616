import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'
const sortUsers = users => {
  // Sort by firstName, and if they are the same, sort by lastName
  return users.sort((a, b) => {
    const firstNameComparison = a.firstName.localeCompare(b.firstName)
    if (firstNameComparison !== 0) {
      return firstNameComparison // Sort by first name
    }
    return a.lastName.localeCompare(b.lastName) // If first names are the same, sort by last name
  })
}
/**
 * Custom hook to fetch users.
 * @returns {{
 *   users: UserList[],
 *   isUsersLoading: boolean,
 *   isUsersError: boolean
 * }}
 */

export const getUsers = async (endpoint, tableName, filters = {}) => {
  try {
    const url = `${baseUrl}${endpoint}`
    const { data } = await axios.get(url, {
      params: { table: tableName, ...filters }, // Pass filters for PK, SK, or EntityType
    })
    const botNames = ['Slackbot', 'marketing']
    const filteredData = data?.filter(user => !botNames.includes(user?.name))
    const sortedUsers = sortUsers(filteredData)
    console.log(
      'sortedUsers',
      sortedUsers?.filter(user => user?.name === 'Grobotic App')
    )
    return sortedUsers || []
    return data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useUsers = (enabled, queryKey = ['users']) => {
  const { data: users, isLoading: isUsersLoading, isError: isUsersError } = useQuery({
    queryKey: queryKey,
    queryFn: () => getUsers('/aws/users', 'users'),
    staleTime: Infinity, // Prevent the query from becoming stale
    cacheTime: 1000 * 60 * 30, // Cache for 30 minutes
    refetchOnWindowFocus: false, // Prevent refetching when window regains focus
    refetchOnReconnect: false, // Prevent refetching when the network reconnects
    enabled: enabled ?? false, // Keep this enabled for debugging
  })
  return { users, isUsersLoading, isUsersError }
}
