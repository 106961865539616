import React from 'react'
import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  useTheme,
} from '@mui/material'
import { FieldType, FieldConfig } from './types.ts' // Import types
import { fieldStyles } from '../Creative/style.js'

interface FieldComponentProps extends FieldConfig {
  name: string
  value: any
  onChange: (event: { target: { name: string; value: unknown } }) => void // Custom event shape
  errors: object
}

const FieldComponent: React.FC<FieldComponentProps> = ({
  name,
  type,
  label,
  options,
  value,
  onChange,
  required,
  placeholder,
  errors,
}) => {
  const theme = useTheme()
  const error = errors?.[name]?.value || false
  const errorText = errors?.[name]?.errorText || ''
  switch (type) {
    case FieldType.TEXT:
      return (
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          fullWidth
          error={error}
          // helperText={error ? errorText : ''}
          sx={{ ...fieldStyles(theme.palette.background.default) }} // Remove extra bottom margin
        />
      )

    case FieldType.TEXTAREA:
      return (
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={placeholder}
          multiline
          rows={3}
          fullWidth
          error={error}
          // helperText={error ? errorText : ''}
          // sx={{ mb: 0, height: '100px', padding: '0px !important' }} // Remove extra bottom margin
          sx={fieldStyles(theme.palette.background.default)}
        />
      )

    case FieldType.SELECT:
      return (
        <FormControl fullWidth sx={{ mb: 0, height: '56px' }} error={error}>
          {/* Remove extra margin */}
          <InputLabel>{label}</InputLabel>
          <Select
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            sx={{
              '& .MuiSelect-select': { cursor: 'pointer' },
              ...fieldStyles(theme.palette.background.default),
            }}
          >
            {options?.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error?.errorText}</FormHelperText>}
        </FormControl>
      )

    case FieldType.MULTISELECT:
      return (
        <FormControl fullWidth sx={{ mb: 0, height: '56px' }} error={error}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            multiple
            name={name}
            value={Array.isArray(value) ? value : []} // Ensure value is an array
            onChange={event => {
              const { value } = event.target
              onChange({
                target: {
                  name,
                  value: Array.isArray(value) ? value : [value], // Ensure multi-select stores arrays
                },
              })
            }}
            sx={{
              ...fieldStyles(theme.palette.background.default),
            }}
          >
            {options?.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error?.errorText}</FormHelperText>}
        </FormControl>
      )

    case FieldType.CHECKBOX:
      return (
        <FormControl component='fieldset' sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
          <label style={{ fontSize: '14px', fontWeight: '500', marginBottom: '4px' }}>{label}</label>
          {options ? (
            options.map(option => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={Array.isArray(value) && value.includes(option)}
                    onChange={event => {
                      const newValue = event.target.checked
                        ? [...(Array.isArray(value) ? value : []), option] // Add option if checked
                        : Array.isArray(value)
                        ? value.filter(v => v !== option)
                        : [] // Remove option if unchecked

                      onChange({ target: { name, value: newValue } })
                    }}
                    name={name}
                  />
                }
                label={option}
                sx={{
                  mb: 0,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.text.primary, // change to your desired color
                  },
                }}
              />
            ))
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(value)}
                  onChange={event => onChange({ target: { name, value: event.target.checked } })}
                  name={name}
                />
              }
              label={label}
              sx={{
                mb: 0,
                textAlign: 'left',
                ...fieldStyles(theme.palette.background.default),
                '& .MuiFormControlLabel-label': {
                  color: theme.palette.text.primary, // change to your desired color
                },
              }}
            />
          )}
        </FormControl>
      )

    case FieldType.COLOR_PICKER:
      return (
        <TextField
          type='color'
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={error}
          helperText={error ? errorText : ''}
          sx={{ marginBottom: 0, ...fieldStyles(theme.palette.background.default) }}
        />
      )

    case FieldType.NUMBER:
      return (
        <TextField
          type='number'
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          fullWidth
          sx={{ mb: 0, ...fieldStyles(theme.palette.background.default) }}
        />
      )

    case FieldType.DATE:
      return (
        <TextField
          type='date'
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={error}
          helperText={error ? errorText : ''}
          sx={{ mb: 0, ...fieldStyles(theme.palette.background.default) }}
        />
      )

    default:
      return null
  }
}

export default FieldComponent
