import React from 'react'
import { useParams } from 'react-router-dom'
import { creativeMasterStatusOptions, creativeTypeOptions, taskMasterStatusOptions } from '../DataGrid/filterArrays.js'
import { useTheme } from '@mui/material'
import TasksDataGrid from '../DataGrid/TasksDataGrid.tsx'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import renderCreativeColumns from '../DataGrid/createColumns/renderCreativeColumns.js'

const initialSortModel = [{ field: 'createdTime', sort: 'desc' }]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  products: false,
  contractedBy: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  seoTier: false,
  creativeLead: false,
  AM: false,
  DMS: false,
  SEO: false,
  MIA: false,
  QA: false,
  PM: false,
  lastModified: false,
  // websiteProgress: false,
}

const CreativePage = () => {
  const theme = useTheme()
  // const filters = {
  //   isCreative: true,
  // }
  const { dealId, orgId } = useParams()
  const filters = {
    status: ['Completed', 'Completed Internally'],
    stage: 'Deal Won',
    type: 'CSA',
    productCategories: ['Creative'],
    productSubcategories: [
      'Videography',
      'Graphic Design', // Filters deals with Search category in products
    ],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  return (
    // <TasksDataGrid
    //   filters={filters}
    //   route={'creative'}
    //   context={'creative'}
    //   initialVisibilityState={initialVisibilityState}
    //   initialSortModel={initialSortModel}
    //   statusKey={'masterStatus'}
    //   statusLabel={'Master Status'}
    //   filterOptions={creativeMasterStatusOptions}
    //   boardTheme={theme.palette.icon.creative}
    //   renderFunction={renderCreativeColumns}
    // />
    <GroboticDataGrid
      dealId={dealId}
      filters={filters}
      queryKey={['ncoPage', 'deals']}
      route={'creative'}
      context={'creative'}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'creativeStatus'}
      statusLabel={'Creative Status'}
      filterOptions={creativeMasterStatusOptions}
      boardTheme={theme.palette.icon.creative}
      renderFunction={renderCreativeColumns}
    />
  )
}
export default CreativePage

// import React, { useState, useEffect } from 'react'
// import { Box } from '@mui/material'
// import { DataGridPro, useGridApiRef, GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
// import { useUpdateTask } from '../../api/aws/useUpdateTask.js'
// import { useBatchUpdateTasks } from '../../api/aws/tasks/useBatchUpdateTasks.js'
// import { useUsers } from '../../api/aws/useUsers.js'
// import { useTheme } from '@mui/material'
// import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
// import renderCreativeColumns from './renderCreativeColumns.js'
// import { gridContainerStyles } from './style.js'
// import { useTasksTable } from '../../api/aws/tasks/useTasksTable.ts'
// import { useNavigate } from 'react-router-dom'
// import { calculateTaskDays, formatDateString, workday } from '../Tasks/taskUtil.js'
// // Import the mass update hook
// import useMassUpdateTasks, { DynamicMassUpdateData } from '../../api/aws/tasks/useMassUpdateTasks.ts'
// import TeamTasksGridFooter from '../TeamTasks/TeamTasksGridFooter.tsx'
// import TeamTasksGridToolbar from '../TeamTasks/TeamTasksGridToolbar.tsx'
// import { useFilteredTasks } from '../../api/aws/tasks/useFilteredTasks.ts'
// import CreativeGridToolbar from './CreativeGridToolbar.tsx'
// import TaskDrawer from '../TaskDrawer/TaskDrawer.js'

// export const initialSortModel = [{ field: 'createdTime', sort: 'desc' }]
// const initialVisibilityState = {
// MIA: false,
// QA: false,
// SEO: false,
// PM: false,
// dueDate: false,
// }
// export interface Task {
//   AM: string[]
//   DMS: string[]
//   PK: string
//   SK: string
//   addtlNotes: string
//   callToAction: string
//   clientToExclude: string
//   createdTime: string
//   creativeLead: string[]
//   creativeType: string
//   dateDue: string
//   deadline: string
//   dealId: string
//   displayAdTypes: string[]
//   display_accessToCreative: string[]
//   endDate: string
//   existingClient: string[]
//   id: string
//   isCreative: string
//   masterStatus: string
//   name: string
//   orgId: string
//   prevRequestLink: string
//   priority: string
//   promotingDetails: string
//   reasonForRequestChange: string
//   requestTypes: string[]
//   sameOrNewTemplate: string[]
//   socialAdTypes: string[]
//   social_accessToCreative: string[]
//   startDate: string
//   taskDays: string
//   task_uuid: string
//   timeCreated: string
//   type: string
//   vibe: string[]
//   website: string
//   // ... any additional fields such as creativeLead, PM, SEO, MIA, QA
// }

// const CreativePage: React.FC = () => {
//   const { pinned } = useLayoutContext()
//   const { showSnackbar } = useSnackbarContext()
//   const theme = useTheme()
//   const navigate = useNavigate()
//   const apiRef = useGridApiRef()
//   const { filteredTasks, isFilteredTasksLoading } = useFilteredTasks(true, 'creativeTasks', {
//     isCreative: true, // ✅ Only fetch tasks where `isCreative` is true
//   })
//   useEffect(() => {
//     if (filteredTasks) {
//       console.log('filteredTasks', filteredTasks)
//     }
//   }, [filteredTasks])

//   const { users, isUsersLoading } = useUsers(true, ['tasks', 'users'])
//   const { mutate: updateTask } = useUpdateTask()
//   const { mutate: batchUpdateTasks } = useBatchUpdateTasks()
//   const [anchorEl, setAnchorEl] = useState(null)

//   // Import and use the mass update hook
//   const { handleMassUpdate, isUpdating } = useMassUpdateTasks()

//   const [tasks, setTasks] = useState<Task[]>([])
//   const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
//   const [selectedTask, setSelectedTask] = useState<Task | null>(null)
//   const [taskDrawerOpen, setTaskDrawerOpen] = useState(false)
//   const [densityModel, setDensityModel] = useState<string>(
//     () => localStorage.getItem('creativeTasksDensityModel') ?? 'standard'
//   )
//   // massUpdateData can be set via a separate form/popover if needed.
//   const [massUpdateData, setMassUpdateData] = useState<DynamicMassUpdateData>({})

//   const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
//     const savedFilters = localStorage.getItem('creativeTasksFilterModel')
//     return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
//   })

//   const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
//     const savedVisibility = localStorage.getItem('creativeTasksColumnVisibilityModel')
//     return savedVisibility
//       ? JSON.parse(savedVisibility)
//       : {
//           MIA: false,
//           QA: false,
//           SEO: false,
//           PM: false,
//           dueDate: false,
//         }
//   })
//   useEffect(() => {
//     if (rowSelectionModel) {
//       console.log('rowSelectionModel', rowSelectionModel)
//     }
//   }, [rowSelectionModel])
//   const [sortModel, setSortModel] = useState(() => {
//     const savedSortModel = localStorage.getItem('creativeTasksSortModel')
//     return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
//   })
//   const clearSelections = () => {
//     setMassUpdateData({})
//   }
//   useEffect(() => {
//     if (filteredTasks && !isFilteredTasksLoading) {
//       setTasks(filteredTasks)
//     }
//   }, [filteredTasks, isFilteredTasksLoading])
//   useEffect(() => {
//     if (filterModel) {
//       // Save the filter model to localStorage when it changes
//       localStorage.setItem('creativeTasksFilterModel', JSON.stringify(filterModel))
//     }
//   }, [filterModel])
//   useEffect(() => {
//     if (sortModel) {
//       // Save the filter model to localStorage when it changes
//       localStorage.setItem('creativeTasksSortModel', JSON.stringify(sortModel))
//     }
//   }, [sortModel])
//   useEffect(() => {
//     if (densityModel) {
//       // Save the filter model to localStorage when it changes.0
//       localStorage.setItem('creativeTasksDensityModel', densityModel)
//     }
//   }, [densityModel])
//   useEffect(() => {
//     if (tasks?.length && rowSelectionModel.length === 1) {
//       const newTask = tasks.find(task => task.SK === rowSelectionModel[0])
//       setSelectedTask(newTask ?? null)
//       // Optionally, reset mass update data when only one row is selected.
//       setMassUpdateData({ vertical: [], type: '' })
//     } else {
//       setSelectedTask(null)
//     }
//   }, [rowSelectionModel, tasks])

//   const showTaskDrawer = (task: Task) => {
//     setSelectedTask(task)
//     setTaskDrawerOpen(true)
//   }
//   const closeTaskDrawer = () => {
//     setSelectedTask(null)
//     setTaskDrawerOpen(false)
//     navigate('/creative')
//   }
//   const handleTaskClick = (_: any, taskData: Task) => {
//     setSelectedTask(taskData)
//     showTaskDrawer(taskData)
//     navigate(`/creative/${taskData.PK}/${taskData.SK}/taskdetails`)
//   }
//   const handleMassUpdateChange = e => {
//     const { name, value } = e.target
//     setMassUpdateData(prev => ({ ...prev, [name]: value }))
//   }
//   const handleTaskUpdate = async (taskIdentifiers: Task[], updatedData: Partial<Task>) => {
//     if (!taskIdentifiers.length) return
//     // Ensure all task identifiers have required fields
//     const validTasks = taskIdentifiers.filter(task => task.PK && task.SK)
//     if (!validTasks.length) {
//       console.warn('[handleTaskUpdate] No valid tasks to update.')
//       return
//     }
//     const { PK, SK } = validTasks[0]
//     const updateData = { taskPK: PK, taskSK: SK, ...updatedData }
//     console.log('Data used for update (single update): ', updateData)

//     updateTask(
//       { taskPK: PK, taskSK: SK, ...updatedData },
//       {
//         onSuccess: () => {
//           setTasks(prev => prev.map(task => (task.PK === PK && task.SK === SK ? { ...task, ...updatedData } : task)))
//         },
//         onError: error => console.error('[handleTaskUpdate] Single update failed', error),
//       }
//     )
//   }
//   const handleClickEditStart = params => {
//     if (rowSelectionModel?.length <= 1) {
//       params.api.startCellEditMode({
//         id: params.id,
//         field: params.field,
//       })
//     } else {
//       return
//     }
//   }
//   // This function processes inline edits. If multiple rows are selected, apply changes to all.
//   const processRowUpdate = async (updatedRow: Task, oldRow: Task) => {
//     const updatedData: Partial<Task> = {}

//     // If "type" changed, recalculate taskDays and dateDue.
//     if (updatedRow.type !== oldRow.type) {
//       const newTaskDays = calculateTaskDays(updatedRow.type)
//       const newDueDate = workday(updatedRow.startDate, newTaskDays)
//       updatedData.type = updatedRow.type
//       updatedData.taskDays = newTaskDays
//       updatedData.dateDue = newDueDate ? formatDateString(newDueDate) : null
//     }
//     // Other fields...
//     if (updatedRow.name !== oldRow.name) updatedData.name = updatedRow.name
//     if (updatedRow.masterStatus !== oldRow.masterStatus) updatedData.masterStatus = updatedRow.masterStatus
//     if (updatedRow.startDate !== oldRow.startDate) updatedData.startDate = updatedRow.startDate
//     if (updatedRow.dateDue !== oldRow.dateDue) updatedData.dateDue = updatedRow.dateDue

//     // Add highPrio update check
//     // if (updatedRow.highPrio !== oldRow.highPrio) updatedData.highPrio = updatedRow.highPrio

//     // ... and so on for other fields if needed

//     if (Object.keys(updatedData).length > 0) {
//       // If multiple rows are selected, trigger mass update.
//       if (rowSelectionModel.length < 2) {
//         // Single row update logic
//         try {
//           await handleTaskUpdate([{ PK: updatedRow.PK, SK: updatedRow.SK }], updatedData, false)
//           setTasks(prev =>
//             prev.map(task =>
//               task.PK === updatedRow.PK && task.SK === updatedRow.SK ? { ...task, ...updatedData } : task
//             )
//           )
//           return { ...updatedRow, ...updatedData }
//         } catch (error) {
//           console.error('Error updating row:', error)
//           throw error
//         }
//       }
//     }
//     return updatedRow
//   }
//   const handleHighPrioChange = (taskId: string, isChecked: boolean) => {
//     // Find the task by its SK.
//     const task = tasks.find(t => t.SK === taskId)
//     if (!task) {
//       console.warn('[handleHighPrioChange] No valid task found for update.')
//       return
//     }
//     // Optimistically update the local state so the checkbox reflects the change immediately.
//     setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, highPrio: isChecked } : t)))
//     // Trigger the single update.
//     handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { highPrio: isChecked }, false)
//   }
//   const handleUserChange = (taskId: string, fieldName: string, newValue: object[]) => {
//     // newValue is an array of user objects
//     const task = tasks.find(t => t.SK === taskId)
//     if (!task) {
//       console.warn('[handleUserChange] No valid task found for update.')
//       return
//     }
//     // Optimistically update local state.
//     setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, [fieldName]: newValue } : t)))
//     // Trigger the update (using your single update logic)
//     handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { [fieldName]: newValue }, false)
//   }

//   const handleFilterModelChange = (model: GridFilterModel) => {
//     setFilterModel(model)
//   }
//   const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) =>
//     setRowSelectionModel([...rowSelectionModel])
//   const handleClearSorting = e => {
//     e.preventDefault()
//     setSortModel([...initialSortModel]) // Reset the sort model
//     localStorage.setItem('creativeTasksSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
//   }
//   const handleClearVisibility = event => {
//     event.preventDefault()
//     setColumnVisibilityModel(initialVisibilityState)
//     localStorage.setItem('creativeTasksColumnVisibilityModel', JSON.stringify(initialVisibilityState))
//   }

//   const columns: GridColDef[] = renderCreativeColumns(
//     theme,
//     users,
//     densityModel,
//     // rowSelectionModel.map(id => String(id)), // Convert to string array
//     handleMassUpdate,
//     rowSelectionModel,
//     handleTaskClick,
//     handleClickEditStart,
//     handleHighPrioChange,
//     handleUserChange
//   )

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         ...gridContainerStyles,
//         pt: pinned ? '60px' : 0.8,
//         transition: 'padding 0.3s ease',
//         height: '98%',
//       }}
//     >
//       <DataGridPro
//         initialState={{
//           sorting: { sortModel },
//           filter: { filterModel },
//           columns: { columnVisibilityModel },
//         }}
//         slots={{
//           toolbar: CreativeGridToolbar,
//           footer: TeamTasksGridFooter,
//         }}
//         slotProps={{
//           toolbar: {
//             filterModel: filterModel,
//             visibilityModel: columnVisibilityModel,
//             sortModel: sortModel,
//             setFilterModel: setFilterModel,
//             onClearSorting: handleClearSorting,
//             onClearVisibility: handleClearVisibility,
//           },
//           footer: {
//             users: users,
//             isLoading: isUsersLoading,
//             massUpdateData: massUpdateData,
//             selectionModel: rowSelectionModel,
//             rowCount: tasks?.length,
//             onChange: handleMassUpdateChange,
//             onUpdate: handleMassUpdate,
//             onClear: clearSelections,
//             tasks: tasks,
//             anchorEl,
//           },
//         }}
//         rows={tasks}
//         columns={columns}
//         rowHeight={52}
//         getRowId={row => `${row.PK}::${row.SK}`}
//         checkboxSelection
//         onCellEditStart={(params, event) => {
//           if (rowSelectionModel.length > 1) {
//             event.defaultMuiPrevented = true // Prevent editing
//           }
//         }}
//         disableRowSelectionOnClick
//         rowSelectionModel={rowSelectionModel}
//         onRowSelectionModelChange={handleRowSelectionModelChange}
//         columnVisibilityModel={columnVisibilityModel}
//         onColumnVisibilityModelChange={newModel => {
//           setColumnVisibilityModel(newModel)
//           localStorage.setItem('creativeTasksColumnVisibilityModel', JSON.stringify(newModel))
//         }}
//         sortModel={sortModel}
//         onSortModelChange={newModel => {
//           setSortModel(newModel)
//           localStorage.setItem('creativeTasksSortModel', JSON.stringify(newModel))
//         }}
//         getRowClassName={params => (params.row.highPrio ? 'high-priority-row' : '')}
//         filterModel={filterModel}
//         onFilterModelChange={handleFilterModelChange}
//         apiRef={apiRef}
//         processRowUpdate={processRowUpdate}
//         onProcessRowUpdateError={error => console.error('Row Update Error:', error)}
//         loading={isFilteredTasksLoading}
//       />
//       {selectedTask && taskDrawerOpen && (
//         <TaskDrawer taskDrawerOpen={taskDrawerOpen} closeTaskDrawer={closeTaskDrawer} selectedTask={selectedTask} />
//       )}
//     </Box>
//   )
// }

// export default CreativePage

// // import React, { useState, useEffect, useMemo } from 'react'
// // import { useParams } from 'react-router-dom'
// // import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
// // import { useProducts } from '../../api/aws/useProducts.js'
// // import { useFilteredDeals } from '../../api/aws/useFilteredDeals.js'
// // import { creativeTasks } from '../DataGrid/taskTemplates.js'
// // import { creativeStatusFilters } from '../DataGrid/filterArrays.js'
// // import { useTheme } from '@mui/material'
// // import renderCreativeColumns from './renderCreativeColumns.js'

// // const initialSortModel = [
// //   { field: 'dealStatus', sort: 'desc' },
// //   { field: 'organizations', sort: 'asc' },
// // ]

// // const initialVisibilityState = {
// //   implementationFee: false,
// //   total: false,
// //   status: false,
// //   id: false,
// //   strategy: false,
// //   vertical: false,
// //   stage: false,
// //   contractId: false,
// //   type: false,
// //   startDate: false,
// //   endDate: false,
// //   isGoogleDisplay: false,
// //   isGoogleSearch: false,
// //   isLinkedIn: false,
// //   isMetaAdvertising: false,
// //   isMicrosoftAdvertising: false,
// //   isSimplifi: false,
// //   isSnapchat: false,
// //   isSpotify: false,
// //   isTikTok: false,
// //   isYoutube: false,
// //   isSeo1: false,
// //   isSeo2: false,
// //   isSeo3: false,
// //   isSeo4: false,
// // }

// // const CreativePage = () => {
// //   const { dealId } = useParams()
// //   const theme = useTheme()
// //   const filters = {
// //     status: 'Completed',
// //     stage: 'Deal Won',
// //     type: 'CSA',
// //     productCategories: ['Creative', 'Graphic Design'],
// //     excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
// //   }
// //   // const { filteredDeals, isFilteredDealsLoading, isFilteredDealsError } = useFilteredDeals(
// //   //   true,
// //   //   ['creative', 'deals'],
// //   //   filters
// //   // )
// //   // const { products, isProductsLoading, isProductsError, productsError } = useProducts()

// //   return (
// //     <GroboticDataGrid
// //       dealId={dealId}
// //       filters={filters}
// //       queryKey={['creative', 'deals']}
// //       route={'creative'}
// //       tasks={creativeTasks}
// //       context={'creative'}
// //       initialVisibilityState={initialVisibilityState}
// //       initialSortModel={initialSortModel}
// //       statusKey={'onboardingStatus'}
// //       statusLabel={'Onboarding Status'}
// //       filterOptions={creativeStatusFilters}
// //       boardTheme={theme.palette.icon.creative}
// //       renderFn={renderCreativeColumns}
// //     />
// //   )
// // }
// // export default CreativePage
