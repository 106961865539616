import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

interface ChkListData {
  PK?: string
  SK?: string
  dealId: string
  title: string
  description: string
  status?: string
  createdTime?: string
  dueDate?: string
  assignee?: string
  priority?: string
  [key: string]: any // Allow additional properties
}

const batchPostChecklists = async (checklists: ChkListData[]): Promise<ChkListData[]> => {
  if (!checklists.length) return []

  const createUrl = `${baseUrl}/aws/checklists/batch`
  const response = await axios.post(createUrl, checklists)
  return response.data
}

export const useBatchPostChecklists = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: batchPostChecklists,

    onSuccess: data => {
      console.log('✅ Checklists Created Successfully:', data)
      showSnackbar(`Created ${data.count} checklists successfully`, 'success')
      queryClient.invalidateQueries(['checklists']) // Refresh cache
    },

    onError: error => {
      console.error('[usePostChecklistsBatch] Mutation Error:', error)
      showSnackbar('Error creating checklists', 'error')
    },
  })
}
