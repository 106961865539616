// CommentsInput.tsx
import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Grid,
  useTheme,
  Button,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useLocation, useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import './style.css'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { createMessageJsx, createSlackPayload, createTaskSlackPayload } from './createMessageJsx'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import Editor from './Editor.tsx'
import { useUser } from '../../api/aws/useUser.js'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import FallbackIcon from '../../images/Grobotic png.png'
import { useTask } from '../../api/aws/tasks/useTask.ts'
import { useUpdateTaskItems } from '../../api/aws/tasks/useUpdateTaskItems.tsx'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CommentList from './CommentList.tsx'
import { Note } from './types.ts'
// --- Type Definitions ---

/** The shape of each note object in `notes`. */

/** The shape of each user or mention object in `selectedUsers`. */
interface MentionUser {
  id: string
  name?: string
  imageUrl?: string
  firstName?: string
  lastName?: string
  slackId?: string
  slackUserId?: string
  user_uuid?: string
  // ... any other props you might have
}

/** (Optional) The shape of your task data. */
interface Organization {
  org_uuid: string
  name?: string
  notes?: Note[]
  // ... any other properties
}

/** The props for CommentsInput (if needed). */
interface CommentsInputProps {
  currentUser?: Object
  expanded?: boolean
}

const TaskMessages: React.FC<CommentsInputProps> = ({ expanded }) => {
  const { orgId } = useParams()
  const { PK, SK } = useParams()
  const { task, isTaskLoading, isTaskError } = useTask(PK, SK)
  const { addNotification, createTaskMentionPayload } = useNotify()
  const [notificationsArray, setNotificationsArray] = useState<any[]>([])
  const [newNoteContent, setNewNoteContent] = useState<string>('')
  const [notes, setNotes] = useState<Note[]>([])
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<MentionUser[]>([])
  const theme = useTheme()
  const [isFrameOpen, setIsFrameOpen] = useState<boolean>(false)
  const [selectedEmbedUrl, setSelectedEmbedUrl] = useState<string | null>(null)
  const { mutate: sendNotification } = useNotifyMessage()
  const location = useLocation()
  const { activeUser } = useActiveUser()
  const { user: currentUser, isUserLoading } = useUser(activeUser?.user?.id)
  const { mutate: updateTaskItems } = useUpdateTaskItems()
  const { showSnackbar } = useSnackbarContext()
  const { selectedFile, setSelectedFile, selectedFiles, setSelectedFiles, showPicker } = useGooglePickerById(
    currentUser?.googleRefreshToken || '',
    currentUser?.user_uuid
  )
  // Extract the owners from each note so we can load their user data
  const userIds = notes.flatMap(note => note.owner || [])
  const { users, isLoading: isUsersLoading } = useUsersById(userIds)

  // // Optionally watch your notifications array
  // useEffect(() => {
  //   if (notificationsArray.length > 0) {
  //     console.log('notificationsArray', notificationsArray)
  //   }
  // }, [notificationsArray])

  // // Debug: watch selected users
  // useEffect(() => {
  //   if (selectedUsers) {
  //     console.log('selectedUsers', selectedUsers)
  //   }
  // }, [selectedUsers])

  // Load notes from task
  useEffect(() => {
    if (task?.notes) {
      const sortedNotes = [...task.notes].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      setNotes(sortedNotes)
    }
  }, [task])

  // -------------- Handlers --------------

  const handleContentChange = (html: string) => {
    setNewNoteContent(html)
  }

  // Called whenever the mention extension detects mentions in the editor
  const handleMentionsChange = (mentions: MentionUser[]) => {
    setSelectedUsers(mentions)
  }

  // Save the editor content manually (if needed)
  const handleSaveEditorContent = (html: string) => {
    console.log('html', html)
    setNewNoteContent(html)
  }

  // Add a new note
  const handleAddTaskNote = async () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)

      // Optionally, create a block for tagged users (if desired)
      const tagsDisplay = selectedUsers.length
        ? `<div class="tagged-users" style="margin-top: 10px; display: flex; align-items: center; justify-content: flex-end; gap: 8px;">
            ${selectedUsers
              .map(
                user => `
                  <div style="display: flex; align-items: center;">
                    <img
                      src="${user.imageUrl}"
                      alt="${user.name}"
                      title="${user.name}"
                      style="width:36px; height:36px; border-radius:50%;"
                    />
                  </div>`
              )
              .join('')}
          </div>`
        : ''

      const noteContentWithTags = newNoteContent // or newNoteContent + tagsDisplay if needed

      // Build the new note.
      // If multiple files are attached, store them in an 'attachments' field.
      const newNote: Note = {
        note_id: uuidv4(),
        contents: noteContentWithTags,
        tagIds: selectedUsers.map(u => u.user_uuid || u.id),
        taggedUsers: selectedUsers.map(user => user),
        owner: activeUser?.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
        context: 'Task',
        // For backwards compatibility, if only one file is attached, you might store in single fields.
        // But for multiple files, add an attachments array.
        attachments: selectedFiles && selectedFiles.length > 0 ? selectedFiles : selectedFile ? [selectedFile] : [],
        // Optionally, clear these single fields:
        iconLink: '',
        thumbnailLink: '',
        embedUrl: '',
      }

      const updatedNotes = [newNote, ...notes]
      setNotes(updatedNotes)

      // Save to your DB...
      updateTaskItems(
        {
          updates: [
            {
              tableName: 'TASKS_TABLE',
              primaryKey: 'PK', // Use the actual PK for tasks
              sortKey: 'SK', // Include SK for uniqueness
              pkValue: task?.PK,
              skValue: task?.SK,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            setIsCreatingNote(false)
            setNewNoteContent('')
            setSelectedUsers([])
            // Optionally, clear attachments:
            setSelectedFile(null)
            setSelectedFiles([])
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
            setIsCreatingNote(false)
          },
        }
      )
    }
  }

  // Send Slack notifications
  const handleSubmit = async () => {
    console.log('handleSubmit Triggered')

    // Build a message from your mentions
    const { selectedUsers: taggedUsers, message } = createMessageJsx(newNoteContent, selectedUsers, theme)
    console.log('TAGGED USERS', taggedUsers)
    console.log('MESSAGE (NOT BEING USED)', message)

    // Slack notifications
    const slackNotificationPromises = taggedUsers.map((user: MentionUser) => {
      const slackChannel = user.slackId || user.slackUserId || user.id
      if (slackChannel) {
        const payload = createTaskSlackPayload({ ...user, slackId: slackChannel }, newNoteContent, task)
        console.log('PAYLOAD FOR SLACK: ', payload)
        return sendNotification(payload, {
          onSuccess: () => console.log(`Notification sent to ${user.firstName} ${user.lastName}`),
          onError: error => console.error(`Error sending notification to ${user.firstName}:`, error),
        })
      }
      return Promise.resolve()
    })

    try {
      await Promise.all(slackNotificationPromises)
    } catch (error) {
      console.error('Error in one or more Slack notifications:', error)
    }

    // Build local notifications data
    const notifyPayload = createTaskMentionPayload(
      taggedUsers,
      'mention',
      'success',
      'Mention',
      `You've been tagged in a task message`,
      'teamtasks',
      task?.name,
      task?.PK,
      task?.SK,
      'imageUrl not here',
      `https://grobotic.app${location?.pathname}`
    )
    console.log('NEW NOTIFICATION DATA', notifyPayload)
    console.log('NOTIFICATION PAYLOAD', notifyPayload)

    // Send batch notifications to your backend
    try {
      await addNotification(notifyPayload)
    } catch (error) {
      console.error('Error sending batch notifications:', error)
    }

    // Reset
    setNewNoteContent('')
    setSelectedUsers([])
  }

  // Combined add note + notifications
  const handleCreateAndNotify = async () => {
    try {
      await handleAddTaskNote()
      await handleSubmit()
      setNewNoteContent('')
    } catch (error) {
      console.error('Error submitting or adding note', error)
    }
  }
  // When the user clicks on a thumbnail, open the iframe preview.
  const openFrame = (embedUrl?: string) => {
    if (embedUrl) {
      setSelectedEmbedUrl(embedUrl)
      setIsFrameOpen(true)
    }
  }

  // Close the iframe preview.
  const closeFrame = () => {
    setIsFrameOpen(false)
    setSelectedEmbedUrl(null)
  }
  // Delete a note
  const handleDeleteNote = (noteId: string) => {
    const updatedNotes = notes.filter(note => note.note_id !== noteId)
    setNotes(updatedNotes)

    updateTaskItems(
      {
        updates: [
          {
            tableName: 'TASKS_TABLE',
            primaryKey: 'PK', // Use the actual PK for tasks
            sortKey: 'SK', // Include SK for uniqueness
            pkValue: task?.PK,
            skValue: task?.SK,
            fieldName: 'notes',
            newValue: updatedNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }
  const handleAttach = () => {
    if (task?.googleDriveFolderId) {
      showPicker(task?.googleDriveFolderId)
    }
  }
  const getGoogleDriveDirectLink = (thumbnailUrl: string) => {
    // Extract file ID if it's a Google Drive URL
    const match = thumbnailUrl.match(/\/d\/(.*?)\//) || thumbnailUrl.match(/id=([^&]+)/)
    return match ? `https://drive.google.com/uc?id=${match[1]}` : thumbnailUrl
  }

  // Loading states
  if (isTaskLoading || isUsersLoading) {
    return <DetailsLoading />
  }
  if (isTaskError || !task) {
    return <Typography>Error loading task details or no task data available.</Typography>
  }

  // --- Render ---
  return (
    <Grid container pt={1} sx={{ height: '100%', display: 'flex' }}>
      {/* <TaskAltIcon sx={{ fontSize: 108 }} /> */}
      <Grid item xs={6}>
        <Box
          sx={{
            mt: 1.2,
            ml: 2.5,
            mr: 2.5,
            pb: 1.2,
            justifyContent: 'center',
            textAlign: 'center',
            height: 'calc(100vh - 210px)',
            overflowY: 'auto',
          }}
        >
          {/* Render the Rich Text Editor */}
          <Editor
            onAttach={handleAttach}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            onContentChange={handleContentChange}
            onSave={handleCreateAndNotify}
            newNoteContent={newNoteContent}
            onMentionsChange={handleMentionsChange}
            height={!expanded ? '50vh' : '40vh'}
            setNewNoteContent={setNewNoteContent}
            // currentUser={currentUser || {}}
            // orgFolderId={task?.googleDriveFolderId}
          />
        </Box>
      </Grid>

      <Grid item xs={6} sx={{ maxHeight: !expanded ? '80vh' : '65vh', overflowY: 'auto', transition: 'all 0.3s ease' }}>
        <CommentList notes={notes || []} users={users} openFrame={openFrame} onDeleteNote={handleDeleteNote} />
      </Grid>

      {/* Render the iframe preview in an overlay */}
      {isFrameOpen && selectedEmbedUrl && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 1300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={closeFrame} // clicking the overlay closes the preview
        >
          <Box sx={{ width: '80%', height: '80%', position: 'relative' }}>
            <iframe src={selectedEmbedUrl} style={{ width: '100%', height: '100%' }} title='File Preview' />
            <Button
              variant='outlined'
              onClick={closeFrame}
              sx={{
                position: 'absolute',
                top: 15,
                right: 68,
                bgcolor: theme.palette.background.default,
                '&:hover': {
                  bgcolor: theme.palette.background.thumbnail,
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                }}
              >
                Close
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default TaskMessages
