import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

// export const useOrganizationById = orgId => {
//   const { data: organization, isLoading: isOrganizationLoading, isError: isOrganizationError } = useGetQueryById(
//     '/aws/organizations',
//     'organizations',
//     orgId
//   )
//   return { organization, isOrganizationLoading, isOrganizationError }
// }

export const getOrganizationById = async id => {
  if (!id) {
    return
  }
  try {
    const url = `${baseUrl}/aws/organizations/${id}`
    const response = await axios.get(url, {
      params: id ? {} : { table: 'organizations' }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else {
      return response.data || []
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return [] // Return an empty array in case of an error
  }
}

export const useOrganizationById = (orgId, queryKey = ['organization', orgId]) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKey, // Use consistent query key
    queryFn: () => getOrganizationById(orgId),
    staleTime: Infinity, // Optional: adjust based on your caching needs
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!orgId, // Only fetch if orgId is provided
  })

  // Return in the same format as the original hook
  return {
    organization: data, // Map data to `organization`
    isOrganizationLoading: isLoading,
    isOrganizationError: isError,
  }
}
