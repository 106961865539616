import React, { useEffect } from 'react'
import { Lock, LockOpen, TextFields } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import type { EditorOptions } from '@tiptap/core'
import { useCallback, useRef, useState } from 'react'
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap'
import EditorMenuControls from './EditorMenuControls.tsx'
import useExtensions from './useExtensions.ts'
import { useUsers } from '../../api/aws/useUsers.js'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import RemoveIcon from '@mui/icons-material/Remove'
import { SelectedFile } from './types.ts'

type MentionData = {
  id: string
  name: string
  imageUrl: string
}
type SelectedTaskTypes = {
  itemIndex: number
  id: string
  role: string
  title: string
  relatedDealField: string
  status: string
}
interface CurrentUser {
  id: string
  name?: string
  imageUrl?: string
  firstName?: string
  lastName?: string
  googleRefreshToken?: string
  user_uuid?: string
  // ... any other props you might have
}

export interface SelectedFiles {
  id: string
  name: string
  mimeType: string
  url: string
  embedUrl: string
  thumbnailLink?: string
  iconLink?: string
}
const exampleContent =
  '<p style="text-align: left, font-color: rgb(91, 112, 114)">Yo Dawg</p>'
type EditorProps = {
  onAttach: () => void
  selectedFile: SelectedFile | null
  selectedFiles: SelectedFiles[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<object | null>>
  setSelectedFile: React.Dispatch<React.SetStateAction<object | null>>
  onSave: (html: string, thumbnailLink: string) => void // Called when user clicks Save
  onContentChange: (html: string) => void // Called on every update
  newNoteContent: string // Pass in the current note content (for controlled usage)
  setNewNoteContent: (value: string) => void // ✅ Added missing type for state update
  onMentionsChange: (mentions: MentionData[]) => void // New: callback for updating tagged users
  height: string
  selectedTask: SelectedTaskTypes
  // currentUser: CurrentUser;
  // orgFolderId: string;
}
function fileListToImageFiles(fileList: FileList): File[] {
  // You may want to use a package like attr-accept
  // (https://www.npmjs.com/package/attr-accept) to restrict to certain file
  // types.
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || '').toLowerCase()
    return mimeType.startsWith('image/')
  })
}

export default function Editor({
  onSave,
  onContentChange,
  newNoteContent,
  setNewNoteContent,
  onMentionsChange,
  onAttach,
  selectedFile,
  setSelectedFile,
  selectedFiles,
  selectedTask,
  setSelectedFiles,
  height,
}: EditorProps) {
  const { users, isUsersLoading, isUsersError } = useUsers(true, [
    'users',
    'textEditor',
  ])
  const [editorInstance, setEditorInstance] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const theme = useTheme()
  // const { isLoaded, showPicker, selectedFile, lastOpenedFolderId } = useGooglePickerById(
  //   currentUser?.googleRefreshToken || '',
  //   currentUser?.user_uuid || ''
  // )
  // console.log('thumbnailLink: ', selectedFile?.thumbnailLink)
  // console.log('iconLink: ', selectedFile?.iconLink)
  // useEffect(() => {
  //   if(editorInstance) {
  //     console.log('editorInstance', editorInstance)
  //   }
  // }, [editorInstance])

  const handleClear = () => {
    if (editorInstance) {
      editorInstance.commands.clearContent() // Clear editor content when needed
    }
    setSelectedFile(null)
    setIsSending(false)
  }
  const extensions = useExtensions({
    placeholder: 'Add your own content here...',
    users,
  })
  const rteRef = useRef<RichTextEditorRef>(null)
  const [isEditable, setIsEditable] = useState(true)
  const [showMenuBar, setShowMenuBar] = useState(true)

  const handleNewImageFiles = useCallback(
    (files: File[], insertPosition?: number): void => {
      if (!rteRef.current?.editor) {
        return
      }

      const attributesForImageFiles = files.map((file) => ({
        src: URL.createObjectURL(file),
        alt: file.name,
      }))

      insertImages({
        images: attributesForImageFiles,
        editor: rteRef.current.editor,
        insertPosition,
      })
    },
    []
  )

  // Allow for dropping images into the editor
  const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> =
    useCallback(
      (view, event, _slice, _moved) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
          return false
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files)
        if (imageFiles.length > 0) {
          const insertPosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })?.pos

          handleNewImageFiles(imageFiles, insertPosition)

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
          event.preventDefault()
          return true
        }

        return false
      },
      [handleNewImageFiles]
    )

  // Allow for pasting images
  const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> =
    useCallback(
      (_view, event, _slice) => {
        if (!event.clipboardData) {
          return false
        }

        const pastedImageFiles = fileListToImageFiles(event.clipboardData.files)
        if (pastedImageFiles.length > 0) {
          handleNewImageFiles(pastedImageFiles)
          // Return true to mark the paste event as handled. This can for
          // instance prevent redundant copies of the same image showing up,
          // like if you right-click and copy an image from within the editor
          // (in which case it will be added to the clipboard both as a file and
          // as HTML, which Tiptap would otherwise separately parse.)
          return true
        }

        // We return false here to allow the standard paste-handler to run.
        return false
      },
      [handleNewImageFiles]
    )

  const [submittedContent, setSubmittedContent] = useState('')
  // Only render the editor when the users data is loaded successfully.
  if (isUsersLoading) {
    return <div>Loading users...</div>
  }

  if (isUsersError) {
    return <div>Error loading users</div>
  }
  return (
    <>
      <Box
        sx={{
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
          position: 'relative',
          '& .MuiCollapse-root': {
            margin: 0,
            padding: 0,
          },
          '& .MuiMenuBar-root': {
            margin: 0,
            padding: 0,
          },
          '& .MuiTiptap-FieldContainer-root': {
            backgroundColor: theme.palette.background.section,
          },
          '& .MenuSelectTextAlign-selectInput': {
            color: 'black',
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={newNoteContent}
          editable={isEditable}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
            attributes: {
              style: `height: ${height}; overflow-y: auto; text-align: left; background-color: ${theme.palette.background.section}; padding: 2px; color: ${theme.palette.text.primary}; transition: height 0.3s ease;`,
            },
          }}
          onCreate={({ editor }) => {
            setEditorInstance(editor) // Save the editor instance
          }}
          onUpdate={() => {
            const html = rteRef.current?.editor?.getHTML() || ''
            onContentChange(html)
            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')
            const mentionElements = doc.querySelectorAll(
              "[data-type='mention']"
            )
            const mentions: MentionData[] = Array.from(mentionElements).map(
              (el) => ({
                id: el.getAttribute('data-id') || '',
                name: el.getAttribute('data-label') || '',
                imageUrl: el.getAttribute('data-image-url') || '', // extract imageUrl
              })
            )
            onMentionsChange(mentions)
          }}
          renderControls={() => <EditorMenuControls />}
          RichTextFieldProps={{
            // The "outlined" variant is the default (shown here only as
            // example), but can be changed to "standard" to remove the outlined
            // field border from the editor
            variant: 'outlined',
            MenuBarProps: {
              hide: !showMenuBar,
            },
            // Below is an example of adding a toggle within the outlined field
            // for showing/hiding the editor menu bar, and a "submit" button for
            // saving/viewing the HTML content
            footer: (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    showMenuBar ? 'Hide formatting' : 'Show formatting'
                  }
                  size="small"
                  onClick={() =>
                    setShowMenuBar((currentState) => !currentState)
                  }
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />

                <MenuButton
                  value="formatting"
                  tooltipLabel={
                    isEditable
                      ? 'Prevent edits (use read-only mode)'
                      : 'Allow edits'
                  }
                  size="small"
                  onClick={() => setIsEditable((currentState) => !currentState)}
                  selected={!isEditable}
                  IconComponent={isEditable ? Lock : LockOpen}
                />

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSubmittedContent(rteRef.current?.editor?.getHTML() ?? '')
                    const html = rteRef.current?.editor?.getHTML() ?? ''
                    onSave(html) // Pass the HTML content to the parent
                    // setNewNoteContent(html);
                    handleClear()
                  }}
                >
                  Post
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onAttach()
                  }}
                >
                  Attach
                </Button>
                {selectedTask && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center', // Use alignItems for vertical alignment.
                      justifyContent: 'flex-end',
                      gap: 1,
                      width: '100%',
                    }}
                  >
                    {/* Label: This will always show */}
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: theme.palette.text.secondary,
                        flexShrink: 0, // Prevent this label from shrinking.
                        textAlign: 'right',
                      }}
                    >
                      Submitting for:
                    </Typography>
                    {/* Title: Truncated with ellipsis if too long */}
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: theme.palette.text.highlight,
                        textAlign: 'right',
                        maxWidth: '200px', // Set desired max width here.
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {selectedTask?.title || selectedTask?.name}
                    </Typography>
                  </Box>
                )}
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>

      {/* Render attached file thumbnail if a file is selected */}
      {selectedFiles && selectedFiles.length > 0 && (
        <Box
          sx={{
            mt: 0,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          {selectedFiles.map((file) => (
            <Box
              key={file.id}
              sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
            >
              <img
                src={file.iconLink}
                alt={file.name}
                style={{ width: 15, height: 'auto', borderRadius: 4 }}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>
                {file.name}
              </Typography>
              <IconButton
                onClick={() => {
                  // Remove this file from the selected files array
                  setSelectedFiles((prev) =>
                    prev.filter((f) => f.id !== file.id)
                  )
                }}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
