import React from 'react'
import { useParams } from 'react-router-dom'
import { taskMasterStatusOptions } from '../DataGrid/filterArrays.js'
import { useTheme } from '@mui/material'
import TasksDataGrid from '../DataGrid/TasksDataGrid.tsx'
import renderTeamTasksColumns from './renderTeamTasksColumns.js'

const initialSortModel = [{ field: 'createdTime', sort: 'desc' }]

const initialVisibilityState = {
  MIA: false,
  QA: false,
  creativeLead: false,
  PM: false,
  dueDate: false,
}

const TeamTasksPage = () => {
  const theme = useTheme()
  const filters = {}
  return (
    <TasksDataGrid
      filters={filters}
      route={'teamtasks'}
      context={'teamTasks'}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'masterStatus'}
      statusLabel={'Master Status'}
      filterOptions={taskMasterStatusOptions ?? []}
      boardTheme={theme.palette.icon.teamtasks}
      renderFunction={renderTeamTasksColumns}
    />
  )
}
export default TeamTasksPage

// import React, { useState, useEffect } from 'react'
// import { Box } from '@mui/material'
// import { DataGridPro, useGridApiRef, GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import { useUpdateTask } from '../../api/aws/useUpdateTask'
// import { useBatchUpdateTasks } from '../../api/aws/tasks/useBatchUpdateTasks'
// import { useUsers } from '../../api/aws/useUsers'
// import { useTheme } from '@mui/material'
// import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
// import renderTeamTasksColumns from './renderTeamTasksColumns'
// import { gridContainerStyles } from './style'
// import { useTasksTable } from '../../api/aws/tasks/useTasksTable.ts'
// import { Outlet, useNavigate, useParams } from 'react-router-dom'
// import { calculateTaskDays, formatDateString, workday } from '../Tasks/taskUtil'
// // Import the mass update hook
// import useMassUpdateTasks, { DynamicMassUpdateData } from '../../api/aws/tasks/useMassUpdateTasks.ts'
// import './style.css'
// import TeamTasksGridFooter from './TeamTasksGridFooter.tsx'
// import TeamTasksGridToolbar from './TeamTasksGridToolbar.tsx'
// import { useTasks } from '../../api/aws/useTasks.js'
// import TaskDrawer from '../TaskDrawer/TaskDrawer.js'
// import { useDrawerContext } from '../../context/DrawerProvider/DrawerProvider.js'

// export const initialSortModel = [{ field: 'createdTime', sort: 'desc' }]
// const initialVisibilityState = {
// MIA: false,
// QA: false,
// creativeLead: false,
// PM: false,
// dueDate: false,
// }
// export interface Task {
//   PK: string
//   SK: string
//   name: string
//   type: string
//   taskDays: number
//   startDate: string | null
//   dateDue: string | null
//   priority: string
//   highPrio: boolean
//   AM: any[]
//   DMS: any[]
//   masterStatus: string
//   // ... any additional fields such as creativeLead, PM, SEO, MIA, QA
// }

// const TeamTasksPage: React.FC = () => {
//   const { PK, SK } = useParams()
//   const { pinned } = useLayoutContext()
//   const { showSnackbar } = useSnackbarContext()
//   const { isDrawerOpen, openDrawer, selectedTask, setSelectedTask, closeDrawer } = useDrawerContext()
//   const theme = useTheme()
//   const navigate = useNavigate()
//   const apiRef = useGridApiRef()
//   const { tasks: tasksTable, isTasksLoading: isTasksTableLoading } = useTasks(true, ['tasksTable'])
//   const { users, isUsersLoading } = useUsers(true, ['tasks', 'users'])
//   const { mutate: updateTask } = useUpdateTask()
//   const { mutate: batchUpdateTasks } = useBatchUpdateTasks()
//   const [anchorEl, setAnchorEl] = useState(null)

//   // Import and use the mass update hook
//   const { handleMassUpdate, isUpdating } = useMassUpdateTasks()

//   const [tasks, setTasks] = useState<Task[]>([])
//   const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
//   // const [selectedTask, setSelectedTask] = useState<Task | null>(null)
//   const [taskDrawerOpen, setTaskDrawerOpen] = useState(false)
//   const [densityModel, setDensityModel] = useState<string>(
//     () => localStorage.getItem('teamTasksDensityModel') ?? 'standard'
//   )
//   // massUpdateData can be set via a separate form/popover if needed.
//   const [massUpdateData, setMassUpdateData] = useState<DynamicMassUpdateData>({})

//   const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
//     const savedFilters = localStorage.getItem('teamTasksFilterModel')
//     return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
//   })

//   const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
//     const savedVisibility = localStorage.getItem('teamTasksColumnVisibilityModel')
//     return savedVisibility
//       ? JSON.parse(savedVisibility)
//       : {
//           MIA: false,
//           QA: false,
//           creativeLead: false,
//           PM: false,
//           dueDate: false,
//         }
//   })

//   const [sortModel, setSortModel] = useState(() => {
//     const savedSortModel = localStorage.getItem('teamTasksSortModel')
//     return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
//   })
//   const clearSelections = () => {
//     setMassUpdateData({})
//   }
//   useEffect(() => {
//     if (tasksTable && !isTasksTableLoading) {
//       setTasks(tasksTable)
//       if (PK && SK) {
//         const selected = tasksTable?.find(task => task?.SK === SK)
//         setSelectedTask(selected)
//         console.log('selected', selected)
//         openDrawer()
//       }
//     }
//   }, [tasksTable, isTasksTableLoading])
//   useEffect(() => {
//     if (filterModel) {
//       // Save the filter model to localStorage when it changes
//       localStorage.setItem('teamTasksFilterModel', JSON.stringify(filterModel))
//     }
//   }, [filterModel])
//   useEffect(() => {
//     if (sortModel) {
//       // Save the filter model to localStorage when it changes
//       localStorage.setItem('teamTasksSortModel', JSON.stringify(sortModel))
//     }
//   }, [sortModel])
//   useEffect(() => {
//     if (densityModel) {
//       // Save the filter model to localStorage when it changes.0
//       localStorage.setItem('teamTasksDensityModel', densityModel)
//     }
//   }, [densityModel])
//   useEffect(() => {
//     if (tasks?.length && rowSelectionModel.length === 1) {
//       const newTask = tasks.find(task => task.SK === rowSelectionModel[0])
//       setSelectedTask(newTask ?? null)
//       // Optionally, reset mass update data when only one row is selected.
//       setMassUpdateData({ vertical: [], type: '' })
//     } else {
//       setSelectedTask(null)
//     }
//   }, [rowSelectionModel, tasks])

//   const showTaskDrawer = (task: Task) => {
//     setSelectedTask(task)
//     setTaskDrawerOpen(true)
//   }
//   const closeTaskDrawer = () => {
//     setSelectedTask(null)
//     setTaskDrawerOpen(false)
//     navigate('/teamtasks')
//   }
//   useEffect(() => {
//     if (selectedTask) {
//       console.log('selectedTask', selectedTask)
//     }
//   }, [selectedTask])
//   const handleTaskClick = (_: any, taskData: Task) => {
//     setSelectedTask(taskData)
//     showTaskDrawer(taskData)
//     navigate(`/teamtasks/${taskData.PK}/${taskData.SK}/taskdetails`)
//   }
//   const handleMassUpdateChange = e => {
//     const { name, value } = e.target
//     setMassUpdateData(prev => ({ ...prev, [name]: value }))
//   }
//   const handleTaskUpdate = async (taskIdentifiers: Task[], updatedData: Partial<Task>) => {
//     if (!taskIdentifiers.length) return
//     // Ensure all task identifiers have required fields
//     const validTasks = taskIdentifiers.filter(task => task.PK && task.SK)
//     if (!validTasks.length) {
//       console.warn('[handleTaskUpdate] No valid tasks to update.')
//       return
//     }
//     const { PK, SK } = validTasks[0]
//     const updateData = { taskPK: PK, taskSK: SK, ...updatedData }
//     console.log('Data used for update (single update): ', updateData)

//     updateTask(
//       { taskPK: PK, taskSK: SK, ...updatedData },
//       {
//         onSuccess: () => {
//           setTasks(prev => prev.map(task => (task.PK === PK && task.SK === SK ? { ...task, ...updatedData } : task)))
//         },
//         onError: error => console.error('[handleTaskUpdate] Single update failed', error),
//       }
//     )
//   }
//   const handleClickEditStart = params => {
//     if (rowSelectionModel?.length <= 1) {
//       params.api.startCellEditMode({
//         id: params.id,
//         field: params.field,
//       })
//     } else {
//       return
//     }
//   }
//   // This function processes inline edits. If multiple rows are selected, apply changes to all.
//   const processRowUpdate = async (updatedRow: Task, oldRow: Task) => {
//     const updatedData: Partial<Task> = {}

//     // If "type" changed, recalculate taskDays and dateDue.
//     if (updatedRow.type !== oldRow.type) {
//       const newTaskDays = calculateTaskDays(updatedRow.type)
//       const newDueDate = workday(updatedRow.startDate, newTaskDays)
//       updatedData.type = updatedRow.type
//       updatedData.taskDays = newTaskDays
//       updatedData.dateDue = newDueDate ? formatDateString(newDueDate) : null
//     }
//     // Other fields...
//     if (updatedRow.name !== oldRow.name) updatedData.name = updatedRow.name
//     if (updatedRow.masterStatus !== oldRow.masterStatus) updatedData.masterStatus = updatedRow.masterStatus
//     if (updatedRow.startDate !== oldRow.startDate) updatedData.startDate = updatedRow.startDate
//     if (updatedRow.dateDue !== oldRow.dateDue) updatedData.dateDue = updatedRow.dateDue

//     // Add highPrio update check
//     // if (updatedRow.highPrio !== oldRow.highPrio) updatedData.highPrio = updatedRow.highPrio

//     // ... and so on for other fields if needed

//     if (Object.keys(updatedData).length > 0) {
//       // If multiple rows are selected, trigger mass update.
//       if (rowSelectionModel.length > 1) {
//         handleMassUpdate(updatedData, rowSelectionModel as string[])
//         setTasks(prev => prev.map(task => (rowSelectionModel.includes(task.SK) ? { ...task, ...updatedData } : task)))
//         return { ...updatedRow, ...updatedData }
//       } else {
//         // Single row update logic
//         try {
//           await handleTaskUpdate([{ PK: updatedRow.PK, SK: updatedRow.SK }], updatedData, false)
//           setTasks(prev =>
//             prev.map(task =>
//               task.PK === updatedRow.PK && task.SK === updatedRow.SK ? { ...task, ...updatedData } : task
//             )
//           )
//           return { ...updatedRow, ...updatedData }
//         } catch (error) {
//           console.error('Error updating row:', error)
//           throw error
//         }
//       }
//     }
//     return updatedRow
//   }
//   const handleHighPrioChange = (taskId: string, isChecked: boolean) => {
//     // Find the task by its SK.
//     const task = tasks.find(t => t.SK === taskId)
//     if (!task) {
//       console.warn('[handleHighPrioChange] No valid task found for update.')
//       return
//     }
//     // Optimistically update the local state so the checkbox reflects the change immediately.
//     setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, highPrio: isChecked } : t)))
//     // Trigger the single update.
//     handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { highPrio: isChecked }, false)
//   }
//   const handleUserChange = (taskId: string, fieldName: string, newValue: object[]) => {
//     // newValue is an array of user objects
//     const task = tasks.find(t => t.SK === taskId)
//     if (!task) {
//       console.warn('[handleUserChange] No valid task found for update.')
//       return
//     }
//     // Optimistically update local state.
//     setTasks(prev => prev.map(t => (t.SK === taskId ? { ...t, [fieldName]: newValue } : t)))
//     // Trigger the update (using your single update logic)
//     handleTaskUpdate([{ PK: task.PK, SK: task.SK }], { [fieldName]: newValue }, false)
//   }

//   const handleFilterModelChange = (model: GridFilterModel) => {
//     setFilterModel(model)
//   }
//   const handleRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) =>
//     setRowSelectionModel([...rowSelectionModel])
//   const handleClearSorting = e => {
//     e.preventDefault()
//     setSortModel([...initialSortModel]) // Reset the sort model
//     localStorage.setItem('teamTasksSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
//   }
//   const handleClearVisibility = event => {
//     event.preventDefault()
//     setColumnVisibilityModel(initialVisibilityState)
//     localStorage.setItem('teamTasksColumnVisibilityModel', JSON.stringify(initialVisibilityState))
//   }

//   const columns: GridColDef[] = renderTeamTasksColumns(
//     theme,
//     users,
//     densityModel,
//     // rowSelectionModel.map(id => String(id)), // Convert to string array
//     handleMassUpdate,
//     rowSelectionModel,
//     handleTaskClick,
//     handleClickEditStart,
//     handleHighPrioChange,
//     handleUserChange
//   )

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         ...gridContainerStyles,
//         pt: pinned ? '60px' : 0.8,
//         transition: 'padding 0.3s ease',
//         height: '98%',
//       }}
//     >
//       <DataGridPro
//         initialState={{
//           sorting: { sortModel },
//           filter: { filterModel },
//           columns: { columnVisibilityModel },
//         }}
//         slots={{
//           toolbar: TeamTasksGridToolbar,
//           footer: TeamTasksGridFooter,
//         }}
//         slotProps={{
//           toolbar: {
//             filterModel: filterModel,
//             visibilityModel: columnVisibilityModel,
//             sortModel: sortModel,
//             setFilterModel: setFilterModel,
//             onClearSorting: handleClearSorting,
//             onClearVisibility: handleClearVisibility,
//           },
//           footer: {
//             users: users,
//             isLoading: isUsersLoading,
//             massUpdateData: massUpdateData,
//             selectionModel: rowSelectionModel,
//             rowCount: tasks?.length,
//             onChange: handleMassUpdateChange,
//             onUpdate: handleMassUpdate,
//             onClear: clearSelections,
//             tasks: tasks,
//             anchorEl,
//           },
//         }}
//         rows={tasks}
//         columns={columns}
//         rowHeight={52}
//         getRowId={row => `${row.PK}::${row.SK}`}
//         checkboxSelection
//         onCellEditStart={(params, event) => {
//           if (rowSelectionModel.length > 1) {
//             event.defaultMuiPrevented = true // Prevent editing
//           }
//         }}
//         disableRowSelectionOnClick
//         rowSelectionModel={rowSelectionModel}
//         onRowSelectionModelChange={handleRowSelectionModelChange}
//         columnVisibilityModel={columnVisibilityModel}
//         onColumnVisibilityModelChange={newModel => {
//           setColumnVisibilityModel(newModel)
//           localStorage.setItem('teamTasksColumnVisibilityModel', JSON.stringify(newModel))
//         }}
//         sortModel={sortModel}
//         onSortModelChange={newModel => {
//           setSortModel(newModel)
//           localStorage.setItem('teamTasksSortModel', JSON.stringify(newModel))
//         }}
//         getRowClassName={params => (params.row.highPrio ? 'high-priority-row' : '')}
//         filterModel={filterModel}
//         onFilterModelChange={handleFilterModelChange}
//         apiRef={apiRef}
//         processRowUpdate={processRowUpdate}
//         onProcessRowUpdateError={error => console.error('Row Update Error:', error)}
//         loading={isTasksTableLoading}
//       />
//       {selectedTask && taskDrawerOpen && (
//         <TaskDrawer taskDrawerOpen={isDrawerOpen} closeTaskDrawer={closeTaskDrawer} selectedTask={selectedTask} />
//       )}
//       <Outlet />
//     </Box>
//   )
// }

// export default TeamTasksPage
