import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createGeneralChecklist,
  createIntegrationChecklist,
  createSeoChecklist,
} from '../../../api/aws/checklists/lists.ts'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'
import { useBatchPostChecklists } from './useBatchPostChecklists.ts'

const fetchChecklists = async (dealId: string) => {
  const response = await axios.get(`${baseUrl}/aws/checklists/${dealId}`)
  console.log('RESPONSE CHECKLISTS: ', response.data)
  return response.data
}

export const useGetChecklists = deal => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['checklists', `DEAL_${deal?.deal_uuid}`], // Include the full PK if needed
    queryFn: async () => {
      if (!deal?.deal_uuid) return null
      const existingChecklists = await fetchChecklists(deal.deal_uuid)
      console.log('📝 Existing Checklists:', existingChecklists)
      // Optionally, filter to make sure the returned checklists match the expected PK:
      return existingChecklists.filter(chk => chk.PK === `DEAL_${deal.deal_uuid}`)
    },
  })
}

// // Fetch checklists from API
// const fetchChecklists = async (dealId: string) => {
//   const response = await axios.get(`${baseUrl}/aws/checklists/?dealId=${dealId}`)
//   console.log('RESPONSE CHECKLISTS: ', response)
//   return response.data
// }

// export const useGetChecklists = deal => {
//   const queryClient = useQueryClient()
//   const { mutate: createChecklistsBatch } = useBatchPostChecklists()

//   return useQuery({
//     queryKey: ['checklists', deal?.deal_uuid], // ✅ Query Key for Fetching
//     queryFn: async () => {
//       if (!deal?.deal_uuid) return null

//       // 🔍 Fetch existing checklists
//       const existingChecklists = await fetchChecklists(deal.deal_uuid)
//       console.log('📝 Existing Checklists:', existingChecklists)

//       // ✅ Store each checklist separately in cache using `PK` and `SK`
//       existingChecklists.forEach(chk => {
//         queryClient.setQueryData(['checklist', chk.PK, chk.SK], chk)
//       })

//       // 🔍 Determine missing checklists
//       const checklistTypes = [
//         createGeneralChecklist(deal),
//         deal.isSeo1 || deal.isSeo2 || deal.isSeo3 || deal.isSeo4 ? createSeoChecklist(deal) : null,
//         deal.type === 'MSA' ? createIntegrationChecklist(deal) : null,
//       ].filter(Boolean) // Remove null values

//       // ✅ Filter out already existing checklists
//       const missingChecklists = checklistTypes.filter(
//         checklist => !existingChecklists.some(c => c.type === checklist.type)
//       )

//       console.log('🔄 Missing Checklists:', missingChecklists)

//       // 🚀 Batch create only the missing checklists
//       if (missingChecklists.length > 0) {
//         createChecklistsBatch(missingChecklists)
//       }

//       // ✅ Store missing checklists separately in cache
//       missingChecklists.forEach(chk => {
//         queryClient.setQueryData(['checklist', chk.PK, chk.SK], chk)
//       })

//       // ✅ Return the final list of checklists
//       return [...existingChecklists, ...missingChecklists]
//     },
//   })
// }
