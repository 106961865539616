import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const fetchDriveMetadata = async (fileId: string, accessToken: string) => {
  const response = await axios.get(`${baseUrl}/aws/googleapi/metadata/${fileId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return response.data
}

export const useDriveMetadata = (fileId: string, accessToken: string) => {
  return useQuery({
    queryKey: ['driveMetadata', fileId],
    queryFn: () => fetchDriveMetadata(fileId, accessToken),
    enabled: !!fileId && !!accessToken, // Only fetch if both exist
    staleTime: 1000 * 60 * 10, // Cache metadata for 10 minutes
    retry: 2, // Retry on failure
  })
}
