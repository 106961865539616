import React, { useState } from 'react'
import { Autocomplete, TextField, Avatar, Box, AvatarGroup, useTheme } from '@mui/material'
import { renderUserOption } from '../Users/renderOptions'
import { useUsers } from '../../api/aws/useUsers'

const TaggingAutocomplete = ({ role, label, onChange, onBlur, selectedUsers, setSelectedUsers, isUpdating }) => {
  const { users, isUsersLoading } = useUsers(true, ['users', 'tags'])
  const theme = useTheme()

  const handleSelectUser = (event, newValue) => {
    setSelectedUsers(newValue)
    onChange(newValue) // Pass full user objects to the parent
  }

  const handleBlur = () => {
    onBlur &&
      onBlur(
        role,
        selectedUsers.map(user => user.user_uuid || user.id)
      )
  }
  return (
    <Autocomplete
      multiple
      options={users || []}
      getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`}
      value={selectedUsers}
      onChange={handleSelectUser}
      onBlur={handleBlur}
      renderOption={(props, option, state) => renderUserOption(props, option, state, theme)}
      renderTags={(tagValue, getTagProps) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
            gap: 1,
          }}
        >
          <AvatarGroup
            max={4}
            spacing='small'
            sx={{ mr: 1, ml: 1 }}
            slotProps={{
              additionalAvatar: {
                sx: {
                  height: 36,
                  width: 36,
                },
              },
            }}
          >
            {tagValue.map((option, index) => {
              const { key, onDelete, ...rest } = getTagProps({ index })
              return (
                <Box
                  key={option.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.text.primary,
                  }}
                  {...rest}
                >
                  <Avatar alt={option.real_name} src={option.image_512} sx={{ height: 36, width: 36 }} />
                </Box>
              )
            })}
          </AvatarGroup>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className='tag-autocomplete'
          label={label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
          }}
          sx={{
            color: theme.palette.text.primary,

            maxHeight: '55px',
            '& .MuiAutocomplete-inputRoot': {
              maxHeight: '60px',
              height: '60px',
              alignItems: 'center',
              backgroundColor: theme.palette.background.mentions,
              border: 'none',
            },
            '& .MuiInputBase-root': {
              alignItems: 'center',
              p: 0,
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
      )}
      fullWidth
      sx={{
        minHeight: '60px',
        '& .MuiOutlinedInput-input': {},
      }}
    />
  )
}

export default TaggingAutocomplete

// import React, { useState, useEffect } from 'react'
// import { Autocomplete, TextField, Avatar, Box, AvatarGroup, useTheme } from '@mui/material'
// import CancelIcon from '@mui/icons-material/Cancel'
// import { renderUserOption } from '../Users/renderOptions'
// import { useUsers } from '../../api/aws/useUsers'

// const TaggingAutocomplete = ({ role, label, onChange, onBlur, isUpdating }) => {
//   const { users, isUsersLoading } = useUsers(true, ['users', 'tags'])
//   const [selectedUsers, setSelectedUsers] = useState([])
//   const theme = useTheme()

//   //   useEffect(() => {
//   //     setSelectedUsers(defaultValue)
//   //   }, [defaultValue])

//   const handleSelectUser = (event, newValue) => {
//     setSelectedUsers(newValue)
//     onChange(newValue) // Trigger update in the parent component
//   }
//   const handleBlur = () => {
//     onBlur(
//       role,
//       selectedUsers?.map(user => user?.user_uuid)
//     )
//   }

//   return (
//     <Autocomplete
//       multiple
//       options={users || []}
//       getOptionLabel={option => `${option.firstName || ''} ${option.lastName || ''}`}
//       value={selectedUsers}
//       onChange={(event, newValue) => handleSelectUser(event, newValue)}
//       onBlur={handleBlur}
//       renderOption={(props, option, state) => renderUserOption(props, option, state, theme)}
//       renderTags={
//         (tagValue, getTagProps) => {
//           // tagValue.map((option, index) => {
//           return (
//             <Box
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 justifyContent: 'left',
//                 alignItems: 'center',
//                 height: '100%',
//                 gap: 1,
//               }}
//             >
//               <AvatarGroup
//                 max={4}
//                 spacing={'small'}
//                 sx={{ mr: 1, ml: 1 }}
//                 slotProps={{
//                   additionalAvatar: {
//                     sx: {
//                       height: 36,
//                       width: 36,
//                     },
//                   },
//                 }}
//               >
//                 {tagValue.map((option, index) => {
//                   const { key, onDelete, ...rest } = getTagProps({ index })
//                   return (
//                     <Box
//                       key={option.id}
//                       sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                       }}
//                       {...rest}
//                     >
//                       <Avatar
//                         key={option.id}
//                         alt={option.real_name}
//                         src={option.image_512}
//                         sx={{ height: 36, width: 36 }}
//                       />
//                     </Box>
//                   )
//                 })}
//               </AvatarGroup>
//             </Box>
//           )
//         }
//         // })
//       }
//       renderInput={params => (
//         <TextField
//           {...params}
//           label={label}
//           variant='outlined'
//           sx={{
//             maxheight: '60px', // Consistent height
//             '& .MuiAutocomplete-inputRoot': {
//               maxHeight: '60px', // Consistent input height
//               height: '60px',
//             },
//             '& .MuiInputBase-root': {
//               p: 0,
//             },
//           }}
//         />
//       )}
//       fullWidth
//       sx={{
//         minHeight: '60px', // Consistent overall height
//       }}
//     />
//   )
// }

// export default TaggingAutocomplete
