import React, { useState } from 'react'
import { Box, Select, MenuItem, Chip, Typography, useTheme } from '@mui/material'
import { renderColorByString } from '../DataGrid/renderColorByString'
const EditSelectCell = ({ params, options }) => {
  const [open, setOpen] = useState(true) // Keep dropdown open initially
  const theme = useTheme()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Select
      autoFocus
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={params.value || 'On Hold'}
      onChange={e => {
        const newValue = e.target.value
        // Update the cell value
        params.api.setEditCellValue(
          {
            id: params.id,
            field: params.field,
            value: newValue,
          },
          e
        )
        // Immediately exit edit mode so the update is applied right away
        params.api.stopCellEditMode({ id: params.id, field: params.field })
      }}
      fullWidth
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ bgcolor: option?.color ?? renderColorByString(option.value, theme) }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography>{option.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
}
export default EditSelectCell

// const EditSelectCell = ({ params, options }) => {
//   const [open, setOpen] = useState(true) // Keep dropdown open initially
//   const theme = useTheme()
//   const handleOpen = () => setOpen(true)
//   const handleClose = () => setOpen(false)

//   return (
//     <Select
//       autoFocus
//       open={open}
//       onOpen={handleOpen}
//       onClose={handleClose}
//       value={params.value || 'On Hold'}
//       onChange={e => {
//         params.api.setEditCellValue({
//           id: params.id,
//           field: params.field,
//           value: e.target.value,
//         })
//         handleClose() // Close dropdown after selection
//       }}
//       fullWidth
//     >
//       {options.map(option => (
//         <MenuItem
//           key={option.value}
//           value={option.value}
//           sx={{ bgcolor: option?.color ?? renderColorByString(option.value, theme) }}
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               gap: 1,
//             }}
//           >
//             {/* <Chip size='small' sx={{ height: 15, width: 15, bgcolor: renderColorByString(option.value, theme) }} /> */}
//             <Typography>{option.label}</Typography>
//           </Box>
//         </MenuItem>
//       ))}
//     </Select>
//   )
// }
// export default EditSelectCell
