import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { Typography, CircularProgress, Button, useTheme } from '@mui/material'
import React from 'react'

interface CreateOptimizationProps {
  onClick: () => void
  isCreating: boolean
  disabled: boolean
}

const CreateOptimizationButton: React.FC<CreateOptimizationProps> = ({ onClick, isCreating, disabled }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        height: '100%',
        backgroundColor: 'rgb(29, 106, 82)',
      }}
      startIcon={<GpsFixedIcon size={30} />}
      disabled={isCreating || disabled}
      onClick={onClick}
      name='isApproved'
      variant='contained'
    >
      {isCreating ? (
        <Typography>
          Creating...{' '}
          <CircularProgress
            size={15}
            sx={{
              color: theme.palette.text.secondary,
            }}
          />
        </Typography>
      ) : (
        <Typography>Create Optimizations</Typography>
      )}
    </Button>
  )
}

export default CreateOptimizationButton
