import * as React from 'react'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

function LinearProgressWithLabel(props) {
  const theme = useTheme()
  const barColor =
    props?.value < 30
      ? theme.palette.text.warning
      : props?.value >= 30 && props?.value <= 90
      ? theme.palette.text.highlight
      : theme.palette.text.success
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant='determinate'
          {...props}
          sx={{
            height: 10,
            borderRadius: 5,
            '& .MuiLinearProgress-bar': {
              borderRadius: 5,
              backgroundColor: barColor,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

export default function ProgressBar({ progress }) {
  const safeProgress = isNaN(progress) ? 0 : progress
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={safeProgress} />
    </Box>
  )
}
