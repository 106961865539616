import { Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { fieldStyles } from '../style'
import { normalizeDate, validDateValue } from '../../DateTime/dateUtils'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'

dayjs.extend(utc)
dayjs.extend(timezone)

const KickoffCall = ({ organization, deal, onCreateDoc }) => {
  // Initialize state for form inputs
  const [formData, setFormData] = useState({})
  const userTimezone = dayjs.tz.guess() // Dynamically detect user's timezone

  const theme = useTheme()
  useEffect(() => {
    if (organization) {
      console.log('organization', organization)
      setFormData(prev => ({
        ...prev,
        website: organization?.website,
        socialProfiles: organization?.socialProfiles,
        vertical: organization?.vertical?.[0],
      }))
    }
  }, [organization])
  useEffect(() => {
    if (deal) {
      console.log('deal', deal)
      setFormData(prev => ({
        ...prev,
        strategyLink: deal?.strategyLink,
      }))
    }
  }, [deal])
  useEffect(() => {
    if (formData) {
      console.log('formData', formData)
    }
  }, [formData])
  const handoffCallData = [
    { key: 'clientIntro', label: 'Introduce Client', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'channels', label: 'Channels', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'website', label: 'Website', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'portal', label: 'Prospect Portal', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'dependencies', label: 'Dependencies', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'vertical', label: 'Vertical', type: 'text', multiline: false, height: 50, disabled: true },
    { key: 'location', label: 'Location', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'socialProfiles', label: 'Socials', type: 'text', multiline: false, height: 50, disabled: false },
    { key: 'creatives', label: 'Creatives', type: 'text', multiline: false, height: 50, disabled: false },
    {
      key: 'strategyLink',
      label: 'Strategy Link',
      type: 'text',
      multiline: false,
      height: 50,
      disabled: false,
    },
    { key: 'propertyDetails', label: 'Property Details', type: 'text', multiline: true, height: 100, disabled: false },
    { key: 'billingDetails', label: 'Billing Details', type: 'text', multiline: true, height: 100, disabled: false },
    { key: 'extraNotes', label: 'Additional Notes', type: 'text', multiline: true, height: 100, disabled: false },
    {
      key: 'websiteReviewNotes',
      label: 'Website Review Notes',
      type: 'text',
      multiline: true,
      height: 100,
      disabled: false,
    },
  ]

  // Handle input change
  const handleChange = (key, value) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: value, // Store user input
    }))
  }

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant='h5'>Kickoff Call</Typography>
      </Grid>
      {handoffCallData.map(field => {
        const fieldValue = formData[field.key] ?? organization?.[field.key] ?? '' // Use organization data if it exists, otherwise use formData
        {
          return !field?.multiline ? (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={field.key}>
              <TextField
                type={field.type}
                label={field.label}
                fullWidth
                variant='outlined'
                value={fieldValue}
                onChange={e => handleChange(field.key, e.target.value)}
                sx={{ ...fieldStyles(theme.palette.background.default), height: field.height ?? 50 }}
                multiline={field.multiline ?? false}
                disabled={field?.disabled}
                InputProps={{
                  style: { height: `${field.height}px` }, // Ensures consistent height
                }}
              />
            </Grid>
          ) : field?.multiline ? (
            <Grid item xs={6} sm={6} md={6} key={field.key}>
              <TextField
                type={field.type}
                label={field.label}
                fullWidth
                variant='outlined'
                value={fieldValue}
                onChange={e => handleChange(field.key, e.target.value)}
                sx={{ ...fieldStyles(theme.palette.background.default, 100), height: field.height ?? 50 }}
                multiline={field.multiline ?? false}
                rows={3}
                margin='dense'
                disabled={field?.disabled}
                InputProps={{
                  style: { height: `${field.height}px` }, // Ensures consistent height
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} key={'launch-date-picker-key'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Launch Date'
                  value={organization?.launchDate ? validDateValue(organization?.launchDate) : null}
                  onChange={newValue => handleChange('launchDate', normalizeDate(newValue, userTimezone))}
                  sx={{ ...fieldStyles(theme.palette.background.default), height: 50 }}
                  slotProps={{
                    textField: { fullWidth: true },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )
        }
      })}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={'create-doc-button-key'}>
        {/* <Button variant='contained' onClick={() => onCreateDoc(formData)} disabled={organization?.handoffDocId}> */}
        <Button variant='contained' onClick={() => onCreateDoc(formData)}>
          Create Doc
        </Button>
      </Grid>
    </Grid>
  )
}

export default KickoffCall
