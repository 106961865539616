import React from 'react'
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Avatar,
  Tooltip,
  Stack,
  Grid,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import { DealDetailsStatusChip } from '../../style/styleElements'
import { detailNameStyle, stringAvatar } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUser } from '../../api/aws/useUser'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import UserImageComponent from '../Users/UserImageComponent'
import RenderVertical from '../Organizations/RenderVertical'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { determineApprovalState } from '../DealApproval/determineApprovalState'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useDeal } from '../../api/aws/useDeal'
import { useParams } from 'react-router-dom'
import DealSearch from './DealSearch'
import EntitySearch from '../EntitySearch/EntitySearch'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const RenderPandaContractStatus = () => (
  <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
    <PandaDocImageComponent size={25} />
    <Typography>Contract Status</Typography>
  </Stack>
)
const RenderVerticalTooltip = () => (
  <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
    <CorporateFareIcon size={25} sx={{ color: 'rgb(114, 229, 218)' }} />
    <Typography>Vertical</Typography>
  </Stack>
)
const RenderDealStatusTooltip = ({ isStatus }) => (
  <Stack direction={'row'} display={'flex'} alignItems={'center'} gap={1}>
    <AttachMoneyIcon size={25} sx={{ color: '#fbc11e' }} />
    <Typography>{isStatus ? 'Deal Status' : 'Deal Stage'}</Typography>
  </Stack>
)

const DealsDetailsHeader = () => {
  const theme = useTheme()
  const { dealId } = useParams()
  const { pinned } = useLayoutContext()
  const { deal: newDeal, isDealLoading, isDealError } = useDeal(dealId)
  const { organization, isOrganizationLoading } = useOrganizationById(newDeal?.organizations?.[0], [
    'dealHeader',
    'organization',
    newDeal?.organizations?.[0],
  ])
  const { user: requestedBy, isUserLoading: isRequestedByLoading } = useUser(newDeal?.requestedBy, 'requestedBy')
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.up('xs'))
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 3,
        position: 'sticky',
        zIndex: 901,
        marginLeft: 5,
        mr: 1,
        p: 0.8,
        pt: pinned ? '75px' : 0.8,
        transition: 'padding 0.3s ease',
      }}
    >
      {newDeal && !isOrganizationLoading && organization && (
        <>
          {organization && organization.photoUrl ? (
            <Tooltip title={<Typography>Organization Name</Typography>}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                <Avatar src={organization.photoUrl} sx={{ width: 50, height: 50 }} />
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    textAlign: 'left', // Align text left or right as needed
                    whiteSpace: 'nowrap', // Prevent wrapping
                    overflow: 'hidden', // Add ellipsis for long links
                    textOverflow: 'ellipsis',
                  }}
                >
                  {organization.name}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
                gap: 4,
              }}
            >
              <Avatar
                {...stringAvatar(organization.name)}
                sx={{
                  backgroundColor: 'rgb(251, 193, 30)',
                  width: 50,
                  height: 50,
                }}
                alt={organization.name}
              />
              <Typography
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'left', // Align text left or right as needed
                  whiteSpace: 'nowrap', // Prevent wrapping
                  overflow: 'hidden', // Add ellipsis for long links
                  textOverflow: 'ellipsis',
                }}
              >
                {organization.name}
              </Typography>
            </Box>
          )}
          <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {/* <DealSearch isLoading={isOrganizationLoading} /> */}
            <EntitySearch />
          </Grid>
          <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
          {isExtraLargeScreen || isLargeScreen ? (
            <Box sx={{ display: 'flex', ml: 1, gap: 1 }}>
              <Tooltip title={<RenderVerticalTooltip />}>
                <Box>
                  {organization && organization.vertical && Array.isArray(organization.vertical) ? (
                    organization.vertical.map((res, index) => (
                      <RenderVertical key={`vertical-organization.id-${index}`} vertical={res} />
                    ))
                  ) : (
                    <RenderVertical vertical={organization && organization.vertical ? organization.vertical : []} />
                  )}
                </Box>
              </Tooltip>
              <Tooltip title={<RenderPandaContractStatus />}>
                <Box>
                  <DealDetailsStatusChip
                    label={newDeal ? newDeal.status : 'No Status Assigned'}
                    theme={theme}
                    status={newDeal.status}
                  />
                </Box>
              </Tooltip>
              <Tooltip title={<RenderDealStatusTooltip isStatus={false} />}>
                <Box>
                  <DealDetailsStatusChip
                    label={newDeal ? newDeal.stage : 'No Stage Assigned'}
                    theme={theme}
                    status={newDeal.stage}
                  />
                </Box>
              </Tooltip>
              {newDeal && newDeal?.stage && newDeal?.stage === 'Deal Won' && (
                <Tooltip title={<RenderDealStatusTooltip isStatus={true} />}>
                  <Box>
                    <DealDetailsStatusChip
                      label={newDeal?.dealStatus ?? 'N/A'}
                      theme={theme}
                      status={newDeal.dealStatus}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          ) : (
            <Tooltip
              title={
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, gap: 1 }}>
                  <Tooltip placement='right' title={<RenderVerticalTooltip />}>
                    <Box>
                      {organization && organization.vertical && Array.isArray(organization.vertical) ? (
                        organization.vertical.map((res, index) => (
                          <RenderVertical key={`vertical-organization.id-${index}`} vertical={res} />
                        ))
                      ) : (
                        <RenderVertical vertical={organization && organization.vertical ? organization.vertical : []} />
                      )}
                    </Box>
                  </Tooltip>
                  <Tooltip placement='right' title={<RenderPandaContractStatus />}>
                    <Box>
                      <DealDetailsStatusChip
                        label={newDeal ? newDeal.status : 'No Status Assigned'}
                        theme={theme}
                        status={newDeal.status}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip placement='right' title={<RenderDealStatusTooltip isStatus={false} />}>
                    <Box>
                      <DealDetailsStatusChip
                        label={newDeal ? newDeal.stage : 'No Stage Assigned'}
                        theme={theme}
                        status={newDeal.stage}
                      />
                    </Box>
                  </Tooltip>
                  {newDeal && newDeal?.stage && newDeal?.stage === 'Deal Won' && (
                    <Tooltip placement='right' title={<RenderDealStatusTooltip isStatus={true} />}>
                      <Box>
                        <DealDetailsStatusChip
                          label={newDeal?.dealStatus ?? 'N/A'}
                          theme={theme}
                          status={newDeal.dealStatus}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              }
            >
              <MoreHorizIcon />
            </Tooltip>
          )}
        </>
      )}
      <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
      {newDeal && newDeal.products && Array.isArray(newDeal.products) && newDeal.products.length > 0 && (
        <>
          {newDeal && newDeal.isRequested && !newDeal.isApproved && !isRequestedByLoading && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                textAlign: 'left', // Align text left or right as needed
                whiteSpace: 'nowrap', // Prevent wrapping
                overflow: 'hidden', // Add ellipsis for long links
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              <VerifiedUserIcon sx={{ color: 'crimson' }} />
              <Typography
                sx={{
                  textAlign: 'left', // Align text left or right as needed
                  whiteSpace: 'nowrap', // Prevent wrapping
                  overflow: 'hidden', // Add ellipsis for long links
                  textOverflow: 'ellipsis',
                }}
              >
                Requested By:
              </Typography>
              <UserImageComponent imageUrl={requestedBy ? requestedBy.image_512 : ''} />
            </Box>
          )}
          {newDeal && newDeal.isApproved && !newDeal.noSignature && (
            <Box
              sx={{
                display: 'flex',
                textAlign: 'left', // Align text left or right as needed
                whiteSpace: 'nowrap', // Prevent wrapping
                overflow: 'hidden', // Add ellipsis for long links
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              <VerifiedUserIcon sx={{ color: 'green' }} />
              <Typography
                sx={{
                  textAlign: 'left', // Align text left or right as needed
                  whiteSpace: 'nowrap', // Prevent wrapping
                  overflow: 'hidden', // Add ellipsis for long links
                  textOverflow: 'ellipsis',
                }}
              >{`Approved - ${
                newDeal && newDeal.timeApproved ? formatDayjsDate(newDeal.timeApproved) : null
              }`}</Typography>
            </Box>
          )}
        </>
      )}
    </Grid>
  )
}

export default DealsDetailsHeader
