import React, { useState, useEffect } from 'react'
import { Mention } from 'primereact/mention'
import { useUsers } from '../../api/aws/useUsers'
import { Box, Button, CircularProgress, Tooltip, Typography, useTheme } from '@mui/material'
import './mention.css'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'

const createSlackPayload = (user, message) => {
  return {
    channel: user.id, // Slack user ID
    text: `You were mentioned in a message: ${message}`, // Fallback text
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Hi <@${user.id}>, you were mentioned in a message!`,
        },
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Message:* ${message}`,
        },
      },
    ],
    username: 'Grobotic',
    icon_emoji: ':bell:',
  }
}

const createMessageJsx = (value, mentions, usersList, theme) => {
  console.log('usersList: ', usersList)
  console.log('value', value, 'mentions', mentions)

  const mentionRegex = /@\w+/g // Matches all mentions starting with @
  const formatValues = value.match(mentionRegex) || [] // Extract mentions
  console.log('formatValues', formatValues)

  // Match @mentions to user usersList
  const selectedUsers = []
  formatValues.forEach(mention => {
    const match = usersList.find(res => res.nickName === mention)
    if (match) {
      selectedUsers.push({ id: match.id, name: match.name, email: match.email })
    }
  })

  console.log('Selected Users:', selectedUsers)

  // Replace mentions in the message with Typography elements
  let lastIndex = 0
  const jsxContent = []
  value.replace(/@\w+/g, (mention, index) => {
    // Add plain text before the mention
    if (index > lastIndex) {
      jsxContent.push(value.slice(lastIndex, index))
    }

    // Add the styled mention
    const user = selectedUsers.find(user => `@${user.name.replace(/ /g, '')}` === mention)
    console.log('Processing mention:', mention, 'Matched User:', user)

    if (user) {
      jsxContent.push(
        <Tooltip
          key={`mention-${index}`}
          title={
            <Box>
              <Typography>ID: {user.id}</Typography>
              <Typography>Name: {user.name}</Typography>
              <Typography>Email: {user.email}</Typography>
            </Box>
          }
        >
          <Typography
            component='span'
            style={{
              color: theme.palette.text.highlight || 'blue',
              fontWeight: 'bold',
            }}
          >
            @{user.name}
          </Typography>
        </Tooltip>
      )
    } else {
      // Keep unmatched mentions as plain text
      jsxContent.push(mention)
    }
    lastIndex = index + mention.length
  })

  // Add any remaining plain text after the last mention
  if (lastIndex < value.length) {
    jsxContent.push(value.slice(lastIndex))
  }

  console.log('jsxContent', jsxContent)
  return { selectedUsers, message: jsxContent }
}

export default function MentionsInput({ setNotificationsArray }) {
  const [value, setValue] = useState('')
  const [usersList, setUserslist] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [renderedMessage, setRenderedMessage] = useState(null)
  const [mentions, setMentions] = useState([])
  const { users, isUsersLoading, isUsersError } = useUsers()
  const { mutate: sendNotification } = useNotifyMessage()
  const theme = useTheme()

  useEffect(() => {
    if (users && Array.isArray(users) && !isUsersLoading) {
      const formattedUsers = users.map(user => ({
        id: user.user_uuid || user.id,
        name: user.name || `${user.firstName} ${user.lastName}`.trim(),
        nickName: `@${user?.name.replace(/ /g, '')}`,
        image_512: user.image_512,
        email: user?.email,
      }))
      setUserslist(formattedUsers)
    }
  }, [users])

  const onSearch = event => {
    const query = event.query.toLowerCase()
    const filteredSuggestions = usersList.filter(user => user.name.toLowerCase().includes(query))
    setSuggestions(filteredSuggestions)
  }
  const initialNotifyState = {
    userId: '',
    type: 'mention',
    severity: 'success',
    title: '',
    message: '',
  }
  const handleTag = event => {
    setValue(event.target.value)
    // console.log('event', event)
  }
  const handleSubmit = () => {
    console.log('value', value)
    console.log('mentions', mentions)
    const { selectedUsers, message } = createMessageJsx(value, mentions, usersList, theme)
    console.log('Selected Users:', selectedUsers)
    console.log('Formatted Message:', message)
    const newNotificationsData = selectedUsers?.map(user => {
      return user
        ? {
            userId: user.id,
            type: 'mention',
            severity: 'success',
            title: `You were mentioned in a message: ${value}`, // Fallback text
            message: value,
          }
        : {}
    })
    console.log('newNotificationsData', newNotificationsData)
    // Trigger a Slack notification for each tagged user
    try {
      selectedUsers.forEach(user => {
        if (user) {
          const payload = createSlackPayload(user, value)
          console.log('payload', payload)
          sendNotification(payload, {
            onSuccess: () => console.log(`Notification sent to ${user.name}`),
            onError: error => console.error(`Error sending notification to ${user.name}:`, error),
          })
        }
      })
    } catch (error) {
      console.error('error sending slack notification', error)
    }
    // Save the message for rendering
    setNotificationsArray(newNotificationsData)
    setRenderedMessage(message)
  }

  if (isUsersLoading) {
    return <CircularProgress />
  }

  const itemTemplate = suggestion => (
    <Box
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'gray',
          position: 'relative',
        },
      }}
      onClick={() => setMentions(prev => [...prev, suggestion])}
    >
      <img
        alt={suggestion.name}
        src={suggestion.image_512}
        width='32'
        height='32'
        style={{ borderRadius: '50%', marginRight: '12px' }}
      />
      <Typography variant='body1' sx={{ lineHeight: 1.5, color: theme.palette.text.primary }}>
        {suggestion.name}
      </Typography>
    </Box>
  )

  return (
    <Box
      className='mention-wrapper'
      sx={{
        '--background-color': theme.palette.background.default,
        '--text-color': theme.palette.text.primary,
        '--surface-color': theme.palette.background.paper,
        '--highlight-color': theme.palette.primary.main,
      }}
    >
      <Mention
        value={value}
        onChange={event => handleTag(event)}
        suggestions={suggestions}
        onSearch={onSearch}
        field='name'
        placeholder='Enter @ to mention people'
        rows={5}
        cols={40}
        itemTemplate={itemTemplate}
        panelStyle={{
          position: 'absolute',
          top: 'calc(100% + 20px)', // Dropdown appears just below the input
          left: 0,
          zIndex: 10,
          m: 2,
          backgroundColor: theme.palette.background.sectionContent,
          borderRadius: '4px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          '--background-color': theme.palette.background.default,
          '--text-color': theme.palette.text.primary,
          '--surface-color': theme.palette.background.paper,
          '--highlight-color': theme.palette.primary.main,
        }}
        className='p-inputtext' // Add this to apply your styles
      />

      <Button onClick={handleSubmit}>Submit</Button>
      {renderedMessage && (
        <Box sx={{ marginTop: '24px', padding: '16px', border: '1px solid #ccc', borderRadius: '8px' }}>
          <Typography variant='h6' gutterBottom>
            Submitted Message:
          </Typography>
          <Box
            sx={{
              fontFamily: 'Muli, sans-serif',
              fontSize: '14px',
              color: theme.palette.text.primary,
            }}
          >
            {renderedMessage}
          </Box>
        </Box>
      )}
    </Box>
  )
}
