import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/queryMutations'

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async ({ taskPK, taskSK, ...updatedData }) => {
      // ✅ Spread updatedData
      console.log('Updating task with:', { taskPK, taskSK, updatedData })

      const encodedPK = encodeURIComponent(taskPK)
      const encodedSK = encodeURIComponent(taskSK)

      const url = `${baseUrl}/aws/tasks/${encodedPK}/${encodedSK}`
      const response = await axios.put(url, updatedData) // ✅ Send updates directly
      return response.data
    },

    onMutate: async ({ taskPK, taskSK, taskData }) => {
      const taskKey = [`task`, taskPK, taskSK]

      await queryClient.cancelQueries(taskKey)
      const previousTask = queryClient.getQueryData(taskKey)

      queryClient.setQueryData(taskKey, old => ({
        ...old,
        ...taskData,
      }))

      return { previousTask }
    },

    onError: (err, { taskPK, taskSK }, context) => {
      const taskKey = [`task`, taskPK, taskSK]
      queryClient.setQueryData(taskKey, context.previousTask)
    },

    onSuccess: (_, { taskPK, taskSK }) => {
      queryClient.invalidateQueries(['task', taskPK, taskSK])
    },
  })

  return mutation
}
