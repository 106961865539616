export const tasksInitialState = {
  task: {
    taskName: '',
    taskType: null,
    masterStatus: null,
    startDate: null,
    dueDate: null,
    project: null,
    doneDate: null,
    projectName: '',
    AM: [],
    DMS: [],
    PM: [],
    MIA: [],
    QA: [],
    SEO: [],
    description: '',
  },
  creativeTask: {
    taskName: '',
    creativeType: null,
    creativeStatus: null,
    masterStatus: null,
    startDate: null,
    dueDate: null,
    project: null,
    doneDate: null,
    projectName: '',
    creativeLead: [],
    AM: [],
    DMS: [],
    PM: [],
    MIA: [],
    QA: [],
    SEO: [],
    description: '',
  },
}
export const newTaskState = {
  PK: '',
  SK: '',
  id: '',
  task_uuid: '',
  name: '',
  type: '',
  orgId: '',
  taskDays: '',
  dateDue: null,
  masterStatus: '',
  AM: [],
  DMS: [],
  PM: [],
  MIA: [],
  QA: [],
  SEO: [],
  creativeLead: [],
  priority: '',
  startDate: null,
  endDate: null,
  deadline: null,
  existingClient: false,
  requestTypes: [],
  createdTime: '',
  addtlNotes: '',
}

// export const creativeRequestsInitialState = {
//   creativeTask: {
//     taskName: '',
//     creativeType: null,
//     creativeStatus: null,
//     masterStatus: null,
//     startDate: null,
//     dueDate: null,
//     project: null,
//     doneDate: null,
//     projectName: '',
//     creativeLead: [],
//     AM: [],
//     DMS: [],
//     PM: [],
//     MIA: [],
//     QA: [],
//     SEO: [],
//     description: '',
//   },
// }
