import { Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
const RenderRowCount = ({ deals, visibleRowCount, rowCount, selectionModel }) => {
  const theme = useTheme()
  return (
    <Grid
      container
      sx={{
        padding: 1,
        alignItems: 'center',
        minHeight: 50,
        bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
        transition: 'height 0.3s ease',
        gap: 1,
      }}
      justifyContent='flex-start'
    >
      <Grid item>
        <Typography variant='body2' mr={2}>
          Total Rows:
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
          {selectionModel && selectionModel?.length > 0 ? selectionModel?.length : visibleRowCount}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
          /
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
          {deals?.length}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default RenderRowCount
