import React from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import PandaDocImageComponent from '../../AvatarImageComponents/PandaDocImageComponent'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

const BoardsNavigation = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  const navItems = [
    {
      label: 'Leads',
      path: 'leads',
      icon: <OnlinePredictionIcon sx={{ height: 22, width: 22, color: 'rgb(94, 123, 167)' }} />,
    },
    {
      label: 'Approvals',
      path: 'approvals',
      icon: <PublishedWithChangesIcon sx={{ height: 22, width: 22, color: 'rgba(205, 254, 194, 1)' }} />,
    },
    {
      label: 'Organizations',
      path: 'organizations',
      icon: <CorporateFareIcon sx={{ height: 22, width: 22, color: 'rgb(114, 229, 218)' }} />,
    },
    {
      label: 'Deals',
      path: 'deals',
      icon: <AttachMoneyIcon sx={{ height: 22, width: 22, color: '#fbc11e' }} />,
    },
    {
      label: 'Contacts',
      path: 'contacts',
      icon: <PersonIcon sx={{ height: 22, width: 22, color: 'rgb(255, 123, 187)' }} />,
    },
    {
      label: 'PandaDoc',
      path: 'pandadocs',
      icon: <PandaDocImageComponent size={24} />,
    },
  ]

  return (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'row', // force horizontal layout
        alignItems: 'center',
        // remove default list margin/padding if needed
        m: 0,
      }}
    >
      {navItems.map(item => {
        const isSelected = item.path === selectedPath.replace(/^\//, '')

        return (
          <ListItem
            key={item.label}
            disablePadding
            sx={{
              width: 'auto', // let items shrink or grow
              display: 'inline-flex', // keep items side by side
            }}
          >
            <ListItemButton
              sx={{
                flexDirection: 'row', // ensure icon & text are horizontal
                minHeight: 60,
                backgroundColor: isSelected ? bgSelectColor : 'transparent',
                '&:hover': {
                  backgroundColor: bgHoverColor,
                },
              }}
              onClick={() => handleNavigation(item.path)}
            >
              <Tooltip
                enterDelay={500}
                leaveDelay={0}
                title={<Typography p={1}>{item.label}</Typography>}
                placement='top'
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    // p: 1,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </Tooltip>

              {/* <ListItemText
                primary={item.label}
                sx={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
                }}
              /> */}
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default BoardsNavigation
