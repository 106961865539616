import { Box, Chip, IconButton, Typography, Tooltip, Link as MuiLink, Avatar, Badge } from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip, NcoChannelContainer, NcoStatusContainer } from '../../../style/styleElements'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import PandaDocLinkIcon from '../../Icons/PandaDockLinkIcon'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles, DisabledIconButton } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import { analyzeStrategy } from '../../Strategy/analyzeStrategy'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import GoogleDisplaySvg from '../../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../../images/icons/Google Ads.png'
import LinkedInSvg from '../../../images/icons/linkedin.svg'
import MetaLightSvg from '../../../images/icons/meta (1).svg'
import MicrosoftSvg from '../../../images/icons/bing.png'
import SimplifiSvg from '../../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../../images/icons/snapchat.svg'
import SpotifySvg from '../../../images/icons/spotify.svg'
import TikTokSvg from '../../../images/icons/tik-tok.png'
import YoutubeSvg from '../../../images/icons/youtube.svg'
import Seo1Svg from '../../../images/icons/seotier1.svg'
import Seo2Svg from '../../../images/icons/seotier2.svg'
import Seo3Svg from '../../../images/icons/seotier3.svg'
import Seo4Svg from '../../../images/icons/seo.png'
import GoogleSearchIcon from '../../../images/icons/google-search-icon.svg'
import { renderColorByString } from '../renderColorByString'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const capitalizeFirstLetter = string => {
  // console.log('capitalizeFirstLetter input:', string)
  if (typeof string !== 'string' || string.length === 0) {
    return ''
  }
  return string?.charAt(0)?.toUpperCase() + string?.slice(1) || ''
}

export const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
export const formatDayjsDateOnly = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY')
  } else {
    return null
  }
}
export const formatDayJsTime = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('hh:mm A')
  } else {
    return null
  }
}
export const formatDayJsDateTime = dateStr => {
  if (dateStr) {
    return dayjs.utc(dateStr).local().format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

export const renderDealsColumns = (organizations, users, deals, density = 'standard', navigate) => {
  const channelsArray = [
    {
      field: 'isGoogleDisplay',
      headerName: 'Google Display',
      src: GoogleDisplaySvg,
    },
    {
      field: 'isGoogleSearch',
      headerName: 'Google Search',
      src: GoogleSearchSvg,
    },
    { field: 'isLinkedIn', headerName: 'LinkedIn', src: LinkedInSvg },
    {
      field: 'isMetaAdvertising',
      headerName: 'Meta Advertising',
      src: MetaLightSvg,
    },
    {
      field: 'isMicrosoftAdvertising',
      headerName: 'Microsoft',
      src: MicrosoftSvg,
    },
    { field: 'isSimplifi', headerName: 'Simplifi', src: SimplifiSvg },
    { field: 'isSnapchat', headerName: 'Snapchat', src: SnapchatSvg },
    { field: 'isSpotify', headerName: 'Spotify', src: SpotifySvg },
    { field: 'isTikTok', headerName: 'TikTok', src: TikTokSvg },
    { field: 'isYoutube', headerName: 'Youtube', src: YoutubeSvg },
    { field: 'isSeo1', headerName: 'SEO1', src: Seo1Svg },
    { field: 'isSeo2', headerName: 'SEO2', src: Seo2Svg },
    { field: 'isSeo3', headerName: 'SEO3', src: Seo3Svg },
    { field: 'isSeo4', headerName: 'SEO4', src: Seo4Svg },
  ]

  const renderChannels = () =>
    channelsArray?.map(channel => ({
      field: channel?.field,
      headerName: channel?.headerName,
      renderHeader: () => {
        return (
          <Tooltip title={channel?.headerName}>
            <img src={channel?.src} alt={channel?.headerName} style={{ height: 24 }} />
          </Tooltip>
        )
      },
      width: 30,
      flex: 0.5,
      headerAlign: 'center',
      disableColumnMenu: true,
      valueGetter: params => {
        return params ? 'Yes' : 'No'
      },
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            cursor: 'pointer',
            bgcolor: renderColorByString(params.value),
          }}
        >
          <NcoChannelContainer
            size='small'
            type={params.value}
            sx={{
              bgcolor: renderColorByString(params.value),
              textAlign: 'center',
            }}
          >
            {params.value}
          </NcoChannelContainer>
        </Box>
      ),
    }))
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      // console.log('[customNotContainsOperator] filterItem:', filterItem)

      // Check if the filter value is defined
      if (filterItem.value === undefined || filterItem.value === null) {
        // console.log('[customNotContainsOperator] Filter value is undefined or null:', filterItem.value)
        return null
      }

      const containsFn = containsOperator.getApplyFilterFn(filterItem)
      if (!containsFn) {
        // console.log('[customNotContainsOperator] No containsFn found for filterItem:', filterItem)
        return null
      }

      // Log the cellValue during the filter function application
      return cellValue => {
        // console.log('[customNotContainsOperator] cellValue:', cellValue)
        const result = !containsFn(cellValue)
        // console.log('[customNotContainsOperator] Result of filter application:', result)
        return result
      }
    },
  }

  // Log the applied filterOperators

  const densityStyles = getDensityStyles(density)
  const stringAvatar = name => {
    // Ensure 'name' is a string, default to an empty string if not
    const validName = typeof name === 'string' ? name : ''

    const nameParts = validName ? validName.split(' ') : ['']

    if (nameParts.length < 2) {
      return {
        children: (
          <Typography
            sx={{
              fontSize: densityStyles.fontSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {nameParts[0][0]}
          </Typography>
        ),
      }
    }
    return {
      children: (
        <Typography
          sx={{
            fontSize: densityStyles.fontSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {`${nameParts[0][0]}${nameParts[1][0]}`}
        </Typography>
      ),
    }
  }
  return [
    {
      field: 'name',
      headerName: 'Deal Name',
      width: 100,
      flex: 1.75,
      headerAlign: 'left',
      valueGetter: params => params,
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const dealId = params.row.id
        const ncoStatus = params?.row?.ncoStatus
        const status = params?.row?.dealStatus

        return (
          // <Link
          //   to={`/deals/${dealId}/details`}
          //   style={{
          //     textDecoration: 'none',
          //     color: 'inherit',
          //     display: 'flex', // Use flexbox for proper alignment
          //     alignItems: 'center', // Align icon and text in the same row
          //     gap: 8, // Space between text and icon
          //     width: '100%',
          //     backgroundColor: 'transparent',
          //     overflow: 'hidden',
          //     textOverflow: 'ellipsis',
          //     whiteSpace: 'nowrap',
          //   }}
          // >
          <Box
            // to={`/deals/${dealId}/details`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex', // Use flexbox for proper alignment
              alignItems: 'center', // Align icon and text in the same row
              gap: 8, // Space between text and icon
              width: '100%',
              backgroundColor: 'transparent',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
            onClick={() => navigate(`/deals/${dealId}/details`)}
          >
            <Tooltip
              title={<Typography sx={{ backgroundColor: 'transparent' }}>{params.value}</Typography>}
              enterDelay={2000}
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '.MuiTooltip-tooltip': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  padding: 0,
                },
              }}
            >
              {/* 🔹 Name with ellipsis if it overflows */}
              <Box
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 1, // Takes up remaining space
                }}
              >
                {params?.value || ''}
              </Box>
            </Tooltip>

            {/* 🔹 Icon (Always stays visible) */}
            {ncoStatus && <AddRoadIcon sx={{ color: 'rgba(159, 90, 253, 1)', height: 20, width: 20 }} />}
          </Box>
          // </Link>
        )
      },
    },

    {
      field: 'dealStatus',
      headerName: 'Deal Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    ...renderChannels(),
    {
      field: 'seoTier',
      headerName: 'SEO',
      renderHeader: () => {
        return (
          <Box>
            <img src={GoogleSearchIcon} alt='seoColumnIcon' style={{ height: 28, width: 28 }} />
          </Box>
        )
      },
      width: 75,
      flex: 0.4,

      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.label ? params?.label : ''
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {params.value ? (
              <NcoStatusContainer
                sx={{
                  bgcolor: renderColorByString(params?.value),
                  textAlign: 'center',
                }}
              >
                {params?.value}
              </NcoStatusContainer>
            ) : (
              <CheckCircleIcon
                sx={{
                  height: '100%',
                  color: 'rgb(96, 94, 94)',
                  opacity: '0.2',
                }}
              />
            )}
          </Box>
        )
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },

    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.55,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {contractUrl ? (
              <MuiLink
                href={`${contractUrl}`}
                onClick={e => e.stopPropagation()}
                target='_blank'
                rel='noopener noreferrer'
              >
                <IconButton>
                  <PandaDocLinkIcon size={densityStyles.globeSize} />
                </IconButton>
              </MuiLink>
            ) : (
              <DisabledIconButton disabled>
                <PandaDocLinkIcon size={densityStyles.globeSize} />
              </DisabledIconButton>
            )}
          </Box>
        )
      },
    },
    {
      field: 'organizations',
      headerName: 'Organization',
      width: 100,
      flex: 1.45,
      headerAlign: 'left',
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg =
          organizations.find(org => org.org_uuid === orgId) || organizations.find(org => org.zoho_id === orgId)
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : params?.row?.organizations?.id || null

        const photoUrl = organizations ? organizations.find(org => org.id === orgId)?.photoUrl : null
        const orgName = typeof params.value === 'string' ? params.value : 'Unknown Organization'

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {/* <Link
              to={orgId ? `/organizations/${orgId}/details` : '#'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            > */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                height: '100%',
                width: '100%',
                fontWeight: 'bold',
                cursor: 'pointer',
                gap: 1,
              }}
              onClick={() => navigate(orgId ? `/organizations/${orgId}/details` : '#')}
            >
              {photoUrl ? (
                <Avatar
                  src={photoUrl}
                  sx={{
                    width: densityStyles.avatarSize,
                    height: densityStyles.avatarSize,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  alt={orgName}
                />
              ) : (
                <Avatar {...stringAvatar(orgName)} sx={{ backgroundColor: 'rgb(114, 229, 218)' }} />
              )}

              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {orgName}
              </Typography>
            </Box>
            {/* </Link> */}
          </Box>
        )
      },
    },
    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        if (!params || !Array.isArray(params)) return ''

        const contractedById = params?.[0] // Ensure we're accessing the array
        const contractedBy = organizations?.find(org => org.id === contractedById)

        return contractedBy?.name ?? '' // Return an empty string if undefined
      },
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const org = organizations ? organizations.find(org => org.id === orgId) : null
        const contractedById = org && Array.isArray(org.contractedBy) ? org.contractedBy[0] : null

        return (
          <>
            {params && params.value ? (
              <RenderContractedByColumn
                key={`${contractedById}`}
                orgName={params?.value ?? ''}
                orgId={contractedById}
                densityStyles={densityStyles}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              >
                <Typography sx={{ color: 'rgb(60, 57, 57)', fontSize: 14 }}>No Organization Assigned</Typography>
              </Box>
            )}
          </>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both are empty
        if (!a) return 1 // Treat empty as larger, so a (empty) goes after b (non-empty)
        if (!b) return -1 // Treat empty as larger, so b (empty) goes after a (non-empty)
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },
    {
      field: 'status',
      headerName: 'Document Status',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStatusChip
              size='small'
              type={params?.value || ''}
              label={capitalizeFirstLetter(params?.value || '')}
            />
          </Box>
        )
      },
    },

    {
      field: 'relatedStrategy',
      headerName: 'Strategy',
      width: 100,
      flex: 0.55,
      headerAlign: 'left',
      sortable: true,
      valueGetter: params => {
        return params?.progress ?? ''
      },
      renderCell: params => {
        const status = params.row.status
        const deal = params && params.row ? params.row : null
        const dealId = deal.id
        const orgId = deal.organizations && deal.organizations.length > 0 ? deal.organizations[0] : null
        const selectedOrganization = organizations.find(org => org.id === orgId)
        return (
          <Link
            to={`/deals/${dealId}/strategy`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <StrategyProgress dealId={dealId} size={densityStyles.globeSize} strategy={params?.row?.relatedStrategy} />
          </Link>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },
    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsDate(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },
    {
      field: 'timeCompleted',
      headerName: 'Date Won',
      width: 100,
      flex: 0.65,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        const isDealWon = params?.row?.stage === 'Deal Won'
        const isCompletedInternally = params?.row?.status === 'Completed Internally'
        const isWon = isDealWon || isCompletedInternally
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {isWon && params?.value ? formatDayjsDate(params?.value) : '—'}
            {/* {params?.value ? formatDayjsDateOnly(params?.value) : '—'} */}
          </Box>
        )
      },
    },

    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        // const userId = params && params[0] ? params[0] : null
        const userId = params && Array.isArray(params) ? params : [params]
        const user = users.find(u => u.id === userId?.[0])
        return user && user.name ? user.name : 'No User Assigned'
      },
      renderCell: params => {
        const userId =
          params && params?.row?.owner && Array.isArray(params?.row?.owner)
            ? params?.row?.owner?.[0]
            : params?.row?.owner
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                ?
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params?.value}
          </Box>
        )
      },
    },
    {
      field: 'creation_timestamp',
      headerName: 'Created Time',
      width: 100,
      flex: 1.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? params : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params?.value ? formatDayjsDate(params?.value) : '—'}
          </Box>
        )
      },
    },
  ]
}
export default renderDealsColumns
