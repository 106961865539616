import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

export const useOptsByOrgId = (orgId: string | null) => {
  return useQuery({
    queryKey: ['optsByOrgId', orgId],
    queryFn: async () => {
      if (!orgId) return []
      const { data } = await axios.get(`${baseUrl}/aws/optimizations/${orgId}`)
      return data || []
    },
    enabled: !!orgId, // ✅ Only run if orgId exists
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    retry: 2, // Retry twice on failure
  })
}
