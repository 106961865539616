import React, { useState, useEffect } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-pro'
import { useProducts } from '../../api/aws/useProducts'
import { gridContainerStyles } from '../Contacts/style'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const ProductsToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  )
}

const ProductsGrid = () => {
  const { pinned } = useLayoutContext()
  const { products, isProductsLoading, isProductsError } = useProducts(true)
  console.log(products)
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.7,
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'category_name',
      headerName: 'Category',
      flex: 0.7,
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory',
      flex: 0.7,
      headerAlign: 'center',
      sortable: true,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 0.7,
      headerAlign: 'center',
      sortable: true,
    },
  ]
  if (isProductsLoading) {
    return <div>loading....</div>
  }

  return (
    <Box
      sx={{
        position: 'relative',
        ...gridContainerStyles,
        pt: pinned ? '60px' : 0.8,
        transition: 'all 0.3s ease', // or .5s, etc.
        height: '98%',
        // height: pinned ? 'calc(100vh - 80px)' : 'calc(100vh - 20px)',
      }}
    >
      <DataGridPro
        rows={products}
        columns={columns}
        getRowId={row => {
          return row?.products_uuid || row?.id
        }}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: ProductsToolbar }}
        loading={isProductsLoading}
      />
    </Box>
  )
}

export default ProductsGrid
