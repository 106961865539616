import {
  generalTasksList,
  premiumWebsiteList,
  newWebsiteList,
  brandingGuideTaskList,
  fullBrandList,
  photoVideoTravelTaskList,
  photoVideoLocalTaskList,
  logoTaskList,
  collateralTaskList,
  shootEquipmentChecklist,
  createCampaignTaskList,
  amTaskList,
  dmsTaskList,
} from './taskListMaps.ts'

interface ChecklistItem {
  id: string
  title: string
  status: 'Done' | 'In Progress' | 'N/A'
  relatedDealField?: string // Optional mapping to deal fields
  key?: string
  amProgress?: number
}

interface ChecklistSection {
  title: string
  items: ChecklistItem[]
  completedCount?: number
  totalCount?: number
}

interface Checklist {
  PK: string
  SK: string
  type: 'Onboarding' | 'Integrations' | 'SEO' | 'Equipment' | 'Website Build'
  role?: string
  sections: ChecklistSection[] // Now uses sections instead of a flat array
  createdAt: string
  updatedAt?: string
}
export const calculateChecklistProgress = (sections: ChecklistSection[]) => {
  return sections.map(section => ({
    ...section,
    completedCount: section.items.filter(item => item.status === 'Done').length,
    totalCount: section.items.length,
  }))
}
interface TaskListSection {
  title: string
  items: TaskListItem[]
  completedCount?: number
  totalCount?: number
}
interface TaskListItem {
  amProgress?: number | 0
  id: string
  title: string
  status: 'Done' | 'In Progress' | 'N/A'
  relatedDealField?: string // Optional mapping to deal fields
  relatedOrgField?: string
  key?: string
}
interface TaskList {
  PK: string
  SK: string
  type: 'Onboarding' | 'Integrations' | 'SEO' | 'Equipment' | 'Website Build' | 'AM' | 'DMS'
  role?: string
  sections: TaskListSection[] // Now uses sections instead of a flat array
  createdAt: string
  updatedAt?: string
}

export const createArrayList = (list, progressKey) => {
  return list.map((name, index, array) => {
    const key = name
      .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
      .replace(/\s+(.)/g, (_, char) => char.toUpperCase()) // Convert to camelCase
      .replace(/\s/g, '') // Remove spaces
      .replace(/^(.)/, (_, char) => char.toLowerCase()) // Ensure first character is lowercase

    return {
      key,
      name,
      role: 'PM',
      dependencies:
        index === 0
          ? []
          : [
              array[index - 1] // Ensure dependencies use the previous key
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .replace(/\s+(.)/g, (_, char) => char.toUpperCase())
                .replace(/\s/g, '')
                .replace(/^(.)/, (_, char) => char.toLowerCase()),
            ],
      id: index + 1,
      relatedDealField: progressKey || 'creativeProgress',
    }
  })
}

export const createIntegrationChecklist = deal => {
  const checklist: Checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_INTEGRATION_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`,
    type: 'Integrations',
    sections: [], // Now using sections instead of a flat list
    createdAt: new Date().toISOString(),
  }
  const gtmGa4Channels =
    deal?.isYoutube ||
    deal?.isGoogleSearch ||
    deal?.isMetaAdvertising ||
    deal?.isLinkedIn ||
    deal?.isMicrosoftAdvertising ||
    deal?.isSimplifi ||
    deal?.isSnapchat ||
    deal?.isSpotify ||
    deal?.isTikTok ||
    deal?.isSeo1 ||
    deal?.isSeo2 ||
    deal?.isSeo3 ||
    deal?.isSeo4

  // GTM SECTION
  if (gtmGa4Channels) {
    checklist.sections.push({
      title: 'Google Tag Manager (GTM)',
      items: [
        { id: 'GTM-1', title: 'Verify Account Level Access As admin', status: 'In Progress', relatedDealField: 'GTM' },
        { id: 'GTM-2', title: 'Add Platform Tags', status: 'In Progress', relatedDealField: 'GTM' },
        { id: 'GTM-3', title: 'Add Event Tags', status: 'In Progress', relatedDealField: 'GTM' },
        {
          id: 'GTM-4',
          title: 'Confirm Tags are firing in preview mode',
          status: 'In Progress',
          relatedDealField: 'GTM',
        },
        {
          id: 'GTM-5',
          title: 'Confirm events are populating in GA4 Debug mode',
          status: 'In Progress',
          relatedDealField: 'GTM',
        },
        { id: 'GTM-6', title: 'Create Google Ads Account', status: 'In Progress', relatedDealField: 'GTM' },
      ],
    })
  }

  // GA4 SECTION
  if (gtmGa4Channels) {
    checklist.sections.push({
      title: 'Google Analytics 4 (GA4)',
      items: [
        { id: 'GA4-1', title: 'Create Gro Managed GA4', status: 'In Progress', relatedDealField: 'GA4' },
        { id: 'GA4-2', title: 'Configure domains', status: 'In Progress', relatedDealField: 'GA4' },
        {
          id: 'GA4-3',
          title: "Configure Unwanted Referrals 'Cross-Domain'",
          status: 'In Progress',
          relatedDealField: 'GA4',
        },
        { id: 'GA4-4', title: 'Enable Google Signals', status: 'In Progress', relatedDealField: 'GA4' },
        {
          id: 'GA4-5',
          title: 'Set Both Data retention options to 14 Months',
          status: 'In Progress',
          relatedDealField: 'GA4',
        },
        { id: 'GA4-6', title: 'Enable Key Event', status: 'In Progress', relatedDealField: 'GA4' },
        {
          id: 'GA4-7',
          title: "Update Key Event Counting Method to 'once per session'",
          status: 'In Progress',
          relatedDealField: 'GA4',
        },
      ],
    })
  }

  // META SECTION
  if (deal.isMetaAdvertising) {
    checklist.sections.push({
      title: 'Meta (Facebook/Instagram)',
      items: [
        { id: 'META-1', title: 'Create account', status: 'In Progress', relatedDealField: 'fbPage' },
        { id: 'META-2', title: 'Assign GRO users', status: 'In Progress', relatedDealField: 'fbPage' },
        { id: 'META-3', title: 'Configure billing', status: 'In Progress', relatedDealField: 'fbPage' },
        {
          id: 'META-4',
          title: 'Create dataset in GRO Overflow business manager',
          status: 'In Progress',
          relatedDealField: 'fbPage',
        },
        {
          id: 'META-5',
          title: 'Share dataset to main business manager',
          status: 'In Progress',
          relatedDealField: 'fbPage',
        },
        { id: 'META-6', title: 'Link dataset to ad account', status: 'In Progress', relatedDealField: 'fbPage' },
        {
          id: 'META-7',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'fbPage',
        },
        { id: 'META-8', title: 'Test custom events', status: 'In Progress', relatedDealField: 'fbPage' },
        {
          id: 'META-9',
          title: 'Verify events in GRO Overflow > Data Sources',
          status: 'In Progress',
          relatedDealField: 'fbPage',
        },
      ],
    })
  }

  // ✅ TIKTOK SECTION
  if (deal.isTikTok) {
    checklist.sections.push({
      title: 'TikTok',
      items: [
        { id: 'TIKTOK-1', title: 'Create ad account', status: 'In Progress', relatedDealField: 'tiktok' },
        { id: 'TIKTOK-2', title: 'Assign GRO users', status: 'In Progress', relatedDealField: 'tiktok' },
        { id: 'TIKTOK-3', title: 'Configure billing', status: 'In Progress', relatedDealField: 'tiktok' },
        {
          id: 'TIKTOK-4',
          title: 'Create pixel',
          status: 'In Progress',
          relatedDealField: 'tiktok',
        },
        {
          id: 'TIKTOK-5',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'tiktok',
        },
        { id: 'TIKTOK-6', title: 'Test events in TikTok Ads UI', status: 'In Progress', relatedDealField: 'tiktok' },
      ],
    })
  }
  // ✅ Simplifi SECTION
  if (deal.isSimplifi) {
    checklist.sections.push({
      title: 'Simplifi',
      items: [
        { id: 'SIMPLIFI-1', title: 'Create ad account', status: 'In Progress', relatedDealField: 'Simplifi' },
        { id: 'SIMPLIFI-2', title: 'Create Retargeting tags', status: 'In Progress', relatedDealField: 'Simplifi' },
        {
          id: 'SIMPLIFI-3',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'Simplifi',
        },
        {
          id: 'SIMPLIFI-4',
          title: 'Create audiences',
          status: 'In Progress',
          relatedDealField: 'Simplifi',
        },
        { id: 'SIMPLIFI-5', title: 'Link audiences to tags', status: 'In Progress', relatedDealField: 'Simplifi' },
      ],
    })
  }
  // ✅ Bing SECTION
  if (deal.isMicrosoftAdvertising) {
    checklist.sections.push({
      title: 'Bing',
      items: [
        { id: 'BING-1', title: 'Create Bing Ads Account', status: 'In Progress', relatedDealField: 'Bing' },
        { id: 'BING-2', title: 'Configure payment for account', status: 'In Progress', relatedDealField: 'Bing' },
        {
          id: 'BING-3',
          title: 'Create Bing Universal Event Tag (UET)',
          status: 'In Progress',
          relatedDealField: 'Bing',
        },
        {
          id: 'BING-4',
          title: 'Create the conversion events in platform',
          status: 'In Progress',
          relatedDealField: 'Bing',
        },
        {
          id: 'BING-5',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'Bing',
        },
        {
          id: 'BING-6',
          title: 'Test that UET and conversion tags are firing',
          status: 'In Progress',
          relatedDealField: 'Bing',
        },
      ],
    })
  }
  // ✅ LinkedIn SECTION
  if (deal.isLinkedIn) {
    checklist.sections.push({
      title: 'LinkedIn',
      items: [
        { id: 'LINKEDIN-1', title: 'Create LinkedIn Ads Account', status: 'In Progress', relatedDealField: 'LinkedIn' },
        { id: 'LINKEDIN-2', title: 'Create LinkedIn Pixel', status: 'In Progress', relatedDealField: 'LinkedIn' },
        {
          id: 'LINKEDIN-3',
          title: 'Confirm Advertiser page permissions',
          status: 'In Progress',
          relatedDealField: 'LinkedIn',
        },
        {
          id: 'LINKEDIN-4',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'LinkedIn',
        },
      ],
    })
  }
  // ✅ SnapChat SECTION
  if (deal.isSnapchat) {
    checklist.sections.push({
      title: 'Snapchat',
      items: [
        { id: 'SNAPCHAT=1', title: 'Create Snapchat Ads Account', status: 'In Progress', relatedDealField: 'Snapchat' },
        { id: 'SNAPCHAT=2', title: 'Create Snap Pixel', status: 'In Progress', relatedDealField: 'Snapchat' },
        {
          id: 'SNAPCHAT=3',
          title: 'Create appropriate Tags and Triggers within GTM',
          status: 'In Progress',
          relatedDealField: 'Snapchat',
        },
      ],
    })
  }
  // Assign section and item indices:
  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))
  return checklist
}
export const createGeneralChecklist = deal => {
  const checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_GENERAL_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK
    type: 'General',
    sections: [
      {
        title: 'General Tasks',
        items: generalTasksList.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          assignedUser: null,
          dependencies: task.dependencies, // Maintain dependencies
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
export const determineCreativeList = deal => {
  let taskList = []

  if (
    deal?.isDroneVideoAdd ||
    deal?.isDroneShootAdd ||
    deal?.isVideoEdit ||
    deal?.isVideoShoot ||
    deal?.isPhotoEdit ||
    deal?.isPhotoShoot ||
    deal?.isLifestyleShoot
  ) {
    taskList = [...photoVideoTravelTaskList]
  }
  if (deal?.isLogoCreation || deal?.isAddtlLogoRevisions) {
    taskList = [...taskList, ...logoTaskList]
  }
  if (deal?.isFullBrandBuild) {
    taskList = [...taskList, ...fullBrandList]
  }
  if (deal?.isMarketingCollateral || deal?.isSystemsKit) {
    taskList = [...taskList, ...collateralTaskList]
  }
  if (
    deal?.isDesignMaterials ||
    deal?.isBrandingGuide ||
    deal?.isPropertyNaming ||
    deal?.isRevisions ||
    deal?.isGraphicDesignGeneral ||
    deal?.isTravelLodging ||
    deal?.isStagingEquipment ||
    deal?.isProjectedCreativeBudget ||
    deal?.isTalentModels
  ) {
    taskList = [...taskList, ...brandingGuideTaskList]
  }

  return taskList
}

export const createGeneralCreativeTaskList = (deal, taskList) => {
  const checklist = {
    PK: `DEAL_${deal?.deal_uuid}`,
    SK: `CREATIVE_TASKLIST_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK

    type: 'General Tasks',
    sections: [
      {
        title: 'General Creative Task List',
        items: taskList?.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}

export const createSeoChecklist = deal => {
  const checklist: Checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_SEO_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`,
    type: 'SEO',
    sections: [], // Now using sections instead of a flat list
    createdAt: new Date().toISOString(),
  }

  if (deal?.isSeo1) {
    checklist.sections.push({
      title: 'SEO - Tier 1 Checklist',
      items: [
        {
          id: 'TIER1-1',
          title: `Confirm Correct GTM & GA4 on client website`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-2',
          title: `Confirm Goals & Conversions in GA4`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-3',
          title: `Google/Bing Webmasters Verification/Submission (only if we have access to the website)`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-4',
          title: `Set up Robots.txt File (only if we have acces to the website)`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-5',
          title: `Sitemap Submission and Addition to Robots.txt (only if we have acces to the website)`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-6',
          title: `Vendasta - Business Listing Distribution`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-7',
          title: `Vendasta - Business Listing Sync Pro`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-8',
          title: `Request access to client's GMB. If client has no access, start creation/claim Google listing. (this task is completed in onboarding once request/creation/claim has been initiated)`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-9',
          title: `Perform SEO Audit in SERanking`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER1-10',
          title: `Provide SEO Audit and Onboarding Notes for AM in Monday`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
      ],
    })
  }
  if (deal?.isSeo2 || deal?.isSeo3 || deal?.isSeo4) {
    checklist.sections.push({
      title: 'SEO - Tier 2 Checklist',
      items: [
        {
          id: 'TIER234-1',
          title: `Confirm Correct GTM & GA4 on client website`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-2',
          title: `Confirm Goals & Conversions in GA4`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-3',
          title: `Google/Bing Webmasters Verification/Submission`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-4',
          title: `Set up Robots.txt File`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-5',
          title: `Sitemap Submission and Addition to Robots.txt`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-6',
          title: `Link Google Webmasters and GA4 to SEranking`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-7',
          title: `Vendasta - Business Listing Distribution`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-8',
          title: `Vendasta - Business Listing Sync Pro`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-9',
          title: `Request access to client's GMB. If client has no access, start creation/claim Google listing. (this task is completed in onboarding once request/creation/claim has been initiated)`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-10',
          title: `Enter Client into SE Ranking`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-11',
          title: `Perform SEO Audit in SEranking`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-12',
          title: `Perform a Form Fill Test`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        { id: 'TIER234-13', title: `Keyword Research`, status: 'In Progress', relatedDealField: 'completeSeoForm' },
        {
          id: 'TIER234-14',
          title: `If Newly Launched Check 301's`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
        {
          id: 'TIER234-15',
          title: `Provide SEO Audit, Keyword Research and Onboarding Notes for AM in Monday`,
          status: 'In Progress',
          relatedDealField: 'completeSeoForm',
        },
      ],
    })
  }
  // Assign section and item indices:
  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))
  return checklist
}

export const integrationFormState = {
  organizationName: '',
  websiteURL: '',
  portal: '',
  gtmId: '',
  ga4PropertyId: '',
  ga4MeasurementId: '',
  websiteNotes: '',
  trackedConversions: '',
  googleAdsId: '',
  metaAccountId: '',
  metaPixelId: '',
  tiktokAccountId: '',
  tiktokPixelId: '',
  simplifiOrgId: '',
  bingAccountId: '',
  bingUetId: '',
}

export const createPremiumWebsiteChecklist = deal => {
  const checklist: Checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_WEBSITE_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`,
    type: 'Website Build',
    sections: [
      {
        title: 'Website Checklist',
        items: premiumWebsiteList?.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          dependencies: task.dependencies, // Maintain dependencies
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}

export const createEquipmentChecklist = deal => {
  const checklist: Checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_EQUIPMENT_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`,
    type: 'Equipment',
    sections: [
      {
        title: 'Equipment Checklist',
        items: shootEquipmentChecklist?.map(task => ({
          id: task.key, // Using key for consistency
          title: task.title,
          status: 'In Progress', // Default status
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
export const createCustomChecklist = (deal, tasks, checklistTitle, assignedUser, context) => {
  const checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `CHECKLIST_${context.toUpperCase()}_CUSTOM_${Date.now().toString(36)}_${Math.random()
      .toString(36)
      .substring(2, 8)}`,
    type: `Custom Checklist - ${context}`,
    sections: [
      {
        title: checklistTitle,
        assignedUser: assignedUser || [],
        items: tasks.map((task, index) => ({
          id: task.key,
          title: task.name,
          status: 'In Progress',
          relatedDealField: task.relatedDealField,
          itemIndex: index,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  return checklist
}

export const createAMTaskList = deal => {
  const checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `TASKLIST_AM_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK
    type: 'AM',
    sections: [
      {
        title: 'AM Tasks',
        items: amTaskList.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          dependencies: task.dependencies, // Maintain dependencies
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
export const CreateDMSTaskList = deal => {
  const checklist = {
    PK: `DEAL_${deal.deal_uuid}`,
    SK: `TASKLIST_DMS_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK
    type: 'DMS',
    sections: [
      {
        title: 'DMS Tasks',
        items: dmsTaskList.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          dependencies: task.dependencies, // Maintain dependencies
          relatedDealField: task.relatedDealField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
export const createOrganizationAMTaskList = org => {
  const checklist = {
    PK: `ORG_${org.org_uuid}`,
    SK: `TASKLIST_AM_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK
    type: 'AM',
    sections: [
      {
        title: 'AM Tasks',
        items: amTaskList.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          dependencies: task.dependencies, // Maintain dependencies
          relatedOrgField: task.relatedOrgField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
export const createOrganizationDMSTaskList = org => {
  const checklist = {
    PK: `ORG_${org.org_uuid}`,
    SK: `TASKLIST_DMS_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`, // Unique SK
    type: 'DMS',
    sections: [
      {
        title: 'DMS Tasks',
        items: dmsTaskList.map(task => ({
          id: task.key, // Using key for consistency
          title: task.name,
          status: 'In Progress', // Default status
          role: task.role,
          dependencies: task.dependencies, // Maintain dependencies
          relatedOrgField: task.relatedOrgField,
        })),
      },
    ],
    createdAt: new Date().toISOString(),
  }

  checklist.sections = checklist.sections.map((section, sectionIndex) => ({
    ...section,
    sectionIndex,
    items: section.items.map((item, itemIndex) => ({
      ...item,
      itemIndex,
    })),
  }))

  return checklist
}
