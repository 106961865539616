import React from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import format from 'date-fns/format'
import { renderColorByString } from '../DataGrid/renderColorByString'
import DisplayChannels from './DisplayChannels.js'
import RoleCard from '../NCO/RoleCard.js'
import RenderOnboardTeam from './components/RenderOnboardTeam.js'

function formatDate(timestamp) {
  if (!timestamp) return
  return format(new Date(timestamp), 'MM/dd/yyyy')
}
const BoardsGridHeader = ({ newDeal, organization }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 1, px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Est Launch Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.estLaunchDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Start Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.startDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              End Date
            </Typography>
            <Typography sx={{ textAlign: 'left', color: theme.palette.text.primary }}>
              {formatDate(newDeal?.endDate)}
            </Typography>
          </Box>
        </Grid>
        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />

        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 1, px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Onboarding Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.onboardingStatus ?? 'On Hold') }}>
              {newDeal?.onboardingStatus ?? 'On Hold'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Contract Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.status) }}>
              {newDeal?.status || 'no nco status'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Deal Status
            </Typography>
            <Typography sx={{ color: renderColorByString(newDeal?.dealStatus) }}>
              {newDeal?.dealStatus || 'no nco status'}
            </Typography>
          </Box>
        </Grid>

        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ py: 1, px: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Organization Status
            </Typography>
            <Typography sx={{ color: renderColorByString(organization?.status) }}>{organization?.status}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Website
            </Typography>
            <Typography
              sx={{ height: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 12 }}
            >
              {organization?.website ?? '—'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left' }}>
              Prospect Portal
            </Typography>
            <Typography>{organization?.portal ?? '—'}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BoardsGridHeader
