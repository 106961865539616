import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

interface OrgChecklistUpdate {
  PK: string
  SK: string
  itemId: string
  newStatus: string
}

export const useBatchUpdateOrgChecklists = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (updates: OrgChecklistUpdate[]) => {
      console.log('UPDATES IN MUTATION: ', updates)
      if (updates.length === 0) return
      return axios.put(`${baseUrl}/aws/checklists/batch_update`, { updates })
    },

    // ✅ Optimistic UI Update
    onMutate: async updates => {
      await queryClient.cancelQueries(['checklists'])

      const previousChecklists = queryClient.getQueryData(['checklists'])
      console.log('previousChecklists', previousChecklists)

      queryClient.setQueryData(['checklists'], (old: any) => {
        if (!old) return old

        return old.map((checklist: any) => ({
          ...checklist,
          sections: checklist.sections.map((section: any) => ({
            ...section,
            items: section.items.map((item: any) => {
              const updated = updates.find(update => update.itemId === item.id)
              return updated ? { ...item, status: updated.newStatus } : item
            }),
          })),
        }))
      })

      return { previousChecklists }
    },

    // 🔥 Rollback on Error
    onError: (_, __, context) => {
      if (context?.previousChecklists) {
        queryClient.setQueryData(['checklists'], context.previousChecklists)
      }
    },

    // ✅ Refetch on Success
    onSettled: context => {
      // queryClient.invalidateQueries(['checklists'])
      console.log('CONTEXT: ', context)

      const checklistPK = context?.data?.orgId
      console.log('CHECKLIST PK', checklistPK)
      const queryKey = ['checklists', context?.orgId ? `ORG_${context.orgId}` : `${checklistPK}`]
      console.log('QUERY KEY', queryKey)
      queryClient.invalidateQueries(['checklists', context?.orgId ? `ORG_${context.orgId}` : `${checklistPK}`])
    },
  })
  return mutation
}
