// usePostOptimization.tsx
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../../queryMutations/mutationFunctions'

export interface OptData {
  PK: string
  opt_uuid: string
  orgId: string
  name: string
  color: string
  type: string
  description: string
  tooltip?: string
  qualifyingTier?: string
  plan?: string
  module: string
  followDate?: string
  channel?: string
  addtlNotes: string
  opts: number
  createdAt: string
  // any additional fields...
}

const postOptimization = async (optData: OptData): Promise<OptData> => {
  const url = `${baseUrl}/aws/optimizations`
  console.log('[usePostOptimization] Submitting Task Data:', optData)
  const response = await axios.post(url, optData)
  return response.data
}

export const usePostOptimization = () => {
  const { showSnackbar } = useSnackbarContext()
  const queryClient = useQueryClient()

  return useMutation<OptData, Error, OptData>({
    mutationFn: postOptimization,
    onMutate: async newOpt => {
      // Optionally cancel any ongoing queries
      await queryClient.cancelQueries({ queryKey: ['optimizations', newOpt.orgId] })
      // Snapshot previous optimizations if needed
      const previousOpts = queryClient.getQueryData<OptData[]>(['optimizations', newOpt.orgId])
      // Optimistically update the cache
      queryClient.setQueryData<OptData[]>(['optimizations', newOpt.orgId], old =>
        old ? [...old, { ...newOpt, opt_uuid: 'temp-id' }] : [{ ...newOpt, opt_uuid: 'temp-id' }]
      )
      return { previousOpts }
    },
    onError: (error, _newOpt, context) => {
      if (context?.previousOpts) {
        queryClient.setQueryData(['optimizations'], context.previousOpts)
      }
      console.error('[usePostOptimization] Mutation Error:', error)
      showSnackbar(error.response?.data?.message || error.message, 'error')
    },
    onSuccess: data => {
      console.log('[usePostOptimization] Optimization Created Successfully:', data)
      // Update the cache with the actual optimization id
      queryClient.setQueryData<OptData[]>(['optimizations', data.orgId], oldOpts =>
        oldOpts ? oldOpts.map(opt => (opt.opt_uuid === 'temp-id' ? data : opt)) : [data]
      )
      showSnackbar('Optimization created successfully', 'success')
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: ['optimizations', variables.orgId] })
    },
  })
}
