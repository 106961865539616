import axios from 'axios'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { baseUrl } from '../../queryMutations/mutationFunctions'

export const useAddChecklistTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async newTasks => {
      return axios.post(`${baseUrl}/aws/checklists/add_task`, { tasks: newTasks })
    },
    onSuccess: () => {
      console.log('✅ New task added successfully.')
      queryClient.invalidateQueries(['checklists']) // Refresh checklist
    },
    onError: error => {
      console.error('❌ Error adding new task:', error)
    },
  })
}
