import React, { useEffect, useMemo, useRef, useState } from 'react'
import { GridToolbarContainer, GridToolbarDensitySelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { Badge, Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import { gridToolbarContainerStyles } from '../Organizations/style.js'
import CustomStatusFilter from '../DataGrid/createColumns/CustomStatusFilter.js'
import CustomTypeFilter from '../DataGrid/CustomTypeFilter.js'
import { StyledGridToolbarQuickFilter } from '../DataGrid/style.js'
import { useActiveUser } from '../../api/slack/useActiveUser.js'
import ResetColumnsButton from '../ui/reset-columns-button.js'
import ResetSortingButton from '../ui/reset-sorting-button.js'
import ResetFiltersButton from '../ui/reset-filters-button.js'
import OpenColumnsMenu from '../ui/open-columns-menu.js'
import OpenFiltersMenu from '../ui/open-filters-menu.js'
import ExportCsvButton from '../ui/export-csv-button.js'
import { statusFilters } from '../DataGrid/filterArrays.js'
import { organizationTypeOptions } from '../Organizations/orgStatusOptions.js'
import FilterMyDataButton from '../ui/filter-my-data-button.tsx'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { slotProps } from '../DataGrid/style.js'
import CustomFiltersModal from '../DataGrid/components/CustomFiltersModal.tsx'
import CustomFiltersMenu from '../ui/custom-filters-menu-button.tsx'
import MonthFilter from './MonthFilter.tsx'
import MonthYearFilter from './MonthYearFilter.tsx'

const initialSortModel = [
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
]

const initialVisibilityState = {
  org_uuid: false,
  affiliated: false,
  source: false,
  street: false,
  city: false,
  state: false,
  zip: false,
  cmsUsed: false,
  social: false,
  dealIds: false,
}
const MetricsToolbar = ({
  filterModel,
  visibilityModel,
  sortModel,
  setFilterModel,
  onClearSorting,
  onClearVisibility,
  setMonthFilter,
  monthFilterValue,
  context,
}) => {
  const theme = useTheme()
  const quickFilterRef = useRef(null) // Create a ref for the quick filter
  const [filterStatus, setFilterStatus] = useState('')
  const [filterStage, setFilterStage] = useState('')
  const [filterType, setFilterType] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [isCustomFiltersOpen, setCustomFiltersOpen] = useState(false)
  const [shouldClear, setShouldClear] = useState(false) // New state to trigger clearing
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const activeUserName =
    activeUser && activeUser.user && activeUser.user.profile ? activeUser.user.profile.real_name : null
  // Function to check if the sort model has changed
  const isSortModelChanged = sortModel.some((sort, index) => {
    const initialSort = initialSortModel[index]
    return sort.field !== initialSort?.field || sort.sort !== initialSort?.sort
  })
  const memoizedStatusFilters = useMemo(() => statusFilters, [])
  const memoizedOrganizationTypeOptions = useMemo(() => organizationTypeOptions, [])
  const [densityAnchorEl, setDensityAnchorEl] = useState(null)
  const apiRef = useGridApiContext() // Get DataGrid API
  // 🔹 Open/Close Handlers for Density Menu
  const handleOpenDensityMenu = event => {
    setDensityAnchorEl(event.currentTarget)
  }
  const handleCloseDensityMenu = () => {
    setDensityAnchorEl(null)
  }
  // 🔹 Toggle Columns Visibility Menu
  const handleOpenColumnsMenu = event => {
    apiRef.current.showPreferences('columns')
  }

  // 🔹 Toggle Filter Panel
  const handleOpenFilterPanel = () => {
    apiRef.current.showFilterPanel()
  }

  // 🔹 Export Data
  const handleExport = () => {
    apiRef.current.exportDataAsCsv()
  }

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault()
        if (quickFilterRef.current) {
          quickFilterRef.current.focus() // Focus the quick filter input
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleFilterMyData = () => {
    setFilterModel({
      ...filterModel,
      quickFilterValues: [activeUserName],
    })
  }

  const handleSetStatusFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }
      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }
  const handleSetTypeFilter = (fieldName, selectedValues) => {
    setFilterModel(prevModel => {
      // Remove existing filter for the same field
      let updatedFilters = prevModel.items.filter(item => item.field !== fieldName)

      // If selectedValues is empty, we remove the filter
      if (selectedValues && selectedValues.length > 0) {
        updatedFilters.push({
          id: `${fieldName}-${Date.now()}`, // Ensure unique ID
          field: fieldName,
          operator: 'isAnyOf', // 🔥 Multi-selection operator
          value: selectedValues,
        })
      }
      return {
        ...prevModel,
        items: updatedFilters,
      }
    })
  }

  const handleClearFilters = e => {
    e.preventDefault()
    setFilterModel({
      items: [],
      quickFilterValues: [],
    }) // Clear filter model
    setMonthFilter('')
    setFilterStatus('') // Reset status filter
    setFilterStage('') // Reset stage filter
    setFilterType('') // Reset type filter
    setShouldClear(true) // Trigger the clearing in the custom filter components
  }
  const handleOpenCustomFilters = event => {
    setAnchorEl(event.currentTarget) // Attach the popover to the button
    setCustomFiltersOpen(true)
  }

  const handleCloseCustomFilters = () => {
    setAnchorEl(null) // Clear the anchor when closing
    setCustomFiltersOpen(false)
  }
  return (
    <GridToolbarContainer sx={gridToolbarContainerStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, ml: 3 }}>
          <GridToolbarDensitySelector slotProps={slotProps(theme)} />
          {/* <GridToolbarColumnsButton slotProps={slotProps(theme)} />
          <GridToolbarFilterButton slotProps={slotProps(theme)} />
          <GridToolbarExport slotProps={slotProps(theme)} /> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <OpenColumnsMenu onOpenColumns={handleOpenColumnsMenu} />
            <OpenFiltersMenu
              onOpenFilters={handleOpenFilterPanel}
              filterModel={filterModel}
              badgeColor={theme.palette.icon.organizations}
            />
            <ExportCsvButton onExport={handleExport} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {/* <CustomTypeFilter
            context={'type'}
            options={organizationTypeOptions}
            setterFn={setFilterType}
            filterModel={filterModel}
            value={filterType}
            onChange={handleSetTypeFilter}
            shouldClear={shouldClear} // Pass the clear trigger to the filter
            setShouldClear={setShouldClear} // Pass the function to reset the trigger
          /> */}
          {/* <MonthFilter value={monthFilterValue} onChange={setMonthFilter} /> */}
          <MonthYearFilter
            value={monthFilterValue}
            onChange={setMonthFilter}
            availableYears={[2020, 2021, 2022, 2023, 2024, 2025]} // optional
          />
          <CustomFiltersMenu
            onOpenCustomFilters={handleOpenCustomFilters}
            count={filterModel?.items?.length ?? 0}
            badgeColor={theme.palette.icon.organizations}
          />
          <CustomFiltersModal
            isOpen={isCustomFiltersOpen}
            anchorEl={anchorEl}
            handleCloseCustomFilters={handleCloseCustomFilters}
          >
            <CustomStatusFilter
              context={'type'}
              options={memoizedOrganizationTypeOptions}
              setterFn={setFilterType}
              filterModel={filterModel}
              value={filterType}
              onChange={handleSetTypeFilter}
            />
            <CustomStatusFilter
              context={'status'}
              options={memoizedStatusFilters}
              setterFn={setFilterStatus}
              filterModel={filterModel}
              value={filterStatus}
              onChange={handleSetStatusFilter}
            />
          </CustomFiltersModal>
          <FilterMyDataButton
            icon={<CorporateFareIcon sx={{ fontSize: '30px' }} />}
            filterModel={filterModel}
            onClick={handleFilterMyData}
            label={'My Organizations'}
          />
          <ResetColumnsButton
            visibilityModel={visibilityModel}
            onClearVisibility={onClearVisibility}
            initialVisibilityState={initialVisibilityState}
          />
          <ResetSortingButton isSortModelChanged={isSortModelChanged} onClearSorting={onClearSorting} />
          <ResetFiltersButton filterModel={filterModel} handleClearFilters={handleClearFilters} />
          <Badge
            badgeContent={
              <>
                {filterModel &&
                filterModel.quickFilterValues &&
                Array.isArray(filterModel.quickFilterValues) &&
                filterModel.quickFilterValues.length > 0 ? (
                  <Typography
                    sx={{
                      fontSize: 12,
                      bgcolor: 'rgb(114, 229, 218)',
                      p: 0.5,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      display: 'flex', // Flexbox centering
                      alignItems: 'center', // Vertically center
                      justifyContent: 'center', // Horizontally center
                      color: theme.palette.mode === 'dark' ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,1)',
                      fontWeight: 'bold',
                    }}
                  >
                    {filterModel && filterModel.quickFilterValues && Array.isArray(filterModel.quickFilterValues)
                      ? filterModel.quickFilterValues.length
                      : null}
                  </Typography>
                ) : null}
              </>
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledGridToolbarQuickFilter placeholder={'ctrl+f or cmd+f to search'} inputRef={quickFilterRef} />
          </Badge>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default MetricsToolbar
