import React, { useState, useEffect, useMemo } from 'react'
import { Drawer, Box, Typography, useTheme, Tabs, Tab, Grid, InputLabel } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useDeal } from '../../api/aws/useDeal.js'
import { useOrganizationById } from '../../api/aws/useOrganization.js'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal.js'
import BoardsGridHeader from './BoardsGridHeader.js'
import { useValidateGoogleToken } from '../../api/google/useValidateGoogleToken.ts'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization.js'
import DisplayChannels from './DisplayChannels.js'
import ExpandCollapseButton from '../ui/expand-collapse-button.tsx'
import { useGetChecklists } from '../../api/aws/checklists/useGetChecklists.ts'
import { useBatchUpdateChecklists } from '../../api/aws/checklists/useBatchUpdateChecklists.ts'
import { useCreateGoogleDoc } from '../../api/google/useCreateGoogleDoc.ts'
import { useDealFolders } from '../../api/google/useDealFolders.ts'
import { formatDayjsDateTime } from '../../utility/formatDayjsDateTime.js'
import { useOrganizationSubfolders } from '../../api/google/useOrganizationSubfolders.ts'
import { useGoogleOrganizationFolder } from '../../api/google/useGoogleOrganizationFolder.ts'
import ReviewDeal from '../DealApproval/ReviewDeal.js'
import CommentsInput from '../Comments/CommentsInput.tsx'
import FileManagement from './components/FileManagement.js'
import TasksByOrganization from './components/TasksByOrganization.tsx'
import {
  createEquipmentChecklist,
  createGeneralCreativeTaskList,
  determineCreativeList,
} from '../../api/aws/checklists/lists.ts'
import { useBatchPostChecklists } from '../../api/aws/checklists/useBatchPostChecklists.ts'
import ProgressBar from './components/ProgressBar.tsx'
import CreativeFields from '../Creative/CreativeFields.tsx'
import CreativeChecklists from './components/CreativeChecklists.tsx'
import CreativeDrawerTasksGrid from './components/CreativeDrawerTasksGrid.js'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider.js'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider.js'

const CreativeDrawerComponent = ({ open, onClose, context }) => {
  const { pinned } = useLayoutContext()
  const { showSnackbar } = useSnackbarContext()
  const { createTaskMentionPayload } = useNotify()
  const navigate = useNavigate()
  const location = useLocation()
  const { validateToken, isValid } = useValidateGoogleToken()
  const { dealId, orgId, campaignId } = useParams()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [updates, setUpdates] = useState({})
  const { mutate: updateDeal } = useUpdateDeal()
  const { mutate: updateOrganization } = useUpdateOrganization()
  const [activeTab, setActiveTab] = useState(0)
  const [completedCounts, setCompletedCounts] = useState({}) // ✅ Store completed checklists
  const [onboardingProgress, setOnboardingProgress] = useState(0)
  // Fetch the deal & organization
  const { deal, isDealLoading } = useDeal(dealId)
  const { organization, isOrganizationLoading } = useOrganizationById(deal?.organizations?.[0])
  // Fetch the Organization's Google Drive Folder ID
  const { data: organizationFolder, isLoading: isOrganizationFolderLoading } = useGoogleOrganizationFolder(
    organization?.org_uuid
  )
  const orgFolderId = organizationFolder?.organizationFolderId ?? null // ✅ Default to null
  // Fetch 'Deals' Folder inside the Organization
  const { data: subfolders, isLoading: isSubfoldersLoading } = useOrganizationSubfolders(orgFolderId || '')
  // Ensure subfolders is always an array
  const safeSubfolders = Array.isArray(subfolders) ? subfolders : []
  // Find the "Deals" folder dynamically
  const dealsFolder = safeSubfolders.find(folder => folder.name === 'Deals')
  const dealsFolderId = dealsFolder ? dealsFolder.id : null
  const creation_timestamp = formatDayjsDateTime(deal?.creation_timestamp)
  // Fetch Specific Deal Folder inside 'Deals' Folder
  // const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(dealsFolderId || '', dealId || '')
  const { data: dealFolders, isLoading: isDealFoldersLoading } = useDealFolders(
    dealsFolderId || '',
    creation_timestamp || ''
  )
  // Ensure dealFolders is always an array
  const safeDealFolders = Array.isArray(dealFolders) ? dealFolders : []
  const dealFolderId = safeDealFolders.length > 0 ? safeDealFolders[0]?.id : null
  const { data: fetchedChecklists, isLoading: isChecklistsLoading } = useGetChecklists(deal)
  const [checklists, setChecklists] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [pendingChecklistUpdates, setPendingChecklistUpdates] = useState([]) // Store pending updates
  const [pendingDealUpdates, setPendingDealUpdates] = useState({}) // Store pending updates
  const { mutateAsync: batchUpdateAsync } = useBatchUpdateChecklists()
  const { mutate: createDoc } = useCreateGoogleDoc()
  const { mutate: createChecklistsBatch } = useBatchPostChecklists()
  const [fieldUpdates, setFieldUpdates] = useState({
    // googleAdsId: deal?.googleAdsId || '',
    // metaAccountId: deal?.metaAccountId || '',
    // metaPixelId: deal?.metaPixelId || '',
    // tiktokAccountId: deal?.tiktokAccountId || '',
    // tiktokPixelId: deal?.tiktokPixelId || '',
    // simplifiOrgId: deal?.simplifiOrgId || '',
    // bingAccountId: deal?.bingAccountId || '',
    // bingUetId: deal?.bingUetId || '',
    // websiteNotes: deal?.websiteNotes || '',
    // trackedConversions: deal?.trackedConversions || '',
  })

  useEffect(() => {
    if (!deal?.deal_uuid || isChecklistsLoading) return

    let taskList = determineCreativeList(deal)
    // If no creative tasks are determined, you could set a default list.
    if (!taskList || taskList.length === 0) {
      // For instance, use a default set of tasks or a flag indicating they should be created.
      // This is an example default—you can adjust it to your needs.
      taskList = [{ key: 'default1', name: 'Default Creative Task 1', role: 'Creative' }]
    }

    // Check if the deal qualifies for equipment tasks.
    const isEquipmentType =
      deal?.isDroneVideoAdd ||
      deal?.isDroneShootAdd ||
      deal?.isVideoEdit ||
      deal?.isVideoShoot ||
      deal?.isPhotoEdit ||
      deal?.isPhotoShoot ||
      deal?.isLifestyleShoot
    const isWebsiteBuild = deal?.isPremiumBuild || deal?.isStandardBuild || deal?.isEcommerceBuild || deal?.isAddPage

    // Build the desired checklists.
    const checklistTypes =
      taskList.length > 0
        ? [
            deal.type === 'CSA' && createGeneralCreativeTaskList(deal, taskList),
            deal?.type === 'CSA' && isEquipmentType && createEquipmentChecklist(deal),
          ].filter(Boolean)
        : []

    // For creative checklists, only check if one with SK starting with "CREATIVE_TASKLIST_" exists.
    const missingChecklists = checklistTypes.filter(checklist => {
      if (checklist.SK && checklist.SK.startsWith('CREATIVE_TASKLIST_')) {
        return !fetchedChecklists.some(c => c.SK && c.SK.startsWith('CREATIVE_TASKLIST_'))
      }
      return !fetchedChecklists.some(c => c.type === checklist.type)
    })

    console.log('🔄 Missing Checklists:', missingChecklists)
    // console.log('taskList:', taskList)
    // console.log('fetchedChecklists:', fetchedChecklists)
    console.log('checklistTypes:', checklistTypes)

    if (missingChecklists.length > 0) {
      createChecklistsBatch(missingChecklists, {
        onSuccess: () => {
          queryClient.invalidateQueries(['checklists', `DEAL_${deal.deal_uuid}`])
        },
        onError: error => {
          console.error('❌ Error creating checklists:', error)
        },
      })
    }
  }, [deal, fetchedChecklists, isChecklistsLoading, createChecklistsBatch, queryClient])

  const handleValidateToken = (token, userId) => {
    if (!token || !isValid) {
      console.log('🔄 Token missing or invalid, validating...')

      validateToken(
        { accessToken: token, refreshToken: '', userId },
        {
          onSuccess: data => {
            if (data.isValid) {
              localStorage.setItem('google_access_token', data.accessToken || '')
            } else {
              console.warn('❌ Token validation failed, please reauthenticate.')
            }
          },
        }
      )
    } else {
      console.log('✅ Using valid access token from cache.')
    }
  }
  useEffect(() => {
    if (pendingChecklistUpdates) {
      console.log('pendingChecklistUpdates', pendingChecklistUpdates)
    }
  }, [pendingChecklistUpdates])
  // Set checklists when fetched
  useEffect(() => {
    if (fetchedChecklists) {
      setChecklists(fetchedChecklists)
    }
  }, [fetchedChecklists])

  // Derive general and integration checklists from the updated `checklists` state
  //   const equipmentChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'Equipment'), [checklists])
  const generalChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'General Tasks'), [checklists])
  const equipmentChecklist = useMemo(() => checklists.filter(chk => chk?.type === 'Equipment'), [checklists])
  const creativeCustomChecklists = useMemo(
    () => checklists.filter(chk => chk?.type === `Custom Checklist - ${context}`),
    [checklists]
  )
  const creativeChecklistsComplete = useMemo(() => {
    const custom = checklists.filter(chk => chk?.type === `Custom Checklist - ${context}`)
    const equipment = checklists.filter(chk => chk?.type === 'Equipment')
    return [...custom, ...equipment]
  }, [checklists, context])

  useEffect(() => {
    if (generalChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedDealField (lowercase)
      const progress = {}

      generalChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedDealField
          if (section.items && section.items.length > 0) {
            const key = section.items[0]?.relatedDealField?.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setCompletedCounts(prev => ({ ...prev, ...progress }))
    }
    if (equipmentChecklist.length > 0) {
      // We'll build a progress object keyed by the relatedDealField (lowercase)
      const progress = {}

      equipmentChecklist.forEach(checklist => {
        checklist.sections.forEach(section => {
          // Assume each section's items share the same relatedDealField
          if (section.items && section.items.length > 0) {
            const key = section.items[0].relatedDealField.toLowerCase() // e.g., "gtm", "ga4", "fbpage"
            const total = section.items.length
            const doneCount = section.items.filter(item => item.status === 'Done').length
            // Store fraction complete (or you can store doneCount and total separately)
            progress[key] = doneCount / total
          }
        })
      })

      setCompletedCounts(prev => ({ ...prev, ...progress }))
    }
  }, [generalChecklist, equipmentChecklist])
  useEffect(() => {
    if (!isDealLoading && completedCounts?.creativeprogress != null) {
      // Ensure both values are numbers
      const dealProgress = Number(deal?.completedCounts?.creativeprogress ?? 0)
      const newProgress = Number(completedCounts.creativeprogress)

      if (dealProgress !== newProgress) {
        setPendingDealUpdates(prev => ({
          ...prev,
          completedCounts: { creativeprogress: newProgress },
        }))
      }
    }
    if (!isDealLoading && completedCounts?.websiteprogress != null) {
      // Ensure both values are numbers
      const websiteProgress = Number(deal?.completedCounts?.websiteprogress ?? 0)
      const newWebsiteProgress = Number(completedCounts.websiteprogress)
      if (websiteProgress !== newWebsiteProgress) {
        setPendingDealUpdates(prev => ({
          ...prev,
          completedCounts: { websiteprogress: newWebsiteProgress },
        }))
      }
    }
  }, [completedCounts, isDealLoading, deal])
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const taskParam = params.get('task')

    if (location.pathname.includes(`projectinfo`)) {
      setActiveTab(1)
    } else if (location.pathname.includes(`review`)) {
      setActiveTab(2)
    } else if (location.pathname.includes(`notes/${orgId}`) || taskParam) {
      // If either the pathname indicates the notes route or there's a task parameter, use tab index 3.
      setActiveTab(3)
    } else if (location.pathname.includes(`checklists/${orgId}`)) {
      setActiveTab(4)
    } else if (location.pathname.includes(`filemgmt`)) {
      setActiveTab(5)
    } else if (location.pathname.includes(`orgtasks/${orgId}`)) {
      setActiveTab(6)
    } else {
      setActiveTab(0)
    }
  }, [location, campaignId, orgId])
  // useEffect(() => {
  //   if (location.pathname.includes(`projectinfo`)) {
  //     setActiveTab(1)
  //   } else if (location.pathname.includes(`review`)) {
  //     setActiveTab(2)
  //   } else if (location.pathname.includes(`notes/${orgId}`)) {
  //     setActiveTab(3)
  //   } else if (location.pathname.includes(`checklists/${orgId}`)) {
  //     setActiveTab(4)
  //   } else if (location.pathname.includes(`filemgmt`)) {
  //     setActiveTab(5)
  //   } else if (location.pathname.includes(`orgtasks/${orgId}`)) {
  //     setActiveTab(6)
  //   } else {
  //     setActiveTab(0)
  //   }
  // }, [location, campaignId])
  const handleTabChange = (event, newValue) => {
    const paths = [
      'main',
      'projectinfo',
      'review',
      `notes/${organization?.org_uuid}`,
      `checklists/${organization?.org_uuid}`,
      'filemgmt',
      `orgtasks/${organization?.org_uuid}`,
      '',
      '',
      '',
    ]
    if (newValue === 8 || newValue === 9) {
      event.preventDefault()
    } else {
      setActiveTab(newValue)
      // For the Notes tab, preserve existing query parameters (if any)
      if (newValue === 3) {
        navigate(`${dealId}/${paths[newValue]}${location.search}`)
      } else {
        navigate(`${dealId}/${paths[newValue]}`)
      }
    }
  }
  // const handleTabChange = (event, newValue) => {
  //   const paths = [
  //     'main',
  //     'projectinfo',
  //     'review',
  //     `notes/${organization?.org_uuid}`,
  //     `checklists/${organization?.org_uuid}`,
  //     'filemgmt',
  //     `orgtasks/${organization?.org_uuid}`,
  //     '',
  //     '',
  //     '',
  //   ]
  //   if (newValue === 8) {
  //     event.preventDefault()
  //   } else if (newValue === 9) {
  //     event.preventDefault()
  //   } else {
  //     setActiveTab(newValue)
  //     navigate(`${dealId}/${paths[newValue]}`)
  //   }
  // }

  const findIndices = (checklist, itemId) => {
    if (!checklist || !Array.isArray(checklist.sections) || checklist.sections.length === 0) {
      console.error('Checklist sections missing for checklist:', checklist)
      return null
    }
    for (let sectionIndex = 0; sectionIndex < checklist.sections.length; sectionIndex++) {
      const section = checklist.sections[sectionIndex]
      if (!section.items || !Array.isArray(section.items)) {
        continue
      }
      for (let itemIndex = 0; itemIndex < section.items.length; itemIndex++) {
        if (section.items[itemIndex] && section.items[itemIndex].id === itemId) {
          return { sectionIndex, itemIndex }
        }
      }
    }
    console.error('Item not found in checklist:', itemId)
    return null
  }

  const handleStatusChange = (checklist, itemId, newStatus) => {
    console.log('HANDLE STATUS CHANGE', checklist, itemId, newStatus)
    if (!itemId || !newStatus) return

    const indices = findIndices(checklist, itemId)
    if (!indices) {
      console.error('Could not find indices for item:', itemId)
      return
    }

    setPendingChecklistUpdates(prev => [
      ...prev.filter(update => update.itemId !== itemId),
      {
        PK: checklist.PK,
        SK: checklist.SK,
        itemId,
        newStatus,
        fieldName: 'status',
        sectionIndex: indices.sectionIndex,
        itemIndex: indices.itemIndex,
      },
    ])

    // Optimistic update:
    setChecklists(prevChecklists =>
      prevChecklists.map(chk =>
        chk.PK === checklist.PK && chk.SK === checklist.SK
          ? {
              ...chk,
              sections: chk.sections.map((section, sIndex) => ({
                ...section,
                items: section.items.map((item, iIndex) =>
                  item.id === itemId ? { ...item, status: newStatus } : item
                ),
              })),
            }
          : chk
      )
    )
  }

  const handleClose = async () => {
    if (pendingChecklistUpdates.length > 0) {
      try {
        await batchUpdateAsync(pendingChecklistUpdates)
        setPendingChecklistUpdates([]) // Clear after successful update
      } catch (error) {
        console.error('Error updating checklists:', error)
        // Optionally, handle the error or show a notification
      }
    }
    if (Object.keys(pendingDealUpdates).length > 0) {
      await handleUpdate()
    }
    if (Object.keys(fieldUpdates)?.length > 0) {
      await handleUpdateFields()
    }
    if (onClose) {
      onClose()
    }
  }

  const handleUpdate = async () => {
    if (Object.keys(pendingDealUpdates).length === 0) return // No update needed if nothing changed

    const dealId = deal?.deal_uuid

    // ✅ Ensure only updates & new values are passed, keeping existing state
    const updateData = {
      ...deal, // Preserve existing deal state
      ...pendingDealUpdates, // Apply user changes
      completedCounts: { ...completedCounts },
      queryKey: ['deal', dealId],
    }

    try {
      updateDeal(
        { dealId, dealData: updateData },
        {
          onSuccess: () => {
            console.log('✅ Deal successfully updated:', updateData)
            setPendingDealUpdates({}) // Reset updates after success
          },
          onError: error => {
            console.error('❌ Error updating deal:', error)
            setPendingDealUpdates({}) // Prevent stale updates
          },
        }
      )
    } catch (e) {
      console.error('[BoardsDrawer] error updating deal', e)
    }
  }
  const handleUpdateFields = async () => {
    if (Object.keys(fieldUpdates).length === 0) return // No update needed if nothing changed

    const dealId = deal?.deal_uuid

    // ✅ Ensure only updates & new values are passed, keeping existing state
    const updateData = {
      ...deal, // Preserve existing deal state
      ...fieldUpdates, // Apply user changes
      queryKey: ['deal', dealId],
    }

    try {
      updateDeal(
        { dealId, dealData: updateData },
        {
          onSuccess: () => {
            console.log('✅ Deal fields successfully updated:', updateData)
            setFieldUpdates({}) // Reset updates after success
          },
          onError: error => {
            console.error('❌ Error updating deal fields:', error)
            setFieldUpdates({}) // Prevent stale updates
          },
        }
      )
    } catch (e) {
      console.error('[BoardsDrawer] error updating deal fields', e)
    }
  }
  // Function to handle an assigned user update.
  const handleAssignedUserChange = (row, selectedUser) => {
    // Find the indices in the current checklist where the row lives.
    // You might need to determine which checklist is relevant (for example, if you have multiple).
    const relevantChecklist = checklists.find(chk => chk.PK === row.PK && chk.SK === row.SK)
    if (!relevantChecklist) {
      console.error('No matching checklist found for row:', row.id)
      return
    }
    const indices = findIndices(relevantChecklist, row.id)
    if (!indices) {
      console.error('Indices not found for row:', row.id)
      return
    }
    // Create an update object that includes the new assigned user.
    const updatePayload = {
      PK: row.PK,
      SK: row.SK,
      itemId: row.id,
      newAssignedUser: selectedUser,
      fieldName: 'assignedUser',
      sectionIndex: indices.sectionIndex,
      itemIndex: indices.itemIndex,
    }
    // Update pending checklist updates state.
    setPendingChecklistUpdates(prev => {
      // Remove any existing update for the same row.
      const filtered = prev.filter(update => update.itemId !== row.id)
      return [...filtered, updatePayload]
    })

    // Also, update the local checklists state optimistically.
    setChecklists(prev =>
      prev.map(chk =>
        chk.PK === row.PK && chk.SK === row.SK
          ? {
              ...chk,
              sections: chk.sections.map((section, sIndex) => ({
                ...section,
                items: section.items.map((item, iIndex) =>
                  item.id === row.id ? { ...item, assignedUser: selectedUser } : item
                ),
              })),
            }
          : chk
      )
    )
  }
  const formatDecimal = decimal => Math.round(decimal * 100)

  const handleAddTaskComment = async (selectedTask, newNoteObj) => {
    if (!selectedTask || !newNoteObj) return

    // Append the new note object to the existing comments array
    const updatedComments = [{ ...newNoteObj, context: selectedTask?.title }, ...(selectedTask.comments || [])]

    // Use your findIndices helper to get the correct section and item indices.
    const indices = findIndices(generalChecklist[0], selectedTask.id)
    if (!indices) {
      console.error('Could not determine indices for item', selectedTask.id)
      return
    }

    const updatePayload = {
      PK: generalChecklist[0].PK,
      SK: generalChecklist[0].SK,
      itemId: selectedTask.id,
      fieldName: 'comments', // specifying a comment update
      newValue: updatedComments,
      sectionIndex: indices.sectionIndex,
      itemIndex: indices.itemIndex,
    }

    console.log('Update payload for comments:', updatePayload)

    batchUpdateAsync([updatePayload], {
      onSuccess: () => {
        showSnackbar('Task comment added successfully', 'success')
      },
      onError: err => {
        console.error('Failed to add task comment:', err)
        showSnackbar('Failed to add task comment', 'error')
      },
    })
  }
  const handleCreateTaskCommentPayload = (userData, location, selectedTask) => {
    console.log('[handleCreateTaskCommentPayload] triggered')
    const payload = createTaskMentionPayload(
      userData,
      'mention',
      'success',
      'Mention',
      `You've been tagged in a task message`,
      context,
      selectedTask?.title,
      generalChecklist?.[0]?.PK,
      generalChecklist?.[0]?.SK,
      '',
      location
    )
    console.log('payload', payload)
    return payload
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose} // ✅ Now using handleClose
      PaperProps={{
        sx: {
          width: '85%',
          overflow: 'hidden',
          height: '100vh',
          pt: pinned ? '60px' : 0.8,
          transition: 'all 0.3s ease',
        },
      }}
    >
      <Box>
        <ExpandCollapseButton
          expanded={expanded}
          setExpanded={setExpanded}
          displayElement={
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={4}>
                <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'left' }}>
                  {organization?.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {/* <InputLabel>
                  {location?.pathname.includes('projectinfo') ? 'Project Progress' : 'Creative Progress'}
                </InputLabel>
                <ProgressBar
                  progress={
                    location?.pathname.includes('projectinfo')
                      ? formatDecimal(completedCounts?.websiteprogress)
                      : formatDecimal(completedCounts?.creativeprogress)
                  }
                /> */}
                <InputLabel>{'Creative Progress'}</InputLabel>
                <ProgressBar progress={formatDecimal(completedCounts?.creativeprogress)} />
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ display: 'flex', gap: 0.8, width: '100%', justifyContent: 'flex-end' }}>
                  <DisplayChannels deal={deal} checklistProgress={completedCounts} />
                </Box>
              </Grid>
            </Grid>
          }
        >
          <BoardsGridHeader newDeal={deal} organization={organization} />
        </ExpandCollapseButton>

        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', mb: 0 }}>
          <Tab label={<Typography>Main</Typography>} />
          <Tab label={<Typography>Project Info</Typography>} />
          <Tab label={<Typography>Review</Typography>} />
          <Tab label={<Typography>Notes</Typography>} />
          <Tab label={<Typography>Checklists</Typography>} />
          <Tab label={<Typography>File Mgmt</Typography>} />
          <Tab label={<Typography>Tasks By Organization</Typography>} />
        </Tabs>

        {/* Tab Content */}
        <Box sx={{ height: 'calc(100vh - 120px)' }}>
          {activeTab === 0 && (
            <CreativeDrawerTasksGrid
              deal={deal}
              handleStatusChange={handleStatusChange}
              handleAssignedUserChange={handleAssignedUserChange}
              updates={pendingDealUpdates}
              setUpdates={setPendingDealUpdates}
              checklists={generalChecklist}
              expanded={expanded}
              checklistProgress={completedCounts}
              checklistType={'General Tasks'}
              context={'creative'}
            />
          )}
          {activeTab === 1 && (
            <CreativeFields
              deal={deal}
              organization={organization}
              formData={fieldUpdates}
              setFormData={setFieldUpdates}
              expanded={expanded}
            />
          )}
          {activeTab === 2 && <ReviewDeal moduleLocation={'boardsDrawer'} isHeaderExpanded={expanded} />}
          {activeTab === 3 && (
            <CommentsInput
              expanded={expanded}
              context={context}
              checklistItems={generalChecklist?.[0]?.sections?.[0]?.items || []}
              onAddTaskComment={handleAddTaskComment}
              onCreateTaskCommentPayload={handleCreateTaskCommentPayload}
            />
          )}

          {activeTab === 4 && !isDealLoading && (
            <CreativeChecklists
              deal={deal}
              formData={fieldUpdates}
              setFormData={setFieldUpdates}
              handleStatusChange={handleStatusChange}
              checklists={creativeChecklistsComplete}
              isLoading={isChecklistsLoading}
              expanded={expanded}
              context={context}
            />
          )}
          {activeTab === 5 && (
            <FileManagement newDeal={deal} organization={organization} dealFolder={safeDealFolders} />
          )}
          {activeTab === 6 && <TasksByOrganization />}
        </Box>
      </Box>
    </Drawer>
  )
}

export default CreativeDrawerComponent
