import React, { useState, useEffect, useMemo } from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGridPro, GridColDef, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { gridContainerStyles } from '../NCO/style'
import MetricsToolbar from './MetricsToolbar'
import TotalsFooter from './TotalsFooter.tsx'
import { useEnrichedOrganizations, Organization } from '../../api/aws/useEnrichedOrganizations.ts'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'
import { computeMonthlyMetrics, computeOverallGrossTotals } from './computeMonthlyMetrics.ts'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import { renderServiceIcon } from './renderServiceIcon.ts'
import PivotTable from './PivotTable.tsx'
import { useProductsTable } from '../../api/aws/products/useProductsTable.ts'

const initialSortModel: GridSortModel = [
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
]

const MetricsDataGrid: React.FC = () => {
  const theme = useTheme()
  const { pinned } = useLayoutContext()
  const { showSnackbar } = useSnackbarContext()
  const { data: productsTable, isLoading: isProductsTableLoading } = useProductsTable(true)
  // Selected month in "YYYY-MM" format; empty string means no monthly filter.
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  // Fetch enriched organizations (overall data from the backend)
  const { enrichedOrganizations, isEnrichedOrganizationsLoading } = useEnrichedOrganizations(true)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  const [rowCount, setRowCount] = useState(0)
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'name', sort: 'asc' }])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('metricColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          org_uuid: false,
          affiliated: false,
          source: false,
          street: false,
          city: false,
          state: false,
          zip: false,
          cmsUsed: false,
          social: false,
          dealIds: false,
        }
  })

  const processedOrganizations = useMemo(() => {
    if (!enrichedOrganizations) return []
    return enrichedOrganizations.map(org => {
      // Clone the organization
      const newOrg = { ...org }

      // If no month filter is applied, merge overall aggregatedMetrics from backend.
      if (!selectedMonth) {
        if (org.aggregatedMetrics && Array.isArray(org.aggregatedMetrics)) {
          org.aggregatedMetrics.forEach(metricObj => {
            const key = Object.keys(metricObj)[0]
            newOrg[key] = metricObj[key]
          })
        }
      } else {
        // When a month is selected, override the totals using the computed monthly metrics.
        if (org.deals && Array.isArray(org.deals) && org.deals.length > 0) {
          const monthlyMetrics = computeMonthlyMetrics(org, selectedMonth)
          // Replace any overall values with the monthly ones.
          Object.keys(monthlyMetrics).forEach(service => {
            newOrg[service] = monthlyMetrics[service]
          })
          newOrg['monthlyAggregatedMetrics'] = monthlyMetrics
        } else if (org.monthlyAggregatedMetrics) {
          // If raw deals aren't available but backend provided monthly metrics, use those.
          Object.keys(org.monthlyAggregatedMetrics).forEach(service => {
            newOrg[service] = org.monthlyAggregatedMetrics[service]
          })
        }
      }
      return newOrg
    })
  }, [enrichedOrganizations, selectedMonth])
  useEffect(() => {
    if (processedOrganizations) {
      setRowCount(processedOrganizations?.length || 0)
    }
  }, [processedOrganizations])
  const grossTotals = useMemo(() => {
    return computeOverallGrossTotals(processedOrganizations, selectedMonth)
  }, [processedOrganizations, selectedMonth])
  // Build dynamic columns by scanning each processed organization's keys.
  const dynamicColumns = useMemo(() => {
    const union = new Set<string>()
    processedOrganizations.forEach(org => {
      Object.keys(org).forEach(key => {
        if (
          !['org_uuid', 'name', 'deals', 'aggregatedMetrics', 'monthlyAggregatedMetrics'].includes(key) &&
          typeof org[key] === 'number'
        ) {
          union.add(key)
        }
      })
    })
    const filteredKeys = Array.from(union).filter(key => (grossTotals[key] || 0) !== 0)
    return filteredKeys.map(key => ({
      field: key,
      headerName: key,
      renderHeader: () => {
        return (
          <Box>
            <img src={renderServiceIcon(key)} alt='seoColumnIcon' style={{ height: 28, width: 28 }} />
          </Box>
        )
      },
      width: 75,
      flex: 1,
      type: 'number',
      valueGetter: params => params || 0,
      // Optionally format the number as currency.
      renderCell: params => (params?.value > 0 ? formatCurrency(params?.value) : '–'),
    })) as GridColDef[]
  }, [processedOrganizations])

  // Define base columns.
  const baseColumns: GridColDef[] = [
    { field: 'name', headerName: 'Organization Name', width: 150 },
    {
      field: 'deals',
      headerName: 'Deals Count',
      width: 150,
      valueGetter: params => params?.length || 0,
    },
    { field: 'contractedBy' },
  ]
  const columns: GridColDef[] = [...baseColumns, ...dynamicColumns]

  // Compute overall gross totals from the processed organizations,
  // so that if a month is selected, it sums from the monthlyAggregatedMetrics.

  // useEffect(() => {
  //   if (!isProductsTableLoading && productsTable) {
  //     console.log('productsTable', productsTable)
  //   }
  // }, [productsTable])
  return (
    <Box
      sx={{
        position: 'relative',
        ...gridContainerStyles,
        pt: pinned ? '60px' : 0,
        transition: 'all 0.3s ease-in-out',
        height: '98%',
      }}
    >
      <DataGridPro
        rows={processedOrganizations}
        columns={columns}
        loading={isEnrichedOrganizationsLoading}
        filterModel={filterModel}
        onFilterModelChange={newModel => setFilterModel(newModel)}
        sortModel={sortModel}
        onSortModelChange={newModel => setSortModel(newModel)}
        slots={{
          toolbar: MetricsToolbar,
          footer: TotalsFooter,
        }}
        slotProps={{
          toolbar: {
            filterModel,
            visibilityModel: columnVisibilityModel,
            sortModel,
            setFilterModel,
            onClearSorting: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              setSortModel([{ field: 'name', sort: 'asc' }])
            },
            onClearVisibility: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              const init = {
                org_uuid: false,
                affiliated: false,
                source: false,
                street: false,
                city: false,
                state: false,
                zip: false,
                cmsUsed: false,
                social: false,
                dealIds: false,
              }
              setColumnVisibilityModel(init)
              localStorage.setItem('metricColumnVisibilityModel', JSON.stringify(init))
            },
            monthFilterValue: selectedMonth,
            setMonthFilter: setSelectedMonth,
          },
          footer: {
            grossTotals: grossTotals,
            dynamicColumns: columns,
            rowCount: rowCount,
          },
        }}
        getRowId={row => row.org_uuid}
        sx={{
          '& .MuiDataGrid-cell': { paddingRight: 1 },
          '& .MuiDataGrid-columnHeaders': { borderBottom: 'none' },
        }}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default MetricsDataGrid
