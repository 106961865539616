import React from 'react'
import { Chip, useTheme, Box } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { Link as RouterLink } from 'react-router-dom'

const RenderContactChip = ({ contacts, justify, marginLeft }) => {
  const theme = useTheme()
  const maxVisibleContacts = 2
  const chipStyles = {
    height: 'auto',
    width: 'auto',
    borderColor: 'transparent',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255)' : 'rgba(0,0,0)',
    borderRadius: '4px',
  }

  return (
    <>
      {contacts.slice(0, maxVisibleContacts).map(contact => (
        <Box
          key={contact.contacts_uuid}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: justify ?? 'center',
            maxWidth: '100%',
            overflow: 'hidden',
            margin: 0,
            marginLeft: marginLeft ?? 0,
            padding: 0.5,
          }}
        >
          <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
          <Chip
            label={
              <RouterLink
                to={`/contacts/${contact.contacts_uuid}/details`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {contact.name}
              </RouterLink>
            }
            size='small'
            style={chipStyles}
          />
        </Box>
      ))}
    </>
  )
}

export default RenderContactChip
