import React, { useMemo } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Box, useTheme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FieldComponent from '../Forms/FieldComponent.tsx'
import { FormConfig, creativeTaskTypeLabels } from '../Forms/types.ts'
import { fieldStyles } from '../Creative/style.js'

const WebsiteFields = ({ deal, formData, setFormData, expanded }) => {
  const theme = useTheme()

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  // Group field configurations by creative type.
  const groupedFields = useMemo(() => {
    if (deal?.creativeType && Array.isArray(deal.creativeType) && deal.creativeType.length > 0) {
      return deal.creativeType.reduce((acc, type) => {
        if (FormConfig[type]) {
          acc[type] = Object.entries(FormConfig[type])
        }
        return acc
      }, {})
    }
    return {}
  }, [deal])
  console.log(groupedFields)

  return (
    <Box
      sx={{
        maxHeight: expanded ? 'calc(100vh - 310px)' : 'calc(100vh - 200px)', // adjust this value as needed
        overflowY: 'auto',
        pr: 1, // optional: add some padding for the scrollbar
      }}
    >
      <Grid container spacing={2} p={2}>
        {/* <Grid item xs={12}>
          <Typography variant='h6' sx={{ color: theme.palette.text.primary }}>
            {deal?.creativeType && deal.creativeType.length > 0
              ? `Creative Fields: ${deal.creativeType.map(type => creativeTaskTypeLabels[type] || type).join(', ')}`
              : 'Creative Fields'}
          </Typography>
        </Grid> */}
        {Object.entries(groupedFields).map(([type, fields]) => {
          console.log('type', type)
          return (
            <Grid item xs={12} key={type}>
              {type === 'website' ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${type}-content`}
                    id={`${type}-header`}
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                      {creativeTaskTypeLabels[type] || type.charAt(0).toUpperCase() + type.slice(1)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: theme.palette.background.details, p: 1 }}>
                    <Grid container spacing={2}>
                      {fields.map(([key, config], index) => {
                        return (
                          <Grid item xs={12} sm={6} key={`${type}-${key}-${index}`}>
                            <FieldComponent
                              name={key}
                              value={formData[key] || deal[key] || ''}
                              onChange={e => handleInputChange(key, e.target.value)}
                              {...config}
                              sx={fieldStyles(theme.palette.background.default)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : type === 'websiteDesignOverview' ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${type}-content`}
                    id={`${type}-header`}
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                      {creativeTaskTypeLabels[type] || type.charAt(0).toUpperCase() + type.slice(1)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: theme.palette.background.details, p: 1 }}>
                    <Grid container spacing={2}>
                      {fields.map(([key, config], index) => {
                        return (
                          <Grid item xs={12} sm={6} key={`${type}-${key}-${index}`}>
                            <FieldComponent
                              name={key}
                              value={formData[key] || deal[key] || ''}
                              onChange={e => handleInputChange(key, e.target.value)}
                              {...config}
                              sx={fieldStyles(theme.palette.background.default)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : type === 'websiteStructure' ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${type}-content`}
                    id={`${type}-header`}
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                      {creativeTaskTypeLabels[type] || type.charAt(0).toUpperCase() + type.slice(1)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: theme.palette.background.details, p: 1 }}>
                    <Grid container spacing={2}>
                      {fields.map(([key, config], index) => {
                        return (
                          <Grid item xs={12} sm={6} key={`${type}-${key}-${index}`}>
                            <FieldComponent
                              name={key}
                              value={formData[key] || deal[key] || ''}
                              onChange={e => handleInputChange(key, e.target.value)}
                              {...config}
                              sx={fieldStyles(theme.palette.background.default)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default WebsiteFields
