import React, { useContext } from 'react'
import { IconButton, useTheme } from '@mui/material'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { ThemeContext } from '../../../themeProviders/ThemeContext'
const ToggleThemeNav = () => {
  const { toggleTheme } = useContext(ThemeContext) // Access theme and toggleTheme

  const theme = useTheme()
  return (
    <>
      {theme.palette.mode === 'dark' ? (
        <IconButton
          onClick={toggleTheme}
          sx={{
            p: 0,
            borderRadius: 0,
            minWidth: 50, // or 60, adjust to taste
            minHeight: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LightModeIcon
            sx={{
              height: 22,
              width: 22,
            }}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={toggleTheme}
          sx={{
            p: 0,
            borderRadius: 0,
            minWidth: 50, // or 60, adjust to taste
            minHeight: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DarkModeIcon
            sx={{
              height: 22,
              width: 22,
            }}
          />
        </IconButton>
      )}
    </>
  )
}

export default ToggleThemeNav
