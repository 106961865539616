import {
  Box,
  Chip,
  IconButton,
  Typography,
  Tooltip,
  Link as MuiLink,
  Avatar,
  Select,
  MenuItem,
  TextField,
  AvatarGroup,
} from '@mui/material'
import dayjs from 'dayjs'
import { GridStatusChip, GridStageChip, NcoStatusContainer, NcoChannelContainer } from '../../../style/styleElements'
import { Link } from 'react-router-dom'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles } from '../style'
import RenderVertical from '../../Organizations/RenderVertical'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import RenderContractedByColumn from '../RenderContractedByColumn'
import { formatCurrency } from '../../DealsKanban/aggregateStageTotals'
import StrategyProgress from '../../Icons/StrategyProgress'
import PandaDocViewer from '../../PaymentSchedule/PandDocViewer'
import EditSelectCell from '../../NCO/EditSelectCell'
import EditableDatePicker from '../../NCO/EditableDatePicker'
import { renderColorByString } from '../renderColorByString'
import MetaLightSvg from '../../../images/icons/meta (1).svg'
import GoogleDisplaySvg from '../../../images/icons/Google Display.png'
import GoogleSearchSvg from '../../../images/icons/Google Ads.png'
import LinkedInSvg from '../../../images/icons/linkedin.svg'
import MicrosoftSvg from '../../../images/icons/bing.png'
import SimplifiSvg from '../../../images/icons/simplifi_logo.svg'
import SnapchatSvg from '../../../images/icons/snapchat.svg'
import SpotifySvg from '../../../images/icons/spotify.svg'
import TikTokSvg from '../../../images/icons/tik-tok.png'
import YoutubeSvg from '../../../images/icons/youtube.svg'
import Seo1Svg from '../../../images/icons/seotier1.svg'
import Seo2Svg from '../../../images/icons/seotier2.svg'
import Seo3Svg from '../../../images/icons/seotier3.svg'
import Seo4Svg from '../../../images/icons/seo.png'
import { formatDateString } from '../../Tasks/taskUtil'
import { customNotContainsOperator, stringAvatar } from '../gridOperators'
import { ncoStatusFilters } from '../filterArrays'
import { formatDayjsModifiedTime } from '../../../utility/formatDayjsDateTime'
import SeoDisplayIcon from '../../ui/seo-display-icon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import GoogleSearchIcon from '../../../images/icons/google-search-icon.svg'
import CircularStatusBar from '../components/CircularStatusBar.tsx'
import GiteIcon from '@mui/icons-material/Gite'

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const boxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
}
const leftBoxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  height: '100%',
}
export const renderWebsiteColumns = (
  organizations,
  users,
  deals,
  density = 'standard',
  handleDealClick,
  handleUserChange,
  selectionModel,
  handleSelectUserRow
) => {
  const handleClickEditStart = params => {
    if (selectionModel?.length <= 1) {
      params.api.startCellEditMode({
        id: params.id,
        field: params.field,
      })
    } else {
      return
    }
  }
  const roleArray = ['AM', 'PM', 'DMS', 'MIA', 'QA']

  const channelsArray = [
    {
      field: 'isGoogleDisplay',
      headerName: 'Google Display',
      src: GoogleDisplaySvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    {
      field: 'isGoogleSearch',
      headerName: 'Google Search',
      src: GoogleSearchSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    { field: 'isLinkedIn', headerName: 'LinkedIn', src: LinkedInSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    {
      field: 'isMetaAdvertising',
      headerName: 'Meta Advertising',
      src: MetaLightSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    {
      field: 'isMicrosoftAdvertising',
      headerName: 'Microsoft',
      src: MicrosoftSvg,
      type: 'srcIcon',
      width: 30,
      flex: 0.5,
    },
    { field: 'isSimplifi', headerName: 'Simplifi', src: SimplifiSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSnapchat', headerName: 'Snapchat', src: SnapchatSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSpotify', headerName: 'Spotify', src: SpotifySvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isTikTok', headerName: 'TikTok', src: TikTokSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isYoutube', headerName: 'Youtube', src: YoutubeSvg, type: 'srcIcon', width: 30, flex: 0.5 },
    // { field: 'seoTier', headerName: 'SEO', src: GoogleSearchIcon, type: 'srcIcon', width: 30, flex: 0.5 },
    { field: 'isSeo1', headerName: 'SEO - Tier 1', tier: 1, src: Seo1Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo2', headerName: 'SEO - Tier 2', tier: 2, src: Seo2Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo3', headerName: 'SEO - Tier 3', tier: 3, src: Seo3Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    { field: 'isSeo4', headerName: 'SEO - Tier 4', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isDroneVideoAdd', headerName: 'isDroneVideoAdd', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isDroneShootAdd', headerName: 'isDroneShootAdd', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isVideoEdit', headerName: 'isVideoEdit', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isVideoShoot', headerName: 'isVideoShoot', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isDesignMaterials', headerName: 'isDesignMaterials', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isFullBrandBuild', headerName: 'isFullBrandBuild', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isSystemsKit', headerName: 'isSystemsKit', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isBrandingGuide', headerName: 'isBrandingGuide', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isPropertyNaming', headerName: 'isPropertyNaming', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isMarketingCollateral', headerName: 'isMarketingCollateral', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isLogoCreation', headerName: 'isLogoCreation', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isAddtlLogoRevisions', headerName: 'isAddtlLogoRevisions', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isRevisions', headerName: 'isRevisions', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isGraphicDesignGeneral', headerName: 'isGraphicDesignGeneral', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isTravelLodging', headerName: 'isTravelLodging', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isStagingEquipment', headerName: 'isStagingEquipment', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isProjectedCreativeBudget', headerName: 'isProjectedCreativeBudget', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isTalentModels', headerName: 'isTalentModels', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isLifestyleShoot', headerName: 'isLifestyleShoot', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isPhotoShoot', headerName: 'isPhotoShoot', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isPhotoEdit', headerName: 'isPhotoEdit', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isPremiumBuild', headerName: 'isPremiumBuild', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isStandardBuild', headerName: 'isStandardBuild', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
    // { field: 'isEcommerceBuild', headerName: 'isEcommerceBuild', tier: 4, src: Seo4Svg, type: 'seoIcon', width: 30, flex: 0.5 },
  ]

  const renderChannels = () =>
    channelsArray?.map(channel => ({
      field: channel?.field,
      headerName: channel?.headerName,
      renderHeader: () => {
        return (
          <>
            {channel?.type === 'srcIcon' ? (
              <Tooltip title={channel?.headerName}>
                <img src={channel?.src} alt={channel?.headerName} style={{ height: 24 }} />
              </Tooltip>
            ) : (
              <SeoDisplayIcon title={channel?.headerName} tier={channel?.tier} />
            )}
          </>
        )
      },
      width: 150,
      flex: 0.5,
      headerAlign: 'center',
      disableColumnMenu: true,
      valueGetter: params => {
        return params ? 'Yes' : 'No'
      },
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            cursor: 'pointer',
            // bgcolor: renderColorByString(params.value),
          }}
        >
          {params?.value === 'Yes' ? (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <CheckCircleIcon sx={{ height: '100%', color: renderColorByString(params.value) }} />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <CheckCircleIcon sx={{ height: '100%', color: 'rgb(96, 94, 94)', opacity: '0.2' }} />
            </Box>
          )}
        </Box>
      ),
    }))

  const densityStyles = getDensityStyles(density)
  return [
    {
      field: 'organizations',
      headerName: 'Organization',
      width: 100,
      flex: 1.45,
      headerAlign: 'left',
      valueGetter: params => {
        const orgId = params
          ? Array.isArray(params)
            ? params[0]
            : typeof params === 'object' && params.id
            ? params.id
            : null
          : null
        const arrayOrg = organizations.find(org => org.id === orgId) || organizations.find(org => org.zoho_id === orgId)
        return arrayOrg ? arrayOrg.name : ''
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const orgId =
          params &&
          params.row &&
          params.row.organizations &&
          Array.isArray(params.row.organizations) &&
          params.row.organizations[0]
            ? params.row.organizations[0]
            : params && params.row && params.row.organizations && params.row.organizations.id
            ? params.row.organizations.id
            : null
        const photoUrl = organizations ? organizations.find(org => org.id === orgId)?.photoUrl : null
        const orgName = params && params.value ? params.value : ''

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            onClick={() => handleDealClick(params.row)}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                height: '100%',
                width: '100%',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              // onClick={() => handleDealClick(params.row)}
            >
              {photoUrl ? (
                <Box
                  sx={{
                    ...leftBoxStyles,
                    gap: 1,
                  }}
                >
                  <Avatar
                    src={photoUrl}
                    sx={{
                      width: densityStyles.avatarSize,
                      height: densityStyles.avatarSize,
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    alt={params && params.value ? params.value : params}
                  />

                  <Typography
                    sx={{
                      fontSize: densityStyles.fontSize,
                      ...boxStyles,
                    }}
                  >
                    {params && params.value ? params.value : params}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    ...leftBoxStyles,
                    gap: 1,
                  }}
                >
                  <Avatar
                    {...stringAvatar(params && params.value ? params.value : params, densityStyles)}
                    sx={{
                      backgroundColor: 'rgb(114, 229, 218)',
                      width: densityStyles.avatarSize,
                      height: densityStyles.avatarSize,
                    }}
                    alt={params && params.value ? params.value : params}
                  />
                  <Typography
                    sx={{
                      fontSize: densityStyles.fontSize,
                      ...boxStyles,
                    }}
                  >
                    {params && params.value ? params.value : params}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* </Link> */}
          </Box>
        )
      },
    },
    {
      field: 'completedCounts',
      headerName: 'Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.creativeprogress ? Math.round(params?.creativeprogress * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'websiteProgress',
      headerName: 'Website Progress',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params ? Math.round(params * 100) : 0
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularStatusBar progress={params?.value || 0} />
          </Box>
        )
      },
    },
    {
      field: 'onboardingStatus',
      headerName: 'Onboarding Status',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params ?? 'Staging'
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
              bgcolor: renderColorByString(params.value),
            }}
            onClick={handleClick}
          >
            <NcoStatusContainer
              size='small'
              type={params.value}
              sx={{ bgcolor: renderColorByString(params.value), textAlign: 'center' }}
            >
              {params.value}
            </NcoStatusContainer>
          </Box>
        )
      },
      renderEditCell: params => <EditSelectCell params={params} options={ncoStatusFilters} />,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <GridStatusChip size='small' type={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'timeCompleted',
      headerName: 'Date Closed',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {params && params.value ? (
              params.value
            ) : (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'buildTier',
      headerName: 'Build',
      renderHeader: () => {
        return (
          <Box>
            <GiteIcon />
          </Box>
        )
      },
      width: 75,
      flex: 0.55,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.label ? params?.label : ''
      },
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {params.value ? (
              <NcoStatusContainer sx={{ bgcolor: renderColorByString(params?.value), textAlign: 'center' }}>
                {params?.value}
              </NcoStatusContainer>
            ) : (
              <CheckCircleIcon sx={{ height: '100%', color: 'rgb(96, 94, 94)', opacity: '0.2' }} />
            )}
          </Box>
        )
      },
    },

    ...renderChannels(),
    {
      field: 'dependenciesDueDate',
      headerName: 'Dependencies Due',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            {params && params.value ? params.value : 'Set Date'}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }

        return <EditableDatePicker value={params.value} onChange={handleChange} />
      },
    },
    {
      field: 'slaDays',
      headerName: 'SLA Days',
      width: 100,
      flex: 0.8,
      headerAlign: 'center',
      editable: true,
      valueGetter: params => {
        return params || 0 // Default to 0 if no value is set
      },
      renderCell: params => {
        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={handleClick}
          >
            {params.value ?? 'Set SLA Days'} {/* Display a default message if no value */}
          </Box>
        )
      },
      renderEditCell: params => {
        const handleChange = event => {
          const value = event.target.value ? parseInt(event.target.value, 10) : 0 // Parse the value as an integer
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: Math.min(100, Math.max(0, value)), // Clamp the value between 0 and 100
          })
        }

        return (
          <TextField
            type='number'
            value={params.value ?? 0} // Default value if not set
            onChange={handleChange}
            fullWidth
            autoFocus
            inputProps={{
              min: 0,
              max: 100,
              step: 1, // Control step increment
            }}
            sx={{
              textAlign: 'center', // Center the text in the input
            }}
          />
        )
      },
    },
    {
      field: 'estLaunchDate',
      headerName: 'Estimated Launch Date',
      width: 100,
      flex: 0.65,
      valueGetter: params => {
        const estLaunchDate = params
        return estLaunchDate ? dayjs(estLaunchDate).format('MM/DD/YYYY') : null
      },
      renderCell: params => {
        const estLaunchDate = params.value
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          >
            {estLaunchDate || (
              <Typography
                sx={{
                  fontSize: densityStyles.fontSize,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                {'—'}
              </Typography>
            )}
          </Box>
        )
      },
    },
    {
      field: 'dateLaunched',
      headerName: 'Date Launched',
      width: 100,
      flex: 0.65,
      editable: true,
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },

      renderCell: params => {
        const dateLaunched = params?.row?.dateLaunched ? formatDateString(params?.row?.dateLaunched) : null

        const handleClick = () => {
          params.api.startCellEditMode({
            id: params.id,
            field: params.field,
          })
        }

        return (
          <>
            {params && params.value ? (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {params.value}
              </Box>
            ) : dateLaunched ? (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {dateLaunched}
              </Box>
            ) : (
              <Box
                sx={{
                  ...boxStyles,
                }}
                onClick={handleClick}
              >
                {'Set Date Launched'}
              </Box>
            )}
          </>
        )
      },
      renderEditCell: params => {
        const dateLaunched = params?.row?.dateLaunched ?? null
        const handleChange = newValue => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.toISOString() : null, // Save as ISO string
          })
        }
        return <EditableDatePicker value={params.value || dateLaunched} onChange={handleChange} />
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => {
        return params ? formatDateString(params) : null
      },
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {params && params.value ? params.value : ''}
          </Box>
        )
      },
    },
    {
      field: 'contractId',
      headerName: 'Contract',
      width: 100,
      flex: 0.45,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        const previewUrl = `https://app.pandadoc.com/a/#/documents`
        const contractId = params && params.value ? params.value : null
        const contractUrl = previewUrl && contractId ? `${previewUrl}/${contractId}` : null
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {contractUrl ? (
              <PandaDocViewer contractId={contractId} height={32} width={32} />
            ) : (
              <PandaDocViewer contractId={contractId} height={32} width={32} disabled={true} />
            )}
          </Box>
        )
      },
    },

    {
      field: 'contractedBy',
      headerName: 'Contracted By',
      width: 100,
      flex: 1.15,
      headerAlign: 'left',
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      valueGetter: params => {
        const contractedById = params && Array.isArray(params) ? params[0] : []
        const contractedBy = organizations ? organizations.find(org => org.id === contractedById) : null
        return contractedBy ? contractedBy.name : ''
      },
      renderCell: params => {
        const orgId =
          params?.row?.organizations && Array.isArray(params.row.organizations) && params.row.organizations[0]
            ? params.row.organizations[0]
            : null
        const org = organizations ? organizations.find(org => org.id === orgId) : null
        const contractedById = org && Array.isArray(org.contractedBy) ? org.contractedBy[0] : null

        return (
          <>
            {params && params.value ? (
              <RenderContractedByColumn
                key={`${contractedById}`}
                orgName={params.value ?? ''}
                orgId={contractedById}
                densityStyles={densityStyles}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  padding: densityStyles.padding,
                  gap: densityStyles.gap,
                }}
              ></Box>
            )}
          </>
        )
      },
      sortComparator: (a, b) => {
        if (!a && !b) return 0 // Both are empty
        if (!a) return 1 // Treat empty as larger, so a (empty) goes after b (non-empty)
        if (!b) return -1 // Treat empty as larger, so b (empty) goes after a (non-empty)
        return a.localeCompare(b) // Standard string comparison for non-empty values
      },
    },

    {
      field: 'strategy',
      headerName: 'Strategy',
      width: 100,
      flex: 0.55,
      headerAlign: 'left',
      sortable: true,
      valueGetter: params => {
        return params?.progress ?? ''
      },
      renderCell: params => {
        const status = params.row.status
        const deal = params && params.row ? params.row : null
        const dealId = deal.id
        const orgId = deal.organizations && deal.organizations.length > 0 ? deal.organizations[0] : null
        const selectedOrganization = organizations.find(org => org.id === orgId)
        const selectedVerticals = selectedOrganization ? selectedOrganization.vertical : []
        return (
          <Link
            to={`/deals/${dealId}/strategy`}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
            }}
          >
            <StrategyProgress dealId={dealId} size={densityStyles.globeSize} />
          </Link>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Document Status',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStatusChip
              size='small'
              type={params?.value || ''}
              label={capitalizeFirstLetter(params?.value || '')}
            />
          </Box>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Vertical',
      flex: 0.7,
      headerAlign: 'center',
      sortable: false,
      valueGetter: params => {
        const deal = deals.find(deal => deal.deal_uuid === params)
        const orgId = deal && Array.isArray(deal.organizations) && deal.organizations[0] ? deal.organizations[0] : []
        const organization = organizations.find(org => org.org_uuid === orgId)
        const verticals = organization && Array.isArray(organization.vertical) ? organization.vertical : []
        const verticalParams = verticals && Array.isArray(verticals) ? verticals[0] : []
        return verticalParams
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RenderVertical
              vertical={params.value}
              width={densityStyles.verticalWidth}
              height={densityStyles.verticalHeight}
              size={densityStyles.verticalSize}
            />
          </Box>
        )
      },
    },

    {
      field: 'stage',
      headerName: 'Deal Stage',
      width: 100,
      flex: 1,
      headerAlign: 'center',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            <GridStageChip size='small' stage={params.value} label={params.value} />
          </Box>
        )
      },
    },
    {
      field: 'lastModified',
      headerName: 'Modified',
      width: 100,
      flex: 1,
      headerAlign: 'left',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatDayjsModifiedTime(params && params.value ? params.value : 'No Modified Time')}
          </Box>
        )
      },
    },
    {
      field: 'hostingTier',
      headerName: 'Hosting',
      // renderHeader: () => {
      //   return (
      //     <Box>
      //       <img src={GoogleSearchIcon} alt='seoColumnIcon' style={{ height: 28, width: 28 }} />
      //     </Box>
      //   )
      // },
      width: 100,
      flex: 0.4,
      headerAlign: 'center',
      valueGetter: params => {
        return params && params?.label ? params?.label : ''
      },
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {params.value ? (
              <NcoStatusContainer sx={{ bgcolor: renderColorByString(params?.value), textAlign: 'center' }}>
                {params?.value}
              </NcoStatusContainer>
            ) : (
              <CheckCircleIcon sx={{ height: '100%', color: 'rgb(96, 94, 94)', opacity: '0.2' }} />
            )}
          </Box>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const userId = !Array.isArray(params) ? params : Array.isArray(params) ? params[0] : []
        const user = users.find(u => u.id === userId)
        return user && user.name ? user.name : 'No User Assigned'
      },
      renderCell: params => {
        const userId = !Array.isArray(params?.row?.owner) ? params.row.owner : params.row.owner[0]
        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <Avatar sx={{ opacity: 0.1 }} />
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'implementationFee',
      headerName: 'Impl. Fee',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {params.value}
          </Box>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      valueGetter: params => params,
      renderCell: params => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {formatCurrency(params.value)}
          </Box>
        )
      },
    },
    {
      field: 'creativeTeam',
      headerName: 'Team',
      flex: 0.1,
      minWidth: 100,
      headerAlign: 'center',
      valueGetter: params => {
        const usersArray = Array.isArray(params) ? params : []
        return usersArray.map(user => user.name).join(', ') // Returns names as a string
      },
      renderCell: params => {
        const values = Array.isArray(params?.row?.creativeTeam) ? params?.row?.creativeTeam : []
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexWrap: 'wrap',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
            // onClick={() => handleClickEditStart(params)}
            // onClick={() => handleSelectUserRow(params?.rowNode?.id, role)}
          >
            <AvatarGroup
              max={densityStyles.avatarMax}
              spacing={densityStyles.avatarSpacing}
              slotProps={{
                additionalAvatar: { sx: { width: densityStyles.avatarSize, height: densityStyles.avatarSize } },
              }}
            >
              {values.length > 0 ? (
                values.map((user, index) => (
                  <Tooltip key={`${user.user_uuid || user.id} - ${index}`} title={user?.role}>
                    <Avatar
                      key={`${user.user_uuid || user.id} - ${index}`}
                      src={user.image_512}
                      sx={{ width: densityStyles.avatarSize, height: densityStyles.avatarSize }}
                    />
                  </Tooltip>
                ))
              ) : (
                <>
                  <Avatar sx={{ opacity: 0.1 }} />
                  <Avatar sx={{ opacity: 0.1 }} />
                  <Avatar sx={{ opacity: 0.1 }} />
                </>
              )}
            </AvatarGroup>
          </Box>
        )
      },
    },

    // ...renderMarketingTeamColumns(
    //   roleArray,
    //   users,
    //   selectionModel,
    //   handleUserChange,
    //   densityStyles,
    //   handleClickEditStart,
    //   handleSelectUserRow
    // ),
  ]
}
export default renderWebsiteColumns
/////// renderDataGridColumns:
// 'organizations',
// 'onboardingStatus',
// 'type',
// 'timeCompleted',
// 'dependenciesDueDate',
// 'slaDays',
// 'estLaunchDate',
// 'dateLaunched',
// 'startDate',
// 'endDate',
// 'contractId',
// 'contractedBy',
// 'strategy',
// 'status',
// 'id',
// 'stage',
// 'lastModified',
// 'products',
// 'owner',
// 'implementationFee',
// 'total',

////// renderNcoColumns:
// 'organizations',
// 'onboardingStatus',
// 'type',
// 'timeCompleted',
// 'isGoogleDisplay',
// 'isGoogleSearch',
// 'isLinkedIn',
// 'isMetaAdvertising',
// 'isMicrosoftAdvertising',
// 'isSimplifi',
// 'isSnapchat',
// 'isSpotify',
// 'isTikTok',
// 'isYoutube',
// 'dependenciesDueDate',
// 'slaDays',
// 'estLaunchDate',
// 'dateLaunched',
// 'startDate',
// 'endDate',
// 'contractId',
// 'contractedBy',
// 'strategy',
// 'id',
// 'stage',
// 'lastModified',
// 'owner',
// 'implementationFee',
// 'total',
