// src/api/customHooks/useUploadTaskImages.ts
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../../queryMutations/mutationFunctions'

interface UploadTaskImagesVars {
  taskId: string
  PK: string
  SK: string
  formData: FormData
}

interface UploadTaskImagesResponse {
  urls: string[]
}

const uploadTaskImages = async ({ formData }: { formData: FormData }): Promise<UploadTaskImagesResponse> => {
  const url = `${baseUrl}/aws/s3/upload`
  console.log('Uploading images, formData:', formData)
  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response.data // Expected: { urls: string[] } (the merged imageUrls array)
}

const useUploadTaskImages = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  return useMutation<UploadTaskImagesResponse, unknown, UploadTaskImagesVars>({
    mutationFn: uploadTaskImages,
    onMutate: async ({ taskId, formData }) => {
      // Optionally, perform an optimistic update here.
    },
    onError: (err, variables, context) => {
      console.error('Upload Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: (data, variables) => {
      console.log('data', data, 'variables', variables)
      // queryClient.invalidateQueries(['task', task.PK, task.SK])

      showSnackbar('Images uploaded successfully', 'success')
    },
  })
}

export default useUploadTaskImages
