import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  useTheme,
  Grid,
  InputLabel,
  Button,
  FormControl,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIconButton from '../ui/save-icon-button'
import { formatDayJsDateTime } from '../DataGrid/createColumns/renderDealsColumns'
import DeleteIconButton from '../ui/delete-icon-button.tsx'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useDeleteOptimization } from '../../api/aws/optimizations/useDeleteOptimization.ts'
dayjs.extend(utc)
dayjs.extend(timezone)

export type Optimization = {
  name: string
  type: string
  channel: string
  description: string
  opts: number
  addtlNotes: string
  color: string
  qualifyingTier: string
  module: string
  followDate: string
}

export type SavedOptimizationProps = {
  optimization: Optimization
  addtlNotes: string
  onUpdate: (field: keyof Optimization, value: string | number) => void
}

const SavedOptimization: React.FC<SavedOptimizationProps> = ({ optimization, addtlNotes, onUpdate, onSave }) => {
  const theme = useTheme()
  const [localValue, setLocalValue] = useState(String(optimization.opts)) // Store as a string for user input
  const { mutate: deleteOptimization } = useDeleteOptimization()

  useEffect(() => {
    // Sync local state when optimization.opts changes externally
    setLocalValue(String(optimization.opts))
  }, [optimization.opts])

  const handleChange = e => {
    let value = e.target.value

    // Allow empty string for user input (so they can delete and retype)
    if (value === '') {
      setLocalValue('')
      return
    }

    // Ensure only valid numbers
    if (!/^\d+$/.test(value)) return

    let numValue = parseInt(value, 10)

    // Limit to range 0-10
    if (numValue < 0) numValue = 0
    if (numValue > 10) numValue = 10

    setLocalValue(String(numValue)) // Keep as string for controlled input
  }

  const handleBlur = () => {
    // Convert back to a number when the input loses focus
    const numValue = localValue === '' ? 0 : parseInt(localValue, 10)
    setLocalValue(String(numValue)) // Ensure field does not remain empty
    onUpdate('opts', numValue) // Send final number value
  }

  const handleSave = () => {
    // If you maintain updated fields locally (or via parent's state), pass them:
    onSave([{ PK: optimization?.PK, SK: optimization?.SK }], {
      ...optimization,
      opts: parseInt(localValue, 10), // use localValue for opts
      // If addtlNotes or any other fields are updated, ensure they are merged in as well
    })
  }

  const handleDelete = () => {
    deleteOptimization(
      { optPK: optimization.PK, optSK: optimization.SK, orgId: optimization.orgId },
      {
        onSuccess: () => {
          console.log('Optimization deleted successfully')
        },
        onError: error => {
          console.error('Error deleting optimization:', error)
        },
      }
    )
  }

  const border = optimization?.module === 'SEO' ? '2px solid rgb(238, 111, 141)' : '2px solid rgb(251, 193, 30)'
  return (
    <Accordion sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderLeft: border }}>
        <Grid
          container
          sx={{ display: 'flex', width: '100%', height: '100%', px: 2, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          <Grid item xs={3.5}>
            <Typography
              sx={{ flex: 1, textAlign: 'left', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {optimization.name}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <Typography sx={{ flex: 1, textAlign: 'right', color: optimization?.color }}>
              {optimization.type || optimization.channel}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ flex: 1, textAlign: 'right', color: theme.palette.text.secondary }}>
              {formatDayJsDateTime(optimization.createdAt)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              textAlign: 'right',
              flexDirection: 'row',
            }}
          >
            <Typography
              sx={{ textAlign: 'right', fontSize: 12, color: theme.palette.text.secondary }}
              variant='caption'
            >
              Opts
            </Typography>
            <Typography sx={{ flex: 0.3, textAlign: 'right', fontSize: 16 }} variant='caption'>
              {optimization.opts}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <DeleteIconButton hasErrors={false} onDelete={handleDelete} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: theme.palette.background.details }}>
        {/* Editable Opts Field */}
        <Box
          mt={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {/* <TextField
            sx={{ width: '85%' }}
            label='Opts'
            type='number'
            value={optimization.opts}
            onChange={e => {
              let value = e.target.value

              // If the input is "0" and user types another number, replace it instead of appending
              if (value.length > 1 && value.startsWith('0')) {
                value = value.slice(1)
              }

              let numValue = parseInt(value, 10)

              // Ensure the final value is a number between 0 and 10
              if (isNaN(numValue)) numValue = 0
              numValue = Math.max(0, Math.min(numValue, 10))

              onUpdate('opts', numValue)
            }}
            placeholder='# of Opts'
            inputProps={{ min: 0, max: 10 }}
          /> */}
          <TextField
            sx={{ width: optimization?.followDate ? '50%' : '85%' }}
            label='Opts'
            type='number'
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder='# of Opts'
            inputProps={{ min: 0, max: 10 }}
          />
          {optimization?.followDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  label='Follow Up Date'
                  // Convert the followDate string to a Dayjs instance if it exists, otherwise pass null
                  value={optimization.followDate ? dayjs(optimization.followDate) : null}
                  // When the user selects a new date, call onUpdate to update the followDate in the parent state
                  onChange={newDate => {
                    const dateString = newDate ? newDate.toISOString() : ''
                    onUpdate('followDate', dateString)
                  }}
                  slotProps={{
                    textField: { fullWidth: true },
                  }}
                />
              </FormControl>
            </LocalizationProvider>
          )}

          {optimization && <SaveIconButton hasErrors={false} onSave={handleSave} />}
        </Box>
        {optimization?.description && (
          <Box py={1}>
            <TextField
              label={'Description'}
              fullWidth
              type='text'
              value={optimization?.description || ''}
              placeholder='Description'
              multiline
              rows={10}
            />
          </Box>
        )}
        {/* Editable Additional Notes Field */}
        <Box py={1}>
          <TextField
            label={'Addtl Notes'}
            fullWidth
            type='text'
            value={addtlNotes}
            onChange={e => onUpdate('addtlNotes', e.target.value)}
            placeholder='Additional Notes'
            multiline
            rows={3}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default SavedOptimization
