import React, { useEffect } from 'react'
import { Checkbox, FormControlLabel, FormControl, FormHelperText, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { determineApprovalState } from '../DealApproval/determineApprovalState'

const ExistingDeal = ({ noSignature, setNoSignature, newDeal, setNewDeal, organization, handleChange, context }) => {
  const theme = useTheme()
  const handleCheckboxChange = event => {
    const { checked } = event.target
    setNoSignature(checked)

    // ✅ Set timeCompleted ONLY when checked
    const timeCompleted = checked ? dayjs().toISOString() : null

    // ✅ Update the approval state
    const updatedApprovalState = determineApprovalState(
      { ...newDeal, noSignature: checked, timeCompleted },
      organization
    )

    // ✅ Use handleChange to ensure state is updated properly
    handleChange('noSignature', checked, organization)
    handleChange('timeCompleted', timeCompleted, organization)

    // ✅ Update newDeal state
    setNewDeal(prev => ({
      ...prev,
      ...updatedApprovalState, // Apply recalculated approval state
      noSignature: checked,
      timeCompleted, // Ensure timeCompleted is included
    }))
  }
  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: context === 'createDeal' ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1,
        maxWidth: '100%',
      }}
      fullWidth
      disabled={context === 'createDeal' ? false : !newDeal?.isDraft}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={noSignature}
            onChange={handleCheckboxChange} // Use the handleChange function here
            color='secondary'
            // disabled={!newDeal?.isDraft}
          />
        }
        label={<Typography sx={{ fontSize: 13, color: theme.palette.text.primary }}>No Signature Required</Typography>}
      />
      <FormHelperText sx={{ color: theme.palette.text.warning }}>If checked moves to deal won</FormHelperText>
    </FormControl>
  )
}

export default ExistingDeal
