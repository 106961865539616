import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Paper,
  Grid,
  CircularProgress,
  useTheme,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useLocation, useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import OrganizationRichTextField from './OrganizationRichTextField'
import TaggingAutocomplete from './TaggingAutocomplete'
import './style.css'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { createMessageJsx, createSlackPayload } from '../Comments/createMessageJsx'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { baseUrl } from '../../api/queryMutations/mutationFunctions'
const BASE_URL = process.env.REACT_APP_BASE_URL
const OrganizationNotes = () => {
  const { orgId } = useParams()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { addNotification, createMentionPayload, unreadCount, markAllAsRead } = useNotify()
  const [notificationsArray, setNotificationsArray] = useState([])
  const [text, setText] = useState('')
  const { mutate: sendNotification } = useNotifyMessage()
  const location = useLocation()
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState([])
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  // Maintain selected users (full objects) from tagging
  const [selectedUsers, setSelectedUsers] = useState([])
  const theme = useTheme()
  // Optionally, if you need to display owners on each note
  const userIds = notes.flatMap(note => note.owner || [])
  const { users, isLoading: isUsersLoading } = useUsersById(userIds)
  useEffect(() => {
    if (notificationsArray && notificationsArray?.length > 0) {
      console.log(notificationsArray)
    }
  }, [notificationsArray])
  useEffect(() => {
    if (organization?.notes) {
      const sortedNotes = [...organization.notes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setNotes(sortedNotes)
    }
  }, [organization])

  // Handler to update the selected users when tagging changes
  const handleTagUser = users => {
    setSelectedUsers(users)
  }

  const handleAddNote = async () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)

      // Create a visible block of tagged user names
      // const tagsDisplay = selectedUsers.length
      //   ? `<div class="tagged-users" style="margin-top: 10px; color: #666; font-style: italic;">
      //       Tagged: ${selectedUsers.map(u => `${u.firstName} ${u.lastName}`).join(', ')}
      //       <img src="https://avatars.slack-edge.com/2024-06-03/7215092766035_19d8c2d7d114f2ce568c_512.jpg" />
      //     </div>`
      //   : ''
      const tagsDisplay = selectedUsers.length
        ? `<div class="tagged-users" style="margin-top: 10px; display: flex; align-items: center; justify-content: flex-end; gap: 8px;">
          ${selectedUsers
            .map(
              user =>
                `<div style="display: flex; align-items: center;">
                   <img 
                     src="${user.image_url || user.img_link || user.image_48}" 
                     alt="${user.firstName}" 
                     title="${user.firstName} ${user.lastName} (${user.id})" 
                     style="width:36px; height:36px; border-radius:50%;" />
                 </div>`
            )
            .join('')}
        </div>`
        : ''

      // Append the visible tag block to the rich text contentddd
      // const noteContentWithTags = tagsDisplay + newNoteContent
      const noteContentWithTags = newNoteContent + tagsDisplay

      // Create a new note object with a tagIds property (an array of ids)
      const newNote = {
        note_id: uuidv4(),
        contents: noteContentWithTags,
        tagIds: selectedUsers.map(u => u.user_uuid || u.id), // New property added here
        owner: activeUser?.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedNotes = [newNote, ...notes] // Add new note at the top
      setNotes(updatedNotes)
      updateItems(
        {
          updates: [
            {
              primaryKey: 'org_uuid',
              tableName: 'organizations',
              itemId: organization.org_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            // Clear inputs and selected tags after submission
            setIsCreatingNote(false)
            setNewNoteContent('')
            setSelectedUsers([])
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
            setIsCreatingNote(false)
          },
        }
      )
    }
  }

  // // Example handleSubmit
  // const handleSubmit = async () => {
  //   console.log('handleSubmit Triggered')
  //   // 1) Build the JSX message (if you want to render it somewhere)
  //   // const { selectedUsers: taggedUsers, message } = createMessageJsx(
  //   //   newNoteContent, // or `value`, whichever holds your typed text
  //   //   selectedUsers, // array of user objects from TaggingAutocomplete
  //   //   theme
  //   // )
  //   // console.log('TAGGED USERS', taggedUsers)
  //   // console.log('MESSAGE (NOT BEING USED)', message)
  //   // 2) Build the local notifications data
  //   // const newNotificationsData = selectedUsers.map(user => ({
  //   //   userId: user.id,
  //   //   type: 'mention',
  //   //   severity: 'success',
  //   //   title: `You were mentioned in a message`,
  //   //   message: newNoteContent, // or `value`
  //   // }))
  //   // // 3) Send Slack notifications
  //   try {
  //     selectedUsers.forEach(user => {
  //       if (user.slackId) {
  //         const payload = createSlackPayload(user, newNoteContent)
  //         console.log('PAYLOAD FOR SLACK: ', payload)
  //         // sendNotification(payload, {
  //         //   onSuccess: () => console.log(`Notification sent to ${user.firstName} ${user.lastName}`),
  //         //   onError: error => console.error(`Error sending notification to ${user.firstName}:`, error),
  //         // })
  //       }
  //     })
  //     const notifyPayload = createMentionPayload(
  //       selectedUsers,
  //       'mention',
  //       'success',
  //       'Mention',
  //       `You've been tagged in a message`,
  //       'nco',
  //       organization?.name,
  //       organization?.id,
  //       navLocation
  //     )
  //     console.log('NEW NOTIFICATION DATA', newNotificationsData)
  //     console.log('NOTIFICATION PAYLOAD', notifyPayload)
  //     await addNotification(notifyPayload)
  //   } catch (error) {
  //     console.error('Error sending Slack notifications', error)
  //   }

  //   // 4) Update local state if you want to show these notifications in the UI
  //   setNotificationsArray(notificationsArray)

  //   // 5) (Optional) Clear out text or selected users if desired
  //   setNewNoteContent('')
  //   setSelectedUsers([])
  // }
  // Example handleSubmit
  const handleSubmit = async () => {
    console.log('handleSubmit Triggered')
    // 1) Build the JSX message (if you want to render it somewhere)
    const { selectedUsers: taggedUsers, message } = createMessageJsx(
      newNoteContent, // or `value`, whichever holds your typed text
      selectedUsers, // array of user objects from TaggingAutocomplete
      theme
    )
    console.log('TAGGED USERS', taggedUsers)
    console.log('MESSAGE (NOT BEING USED)', message)
    // 2) Build the local notifications data
    const newNotificationsData = selectedUsers.map(user => ({
      userId: user.id,
      type: 'mention',
      severity: 'success',
      title: `You were mentioned in a message`,
      message: newNoteContent, // or `value`
    }))
    // // 3) Send Slack notifications
    try {
      selectedUsers.forEach(user => {
        if (user.slackId) {
          const payload = createSlackPayload(user, newNoteContent)
          console.log('PAYLOAD FOR SLACK: ', payload)
          sendNotification(payload, {
            onSuccess: () => console.log(`Notification sent to ${user.firstName} ${user.lastName}`),
            onError: error => console.error(`Error sending notification to ${user.firstName}:`, error),
          })
        }
      })
      const path = `${BASE_URL}${location?.pathname}}`
      const notifyPayload = createMentionPayload(
        selectedUsers,
        'mention',
        'success',
        'Mention',
        `You've been tagged in a message`,
        'nco',
        organization?.name,
        organization?.id,
        path
      )
      console.log('NEW NOTIFICATION DATA', newNotificationsData)
      console.log('NOTIFICATION PAYLOAD', notifyPayload)
      setNotificationsArray(notifyPayload)

      await addNotification(notifyPayload)
    } catch (error) {
      console.error('Error sending Slack notifications', error)
    }

    // 4) Update local state if you want to show these notifications in the UI

    // 5) (Optional) Clear out text or selected users if desired
    setNewNoteContent('')
    setSelectedUsers([])
  }
  const handleCreateAndNotify = async () => {
    try {
      await handleAddNote()
      await handleSubmit()
    } catch (error) {
      console.error('error submitting or adding note')
    }
  }
  const handleDeleteNote = noteId => {
    const updatedNotes = notes.filter(note => note.note_id !== noteId)
    setNotes(updatedNotes)

    updateItems(
      {
        updates: [
          {
            primaryKey: 'org_uuid',
            tableName: 'organizations',
            itemId: organization.org_uuid,
            fieldName: 'notes',
            newValue: updatedNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }
  if (isOrganizationLoading || isUsersLoading) {
    return <DetailsLoading />
  }

  if (isOrganizationError || !organization) {
    return <Typography>Error loading organization details or no organization data available.</Typography>
  }

  return (
    <Grid container pt={1} sx={{ height: '100%', display: 'flex' }}>
      <Grid item xs={6}>
        <Box
          sx={{
            // p: 1.2,
            mt: 1.2,
            ml: 2.5,
            mr: 2.5,
            pb: 1.2,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {/* Tagging autocomplete returns full user objects */}
          <TaggingAutocomplete
            label='Tag Users'
            onChange={handleTagUser}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
          {/* Optionally show a preview of the selected tags */}

          <OrganizationRichTextField
            content={newNoteContent}
            onContentChange={setNewNoteContent}
            setIsCreatingNote={setIsCreatingNote}
            isCreatingNote={isCreatingNote}
            handleAddNote={handleCreateAndNotify}
            justify='left'
          />
          {selectedUsers.length > 0 && (
            <Typography variant='caption' sx={{ mt: 1, color: theme.palette.text.primary, fontStyle: 'italic' }}>
              Tagged: {selectedUsers.map(u => `${u.firstName} ${u.lastName}`).join(', ')}
            </Typography>
          )}
        </Box>
      </Grid>

      {/* Notes List */}
      <Grid item xs={6} sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
        <List>
          {notes.map(note => {
            const noteOwner = users.find(user => user.id === note.owner?.[0])
            return (
              <ListItem
                key={note.note_id}
                // component={Paper}
                elevation={1}
                sx={{
                  mb: 2,
                  // p: 2,
                  display: 'flex',
                  alignItems: 'flex-start',
                  bgcolor: theme.palette.background.mentions,
                  '--color': theme.palette.text.link,

                  borderRadius: '10px',
                }}
              >
                {noteOwner && (
                  <Avatar alt={noteOwner.name} src={noteOwner.image_512} sx={{ width: 48, height: 48, mr: 2 }} />
                )}
                <ListItemText
                  sx={{ color: theme.palette.text.primary }}
                  primary={
                    <>
                      {noteOwner && (
                        <Typography variant='subtitle2' fontWeight='bold'>
                          {noteOwner.name} - {new Date(note.createdAt).toLocaleString()}
                        </Typography>
                      )}
                    </>
                  }
                  secondary={<Typography variant='body2' dangerouslySetInnerHTML={{ __html: note.contents }} />}
                />
                <IconButton edge='end' aria-label='delete' onClick={() => handleDeleteNote(note.note_id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default OrganizationNotes

// import React, { useState, useEffect } from 'react'
// import {
//   Box,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Avatar,
//   Paper,
//   Grid,
//   CircularProgress,
// } from '@mui/material'
// import DeleteIcon from '@mui/icons-material/Delete'
// import { v4 as uuidv4 } from 'uuid'
// import { useUpdateItems } from '../../api/aws/useUpdateItems'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import { useParams } from 'react-router-dom'
// import { useOrganizationById } from '../../api/aws/useOrganization'
// import { useUsersById } from '../../api/customHooks/useUsersById'
// import DetailsLoading from '../Loading/DetailsLoading'
// import { useActiveUser } from '../../api/slack/useActiveUser'
// import OrganizationRichTextField from './OrganizationRichTextField'
// import TaggingAutocomplete from './TaggingAutocomplete'

// const OrganizationNotes = () => {
//   const { orgId } = useParams()
//   const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
//   const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
//   const [newNoteContent, setNewNoteContent] = useState('')
//   const [notes, setNotes] = useState([])
//   const { mutate: updateItems } = useUpdateItems()
//   const { showSnackbar } = useSnackbarContext()
//   const [isCreatingNote, setIsCreatingNote] = useState(false)
//   const [notesData, setNotesData] = useState({})
//   const userIds = notes.flatMap(note => note.owner || [])
//   const { users, isLoading: isUsersLoading } = useUsersById(userIds)
//   const [selectedTagIds, setSelectedTagIds] = useState([])

//   useEffect(() => {
//     if (organization?.notes) {
//       const sortedNotes = [...organization.notes].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//       setNotes(sortedNotes)
//     }
//   }, [organization])
//   // Update tag IDs when a user is tagged
//   const handleTagUser = tagIds => {
//     setSelectedTagIds(tagIds)
//   }

//   const handleAddNote = () => {
//     if (newNoteContent && newNoteContent !== '<p></p>') {
//       setIsCreatingNote(true)

//       // Append a hidden span containing the tag IDs to the rich text content
//       const noteContentWithTags = `
//         ${newNoteContent}
//         <span class="hidden-tag-ids" style="display:none;" data-tagids="${selectedTagIds.join(',')}"></span>
//       `

//       const newNote = {
//         note_id: uuidv4(),
//         contents: noteContentWithTags,
//         owner: activeUser?.user ? [activeUser.user.id] : [],
//         createdAt: new Date().toISOString(),
//       }

//       const updatedNotes = [newNote, ...notes] // Add new note at the top
//       setNotes(updatedNotes)

//       updateItems(
//         {
//           updates: [
//             {
//               primaryKey: 'org_uuid',
//               tableName: 'organizations',
//               itemId: organization.org_uuid,
//               fieldName: 'notes',
//               newValue: updatedNotes,
//             },
//           ],
//         },
//         {
//           onSuccess: () => {
//             showSnackbar('Note added successfully', 'success')
//             handleClear()
//           },
//           onError: err => {
//             console.error('Failed to add note:', err)
//             showSnackbar('Failed to add note', 'error')
//             handleClear()
//           },
//         }
//       )
//     }
//   }

// const handleDeleteNote = noteId => {
//   const updatedNotes = notes.filter(note => note.note_id !== noteId)
//   setNotes(updatedNotes)

//   updateItems(
//     {
//       updates: [
//         {
//           primaryKey: 'org_uuid',
//           tableName: 'organizations',
//           itemId: organization.org_uuid,
//           fieldName: 'notes',
//           newValue: updatedNotes,
//         },
//       ],
//     },
//     {
//       onSuccess: () => {
//         showSnackbar('Note deleted successfully', 'success')
//       },
//       onError: err => {
//         console.error('Failed to delete note:', err)
//         showSnackbar('Failed to delete note', 'error')
//       },
//     }
//   )
// }

//   const handleClear = () => {
//     setIsCreatingNote(false)
//     setNewNoteContent('')
//   }
//   if (isOrganizationLoading || isUsersLoading) {
//     return <DetailsLoading />
//   }

//   if (isOrganizationError || !organization) {
//     return <Typography>Error loading organization details or no organization data available.</Typography>
//   }

//   return (
//     <Grid container sx={{ height: '100%', display: 'flex' }}>
//       <>
//         <Grid item xs={6}>
//           {/* <Typography variant='h6' gutterBottom>
//           Notes
//         </Typography> */}

//           {/* Note addition form */}
//           <Box
//             sx={{
//               p: 1.2,
//               ml: 2.5,
//               mr: 2.5,
//               justifyContent: 'center',
//               textAlign: 'center',
//             }}
//           >
//             <TaggingAutocomplete
//               label='Tag Users'
//               onChange={handleTagUser}
//               onBlur={(role, tagIds) => console.log('Selected tag IDs on blur:', tagIds)}
//             />

//             <OrganizationRichTextField
//               content={newNoteContent}
//               onContentChange={setNewNoteContent}
//               setIsCreatingNote={setIsCreatingNote}
//               isCreatingNote={isCreatingNote}
//               handleAddNote={handleAddNote}
//               justify={'left'}
//             />
//           </Box>
//         </Grid>

//         {/* Notes List with Scrollable Area */}
//         {/* List of notes */}
//         <Grid item xs={6} p={1} sx={{ maxHeight: '65vh', overflowY: 'auto' }}>
//           <List>
//             {notes.map(note => {
//               const noteOwner = users.find(user => user.id === note.owner?.[0])
//               return (
//                 <ListItem
//                   key={note.note_id}
//                   component={Paper}
//                   elevation={1}
//                   sx={{ mb: 2, p: 2, display: 'flex', alignItems: 'flex-start' }}
//                 >
//                   {noteOwner && (
//                     <Avatar alt={noteOwner.name} src={noteOwner.image_512} sx={{ width: 48, height: 48, mr: 2 }} />
//                   )}
//                   <ListItemText
//                     primary={
//                       <>
//                         {noteOwner && (
//                           <Typography variant='subtitle2' fontWeight='bold'>
//                             {noteOwner.name} - {new Date(note.createdAt).toLocaleString()}
//                           </Typography>
//                         )}
//                       </>
//                     }
//                     secondary={<Typography variant='body2' dangerouslySetInnerHTML={{ __html: note.contents }} />}
//                   />
//                   <IconButton edge='end' aria-label='delete' onClick={() => handleDeleteNote(note.note_id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </ListItem>
//               )
//             })}
//           </List>
//         </Grid>
//       </>
//     </Grid>
//   )
// }

// export default OrganizationNotes
