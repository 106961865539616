import React from 'react'
import { Badge, IconButton, Tooltip, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

const FilterMyDataButton = ({ icon, filterModel, onClick, label }) => {
  const theme = useTheme()
  const isFilterActive =
    filterModel && Array.isArray(filterModel.quickFilterValues) && filterModel.quickFilterValues.length > 0
  return (
    <Tooltip title={label}>
      <IconButton
        sx={{
          borderRadius: '0px',
        }}
        onClick={onClick}
      >
        <Badge
          badgeContent={
            <CheckIcon
              sx={{
                fontSize: '18px',
                color: isFilterActive ? theme.palette.text.success : theme.palette.text.secondary,
              }}
            />
          }
        >
          {icon}
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default FilterMyDataButton
