// useUpdateOptimization.ts
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

interface UpdateOptimizationVars {
  optPK: string
  optSK: string
  updatedData: Partial<Omit<any, 'PK' | 'opt_uuid' | 'orgId'>>
  orgId: string // new field
}
export const useUpdateOptimization = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation<any, Error, UpdateOptimizationVars, unknown>({
    mutationFn: async ({ optPK, optSK, updatedData }) => {
      const encodedPK = encodeURIComponent(optPK)
      const encodedSK = encodeURIComponent(optSK)
      const url = `${baseUrl}/aws/optimizations/${encodedPK}/${encodedSK}`
      console.log('Updating optimization with:', { optPK, optSK, updatedData })
      const response = await axios.put(url, updatedData)
      return response.data
    },
    onMutate: async ({ optPK, optSK, updatedData, orgId }) => {
      // Update the individual optimization cache:
      const optKey = ['optimization', optPK, optSK]
      await queryClient.cancelQueries({ queryKey: optKey })
      const previousOpt = queryClient.getQueryData(optKey)
      queryClient.setQueryData(optKey, old => ({
        ...old,
        ...updatedData,
      }))

      // Update the list cache for all optimizations by orgId:
      const listKey = ['optsByOrgId', orgId]
      await queryClient.cancelQueries({ queryKey: listKey })
      const previousList = queryClient.getQueryData(listKey)
      queryClient.setQueryData(listKey, oldData => {
        if (!oldData) return oldData
        return oldData.map(opt => (opt.PK === optPK && opt.SK === optSK ? { ...opt, ...updatedData } : opt))
      })

      return { previousOpt, previousList }
    },
    onError: (err, { optPK, optSK, orgId }, context) => {
      const optKey = ['optimization', optPK, optSK]
      if (context?.previousOpt) {
        queryClient.setQueryData(optKey, context.previousOpt)
      }
      const listKey = ['optsByOrgId', orgId]
      if (context?.previousList) {
        queryClient.setQueryData(listKey, context.previousList)
      }
    },
    onSuccess: (_, { optPK, optSK, orgId }) => {
      // queryClient.invalidateQueries({ queryKey: ['optimization', optPK, optSK] })
      // queryClient.invalidateQueries({ queryKey: ['optsByOrgId', orgId] })
    },
    // onMutate: async ({ optPK, optSK, updatedData }) => {
    //   const optKey = ['optimization', optPK, optSK]
    //   await queryClient.cancelQueries({ queryKey: optKey })
    //   const previousOpt = queryClient.getQueryData(optKey)
    //   queryClient.setQueryData(optKey, old => ({
    //     ...old,
    //     ...updatedData,
    //   }))
    //   return { previousOpt }
    // },
    // onError: (err, { optPK, optSK }, context) => {
    //   const optKey = ['optimization', optPK, optSK]
    //   if (context?.previousOpt) {
    //     queryClient.setQueryData(optKey, context.previousOpt)
    //   }
    // },
    // onSuccess: (_, { optPK, optSK }) => {
    //   queryClient.invalidateQueries({ queryKey: ['optimization', optPK, optSK] })
    // },
  })
  return mutation
}
