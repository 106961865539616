import React from 'react'
import { StyledGridTypography, StyledGridDefaultTypography, BoxScroller } from '../../style/styleElements'
import { Grid, useTheme } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import { Link } from 'react-router-dom'
import { renderColorByString } from '../DataGrid/renderColorByString'

const MyDeals = ({ deal }) => {
  const theme = useTheme()
  const rowBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.9)' : 'rgb(255, 255, 255, 1)'
  const dealId = deal ? deal.deal_uuid : ''
  return (
    <>
      {dealId ? (
        <Link
          to={`/deals/${dealId}/details`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
        >
          <Grid
            container
            sx={{
              margin: '0 auto',
              marginTop: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // This helps space out the items evenly
              backgroundColor: rowBackgroundColor,
              borderRadius: '15px',
              // padding: '10px',
              '& > .MuiGrid-item': {
                padding: '10px',
              },
              '&:hover': {
                transform: 'scale(1.02)',
                // margin: '-1px', // Slightly reduce the margin to compensate for the transform
                zIndex: 1, // Ensure the scaled item is above others if they overlap
              },
              transition: 'all 0.3s ease-in-out', // Include margin in the transition for a smooth effect
            }}
          >
            <Grid item xs={3}>
              <StyledGridDefaultTypography>Name</StyledGridDefaultTypography>
              <StyledGridTypography>{deal.name}</StyledGridTypography>
            </Grid>
            <Grid item xs={1}>
              <StyledGridTypography
                sx={{
                  color: renderColorByString(deal.type),
                  fontWeight: 'bold',
                }}
              >
                {deal.type}
              </StyledGridTypography>
            </Grid>
            <Grid item xs={2}>
              <StyledGridTypography
                sx={{
                  color: renderColorByString(deal.status),
                  fontWeight: 'bold',
                }}
              >
                {deal.status}
              </StyledGridTypography>
            </Grid>
            <Grid item xs={2}>
              <StyledGridDefaultTypography>Total</StyledGridDefaultTypography>
              <StyledGridTypography sx={{ color: 'rgb(92, 203, 131)' }}>
                {formatCurrency(deal.total)}
              </StyledGridTypography>
            </Grid>
            <Grid item xs={3}>
              <StyledGridDefaultTypography>Monthly Total</StyledGridDefaultTypography>
              <StyledGridTypography sx={{ color: 'rgb(92, 203, 131)' }}>
                {formatCurrency(deal.monthlyTotal)}
              </StyledGridTypography>
            </Grid>
          </Grid>
        </Link>
      ) : (
        <Grid
          container
          sx={{
            margin: '0 auto',
            marginTop: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // This helps space out the items evenly
            backgroundColor: rowBackgroundColor,
            borderRadius: '15px',
            padding: '10px',
            '& > .MuiGrid-item': {
              padding: '10px',
            },
            transition: 'ease-in background-color 0.2s',
          }}
        >
          <Grid item xs={12 / 2}>
            <StyledGridDefaultTypography>Name</StyledGridDefaultTypography>
            <StyledGridTypography>{deal.name}</StyledGridTypography>
          </Grid>
          <Grid item xs={12 / 2}>
            <StyledGridDefaultTypography>Total</StyledGridDefaultTypography>
            <StyledGridTypography>{formatCurrency(deal.total)}</StyledGridTypography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MyDeals
