import React, { useState, useEffect } from 'react'
import { Grid, Typography, Card, useTheme } from '@mui/material'
import { ncoDependenciesList, dependenciesNameMapping } from './ncoTaskObjects'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

const Channels = ({ handleChange, checklistProgress }) => {
  const { dealId } = useParams()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const deal = queryClient.getQueryData(['deal', dealId])

  const initializeSelectedStatuses = () => {
    const initialStatuses = {}
    ncoDependenciesList[0].Dependency.forEach(dep => {
      const key = Object.keys(dep)[0]
      initialStatuses[key] = deal?.[key] || 'N/A'
    })
    return initialStatuses
  }

  const [selectedStatuses, setSelectedStatuses] = useState(initializeSelectedStatuses())

  useEffect(() => {
    setSelectedStatuses(initializeSelectedStatuses())
  }, [deal, ncoDependenciesList])

  const handleCardClick = (dependency, statusOptions) => {
    const currentIndex = statusOptions.findIndex(option => option.name === selectedStatuses[dependency])
    const nextIndex = (currentIndex + 1) % statusOptions.length
    const updatedStatus = statusOptions[nextIndex].name

    setSelectedStatuses(prev => ({
      ...prev,
      [dependency]: updatedStatus,
    }))

    handleChange(dependency, updatedStatus)
  }

  // Utility to format a decimal as a percentage string.
  const formatPercentage = decimal => `${Math.round(decimal * 100)}%`

  // Helper to determine color based on progress fraction
  const getChannelColor = progress => {
    if (progress === 1) return 'rgb(51, 211, 145)' // fully complete (green)
    if (progress === 0) return 'rgb(121, 126, 147)' // not started (grey)
    return 'rgb(253, 188, 100)' // partially complete (amber)
  }

  return (
    <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      {ncoDependenciesList[0].Dependency.map((dep, index) => {
        const dependencyKey = Object.keys(dep)[0]
        const normalizedKey = dependencyKey.toLowerCase()
        const dependencyName = dependenciesNameMapping[dependencyKey] || dependencyKey
        const statusOptions = dep[dependencyKey]

        // For certain channels, display the progress percentage if available.
        let currentStatus
        if (['fbpage', 'gtm', 'ga4'].includes(normalizedKey) && checklistProgress?.[normalizedKey] != null) {
          currentStatus = formatPercentage(checklistProgress[normalizedKey])
        } else {
          currentStatus = selectedStatuses[dependencyKey]
        }

        // Use our helper for color if we have a progress-based channel,
        // otherwise, fall back to the color provided in statusOptions.
        const currentStatusColor =
          ['fbpage', 'gtm', 'ga4'].includes(normalizedKey) && checklistProgress?.[normalizedKey] != null
            ? getChannelColor(checklistProgress[normalizedKey])
            : statusOptions.find(option => option.name === currentStatus)?.color || theme.palette.grey[300]

        return (
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={1.7}
            xl={1.7}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Card
              onClick={() => handleCardClick(dependencyKey, statusOptions)}
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: currentStatusColor,
                color: theme.palette.getContrastText(currentStatusColor),
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: `0px 0px 12px ${theme.palette.grey[400]}`,
                },
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                minHeight: 40,
                height: {
                  xs: 40,
                  sm: 40,
                  md: 50,
                  lg: 50,
                  xl: 50,
                },
                minWidth: 35,
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%',
                },
              }}
            >
              <Typography
                variant='body1'
                noWrap
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: {
                    xs: '0.9rem',
                    sm: '0.9rem',
                    md: '0.8rem',
                    lg: '1rem',
                    xl: '1rem',
                  },
                }}
              >
                {dependencyName}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 'bold',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  fontSize: {
                    xs: '1rem',
                    sm: '0.8rem',
                    md: '0.8rem',
                    lg: '0.8rem',
                    xl: '0.8rem',
                  },
                }}
              >
                {currentStatus}
              </Typography>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Channels
