import React from 'react'
import { Badge, IconButton, Tooltip, useTheme } from '@mui/material'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import ClearIcon from '@mui/icons-material/Clear'

const ResetColumnsButton = ({ visibilityModel, onClearVisibility, initialVisibilityState }) => {
  const theme = useTheme()
  const isVisibilityChanged = Object.keys(visibilityModel).some(
    column => visibilityModel[column] !== initialVisibilityState[column]
  )
  return (
    <Tooltip title={isVisibilityChanged ? 'Reset Columns' : ''}>
      <IconButton
        sx={{
          color: isVisibilityChanged
            ? theme.palette.text.primary // Active columns color
            : theme.palette.text.tertiary, // Default gray color when no changes
          borderRadius: '0px',
        }}
        disabled={isVisibilityChanged ? false : true}
        onClick={onClearVisibility}
      >
        <Badge
          badgeContent={
            <ClearIcon
              sx={{
                fontSize: '18px',
                color: isVisibilityChanged ? theme.palette.text.error : theme.palette.text.secondary,
              }}
            />
          }
          color=''
        >
          <ViewColumnIcon
            sx={{
              fontSize: '30px',
              color: isVisibilityChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Active filters color
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default ResetColumnsButton
