import { Grid } from '@mui/material'
import React from 'react'
import FinancialTotals from './FinancialTotals'
import AccountingDrawerHeader from './AccountingDrawerHeader'
interface AccountingDetailsProps {
  organization: object | null
  billingContact: object | null
  newDeal: object | null
  totals: object | null
  isLoading: boolean
  containerHeight: string | ''
  hideViewIcon: boolean
}

const AccountingDetails: React.FC<AccountingDetailsProps> = ({
  organization,
  billingContact,
  newDeal,
  totals,
  isLoading,
}) => {
  return (
    <>
      <AccountingDrawerHeader
        organization={organization}
        billingContact={billingContact}
        newDeal={newDeal}
        totals={totals}
        isLoading={isLoading}
      />
      <Grid item xs={12} sm={12} lg={12} sx={{ height: 'calc(100vh - 75px)' }}>
        <FinancialTotals containerHeight={'100%'} hideViewIcon={true} />
      </Grid>
    </>
  )
}

export default AccountingDetails
