import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  Select,
  OutlinedInput,
  useTheme,
  Grid,
  CircularProgress,
  Button,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
  Stack,
  Chip,
} from '@mui/material'
import { dialogStyles } from './style'
import {
  RequestTypeOptions,
  FormConfig,
  FieldType,
  FieldConfig,
  initialFormData,
  initialRequestFields,
  existingClientOptions,
  User,
  MarketingFormProps,
  MarketingFormData,
  TaskTypeOptions,
  taskTypeLabels,
  MarketingFormConfig,
  reviewChannelFields,
  initialMarketingFormData,
  updateChannelFields,
  budgetChannelFields,
  createSetupChannelFields,
  newClientOnboardFields,
  campaignChecklistFields,
  adApprovalsFields,
  pauseFields,
  enableFields,
  handleFormatTypesString,
} from './types.ts'
import FieldComponent from './FieldComponent.tsx'
import OnboardTeamAutocomplete from '../DataGrid/components/OnboardTeamAutocomplete.tsx'
import { useUsers } from '../../api/aws/useUsers.js'
import { BoxScroller } from '../../style/styleElements.js'
import TaskRichText from '../Tasks/TaskRichText.js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import { normalizeDate } from '../DateTime/dateUtils.js'
import { useCreateTask } from '../../api/aws/useCreateTask.js'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider.js'
import { useSubmitTask } from '../../api/aws/tasks/useSubmitTask.tsx'
import FormAutocomplete from './FormAutocomplete.tsx'
import { useOrganizations } from '../../api/aws/useOrganizations.js'
import { useDeals } from '../../api/aws/useDeals.js'
import { useOrganizationDealsById } from '../../api/aws/useOrganizationDealsById.tsx'
import { useCreateGoogleFolder } from '../../api/google/useCreateGoogleFolder.ts'
import CreateTaskHeader from '../Tasks/CreateTaskHeader.js'
import FormTypeSelect from './FormTypeSelect.tsx'
import LockIcon from '@mui/icons-material/Lock'
import { useUsersById } from '../../api/customHooks/useUsersById.js'
import { useActiveUser } from '../../api/slack/useActiveUser.js'
import { useUser } from '../../api/aws/useUser.js'
import RenderTypeFields from './RenderTypeFields.tsx'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DescriptionIcon from '@mui/icons-material/Description'
import { NewFileAttachments } from '../TaskDrawer/NewFileAttachments.tsx'
import useRawUpload from '../../api/aws/tasks/useRawUpload.ts'

interface OptionType {
  id: string
  name?: string // For organizations
  dba?: string // DBA field if needed
  firstName?: string // For users
  lastName?: string // For users
  image_512?: string // For avatars
  org_uuid?: string
  assigner: object
  assignees: User[]
  AM: User[]
  DMS: User[]
  website: string
  deal_uuid: string
  deals: object[]
  organization: object | null
  subfolderIds?: string
  googleDriveFolderId?: string
}
type CurrentUser = {
  user_uuid: string
  id: string
  firstName: string
  lastName: string
  name: string
  image_512: string
  role: string
  title: string
}

const channelFieldMap: Record<string, Record<string, Record<string, FieldConfig>>> = {
  reviewAudit: reviewChannelFields,
  updates: updateChannelFields,
  budget: budgetChannelFields,
  createSetup: createSetupChannelFields,
  newClientOnboard: newClientOnboardFields,
  campaignChecklists: campaignChecklistFields,
  adApprovals: adApprovalsFields,
  pause: pauseFields,
  enable: enableFields,
  // Add more mappings as needed
}
const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

const MarketingTaskForm: React.FC<MarketingFormProps> = ({ open, onClose }) => {
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['users', 'creativeIntake'])
  const { data: organizations, isLoading } = useOrganizations(true, ['organizations', 'creativeIntake'])
  const { deals, isDealsLoading } = useDeals(true, ['deals', 'creativeIntake'])
  const [selectedOrganization, setSelectedOrganization] = useState<OptionType | null>(null)
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null)
  const { organization, isOrganizationLoading } = useOrganizationDealsById(selectedOrgId)
  const [selectedDeal, setSelectedDeal] = useState<OptionType | null>(null)
  const [orgDeals, setOrgDeals] = useState<object[]>([])
  const { mutate: createTaskFolder } = useCreateGoogleFolder()
  const [formData, setFormData] = useState<MarketingFormData>({ ...initialMarketingFormData })
  const [isCreating, setIsCreating] = useState(false)
  const { mutate: createTask } = useCreateTask()
  const { submitNewTask } = useSubmitTask()
  const { validateMarketingForm, marketingFormErrors } = useValidationContext()
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [taskName, setTaskName] = useState(organization?.name)
  const [selectedTaskType, setSelectedTaskType] = useState<keyof TaskTypeOptions | ''>('')
  const [assignees, setAssignees] = useState([])
  const [selectedImages, setSelectedImages] = useState<File[]>([])
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const { mutateAsync: rawUpload } = useRawUpload()

  // ✅ Use useMemo to efficiently return user IDs
  ///////////// REQUESTED INTERNAL OR CLIENT DROPDOWN NEEDED****************************
  /////////////
  const userIds = useMemo(() => {
    if (!organization) return []

    // Combine AM and DMS arrays, ensuring unique valid IDs
    const combinedIds = [...(organization.AM ?? []), ...(organization.DMS ?? [])]

    return Array.from(new Set(combinedIds.filter(Boolean))) // Remove duplicates & falsy values
  }, [organization])
  const { activeUser, isActiveUserLoading } = useActiveUser()
  // console.log('ACTIVE USER', activeUser)
  const { id: activeUserId } = activeUser?.user
  const { user: currentUser, isUserLoading: isCurrentUserLoading } = useUser(activeUserId)
  const { users: taskAssignees, isUsersByIdLoading: isTaskAssigneesLoading } = useUsersById(userIds ?? [])
  // console.log('currentUser', currentUser, 'taskAssignees', taskAssignees)
  const [requestFields, setRequestFields] = useState<RequestTypeOptions>(initialRequestFields)
  const theme = useTheme()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }
  useEffect(() => {
    if (organization?.name && formData?.type) {
      setTaskName(`${organization.name} - ${handleFormatTypesString(formData.type)}`)
    } else if (organization?.name) {
      setTaskName(organization.name)
    } else {
      setTaskName('')
    }
  }, [organization?.name, formData?.type])
  // ✅ Only update formData when users change

  // Keep formData.name in sync with taskName
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: taskName, // Ensure the form's name field is updated
    }))
  }, [taskName])

  useEffect(() => {
    if (organization) {
      setFormData(prev => ({
        ...prev,
        // ✅ Don’t auto-fill assignees
        assigner: currentUser
          ? {
              user_uuid: currentUser.user_uuid || '',
              id: currentUser.id || '',
              firstName: currentUser.firstName || '',
              lastName: currentUser.lastName || '',
              name: currentUser.name || '',
              image_512: currentUser.image_512 || '',
              role: currentUser.role || '',
              title: currentUser.title || '',
            }
          : prev.assigner,
      }))
    }
  }, [organization])

  const handleUserChange = (
    role: string,
    selectedUsers: {
      id: string
      user_uuid: string
      firstName: string
      lastName: string
      name: string
      image_512: string
      role: string
    }[]
  ) => {
    const key = role.toLowerCase() // ensure it matches the formData keys
    const formattedUsers = selectedUsers.map(user => ({
      user_uuid: user?.user_uuid || user?.id,
      firstName: user.firstName,
      lastName: user.lastName,
      name: user.name,
      image_512: user.image_512,
      role: user.role,
    }))

    setFormData(prev => ({
      ...prev,
      [key]: formattedUsers,
    }))
  }

  const handleAddtlNotesChange = (newValue: string) => {
    setFormData(prev => ({ ...prev, addtlNotes: newValue }))
  }
  const handleOrganizationChange = (selectedOrg: OptionType | null) => {
    if (!selectedOrg) {
      setFormData(initialMarketingFormData)
    }
    setSelectedOrganization(selectedOrg)
    setSelectedOrgId(selectedOrg?.org_uuid || null)
    const newOrgDeals = selectedOrg?.deals
    setOrgDeals(newOrgDeals || [])
    setFormData(prev => ({
      ...prev,
      orgId: selectedOrg?.org_uuid || '',
      website: selectedOrg?.website || '',
      tasksFolderId: selectedOrg?.subfolderIds?.tasksFolderId || '',
      creativeTasksFolderId: selectedOrg?.subfolderIds?.creativeTasksFolderId || '',
      googleDriveFolderId: selectedOrg?.googleDriveFolderId || '',
      assigner: currentUser
        ? {
            user_uuid: currentUser.user_uuid || '',
            id: currentUser.id || '',
            firstName: currentUser.firstName || '',
            lastName: currentUser.lastName || '',
            name: currentUser.name || '',
            image_512: currentUser.image_512 || '',
            role: currentUser.role || '',
            title: currentUser.title || '',
          }
        : prev.assigner,
    }))
  }

  const handleChange = (event: { target: { name: string; value: unknown } }) => {
    const { name, value } = event.target
    const selectedTaskType = formData.type as keyof TaskTypeOptions
    const fieldType = selectedTaskType ? FormConfig[selectedTaskType]?.[name]?.type : undefined

    setFormData(prev => ({
      ...prev,
      [name]: fieldType === FieldType.MULTISELECT ? (Array.isArray(value) ? value : [value]) : value,
    }))
  }
  const fieldTypeOrder = [
    FieldType.TEXT,
    FieldType.SELECT,
    FieldType.MULTISELECT,
    FieldType.CHECKBOX,
    FieldType.NUMBER,
    FieldType.COLOR_PICKER,
    FieldType.DATE,
    FieldType.TEXTAREA, // Put textareas at the bottom
  ]
  const { fields } = useMemo(() => {
    if (!formData.type) {
      return { fields: [] }
    }

    const selectedType = formData.type as keyof TaskTypeOptions
    return {
      fields: MarketingFormConfig[selectedType] ? Object.entries(MarketingFormConfig[selectedType]) : [],
    }
  }, [formData.type])
  useEffect(() => {
    const validateForm = async () => {
      if (formData) {
        const { isValid } = await validateMarketingForm(formData) // Await the validation
        setIsFormValid(isValid)
      }
    }

    validateForm() // Call the async function
  }, [formData, formData?.type])
  const sortFieldsByType = (fields: [string, FieldConfig][]) => {
    return fields.sort((a, b) => {
      const typeA = fieldTypeOrder.indexOf(a[1].type)
      const typeB = fieldTypeOrder.indexOf(b[1].type)
      return typeA - typeB
    })
  }
  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    const selectedType = e.target.value as keyof TaskTypeOptions
    setFormData(prev => ({
      ...prev,
      type: selectedType, // Update formData with selected type
    }))
  }
  // const handleSubmitNewTask = async task => {
  //   let imageData: string[] = [] // Declare imageData as an array of strings

  //   if (task && selectedFiles.length > 0) {
  //     const uploadFormData = new FormData()
  //     Array.from(selectedFiles).forEach(file => {
  //       uploadFormData.append('files', file)
  //     })
  //     // Append the required fields for your backend:
  //     uploadFormData.append('tableName', 'TASKS_TABLE')
  //     uploadFormData.append('PK', task.PK)
  //     uploadFormData.append('SK', task.SK)
  //     uploadFormData.append('fieldName', 'imageUrls')

  //     // Call your uploadImages mutation from useUploadTaskImages
  //     uploadImages(
  //       { taskId: task.task_uuid, PK: task.PK, SK: task.SK, formData: uploadFormData },
  //       {
  //         onSuccess: data => {
  //           console.log('Successfully added files:', data)
  //         },
  //         onError: error => {
  //           console.error('Error adding files:', error)
  //         },
  //       }
  //     )
  //   }

  //   const submitData = {
  //     ...formData,
  //     images: imageData, // Include the converted images in your form data
  //     isMarketing: true,
  //     channel: 'Digital',
  //     type: formData?.type ?? 'Marketing Form',
  //     masterStatus: formData?.masterStatus ?? 'Not Started',
  //   }

  //   console.log('submitData', submitData)
  //   try {
  //     setIsCreating(true)

  //     await submitNewTask({
  //       formData: submitData, // Pass the updated formData with images
  //       setIsCreating, // Function to toggle loading state
  //       onClose, // Function to close modal after success
  //       parentFolderId: formData?.tasksFolderId || '', // Ensure correct parent folder ID
  //       selectedFiles: selectedFiles || [],
  //     })
  //     setFormData({ ...initialMarketingFormData })
  //   } catch (error) {
  //     setIsCreating(false)
  //     console.error('❌ Error creating new task:', error)
  //   }
  // }
  // NEW HELPER FUNCTION
  const handleSubmitNewTask = async () => {
    setIsCreating(true)
    try {
      let uploadedUrls: string[] = []

      // STEP 1: If files are selected, upload them and get their URLs.
      if (selectedFiles.length > 0) {
        const uploadFormData = new FormData()
        selectedFiles.forEach(file => {
          uploadFormData.append('files', file)
        })
        // Use mutateAsync to get a Promise that resolves to the upload result.
        const data = await rawUpload({ formData: uploadFormData })
        uploadedUrls = data.urls
      }

      // STEP 2: Build submitData with the returned image URLs.
      const submitData = {
        ...formData,
        imageUrls: uploadedUrls, // Assign the uploaded URLs here.
        isMarketing: true,
        channel: 'Digital',
        type: formData?.type ?? 'Marketing Form',
        masterStatus: formData?.masterStatus ?? 'Not Started',
      }

      console.log('submitData', submitData)
      // STEP 3: Create the task using your submitNewTask function.
      await submitNewTask({
        formData: submitData,
        setIsCreating,
        onClose,
        parentFolderId: formData?.tasksFolderId || '',
      })

      // Clear the form and file selection on success.
      setFormData({ ...initialMarketingFormData })
      setSelectedFiles([])
    } catch (error) {
      console.error('❌ Error in task creation flow:', error)
      setIsCreating(false)
    }
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files)
      // Append new files to previously selected ones
      setSelectedFiles(prevFiles => [...prevFiles, ...newFiles])
    }
  }

  const handleRemoveNewFile = (index: number) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index))
  }

  return (
    <Dialog fullWidth maxWidth='md' PaperProps={{ sx: dialogStyles(theme) }} open={open} onClose={onClose}>
      <CreateTaskHeader context={'Digital Task Form'} />

      <BoxScroller
        sx={{
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,1)' : 'rgba(0,0,0,0.5)',
            borderRadius: '6px',
          },
        }}
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 1100, margin: 'auto' }}>
            <Grid container rowSpacing={2} columnSpacing={1} sx={{ alignItems: 'center' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormAutocomplete
                  data={organizations}
                  isLoading={isLoading}
                  label={'Organizations'}
                  error={marketingFormErrors?.orgId?.value}
                  onChange={handleOrganizationChange}
                  value={selectedOrganization}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  select
                  label='Type'
                  name='type'
                  value={formData.type || ''} // ✅ Ensure it's always a string
                  onChange={handleTypeChange}
                  fullWidth
                  disabled={isOrganizationLoading || !organization?.name}
                  SelectProps={{
                    multiple: false, // ✅ Ensure it's single-select
                    renderValue: selected => taskTypeLabels[selected as keyof TaskTypeOptions] || '',
                  }}
                >
                  {Object.entries(taskTypeLabels).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormTypeSelect
                  label='Requested By'
                  value={formData.requestedBy ?? ''} // Bind to formData.type
                  options={[
                    {
                      value: 'Internal',
                      label: 'Internal',
                      color: 'rgb(51, 158, 205)',
                    },
                    {
                      value: 'External',
                      label: 'External',
                      color: 'rgb(255, 189, 189)',
                    },
                  ]} // Pass taskTypeList
                  onChange={selectedValue => {
                    setFormData(prev => ({
                      ...prev,
                      requestedBy: selectedValue, // Update formData with selected type
                    }))
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormTypeSelect
                  label='Request Type'
                  value={formData.requestType ?? ''} // Bind to formData.type
                  options={[
                    {
                      value: 'Issue',
                      label: 'Issue',
                      color: 'rgb(238, 40, 99)',
                    },
                    {
                      value: 'Observation',
                      label: 'Observation',
                      color: 'rgb(168, 202, 255)',
                    },
                    {
                      value: 'Request',
                      label: 'Request',
                      color: 'rgba(255, 148, 112, 1)',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                      color: 'rgb(169, 190, 232)',
                    },
                  ]} // Pass taskTypeList
                  onChange={selectedValue => {
                    setFormData(prev => ({
                      ...prev,
                      requestType: selectedValue, // Update formData with selected type
                    }))
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label='Name'
                  name='name'
                  value={taskName ?? formData.name}
                  onChange={handleInputChange}
                  fullWidth
                  // error={marketingFormErrors?.name?.value}
                  InputProps={{
                    readOnly: true,
                    endAdornment: <LockIcon sx={{ color: theme.palette.text.secondary }} />,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label='Website'
                  name='website'
                  value={formData.website}
                  onChange={handleInputChange}
                  fullWidth
                  error={marketingFormErrors?.website?.value}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <OnboardTeamAutocomplete
                  users={users}
                  role='Assignees'
                  label='Assignee(s)'
                  onChange={handleUserChange}
                  defaultValue={formData?.assignees || []}
                  disabled={false}
                  isLoading={isTaskAssigneesLoading}
                  // error={marketingFormErrors?.assignee?.value}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <OnboardTeamAutocomplete
                  users={users}
                  role='Assigner'
                  label='Assigner'
                  onChange={handleUserChange}
                  defaultValue={formData?.assigner ? [formData?.assigner] : []}
                  disabled={true}
                  isLoading={isActiveUserLoading || isCurrentUserLoading}

                  // error={marketingFormErrors?.assigner?.value}
                />
              </Grid>
              <Grid
                item
                xs={12} // Set full width for textareas
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DateRangePicker
                      localeText={{ start: 'Start Date', end: 'Due Date' }}
                      value={[
                        formData?.startDate ? dayjs(formData?.startDate) : null,
                        formData?.endDate ? dayjs(formData?.endDate) : null,
                      ]}
                      onChange={newValue => {
                        const [startDate, endDate] = newValue

                        setFormData(prev => ({
                          ...prev,
                          startDate: normalizeDate(startDate, 'America/New_York'),
                          endDate: normalizeDate(endDate, 'America/New_York'),
                        }))
                      }}
                      slotProps={{
                        textField: {
                          error: marketingFormErrors?.startDate?.value || marketingFormErrors.endDate?.value,
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiMultiInputDateRangeField-separator': {
                          m: 0,
                        },
                        '& .MuiFormControl-root': {
                          m: 0,
                        },
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>

            {formData.type && (
              <Grid
                container
                rowSpacing={2}
                columnSpacing={1}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: 'bold',
                      borderBottom: '2px solid',
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    {taskTypeLabels[formData.type as keyof TaskTypeOptions]}
                  </Typography>
                </Grid>

                {fields.map(([key, config]) => (
                  <Grid key={key} item xs={9} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <FieldComponent name={key} value={formData[key] || ''} onChange={handleChange} {...config} />
                  </Grid>
                ))}

                {formData.type && formData.channel && channelFieldMap[formData.type] && (
                  <Grid container spacing={2}>
                    {Array.isArray(formData.channel) &&
                      formData.channel.map(channel => {
                        const channelFields = channelFieldMap[formData.type]?.[channel] || {}
                        return (
                          <Grid item xs={12} key={channel}>
                            <RenderTypeFields
                              formData={formData}
                              channel={channel}
                              channelFields={channelFields}
                              onChange={handleChange}
                            />
                          </Grid>
                        )
                      })}
                  </Grid>
                )}
              </Grid>
            )}

            <Grid
              item
              xs={12} // Set full width for textareas
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pt={1}
            >
              <TaskRichText
                content={formData.addtlNotes}
                onContentChange={handleAddtlNotesChange}
                placeholderText={'Description...'}
              />
            </Grid>
          </Box>
        </DialogContent>
      </BoxScroller>
      <DialogActions>
        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Render previews for newly selected files */}
          <Grid item xs={8}>
            {selectedFiles.length > 0 && (
              <NewFileAttachments selectedFiles={selectedFiles} onRemoveFile={handleRemoveNewFile} />
            )}
          </Grid>
          <Grid item xs={4}>
            <Button
              variant='outlined'
              component='label'
              sx={{
                marginRight: 1,
              }}
            >
              Attach Files
              <input
                type='file'
                accept='image/*,application/pdf,.doc,.docx'
                multiple
                hidden
                onChange={handleImageUpload}
              />
            </Button>
            <Button
              sx={{
                marginRight: 1,
                backgroundColor: 'rgb(244, 67, 54, 0.2)',
                '&:hover': {
                  backgroundColor: 'rgb(244, 67, 54, 0.7)',
                },
              }}
              onClick={onClose}
              color='primary'
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              color='secondary'
              variant='contained'
              // onClick={() => console.log('[Create Button] formData', formData)}
              onClick={handleSubmitNewTask}
              // onClick={() => console.log('formData', formData)}
              disabled={!isFormValid || isCreating}
            >
              Create Task
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default MarketingTaskForm
