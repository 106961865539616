// CommentsInput.tsx
import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Grid,
  useTheme,
  Button,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import './style.css'
import { useNotify } from '../../context/NotificationProvider/NotificationProvider'
import { createMessageJsx, createSlackPayload } from './createMessageJsx'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import Editor from './Editor.tsx'
import { useUser } from '../../api/aws/useUser.js'
import useGooglePickerById from '../../api/google/useGooglePickerById.ts'
import FallbackIcon from '../../images/Grobotic png.png'
import { useTask } from '../../api/aws/tasks/useTask.ts'
import { Note, MentionUser, Organization, CommentsInputProps } from './types.ts'
import CommentList from './CommentList.tsx'
import { filterNotesFn } from './filterNotesFn.tsx'
import TaskSelector from './TaskSelector.tsx'
import DrawerLoading from '../Loading/DrawerLoading.js'

const CommentsInput: React.FC<CommentsInputProps> = ({
  expanded,
  context,
  checklistItems,
  onAddTaskComment,
  onCreateTaskCommentPayload,
}) => {
  const { orgId } = useParams()
  const { PK, SK } = useParams()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { task, isTaskLoading, isTaskError } = useTask(PK, SK)
  const { addNotification, createMentionPayload } = useNotify()
  const [notificationsArray, setNotificationsArray] = useState<any[]>([])
  const [newNoteContent, setNewNoteContent] = useState<string>('')
  const [notes, setNotes] = useState<Note[]>([])
  const [secondNotes, setSecondNotes] = useState<Note[]>([])
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<MentionUser[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTask, setSelectedTask] = useState(null)
  const [taskComments, setTaskComments] = useState([]) // this will hold the current notes for that task
  const theme = useTheme()
  const [isFrameOpen, setIsFrameOpen] = useState<boolean>(false)
  const [selectedEmbedUrl, setSelectedEmbedUrl] = useState<string | null>(null)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const { mutate: sendNotification } = useNotifyMessage()
  const location = useLocation()
  const { activeUser } = useActiveUser()
  const { user: currentUser, isUserLoading } = useUser(activeUser?.user?.id)
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()
  const { selectedFile, setSelectedFile, selectedFiles, setSelectedFiles, showPicker } = useGooglePickerById(
    currentUser?.googleRefreshToken || '',
    currentUser?.user_uuid
  )
  // Extract the owners from each note so we can load their user data
  const userIds = notes.flatMap(note => note.owner || [])
  const { users, isLoading: isUsersLoading } = useUsersById(userIds)

  // Load notes from organization
  useEffect(() => {
    if (organization?.notes) {
      const sortedNotes = [...organization.notes].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      const filteredNotes = context === 'seo' ? filterNotesFn(sortedNotes, context) : sortedNotes
      console.log('sortedNotes', sortedNotes)
      const group1Notes = filteredNotes.slice(0, 3)
      const group2Notes = filteredNotes.slice(3)
      setNotes(filteredNotes)
      setSecondNotes(group2Notes)
    }
  }, [organization])
  useEffect(() => {
    if (selectedTask) {
      console.log('selectedTask', selectedTask)
    }
  }, [selectedTask])
  useEffect(() => {
    const taskParam = searchParams.get('task')
    if (taskParam && checklistItems && checklistItems.length) {
      const taskFromUrl = checklistItems.find(task => task.id === taskParam)
      if (taskFromUrl) {
        setSelectedTask(taskFromUrl)
        setTaskComments(taskFromUrl.comments || [])
      }
    }
  }, [searchParams, checklistItems])
  // -------------- Handlers --------------

  const handleContentChange = (html: string) => {
    setNewNoteContent(html)
  }

  // Called whenever the mention extension detects mentions in the editor
  const handleMentionsChange = (mentions: MentionUser[]) => {
    setSelectedUsers(mentions)
  }

  // Save the editor content manually (if needed)
  const handleSaveEditorContent = (html: string) => {
    console.log('html', html)
    setNewNoteContent(html)
  }

  const handleAddNote = async () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)

      // Optionally, create a block for tagged users (if desired)
      const tagsDisplay = selectedUsers.length
        ? `<div class="tagged-users" style="margin-top: 10px; display: flex; align-items: center; justify-content: flex-end; gap: 8px;">
            ${selectedUsers
              .map(
                user => `
                  <div style="display: flex; align-items: center;">
                    <img
                      src="${user.imageUrl}"
                      alt="${user.name}"
                      title="${user.name}"
                      style="width:36px; height:36px; border-radius:50%;"
                    />
                  </div>`
              )
              .join('')}
          </div>`
        : ''

      const noteContentWithTags = newNoteContent // or newNoteContent + tagsDisplay if needed

      // Build the new note.
      // If multiple files are attached, store them in an 'attachments' field.
      const newNote: Note = {
        note_id: uuidv4(),
        contents: noteContentWithTags,
        tagIds: selectedUsers.map(u => u.user_uuid || u.id),
        taggedUsers: selectedUsers.map(user => user),
        owner: activeUser?.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
        context: context || '',
        // For backwards compatibility, if only one file is attached, you might store in single fields.
        // But for multiple files, add an attachments array.
        attachments: selectedFiles && selectedFiles.length > 0 ? selectedFiles : selectedFile ? [selectedFile] : [],
        // Optionally, clear these single fields:
        iconLink: '',
        thumbnailLink: '',
        embedUrl: '',
      }

      const updatedNotes = [newNote, ...notes]
      setNotes(updatedNotes)

      // Save to your DB...
      updateItems(
        {
          updates: [
            {
              primaryKey: 'org_uuid',
              tableName: 'organizations',
              itemId: organization?.org_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            setIsCreatingNote(false)
            setNewNoteContent('')
            setSelectedUsers([])
            // Optionally, clear attachments:
            setSelectedFile(null)
            setSelectedFiles([])
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
            setIsCreatingNote(false)
          },
        }
      )
    }
  }
  // Send Slack notifications
  const handleSubmit = async () => {
    // Build a message from your mentions
    const baseUrl = process.env.REACT_APP_BASE_URL
    const slackNavUrl = selectedTask
      ? `${baseUrl}${location?.pathname}${location?.search}`
      : `${baseUrl}${location?.pathname}`
    const { selectedUsers: taggedUsers } = createMessageJsx(newNoteContent, selectedUsers, theme)
    // // Slack notifications
    const slackNotificationPromises = taggedUsers.map((user: MentionUser) => {
      const slackChannel = user.user_uuid || user.slackId || user.slackUserId || user.id
      if (slackChannel) {
        const payload = createSlackPayload({ ...user, slackId: slackChannel }, newNoteContent, slackNavUrl)
        return sendNotification(payload, {
          onSuccess: () => console.log(`Notification sent to ${user.name}`),
          onError: error => console.error(`Error sending notification to ${user.name}:`, error),
        })
      }
      return Promise.resolve()
    })

    try {
      await Promise.all(slackNotificationPromises)
    } catch (error) {
      console.error('Error in one or more Slack notifications:', error)
    }

    // Build local notifications data
    const notifyPayload = selectedTask
      ? createMentionPayload(
          taggedUsers,
          'mention',
          'success',
          'Mention',
          `You've been tagged in a message`,
          'nco',
          organization?.name,
          organization?.org_uuid,
          'imageUrl not here',
          `${location?.pathname}${location?.search}`
        )
      : createMentionPayload(
          taggedUsers,
          'mention',
          'success',
          'Mention',
          `You've been tagged in a message`,
          'nco',
          organization?.name,
          organization?.org_uuid,
          'imageUrl not here',
          location?.pathname
        )
    console.log('notifyPayload', notifyPayload)
    // Send batch notifications to your backend
    try {
      await addNotification(notifyPayload)
    } catch (error) {
      console.error('Error sending batch notifications:', error)
    }

    // Reset
    setNewNoteContent('')
    setSelectedUsers([])
  }

  // Combined add note + notifications
  const handleCreateAndNotify = async () => {
    try {
      if (selectedTask) {
        handleSaveTaskNote()
      } else {
        handleAddNote()
      }
      await handleSubmit()
    } catch (error) {
      console.error('Error submitting or adding note', error)
    }
  }
  // When the user clicks on a thumbnail, open the iframe preview.
  const openFrame = (embedUrl?: string, webContentLink?: string) => {
    console.log('webContentLink', webContentLink)
    if (embedUrl) {
      setSelectedEmbedUrl(embedUrl)
      setIsFrameOpen(true)
    }
    if (webContentLink) {
      setDownloadUrl(webContentLink)
    }
  }

  // Close the iframe preview.
  const closeFrame = () => {
    setIsFrameOpen(false)
    setSelectedEmbedUrl(null)
  }
  // Delete a note
  const handleDeleteNote = (noteId: string) => {
    const updatedNotes = notes.filter(note => note.note_id !== noteId)
    setNotes(updatedNotes)

    updateItems(
      {
        updates: [
          {
            primaryKey: 'org_uuid',
            tableName: 'organizations',
            itemId: organization?.org_uuid,
            fieldName: 'notes',
            newValue: updatedNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }
  const handleAttach = () => {
    if (organization?.googleDriveFolderId) {
      showPicker(organization?.googleDriveFolderId)
    }
  }
  // Update the URL query when a task is selected
  const handleTaskSelect = task => {
    if (task) {
      const searchParams = task?.SK?.startsWith('OPTIMIZATION') ? { task: task.name } : { task: task.id }
      setSelectedTask(task)
      setTaskComments(task?.comments || [])
      setSearchParams(searchParams)
    } else {
      // When clearing the selection, reset state and remove the task query parameter
      setSelectedTask(null)
      setTaskComments([])
      setSearchParams({})
    }
  }
  const handleSaveTaskNote = async () => {
    if (!selectedTask) {
      showSnackbar('Please select a task', 'error')
      return
    }
    const newNote: Note = {
      note_id: uuidv4(),
      contents: newNoteContent,
      tagIds: selectedUsers.map(u => u.user_uuid || u.id),
      taggedUsers: selectedUsers.map(user => user),
      owner: activeUser?.user ? [activeUser.user.id] : [],
      createdAt: new Date().toISOString(),
      context: context || '',
      attachments: selectedFiles && selectedFiles.length > 0 ? selectedFiles : selectedFile ? [selectedFile] : [],
      iconLink: '',
      thumbnailLink: '',
      embedUrl: '',
    }

    // Pass the full newNote object
    await onAddTaskComment(selectedTask, newNote)
    const updatedComments = [newNote, ...(selectedTask.comments || [])]
    setNewNoteContent('')
    setSelectedTask(prev => ({ ...prev, comments: updatedComments }))
    setTaskComments(updatedComments)
  }
  const handleDownload = (url: string) => {
    setIsDownloading(true)
    try {
      const a = document.createElement('a')
      a.href = url
      a.download = '' // You can set a specific filename, e.g. a.download = 'filename.ext'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setIsDownloading(false)
    } catch (e) {
      console.error('error downloading file', e)
      setIsDownloading(false)
    }
  }

  // Loading states
  if (isOrganizationLoading || isUsersLoading) {
    return <DetailsLoading />
  }
  if (isOrganizationError || !organization) {
    return <Typography>Error loading organization details or no organization data available.</Typography>
  }

  // --- Render ---
  return (
    <Grid container pt={1} sx={{ height: '100%', display: 'flex' }}>
      <Grid item xs={6}>
        <Box
          sx={{
            mt: 1.2,
            ml: 2.5,
            mr: 2.5,
            pb: 1.2,
            justifyContent: 'center',
            textAlign: 'center',
            height: 'calc(100vh - 220px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {/* Render the Rich Text Editor */}
          <Editor
            onAttach={handleAttach}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            selectedTask={selectedTask ?? null}
            onContentChange={handleContentChange}
            onSave={handleCreateAndNotify}
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            onMentionsChange={handleMentionsChange}
            height={!expanded ? '50vh' : '40vh'}
            // currentUser={currentUser || {}}
            // orgFolderId={organization?.googleDriveFolderId}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          maxHeight: !expanded ? '80vh' : '65vh',
          overflowY: 'auto',
          transition: 'all 0.3s ease',
          pr: 3,
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ mb: 2, py: 2 }}>
          <TaskSelector
            checklistItems={checklistItems}
            // onTaskSelected={task => {
            //   setSelectedTask(task)
            //   setTaskComments(task?.comments || [])
            // }}
            onTaskSelected={handleTaskSelect}
            selectedTask={selectedTask}
          />
        </Box>

        <CommentList
          notes={selectedTask ? taskComments : notes || []}
          users={users}
          openFrame={openFrame}
          onDeleteNote={handleDeleteNote}
        />
      </Grid>

      {/* Render the iframe preview in an overlay */}
      {isFrameOpen && selectedEmbedUrl && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 1300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={closeFrame} // clicking the overlay closes the preview
        >
          <Box sx={{ width: '80%', height: '80%', position: 'relative' }}>
            {isDownloading ? (
              <DrawerLoading />
            ) : (
              <>
                <iframe src={selectedEmbedUrl} style={{ width: '100%', height: '100%' }} title='File Preview' />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: '100%' }}>
                  <Button
                    variant='outlined'
                    onClick={closeFrame}
                    sx={{
                      position: 'absolute',
                      top: 17,
                      right: 68,
                      bgcolor: theme.palette.background.default,
                      '&:hover': {
                        bgcolor: theme.palette.background.thumbnail,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      Close
                    </Typography>
                  </Button>
                  {downloadUrl ? (
                    <Button
                      variant='outlined'
                      onClick={e => {
                        e.stopPropagation()
                        handleDownload(downloadUrl)
                      }}
                      sx={{
                        position: 'absolute',
                        top: 17,
                        right: 168,
                        bgcolor: theme.palette.background.default,
                        '&:hover': {
                          bgcolor: theme.palette.background.thumbnail,
                        },
                      }}
                    >
                      <Typography sx={{ color: theme.palette.text.primary }}>Download</Typography>
                    </Button>
                  ) : null}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default CommentsInput
