import { Box, FormControl, Select, MenuItem, Grid, Typography, IconButton, SelectChangeEvent } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import React from 'react'
import RenderOnboardTeam from './RenderOnboardTeam'
import AddTaskIcon from '@mui/icons-material/AddTask'
import RenderCreativeTeaam from './RenderCreativeTeam'

interface ToolbarProps {
  selectedRole: string
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>
  checklistType: string
  handleAddRow: () => void
  deal: any
  onRoleChange: () => void
  context: string
}

const DrawerTasksToolbar: React.FC<ToolbarProps> = ({
  deal,
  selectedRole,
  setSelectedRole,
  onRoleChange,
  handleAddRow,
  checklistType,
  context,
}) => {
  const roles = ['AM', 'PM', 'DMS', 'SEO', 'QA', 'BD', 'MIA', 'Integration']
  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedRole(e.target.value)
  }

  return (
    <GridToolbarContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, pl: 1 }}>
          <FormControl sx={{ display: 'flex', height: 50 }} fullWidth>
            <Select value={selectedRole} onChange={handleChange} sx={{ height: 40, borderRadius: '4px' }}>
              <MenuItem value='All'>All Roles</MenuItem>
              {roles.map(role => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pl={2}>
            {context === 'creative' || context === 'website' ? (
              <RenderCreativeTeaam deal={deal} onRoleChange={onRoleChange} />
            ) : (
              <RenderOnboardTeam deal={deal} onRoleChange={onRoleChange} />
            )}
          </Grid>
        </Box>
        <Typography variant='h6'>{checklistType}</Typography>
        <IconButton onClick={handleAddRow}>
          <AddTaskIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </Box>
    </GridToolbarContainer>
  )
}

export default DrawerTasksToolbar
