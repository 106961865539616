import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateMassItems = async ({ updates }) => {
  console.log('[UpdateItems] called with updates: ', updates)
  const url = `${baseUrl}/aws/updatemassitems`
  try {
    const response = await axios.put(url, { updates })
    if (response.status !== 200) throw new Error('Network response was not ok')
    return response.data
  } catch (error) {
    console.error('Failed to update items:', error)
    throw error
  }
}

export const useUpdateMassItems = (invalidateQueries = []) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: updateMassItems,
    onSuccess: (data, variables) => {
      const affectedQueries = Array.from(new Set(variables.updates.map(u => u.tableName)))
      console.log('affectedQueries', affectedQueries)
      affectedQueries.forEach(queryKey => {
        queryClient.invalidateQueries(queryKey)
      })
    },
    onError: error => {
      console.error('[useUpdateMassItems] Error updating items:', error)
    },
  })

  return mutation
}
