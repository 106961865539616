export const boardViewMapping: Record<string, string> = {
  '/nco': 'nco',
  '/creative': 'creative',
  '/seo': 'seo',
  '/website': 'website',
  '/mtb': 'mtb',
  '/deals': 'deals',
  '/organizations': 'organizations',
  '/contacts': 'contacts',
  '/leads': 'leads',
  '/teamtasks': 'teamtasks',
  '/campaignchecklists': 'campaignchecklists',
  '/ads': 'ads',
}
