// useDownloadImage.ts
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../../queryMutations/mutationFunctions'

/**
 * Downloads an image by S3 key.
 * Expects the backend endpoint to return an object with a `url` property.
 */
const downloadImage = async (key: string): Promise<string> => {
  const response = await axios.get<{ url: string }>(`${baseUrl}/aws/downloadimage`, { params: { key } })
  return response.data.url
}

/**
 * Custom hook for downloading an image.
 */
export const useDownloadImage = (): UseMutationResult<string, unknown, string, unknown> => {
  return useMutation<string, unknown, string>({
    mutationFn: downloadImage,
    onSuccess: data => {
      console.log('Success!')
    },
  })
}
