import React, { useState, useEffect } from 'react'
import { Box, Typography, List, ListItem, ListItemText, IconButton, Avatar, Paper, Grid, useTheme } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { useUsersById } from '../../api/customHooks/useUsersById'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import IntegrationRichTextField from './IntegrationRichTextField'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider'

const IntegrationNotes = ({ expanded }) => {
  const { orgId } = useParams()
  const { pinned } = useLayoutContext()
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const [newIntegrationNoteContent, setNewIntegrationNoteContent] = useState('')
  const [integrationNotes, setIntegrationNotes] = useState([])
  const { mutate: updateItems } = useUpdateItems()
  const { showSnackbar } = useSnackbarContext()
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const theme = useTheme()
  const integrationUserIds = ['U062JDP8R5X', 'U01FK2W9YNS', 'U049PRPH6TF', 'U015KLM51B6']
  // Extract user IDs from integrationNotes
  const userIds = integrationNotes.flatMap(note => note.owner || [])
  const { users, isLoading: isUsersLoading } = useUsersById(userIds)

  useEffect(() => {
    if (organization && organization.integrationNotes) {
      const sortedIntegrationNotes = [...organization.integrationNotes].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      setIntegrationNotes(sortedIntegrationNotes)
    }
  }, [organization])

  const handleAddNote = () => {
    if (newIntegrationNoteContent && newIntegrationNoteContent !== '<p></p>') {
      setIsCreatingNote(true)

      const newNote = {
        note_id: uuidv4(),
        contents: newIntegrationNoteContent,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedIntegrationNotes = [...integrationNotes, newNote]
      setIntegrationNotes(updatedIntegrationNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'org_uuid',
              tableName: 'organizations',
              itemId: organization.org_uuid,
              fieldName: 'integrationNotes',
              newValue: updatedIntegrationNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            handleClear()
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
            handleClear()
          },
        }
      )
    }
  }

  const handleDeleteNote = noteId => {
    const updatedIntegrationNotes = integrationNotes.filter(note => note.note_id !== noteId)
    setIntegrationNotes(updatedIntegrationNotes)

    updateItems(
      {
        updates: [
          {
            primaryKey: 'org_uuid',
            tableName: 'organizations',
            itemId: organization.org_uuid,
            fieldName: 'integrationNotes',
            newValue: updatedIntegrationNotes,
          },
        ],
      },
      {
        onSuccess: () => {
          showSnackbar('Note deleted successfully', 'success')
        },
        onError: err => {
          console.error('Failed to delete note:', err)
          showSnackbar('Failed to delete note', 'error')
        },
      }
    )
  }
  const handleClear = () => {
    setIsCreatingNote(false)
    setNewIntegrationNoteContent('') // This clears the note content state
  }
  if (isOrganizationLoading || isUsersLoading) {
    return <DetailsLoading />
  }

  if (isOrganizationError || !organization) {
    return <Typography>Error loading organization details or no organization data available.</Typography>
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* Note addition form */}
        <Box
          sx={{
            // p: 1.2,
            // ml: 2.5,
            // mr: 2.5,
            pb: 1.5,
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <IntegrationRichTextField
            content={newIntegrationNoteContent}
            onContentChange={setNewIntegrationNoteContent}
            setIsCreatingNote={setIsCreatingNote}
            isCreatingNote={isCreatingNote}
            handleAddNote={handleAddNote}
            justify={'left'}
          />
        </Box>
      </Grid>
      {/* List of integrationNotes */}
      <Grid item xs={12}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height:
              pinned && !expanded
                ? 'calc(100vh - 390px)'
                : !pinned && expanded
                ? `calc(100vh - 495px)`
                : pinned && expanded
                ? `calc(100vh - 400px)`
                : 'calc(100vh - 360px)',
            transition: 'height 0.3s ease',
            overflowY: 'auto',
          }}
        >
          {integrationNotes.map(note => {
            const noteOwner = users.find(user => user.id === note.owner?.[0])
            return (
              <ListItem
                key={note.note_id}
                component={Paper}
                elevation={1}
                sx={{
                  mb: 2,
                  p: 2,
                  display: 'flex',
                  alignItems: 'flex-start',
                  bgcolor: theme.palette.background.section,
                }}
              >
                {noteOwner && (
                  <Avatar alt={noteOwner.name} src={noteOwner.image_512} sx={{ width: 48, height: 48, mr: 2 }} />
                )}
                <ListItemText
                  primary={
                    <>
                      {noteOwner && (
                        <Typography variant='subtitle2' fontWeight='bold'>
                          {noteOwner.name} - {new Date(note.createdAt).toLocaleString()}
                        </Typography>
                      )}
                    </>
                  }
                  secondary={<Typography variant='body2' dangerouslySetInnerHTML={{ __html: note.contents }} />}
                />
                {integrationUserIds.includes(activeUser?.user?.id) && (
                  <IconButton edge='end' aria-label='delete' onClick={() => handleDeleteNote(note.note_id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            )
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default IntegrationNotes
