import React from 'react'
import { useParams } from 'react-router-dom'
import GroboticDataGrid from '../DataGrid/GroboticDataGrid.js'
import { ncoStatusFilters } from '../DataGrid/filterArrays.js'
import { ncoTeamTasks } from './ncoTaskObjects.js'
import { useTheme } from '@mui/material'
import renderNcoColumns from '../DataGrid/createColumns/renderNcoColumns.js'
import { data1, data2, data3, data4, data5, data6, data7, combineArrays, countSubitemsFrequency } from './mondayData.js'
import CreateCustomChecklist from '../DataGrid/components/CreateCustomChecklist.jsx'

const initialSortModel = [
  { field: 'dealStatus', sort: 'desc' },
  { field: 'organizations', sort: 'asc' },
]

const initialVisibilityState = {
  implementationFee: false,
  total: false,
  status: false,
  id: false,
  strategy: false,
  vertical: false,
  stage: false,
  contractId: false,
  type: false,
  startDate: false,
  endDate: false,
  products: false,
  contractedBy: false,
  isGoogleDisplay: false,
  isGoogleSearch: false,
  isLinkedIn: false,
  isMetaAdvertising: false,
  isMicrosoftAdvertising: false,
  isSimplifi: false,
  isSnapchat: false,
  isSpotify: false,
  isTikTok: false,
  isYoutube: false,
  isSeo1: false,
  isSeo2: false,
  isSeo3: false,
  isSeo4: false,
  seoTier: false,
  creativeLead: false,
  AM: false,
  DMS: false,
  SEO: false,
  MIA: false,
  QA: false,
  PM: false,
  lastModified: false,
}

const NCOPage = () => {
  const theme = useTheme()
  const { dealId, orgId } = useParams()
  const filters = {
    status: ['Completed', 'Completed Internally'],
    stage: 'Deal Won',
    type: 'MSA',
    // productCategories: ['Digital'],
    // productSubcategories: [
    //   'Videography',
    //   'Graphic Design', // Filters deals with Search category in products
    // ],
    excludedDealStatus: ['Expired', 'Expired (system)', 'Cancelled'],
  }
  return (
    <GroboticDataGrid
      dealId={dealId}
      filters={filters}
      queryKey={['ncoPage', 'deals']}
      route={'nco'}
      tasks={ncoTeamTasks}
      context={'nco'}
      initialVisibilityState={initialVisibilityState}
      initialSortModel={initialSortModel}
      statusKey={'onboardingStatus'}
      statusLabel={'Onboarding Status'}
      filterOptions={ncoStatusFilters}
      boardTheme={theme.palette.icon.nco}
      renderFunction={renderNcoColumns}
    />
  )
}
export default NCOPage
