import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

export const getFilteredDeals = async (filters = {}) => {
  try {
    const url = `${baseUrl}/aws/deals/filter`

    if (!filters || Object.keys(filters).length === 0) {
      console.warn('No filters provided for getFilteredDeals')
      return []
    }

    const response = await axios.get(url, {
      params: { filters: JSON.stringify(filters) },
    })

    return response.data || []
  } catch (error) {
    console.error('Error fetching data:', error)
    return []
  }
}

export const useFilteredDeals = (enabled, queryKey = ['dealsByType'], filters) => {
  const validFilters = filters && Object.keys(filters).length > 0 ? filters : {}

  const { data: filteredDeals, isLoading: isFilteredDealsLoading, isError: isFilteredDealsError } = useQuery({
    queryKey: [queryKey, validFilters],
    queryFn: () => getFilteredDeals(validFilters),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10,
    enabled: enabled && Object.keys(validFilters).length > 0,
  })

  return {
    filteredDeals,
    isFilteredDealsLoading,
    isFilteredDealsError,
  }
}
