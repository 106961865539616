import { Box, Typography, Grid } from '@mui/material'
import React from 'react'
import FieldComponent from './FieldComponent.tsx'
import { FieldConfig, MarketingFormData } from './types'
import { renderColorByString } from '../DataGrid/renderColorByString'

interface RenderTypeFieldsProps {
  channel: string
  channelFields: Record<string, FieldConfig>
  formData: MarketingFormData
  onChange: (event: {
    target: {
      name: string
      value: unknown
    }
  }) => void
}

const RenderTypeFields: React.FC<RenderTypeFieldsProps> = ({ formData, channel, channelFields, onChange }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          pb: 0.5,
        }}
      >
        <Typography sx={{ color: renderColorByString(channel), fontSize: 18, fontWeight: 'bold' }}>
          {channel}
        </Typography>
        {/* <Typography variant='subtitle1'>Details</Typography> */}
      </Box>
      <Grid container spacing={2} px={3.5}>
        {Object.entries(channelFields).map(([key, config]) => {
          const fullWidthKeys = [
            'searchCampaignPlatform',
            'socialCampaignPlatform',
            'displayCampaignPlatform',
            'videoCampaignPlatform',
            'seoCampaignChecklist',
            'seoEnable',
            'seoAdApprovals',
            'seoCreate',
            'seoNco',
            'seoUpdate',
            'seoPause',
            'integrationNco',
            'integrationPause',
            'integrationEnable',
            'integrationCampaignChecklist',
            'integrationAdApprovals',
            'integrationUpdate',
          ]

          const gridSize = fullWidthKeys.includes(key) ? 12 : 6
          return (
            <Grid item xs={gridSize} key={key}>
              <FieldComponent name={key} value={formData[key] || ''} onChange={onChange} {...config} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default RenderTypeFields
