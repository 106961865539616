import * as React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useDeals } from '../../api/aws/useDeals'
import { Box, CircularProgress } from '@mui/material'
import { CustomTreeDataGroupingCell } from './CustomTreeDataGroupingCell.tsx'
import { gridContainerStyles } from '../Organizations/style.js'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'

export default function PivotTable() {
  const { pinned } = useLayoutContext()
  const { data: organizations = [], isLoading: isOrganizationsLoading } = useOrganizations(true, ['treeOrgs'])
  const { deals = [], isDealsLoading } = useDeals(true, ['treeDeals'])
  const loading = isOrganizationsLoading || isDealsLoading
  // 🧠 Create a map of org ID => org name for quick lookup
  const orgNameMap = React.useMemo(() => {
    const map = {}
    organizations.forEach(org => {
      map[org.id] = org.name
    })
    return map
  }, [organizations])

  // 🌳 Combine orgs + deals into tree-friendly flat data
  const combinedData = React.useMemo(() => {
    if (loading) return []

    const orgRows = organizations.map(org => ({
      id: org.id,
      type: 'organization',
      name: org.name,
      website: org.website,
      parentId: null,
    }))

    const dealRows = deals.map(deal => {
      const orgId = deal.organizations?.[0] || 'unknown-org'
      return {
        id: deal.id,
        type: 'deal',
        name: deal.name,
        website: '', // Optional: deal.website if needed
        parentId: orgId,
      }
    })

    return [...orgRows, ...dealRows]
  }, [organizations, deals, loading])

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: params => {
        const displayName = params.row.name
        return params.row.type === 'organization' ? <strong>🏢 {displayName}</strong> : <>📄 {displayName}</>
      },
    },
    { field: 'website', headerName: 'Website', flex: 1 },
  ]

  return (
    <Box
      sx={{
        position: 'relative',
        ...gridContainerStyles,
        pt: pinned ? '60px' : 0,
        // transition: 'height 0.3s ease', // or .5s, etc.
        transition: 'all 0.3s ease-in-out',
        height: '98%',
        // height: pinned ? 'calc(100vh - 80px)' : 'calc(100vh - 20px)',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <DataGridPro
          rows={combinedData}
          columns={columns}
          getRowId={row => row.id}
          treeData
          getTreeDataPath={row => {
            if (row.type === 'organization') {
              return [`${row.name}___${row.id}`]
            }
            if (row.type === 'deal') {
              const orgName = orgNameMap[row.parentId] || 'Unknown Org'
              return [`${orgName}___${row.parentId}`, `${row.name}___${row.id}`]
            }
            return [`${row.name}___${row.id}`]
          }}
          groupingColDef={{
            headerName: 'Group',
            flex: 1,
            renderCell: params => <CustomTreeDataGroupingCell {...params} />,
          }}
          defaultGroupingExpansionDepth={0}
          disableRowSelectionOnClick
        />
      )}
    </Box>
  )
}

// import * as React from 'react'
// import { DataGridPro } from '@mui/x-data-grid-pro'
// import { useDemoData } from '@mui/x-data-grid-generator'

// export default function PivotTable() {
//   const { data, loading } = useDemoData({
//     dataSet: 'Employee',
//     rowLength: 1000,
//     treeData: { maxDepth: 2, groupingField: 'name', averageChildren: 200 },
//   })
//   console.log('data', data)
//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGridPro loading={loading} {...data} />
//     </div>
//   )
// }
// import * as React from 'react'
// import {
//   DataGridPro,
//   GridRenderCellParams,
//   useGridApiContext,
//   GridColDef,
//   GridRowsProp,
//   DataGridProProps,
//   useGridSelector,
//   gridFilteredDescendantCountLookupSelector,
// } from '@mui/x-data-grid-pro'
// import Box from '@mui/material/Box'
// import Button, { ButtonProps } from '@mui/material/Button'

// export const isNavigationKey = (key: string) =>
//   key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' '

// function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
//   const { id, field, rowNode } = props
//   const apiRef = useGridApiContext()
//   const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
//   const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

//   const handleClick: ButtonProps['onClick'] = event => {
//     if (rowNode.type !== 'group') {
//       return
//     }

//     apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
//     apiRef.current.setCellFocus(id, field)
//     event.stopPropagation()
//   }

//   return (
//     <Box sx={{ ml: rowNode.depth * 4 }}>
//       <div>
//         {filteredDescendantCount > 0 ? (
//           <Button onClick={handleClick} tabIndex={-1} size='small'>
//             See {filteredDescendantCount} employees
//           </Button>
//         ) : (
//           <span />
//         )}
//       </div>
//     </Box>
//   )
// }

// interface Row {
//   hierarchy: string[]
//   jobTitle: string
//   recruitmentDate: Date
//   id: number
// }

// const rows: GridRowsProp<Row> = [
//   {
//     hierarchy: ['Sarah'],
//     jobTitle: 'Head of Human Resources',
//     recruitmentDate: new Date(2020, 8, 12),
//     id: 0,
//   },
//   {
//     hierarchy: ['Thomas'],
//     jobTitle: 'Head of Sales',
//     recruitmentDate: new Date(2017, 3, 4),
//     id: 1,
//   },
//   {
//     hierarchy: ['Thomas', 'Robert'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2020, 11, 20),
//     id: 2,
//   },
//   {
//     hierarchy: ['Thomas', 'Karen'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2020, 10, 14),
//     id: 3,
//   },
//   {
//     hierarchy: ['Thomas', 'Nancy'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2017, 10, 29),
//     id: 4,
//   },
//   {
//     hierarchy: ['Thomas', 'Daniel'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2020, 7, 21),
//     id: 5,
//   },
//   {
//     hierarchy: ['Thomas', 'Christopher'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2020, 7, 20),
//     id: 6,
//   },
//   {
//     hierarchy: ['Thomas', 'Donald'],
//     jobTitle: 'Sales Person',
//     recruitmentDate: new Date(2019, 6, 28),
//     id: 7,
//   },
//   {
//     hierarchy: ['Mary'],
//     jobTitle: 'Head of Engineering',
//     recruitmentDate: new Date(2016, 3, 14),
//     id: 8,
//   },
//   {
//     hierarchy: ['Mary', 'Jennifer'],
//     jobTitle: 'Tech lead front',
//     recruitmentDate: new Date(2016, 5, 17),
//     id: 9,
//   },
//   {
//     hierarchy: ['Mary', 'Jennifer', 'Anna'],
//     jobTitle: 'Front-end developer',
//     recruitmentDate: new Date(2019, 11, 7),
//     id: 10,
//   },
//   {
//     hierarchy: ['Mary', 'Michael'],
//     jobTitle: 'Tech lead devops',
//     recruitmentDate: new Date(2021, 7, 1),
//     id: 11,
//   },
//   {
//     hierarchy: ['Mary', 'Linda'],
//     jobTitle: 'Tech lead back',
//     recruitmentDate: new Date(2017, 0, 12),
//     id: 12,
//   },
//   {
//     hierarchy: ['Mary', 'Linda', 'Elizabeth'],
//     jobTitle: 'Back-end developer',
//     recruitmentDate: new Date(2019, 2, 22),
//     id: 13,
//   },
//   {
//     hierarchy: ['Mary', 'Linda', 'William'],
//     jobTitle: 'Back-end developer',
//     recruitmentDate: new Date(2018, 4, 19),
//     id: 14,
//   },
// ]

// const columns: GridColDef<Row>[] = [
//   {
//     field: 'name',
//     headerName: 'Name',
//     valueGetter: (value, row) => {
//       const hierarchy = row.hierarchy
//       return hierarchy[hierarchy.length - 1]
//     },
//   },
//   { field: 'jobTitle', headerName: 'Job Title', width: 200 },
//   {
//     field: 'recruitmentDate',
//     headerName: 'Recruitment Date',
//     type: 'date',
//     width: 150,
//   },
// ]

// const getTreeDataPath: DataGridProProps['getTreeDataPath'] = row => row.hierarchy

// const groupingColDef: DataGridProProps['groupingColDef'] = {
//   headerName: 'Hierarchy',
//   renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
// }

// export default function PivotTable() {
//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGridPro
//         treeData
//         rows={rows}
//         columns={columns}
//         getTreeDataPath={getTreeDataPath}
//         groupingColDef={groupingColDef}
//       />
//     </div>
//   )
// }
