import React from 'react'
import { List, ListItem, Typography, ListItemIcon, ListItemButton, ListItemText, useTheme, Badge } from '@mui/material'
import ConnectedUsers from '../../../context/Websocket/ConnectedUsers'
import PeopleIcon from '@mui/icons-material/People'
import { useWebsocketContext } from '../../../context/Websocket/WebsocketProvider'

const ConnectedUsersNav = ({ open, isConnectedUsersOpen, handleOpenConnectedUsers, handleCloseConnectedUsers }) => {
  const theme = useTheme()
  const { connectedUsers } = useWebsocketContext()

  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  // Because you only have a single item, you *could* skip the .map entirely,
  // but we'll show the structure in case you add more items later.
  const navItems = [{ label: 'Connected Users' }]

  return (
    <>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          m: 0, // remove default list margin/padding
        }}
      >
        {navItems.map(item => (
          <ListItem
            key={item.label}
            disablePadding
            sx={{
              display: 'inline-flex',
              width: 'auto',
            }}
          >
            <ListItemButton
              onClick={handleOpenConnectedUsers}
              sx={{
                minWidth: 50, // or 60, adjust to taste
                minHeight: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: bgHoverColor,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Badge
                  badgeContent={
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(63, 195, 128, 1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {Array.isArray(connectedUsers) ? connectedUsers.length : 0}
                    </Typography>
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <PeopleIcon
                    sx={{
                      height: 22,
                      width: 22,
                    }}
                  />
                </Badge>
              </ListItemIcon>

              {/* If you want the text always visible in a horizontal layout, 
                  you can remove or adjust references to 'open' */}
              {/* <ListItemText
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
                  }}
                >
                  {item.label}
                </Typography>
              </ListItemText> */}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* Render the ConnectedUsers modal if open */}
      {isConnectedUsersOpen && (
        <ConnectedUsers
          isConnectedUsersOpen={isConnectedUsersOpen}
          handleCloseConnectedUsers={handleCloseConnectedUsers}
        />
      )}
    </>
  )
}

export default ConnectedUsersNav
