import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions'

export const useOrganizationDealsById = (orgId: string | null) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['organizationById', 'deals', orgId],
    queryFn: async () => {
      if (!orgId) return null
      const { data } = await axios.get(`${baseUrl}/aws/organizations/${orgId}`)
      console.log('DATA', data)
      // ✅ Ensure response matches OptionType structure
      const organization = {
        org_uuid: data.org_uuid || '',
        name: data.name || '',
        dba: data.dba || '',
        website: data.website || '',
        AM: data.AM || [],
        DMS: data.DMS || [],
        deal_uuid: data.deal_uuid || '',
        deals: data.deals || [], // ✅ Filter deals with onboardingStatus
        subfolderIds: data.subfolderIds || null,
        googleDriveFolderId: data.googleDriveFolderId || '',
      }

      // ✅ Sort deals based on `status`, with "Active" first
      organization.deals = organization.deals.sort((a, b) => {
        const order = ['Active', 'Pending', 'Inactive'] // Sorting order
        return (order.indexOf(a.status) || Infinity) - (order.indexOf(b.status) || Infinity)
      })

      return organization
    },
    enabled: !!orgId,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  })

  return { organization: data, isOrganizationLoading: isLoading, isOrganizationError: isError }
}
