import React, { useState, useMemo, useEffect } from 'react'
import { Box, Button, Typography, IconButton, TextField, Badge, useTheme } from '@mui/material'
import { DataGridPro, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import { useLayoutContext } from '../../context/LayoutProvider/LayoutProvider.js'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { renderColorByString } from '../DataGrid/renderColorByString.js'
import Channels from '../NCO/Channels.js'
import { useBatchPostChecklists } from '../../api/aws/checklists/useBatchPostChecklists.ts'
import { useBatchUpdateCreativeChecklists } from '../../api/aws/checklists/useBatchUpdateCreativeChecklists.ts'
import CustomChecklistModal from '../DataGrid/components/CustomChecklistModal.tsx'
import { useAddChecklistTask } from '../../api/aws/checklists/useBatchAddChecklistTask.tsx'
import RemoveIcon from '@mui/icons-material/Remove'
import { useRemoveChecklistTask } from '../../api/aws/checklists/useRemoveCheclistTask.tsx'
import AddTaskIcon from '@mui/icons-material/AddTask'
import UserImageComponent from '../Users/UserImageComponent.js'
import DrawerTasksToolbar from '../DataGrid/components/DrawerTasksToolbar.tsx'

const getTaskStatus = (task, ncoTasks, completedTasks, checklistProgress = {}, checklists = {}) => {
  let status = task.status || ncoTasks?.[task.id] || 'N/A'

  if (status === 'Done') {
    completedTasks.add(task.id)
  }

  return status
}

const MtbTasksGrid = ({
  deal,
  handleStatusChange,
  setUpdates,
  checklistProgress,
  checklists,
  expanded,
  checklistType,
  context,
}) => {
  const { pinned } = useLayoutContext()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { dealId } = useParams()
  const [selectedRole, setSelectedRole] = useState('All')
  const [openModal, setOpenModal] = useState(false)
  const [taskInput, setTaskInput] = useState('')
  const [customChecklists, setCustomChecklists] = useState([]) // Store new checklists
  const [newRows, setNewRows] = useState([]) // Store newly added rows
  const [checklistTitle, setChecklistTitle] = useState('')
  const { mutate: postChecklists } = useBatchPostChecklists()
  const { mutate: addChecklistTask } = useAddChecklistTask()
  const { mutate: removeChecklistTask } = useRemoveChecklistTask()
  const statusOptions = ['N/A', 'In Progress', 'Done']
  const [localInput, setLocalInput] = useState('') // Local state
  const [inputStates, setInputStates] = useState({})
  const [newTaskName, setNewTaskName] = useState('')
  const [newRole, setNewRole] = useState('')
  const [checklistIds, setChecklistIds] = useState(null)
  const [sectionIndex, setSectionIndex] = useState(0)
  const apiRef = useGridApiRef()
  const rowCount = typeof apiRef?.current?.getRowsCount === 'function' ? apiRef.current.getRowsCount() : 0
  const lastRowIndex = rowCount - 1
  useEffect(() => {
    const generalChecklist = checklistType
      ? checklists?.find(chk => chk.type === checklistType)
      : checklists?.find(chk => chk.type === 'General')

    if (generalChecklist) {
      const { PK, SK, sections } = generalChecklist
      if (PK && SK) {
        setChecklistIds({ PK, SK })
      }
      if (sections && sections[0]?.sectionIndex !== undefined) {
        setSectionIndex(sections[0].sectionIndex)
      }
    }
  }, [checklists, checklistType])

  const orderedTasks = useMemo(() => {
    if (!deal) return []

    let completedTasks = new Set()
    const generalChecklist = checklistType
      ? checklists?.find(chk => chk.type === checklistType)
      : checklists?.find(chk => chk.type === 'General')

    // No state updates here!
    const generalTasks =
      generalChecklist?.sections?.flatMap(section =>
        section.items.map(task => ({
          id: task.id,
          role: task.role,
          name: task.title,
          status: task.status || 'In Progress',
          dependencies: task.dependencies || [],
          orderIndex: task?.itemIndex,
        }))
      ) || []

    // Combine existing and new rows
    const allTasks = [...generalTasks, ...newRows].map((task, index) => {
      const status = getTaskStatus(task, checklistProgress, completedTasks, checklists)
      const canEdit = task.dependencies.every(dep => completedTasks.has(dep))
      return {
        ...task,
        status,
        canEdit,
        uniqueId: `${task.key}-${task.id}-${index}`,
        onboardingTeam: deal?.onboardingTeam,
      }
    })

    return selectedRole === 'All' ? allTasks : allTasks.filter(task => task.role === selectedRole)
  }, [deal, checklistProgress, checklists, selectedRole, newRows])

  /** 🔹 Open/Close Modal */
  const handleOpenModal = title => {
    console.log('🔍 handleOpenModal triggered by:', title)
    if (!title) {
      console.log('⚠️ No title provided, preventing modal from opening.')
      return
    }

    setChecklistTitle(title)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setTaskInput('')
    setOpenModal(false)
  }

  const handleAddRow = () => {
    const newIndex = orderedTasks.length
    const newTaskId = `newTask_${newIndex}`

    const newTask = {
      id: newTaskId,
      name: newTaskName ?? '',
      role: '',
      status: 'In Progress',
      dependencies: orderedTasks.length > 0 ? [orderedTasks[orderedTasks.length - 1].id] : [],
      orderIndex: newIndex,
      PK: checklistIds?.PK || `DEAL_${deal?.deal_uuid}`,
      SK:
        checklistIds?.SK || `CHECKLIST_CUSTOM_${Date.now().toString(36)}_${Math.random().toString(36).substring(2, 8)}`,
      isNew: true,
    }

    setNewRows(prev => {
      const updatedRows = [...prev, newTask]
      console.log('✅ New Rows After Adding:', updatedRows)
      // Start editing the "role" cell for the new row.
      setTimeout(() => {
        // Ensure the method exists and the cell is in view mode.
        if (typeof apiRef?.current?.startCellEditMode === 'function') {
          apiRef.current.startCellEditMode({ id: newTaskId, field: 'role' })
        }
      }, 100)
      return updatedRows
    })
  }

  const handleRowChange = (rowId, value) => {
    console.log(`Updating row ${rowId} - name: ${value}`)

    apiRef.current.setEditCellValue({ id: rowId, field: 'name', value })
    setInputStates(prev => ({
      ...prev,
      [rowId]: value, // ✅ Ensure spaces are stored properly
    }))
  }
  const onRowNameChange = value => {
    console.log('VALUE', value)
    setNewTaskName(value)
  }
  const onRowRoleChange = value => {
    console.log('VALUE', value)
    setNewRole(value)
  }

  /** ✅ Save new rows to backend */
  const handleSaveNewRows = () => {
    if (newRows.length === 0) return
    console.log('newRows', newRows)
    const tasksToAdd = newRows.map(task => ({
      PK: task.PK || checklistIds?.PK,
      SK: task.SK || checklistIds?.SK,
      sectionIndex: sectionIndex,
      itemId: task.id,
      name: newTaskName,
      role: task.role,
      status: task.status,
      dependencies: task.dependencies,
      itemIndex: task.orderIndex,
    }))

    console.log('🆕 Adding new tasks:', tasksToAdd)

    addChecklistTask(tasksToAdd, {
      onSuccess: () => {
        console.log('✅ New tasks added!')
        setNewRows([])
        queryClient.invalidateQueries(['checklists'])
      },
      onError: error => {
        console.error('❌ Failed to add new tasks:', error)
      },
    })
  }
  const handleChange = (rowId, currentStatus) => {
    const checklist = checklistType
      ? checklists?.find(chk => chk.type === checklistType)
      : checklists?.find(chk => chk.type === 'General') // Ensure correct checklist
    if (!checklist) return

    // Determine next status based on the currentStatus passed in
    const currentIndex = statusOptions.indexOf(currentStatus)
    const nextStatus = statusOptions[(currentIndex + 1) % statusOptions.length]

    // Call the parent’s function with the checklist, task id, and new status
    handleStatusChange(checklist, rowId, nextStatus)
  }

  const handleRemoveTaskRow = task => {
    // If task is new (not saved to backend), remove it from local state:
    if (task.isNew) {
      setNewRows(prev => prev.filter(t => t.id !== task.id))
      return
    }

    const taskToRemove = {
      PK: checklistIds.PK,
      SK: checklistIds.SK,
      itemIndex: task?.orderIndex,
      sectionIndex, // from state, if applicable
      itemId: task?.id, // assuming row.id matches the itemId in DynamoDB
    }
    removeChecklistTask(taskToRemove)
  }
  const handleFieldChange = async (fieldName, newValue) => {
    if (deal[fieldName] !== newValue) {
      setUpdates(prev => ({ ...prev, [fieldName]: newValue }))
      await queryClient.setQueryData(['deal', dealId], old => ({
        ...old,
        [fieldName]: newValue,
      }))
    }
  }
  const gridHeight = useMemo(() => {
    const baseOffset = context === 'nco' ? 245 : 195
    const additionalOffset = expanded ? 120 : 0
    return `calc(100vh - ${baseOffset + additionalOffset}px)`
  }, [context, expanded])

  return (
    <Box
      sx={{
        height: gridHeight,
        transition: 'all 0.3s ease-in-out',
        pb: 0,
        pt: 0,
        pr: 1,
        pl: 1,
      }}
    >
      {context === 'nco' && <Channels handleChange={handleFieldChange} checklistProgress={checklistProgress} />}
      {/* Header with "Add Task" Button */}

      <DataGridPro
        rows={orderedTasks}
        getRowId={row => {
          return `${row?.id}-${row?.key}`
        }}
        apiRef={apiRef}
        columns={[
          { field: 'orderIndex', headerName: '#', width: 50, sortable: false },
          {
            field: 'role',
            sortable: false,
            headerName: 'Team Member(s)',
            flex: 1,
            // editable: true,
            renderCell: params => {
              const role = params?.value
              const team = params?.row?.onboardingTeam
              const selectedUsers = team?.filter(user => user?.role === role)
              // const selectedUsers = row
              const canEdit = params?.row?.canEdit
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {selectedUsers && selectedUsers?.length > 0 ? (
                    <>
                      {selectedUsers?.map(user => (
                        <Badge
                          key={user?.id}
                          badgeContent={
                            <Typography
                              sx={{
                                fontSize: 12,
                                color: 'rgba(63, 195, 128, 1)',
                                fontWeight: 'bold',
                              }}
                            >
                              {user?.role}
                            </Typography>
                          }
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <UserImageComponent imageUrl={user.image_512} key={user.user_uuid} />
                        </Badge>
                      ))}
                    </>
                  ) : (
                    <Typography sx={{ color: theme.palette.text.secondary }}>{role}</Typography>
                  )}
                </Box>
              )
            },
          },
          // For the Task Name column:
          {
            field: 'name',
            headerName: 'Task',
            flex: 1,
            sortable: false,
            editable: true,
            renderCell: params => {
              const rowIndex = params?.row?.orderIndex
              const isNew = params?.row?.isNew
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{params?.value}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation() // Prevent unintended triggers
                        if (params?.value) {
                          console.log('🛑 Manually opening modal for:', params.value)
                          handleOpenModal(params.value)
                        }
                      }}
                      color='primary'
                    >
                      <AddIcon />
                    </IconButton>
                    {lastRowIndex === rowIndex && (
                      <IconButton
                        onClick={event => {
                          event.stopPropagation()
                          // Prepare the task object to remove.
                          // Ensure you pass the required identifiers (PK, SK, sectionIndex, itemId).

                          handleRemoveTaskRow(params?.row)
                        }}
                        color='error'
                      >
                        <RemoveIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              )
            },
            renderEditCell: params => {
              const handleChange = event => {
                const newValue = event.target.value
                apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue })
                onRowNameChange(newValue, params.field)
              }

              const handleBlur = () => {
                // Only force edit mode if the row is new and the field is empty.
                if (params.row.isNew && !params.value.trim()) {
                  setTimeout(() => {
                    apiRef.current.startCellEditMode({ id: params.id, field: params.field })
                  }, 0)
                } else {
                  apiRef.current.stopCellEditMode({ id: params.id, field: params.field })
                }
              }

              return (
                <TextField
                  fullWidth
                  value={params.value || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      handleBlur()
                    }
                  }}
                />
              )
            },
          },

          {
            field: 'status',
            headerName: 'Status',
            width: 200,
            sortable: false,
            renderCell: params => {
              const currentStatus = params.value
              const canEdit = params?.row?.canEdit

              // Toggle only when the button is clicked; pass the current status
              const handleToggle = () => {
                if (!canEdit) return
                handleChange(params.row.id, currentStatus)
              }

              return (
                <Button
                  onClick={handleToggle}
                  disabled={!canEdit}
                  sx={{
                    backgroundColor: renderColorByString(currentStatus),
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: renderColorByString(currentStatus, true),
                    },
                    textTransform: 'capitalize',
                    width: '100%',
                    padding: '8px 16px',
                    opacity: canEdit ? 1 : 0.5,
                  }}
                >
                  {currentStatus}
                </Button>
              )
            },
          },
        ]}
        disableSelectionOnClick
        slots={{
          footer: () =>
            newRows.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant='contained' color='success' onClick={handleSaveNewRows}>
                  Save New Tasks
                </Button>
              </Box>
            ),
          toolbar: DrawerTasksToolbar,
        }}
        slotProps={{
          toolbar: {
            deal: deal,
            selectedRole: selectedRole,
            setSelectedRole: setSelectedRole,
            onRoleChange: handleFieldChange,
            handleAddRow: handleAddRow,
            checklistType: checklistType,
          },
        }}
      />

      {/* 🛠 Modal for Creating Custom Checklists */}
      <CustomChecklistModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        deal={deal}
        checklistTitle={checklistTitle}
        context={context}
      />
    </Box>
  )
}

export default MtbTasksGrid
