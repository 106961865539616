import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { GridFooterContainer, GridColDef, GridRowCount } from '@mui/x-data-grid-pro'
import { renderServiceIcon } from './renderServiceIcon.ts'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'

interface TotalsFooterProps {
  grossTotals: { [key: string]: number }
  dynamicColumns: GridColDef[]
  rowCount: number
}

const TotalsFooter: React.FC<TotalsFooterProps> = ({ grossTotals, dynamicColumns, rowCount }) => {
  // Filter dynamic columns to only those with a nonzero gross total.
  const columnsWithTotals = dynamicColumns.filter(col => (grossTotals[col.field] || 0) !== 0)

  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }}
      >
        <GridRowCount rowCount={rowCount} />
        {columnsWithTotals.map(col => (
          <Box key={col.field} sx={{ ml: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Tooltip title={col.headerName}>
              <img src={renderServiceIcon(col.headerName)} style={{ height: 24, width: 24 }} />
            </Tooltip>
            <Typography variant='body2' fontWeight='bold'>
              {formatCurrency(grossTotals[col.field] || 0)}
            </Typography>
          </Box>
        ))}
      </Box>
    </GridFooterContainer>
  )
}

export default TotalsFooter
