import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  Select,
  OutlinedInput,
  useTheme,
  Grid,
  CircularProgress,
  Button,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { dialogStyles } from './style'
import {
  FormData,
  TaskTypeOptions,
  DisplayAdsFields,
  SocialAdFields,
  OrganicSocialFields,
  PhotographyFields,
  PrintDesignFields,
  WebsiteFields,
  VideoFields,
  BrandDesignFields,
  LogoDesignFields,
  FormConfig,
  FieldType,
  FieldConfig,
  CreativeIntakeProps,
  initialFormData,
  initialRequestFields,
  existingClientOptions,
  User,
} from './types.ts'
import FieldComponent from './FieldComponent.tsx'
import { creativeTaskTypeLabels } from './types.ts'
import OnboardTeamAutocomplete from '../DataGrid/components/OnboardTeamAutocomplete.tsx'
import { useUsers } from '../../api/aws/useUsers.js'
import { BoxScroller } from '../../style/styleElements.js'
import TaskRichText from '../Tasks/TaskRichText.js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import { normalizeDate } from '../DateTime/dateUtils.js'
import SelectTaskType from '../Tasks/CreateTasks/SelectTaskType.js'
import { useCreateTask } from '../../api/aws/useCreateTask.js'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider.js'
import { useSubmitTask } from '../../api/aws/tasks/useSubmitTask.tsx'
import FormAutocomplete from './FormAutocomplete.tsx'
import { useOrganizations } from '../../api/aws/useOrganizations.js'
import { useDeals } from '../../api/aws/useDeals.js'
import { useOrganizationById } from '../../api/aws/useOrganization.js'
import { useOrganizationDealsById } from '../../api/aws/useOrganizationDealsById.tsx'
import { useCreateGoogleFolder } from '../../api/google/useCreateGoogleFolder.ts'
import CreateTaskHeader from '../Tasks/CreateTaskHeader.js'
import { useActiveUser } from '../../api/slack/useActiveUser.js'
import { useUser } from '../../api/aws/useUser.js'
import { useUsersById } from '../../api/customHooks/useUsersById.js'
import DrawerLoading from '../Loading/DrawerLoading.js'
interface OptionType {
  id: string
  name?: string // For organizations
  dba?: string // DBA field if needed
  firstName?: string // For users
  lastName?: string // For users
  image_512?: string // For avatars
  org_uuid?: string
  AM: User[]
  DMS: User[]
  website: string
  deal_uuid: string
  deals: object[]
  organization: object | null
  subfolderIds?: string
  googleDriveFolderId?: string
}
const CreativeIntakeForm: React.FC<CreativeIntakeProps> = ({ open, onClose }) => {
  const { users, isUsersLoading, isUsersError } = useUsers(true, ['users', 'creativeIntake'])
  const { data: organizations, isLoading } = useOrganizations(true, ['organizations', 'creativeIntake'])
  const { deals, isDealsLoading } = useDeals(true, ['deals', 'creativeIntake'])
  const [selectedOrganization, setSelectedOrganization] = useState<OptionType | null>(null)
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null)
  const { organization, isOrganizationLoading } = useOrganizationDealsById(selectedOrgId)
  const [taskName, setTaskName] = useState(organization?.name)

  const userIds = useMemo(() => {
    if (!organization) return []

    // Combine AM and DMS arrays, ensuring unique valid IDs
    const combinedIds = [...(organization.AM ?? []), ...(organization.DMS ?? [])]

    return Array.from(new Set(combinedIds.filter(Boolean))) // Remove duplicates & falsy values
  }, [organization])
  const { activeUser, isActiveUserLoading } = useActiveUser()
  const { id: activeUserId } = activeUser?.user
  const { user: currentUser, isUserLoading: isCurrentUserLoading } = useUser(activeUserId)
  const { users: taskAssignees, isUsersByIdLoading: isTaskAssigneesLoading } = useUsersById(userIds ?? [])
  const [selectedDeal, setSelectedDeal] = useState<OptionType | null>(null)
  const [orgDeals, setOrgDeals] = useState<object[]>([])
  const { mutate: createTaskFolder } = useCreateGoogleFolder()
  const [formData, setFormData] = useState<FormData>({ ...initialFormData, taskType: '' })
  const [isCreating, setIsCreating] = useState(false)
  const { mutate: createTask } = useCreateTask()
  const { submitNewTask } = useSubmitTask()
  const { validateCreativeIntake, creativeIntakeErrors } = useValidationContext()
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [requestFields, setRequestFields] = useState<TaskTypeOptions>(initialRequestFields)
  const theme = useTheme()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }
  useEffect(() => {
    if (organization?.name && formData?.type) {
      setTaskName(`${organization.name} - ${formData.type?.map(type => type)}`)
    } else if (organization?.name) {
      setTaskName(organization.name)
    } else {
      setTaskName('')
    }
  }, [organization?.name, formData?.type])
  useEffect(() => {
    if (taskName) {
      console.log('taskName', taskName)
    }
  }, [taskName])
  // Keep formData.name in sync with taskName
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      name: taskName, // Ensure the form's name field is updated
    }))
  }, [taskName])

  useEffect(() => {
    if (organization) {
      setFormData(prev => ({
        ...prev, // Preserve all other existing fields
        assignees: taskAssignees
          ? taskAssignees.map(user => ({
              user_uuid: user.user_uuid || '',
              id: user.id || '',
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              name: user.name || '',
              image_512: user.image_512 || '',
              role: user.role || '',
              title: user.title || '',
            }))
          : prev.assignees, // Ensure we don't override with `undefined`
        assigner: currentUser
          ? {
              user_uuid: currentUser.user_uuid || '',
              id: currentUser.id || '',
              firstName: currentUser.firstName || '',
              lastName: currentUser.lastName || '',
              name: currentUser.name || '',
              image_512: currentUser.image_512 || '',
              role: currentUser.role || '',
              title: currentUser.title || '',
            }
          : prev.assigner, // Preserve the previous value if currentUser is null
      }))
    }
  }, [organization]) // Added dependencies to prevent stale values

  const handleChange = (event: { target: { name: string; value: unknown } }) => {
    const { name, value } = event.target
    const selectedRequestType = formData.taskType as keyof TaskTypeOptions
    const fieldType = selectedRequestType ? FormConfig[selectedRequestType]?.[name]?.type : undefined

    setFormData(prev => ({
      ...prev,
      [name]: fieldType === FieldType.MULTISELECT ? (Array.isArray(value) ? value : [value]) : value,
    }))
  }
  const handleUserChange = (
    role: string,
    selectedUsers: { id: string; firstName: string; lastName: string; name: string; image_512: string; title: string }[]
  ) => {
    setFormData(prev => ({
      ...prev,
      [role]: selectedUsers.map(user => ({
        user_uuid: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user?.name,
        image_512: user.image_512,
        role: role, // Store the role assigned to this user
        title: user?.title,
      })),
    }))
  }

  const handleAddtlNotesChange = (newValue: string) => {
    setFormData(prev => ({ ...prev, addtlNotes: newValue }))
  }
  const handleOrganizationChange = (selectedOrg: OptionType | null) => {
    setSelectedOrganization(selectedOrg)
    setSelectedOrgId(selectedOrg?.org_uuid || null)
    const newOrgDeals = selectedOrg?.deals
    setOrgDeals(newOrgDeals || [])
    setFormData(prev => ({
      ...prev,
      orgId: selectedOrg?.org_uuid || '',
      website: selectedOrg?.website || '',
      tasksFolderId: selectedOrg?.subfolderIds?.tasksFolderId,
      creativeTasksFolderId: selectedOrg?.subfolderIds?.creativeTasksFolderId,
      googleDriveFolderId: selectedOrg?.googleDriveFolderId,
    }))
  }
  const handleFormatTypesString = (type: string) => {
    switch (type) {
      case 'socialAd':
        return 'Social Ads'
      case 'displayAds':
        return 'Display Ads'
      case 'organicSocial':
        return 'Organic Social'
      case 'photography':
        return 'Photography'
      case 'printDesign':
        return 'Print Design'
      case 'website':
        return 'Website'
      case 'video':
        return 'Video'
      case 'logoDesign':
        return 'Logo Design'
      case 'brandDesign':
        return 'Brand Design'
      default:
        return ''
    }
  }
  const handleRequestTypeChange = (e: SelectChangeEvent<string>) => {
    const selectedType = e.target.value as keyof TaskTypeOptions
    const formattedStrings = handleFormatTypesString(selectedType)
    console.log('formattedStrings', formattedStrings)
    setFormData(prev => ({
      ...prev,
      name: `${taskName}${creativeTaskTypeLabels[selectedType]}`,
      taskType: selectedType,
      website: prev?.website || '',
      AM: prev?.AM || [],
      creativeLead: prev?.creativeLead || [],
      DMS: prev?.DMS || [],
      startDate: prev?.startDate || '',
      endDate: prev?.endDate || '',
      addtlNotes: prev?.addtlNotes || '',
      assignees: prev?.assignees || [],
      creativeStatus: prev?.creativeStatus || 'Staging',
    }))
  }

  const fieldTypeOrder = [
    FieldType.TEXT,
    FieldType.SELECT,
    FieldType.MULTISELECT,
    FieldType.CHECKBOX,
    FieldType.NUMBER,
    FieldType.COLOR_PICKER,
    FieldType.DATE,
    FieldType.TEXTAREA, // Put textareas at the bottom
  ]

  const { fields } = useMemo(() => {
    if (!formData.taskType) {
      return { fields: [] }
    }

    const selectedType = formData.taskType as keyof TaskTypeOptions
    return {
      fields: FormConfig[selectedType] ? Object.entries(FormConfig[selectedType]) : [],
    }
  }, [formData.taskType])

  useEffect(() => {
    const validateForm = async () => {
      if (formData) {
        const { isValid } = await validateCreativeIntake(formData) // Await the validation
        setIsFormValid(isValid)
      }
    }

    validateForm() // Call the async function
  }, [formData, formData?.taskType])

  const sortFieldsByType = (fields: [string, FieldConfig][]) => {
    return fields.sort((a, b) => {
      const typeA = fieldTypeOrder.indexOf(a[1].type)
      const typeB = fieldTypeOrder.indexOf(b[1].type)
      return typeA - typeB
    })
  }

  const handleSubmitNewTask = async () => {
    try {
      setIsCreating(true)

      await submitNewTask({
        formData: { ...formData, isCreative: true, type: 'Creative Intake' }, // ✅ Ensure formData is properly structured
        setIsCreating, // ✅ Function to toggle loading state
        onClose, // ✅ Function to close modal after success
        parentFolderId: formData?.creativeTasksFolderId || '', // ✅ Ensure correct parent folder ID
      })
    } catch (error) {
      setIsCreating(false)
      console.error('❌ Error creating new task:', error)
    }
  }
  if (isUsersLoading) {
    return <DrawerLoading />
  }
  return (
    <Dialog fullWidth maxWidth='md' PaperProps={{ sx: dialogStyles(theme) }} open={open} onClose={onClose}>
      <CreateTaskHeader context={'Creative Intake Form'} />

      <BoxScroller
        sx={{
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,1)' : 'rgba(0,0,0,0.5)',
            borderRadius: '6px',
          },
        }}
      >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 1100, margin: 'auto' }}>
            {/* <Typography variant='h5' sx={{ color: theme.palette.text.primary }}>
              Creative Intake Form
            </Typography> */}

            <Grid container rowSpacing={2} columnSpacing={1} sx={{ alignItems: 'center' }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormAutocomplete
                  data={organizations}
                  isLoading={isLoading}
                  label={'Organizations'}
                  error={creativeIntakeErrors?.orgId?.value}
                  onChange={handleOrganizationChange}
                  value={selectedOrganization}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  select
                  label='Task Type'
                  name='taskType'
                  value={formData.taskType || ''} // ✅ Ensure it's always a string
                  onChange={handleRequestTypeChange}
                  fullWidth
                  disabled={isOrganizationLoading || !organization?.name}
                  SelectProps={{
                    multiple: false, // ✅ Ensure it's single-select
                    renderValue: selected => creativeTaskTypeLabels[selected as keyof TaskTypeOptions] || '',
                  }}
                >
                  {Object.entries(creativeTaskTypeLabels).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label='Name'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  fullWidth
                  error={creativeIntakeErrors?.name?.value}
                  disabled={isOrganizationLoading}
                  InputProps={{
                    endAdornment: isOrganizationLoading ? (
                      <CircularProgress size={24} sx={{ color: theme.palette.text.secondary }} />
                    ) : null,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  label='Website'
                  name='website'
                  value={formData.website}
                  onChange={handleInputChange}
                  fullWidth
                  error={creativeIntakeErrors?.website?.value}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <OnboardTeamAutocomplete
                  users={users}
                  role='Assignee'
                  label='Assignee(s)'
                  onChange={handleUserChange}
                  defaultValue={formData?.assignees || []}
                  disabled={false}
                  isLoading={isTaskAssigneesLoading}
                  // error={marketingFormErrors?.assignee?.value}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <OnboardTeamAutocomplete
                  users={users}
                  role='Assigner'
                  label='Assigner'
                  onChange={handleUserChange}
                  defaultValue={formData?.assigner ? [formData?.assigner] : []}
                  disabled={true}
                  isLoading={isActiveUserLoading || isCurrentUserLoading}

                  // error={marketingFormErrors?.assigner?.value}
                />
              </Grid>
              <Grid
                item
                xs={9} // Set full width for textareas
                sm={9}
                md={9}
                lg={9}
                xl={9}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DateRangePicker
                      localeText={{ start: 'Expected Start Date', end: 'Expected Close Date' }}
                      value={[
                        formData?.startDate ? dayjs(formData?.startDate) : null,
                        formData?.endDate ? dayjs(formData?.endDate) : null,
                      ]}
                      onChange={newValue => {
                        const [startDate, endDate] = newValue

                        setFormData(prev => ({
                          ...prev,
                          startDate: normalizeDate(startDate, 'America/New_York'),
                          endDate: normalizeDate(endDate, 'America/New_York'),
                        }))
                      }}
                      slotProps={{
                        textField: {
                          error: creativeIntakeErrors?.startDate?.value || creativeIntakeErrors.endDate?.value,
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiMultiInputDateRangeField-separator': {
                          m: 0,
                        },
                        '& .MuiFormControl-root': {
                          m: 0,
                        },
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  select
                  label='Existing Client?'
                  name='existingClient'
                  options={existingClientOptions}
                  value={formData.existingClient}
                  onChange={handleInputChange}
                  fullWidth
                >
                  {existingClientOptions.map(option => (
                    <MenuItem key={option?.key} value={option?.value}>
                      {option?.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {formData.taskType && (
              <Grid container rowSpacing={2} columnSpacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: 'bold',
                      borderBottom: '2px solid',
                      borderColor: theme.palette.primary.main,
                    }}
                  >
                    {creativeTaskTypeLabels[formData.taskType as keyof TaskTypeOptions]}
                  </Typography>
                </Grid>

                {fields.map(([key, config]) => (
                  <Grid key={key} item xs={6}>
                    <FieldComponent name={key} value={formData[key] || ''} onChange={handleChange} {...config} />
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid
              item
              xs={12} // Set full width for textareas
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pt={1}
            >
              <TaskRichText content={formData.addtlNotes} onContentChange={handleAddtlNotesChange} />
            </Grid>
          </Box>
        </DialogContent>
      </BoxScroller>
      <DialogActions>
        <Button
          sx={{
            marginLeft: 4,
            backgroundColor: 'rgb(244, 67, 54, 0.2)',
            '&:hover': {
              backgroundColor: 'rgb(244, 67, 54, 0.7)',
            },
          }}
          onClick={onClose}
          color='primary'
          variant='outlined'
        >
          Cancel
        </Button>
        <Button
          color='secondary'
          variant='contained'
          // onClick={() => console.log('[Create Button] formData', formData)}
          onClick={handleSubmitNewTask}
          disabled={!isFormValid || isCreating}
        >
          Create Task
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreativeIntakeForm
// Logo review
